import { FormErrorTypes } from "@/components/formGenerator/form-schema.interface";
import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useLogin from "@/components/use/useLogin";
import useSchema from "@/components/use/useSchema";
import { AmplifyUser, AmplifyUserCredentials, LoginActionTypes, LoginPageParameterType } from "./login.interface";
import { useUserStore } from "@/store/vueStore/useUserStore";


export const signInButton = {
    class: "btn-success m-2 save float-end",
    label: "Sign In",
    triggerEvent: {
        originatingAction: "Sign In Button",
        type: LoginActionTypes.SIGN_IN_ACTION,
        payload: null
    },
    isEnabled: true,
    enabledWhenFormIsValid: true,
};

const stashUserInStore = async (user: AmplifyUser): Promise<void> => {
    const { getCurrentCredentials } = useLogin();
    const userStore = useUserStore();

    userStore.setUser(user);
    const credentials = await getCurrentCredentials();

    if (!credentials.hasError()) {
        userStore.setCredentials(credentials.getData() as AmplifyUserCredentials);
    }
}

export const signInSchema =
    FormSchemaMaker.Group(
        {
            label: "Sign in to your existing user account",
            field: "data",
            properties: {
                classes: "",
                hideLabel: false
            },
        },
        [
            FormSchemaMaker.Email({
                name: "username",
                label: "Email Address",
                field: "emailValue",
                properties: { labelAlign: "top" },
                rules: { required: true, email: true },
                value: "",
                placeHolder: "Enter your email address",
                autocomplete: "username"
            }),
            FormSchemaMaker.Password({
                name: "password",
                label: "Password",
                field: "passwordValue",
                properties: { labelAlign: "top" },
                rules: { required: true, min: 8, max: 32 },
                value: "",
                placeHolder: "Enter your password",
                autocomplete: "current-password"
            }),
        ]
    );

export const signInPage = (options: LoginPageParameterType): void => {
    options.currentSchema.value = signInSchema;
    options.buttonFooter.value = {
        infoText: "No account?",
        linkText: "Create account",
        eventType: {
            originatingAction: "Sign In Link",
            type: LoginActionTypes.GOTO_SIGN_UP_PAGE
        },
        footerInfoText: "Forgot your password?",
        footerLinkText: "Reset password",
        footerEventType: {
            originatingAction: "Sign In Footer",
            type: LoginActionTypes.GOTO_RESET_PAGE
        },
    };
    options.buttonFooter2.value = null;
    options.buttonList[0] = signInButton;
    options.enterKeyAction.value.triggerEvent.type = LoginActionTypes.SIGN_IN_ACTION;
}

export const signInAction = async (options: LoginPageParameterType): Promise<void> => {
    if (!options.originationEvent?.payload?.schemaItem) return;

    const { signIn } = useLogin();
    const { schema_getJSON } = useSchema();

    const data = schema_getJSON(options.originationEvent.payload.schemaItem).data;

    try {
        const result = await signIn(data.emailValue as string,
            data.passwordValue as string);

        console.debug("Results of user ", result);
        if (result.hasError()) {
            const errorMsg = result.getFlatErrorMessage();
            if (errorMsg == "User is not confirmed.") {
                options.onPress({
                    originatingAction: "Sign In Success",
                    type: LoginActionTypes.GOTO_USER_CONFIRM_PAGE,
                    //payload: data.emailValue,
                });
                return
            }

            options.errorMessage.value = errorMsg;
            options.emitError({ type: FormErrorTypes.INVALID, message: errorMsg });
            return;
        }

        console.debug("New user has been logged in: ", result.getData());
        stashUserInStore(result.getData() as AmplifyUser);

    } catch (error) {
        console.error(error);
    }
}

export const signInExistingUser = async (): Promise<void> => {
    const { getCurrentUser } = useLogin();

    const userData = await getCurrentUser();

    console.log("Sign In Existing User - ", userData);

    if (!userData.hasError()) {
        stashUserInStore(userData.getData() as AmplifyUser);
    }
}