import * as BABYLON from 'babylonjs';
import { Pipe_Elbow_45 } from "../Pipe/Pipe_Elbow_45";
import { Pipe_Elbow_90 } from "../Pipe/Pipe_Elbow_90";
import { Pipe_MountBoard } from "../Pipe/Pipe_MountBoard";
import { Pipe_Tube } from "../Pipe/Pipe_Tube";
import { Pipe_T_Joint } from "../Pipe/Pipe_T_Joint";
import { PrimativeObjectGenerator } from "./PrimativeFactoryInterface";
import { Pipe_Nozzle } from '../Pipe/Pipe_Nozzle';

export enum PipeGeneratorType {
    Board,
    Pipe,
    Elbow_90,
    Elbow_45,
    T_Joint,
    Nozzle
}

export class PipeGenerator {
    private generators: Map<PipeGeneratorType, PrimativeObjectGenerator> = new Map();

    constructor(scene: BABYLON.Scene) {
        this.generators.set(PipeGeneratorType.Board, new Pipe_MountBoard(scene));
        this.generators.set(PipeGeneratorType.Pipe, new Pipe_Tube(scene));
        this.generators.set(PipeGeneratorType.Elbow_90, new Pipe_Elbow_90(scene));
        this.generators.set(PipeGeneratorType.Elbow_45, new Pipe_Elbow_45(scene));
        this.generators.set(PipeGeneratorType.T_Joint, new Pipe_T_Joint(scene));
        this.generators.set(PipeGeneratorType.Nozzle, new Pipe_Nozzle(scene));
    }

    public addPreloadAsset(scene: BABYLON.Scene, assetManager: BABYLON.AssetsManager) {
        this.generators.forEach(function (v) {
            v.addPreloadAssets(assetManager);
        });
    }


    public create(type: PipeGeneratorType, parent: BABYLON.Mesh | null, options: any): any {
        const generator = this.generators?.get(type);
        if (generator) {
            return generator.create(parent, options);
        } else {
            return null;
        }
    }
}


