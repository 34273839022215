
  import FormGroupButtons from "./form-group-buttons.vue";
  import FormSchemaJson from "../form-schema-json.vue";
  import BModal from "../../bootstrap/b-modal.vue";

  import { defineComponent, PropType } from "vue";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { FormButtonAction } from "../form.interface";

  export default defineComponent({
    components: {
      FormGroupButtons,
      FormSchemaJson,
      BModal,
    },
    props: {
      ...formItemMixinProps,
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        default: () => {
          return [];
        },
      },
    },
    emits: ["btnPress"],
    setup() {
      return {};
    },
    methods: {
      onBtnPress(btnAction: FormButtonAction) {
        this.$emit("btnPress", btnAction);
      },
    },
  });
