
  import { defineComponent } from 'vue'
  import QuestionViewer from './QuestionViewer.vue'
  import questionData from "./mock/question-component-1.json"

  export default defineComponent({
    components: { QuestionViewer },
    setup() {
      return {
        questionData
      }
    }
  })
