import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import { UserResponseType } from "../components/user-response.interface";

export function getProblemPartsSchema() {
   return FormSchemaMaker.ArrayOfObjectWithEditor({
      label: "Problem Parts",
      field: "requestedResponses",
      properties: {
         labelAlign: "top",
      },
      rules: {},
      hint: "List any known variables",
      typeSelectorInfo: {
         label: "Question Type",
         default: UserResponseType.INPUT_TEXT,
         choices: [
            {
               typeName: UserResponseType.MULTIPLE_CHOICE,
               visibleFields: ['name', 'description', 'multipleChoiceType', 'nrChoices', 'nrSigFigs', 'correctAnswer', 'partialPMper',
                  'correctUnit', 'incorrectAnswers', 'changeOrder', 'changeValue', 'changeSign', 'changeInteger']
            },
            {
               typeName: UserResponseType.INPUT_TEXT, visibleFields: ['name', 'description', 'inputType',
                  'correctAnswer', 'correctPMper', 'partialPMper', 'correctUnit', 'nrSigFigs']
            },
            {
               typeName: UserResponseType.HANDWRITING, visibleFields: ['name', 'description', 'translation']
            }
         ]
      }
   }, [
      FormSchemaMaker.Text({ label: "Name", field: "name", properties: { labelCols: 4, componentCols: 8 }, hint: "Enter the name of this problem part (used only for your reference purposes)." }),
      FormSchemaMaker.Text({ label: "Statement", field: "description", properties: { labelCols: 4, componentCols: 8 }, hint: "Statement of requested quantity that will be displayed to the student." }),
      FormSchemaMaker.Select({ label: "Type", properties: { labelCols: 4, componentCols: 8 }, field: "multipleChoiceType", choices: ["AUTO GENERATE ANSWERS", "SPECIFY ANSWERS"], value: "AUTO GENERATE ANSWERS", hint: "AUTO GENERATE ANSWERS will take the solution and change the value numerically, whereas SPECIFY ANSWERS will use the specified answers in the list of answers array." }),
      FormSchemaMaker.Select({ label: "Type", properties: { labelCols: 4, componentCols: 8 }, field: "inputType", choices: ["SCALAR", "VECTOR"], value: "SCALAR", hint: "SCALAR will take request a single number from the user while VECTOR will have the student enter numerical values for the i, j, and k components." }),
      FormSchemaMaker.Number({ label: "Number of Auto-generated Choices", value: 4, properties: { labelCols: 4, componentCols: 8 }, field: "nrChoices", rules: { min_value: 1, max_value: 50 }, hint: "Enter the number of multiple choice selections to include in the question (Used only by the auto generated answers option)" }),
      FormSchemaMaker.Number({ label: "Number of Significant Figures in Auto-gen Choices", properties: { labelCols: 4, componentCols: 8 }, field: "nrSigFigs", rules: { min_value: 2, max_value: 20 }, value: 3, hint: "Enter the number of significant figures to use." }),
      FormSchemaMaker.Text({ label: "Correct Answer", properties: { labelCols: 4, componentCols: 8 }, field: "correctAnswer", hint: "The correct answer or the equation for the correct answer to the problem." }),
      FormSchemaMaker.TextBox({ label: "Translation", field: "translation", properties: {}, hint: "The text translation returned by handwriting analysis." }),
      FormSchemaMaker.Number({ label: "Full Credit pm percent", properties: { labelCols: 4, componentCols: 8 }, field: "correctPMper", hint: "The student must input the correct answer to within this percent to receive full credit.", value: 1 }),
      FormSchemaMaker.Number({ label: "Loss Per Attempt", properties: { labelCols: 4, componentCols: 8 }, field: "partialPMper", hint: "Each time the student obtains an incorrect answer, take off this many points.", value: 3 }),
      //FormSchemaMaker.Text({ label: "Correct Unit", field: "correctUnit", properties: { labelCols: 4, componentCols: 8 }, hint: "The answer unit will be displayed adjacent to the numerical answer. If blank, nothing will appear.", value: '' }),
      FormSchemaMaker.ArrayOfText({ label: "Incorrect Answer Array", field: "incorrectAnswers", properties: { labelCols: 4, componentCols: 8 }, hint: "Enter a list of incorrect answers to be displayed to the user. You do not need this array if the auto generate option is selected" }),
      FormSchemaMaker.CheckBox({ label: "Order", field: "changeOrder", properties: { labelCols: 3, componentCols: 3 }, value: false, hint: "Allow for changes in the order of magnitude of the answer (by factor of 1000)." }),
      FormSchemaMaker.CheckBox({ label: "Value", field: "changeValue", properties: { labelCols: 3, componentCols: 3 }, value: true, hint: "Allow changes in solution by random floating point values." }),
      FormSchemaMaker.CheckBox({ label: "Integer", field: "changeInteger", properties: { labelCols: 3, componentCols: 3 }, value: true, hint: "Allow changes in solution by random integer values." }),
      FormSchemaMaker.CheckBox({ label: "Sign", field: "changeSign", properties: { labelCols: 3, componentCols: 3 }, value: false, hint: "Allow changes in the sign of the solution." })
   ])
}
