<template>
  <div>
    <textarea v-model="jsonData"
              class="w-100"
              rows="20"
              readonly />
  </div>
</template>
<script>
  import { FormSchemaParser } from "./form-schema-parser";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormSchemaJson",
    props: {
      schema: {
        type: Object,
        required: true,
      },
    },
    computed: {
      jsonData: function () {
        let formParser = new FormSchemaParser(this.schema);
        return JSON.stringify(formParser.toJSON(), null, "\t");
      },
    },
  });
</script>
<style lang="">
</style>