<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <div class="row">
      <div class="col">
        <input :class="'dateSubElement form-control ' + validationState"
               type="date"
               :value="currentDate"
               :aria-describedby="fid"
               :readonly="schemaitem.properties.disabled"
               required
               @input="currentDate=$event.target.value">
      </div>
      <div class="col">
        <input :class="'timeSubElement form-control ' + validationState"
               type="time"
               :value="currentTime"
               :aria-describedby="fid"
               :readonly="schemaitem.properties.disabled"
               required
               @input="currentTime=$event.target.value">
      </div>
    </div>
  </form-input-group>
</template>

<script lang="ts">
  import formInputGroup from "./form-input-layout.vue";
  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps, formItemMixinInterface } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, ref, SetupContext, watch } from "vue";
  import useTime from "../../use/useTime";

  export default defineComponent({
    name: "FormInputText",
    components: { formInputGroup },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const { getLocalDateFrom, getLocalTimeFrom, composeDateTime } = useTime();

      const strTime = getLocalTimeFrom(props.modelValue as string);
      const strDate = getLocalDateFrom(props.modelValue as string);

      const currentTime = ref(strTime);
      const currentDate = ref(strDate);

      let { currentValue, validationState } = useValidation(props as formItemMixinInterface, context);

      watch(currentTime, () => {
        currentValue.value = composeDateTime(
          currentTime.value,
          currentDate.value
        );
      });

      watch(currentDate, () => {
        currentValue.value = composeDateTime(
          currentTime.value,
          currentDate.value
        );
      });

      return {
        currentTime,
        currentDate,
        currentValue,
        validationState,
      };
    },
  });
</script>
<style lang="">
</style>