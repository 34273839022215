import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useTime from "@/components/use/useTime";
import { v4 as uuidv4 } from 'uuid';

export function getCourseAvailabilitySchema(courseId: string) {
   const { getDateInFuture } = useTime();
   const uniqueCourseNr = uuidv4();
   return FormSchemaMaker.Tab({
      label: "Tab Database Item",
      field: "",
      properties: { classes: "m-1 p-1 border formColorGroup" }
   },
      [
         FormSchemaMaker.Group({ label: "Course Database Item", field: "", properties: { classes: "m-1" } },
            [
               FormSchemaMaker.ReadOnly({ label: "UID", properties: { labelCols: 4, componentCols: 6 }, field: "id", value: uniqueCourseNr }),
               FormSchemaMaker.ReadOnly({ label: "Course ID", properties: { labelCols: 4, componentCols: 6 }, field: "courseID", value: uniqueCourseNr }),
               FormSchemaMaker.ReadOnly({ label: "Creator", properties: { labelCols: 4, componentCols: 6 }, field: "userEmail", value: "" }),
               FormSchemaMaker.ReadOnly({ label: "Field Type", properties: { labelCols: 4, componentCols: 6 }, field: "fieldType", value: "COURSE" }),
               FormSchemaMaker.Text({ label: "Course Name", field: "name", rules: { min: 5, max: 32 }, hint: "Assign a name to your course. Use a descriptive title that will help users find your course." }),
               FormSchemaMaker.TextBox({ label: "Short Description", field: "description", hint: "Add a description to help users learn about your content." }),
               FormSchemaMaker.FileLocation({ label: "Thumbnail", field: "co_pictureURL", hint: "Add the URL for a thumbnail photo." }),
               FormSchemaMaker.MarkdownIt({ label: "Long Description", field: "long_description", hint: "Add a longer description to help users learn about your content." }),
               FormSchemaMaker.ArrayOfEmail({ label: "List of Coeditors", field: "co_editors", hint: "Add the user email addresses for any individuals you would like to make editors of your course content. Anyone added to this list will be given full access to alter your course." })
            ]),
         FormSchemaMaker.Group({ label: "Availibility", field: "availibility", properties: { classes: "p-1" } },
            [
               FormSchemaMaker.ReadOnly({
                  label: "Stripe Product ID", field: "stripeProductID", value: "",
                  hint: ""
               }),
               FormSchemaMaker.ReadOnly({
                  label: "Stripe Price ID", field: "stripePriceID", value: "",
                  hint: ""
               }),
               FormSchemaMaker.Select({
                  label: "Type of Restriction", field: "restrictionType",
                  choices: ["ACCESS_CODE", "EMAIL_MATCH", "EMAIL_LIST", "PAID", "FREE", "NONE"],
                  hint: "Access Code requires a user to input a code before registering.\n" +
                     "Email Matching can be used to restrict access to users that have email addresses from a particular school.\n" +
                     "Email List can be used to add the user email of your students directly to register them for the course.\n" +
                     "Paid requires students to pay before registering for your course.\n" +
                     "Free allow any student to register for your course free of charge.\n" +
                     "None sets no restrictions on course availibility."
               }),
               FormSchemaMaker.Integer({
                  label: "Cost", field: "cost", rules: { min_value: 0, max_value: 100 },
                  hint: "Cost in dollars students will be charged."
               }),
               FormSchemaMaker.Text({
                  label: "Access Code", field: "inputCode", rules: { min: 7, max: 50 },
                  hint: "Select a 15 digit access code which your students will use to register for this course."
               }),
               FormSchemaMaker.Text({
                  label: "Email Regex Condition", field: "userEmailMatch", value: "",
                  hint: "Enter the regex expression used to filter email addresses for registration."
               }),
               FormSchemaMaker.ArrayOfEmail({
                  label: "List of Allowed Emails", field: "userEmailList",
                  hint: "Enter the email address for each student you would like registered in this course."
               }),
               FormSchemaMaker.CheckBox({
                  label: "Restrict to Dates", field: "restrictToDates",
                  hint: "Restrict access to your course based on dates."
               }),
               FormSchemaMaker.DateTime({
                  label: "Show On", field: "showOn",
                  hint: "Begin displaying your course to students on this date. The course will appear as available, " +
                     "but students will be unable to start the content until the Open On date has arrived."
               }),
               FormSchemaMaker.DateTime({
                  label: "Open On", field: "openOn",
                  hint: "Students will be allowed to complete course content starting on this date. "
               }),
               FormSchemaMaker.DateTime({
                  label: "Close On", field: "closeOn",
                  hint: "Students will no long be able to access your course following the close on date."
               }),
               FormSchemaMaker.DateTime({
                  label: "Hide On", field: "hideOn",
                  hint: "Remove the course from user view on this date."
               }),
            ]),
         FormSchemaMaker.Group({ label: "Course Database Item", field: "", properties: { classes: "m-1" } },
            [
               FormSchemaMaker.ReadOnly({ label: "Question Bank ID", field: "co_questionBankID", value: "" }),
               FormSchemaMaker.CheckBox({ label: "Include Textbook", properties: { labelCols: 4, componentCols: 6 }, field: "co_includeText", value: false }),
               FormSchemaMaker.ReadOnly({ label: "Textbook ID", properties: { labelCols: 4, componentCols: 6 }, field: "co_textbookID", value: "", hint: "Give students access to the course textbook." }),
               FormSchemaMaker.CheckBox({ label: "Include Assignments", properties: { labelCols: 4, componentCols: 6 }, field: "co_includeAssignments", value: false, hint: "Give students access to course assignments." }),
               FormSchemaMaker.ReadOnly({ label: "Assignment ID", properties: { labelCols: 4, componentCols: 6 }, field: "co_assignmentBook", value: "" }),
               FormSchemaMaker.CheckBox({ label: "Include Laboratory", properties: { labelCols: 4, componentCols: 6 }, field: "co_includeLab", value: false, hint: "Gives students access to the laboratory." }),
               FormSchemaMaker.ReadOnly({ label: "Laboratory ID", properties: { labelCols: 4, componentCols: 6 }, field: "co_laboratoryID", value: "" }),
               FormSchemaMaker.CheckBox({ label: "Include Student Notebook", field: "co_includeNotebook", value: false, hint: "Creates a seperate hand writing notebook for each student to use in this course." })
            ]),
         FormSchemaMaker.Group({ label: "Course Topics", field: "", properties: { classes: "m-1" } },
            [
               FormSchemaMaker.ArrayOfObjectWithEditor({
                  label: "Topics",
                  field: "co_topics",
                  properties: {
                     labelAlign: "top",
                  },
                  rules: {},
                  hint: "",
                  value: [],
               },
                  //FormSchemaMaker.ArrayOfObjectWithEditor({ label: "Course Topics", field: "co_topics", rules: {}, hint: "" },
                  [
                     FormSchemaMaker.Text({ label: "Name", field: "name", hint: "Enter the subtopic name." }),
                     FormSchemaMaker.ReadOnly({ label: "ID", field: "id", value: () => { return uuidv4(); } }),
                     FormSchemaMaker.ArrayOfObject({ label: "Subtopics", field: "subTopics", rules: {}, hint: "List any known variables", value: [] },
                        [
                           FormSchemaMaker.Text({ label: "Name", field: "name", hint: "Enter the subtopic name.", value: "" }),
                           FormSchemaMaker.ReadOnly({ label: "ID", field: "id", value: () => { return uuidv4(); } }),
                        ]
                     )
                  ]
               )
            ]
         )
      ]);
}

