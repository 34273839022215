
  import { BaseEventRoute } from '@/components/global/event.interface';
  import useEvents from '@/components/use/useEvents';
  import { useDatabaseMappings } from '@/store/connectors/databaseMapping';
  import { useUserStore } from '@/store/vueStore/useUserStore';
  import { defineComponent, EmitsOptions, SetupContext } from 'vue'
  import PCardButtonBar from '../components/p-cardButtonBar.vue'

  export default defineComponent({
    components: { PCardButtonBar },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props, context: SetupContext<EmitsOptions>) {
      console.debug("Enter Course Edit Page")
      const { userName, isAdmin } = useUserStore();

      if (!userName) return null;

      const {
        course,
        assignmentBook,
        textbook,
        laboratory,
        questionBank
      } = await useDatabaseMappings().getCourseMaterials(userName, props.courseId);

      const courseData = course?.data();
      if (!courseData) return null;

      const buttons = [
        [{
          label: "Edit Course",
          icon: "bi-pencil",
          emitStr: "EditCourse",
          show: isAdmin
        },
        {
          label: "Textbook",
          icon: "bi-book",
          emitStr: "EditTextbook",
          show: true
        }],
        [{
          label: "Laboratory",
          icon: "bi-tools",
          emitStr: "EditLaboratory",
          show: true
        },
        {
          label: "Assignment",
          icon: "bi-journal-text",
          emitStr: "EditAssignment",
          show: true
        }],
        [{
          label: "Questions",
          icon: "bi-question-diamond",
          emitStr: "EditQuestions",
          show: true
        }],
      ];

      const { eventRouter } = useEvents(context);
      const routes = [
        {
          eventName: "EditCourse",
          routeName: "CourseEditor",
          params: {
            courseId: props.courseId
          },
        },
        {
          eventName: "EditTextbook",
          routeName: "TextbookEditor",
          params: {
            courseId: props.courseId,
            textId: textbook?.getID(),
            rootPageId: textbook?.getRootPageID(),
            pageId: textbook?.getFirstPage(),
          },
        },
        {
          eventName: "EditLaboratory",
          routeName: "AssignmentEditor",
          params: {
            courseId: props.courseId,
            textId: laboratory?.getID(),
            rootPageId: laboratory?.getRootPageID(),
            pageId: laboratory?.getFirstPage(),
          },
        },
        {
          eventName: "EditAssignment",
          routeName: "AssignmentEditor",
          params: {
            courseId: props.courseId,
            textId: assignmentBook?.getID(),
            rootPageId: assignmentBook?.getRootPageID(),
            pageId: assignmentBook?.getFirstPage(),
          },
        },
        {
          eventName: "EditQuestions",
          routeName: "QuestionBankEditor",
          params: {
            courseId: props.courseId,
            textId: questionBank?.getID(),
            rootPageId: questionBank?.getRootPageID(),
            pageId: questionBank?.getFirstPage(),
          },
        },

      ] as BaseEventRoute[];

      return {
        buttons,
        eventRouter,
        routes
      }
    }
  })
