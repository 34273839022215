import * as BABYLON from 'babylonjs';
import { AssetTaskTiedToScene, BaseRenderedObject, RenderedObjectTypes } from '../../primatives/BaseRenderedObject';
import { MaterialRegistry } from '../../primatives/Materials/MaterialRegistry';
import { BaseWindTunnelGeometry } from '../../properties/Geometries_Drag';
import { Fluid, Fluids } from '../../properties/Standard_Fluids';
import { ScalarValue, _u } from '../../values/ScalarValue';
import { VariableTable } from "../../values/VariableTable";
import { InstrumentDisplayValue, NoiseCorrelation, NoiseType, RangeOverFlowOptions } from '../InstrumentDisplayValue';
import { ElectronicDevice, ElectronicDisplay, ElectronicStateMachine } from './BaseElectronicDevice';
import { ControlResponseRequested } from '../../controls/Control_Base';
import { Nullable } from 'babylonjs/types';

export class WindTunnelController extends ElectronicDevice {
    private windTunnel: Nullable<WindTunnel> = null;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.WIND_TUNNEL;

        // textures
        this.faceTextureURL = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/WindTunnel.png";

        // setup state 
        // setup state machine
        this.stateMachine = new ElectronicStateMachine({
            states: {
                isConnected: false,
                dragForce: 0,
                liftForce: 0,
                fanSpeed: 0,
                isRunning: false,
                caseIsOpen: false
            },
            computed: {
            },
            actions: {
                hitPower: (sm) => {
                    sm.togglePower();
                },
                hitRun: (sm) => {
                    sm.set('isOn', true);
                    sm.set('isRunning', true);
                },
                hitStop: (sm) => {
                    sm.set('isOn', true);
                    sm.set('isRunning', false);
                },
                hitSpeedUp: (sm) => {
                    sm.set('isOn', true);
                    sm.addToState('fanSpeed', 0.5).limitValue('fanSpeed', 0, 10);
                },
                hitSpeedDown: (sm) => {
                    sm.set('isOn', true);
                    sm.addToState('fanSpeed', -0.5).limitValue('fanSpeed', 0, 10);
                }
            }
        });


        // button map
        this.addButtonMapItem('SPEED_UP', [0.18, -0.77, 0.39, -0.65], 'hitSpeedUp');
        this.addButtonMapItem('SPEED_DOWN', [0.18, -0.97, 0.39, -0.85], 'hitSpeedDown');

        this.addButtonMapItem("RUN", [0.61, -0.77, 0.81, -0.65], 'hitRun');
        this.addButtonMapItem("STOP", [0.61, -0.97, 0.81, -0.85], 'hitStop');


        // setup ports
        this.portMap.set('AxialForce',
            {
                name: '0',
                value: new InstrumentDisplayValue(0, 'N',
                    {
                        minValue: _u(-1000, "N"),
                        maxValue: _u(1000, "N"),
                        overflow: RangeOverFlowOptions.CLIP
                    },
                    {
                        addNoise: true,
                        type: NoiseType.PerOfRange,
                        percent: 0.000005,
                        correlation: NoiseCorrelation.SinVariationWithTime,
                        period: 1
                    }),
                connection: undefined
            });
        this.portMap.set('ZForce',
            {
                name: '0',
                value: new InstrumentDisplayValue(0, 'N',
                    {
                        minValue: _u(-1000, "N"),
                        maxValue: _u(1000, "N"),
                        overflow: RangeOverFlowOptions.CLIP
                    },
                    {
                        addNoise: true,
                        type: NoiseType.PerOfRange,
                        percent: 0.000005,
                        correlation: NoiseCorrelation.SinVariationWithTime,
                        period: 1
                    }),
                connection: undefined
            });

        // display function
        this.onDisplayUpdate = this.displayCallback;
    }

    public setWindTunnel(windTunnel: WindTunnel) {
        this.windTunnel = windTunnel;
    }

    public getStateMachine() {
        return this.stateMachine;
    }

    private displayCallback(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {
        const time = variableTable.get("TIME")?.in("s") ?? 0;
        const fanSpeed = this.stateMachine?.get('fanSpeed');
        const isRunning = this.stateMachine?.get('isRunning');

        this.windTunnel?.updateForces();

        display.drawText("Fan ", 5, 30, this.infoFont)
            .conditionalDraw(isRunning && !this.stateMachine?.get('caseIsOpen'), "--", fanSpeed, "", 3, 40, 50, 30, this.infoFont);

        if (this.stateMachine?.get('isConnected')) {
            const dF = this.stateMachine?.get('dragForce');
            const axialForce = (this.portMap?.get('AxialForce')?.value?.copy(_u(dF, 'N')) as InstrumentDisplayValue)
                .getDisplayValueIn('N', time);

            display.drawText("FD", 5, 80, this.infoFont)
                .conditionalDraw(isRunning && !this.stateMachine?.get('caseIsOpen'), "--", axialForce, "N", 4, 40, 50, 80, this.infoFont);

            const zF = this.stateMachine?.get('liftForce');
            const zForce = (this.portMap.get('ZForce')?.value?.copy(_u(zF, 'N')) as InstrumentDisplayValue)
                .getDisplayValueIn('N', time);

            display.drawText("FL", 5, 110, this.infoFont)
                .conditionalDraw(isRunning && !this.stateMachine?.get('caseIsOpen'), "--", zForce, "N", 4, 40, 50, 110, this.infoFont);
        }
    }

}

interface WindTunnelOptions {
    location: BABYLON.Vector3,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    fluid: Fluids,
    temperature: ScalarValue
}

export class WindTunnel extends BaseRenderedObject {
    private snapObjectLocation: BABYLON.Vector3 = BABYLON.Vector3.Zero();
    private controller: Nullable<WindTunnelController> = null;
    private connectedObject: Nullable<BaseWindTunnelGeometry> = null;
    private windtunnelMesh: Nullable<BABYLON.Mesh> = null;
    private wheelMesh: Nullable<BABYLON.Mesh> = null;
    private wheelRotation: InstrumentDisplayValue;
    private fluid: Nullable<Fluid> = null;
    static assetTask: Nullable<AssetTaskTiedToScene> = null;


    private options: WindTunnelOptions = {
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        fluid: Fluids.AIR,
        temperature: _u(20, 'C')
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.WIND_TUNNEL;

        this.addChild(new WindTunnelController("wt_controller", this.scene, this));

        this.wheelRotation = new InstrumentDisplayValue(0, "RADIANS",
            {
                minValue: _u(0, "Radians"),
                maxValue: _u(Math.PI / 2.0, "Radians"),
                overflow: RangeOverFlowOptions.CLIP
            },
            { addNoise: false }
        );
    }

    public setOptions(options: WindTunnelOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }


    public registerAbsoluteSnapPoints() {
        this.registerSnapPoint(
            this.snapObjectLocation,
            [RenderedObjectTypes.WT_GEOMETRY],
            (geom) => {
                this.connectedObject = geom as BaseWindTunnelGeometry;
                this.controller?.getStateMachine()?.set('isConnected', true);
            },
            (oldGeom) => {
                this.setHandle(0);
                this.connectedObject = null;
                this.controller?.getStateMachine()?.set('isConnected', false);
            }
        );
        super.registerAbsoluteSnapPoints();
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        const url = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/windtunnel/";
        const fileName = "WindTunnel.stl";

        this.preloadAssetWorker(
            assetManager,
            WindTunnel.assetTask,
            "Load Wind tunnel",
            url,
            fileName,
            (task) => {
                this.windtunnelMesh = (task.loadedMeshes[0] as BABYLON.Mesh);
                this.windtunnelMesh.scaling = BABYLON.Vector3.One().scale(0.015);
                this.windtunnelMesh.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(0.1, 0.1, 0.6));
                this.windtunnelMesh.setEnabled(false);
            });

        super.addPreloadAssets(assetManager);
    }

    public updateForces() {
        const sm = this.controller?.getStateMachine();
        if (this.connectedObject === null) {
            sm?.set('dragForce', 0);
            sm?.set('liftForce', 0);
            return;
        }

        const rho = this.fluid?.getDensity().in('kg/m^3') ?? 0;
        const A = this.connectedObject.crossSectionalArea(_u(0, 'rad')).in('m^2');
        const L = this.connectedObject.characteristicLength().in('m');
        const U = this.getSpeed().in('m/s');
        const v = this.fluid?.getViscosity().in('m^2/s') ?? 1;
        const Re = L * U / v;
        const angle = this.wheelRotation.in('DEG');
        const Cd = this.connectedObject.dragCoef(Re, angle);
        const Cl = this.connectedObject.liftCoef(Re, angle);


        sm?.set('dragForce', 0.5 * Cd * rho * A * U * U);
        sm?.set('liftForce', 0.5 * Cl * rho * A * U * U);
    }

    public getSpeed() {
        const fanSetting = this.controller?.getStateMachine()?.get('fanSpeed') / 2.5; // 0 to 10
        const exp = Math.exp(fanSetting);
        const speed = _u(10 * (exp - 1) / (exp + 1), 'm/s');
        return speed;
    }

    public render() {
        const distFromFloor = 0.73;

        this.fluid = new Fluid(this.options.fluid, this.options.temperature);

        const base = BABYLON.MeshBuilder
            .CreateBox("Base", { width: 5, height: 0.2, depth: 1 }, this.scene);


        const partHolder = BABYLON.MeshBuilder
            .CreateCylinder("partHolder", { diameter: 0.01, height: 0.3, subdivisions: 1, tessellation: 5 }, this.scene)
            .locallyTranslate(new BABYLON.Vector3(-0.5, 1, -0.15))
            .addRotation(Math.PI / 2.0, 0, 0)
            .setParent(base);

        const angleControlRod = BABYLON.MeshBuilder
            .CreateCylinder("angleControl", { diameter: 0.02, height: 0.6, subdivisions: 1, tessellation: 5 }, this.scene)
            .locallyTranslate(new BABYLON.Vector3(-1.0, 0.5, -0.0))
            .addRotation(Math.PI / 2.0, 0, 0)
            .setParent(base);

        const wheel = BABYLON.MeshBuilder
            .CreateCylinder("wheel", { diameter: 0.15, height: 0.02, subdivisions: 1, tessellation: 15 }, this.scene);

        wheel.addRotation(Math.PI / 2.0, 0, 0);
        wheel.bakeCurrentTransformIntoVertices();

        this.wheelMesh = wheel;

        wheel.locallyTranslate(new BABYLON.Vector3(-1.0, 0.5, 0.3))
            //    .addRotation(Math.PI / 2.0, 0, 0)
            .setParent(base);

        wheel.clone("backWheel", base)
            .locallyTranslate(new BABYLON.Vector3(0.0, 0.0, -0.6));

        wheel.clone("backWheel", base)
            .locallyTranslate(new BABYLON.Vector3(0.5, 0.5, -0.6));

        const knob = BABYLON.MeshBuilder
            .CreateCylinder("knob", { diameter: 0.02, height: 0.05, subdivisions: 1, tessellation: 10 }, this.scene);

        knob.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(0.3, 0.3, 0.3));

        knob.locallyTranslate(new BABYLON.Vector3(-1.06, 0.5, 0.32))
            .addRotation(Math.PI / 2.0, 0, 0)
            .setParent(wheel);

        wheel.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(1, 0, 0));

        // register hit response
        wheel.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        wheel.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    wheel.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(1, 0, 0));
                }));

        wheel.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    wheel.material = MaterialRegistry.getHighlight(this.scene);
                }));

        wheel.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    // select fluid
                    // select amount?
                    this.getControlPanel()
                        .processHit(ControlResponseRequested.OPEN_VALVE_SLIDER,
                            {
                                controlParams: {
                                    getTargetMesh: () => { return wheel; },
                                    title: "Rotate Object",
                                    sliderTitle: "Angle",
                                    sliderUnit: "degs",
                                    max: 20 / 180 * Math.PI,
                                    min: -20 / 180 * Math.PI
                                }
                            },
                            this,
                            { properties: ["HANDLE"] });
                }));



        const marker = BABYLON.MeshBuilder.CreateSphere('Marker', { diameter: 0.03 }, this.scene);
        marker.position = new BABYLON.Vector3(-0.5, 1.0, 0);
        marker.setParent(base);

        const windT = this.windtunnelMesh
            ?.createInstance(this.name + 'instance');

        windT
            ?.addRotation(Math.PI / 2.0, Math.PI / 2.0, 0)
            .setEnabled(true);

        const glass = BABYLON.MeshBuilder
            .CreateBox('glass', { width: 1.3, height: 0.5, depth: 0.01 }, this.scene);

        glass.position.x -= 0.5;

        const glass2 = glass.clone();
        glass2.setPivotPoint(new BABYLON.Vector3(0, 0.25, 0))

        glass.position.z -= 0.25;
        glass2.position.z += 0.25;
        glass2.position.y += 0.25;

        glass.setParent(windT ?? null);
        glass.material = MaterialRegistry.getGlass(this.scene);

        glass2.setParent(windT ?? null);
        glass2.material = MaterialRegistry.getGlass(this.scene);


        let isOpen = false;
        this.controller = this.getChildByName('wt_controller') as WindTunnelController;
        this.controller.setWindTunnel(this);

        glass2.actionManager = new BABYLON.ActionManager(this.scene);
        glass2.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    if (isOpen) {
                        glass2.addRotation(Math.PI / 1.5, 0, 0);
                        this.controller?.getStateMachine()?.set('caseIsOpen', false);
                    } else {
                        this.controller?.getStateMachine()?.set('caseIsOpen', true);
                        glass2.addRotation(-Math.PI / 1.5, 0, 0);
                    }
                    isOpen = !isOpen;

                }));

        if (windT) {
            windT.position.y += 1.0;
            windT.position.x += 0;
            windT.setParent(base);
        }

        const pole1 = BABYLON.MeshBuilder.CreateBox("Pole1", { width: 0.2, height: distFromFloor, depth: 0.2 }, this.scene);
        const pole2 = pole1.clone('pole2');

        pole1.locallyTranslate(new BABYLON.Vector3(-1, distFromFloor / 2.0, 0));
        pole1.setParent(base);

        pole2.locallyTranslate(new BABYLON.Vector3(1, distFromFloor / 2.0, 0));
        pole2.setParent(base);

        const pole3 = BABYLON.MeshBuilder.CreateBox("Pole3", { width: 0.02, height: distFromFloor, depth: 0.02 }, this.scene);
        pole3.locallyTranslate(new BABYLON.Vector3(-0.5, distFromFloor / 2.0, 0.4));
        pole3.setParent(base);


        this.myContainerNode = base;

        // now create electronic display
        this.getChildByName("wt_controller")
            ?.setOptions({
                width: 0.15,
                height: 0.15,
                depth: 0.025,
                edgeRadius: 0.0,
                font: 20,
                displayHeight: 0.20,
                displayWidth: 0.25,
                portrudeDistance: 0.2,
                portrudeHeight: 0.01,
                displayLocation: new BABYLON.Vector3(0, 0.065, -0.085),
                location: new BABYLON.Vector3(-0.5, 0.6, 0.4),
                rotateY: Math.PI
            })
            .render();

        base.position = this.options.location;
        base.setParent(this.parent?.getContainerMesh() ?? null);
        this.snapObjectLocation = marker.getAbsolutePosition();



        /*
        let testTable = [
            {
                'a': 1,
                table: [
                    { b: 0, c: 1 },
                    { b: 1, c: 2 },
                ]
            },
            {
                'a': 2,
                table: [
                    { b: 0, c: 3 },
                    { b: 1, c: 4 },
                ]
            }
        ];

        test(1, 2);

        test(1, 0)
        test(1, 1)
        test(1, 0.5);
        test(2, 0);
        test(2, 1);
        test(2, 0.5);
        

        test(1.5, 0);
        test(1.5, 1);
        test(1.5, 0.5);



        function test(a, b) {
            let c = interpolate2DObjectTable(testTable, 'a', a, 'b', b, 'c');
            console.log('a', a, 'b', b, 'c', c);

        }
        */

        super.render();
        return this;
    }


    private setHandle(value: any) {

        this.wheelRotation.set(value, "Radians");
        const quaternion = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.Z, -value);
        if (this.wheelMesh)
            this.wheelMesh.rotationQuaternion = quaternion;

        if (this.connectedObject !== null && this.connectedObject?.myContainerNode) {
            this.connectedObject.myContainerNode.rotationQuaternion = quaternion;
        }
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "HANDLE":
                this.setHandle(value);
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "HANDLE":
                return this.wheelRotation.getDisplayValue();
        }

        super.getProperty(name);
    }

}