
  import { reactive, Ref, ref } from "@vue/reactivity";
  import FormRoot from "../../components/formGenerator/components/form-root.vue";
  import {
    LoginActionTypes,
    LoginButtonFooterType,
    LoginPageEvent,
    LoginPageParameterType,
  } from "./login/login.interface";
  import { signInAction, signInPage } from "./login/login-sign-in";
  import { submitPasswordResetConfirmationAction } from "./login/login-submit-password-reset";
  import {
    confirmUserAction,
    confirmUserPage,
    resendConfirmationAction,
  } from "./login/login-confirm-user";
  import { submitPasswordResetConfirmationPage } from "./login/login-submit-password-reset";
  import {
    requestPasswordResetAction,
    requestPasswordResetPage,
  } from "./login/login-request-password-reset";
  import {
    signUpAction,
    signUpSchema,
    signUpUserPage,
  } from "./login/login-sign-up";
  import { computed, EmitsOptions, SetupContext } from "@vue/runtime-core";
  import useLogin from "@/components/use/useLogin";
  import {
    FormButtonAction,
    FormErrorEvent,
  } from "@/components/formGenerator/form.interface";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import router from "@/router";

  export default {
    components: {
      FormRoot,
    },
    emits: ["error"],
    setup(props: Record<string, unknown>, context: SetupContext<EmitsOptions>) {
      const currentSchema = ref(signUpSchema);
      const isLoading = ref(false);
      const { isSignedIn } = useUserStore();
      const computedIsSignedIn = computed(() => isSignedIn);

      const enterKeyAction: Ref<FormButtonAction> = ref({
        triggerEvent: {
          type: LoginActionTypes.SIGN_IN_ACTION,
          payload: null,
        },
        label: "",
        class: "",
        isEnabled: true,
        enabledWhenFormIsValid: true,
      });

      const buttonFooter: Ref<LoginButtonFooterType> = ref({
        infoText: "",
        linkText: "",
        eventType: {
          originatingAction: "Login main page",
          type: LoginActionTypes.EMPTY,
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
          originatingAction: "",
          type: LoginActionTypes.EMPTY,
        },
      });

      const buttonFooter2: Ref<LoginButtonFooterType> = ref({
        infoText: "",
        linkText: "",
        eventType: {
          originatingAction: "Login main page",
          type: LoginActionTypes.EMPTY,
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
          originatingAction: "",
          type: LoginActionTypes.EMPTY,
        },
      });

      const displayBehavior = {
        displayHints: false,
        hasChanges: false,
        showJSONButton: false,
        rootTitle: "",
        buttonAlign: "bottom",
      };

      const buttonList = reactive([]);

      const { signIn, signOut } = useLogin();
      const errorMessage = ref("");

      const onError = (event: FormErrorEvent) => {
        console.debug("Login - Emit error ", event);
        context.emit("error", event);
      };

      const onPress = async (event: LoginPageEvent) => {
        console.debug("Login - event registred ", event);

        const loginParameters: LoginPageParameterType = {
          originationEvent: event,
          currentSchema,
          buttonFooter,
          buttonFooter2,
          buttonList,
          enterKeyAction,
          errorMessage,
          onPress,
          emitError: onError,
        };

        switch (event.type) {
          // sign in
          case LoginActionTypes.GOTO_SIGN_IN_PAGE: {
            signInPage(loginParameters);
            break;
          }
          case LoginActionTypes.SIGN_IN_ACTION:
            isLoading.value = true;
            await signInAction(loginParameters);
            isLoading.value = false;

            if (computedIsSignedIn) {
              router.push({ name: "StudentHome" });
            }
            break;

          case LoginActionTypes.SIGN_OUT_ACTION:
            signOut();
            break;

          // sign up
          case LoginActionTypes.GOTO_SIGN_UP_PAGE:
            signUpUserPage(loginParameters);
            break;
          case LoginActionTypes.SIGN_UP_ACTION:
            signUpAction(loginParameters);
            break;
          case LoginActionTypes.GOTO_USER_CONFIRM_PAGE:
            confirmUserPage(loginParameters);
            break;
          case LoginActionTypes.CONFIRM_USER_ACTION:
            confirmUserAction(loginParameters);
            break;
          case LoginActionTypes.RESEND_CONFIRMATION_ACTION:
            resendConfirmationAction(loginParameters);
            break;

          // password reset
          case LoginActionTypes.GOTO_RESET_PAGE:
            requestPasswordResetPage(loginParameters);
            break;
          case LoginActionTypes.REQUEST_RESET_PASSWORD_ACTION:
            requestPasswordResetAction(loginParameters);
            break;
          case LoginActionTypes.GOTO_RESET_PASSWORD_CONFIRMATION_PAGE:
            submitPasswordResetConfirmationPage(loginParameters);
            break;
          case LoginActionTypes.RESET_PASSWORD_SUBMIT_CONFIRMATION_ACTION:
            submitPasswordResetConfirmationAction(loginParameters);
            break;
        }
      };

      // initialize
      onPress({
        originatingAction: "Login main page",
        type: LoginActionTypes.GOTO_SIGN_IN_PAGE,
      });

      return {
        isLoading,
        signIn,
        signOut,
        onError,
        onPress,
        buttonList,
        currentSchema,
        buttonFooter,
        buttonFooter2,
        displayBehavior,
        errorMessage,
        enterKeyAction,
      };
    },
    methods: {
      onChange(): void {
        return;
      },
    },
  };
