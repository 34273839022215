
  export default {
    metaInfo() {
      return {
        title:
          "Vengla - Virtual Engineering Laboratories, Online Fluid Mechanics Laboratory, Remote Laboratories",
        meta: [
          {
            name: "description",
            content:
              "Virtual Engineering Labs are designed to engage university students in data collection, facilitate student interaction with engineering instrumentation, and " +
              " create explorable virtual environments.Professors can tailor laboratory materials for use in their course.",
          },
        ],
      };
    },
    components: {},
    setup(): unknown {
      return {};
    },
  };
