/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateQuestionSubmission = /* GraphQL */ `
  mutation UpdateQuestionSubmission(
    $courseID: String!
    $componentID: String!
    $questionID: String!
    $questionVersion: String!
    $questionPart: Int
    $entry: String
    $scoreInPercent: Float
    $percentOfQuestion: Float
  ) {
    updateQuestionSubmission(
      courseID: $courseID
      componentID: $componentID
      questionID: $questionID
      questionVersion: $questionVersion
      questionPart: $questionPart
      entry: $entry
      scoreInPercent: $scoreInPercent
      percentOfQuestion: $percentOfQuestion
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const updateSubmissionAttempt = /* GraphQL */ `
  mutation UpdateSubmissionAttempt(
    $courseID: String!
    $componentID: String!
    $questionID: String!
    $questionVersion: String!
    $questionPart: Int!
    $entry: String!
  ) {
    updateSubmissionAttempt(
      courseID: $courseID
      componentID: $componentID
      questionID: $questionID
      questionVersion: $questionVersion
      questionPart: $questionPart
      entry: $entry
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const updateAccomodation = /* GraphQL */ `
  mutation UpdateAccomodation(
    $courseID: String!
    $componentID: String!
    $userId: String!
    $accomodationType: String!
    $accomodationValue: String!
  ) {
    updateAccomodation(
      courseID: $courseID
      componentID: $componentID
      userId: $userId
      accomodationType: $accomodationType
      accomodationValue: $accomodationValue
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const overrideSubmissionGrade = /* GraphQL */ `
  mutation OverrideSubmissionGrade(
    $courseID: String!
    $componentID: String!
    $userID: String!
    $newGrade: String!
  ) {
    overrideSubmissionGrade(
      courseID: $courseID
      componentID: $componentID
      userID: $userID
      newGrade: $newGrade
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubmissionFromTable = /* GraphQL */ `
  mutation DeleteSubmissionFromTable(
    $courseID: String!
    $componentID: String!
    $questionID: String!
    $questionVersion: String!
  ) {
    deleteSubmissionFromTable(
      courseID: $courseID
      componentID: $componentID
      questionID: $questionID
      questionVersion: $questionVersion
    )
  }
`;
export const registerUserForCourse = /* GraphQL */ `
  mutation RegisterUserForCourse(
    $courseID: String!
    $userEmail: String!
    $accessCode: String
  ) {
    registerUserForCourse(
      courseID: $courseID
      userEmail: $userEmail
      accessCode: $accessCode
    ) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const createCodeLogTable = /* GraphQL */ `
  mutation CreateCodeLogTable(
    $input: CreateCodeLogTableInput!
    $condition: ModelCodeLogTableConditionInput
  ) {
    createCodeLogTable(input: $input, condition: $condition) {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCodeLogTable = /* GraphQL */ `
  mutation UpdateCodeLogTable(
    $input: UpdateCodeLogTableInput!
    $condition: ModelCodeLogTableConditionInput
  ) {
    updateCodeLogTable(input: $input, condition: $condition) {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCodeLogTable = /* GraphQL */ `
  mutation DeleteCodeLogTable(
    $input: DeleteCodeLogTableInput!
    $condition: ModelCodeLogTableConditionInput
  ) {
    deleteCodeLogTable(input: $input, condition: $condition) {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createUserPermissions = /* GraphQL */ `
  mutation CreateUserPermissions(
    $input: CreateUserPermissionsInput!
    $condition: ModelUserPermissionsConditionInput
  ) {
    createUserPermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPermissions = /* GraphQL */ `
  mutation UpdateUserPermissions(
    $input: UpdateUserPermissionsInput!
    $condition: ModelUserPermissionsConditionInput
  ) {
    updateUserPermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPermissions = /* GraphQL */ `
  mutation DeleteUserPermissions(
    $input: DeleteUserPermissionsInput!
    $condition: ModelUserPermissionsConditionInput
  ) {
    deleteUserPermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const createGroupFormation = /* GraphQL */ `
  mutation CreateGroupFormation(
    $input: CreateGroupFormationInput!
    $condition: ModelGroupFormationConditionInput
  ) {
    createGroupFormation(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupFormation = /* GraphQL */ `
  mutation UpdateGroupFormation(
    $input: UpdateGroupFormationInput!
    $condition: ModelGroupFormationConditionInput
  ) {
    updateGroupFormation(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupFormation = /* GraphQL */ `
  mutation DeleteGroupFormation(
    $input: DeleteGroupFormationInput!
    $condition: ModelGroupFormationConditionInput
  ) {
    deleteGroupFormation(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const createTextbook = /* GraphQL */ `
  mutation CreateTextbook(
    $input: CreateTextbookInput!
    $condition: ModelTextbookConditionInput
  ) {
    createTextbook(input: $input, condition: $condition) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateTextbook = /* GraphQL */ `
  mutation UpdateTextbook(
    $input: UpdateTextbookInput!
    $condition: ModelTextbookConditionInput
  ) {
    updateTextbook(input: $input, condition: $condition) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteTextbook = /* GraphQL */ `
  mutation DeleteTextbook(
    $input: DeleteTextbookInput!
    $condition: ModelTextbookConditionInput
  ) {
    deleteTextbook(input: $input, condition: $condition) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const createSubmissions = /* GraphQL */ `
  mutation CreateSubmissions(
    $input: CreateSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    createSubmissions(input: $input, condition: $condition) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const updateSubmissions = /* GraphQL */ `
  mutation UpdateSubmissions(
    $input: UpdateSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    updateSubmissions(input: $input, condition: $condition) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubmissions = /* GraphQL */ `
  mutation DeleteSubmissions(
    $input: DeleteSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    deleteSubmissions(input: $input, condition: $condition) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const createMarketingEmailLists = /* GraphQL */ `
  mutation CreateMarketingEmailLists(
    $input: CreateMarketingEmailListsInput!
    $condition: ModelMarketingEmailListsConditionInput
  ) {
    createMarketingEmailLists(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const updateMarketingEmailLists = /* GraphQL */ `
  mutation UpdateMarketingEmailLists(
    $input: UpdateMarketingEmailListsInput!
    $condition: ModelMarketingEmailListsConditionInput
  ) {
    updateMarketingEmailLists(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarketingEmailLists = /* GraphQL */ `
  mutation DeleteMarketingEmailLists(
    $input: DeleteMarketingEmailListsInput!
    $condition: ModelMarketingEmailListsConditionInput
  ) {
    deleteMarketingEmailLists(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const createSharePermissions = /* GraphQL */ `
  mutation CreateSharePermissions(
    $input: CreateSharePermissionsInput!
    $condition: ModelSharePermissionsConditionInput
  ) {
    createSharePermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSharePermissions = /* GraphQL */ `
  mutation UpdateSharePermissions(
    $input: UpdateSharePermissionsInput!
    $condition: ModelSharePermissionsConditionInput
  ) {
    updateSharePermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSharePermissions = /* GraphQL */ `
  mutation DeleteSharePermissions(
    $input: DeleteSharePermissionsInput!
    $condition: ModelSharePermissionsConditionInput
  ) {
    deleteSharePermissions(input: $input, condition: $condition) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTextRecognition = /* GraphQL */ `
  mutation CreateTextRecognition(
    $input: CreateTextRecognitionInput!
    $condition: ModelTextRecognitionConditionInput
  ) {
    createTextRecognition(input: $input, condition: $condition) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateTextRecognition = /* GraphQL */ `
  mutation UpdateTextRecognition(
    $input: UpdateTextRecognitionInput!
    $condition: ModelTextRecognitionConditionInput
  ) {
    updateTextRecognition(input: $input, condition: $condition) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteTextRecognition = /* GraphQL */ `
  mutation DeleteTextRecognition(
    $input: DeleteTextRecognitionInput!
    $condition: ModelTextRecognitionConditionInput
  ) {
    deleteTextRecognition(input: $input, condition: $condition) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
