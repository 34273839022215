import * as BABYLON from 'babylonjs';
import { Nullable } from 'babylonjs/types';
import { LinearMomentumStation } from '../experiments/LinearMomentum';
import { PipeLossStation } from "../experiments/PipeLoss";
import { ViscometryStation } from '../experiments/Viscometry';
import { WindTunnelstation } from '../experiments/WindTunnelStation';
import { RenderedObject } from "../primatives/BaseRenderedObject";
import { BaseLaboratory } from './BaseLaboratory';

export class CompleteLaboratory extends BaseLaboratory {

	constructor(name: string, scene: BABYLON.Scene, parent: Nullable<RenderedObject>) {
		super(name, scene, parent);

		// create experiments
		this.experiments.push(new PipeLossStation("Pipe Loss Experiment", this.scene, this));
		this.experiments.push(new ViscometryStation("Viscometry Station", this.scene, this));
		this.experiments.push(new WindTunnelstation("Wind Tunnel Station", this.scene, this));
		this.experiments.push(new LinearMomentumStation("Linear Momentum", this.scene, this));

		this.experiments.forEach((v) => {
			this.addChild(v);
		});

		scene.debugLayer.show();
	}

	public render() {
		const floorWidth = this.options.floorWidth ?? 0;
		const floorLength = this.options.floorLength ?? 0;

		this.setCamera(0, floorWidth / 2.0 - 2, 0, 1.5, floorWidth / 2.0);

		// set options on components and experiments here
		this.experiments[0]
			.setOptions({
				location: new BABYLON.Vector3(0, 1.5, floorWidth / 2.0),
				rotationY: 0
			});

		// set options on components and experiments here
		this.experiments[1].setOptions({
			location: new BABYLON.Vector3(floorLength / 2.0 - 0.5, 0.7, floorWidth / 2.0 - 0.2),
			rotationY: 0
		});

		// set options on components and experiments here
		this.experiments[2].setOptions({
			location: new BABYLON.Vector3(0.0, 0.0, - floorWidth / 2.0 + 0.5),
			rotationY: 0
		});
		//
		this.experiments[3].setOptions({
			location: new BABYLON.Vector3(-1.0, 0.9, floorWidth / 2.0 - 0.2),
			rotationY: 0
		});

		return super.render();
	}
}


