import { BCatalogEntry } from "../bootstrap/b-catalog.interface";


export enum FormValidationStates {
    dirty,
    valid,
    invalid
}
export enum FormErrorTypes {
    INVALID
}

export enum FormTypes {
    READONLY,
    TEXT,
    TEXT_BOX,
    DIVIDER,
    TIP_TAP,
    MARKDOWN_IT,
    NUMBER,
    INTEGER,
    EMAIL,
    PASSWORD,
    SEARCH,
    URL,
    FILE_LOCATION,
    TEL,
    DATE,
    TIME,
    DATE_TIME,
    RANGE,
    COLOR,
    ENUM,
    GROUP,
    ROW,
    NONE,
    CHECK_BOX,
    ARRAY_OF_TEXT,
    ARRAY_OF_FLOAT,
    ARRAY_OF_INT,
    ARRAY_OBJECTS,
    ARRAY_OBJECTS_EDITOR,
    ARRAY_OF_EMAIL,
    ARRAY_OF_COURSETOPICS,
    ARRAY_OF_QUESTIONS,
    SELECT,
    SELECT_ITEM_SUBITEM,
    SELECT_CATALOG,
    TAB
}

export const FormTypesMap = new Map<number, { componentName: string, inputType: string } | null>(
    [
        [FormTypes.READONLY, { componentName: "TextInput", inputType: 'text' }],
        [FormTypes.TEXT, { componentName: "TextInput", inputType: 'text' }],
        [FormTypes.TEXT_BOX, { componentName: "TextBox", inputType: 'text' }],
        [FormTypes.TIP_TAP, { componentName: "TipTap", inputType: 'text' }],
        [FormTypes.MARKDOWN_IT, { componentName: "MarkdownIt", inputType: 'text' }],
        [FormTypes.SELECT, { componentName: "SelectList", inputType: 'text' }],
        [FormTypes.SELECT_ITEM_SUBITEM, { componentName: "SelectItemSubItem", inputType: 'text' }],

        [FormTypes.ARRAY_OF_TEXT, { componentName: "ArrayInput", inputType: 'text' }],
        [FormTypes.ARRAY_OF_EMAIL, { componentName: "ArrayInput", inputType: 'email' }],
        [FormTypes.ARRAY_OF_FLOAT, { componentName: "ArrayInput", inputType: 'number' }],
        [FormTypes.ARRAY_OF_INT, { componentName: "ArrayInput", inputType: 'number' }],
        [FormTypes.ARRAY_OBJECTS, { componentName: "ArrayOfObjects", inputType: 'text' }],
        [FormTypes.ARRAY_OBJECTS_EDITOR, { componentName: "ObjectEditorInput", inputType: 'text' }],
        [FormTypes.ARRAY_OF_COURSETOPICS, { componentName: "SelectCourseTopic", inputType: 'text' }],
        [FormTypes.ARRAY_OF_QUESTIONS, { componentName: "SelectQuestion", inputType: 'text' }],

        [FormTypes.SELECT_CATALOG, { componentName: "SelectFromCatalog", inputType: 'text' }],

        [FormTypes.FILE_LOCATION, { componentName: "FormInputFile", inputType: 'url' }],

        [FormTypes.DIVIDER, { componentName: "DividerInput", inputType: 'text' }],
        [FormTypes.NUMBER, { componentName: "TextInput", inputType: 'number' }],
        [FormTypes.INTEGER, { componentName: "TextInput", inputType: 'number' }],
        [FormTypes.EMAIL, { componentName: "TextInput", inputType: 'email' }],
        [FormTypes.PASSWORD, { componentName: "TextInput", inputType: 'password' }],
        [FormTypes.SEARCH, { componentName: "TextInput", inputType: 'search' }],
        [FormTypes.URL, { componentName: "TextInput", inputType: 'url' }],
        [FormTypes.TEL, { componentName: "TextInput", inputType: 'tel' }],
        [FormTypes.DATE, { componentName: "TextInput", inputType: 'date' }],
        [FormTypes.DATE_TIME, { componentName: "DateTime", inputType: 'date' }],
        [FormTypes.TIME, { componentName: "TextInput", inputType: 'time' }],
        [FormTypes.RANGE, { componentName: "TextInput", inputType: 'range' }],
        [FormTypes.COLOR, { componentName: "TextInput", inputType: 'color' }],
        [FormTypes.CHECK_BOX, { componentName: "CheckBox", inputType: 'boolean' }],
        [FormTypes.ENUM, null],
        [FormTypes.GROUP, { componentName: "FormInputGroup", inputType: 'text' }],
        [FormTypes.ROW, { componentName: "FormInputRow", inputType: 'text' }],
        [FormTypes.TAB, { componentName: "FormTab", inputType: 'text' }],
        [FormTypes.NONE, null],
    ]);


export type ValidationMetaData = {
    fieldIndex: number,
    rowIndex: number
}

export type FormCustomValidationRule =
    (input: unknown, meta: ValidationMetaData | undefined) => { isValid: boolean, errorMessage: string | string[] };

export interface FormValidateRules {
    required: boolean,
    confirmed: unknown,
    min: number,
    max: number,
    min_value: number,
    max_value: number,
    numeric: boolean,
    integer: boolean,
    email: boolean,
    alpha: boolean,
    image: boolean,
    check_true: boolean,
    variable_name: boolean,
    physical_unit: boolean,
    custom: FormCustomValidationRule,
    has_lowercase: boolean,
    has_uppercase: boolean,
    has_symbol: boolean
    has_number: boolean
}

export interface FormCatalogInterface {
    getCatalogRecords: () => BCatalogEntry[],
    makeNewItemEntry: (item: BCatalogEntry) => { id: string }
}

export interface FormButton {
    title: string;
    emit: string;
}

export interface FormProperties {
    disabled?: boolean;
    hidden?: boolean;
    classes?: string;
    labelCols?: number;
    labelAlign?: string;
    labelSize?: string;
    hideLabel?: boolean;
    showJSON?: boolean;
    customButtons?: FormButton[];
    componentCols?: number;
    componentOffset?: number;
}

export interface FormTypeSelectorChoices {
    typeName: string;
    visibleFields: string[];
}

export type ValidatedState = { state: number, class: string, hints: string[] }
export type ValidationHooks = {
    onBeforeValidate: () => void;
    onAfterValidate: () => void;
}

export interface FormSchemaItem {
    name?: string;
    fieldType?: FormTypes;
    formGen?: { componentName: string, inputType: string } | null;
    label: string;
    field: string;
    placeHolder?: string | null;
    hint?: string;
    choices?: unknown[];
    value?: unknown;
    properties?: FormProperties;
    rules?: Partial<FormValidateRules>;
    typeSelectorInfo?: {
        label: string;
        default: string;
        choices: FormTypeSelectorChoices[];
    },
    children?: FormSchemaItem[];
    catalog?: FormCatalogInterface;
    autocomplete?: string;
    meta?: Record<string, unknown>;
    _internalID?: string;
    _isValid?: FormValidationStates;
    _validationHints?: string[];
    _rowValidation?: ValidatedState[];
    _itemValidation?: Record<string, ValidatedState>[];
}

/**
 * Deep copy the options onto the default default schema then append
 * the children which must be FormSchemaItems. This is typically 
 * used in a nested. 
 * @param defaultValues
 * @param options
 * @param children
 */
export function deepCombineSchemaSingleItem(defaultValues: FormSchemaItem, options: FormSchemaItem, children: FormSchemaItem[]): FormSchemaItem {
    const resProp = { ...defaultValues.properties, ...(('properties' in options) ? options.properties : {}) };
    const resRules = { ...defaultValues.rules, ...(('rules' in options) ? options.rules : {}) };
    const result = { ...defaultValues, ...options };
    result.properties = resProp;
    result.rules = resRules;
    result.children = children;
    return result;
}

