
  import BCatalog from '@/components/bootstrap/b-catalog.vue'
  import { defineComponent, EmitsOptions, reactive, ref, SetupContext, watch } from 'vue'
  import { formItemMixinProps } from '../mixins/form.mixins'
  import formInputArrayOfObjects from './form-input-arrayOfObjects.vue'

  export default defineComponent({
    name: "FormSelectCatalog",
    components: { formInputArrayOfObjects, BCatalog },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const emptyFunc = () => { return; }
      const showCatalog = ref(false);
      const catalogItemList = ref(props.schemaitem.catalog?.getCatalogRecords() || []);
      const filterItemList = ref(catalogItemList.value);
      const makeNewItemEntry = ref(props.schemaitem.catalog?.makeNewItemEntry || emptyFunc);

      const selectedItems = reactive((props.schemaitem.value as []).map((v) => {
        return makeNewItemEntry.value(v);
      }));

      const filterText = ref("");

      watch(filterText, () => {
        const searchTerm = filterText.value.toLowerCase();
        filterItemList.value = catalogItemList.value.filter((v) => {
          return v.name.toLowerCase().includes(searchTerm);
        });
      });

      const addFunction = () => {
        showCatalog.value = true;
        return;
      }

      const onChange = () => {
        context.emit("update:modelValue", selectedItems);
      }


      return {
        showCatalog,
        onChange,
        selectedItems,
        addFunction,
        makeNewItemEntry,
        filterText,
        filterItemList
      }
    }
  })
