import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { StandardMaterial } from 'babylonjs';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
import { Nullable } from 'babylonjs/types';

export interface PrimativeOptions {
    name?: string,
    scale?: number,
    location?: BABYLON.Vector3,
    rotateX?: number,
    rotateY?: number,
    rotateZ?: number
}

export interface PrimativeObjectGenerator {
    originalMesh: Nullable<BABYLON.Mesh>;
    scene: BABYLON.Scene;
    create(parent: BABYLON.Mesh | null, options: PrimativeOptions): void;
    addPreloadAssets(assetManager: BABYLON.AssetsManager): void;
}

export class BasePrimativeObjectGenerator implements PrimativeObjectGenerator {
    public name = "Base Object";
    public originalMesh: Nullable<BABYLON.Mesh> = null;
    public scene: BABYLON.Scene;
    public setMaterial: (mesh: BABYLON.Mesh) => void;

    public defaultPrimOptions: PrimativeOptions = {
        location: BABYLON.Vector3.Zero(),
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0
    }

    constructor(scene: BABYLON.Scene) {
        this.scene = scene;
        this.setMaterial = this.defaultMaterial;
    }

    public create(parent: BABYLON.Mesh,
        options: PrimativeOptions) {
        options = { ...this.defaultPrimOptions, ...options };
        if (this.originalMesh === null) return null;

        const newBase = this.originalMesh
            .createInstance(this.name + 'instance');
        //.clone()
        //.makeGeometryUnique();

        newBase.setEnabled(true);

        return this.adjustMesh(newBase, options, parent);

    }

    public addPreloadAssets(assetManager: unknown): void {
        return;
    }

    public defaultMaterial(mesh: BABYLON.Mesh) {
        mesh.material = MaterialRegistry.getCopper(this.scene as any) as any;
    }

    public async addPreloadAssetURL(assetManager: BABYLON.AssetsManager,
        url: string,
        gltfFile: string) {

        // pull url 
        const task = assetManager.addMeshTask("load " + gltfFile, "", url, gltfFile);

        task.onSuccess = (task) => {
            this.originalMesh = (task.loadedMeshes[0] as BABYLON.Mesh);
            this.originalMesh.scaling = BABYLON.Vector3.One().scale(0.001);
            this.setMaterial(this.originalMesh);
            this.originalMesh.setEnabled(false);
        }

        task.onError = (task) => {
            console.log("error executing " + task.name, task.errorObject);
        }
    }

    public getColor() {
        return MaterialRegistry.getCopper(this.scene as any);
    }

    public adjustMesh(copy: BABYLON.InstancedMesh | BABYLON.Mesh, options: PrimativeOptions, parent: BABYLON.Mesh) {
        if (parent !== null) {
            parent.addChild(copy);
            copy.setParent(parent);
        }
        copy.addRotation(options.rotateX ?? 0, 0, 0)
            .addRotation(0, options.rotateX ?? 0, 0)
            .addRotation(0, 0, options.rotateZ ?? 0);

        console.log(options.scale)

        if (options.scale && options.scale !== 1) {
            copy.scaling = BABYLON.Vector3.One().scale(0.001 * options.scale);
        }

        copy.position = options.location ?? BABYLON.Vector3.Zero();

        //copy.material = this.getColor() as any; 
        return copy;
    }

}