import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";


function saveFile(file: any, filename: string) {
   if ((window.navigator as any).msSaveOrOpenBlob) // IE10+
      (window.navigator as any).msSaveOrOpenBlob(file, filename);
   else { // Others
      const a = document.createElement("a"),
         url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
         document.body.removeChild(a);
         window.URL.revokeObjectURL(url);
      }, 0);
   }
}

// Function to download data to a file
export function downloadData(data: any[], filename: string, type: string) {
   const file = new Blob(data, { type: type });
   saveFile(file, filename);
}

export function downloadJSON(data: Record<string, unknown>[], filename: string, type: string) {
   const file = new Blob([JSON.stringify(data)], { type: type });
   saveFile(file, filename);
}

export function downloadArrayOfObjectsData(data: Record<string, unknown>[], filename: string, type: string) {
   const headers = Object.keys(data[0]);
   const processedData: any[] = [];
   processedData.push(headers)
   data.forEach((rowData) => {
      const row = headers.map((key) => {
         if (TypeGuard.hasProp(rowData, key) &&
            !TypeGuard.isNullOrUndefined(rowData[key])) {
            const dataItem = rowData[key];
            if (TypeGuard.isArray(dataItem))
               return JSON.stringify(dataItem);
            else
               return dataItem;
         } else {
            return "-"
         }

      }).join(",");
      processedData.push(row);
   })


   const file = new Blob([processedData.join("\n")], { type: type });
   saveFile(file, filename);
}