
  import router from "@/router";
  import { defineComponent } from "vue";
  import PageIterator from "../components/p-page-iterator.vue";
  import TextbookPageEditor from "./TextbookPageCreator.vue";
  import { bookEditorProps, pageEditorProps, usePageEditorFunctions } from "./usePageEditorFunctions";

  export default defineComponent({
    name: "TextbookEditor",
    components: { PageIterator, TextbookPageEditor },
    props: {
      ...bookEditorProps,
      ...pageEditorProps
    },
    async setup(props) {
      function routeTo(inputPageId: string) {
        router.push({
          name: "TextbookEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
            rootPageId: props.rootPageId,
          },
        });
      }

      return {
        ...usePageEditorFunctions(routeTo)
      }
    },
  });
