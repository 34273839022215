import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { WrappedRecord } from "./wrappedRecord";

export enum RecordTypes {
    COURSE,
    ASSIGNMENT,
    REGISTRATION,
    NOTEBOOK,
    BOOK,
    PAGE,
    TOC,
    TEXT_RECOGNITION
}

export interface BaseRecord {
    id: string
}

export interface GenericRecordCreatorParameters {
    objectType: RecordTypes;

    createQuery: string;
    updateQuery: string;
    deleteQuery: string;
}

export interface RecordCreatorParameters<T extends BaseRecord, U> extends GenericRecordCreatorParameters {
    defaultValues: T;

    onAfterCreatedModifyData: (data: T, createOptions: U, self: WrappedRecord<T, U>) => void;
    onBeforeSaveToDB: (data: T, self: WrappedRecord<T, U>) => T;
    onAfterRecordWrapFromDB: (data: T, self: WrappedRecord<T, U>) => void;
    getDeleteItemParameters: (data: T) => Record<string, unknown>;
}

export const recordCreatorParameterLibrary: Record<RecordTypes, GenericRecordCreatorParameters> = {} as Record<RecordTypes, GenericRecordCreatorParameters>;

export function setRecordCreator<T extends BaseRecord, U>(type: RecordTypes, creator: RecordCreatorParameters<T, U>) {
    recordCreatorParameterLibrary[type] = creator;
}

export function lookupRecordCreator<T extends BaseRecord, U>(type: RecordTypes): RecordCreatorParameters<T, U> {
    return recordCreatorParameterLibrary[type] as RecordCreatorParameters<T, U>;
}
