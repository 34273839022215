<template>
  <h4>Last revision made on July 27, 2020</h4>
  <h2>Rules for Conduct</h2>
  <p>
    It is prohibited to submit, upload, or create content the following on the Vengla Site:
    <ul>
      <li>content that defames, harrasses, or threatens others</li>
      <li>content that infringes on intellectual property</li>
      <li>profane, pornographic, obscene, indecent, or unlawful content</li>
      <li>content with the intent to mislead or defraud others</li>
    </ul>
  </p>
  <h2>Violations</h2>
  <p>
    If you are determined to be in Violation of the Terms of Service or Honor Code,
    you will be subject to some combination of the following:
    <ul>
      <li>Deletion of your account and all content you have created.</li>
      <li>Termination of your use of the Vengla site.</li>
      <li>Additinal action at the sole disretion of Vengla. </li>
    </ul>
  </p>
  <h2>Indemnification</h2>
  <p>
    You agree to hold harmless and indemnify Vengla and their officers, faculty, students, agents, and employees from and
    against any third-party claims, actions, or demands arising out of, resulting from or in any way related to your use of the Vengla Site,
    including any liability or expense arising from any and all claims, losses, damages (actual and consequential), suits, judgements, litigation
    costs, and attorneys' fees, of every kind and nature. In such a case, Vengla will provide you with written notice of such claim, suit, or action.
  </p>
  <h2>Termination Rights</h2>
  <p>
    You agree that Vengla, in its solde discretion, may terminate your account and further use of the Vengla Site, for any reason or no reason, upon notice to you.
  </p>
  <h2> Disclaimers of Warrent / Limitations of Liabilities</h2>
  <p>
    The Vengla site and any information, content, or services made available on or through the Vengla site are provided "as is" and "as available" without warrenty
    of any kind (epress, implied or otherwise), including, without limitation, any implied warrenties of merchantability, fitness for a particular purpose, and non-infringment,
    except insofar as any such implied warrenties may not be disclaimed under applicable law.

    Vengla does not warrent that the Vengla Site will operate in an uninterrupted or error-free manner, that the Vengla site is free of viruses or other
    harmful components, or that the content will meet your needs or expectations. Vengla also makes no warrenty about the accuracy, completeness,
    timeliness, or quality of the Vengla site or any content. Vengla also makes no warrenty that content will continue to be made available.

    Use of the Vengla Site, and the content obtained from or through the Vengla Site, is at your own risk. You will be solely responsible for any damage to your property (including your computer system) or loss of
    data that results from the download or use of such material or data.
  </p>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Terms",
    setup() {
      return {};
    },
  });
</script>

<style scoped>
</style>