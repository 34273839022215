export const katexGlobalOptions = {
   delimiters: [
      { left: "$$", right: "$$", display: true },
      { left: "$", right: "$", display: false }
   ],
   macros: {
      "\\par": "\\left(#1\\right)",
      "\\vecs": "{#1} \\hat{i} + #2 \\hat{j} + #3 \\hat{k} ",
      "\\vecp": "\\par{#1} \\hat{i} + \\par{#2} \\hat{j} + \\par{#3} \\hat{k} ",
      "\\pythag": "\\sqrt{ \\par{#1}^2 + \\par{#2}^2 }",
      "\\pythagtd": "\\sqrt{ \\par{#1}^2 + \\par{#2}^2 + \\par{#3}^2 }",
   }
}