
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      label: {
        type: String,
        default: "Loading ..."
      }
    },
    setup() {

      return {}
    }
  })
