import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import { Nullable } from 'babylonjs/types';
import { ConnectableObjectInterface, PortInternals } from "../../instruments/Electronic/CordConnector";
import { Fluid, Fluids } from '../../properties/Standard_Fluids';
import { BUtil } from '../../utils/BabylonUtils';
import { ScalarValue, _u } from '../../values/ScalarValue';
import { VariableTable } from '../../values/VariableTable';
import { AssetTaskTiedToScene, BaseRenderedObject, RenderedObjectTypes } from "../BaseRenderedObject";
import { MaterialRegistry } from "../Materials/MaterialRegistry";

interface TankOptions {
    location: BABYLON.Vector3,
    fluidHeight?: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    fluid: Fluids,
    size: {
        width: number,
        height: number,
        depth: number,
        wallThickness: number
    },
    temperature: ScalarValue,
    hasDrain: boolean,
    drainDiameter: number,
    drainHeight: number
}

export class ClearWaterTank extends BaseRenderedObject {
    public portMap: Map<string, PortInternals> = new Map();
    public static meshTemplate: Nullable<BABYLON.Mesh> = null;
    public static loadAssetTask: Nullable<AssetTaskTiedToScene> = null;
    public fluidMesh: Nullable<BABYLON.Mesh> = null;
    public fluid: Nullable<Fluid> = null;

    private options: TankOptions = {
        fluidHeight: 0.2,
        location: new BABYLON.Vector3(0, 0, 0),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        fluid: Fluids.WATER,
        size: {
            width: 0.2,
            height: 0.5,
            depth: 0.2,
            wallThickness: 0.01
        },
        hasDrain: false,
        drainDiameter: 0.0254,
        drainHeight: 0,
        temperature: _u(20, 'C')
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.CLEAR_WATER_TANK;
        this.portMap.set("0", { name: "0", value: undefined, connection: { isConnected: false, connectedPort: "", connectedObject: null } });
    }

    public setOptions(options: TankOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface) {
        const curPort = this.portMap.get(myPort);
        if (curPort)
            curPort.connection = { isConnected: true, connectedPort: connectPort, connectedObject: connectToObj };
    }

    public removeConnection(myPort: string) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.connectedObject?.removeConnection(tmpPort.connection.connectedPort);
            tmpPort.connection.isConnected = false;
        }
    }

    private setFluidColor() {
        //this.fluidMesh.material = MaterialRegistry.getFluid(this.scene, this.options.fluid);
        // Water material
        const waterMaterial = new Materials.WaterMaterial("waterMaterial", this.scene, new BABYLON.Vector2(100, 100));
        waterMaterial.bumpTexture = new BABYLON.Texture("//www.babylonjs.com/assets/waterbump.png", this.scene);
        waterMaterial.backFaceCulling = true;

        waterMaterial.windForce = 0.4;
        waterMaterial.waveHeight = 0;
        waterMaterial.bumpHeight = 0.5;
        waterMaterial.waveLength = 0.5;
        //waterMaterial.waveSpeed = 1.0;
        waterMaterial.waterColor = new BABYLON.Color3(0.1, 0.1, 0.7);
        waterMaterial.windDirection = new BABYLON.Vector2(1, 1);
        waterMaterial.colorBlendFactor = 0.6;
        //const room = this.scene.getMeshByName("Room");
        //waterMaterial.addToRenderList(room);
        const skybox = this.scene.getMeshByName("reflectionSkyBox");
        waterMaterial.addToRenderList(skybox);
        //waterMaterial.alpha = 0.99;

        if (this.fluidMesh)
            this.fluidMesh.material = waterMaterial;

    }

    public render() {

        const width = this.options.size.width;
        const height = this.options.size.height;
        const depth = this.options.size.depth;
        const wallThickness = this.options.size.wallThickness;
        const fluidHeight = this.getFluidHeight();

        const base = BUtil.Box("Base", width + wallThickness, wallThickness, depth, this.scene);
        const top = BUtil.cloneAndTranslate(base, base, "Top", 0, height, 0);
        const left = BUtil.Box("Left", wallThickness, height + wallThickness, depth, this.scene);
        BUtil.setParentAndTranslate(left, base, -width / 2.0, height / 2.0, 0);
        const right = BUtil.cloneAndTranslate(left, base, "Right", width, 0, 0);
        const back = BUtil.Box("Back", width + wallThickness, height + wallThickness, wallThickness, this.scene);
        BUtil.setParentAndTranslate(back, base, 0, height / 2.0, depth / 2.0);
        const front = BUtil.cloneAndTranslate(back, base, "Front", 0, 0, -depth);

        const glass = MaterialRegistry.getGlass(this.scene);
        top.material = glass;
        left.material = glass;
        right.material = glass;
        base.material = glass;
        back.material = glass;
        front.material = glass;

        this.fluidMesh = BUtil.Box("Fluid", width - wallThickness, 1, depth - wallThickness, this.scene);
        BUtil.setParentAndTranslate(this.fluidMesh, base, 0, (fluidHeight + wallThickness) / 2, 0);

        this.setFluidColor();

        // position and orient the valve
        base.position = this.options.location;
        base.setParent(this.parent?.getContainerMesh() ?? null);

        this.myContainerNode = base;

        // register hit response
        base.actionManager = new BABYLON.ActionManager(this.scene);

        super.render();

        return this;
    }

    public setTemperature(temp: ScalarValue) {
        this.options.temperature = temp;
    }

    public simulateHeating(surfTemp: number, dTinSeconds: number) {
        // Q = m c T
        // dTemp = dQ / m c
        const curTemp = this.options.temperature.in('C');
        const props = this.getFluidProps();
        const cp = props.getSpecificHeat().in('kJ/kg/K');
        const den = props.getDensity().in('kg/m3');
        const volume = 0;//this.getVolume().in('m3');
        const A = Math.pow(volume, 2 / 3);
        const k = 1; // kJ/m K
        const t = 0.002; // m

        // dQ/Dt= - k A / d * (curTemp - surfTemp)
        // dTemp = dQ/Dt * dT / (m c)
        const dQDt = -k * A / t * (curTemp - surfTemp);
        let dTemp = dQDt * dTinSeconds / (cp * volume * den);
        if (Math.abs(dTemp) > 100) { dTemp = surfTemp - curTemp; }
        this.options
            .temperature
            .setValue(curTemp + dTemp);
    }

    public setFluidHeight(newHeight: number) {
        const delta = (newHeight - this.getFluidHeight()) / (this.fluidMesh?.scaling.y ?? 1);
        this.options.fluidHeight = newHeight;
        this.fluidMesh?.locallyTranslate(BUtil.V3(0, delta / 2.0, 0));
    }

    public getFluidHeight() {
        return this.options.fluidHeight ?? 0;
    }

    public addFluidVolume(volume: number) {
        this.setFluidHeight(this.getFluidHeight() + volume / (this.options.size.width * this.options.size.depth));
    }

    public setFluid(val: Fluids) {
        this.options.fluid = val;
        this.options.temperature = _u(20, 'C');
        this.setFluidColor();
    }

    public getFluidType() { return this.options.fluid; }
    public getFluidProps() {
        if (this.fluid === null) {
            this.fluid = new Fluid(this.getFluidType(), this.options.temperature);
        }

        this.fluid.setType(this.getFluidType());
        this.fluid.setTemp(this.options.temperature);
        return this.fluid;
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "FLUID":
                this.setFluid(value);
                break;
            case "TEMPERATURE":
                this.setTemperature(value);
                break;
            case "FLUID_HEIGHT":
                this.setFluidHeight(value);
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "FLUID":
                return this.getFluidType();
            case "TEMPERATURE":
                return this.options.temperature;
            case "FLUID_HEIGHT":
                return this.getFluidHeight();
        }

        super.getProperty(name);
    }

    setObjectStateFromTable(variableTable: VariableTable) {
        if (this.options.hasDrain) {
            const deltaT = variableTable.get("DELTA_T")?.in('s') ?? 1;
            const A = Math.PI * Math.pow(this.options.drainDiameter / 2, 2);
            const Q = Math.sqrt(2 * 9.81 * (this.getFluidHeight() - this.options.drainHeight)) * A;

            if (this.getFluidHeight() > this.options.drainHeight) {
                this.addFluidVolume(-Q * deltaT);
            }
        }

        if (this.fluidMesh)
            this.fluidMesh.scaling.y = this.getFluidHeight();


        //this.fluidMesh.setPositionWithLocalVector(BUtil.V3(0, (this.getFluidHeight() + this.options.size.wallThickness), 0));
        super.setObjectStateFromTable(variableTable);
    }

}

