
  import { defineComponent, PropType } from "vue";
  import useComponents from "../../use/usePageComponents";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import ComponentViewer from "./ComponentViewer.vue";

  export default defineComponent({
    components: {
      ComponentViewer
    },
    props: {
      pageData: {
        type: Object as PropType<PageFromTextbookTable>,
        required: true,
      },
    },
    setup(props) {
      console.debug("Page Viewer Loading Content");

      const {
        getActiveComponentsFromPageData,
      } = useComponents();

      let content = getActiveComponentsFromPageData(props.pageData);


      return {
        content,
      };
    },
  });
