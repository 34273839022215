<template>
  <div class="container border p-3">
    <div class="row">
      <div class="col-8">
        <h1>
          <div class="icon-circle mb-5">
            <i class="bi bi-person-circle text-primary" />
            {{ fullName }}
          </div>
        </h1>
      </div>
      <div class="col-4">
        <button class="btn btn-danger float-end"
                @click="deleteAccount">
          Delete Account
        </button>
      </div>
    </div>
    <div v-for="(entry, index) in formEntries"
         :key="index"
         class="row mb-3">
      <label class="form-label col-sm-4 form-label">
        {{ entry.label }}
      </label>
      <div class="col">
        <input :value="entry.data"
               class="form-control"
               readonly>
      </div>
    </div>
    <BTable table-classes="table-bordered table-striped table-hover"
            thead-classes="table-dark"
            class="p-0"
            :is-scrollable="false"
            :data="registeredCourses"
            :fields="registeredCourseTableFields" />
  </div>
  <BConfirmDialog ref="confirmDialog" />
</template>

<script lang="ts">
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent, reactive, ref } from "vue";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import BTable from "@/components/bootstrap/table/b-table.vue";
  import BConfirmDialog from "@/components/bootstrap/b-confirm-dialog.vue";
  import { ConfirmDialogOptions } from "@/components/bootstrap/b-confirm-dialog.interface";
  import { bTableFields } from "@/components/bootstrap/table/b-table.interface";
  import useTime from "@/components/use/useTime";

  export default defineComponent({
    components: { BTable, BConfirmDialog },
    async setup() {
      const { userName, firstName, lastName, fullName, groups } = useUserStore();

      const formEntries = reactive([
        { label: "Email:", data: userName },
        { label: "First Name:", data: firstName },
        { label: "Last Name:", data: lastName },
        { label: "Groups:", data: groups },
      ]);

      const confirmDialog = ref<ConfirmDialogOptions | null>(null);
      const registeredCourses = await useDatabaseMappings().getRegisteredCoursesForUser(userName!);

      const { computeRelativeDateString } = useTime();
      const registeredCourseTableFields: Partial<bTableFields>[] = [
        {
          key: "course._data.name",
          freezeColumn: true,
          label: "Course",
          sortable: true,
          includeInFilter: true,
          class: "",
        },
        {
          key: "registration._data.reg_initDate",
          label: "Registration Date",
          class: "",
          formatter: (value: unknown) => {
            return computeRelativeDateString(value as string);
          },
        },
        {
          key: "registration._data.reg_expiration",
          label: "Expiration Date",
          class: "",
          formatter: (value: unknown) => {
            return computeRelativeDateString(value as string);
          },
        },
        {
          key: "registration._data.reg_role",
          label: "Role",
          class: "",
        },
        {
          key: "registration._data.id",
          label: "Registration ID",
          class: "",
        },
      ];

      const deleteAccount = () => {
        confirmDialog?.value
          ?.open(
            "Delete Account",
            "Clicking Ok will permentaly delete your account! Are you sure?",
            {}
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed)
              confirmDialog?.value
                ?.open(
                  "Delete Everything",
                  "Last chance! Are you sure you want to delete everything?",
                  {}
                )
                .then((secondConfirm: boolean) => {
                  console.log(isConfirmed, secondConfirm);
                });
          });
        return;
      };

      return {
        registeredCourses,
        registeredCourseTableFields,
        fullName,
        formEntries,
        deleteAccount,
        confirmDialog,
      };
    },
  });
</script>

<style scoped>
</style>