import * as BABYLON from 'babylonjs';
import { ControlResponseRequested } from "../../controls/Control_Base";
import { BaseRenderedObject, RenderedObjectTypes } from '../../primatives/BaseRenderedObject';
import { MaterialRegistry } from "../../primatives/Materials/MaterialRegistry";
import { _u } from "../../values/ScalarValue";
import { VariableTable } from "../../values/VariableTable";
import { InstrumentDisplayValue, NoiseCorrelation, NoiseType, RangeOverFlowOptions } from "../InstrumentDisplayValue";
import { ElectronicDevice, ElectronicDisplay, ElectronicStateMachine } from './BaseElectronicDevice';


export class ForceMeterElectronic extends ElectronicDevice {

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.FORCE_METER_ELECTRONIC;
        // textures
        this.faceTextureURL = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/DigitalForceGauge.png";

        // setup state machine
        this.stateMachine = new ElectronicStateMachine({
            states: {
                curUnit: 0,
                units: ["N", "kN", "dyne", "lbf"],
                isOnHold: false
            },
            computed: {
                getCurUnit: (sm) => { return sm.get('units')[sm.get('curUnit')]; }
            },
            actions: {
                hitPower: (sm) => { sm.togglePower(); },
                hitUnit: (sm) => { sm.incrementListSelection('curUnit', 'units'); },
                hitHoldButton: (sm) => { sm.toggle('isOnHold'); }
            }
        });

        // default options
        const height = 0.3 * 1.5;
        const width = 0.20 * 1.5;
        this.setOptions({
            width: width,
            height: height,
            depth: 0.05,
            edgeRadius: 0.02,
            fontSize: this.digitFont,
            displayHeight: height / 2.0,
            displayWidth: height / 2.0,
            displayLocation: new BABYLON.Vector3(0, height / 4.0 - 0.04, -0.001)
        });

        // button map
        this.addButtonMapItem("UNIT", [0.07, 0.23, 0.45, 0.38], 'hitUnit');
        this.addButtonMapItem("DIF", [0.07, 0.07, 0.45, 0.21], 'hitDiffButton');

        this.addButtonMapItem('POWER', [0.52, 0.23, 0.91, 0.38], 'hitPower');
        this.addButtonMapItem("HOLD", [0.52, 0.07, 0.91, 0.21], 'hitHoldButton');

        // setup ports
        // This is a two channel pressure meter
        const portNames = ["LEFT", "RIGHT"];

        portNames.forEach((v) => {
            this.portMap.set(v,
                {
                    name: v,
                    value: new InstrumentDisplayValue(0, "N",
                        {
                            minValue: _u(-100, "N"),
                            maxValue: _u(100, "N"),
                            overflow: RangeOverFlowOptions.CLIP
                        },
                        {
                            addNoise: true,
                            type: NoiseType.PerOfRange,
                            percent: 0.001,

                            correlation: NoiseCorrelation.SinVariationWithTime,
                            period: 0.1
                        }
                    ),
                    connection: {
                        isConnected: false,
                        connectedPort: "",
                        connectedObject: null
                    }
                });
        });

        // display function
        this.onDisplayUpdate = this.displayCallback;
        this.onParentCreated = this.createPortGraphics;
    }


    private displayVal1 = 0;
    private displayVal2 = 0;
    private displayCallback(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {

        const time = variableTable.get("TIME")?.in("s");

        const p1 = this.getValuesFromPort("LEFT", variableTable);
        const p2 = this.getValuesFromPort("RIGHT", variableTable);
        const desiredUnit = this.stateMachine?.getComputed('getCurUnit');

        const isOnHold = this.stateMachine?.get('isOnHold');

        this.displayVal1 = isOnHold ? this.displayVal1 : p1.getDisplayValueIn(desiredUnit, time);

        display.drawText("CH1", 0, 20, this.infoFont);
        display.drawLeadingNegValue(this.displayVal1, "", 4, 40, 60, 45, this.infoFont);

        this.displayVal2 = isOnHold ? this.displayVal2 : p2.getDisplayValueIn(desiredUnit, time);
        display.drawText("CH2", 0, 70, this.infoFont);
        display.drawLeadingNegValue(this.displayVal2, "", 4, 40, 60, 100, this.infoFont);

        display.drawText(desiredUnit, 0, 120, this.infoFont);

        if (isOnHold) {
            display.drawText("HOLD", 85, 10, this.infoFont2);
        }
    }

    private getValuesFromPort(portName: string, variableTable: VariableTable) {
        let actualValue = _u(0, "N");
        if (this.portMap.get(portName)?.connection?.isConnected) {
            actualValue = variableTable.get(this.getPressureVariableName(portName)) ?? actualValue;
        }

        this.portMap.get(portName)?.value?.copy(actualValue);
        return this.portMap.get(portName)?.value as InstrumentDisplayValue;
    }

    public getPressureVariableName(port: string): string {
        const cObj = this.portMap.get(port)?.connection;
        let pvn = (cObj?.connectedObject as unknown as BaseRenderedObject).getName();
        pvn += this.portMap.get(port)?.connection?.connectedPort;
        return pvn;
    }

    public createPortGraphics() {
        // create two port connections on the top of the instrument
        const portConnector_1 = BABYLON.MeshBuilder.CreateCylinder("LEFT_PORT", {
            height: 0.06,
            diameter: 0.05,
            tessellation: 5
        }, this.scene);

        portConnector_1.material = MaterialRegistry.getSilver(this.scene);
        portConnector_1.position.x = -this.options.width / 3.0;
        portConnector_1.position.y = this.options.height / 2.0 + 0.02;
        portConnector_1.position.z = this.options.depth / 2.0;
        portConnector_1.setParent(this.getContainerMesh());

        const portConnector_2 = portConnector_1.clone("RIGHT_PORT");
        portConnector_2.position.x = this.options.width / 3.0;
        //portConnector_2.position.y = this.options.height / 2.0;
        //portConnector_2.position.z = 0;
        portConnector_2.setParent(this.getContainerMesh());

        this.setupHitActions(portConnector_1, "LEFT");
        this.setupHitActions(portConnector_2, "RIGHT");
    }


    private setupHitActions(portMesh: BABYLON.Mesh, portTag: string) {
        // register hit response
        portMesh.actionManager = new BABYLON.ActionManager(this.scene);
        portMesh.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    portMesh.material = MaterialRegistry.getSilver(this.scene);
                }));

        portMesh.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    portMesh.material = MaterialRegistry.getHighlight(this.scene);
                }));

        portMesh.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    if (!this.portMap.get(portTag)?.connection?.isConnected) {
                        this.getControlPanel()
                            .processHit(ControlResponseRequested.FORCE_CONNNECTION,
                                {
                                    controlParams: {}
                                },
                                this,
                                { port: this.portMap.get(portTag) });
                    }

                }));

    }
}