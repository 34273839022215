<template>
  <div>
    <FormRoot :key="formKey"
              :is-loading="isLoading"
              :schemaitem="schema"
              :button-list="buttonList"
              :triggers="triggers"
              :displaybehavior="displaybehavior"
              @change="onChange"
              @btnPress="onBtnPress" />
  </div>
</template>

<script lang="ts">
  import { BCatalogEntry } from '@/components/bootstrap/b-catalog.interface';
  import useCourseMaterials from '@/store/connectors/useCourseMaterials';
  import { defineComponent, EmitsOptions, Ref, ref, SetupContext } from 'vue'
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import useReactiveForm from '@/components/use/useReactiveForm';
  import { getQuizComponentSchema } from './QuizComponent.schema';
  import { FormCatalogInterface } from '@/components/formGenerator/form-schema.interface';
  import useSchema from '@/components/use/useSchema';
  import { componentEditorProps } from './useComponentEditor';

  export default defineComponent({
    components: {
      FormRoot
    },
    props: {
      ...componentEditorProps,
      courseId: {
        type: String,
        required: true
      }
    },
    emits: ["change", "update:component"],
    async setup(props, context: SetupContext<EmitsOptions>) {
      const selectedQuestions = ref(props.component?.data.array);
      const { getQuestionBank } = useCourseMaterials();
      const questionBank = await getQuestionBank(props.courseId);

      const questionRecords: Ref<BCatalogEntry[]> = ref([]);
      const questionBankID: Ref<string> = ref("");

      const activeIndex = ref(0);

      if (questionBank) {
        questionRecords.value = questionBank.getPageList().map(v => {
          return {
            id: v.data().id,
            name: v.data().name || "",
            url: "content/" + questionBank.getID() + '/thumbnails/' + v.data().id + '.jpg'
          }
        });

        questionBankID.value = questionBank.getID() || "";
      }

      const findQuestionName = (questionId: string) => {
        return questionRecords.value.find((v) => { return v.id === questionId })?.name;
      }

      const selectedList = ref([{ id: "test", points: 2, questionBankID: "sjdfksf" }]);

      const catalogOptions: FormCatalogInterface = {
        getCatalogRecords: () => { return questionRecords.value; },
        makeNewItemEntry: (item: BCatalogEntry) => {
          return {
            id: item.id,
            name: findQuestionName(item.id),
            questionBankID,
            points: -1,
            maxAttempts: -1
          }
        }
      }

      // --------------- form ------------
      const {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
      } = useReactiveForm(getQuizComponentSchema(catalogOptions),
        { data: { ...props.component?.data } }); //
      displaybehavior.showJSONButton = false;

      const onBtnPress = (event: string) => {
        console.log(event)
      }

      const { schema_getJSON } = useSchema();

      const onChange = () => {
        const jsonData = schema_getJSON(schema.value);
        const newCompData = { ...props.component, ...jsonData };
        console.log(newCompData)
        context.emit("update:component", newCompData);
        context.emit("change");
      }

      return {
        onChange,
        buttonList: [],
        onBtnPress,
        activeIndex,
        selectedList,
        selectedQuestions,
        questionRecords,
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
        findQuestionName
      };
    },
  })
</script>

<style scoped>
</style>