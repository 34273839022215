
  import BTab from "@/components/bootstrap/b-tab.vue";
  import BTabs from "@/components/bootstrap/b-tabs.vue";
  import { defineComponent } from "vue";
  import PrivacyStatement from "./PrivacyStatement.vue";
  import TermsAndConditions from "./TermsAndConditions.vue";

  export default defineComponent({
    components: { PrivacyStatement, TermsAndConditions, BTabs, BTab },
    setup() {
      console.debug("tabs created");
      return {};
    },
  });
  PrivacyStatement;
