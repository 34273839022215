<template>
  <div :id="mid" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <button type="button"
                  :class="'btn ' + closeButtonClass + ' modalClose'"
                  data-bs-dismiss="modal"
                  @click="$emit('btnPress', 'CLOSE')">
            {{ closeButtonText }}
          </button>
          <button type="button"
                  :class="'btn ' + okButtonClass + ' modalOK'"
                  @click="$emit('btnPress', 'OK')">
            {{ okButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { v4 as uuid4 } from "uuid";
  export default {
    name: "BModal",
    props: {
      mid: {
        type: String,
        default: uuid4(),
      },
      title: {
        type: String,
        default: "",
      },
      okButtonText: {
        type: String,
        default: "Ok",
      },
      okButtonClass: {
        type: String,
        default: "btn-primary",
      },
      closeButtonText: {
        type: String,
        default: "Close",
      },
      closeButtonClass: {
        type: String,
        default: "btn-secondary",
      },
    },
    emits: ["btnPress"],
  };
</script>
<style lang="">
</style>