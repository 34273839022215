import { FieldType } from "../../../API";
import { createCourse, deleteCourse, updateCourse } from '../../../graphql/mutations';
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { DatabaseUtility } from "../databaseUtility";
import { WrappedRecord } from "../wrappedRecord";
import { RegistrationFromCourseTable } from "./table-course.interface";
import { getDefaultBaseCourseValues } from "./table-course-utils";

export interface RegistrationCreationOptioins {
    empty?: string;
}

const registrationRecordCreator: RecordCreatorParameters<RegistrationFromCourseTable, RegistrationCreationOptioins> = {
    defaultValues: {
        ...getDefaultBaseCourseValues(),
        reg_initDate: DatabaseUtility.getCurrentDate(),
        reg_expiration: DatabaseUtility.getCurrentDate(),
        reg_receipt: {
            registeredOn: DatabaseUtility.getCurrentDate(),
            amountPaid: 0,
            cost: 0,
            discountApplied: 0,
            hasPaid: false,
            transactionID: "",
            wasCharged: false,
            wasFree: false
        },
        reg_userID: ""
    },
    objectType: RecordTypes.REGISTRATION,
    createQuery: createCourse,
    updateQuery: updateCourse,
    deleteQuery: deleteCourse,

    getDeleteItemParameters: function (data: RegistrationFromCourseTable) {
        return {
            id: data.id,
            courseID: data.courseID,
            userEmail: data.userEmail,
            fieldType: data.fieldType
        }
    },

    onAfterCreatedModifyData: function (data: RegistrationFromCourseTable,
        createOptions: RegistrationCreationOptioins,
        myRecord: RegistrationRecord) {
        myRecord.data().fieldType = FieldType.REGISTRATION;
    },

    onBeforeSaveToDB: function (data: RegistrationFromCourseTable, myRecord: RegistrationRecord) {
        return data;
    },

    onAfterRecordWrapFromDB: function (data: RegistrationFromCourseTable, myRecord: RegistrationRecord) {
        return;
    }
}

setRecordCreator(RecordTypes.REGISTRATION, registrationRecordCreator);


/**
 * Class used to hold, update, and create database
 * Registration items.
 * */
export class RegistrationRecord extends WrappedRecord<RegistrationFromCourseTable, RegistrationCreationOptioins> {
    constructor() {
        super(RecordTypes.REGISTRATION)
    }

    static loadFromJSON(data: Record<string, unknown>): RegistrationRecord {
        const newReg = new RegistrationRecord();
        Object.assign(newReg, data);
        return newReg;
    }
}
