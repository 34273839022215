
  import {
    bNavbarDropdownPropTypes,
    bNavbarDropdownType,
  } from "@/components/bootstrap/b-navbar.interface";
  import { ExtendedMeta } from "@/router";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { computed, defineComponent, reactive, Ref, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import BNavbarDropDown from "../../components/bootstrap/b-navbar-dropdown.vue";
  import BNavbarHorizontal from "../../components/bootstrap/b-navbar-horizontal.vue";

  export default defineComponent({
    components: {
      BNavbarDropDown,
      BNavbarHorizontal,
    },
    setup() {
      const route = useRoute();
      const routeMeta: Ref<ExtendedMeta["breadCrumbs"]> = ref({ icon: "", includeCourse: false });
      watch(() => route.meta.breadCrumbs,
        () => { routeMeta.value = (route.meta as ExtendedMeta).breadCrumbs; });

      const getCourseLink = () => {
        const breadCrumbs = [{
          type: bNavbarDropdownType.LINK,
          to: "/course/" + route.params.courseId,
          iconName: "bi-bookmark-fill",
          label: "Course",
          class: "border-start border-end"
        }];

        if (route.path && routeMeta.value.icon) {
          breadCrumbs.push({
            type: bNavbarDropdownType.LINK,
            to: route.path,
            iconName: routeMeta.value.icon,
            label: "",
            class: "border-start border-end"
          })
        }

        return breadCrumbs;
      };

      const userStore = useUserStore();
      const isAdmin = computed(() => userStore.isAdmin);
      const isSignedIn = computed(() => userStore.isSignedIn);
      const userName = computed(() => userStore.userName);

      console.debug("signed in:", isSignedIn.value);

      const horizontalOpenItems: Array<bNavbarDropdownPropTypes> = reactive([
        {
          type: bNavbarDropdownType.LINK,
          label: "Vengla",
          to: "/",
          iconName: "",
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Contact Us",
          to: "/contact",
          iconName: "",
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Terms & Privacy",
          to: "/terms",
          iconName: "",
        },
      ]);

      const horizontalRestrictedItems: Array<bNavbarDropdownPropTypes> = reactive(
        [
          {
            type: bNavbarDropdownType.LINK,
            to: "/home",
            iconName: "bi-house-fill",
          },
        ]
      );


      const dropdownStyle = {
        label: "",
        iconName: "bi-gear-fill",
        rightSideDrop: true,
      };

      const dropdownItems: Array<bNavbarDropdownPropTypes> = reactive([
        {
          type: bNavbarDropdownType.TEXT,
          label: userName,
        },
        {
          type: bNavbarDropdownType.DIVIDER,
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Admin Page",
          to: "/AdminUserData",
          iconName: "bi-grid-1x2-fill",
          restrictToAdmin: true
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Hadnwriting Training",
          to: "/HandwritingTraining",
          iconName: "bi-grid-1x2-fill",
          restrictToAdmin: true
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Profile",
          to: "/Profile",
          iconName: "bi-person-fill",
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Contact Us",
          to: "/Contact",
          iconName: "bi-envelope-fill",
        },
        {
          type: bNavbarDropdownType.DIVIDER,
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Terms and privacy Statement",
          to: "/Terms",
          iconName: "",
        },
        {
          type: bNavbarDropdownType.DIVIDER,
        },
        {
          type: bNavbarDropdownType.LINK,
          label: "Sign Out",
          to: "/Logout",
          iconName: "",
        },
      ]);


      return {
        userName,
        isAdmin,
        isSignedIn,
        horizontalOpenItems,
        horizontalRestrictedItems,
        dropdownItems,
        dropdownStyle,
        routeMeta,
        getCourseLink
      };
    },
  });
