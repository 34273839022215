import * as BABYLON from 'babylonjs';

import { InstrumentDisplayValue, RangeOverFlowOptions } from "../../instruments/InstrumentDisplayValue";
import { _u } from "../../values/ScalarValue";
import { VariableTable } from "../../values/VariableTable";
import { BaseRenderedObject, RenderedObjectTypes, AssetTaskTiedToScene } from "../BaseRenderedObject";
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { ControlResponseRequested } from "../../controls/Control_Base";
import { Nullable } from 'babylonjs/types';

interface BallValueOptions {
    location: BABYLON.Vector3,
    pipeDiameter?: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number
}

export class BallValve extends BaseRenderedObject {
    public static valveTube_template: Nullable<BABYLON.Mesh> = null;
    public static handle_template: Nullable<BABYLON.Mesh> = null;
    public static loadAssetTask: Nullable<AssetTaskTiedToScene> = null;
    public static loadValveAssetTask: Nullable<AssetTaskTiedToScene> = null;
    public static handleMaterial: BABYLON.Material;

    private handleMesh: Nullable<BABYLON.Mesh> = null;
    private handlePosition: InstrumentDisplayValue;

    private options: BallValueOptions = {
        pipeDiameter: 0.0254,
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.PIPE_BALL_VALVE;
        this.handlePosition = new InstrumentDisplayValue(0, "RADIANS",
            {
                minValue: _u(0, "Radians"),
                maxValue: _u(Math.PI / 2.0, "Radians"),
                overflow: RangeOverFlowOptions.CLIP
            },
            { addNoise: false }
        );
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.preloadAssetWorker(
            assetManager,
            BallValve.loadAssetTask,
            "Load Valve handle",
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/pipes/",
            "ValveHandle.obj",
            (task) => {
                BallValve.handle_template = (task.loadedMeshes[0] as BABYLON.Mesh);
                BallValve.handle_template.scaling = BABYLON.Vector3.One().scale(0.001);

                BallValve.handleMaterial = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Red());
                BallValve.handle_template.material = BallValve.handleMaterial;
                BallValve.handle_template.setEnabled(false);
            }
        );

        this.preloadAssetWorker(
            assetManager,
            BallValve.loadValveAssetTask,
            "Load Valve Base",
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/pipes/",
            "ValveBase.obj",
            (task) => {
                BallValve.valveTube_template = (task.loadedMeshes[0] as BABYLON.Mesh);
                BallValve.valveTube_template.scaling = BABYLON.Vector3.One().scale(0.001);
                BallValve.valveTube_template.material = MaterialRegistry.getCopper(this.scene);
                BallValve.valveTube_template.setEnabled(false);
            }
        );

        super.addPreloadAssets(assetManager);
    }

    public setOptions(options: BallValueOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public render() {
        if (BallValve.valveTube_template == null)
            return super.render();

        const newBase = BallValve.valveTube_template
            //.createInstance(this.name + 'instance')
            .clone(this.name + 'base', null, false, true);
        //newBase.makeGeometryUnique();
        newBase.setEnabled(true);
        this.myContainerNode = newBase as BABYLON.Mesh;


        const newHandle = BallValve.handle_template
            ?.clone(this.name + 'handle', null, false, true);

        if (!newHandle)
            return super.render();

        //newBase.getChildMeshes(true)[1];
        newHandle.setEnabled(true);

        newHandle.metadata = { "ValveObject": this };
        //newHandle.position = this.options.location;
        newBase.addChild(newHandle);//this.parent.getContainerMesh();

        // position and orient the valve
        newBase.addRotation(this.options.rotateX, this.options.rotateY, this.options.rotateZ);
        newBase.position = this.options.location;
        newBase.parent = this.parent?.getContainerMesh() ?? null;

        this.handleMesh = <BABYLON.Mesh>newHandle;

        // register hit response
        newHandle.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        newHandle.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newHandle.material = BallValve.handleMaterial;
                }));

        newHandle.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newHandle.material = MaterialRegistry.getHighlight(this.scene);
                }));

        newHandle.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    this.getControlPanel()
                        .processHit(ControlResponseRequested.OPEN_VALVE_SLIDER,
                            {
                                controlParams: {
                                    title: "Open Valve",
                                    sliderTitle: "Angle",
                                    sliderUnit: "degs",
                                    min: 0,
                                    max: Math.PI / 2.0
                                }
                            },
                            this,
                            { properties: ["HANDLE"] });
                }));

        this.setHandle(0);
        return super.render();
    }

    private setHandle(value: any) {
        this.handlePosition.set(value, "Radians");
        const quaternion = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.Z, (value - Math.PI / 2.0));
        if (this.handleMesh)
            this.handleMesh.rotationQuaternion = quaternion;
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "HANDLE":
                this.setHandle(value);
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "HANDLE":
                return this.handlePosition.getDisplayValue();
        }

        super.getProperty(name);
    }

    setTableFromObjectState(variableTable: VariableTable) {
        variableTable.set(this.name, this.handlePosition.getRatioOfRange(), "");
        super.setTableFromObjectState(variableTable);
    }
}

