<template>
  <nav>
    <div id="nav-tab"
         class="nav nav-tabs"
         role="tablist">
      <button v-for="(field, index) in schemaitem.children"
              type="button"
              :key="'l' + index"
              class="nav-link"
              :class="{active: index === 0}"
              data-bs-toggle="tab"
              :data-bs-target="'#nav-tab-'+index">
        {{ field.label }}
      </button>
    </div>
  </nav>
  <div class="tab-content">
    <div v-for="(field, index) in schemaitem.children"
         :id="'nav-tab-' + index"
         :key="index"
         class="tab-pane fade show"
         :class="{active: index === 0}">
      <FormInputGroup v-model:modelValue="schemaitem.children[index].value"
                      :form-global-options="formGlobalOptions"
                      :schemaitem="schemaitem.children[index]"
                      :displaybehavior="displaybehavior"
                      :triggers="triggers"
                      @update:modelValue="onChange($event)" />
    </div>
  </div>
</template> 

<script lang='ts'>
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, SetupContext } from "vue";
  import FormInputGroup from "./form-input-group.vue";

  export default defineComponent({
    name: "FormTab",
    components: {
      FormInputGroup
    },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const onChange = (data: unknown) => {
        context.emit("change");
      }

      return {
        onChange
      };
    }
  });
</script>

<style lang="">
</style>