
import { UserPermissions } from "@/API";
import { TextbookObject } from "@/components/contentGenerator/textbook-object";
import { CourseRecord } from "../database/Course/record-course";
import { RegistrationRecord } from "../database/Course/record-registration";
import { CourseTableSearchResult } from "../database/Course/table-course-search";
import { CourseFromCourseTable, CourseTableSearchTypes } from "../database/Course/table-course.interface";
import { TextbookSearchResults } from "../database/Textbook/table-textbook-search";
import { PageFromTextbookTable, TextbookTableSearchTypes } from "../database/Textbook/table-textbook.interface";
import { LocalStorageClass } from "../local/localStorage";
import { dynamicPullData } from "./dynamicPullData";
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { getBatchOfUserProfiles, getTextbook } from "@/graphql/queries";
import { CharRecord } from "../database/TextRecognition/record-char";
import { TextRecognitionSearchTypes } from "../database/TextRecognition/table-textrecognition.interface";
import { TextRecognitionSearchResults } from "../database/TextRecognition/table-textrecognition-search";

const namespace = "Vengla-DB";
const lsc = new LocalStorageClass().config(namespace);

export interface CourseRegistraitonDetail {
    registration: RegistrationRecord,
    course?: CourseRecord,
    role?: string
}


export interface CourseMaterials {
    error?: string;
    registration?: RegistrationRecord;
    course?: CourseRecord;
    assignmentBook?: TextbookObject;
    textbook?: TextbookObject;
    laboratory?: TextbookObject;
    questionBank?: TextbookObject;
    //notebook?: Textbook;
}

//TODO add clear cache method. 
export function useDatabaseMappings() {
    const getSinglePage = async (textID: string, fieldType: string, pageID: string) => {
        const result = await GraphQLAPI.graphql(graphqlOperation(getTextbook,
            {
                textID,
                fieldType,
                id: pageID
            })) as { data: { getTextbook: PageFromTextbookTable } };

        return result.data.getTextbook;
    }


    const getBook = async (bookID: string | undefined, forcePullFromDB = false) => {
        if (bookID === undefined) {
            return [];
        }

        return await dynamicPullData(namespace,
            `Book-${bookID}`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const ctsr = new TextbookSearchResults(bookID, "")
                    const result = await ctsr
                        .runSearch(TextbookTableSearchTypes.ASSIGNMENTBOOK_BY_ID) as TextbookSearchResults;
                    console.debug("Book Search Result PULL:", result);

                    return { textbook: result.textbooks[0], tableOfContents: result.toc[0], pages: result.pages };
                },
                reconstructObjects(data: Record<string, unknown>): TextbookObject {
                    const { textbook, tableOfContents, pages } = data as {
                        textbook: Record<string, unknown>,
                        tableOfContents: Record<string, unknown>,
                        pages: Record<string, unknown>[]
                    };

                    const assmebledBook = TextbookObject.loadFromJSON(textbook, tableOfContents, pages);
                    console.debug("Book Search Result STORAGE:", assmebledBook);

                    return assmebledBook;
                },
                storage: lsc,
                forcePullFromDB
            }) as TextbookObject;
    }


    const getRegistrationsByUser = async (userName: string): Promise<RegistrationRecord[]> => {
        return await dynamicPullData<RegistrationRecord>(namespace,
            `RegistrationByUser-${userName}`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const ctsr = new CourseTableSearchResult("", userName)
                    const result = await ctsr
                        .runSearch(CourseTableSearchTypes.REGISTRATIONS_BY_USER) as CourseTableSearchResult;
                    return result.getAllRegistrations();
                },
                reconstructObjects(data: Record<string, unknown>): RegistrationRecord {
                    return RegistrationRecord.loadFromJSON(data);
                },
                storage: lsc,
                forcePullFromDB: true
            }) as RegistrationRecord[];
    }


    const getRegistrationForCourse = async (courseId: string): Promise<RegistrationRecord[]> => {
        return await dynamicPullData<RegistrationRecord>(namespace,
            `RegistrationByCourse-${courseId}`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const ctsr = new CourseTableSearchResult(courseId, "");
                    const result = await ctsr
                        .runSearch(CourseTableSearchTypes.REGISTRATIONS_BY_COURSE) as CourseTableSearchResult;
                    return result.getAllRegistrations();
                },
                reconstructObjects(data: Record<string, unknown>): RegistrationRecord {
                    return RegistrationRecord.loadFromJSON(data);
                },
                storage: lsc,
                forcePullFromDB: true
            }) as RegistrationRecord[];
    }

    const getUserProfilesForCourse = async (courseId: string) => {
        const registrations = await getRegistrationForCourse(courseId);
        const userEmails = registrations.map((r) => r.data().userEmail);
        const userIDs = registrations.map((r) => r.data().reg_userID);

        return await dynamicPullData<UserPermissions>(namespace,
            `UserProfiles-${courseId}`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const result = await GraphQLAPI.graphql(graphqlOperation(getBatchOfUserProfiles,
                        {
                            userEmails,
                            userIDs
                        })) as { data: { getBatchOfUserProfiles: [UserPermissions] } };

                    return result.data.getBatchOfUserProfiles;
                },
                reconstructObjects(data: Record<string, unknown>): UserPermissions {
                    return data as UserPermissions;
                },
                storage: lsc,
                forcePullFromDB: true
            }) as UserPermissions[];
    }

    const getAllHandwrittenChars = async (mode: TextRecognitionSearchTypes): Promise<CharRecord[]> => {
        return await dynamicPullData<CharRecord>(namespace,
            `Available-Chars-${mode}`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const ctsr = new TextRecognitionSearchResults()
                    const result = await ctsr
                        .runSearch(mode) as TextRecognitionSearchResults;
                    return (TextRecognitionSearchTypes.BY_EQN === mode) ? result.getAllEqns_2() : result.getAllChars();
                },
                reconstructObjects(data: Record<string, unknown>): CharRecord {
                    return CharRecord.loadFromJSON(data);
                },
                storage: lsc,
                forcePullFromDB: false
            }) as CharRecord[];
    }


    const getAvailableCourseList = async (): Promise<CourseRecord[]> => {
        return await dynamicPullData<CourseRecord>(namespace,
            `AvailableCourses`,
            {
                creationDate: -1,
                expirationDate: -1,
                pullFromDB: async () => {
                    const ctsr = new CourseTableSearchResult("", "")
                    const result = await ctsr
                        .runSearch(CourseTableSearchTypes.COURSE_LIST) as CourseTableSearchResult;
                    return result.getAllCourses();
                },
                reconstructObjects(data: Record<string, unknown>): CourseRecord {
                    return CourseRecord.loadFromJSON(data);
                },
                storage: lsc,
                forcePullFromDB: false
            }) as CourseRecord[];
    }


    const getRegisteredCoursesForUser = async (userName: string) => {
        const registrations = await getRegistrationsByUser(userName);
        const courses = await getAvailableCourseList();

        const registeredCourseDetails: CourseRegistraitonDetail[] = [];
        registrations.forEach((v) => {
            registeredCourseDetails.push({
                registration: v,
                course: courses.find((obj) => obj.data().courseID === v.data().courseID),
                role: v.data().reg_role
            });
        });

        return registeredCourseDetails;
    }

    const getCourseRecord = async (courseID: string): Promise<CourseFromCourseTable> => {
        if (!lsc.hasKey("AvailableCourses")) {
            await getAvailableCourseList();
        }

        const availableCourses = await lsc.get("AvailableCourses");
        const course = availableCourses.filter((v: { _data: { id: string } }) => { return v._data.id === courseID });
        return course[0]._data;
    }


    const getCourseMaterials = async (userName: string, courseID: string, isAdmin = false): Promise<CourseMaterials> => {
        // check registration 
        const registrations = await getRegistrationsByUser(userName);
        const registration = registrations.find((v) => v.data().courseID === courseID);
        let error: string | undefined = undefined;
        if (registration === undefined && !isAdmin) {
            error = "User: " + userName + " is not registred for course: " + courseID;
            console.debug(error);
            return {
                error,
            };
        }

        const courses = await getAvailableCourseList();
        const course = courses.find((obj) => obj.data().courseID === courseID);
        const assignmentBook = await getBook(course?.data().co_assignmentBook, true) as TextbookObject;
        const textbook = await getBook(course?.data().co_textbookID) as TextbookObject;
        const laboratory = await getBook(course?.data().co_laboratoryID) as TextbookObject;
        const questionBank = await getBook(course?.data().co_questionBankID) as TextbookObject;
        // notebook?

        return {
            registration,
            course,
            assignmentBook,
            textbook,
            laboratory,
            questionBank,
        };
    }


    return {
        getSinglePage,
        getBook,
        getRegistrationsByUser,
        getRegistrationForCourse,
        getAvailableCourseList,
        getRegisteredCoursesForUser,
        getCourseMaterials,
        getCourseRecord,
        getUserProfilesForCourse,
        getAllHandwrittenChars
    }

}