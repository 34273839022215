<template>
  <p>stuff</p>
</template>

<script lang="ts">
  export default {
    setup(): unknown {
      return {};
    },
  };
</script>
