
  import BItemSubitem from '@/components/bootstrap/b-item-subitem.vue'
  import bSelect from '@/components/bootstrap/b-select.vue'
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext } from 'vue'
  import { FormSchemaItem } from '../form-schema.interface'

  export default defineComponent({
    components: { bSelect, BItemSubitem },
    props: {
      modelValue: {
        type: Object as PropType<Record<string, unknown>>,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        required: true
      },
      validationClass: {
        type: String,
        default: ""
      }
    },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const schemaValue = ref(props.modelValue);


      const onChange = () => {
        context.emit("update:modelValue");
      }

      return {
        onChange,
        schemaValue
      }
    }
  })
