
  import BButton from "@/components/bootstrap/b-button.vue";
  import { defineComponent, inject, PropType, ref } from "vue";
  import { Page_QuizComponent } from "../pages/pages.interface";
  import usePagination from "@/components/use/usePagination";
  import QuestionViewer from "../questions/QuestionViewer.vue";
  import { useSubmissions } from "../questions/components/useSubmissions";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import useTime from "@/components/use/useTime";
  import useQuestionsFromQuizComponent from "@/components/use/useQuestionsFromQuizComponent";
  import BLoading from "@/components/bootstrap/b-loading.vue";
  import { useIntervalFn } from "@vueuse/core";
  import { useDueDates } from "@/views/student/useDueDates";


  export default defineComponent({
    components: { BButton, QuestionViewer, BLoading },
    props: {
      content: {
        type: Object as PropType<Page_QuizComponent>,
        required: true,
      },
    },
    async setup(props) {
      let countDown = null;
      const isExam = ref(false);
      const isLink = ref(false);
      const isOpen = ref(true);
      const courseID = inject("courseId", "empty") as string;
      const pageData = inject("pageData", {}) as PageFromTextbookTable;
      const pageName = pageData.name;
      const showSolution = ref(false);
      const allowInput = ref(true);

      const { datePlusOffsetHasPast, countDownTo } = useTime();

      console.log("Quiz:", props.content.id);
      console.log("Course:", courseID);
      console.log({ pageData });


      const {
        getLocalTimeFromTimeStamp,
        computeRelativeDateString
      } = useTime();

      const {
        questionIDs,
        questionBankInfo,
        pullQuestions
      } = useQuestionsFromQuizComponent(props.content);

      const questions = await pullQuestions();

      const currentQuestionData = ref(questions[0]);
      const currentQuestionBankID = ref(questionBankInfo.value[0].questionBankID)

      const { activePageNr, setPageNr, getMaxNrPages } = usePagination(
        0,
        ref(() => questions.length),
        () => {
          currentQuestionData.value = questions[activePageNr.value];
          currentQuestionBankID.value = questionBankInfo.value[activePageNr.value].questionBankID
          //context.emit("change", activePageNr.value);
        }
      );

      const { pullAssignmentSubmission } = useSubmissions();
      const { userName, isAdmin } = useUserStore();

      const submission = await pullAssignmentSubmission({
        courseID,
        componentID: props.content.id,
        userEmail: userName as string,
        questionIDs,
      });

      const { getDueDates } = useDueDates();
      const { dueOn, openOn } = getDueDates(pageData, submission);
      const readableOpenOn = computeRelativeDateString(new Date(openOn).toISOString());

      if (pageData.fieldType === "ASSIGNMENT") {
        if (datePlusOffsetHasPast(dueOn, 1)) {
          showSolution.value = true;
        }
      }

      if (pageData.fieldType === "ASSIGNMENT" || pageData.asn_options?.catagory === "Exam" || pageData.asn_options?.catagory === "Link") {

        isOpen.value = openOn < new Date().getTime();
        isLink.value = pageData.asn_options?.catagory === "Link";
        isExam.value = pageData.asn_options?.catagory === "Exam";

        if (datePlusOffsetHasPast(dueOn, 0)) {
          allowInput.value = false;
        } else {
          countDown = countDownTo(dueOn);
        }

        useIntervalFn(() => {
          isOpen.value = openOn < new Date().getTime();
          allowInput.value = dueOn > new Date().getTime();
        }, 2000);
      }




      return {
        pageName,
        countDown,
        readableOpenOn,
        isExam,
        isLink,
        isOpen,
        isAdmin,
        allowInput,
        courseID,
        activePageNr,
        setPageNr,
        getMaxNrPages,
        questions,
        currentQuestionData,
        currentQuestionBankID,
        showSolution,
        getLocalTimeFromTimeStamp
      };
    },
  });
