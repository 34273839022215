
  import BCard from "@/components/bootstrap/b-card.vue";
  import useEvents from "@/components/use/useEvents";
  import { defineComponent, EmitsOptions, PropType, SetupContext } from "vue";

  export interface buttonBarOptions {
    label: string;
    icon: string;
    show: boolean;
    emitStr: string;
  }

  export default defineComponent({
    components: { BCard },
    props: {
      buttons: {
        type: Object as PropType<Partial<buttonBarOptions>[]>,
        required: true,
      },
      cardClass: {
        type: String,
        default: ""
      },
      colClass: {
        type: String,
        default: "col-lg-4 col-md-6 col-sm-12 p-1"
      },
      barClass: {
        type: String,
        default: ""
      }
    },
    emits: ["click"],
    setup(prop, context: SetupContext<EmitsOptions>) {
      const { emitTyped } = useEvents(context);
      return {
        emitTyped,
      };
    },
  });
