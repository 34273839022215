
import axios from "axios";
import { failable } from "@/components/global/response.interface";

export enum LambdaFunctions {
    CONTACT_US = "contactVengla",
    ampQUIZ_FUNCTIONS = "QuizFunction",
    REGISTRATION = "CourseRegistration",
}


interface AxiosPostOptions {
    lambdaFunction: LambdaFunctions,
    params: Record<string, unknown>
}

const baseLambdaURL = "https://hanl18w0z2.execute-api.us-east-1.amazonaws.com/default/";

export default function useAxios() {
    const postAxios = async (options: AxiosPostOptions) => {
        return await failable(async () => {
            return await axios({
                method: "post",
                url: baseLambdaURL + options.lambdaFunction,
                params: options.params
            });
        });
    }

    return {
        postAxios
    };
}