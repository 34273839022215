export default function useS3() {
   const resolveURL = (folderID: string, url: string) => {
      if (url.search("https://") !== -1) {
         console.log("url", url)
         return url;
      } else {

         return "content/" + folderID + "/" + url;
      }
   }

   return {
      resolveURL
   };
}