<template>
  <FormRoot :key="formKey"
            :is-loading="isLoading"
            :schemaitem="schema"
            :button-list="buttonList"
            :triggers="triggers"
            :displaybehavior="displaybehavior"
            @change="onChange"
            @btnPress="onBtnPress" />
</template>

<script lang="ts">
  import { defineComponent, EmitsOptions, ref, SetupContext } from 'vue';
  import { componentEditorProps } from './useComponentEditor';
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import useReactiveForm from '@/components/use/useReactiveForm';
  import { getBabylonComponentSchema } from './BabylonComponent.schema';
  import useSchema from '@/components/use/useSchema';

  export default defineComponent({
    name: "BabylonComponentEditor",
    components: { FormRoot },
    props: {
      ...componentEditorProps
    },
    emits: ["change", "update:component"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.log("Component", props.component?.data)
      const content = ref(props.component?.data.content);

      // --------------- form ------------
      const {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
      } = useReactiveForm(getBabylonComponentSchema(),
        { data: { ...props.component?.data } }); //
      displaybehavior.showJSONButton = false;

      const onBtnPress = (event: string) => {
        console.log(event)
      }

      const { schema_getJSON } = useSchema();

      const onChange = () => {
        const jsonData = schema_getJSON(schema.value);
        const newCompData = { ...props.component, ...jsonData };
        console.log(newCompData)
        context.emit("update:component", newCompData);
        context.emit("change");
      }

      return {
        buttonList: [],
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
        onChange,
        onBtnPress,
        content
      }
    }
  })
</script>

<style scoped>
</style>