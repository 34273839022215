import { TypeGuard } from '../contentGenerator/mathjs/Type-guards';
import { FormSchemaItem, FormTypes, deepCombineSchemaSingleItem } from './form-schema.interface';
/**
 * FormItem
 *    This class is used to update, transform, and reset
 *    schema and json objects.
 * */
export class FormSchemaParser {
    private data: FormSchemaItem = {
        fieldType: FormTypes.TEXT,
        formGen: { componentName: "TextInput", inputType: "text" },
        label: "",
        placeHolder: "",
        hint: "",
        field: "",
        value: 0,
        children: [],
        rules: {
        },
        properties: {
            disabled: false,
            hidden: false,
            classes: "",
            componentCols: 12,
            componentOffset: 0,
        }
    };

    private children: FormSchemaParser[] = [];

    /**
     * constructor 
     * @param schemaObj 
     * Contains the schema that defines the object
     * @param jsonObj 
     * A JSON object containing initial values. If this parameter is not included 
     * it will default to null and default values will be taken from the schema itself.
     */
    constructor(schemaObj: FormSchemaItem, jsonObj: Record<string, unknown> | null = null) {

        // copy and load the schema object using default values from inside the schema
        this.data = deepCombineSchemaSingleItem(this.data, schemaObj, schemaObj.children as FormSchemaItem[]);
        (schemaObj.children as FormSchemaItem[]).forEach((v) => {
            if (this.data.fieldType === FormTypes.ARRAY_OBJECTS ||
                this.data.fieldType === FormTypes.ARRAY_OBJECTS_EDITOR) {
                this.children.push(new FormSchemaParser(v, null));
            } else {
                this.children.push(new FormSchemaParser(v, jsonObj));
            }
        });

        // load values from the jsonObject if it is present
        if (jsonObj !== null) {
            this.loadMyValueFromJSON(jsonObj);
        }
        return this;
    }

    /**
     * Find a child form item that has the given field. It
     * will check only a single level of children and does not 
     * recursively search children.
     * @param field
     */
    private findChildWithField(field: string): number {
        const index = this.children.findIndex((v) => { return v.data.field === field; });
        if (index === -1) {
            throw "Your JSON Object does not match your schema for entry of '" + field + "'";
        }
        return index;
    }

    /**
     * Search through the jsonObj to find
     * values for this form item. This function will recursively 
     * search the child tree for matches. Make sure that each
     * field name is unique. In other words, nested 
     * fields with the same name are not allowed as their values
     * will not be properly retrieved. 
     * @param jsonObj
     */
    private loadMyValueFromJSON(jsonObj: Record<string, unknown>) {
        const myField = this.data.field;

        const searchResult: { found: boolean, value: unknown } = { found: false, value: null };

        const checkSubObj = (obj: Record<string, unknown>) => {
            console.log("CheckSub")
            // check if field sits on object
            if (Object.prototype.hasOwnProperty.call(obj, myField)) {
                searchResult.found = true;
                searchResult.value = obj[myField];
                if (this.data.fieldType === FormTypes.GROUP &&
                    (searchResult.value as any)._groupSelectedType) {
                    this.data.value = (searchResult.value as any)._groupSelectedType;
                }
            } else {
                Object.keys(obj).forEach((v) => {
                    if (!searchResult.found) {
                        if (typeof obj[v] === 'object' && obj[v] !== null) {
                            checkSubObj(obj[v] as Record<string, unknown>);
                        }
                    }
                });
            }
        }


        checkSubObj(jsonObj);
        if (searchResult.found) {
            this.data.value = searchResult.value;
        }

        return this;
    }

    /**
     * Convert this formItem tree to a schema
     * */
    toSchema(): FormSchemaItem {
        return this.appendSchema({} as FormSchemaItem);
    }

    private appendSchema(obj: FormSchemaItem) {
        const newChildren: FormSchemaItem[] = [];
        this.children.forEach((v) => {
            newChildren.push(v.appendSchema({} as FormSchemaItem));
        });

        return deepCombineSchemaSingleItem(obj, this.data, newChildren);
    }

    /**
     * Return a json object corresponding to this form schema
     * */
    toJSON(): unknown {
        return this.appendJSON({});
    }

    private appendJSON(obj: Record<string, unknown>) {

        const newObj: any = {};
        switch (this.data.fieldType) {
            case FormTypes.GROUP:
                if (!TypeGuard.isNullOrUndefined(this.data.typeSelectorInfo))
                    newObj._groupSelectedType = this.data.value;
            // eslint-disable-next-line no-fallthrough
            case FormTypes.ROW:
            case FormTypes.TAB:
                // get child values
                this.children.forEach((v) => {
                    v.appendJSON(newObj);
                });

                if (this.data.field === null ||
                    this.data.field === undefined ||
                    this.data.field.length === 0) {
                    // merge
                    Object.assign(obj, newObj);
                } else {
                    obj[this.data.field] = newObj;
                }
                break;
            case FormTypes.ARRAY_OF_FLOAT:
            case FormTypes.ARRAY_OF_INT:
            case FormTypes.ARRAY_OF_TEXT:
            case FormTypes.ARRAY_OBJECTS:
            case FormTypes.ARRAY_OF_EMAIL:
                obj[this.data.field] = this.data.value;
                break;
            default:
                obj[this.data.field] = this.data.value;
                break;
        }

        return obj;
    }
}
