import { getBatchOfQuestions } from "@/graphql/queries";
import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { ref } from "vue";
import { Page_QuizComponent } from "../contentGenerator/pages/pages.interface";

export default function useQuestionsFromQuizComponent(quizComponent: Page_QuizComponent) {
   const quizComponentId = ref(quizComponent.id);
   const questionBankInfo = ref(quizComponent.data.array);

   const questionBankIDs = questionBankInfo.value.map(
      (v) => v.questionBankID
   );


   const questionIDs = questionBankInfo.value.map(
      (v) => v.id
   );

   const pullQuestions = async () => {
      const batchOfQuestions = await GraphQLAPI.graphql(graphqlOperation(getBatchOfQuestions,
         {
            questionBankIDs,
            questionIDs
         })) as { data: { getBatchOfQuestions: PageFromTextbookTable[] } };

      const unorganizedQuestions = batchOfQuestions.data.getBatchOfQuestions;
      const questions: PageFromTextbookTable[] = [];

      // present the questions in same order as specified
      questionBankInfo.value.forEach((element) => {
         const found = unorganizedQuestions.find(
            (v) => v.id === element.id
         );
         console.log(found, element.id);
         questions.push(found as PageFromTextbookTable);
      });

      return questions;
   }

   return {
      questionIDs,
      questionBankInfo,
      pullQuestions,
      quizComponentId
   }
}