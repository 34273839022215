
  import {
    computed,
    defineComponent,
    EmitsOptions,
    onMounted,
    Ref,
    ref,
    SetupContext,
    watch,
  } from "vue";

  export default defineComponent({
    props: {
      nrRecords: {
        type: Number,
        required: true,
      },
      nrRecordsPerPage: {
        type: Number,
        default: 5,
      },
    },
    emits: ["pageRangeChange"],
    setup(props, context: SetupContext<EmitsOptions>) {
      let activePageNr: Ref<number> = ref(1);
      const activeNrRecordsPerPage = ref(props.nrRecordsPerPage);

      const nrPages = computed(() => {
        return Math.ceil(props.nrRecords / activeNrRecordsPerPage.value);
      });

      const perPageOptions = [5, 10, 20, 50, 100];

      const emitRange = (): void => {
        context.emit("pageRangeChange", [
          (activePageNr.value - 1) * activeNrRecordsPerPage.value,
          activePageNr.value * activeNrRecordsPerPage.value,
        ]);
      };

      watch(
        () => props.nrRecords,
        () => {
          activePageNr.value = 1;
        }
      );

      watch(activeNrRecordsPerPage, () => {
        emitRange();
      });

      watch(activePageNr, () => {
        emitRange();
      });

      onMounted(() => {
        activePageNr.value = 1;
        emitRange();
      });

      return {
        activePageNr,
        nrPages,
        perPageOptions,
        activeNrRecordsPerPage,
      };
    },
  });
