export interface QuizAnsVariation {
   sign: boolean;
   order: boolean;
   value: boolean;
   integer: boolean;
   variable: boolean;
}

export enum AnswerState {
   NOT_CHECKED,
   CORRECT,
   PARTIAL_CORRECT,
   WRONG
}

export enum MultipleChoiceType {
   AUTO_GENERATE_ANSWERS = "AUTO GENERATE ANSWERS",
   SPECIFY_ANSWERS = "SPECIFY ANSWERS"
}

export interface UserResponseSetupData {
   name: string;
   description: string;
   type: string;
   inputType: string;
   nrChoices: number;
   nrSigFigs: number;
   correctAnswer: string;
   correctUnit: string;
   incorrectAnswers: string[];
   changeOrder: boolean;
   changeValue: boolean;
   changeInteger: boolean;
   changeSign: boolean;
   partialPMper: number;
   correctPMper: number;
   multipleChoiceType: MultipleChoiceType;
   selectedTypeIndicator: UserResponseType;
}

export enum UserResponseType {
   MULTIPLE_CHOICE = "Multiple Choice",
   INPUT_TEXT = "Input Values",
   TRUE_FALSE = "True/False",
   SCALAR = "SCALAR",
   VECTOR = "VECTOR",
   HANDWRITING = "Handwriting"
}