
  import BCard from "@/components/bootstrap/b-card.vue";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";



  export default {
    name: "StudentHome",
    components: { BCard },
    async setup() {
      const { userName } = useUserStore();
      if (!userName) return;

      const registeredCourses =
        await useDatabaseMappings().getRegisteredCoursesForUser(userName);

      const onClick = (courseId: string) => {
        router.push({ name: "StudentCourse", params: { courseId } });
      };

      const gotoRegistration = () => {
        router.push({ name: "AvailableCourseList" });
      }

      const { resolveURL } = useS3();

      return {
        registeredCourses,
        onClick,
        resolveURL,
        gotoRegistration
      };
    },
  };
