<template>
  <b-markdown-editor v-model:content="content" />
</template>

<script lang="ts">
  import { defineComponent, EmitsOptions, ref, SetupContext, watch } from 'vue';
  import { componentEditorProps } from './useComponentEditor';
  import bMarkdownEditor from '@/components/bootstrap/b-markdown-editor.vue';

  export default defineComponent({
    components: { bMarkdownEditor },
    props: {
      ...componentEditorProps
    },
    emits: ["update:component"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.log("Component", props.component?.data)
      const content = ref(props.component?.data.content);

      watch(content, (newValue) => {
        const newObj = { ...props.component, ...{ data: { content: newValue } } };
        context.emit("update:component", newObj)
      });


      return {
        content
      }
    }
  })
</script>

<style scoped>
</style>