
  import { loadStripe } from '@stripe/stripe-js';
  import BButton from '@/components/bootstrap/b-button.vue'
  import bImg from '@/components/bootstrap/b-img.vue'
  import BMarkdown from '@/components/bootstrap/b-markdown.vue'
  import useS3 from '@/components/use/useS3'
  import { useDatabaseMappings } from '@/store/connectors/databaseMapping'
  import { useUserStore } from '@/store/vueStore/useUserStore'
  import { defineComponent, ref } from 'vue'
  import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
  import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
  import router from '@/router';
  import { registerUserForCourse } from '@/graphql/mutations';
  import BConfirmDialog from '@/components/bootstrap/b-confirm-dialog.vue';
  import useConfirmationDialog from '@/components/use/useConfirmationDialog';


  interface IStripeParameters {
    publishableKey: string;
    successURL: string;
    cancelURL: string;
  }

  const StripeParameters: IStripeParameters = {
    // production key
    //static publishableKey: "pk_live_mc6gz6WJFZifSMnOwPvpDHc700fzdgdOlx"
    // test key
    publishableKey: 'pk_test_51Gt0O2CtAkqEIGfGoqh0TkzND4NMzhAgNLovsYj3iHRE3M5e58vm0N9HIn5nEgMR2lzUF0yKE19X4EkevISpdCS800lAH0Iq6E',
    successURL: 'https://vengla.com/registersuccess?session_id={CHECKOUT_SESSION_ID}',
    cancelURL: 'https://vengla.com/registercancel'
  };

  export default defineComponent({
    name: "AvailableCourseDetail",
    components: { bImg, BButton, BMarkdown, BConfirmDialog },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      const { confirmDialog } = useConfirmationDialog();

      const course = await useDatabaseMappings().getCourseRecord(props.courseId)
      const { resolveURL } = useS3();
      const { userName, isAdmin } = useUserStore();

      const registrations = await useDatabaseMappings()
        .getRegistrationsByUser(userName as string);

      const isRegistered = ref(registrations.filter((v) => v.data().courseID === props.courseId).length > 0);
      const isLoading = ref(false);
      let accessCode = "";
      const hasError = ref(false);
      const errorMessage = ref("");

      const localCheckout = async () => {
        isLoading.value = true;
        try {
          const result = await GraphQLAPI.graphql(graphqlOperation(registerUserForCourse,
            {
              courseID: props.courseId,
              userEmail: userName,
              accessCode
            }));

          console.log(result);
          isLoading.value = false;
          isRegistered.value = true;
          router.push({ name: 'StudentHome' });
        } catch (err) {
          hasError.value = true;
          isLoading.value = false;
          errorMessage.value =
            (err as any).errors[0].message;
        }
      }
      const getAccessCode = async () => {
        confirmDialog.value
          ?.open(
            `Register for ${course.name}`,
            "Enter your access code. If you do not have a code, request one from your instructor.",
            {}
          )
          .then(async (input) => {
            const data = input as unknown as { isConfirmed: boolean, value: string };
            if (data.isConfirmed) {
              accessCode = data.value;
              await localCheckout();
              return;
            } else {
              isLoading.value = false;
            }
          });

      };



      const stripeCheckout = () => {
        loadStripe(StripeParameters.publishableKey)
          .then((stripe) => {
            if (TypeGuard.isNullOrUndefined(stripe))
              return;

            stripe.redirectToCheckout({
              customerEmail: userName,
              clientReferenceId: JSON.stringify({ courseID: props.courseId, userEmail: userName, date: new Date().toISOString() }),
              lineItems: [{
                price: course.availibility?.stripePriceID as string,
                quantity: 1
              }],
              mode: 'payment',
              successUrl: StripeParameters.successURL,
              cancelUrl: StripeParameters.cancelURL
            })
              .then(function (result) {
                console.log(result.error.message);
              });
          });
      }

      const onRegister = async () => {
        isLoading.value = true;
        switch (course.availibility?.restrictionType) {
          case "PAID":
            stripeCheckout();
            break;
          case "ACCESS_CODE":
            await getAccessCode();
            break;
          case "EMAIL_MATCH":
          case "EMAIL_LIST":
          default:
            await localCheckout();
            break;
        }
      }

      const onEdit = () => {
        router.push({ name: "CourseEditor", params: { courseId: props.courseId } });
      }

      return {
        confirmDialog,
        isLoading,
        course,
        resolveURL,
        isRegistered,
        onRegister,
        onEdit,
        isAdmin,
        hasError,
        errorMessage
      }
    }
  })
