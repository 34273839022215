
  import MarkdownIt from "markdown-it";
  import MarkdownItSubscript from "markdown-it-sub";
  import MarkdownItSuperscript from "markdown-it-sup";
  import MarkdownItFootnote from "markdown-it-footnote";
  import MarkdownItAttrs from "markdown-it-attrs";
  import MarkdownCustomBlock from "peter-markdown-it-custom-block";

  import { defineComponent, inject, ref } from "vue";
  import VRuntimeTemplate from "vue3-runtime-template";
  import BImg from "./b-img.vue";
  import BVideo from "./b-video.vue";
  import useS3 from "@/components/use/useS3";
  import { TypeGuard } from "../contentGenerator/mathjs/Type-guards";

  export default defineComponent({
    name: "BMarkdown",
    components: {
      VRuntimeTemplate,
      // eslint-disable-next-line vue/no-unused-components
      BImg,
      // eslint-disable-next-line vue/no-unused-components
      BVideo
    },
    props: {
      content: {
        type: String,
        default: "",
      },
      imgFolder: {
        type: String,
        default: ""
      }
    },

    setup(props) {
      const sourceContent = ref(props.content);
      const { resolveURL } = useS3();

      const md = ref();

      const renderMarkdown = () => {
        let markdown = new MarkdownIt()
          .use(MarkdownItSubscript)
          .use(MarkdownItSuperscript)
          .use(MarkdownItFootnote)
          .use(MarkdownItAttrs)
          .use(MarkdownCustomBlock)
          .set({});

        md.value = markdown.render(sourceContent.value);

        let striped = md.value;

        let regexpKey = /<img.*?>/g;
        const srcRegx = /src="(.*?)"/;
        const classRegx = /class="(.*?)"/;
        const altRegx = /alt="(.*?)"/;

        let repFunc = (substring: string): string => {
          const srcFound = substring.match(srcRegx);
          console.log({ srcFound })
          if (TypeGuard.isNullOrUndefined(srcFound)) {
            return "";
          }

          if (srcFound[1].search(/.jpg/i) !== -1 || srcFound[1].search(/.png/i) !== -1) {
            return getImageTag(substring, srcFound[1]);
          }

          if (srcFound[1].search(/.mpg/i) !== -1 || srcFound[1].search(/.mp4/i) !== -1) {
            return getVideoTag(substring, srcFound[1]);
          }
          return "";
        }

        function getVideoTag(originalImg: string, sourceString: string) {
          const classFound = originalImg.match(classRegx);
          const altFound = originalImg.match(altRegx);

          //return "<BVideo @request-modal='onRequestModal' " + srcFound[1] + "></BVideo>"
          let newStr = `<BVideo level="public" src="${resolveURL(props.imgFolder, sourceString)}" `;
          if (classFound)
            newStr += classFound[0] + " ";


          if (altFound)
            newStr += altFound[0];

          newStr += "/>";
          return newStr;
        }


        function getImageTag(originalImg: string, sourceString: string) {
          const classFound = originalImg.match(classRegx);
          const altFound = originalImg.match(altRegx);

          let newStr = `<BImg level="public" src="${resolveURL(props.imgFolder, sourceString)}" `;
          if (classFound)
            newStr += classFound[0] + " ";


          if (altFound)
            newStr += altFound[0];

          newStr += "/>";
          return newStr;
        }

        let res = striped.replace(regexpKey, repFunc);

        striped = res.replace(/\{\{(.+?)\}\}/g, "");
        console.log({ striped })
        md.value = striped;
        console.log(md.value);
      };

      renderMarkdown();

      return {
        md,
      };
    },
  });
