import { CharRecord } from '@/store/database/TextRecognition/record-char';
import { pathPrefixType } from 'aws-sdk/clients/iam';
import { Nullable } from 'babylonjs/types';
import { simplify } from 'mathjs';
import * as paper from 'paper';
import { Ref } from 'vue';
import { TypeGuard } from '../../mathjs/Type-guards';
import { PointMathJSCalculations } from './ConvexHulls';
//import * as mathjs from 'mathjs';

//import { PointCalculations, PointMathJSCalculations } from './ConvexHull';

export const TextRecognition_Equations = [
   "3 + 4.2",
   "3.78 + 9.0",
   "5.2 - 7/8",
   "\\sqrt{3}",
   "\\frac{3 + 9}{6 - 0}",
   "z=9x+4y",
   "f^2=\\sqrt{x^3 + y^4}",
   "A_x + B_y = C_d",
   "K.E. = \\frac{1}{2} mV^2",
   "P.E. = m g h",
   "4 / 5",
   "\\frac{2}{3}",
   "F_D = 0.5 C_D \\rho A V^2",
   "\\vec{V} = V_x \\hat{i} + V_y \\hat{j} + V_z \\hat{k}",
   "A_x",
   "B_y",
   "\\sin{\\theta} = 45 \\degree",
   "\\tan{\\alpha} = 30 \\degree",
   "x = 15.3 [m/s]"
]

export const TextRecognition_Characters = [
   "0",
   "1",
   "2",
   "3",
   "4",
   "5",
   "6",
   "7",
   "8",
   "9",
   "\\cdot",
   "\\vec{}",
   "\\hat{}",
   "\\times",
   "/",
   "-",
   "+",
   "*",
   "^",
   "\\sqrt{}",
   "\\partial",
   "a",
   "b",
   "c",
   "d",
   "e",
   "f",
   "g",
   "h",
   "i",
   "j",
   "k",
   "l",
   "m",
   "A",
   "B",
   "C",
   "D",
   "E",
   "F",
   "G",
   "H",
   "I",
   "J",
   "K",
   "L",
   "M",
   "N",
   "O",
   "P",
   "Q",
   "R",
   "S",
   "T",
   "x",
   "y",
   "z",
   "X",
   "Y",
   "Z",
   "\\alpha",
   "\\beta",
   "\\gamma",
   "\\delta",
   "\\epsilon",
   "\\theta",
   "\\lambda",
   "\\mu",
   "\\nu",
   "\\xi",
   "\\pi",
   "\\sigma",
   "\\tau",
   "\\phi",
   "\\psi",
   "\\omega",
   "\\Delta",
   "\\Theta",
   "\\Lambda",
   "\\Sigma",
   "\\Phi",
   "\\Psi",
   "\\Omega",
   "[",
   "]",
   "(",
   ")",
   "=",
   "bslash",
   "|"
];

export enum HandwritingSimplficationMode {
   Original = "Original",
   Simplified = "Simplified",
   Flattened = "Flattened"
}

export interface CharacterAnalysis {
   layers: paper.Layer[],
   strokeAnalysis: []
}

export interface StrokeAnalysis {
   pathClone: paper.Layer;
   strokeLabel: string;
   charIndexInLayer: number;
   nrIntersections: number;
}

export function useHandwritingAnalysis(scope: Ref<paper.PaperScope | null>) {
   const paperColors: paper.Color[] = [];

   for (let i = 0; i < 5 * 360; i += 70) {
      paperColors.push(new paper.Color({ hue: i, saturation: 1, brightness: 1 }));
   }


   const convertCharRecordToPaperLayer = (dbCharRecord: CharRecord) => {
      const tmpLayer: paper.Layer = new paper.Layer();
      tmpLayer.importJSON(JSON.parse(dbCharRecord.data().orignalPaths[0]));
      (tmpLayer as any).fullySelected = false;
      return tmpLayer;
   }

   const convertLayerToDBString = (layer: paper.Layer): [string] => {
      return [JSON.stringify(layer.exportJSON())]
   }

   const displayCharacters = (charRecords: CharRecord[], mode: HandwritingSimplficationMode) => {
      const layers: paper.Layer[] = [];

      if (TypeGuard.isNullOrUndefined(scope.value))
         return layers;

      scope.value.project.clear();

      charRecords.forEach((v) => {
         layers
            .push(convertCharRecordToPaperLayer(v));
      })

      return layers;
   }

   const modifyStrokeLabel = (newLabel: string, strokeIndexInChar: number, dbCharRecord: CharRecord, updateInDB = false) => {
      const charLayer = convertCharRecordToPaperLayer(dbCharRecord);
      charLayer.getItems({})[strokeIndexInChar].data.strokeLabel = newLabel;
      dbCharRecord.data().orignalPaths = convertLayerToDBString(charLayer);

      if (updateInDB) {
         console.log('Modify Label', newLabel)
         dbCharRecord.updateInDB();
      }

      charLayer.remove();
   }

   const processSequentialTimes = (layer: paper.Layer) => {
      layer
         .getItems({})
         .forEach((stroke: any, ind, itemArray) => {
            // record time elapsed since last stroke
            stroke.data.pauseBeforeStroke = (ind > 0) ? stroke.data.startTime - itemArray[ind - 1].data.endTime : -1;
         });
   }

   const processStrokeIntersections = (layer: paper.Layer, visibleLayer: Nullable<paper.Layer> = null, addCircleAtInter = false) => {
      // intersections
      const itemArray: paper.Item[] = layer.getItems({});
      itemArray.forEach((v) => { v.data.nrIntersections = 0; })
      for (let i = 0; i < itemArray.length; i++) {
         const stroke1 = itemArray[i] as paper.Path;
         for (let j = i + 1; j < itemArray.length; j++) {
            const stroke2 = itemArray[j] as paper.Path;
            const intersections = stroke1.getIntersections(stroke2);
            stroke1.data.nrIntersections += intersections.length;
            stroke2.data.nrIntersections += intersections.length;

            if (addCircleAtInter && visibleLayer) {
               for (let interIndex = 0; interIndex < intersections.length; interIndex++) {
                  new paper.Path.Circle({
                     center: intersections[interIndex].point,
                     radius: 5,
                     fillColor: '#009dec'
                  }).addTo(visibleLayer);
               }
            }
         }
      }
   }

   const getSingleStrokeStats = (stroke: paper.Path) => {
      const arrayOfPoints = stroke.segments.map((v) => {
         return v.point;
      });

      const stats: any = PointMathJSCalculations.getStatistics(arrayOfPoints);
      stats.strokeLabel = stroke.data.strokeLabel;
      stats.nrIntersections = stroke.data.nrIntersections;
      return stats;
   }

   const analyzeCharacterStrokes = (charLayer: paper.Layer, charIndexInLayer: number): StrokeAnalysis[] => {
      const strokeAnalysis: StrokeAnalysis[] = [];
      charLayer.getItems({}).forEach((pI: any, ind) => {
         if (pI.segments.length > 0) {
            const analyzedStroke: any = getSingleStrokeStats(pI);
            analyzedStroke.pathClone = pI;//clone;
            analyzedStroke.strokeLabel = pI.data.strokeLabel;

            analyzedStroke.charIndexInLayer = charIndexInLayer;
            analyzedStroke.strokeIndexInChar = ind;
            analyzedStroke.nrIntersections = pI.data.nrIntersections;
            strokeAnalysis.push(analyzedStroke);
         }
      })
      return strokeAnalysis;
   }

   const labelCharacterStrokes = (loadChar: string, charRecord: CharRecord, charLayer: paper.Layer) => {
      const strokes = charLayer.getItems({});
      const nrStrokesRecorded = strokes.length;

      if (nrStrokesRecorded === 1) {
         const firstStroke = strokes[0];
         if (firstStroke.data.strokeLabel == undefined) {
            firstStroke.data.strokeLabel = loadChar;
            (charRecord.data as any).orignalPaths = convertLayerToDBString(charLayer);
            charRecord.updateInDB();
            console.log("Update Char Stroke Label: ", loadChar);
         }
      }
   }

   const simplifyLayers = (charLayer: paper.Layer, displayMode: HandwritingSimplficationMode) => {
      // simplify layers
      charLayer.getItems({}).forEach((pI: any, ind) => {
         pI.strokeColor = paperColors[ind];
         if (displayMode != HandwritingSimplficationMode.Original) {
            pI.simplify(3);
            if (displayMode === HandwritingSimplficationMode.Flattened) {
               pI.flatten(5);
            }
         }
         //pI.translate(new paper.Point(charIndexInTAQR * 20, 0));
      });
   }

   const analyzeCharacterLayers = (charRecords: CharRecord[], displayMode: HandwritingSimplficationMode) => {
      let strokeAnalysis: StrokeAnalysis[] = [];
      charRecords.forEach((charRecord, charRecordIndex) => {
         const charLayer = convertCharRecordToPaperLayer(charRecord);
         simplifyLayers(charLayer, displayMode);
         labelCharacterStrokes(charRecord.data().character, charRecord, charLayer);
         strokeAnalysis = [...strokeAnalysis, ...analyzeCharacterStrokes(charLayer, charRecordIndex)];
      });
      return strokeAnalysis;
   }

   const downloadAnalysis = (charRecords: CharRecord[], displayMode: HandwritingSimplficationMode) => {
      const analyzeAllCharacters: any[] = [];
      for (let i = 0; i < TextRecognition_Characters.length; i++) {
         const charMatch = TextRecognition_Characters[i];
         const matchingRecords = charRecords.filter((v) => v.data().character === charMatch);

         analyzeAllCharacters.push({
            char: charMatch,
            analysis: analyzeCharacterLayers(matchingRecords, displayMode)
         });
      }

      download(JSON.stringify(analyzeAllCharacters), "charAnalysis.txt", 'text/plain')
   }

   // Function to download data to a file
   const download = (data: any, filename: string, type: string) => {
      const file = new Blob([data], { type: type });
      if ((window.navigator as any).msSaveOrOpenBlob) // IE10+
         (window.navigator as any).msSaveOrOpenBlob(file, filename);
      else { // Others
         const a = document.createElement("a"),
            url = URL.createObjectURL(file);
         a.href = url;
         a.download = filename;
         document.body.appendChild(a);
         a.click();
         setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
         }, 0);
      }
   }

   return {
      downloadAnalysis,
      processSequentialTimes,
      displayCharacters,
      modifyStrokeLabel,
      analyzeCharacterLayers,
      processStrokeIntersections
   }
}
