<template>
  <PageIterator :course-id="courseId"
                :text-id="textId"
                :page-id="pageId"
                :root-page-id="rootPageId"
                :editable="false"
                :nestable="true"
                route-to-destination="TextbookViewer">
    <template #display="{ data }">
      <Page v-if="data"
            :key="data?.data().id"
            :page-data="data?.data()" />
    </template>
  </PageIterator>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import Page from "../../components/contentGenerator/pages/Page.vue";
  import PageIterator from "../components/p-page-iterator.vue";

  export default defineComponent({
    name: "TextbookViewer",
    components: { Page, PageIterator },
    props: {
      courseId: {
        type: String,
        required: true,
      },
      textId: {
        type: String,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      rootPageId: {
        type: String,
        required: true,
      },
    },
    async setup() {
      return {};
    },
  });
</script>

<style scoped>
</style>