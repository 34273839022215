import { PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import { FormSchemaItem } from '../form-schema.interface';
import { FormDisplayBehavior } from "../form.interface";

export interface formValidationTriggerInterface {
    validate: boolean
}

export interface formItemMixinInterface {
    fid: string,
    modelValue: unknown,
    triggers: formValidationTriggerInterface,
    displaybehavior: {
        displayHints: boolean,
        hasChanges: boolean,
        showJSONButton: boolean,
        buttonAlign: string
    },
    schemaitem: FormSchemaItem,
    formGlobalOptions: {
        rootDir: string,
        fileSecurityType: string,
        debug: boolean
    }
}

export const formItemMixinProps = {
    fid: {
        type: String,
        default: uuid4(),
    },
    modelValue: {
    },
    triggers: {
        type: Object as PropType<formValidationTriggerInterface>,
        default: { validate: false }
    },
    displaybehavior: {
        type: Object as PropType<FormDisplayBehavior>,
        default: {
            displayHints: false,
            hasChanges: false,
            showJSONButton: false,
            buttonAlign: 'top'
        }
    },
    schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        default: {},
        required: true
    },
    formGlobalOptions: {
        type: Object as PropType<{ rootDir: string, fileSecurityType: string, debug: boolean }>,
        default: {
            rootDir: '',
            fileSecurityType: 'protected',
            debug: true
        }
    }
};