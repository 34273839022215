

// interpolateObjectTable(tempTable, 'temp', 0.4, 'velocity')
export function interpolateObjectTable(table: any[], indexField: string, indexValue: number, prop: string) {
    const index = table.findIndex((v, i, o) => { return v[indexField] > indexValue; });

    if (index == -1) { return table[table.length - 1][prop]; }
    if (index == 0) { return table[0][prop]; }

    const R = (indexValue - table[index - 1][indexField]) / (table[index][indexField] - table[index - 1][indexField]);

    return table[index][prop] * R + table[index - 1][prop] * (1 - R);
}


export interface nDInterpolationParams {
    table: any[],

}

// object format
// [ { v1_name: value, table: [ {key1: value, key2: value, interpPropName: value }]}]
// interpolateObjectTable(var1_val, tempTable1, var1_val1, tempTabl2, val2, 'temp', 0.4, 'velocity')
export function interpolate2DObjectTable(table: any[], v1_name: string, v1_currentValue: number,
    v2_indexField: string, v2_indexValue: number, interpPropName: string) {

    const index = table.findIndex((v, i, o) => { return v[v1_name] > v1_currentValue; });

    let index1, index2;
    if (index == -1 || index === table.length) {
        // use last table's value
        index1 = table.length - 2;
        index2 = table.length - 1;
    } else if (index == 0) {
        // use first table
        index1 = 0;
        index2 = 1;
    } else {
        index1 = index - 1;
        index2 = index;
    }

    const table1 = table[index1].table;
    const table2 = table[index2].table;

    const v1_1 = table[index1][v1_name];
    const v1_2 = table[index2][v1_name];

    const inter1 = interpolateObjectTable(table1, v2_indexField, v2_indexValue, interpPropName);
    const inter2 = interpolateObjectTable(table2, v2_indexField, v2_indexValue, interpPropName);

    const R = (v1_currentValue - v1_1) / (v1_2 - v1_1);

    return inter2 * R + inter1 * (1 - R);
}


