import { CourseRecord } from "@/store/database/Course/record-course";
import { LocalStorageClass } from "@/store/local/localStorage";


const namespace = "Vengla-DB";
const lsc = new LocalStorageClass().config(namespace);

export function useCourseEditorFunctions() {
   const updateLocalAndDBCourse = async (courseRecord: CourseRecord) => {
      const result = await courseRecord.updateInDB();
      const updatedDBData = (await result?.getData())?.data?.updateCourse;

      if (!updatedDBData)
         return;

      const courseID = courseRecord.data().id;
      const localBook = await lsc.get(`AvailableCourses`);

      const foundCourse = localBook.find((course: any) => course._data.id === courseID);
      foundCourse._data = updatedDBData;

      await lsc.set(`AvailableCourses`, localBook);
   }

   return {
      updateLocalAndDBCourse
   }
}
