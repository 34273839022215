<template>
  <li class="nav-item dropdown">
    <a id="navbarDropdownColor"
       class="nav-link dropdown-toggle"
       href="#"
       role="button"
       data-bs-toggle="dropdown"
       aria-expanded="false">
      {{ label }}
    </a>
    <ul class="dropdown-menu"
        aria-labelledby="navbarDropdown"
        :class="ulClass">
      <slot />
    </ul>
  </li>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "BToolbarDropdown",
    props: {
      label: {
        type: String,
        default: ""
      },
      ulClass: {
        type: String,
        default: ""
      }
    },
    setup(props) {
      return {}
    }
  })
</script>

<style scoped>
</style>