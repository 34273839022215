/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeSubmission = /* GraphQL */ `
  subscription SubscribeSubmission($courseID: ID!) {
    subscribeSubmission(courseID: $courseID) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const subscribeSubmissionAttempt = /* GraphQL */ `
  subscription SubscribeSubmissionAttempt($courseID: ID!) {
    subscribeSubmissionAttempt(courseID: $courseID) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCodeLogTable = /* GraphQL */ `
  subscription OnCreateCodeLogTable {
    onCreateCodeLogTable {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCodeLogTable = /* GraphQL */ `
  subscription OnUpdateCodeLogTable {
    onUpdateCodeLogTable {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCodeLogTable = /* GraphQL */ `
  subscription OnDeleteCodeLogTable {
    onDeleteCodeLogTable {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserPermissions = /* GraphQL */ `
  subscription OnCreateUserPermissions($createdBy: String) {
    onCreateUserPermissions(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserPermissions = /* GraphQL */ `
  subscription OnUpdateUserPermissions($createdBy: String) {
    onUpdateUserPermissions(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserPermissions = /* GraphQL */ `
  subscription OnDeleteUserPermissions($createdBy: String) {
    onDeleteUserPermissions(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupFormation = /* GraphQL */ `
  subscription OnCreateGroupFormation($userEmail: String) {
    onCreateGroupFormation(userEmail: $userEmail) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupFormation = /* GraphQL */ `
  subscription OnUpdateGroupFormation($userEmail: String) {
    onUpdateGroupFormation(userEmail: $userEmail) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupFormation = /* GraphQL */ `
  subscription OnDeleteGroupFormation($userEmail: String) {
    onDeleteGroupFormation(userEmail: $userEmail) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse($owner: String, $co_editors: String) {
    onCreateCourse(owner: $owner, co_editors: $co_editors) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse($owner: String, $co_editors: String) {
    onUpdateCourse(owner: $owner, co_editors: $co_editors) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse($owner: String, $co_editors: String) {
    onDeleteCourse(owner: $owner, co_editors: $co_editors) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTextbook = /* GraphQL */ `
  subscription OnCreateTextbook($owner: String) {
    onCreateTextbook(owner: $owner) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTextbook = /* GraphQL */ `
  subscription OnUpdateTextbook($owner: String) {
    onUpdateTextbook(owner: $owner) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTextbook = /* GraphQL */ `
  subscription OnDeleteTextbook($owner: String) {
    onDeleteTextbook(owner: $owner) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubmissions = /* GraphQL */ `
  subscription OnCreateSubmissions($owner: String) {
    onCreateSubmissions(owner: $owner) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSubmissions = /* GraphQL */ `
  subscription OnUpdateSubmissions($owner: String) {
    onUpdateSubmissions(owner: $owner) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSubmissions = /* GraphQL */ `
  subscription OnDeleteSubmissions($owner: String) {
    onDeleteSubmissions(owner: $owner) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMarketingEmailLists = /* GraphQL */ `
  subscription OnCreateMarketingEmailLists($createdBy: String) {
    onCreateMarketingEmailLists(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarketingEmailLists = /* GraphQL */ `
  subscription OnUpdateMarketingEmailLists($createdBy: String) {
    onUpdateMarketingEmailLists(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarketingEmailLists = /* GraphQL */ `
  subscription OnDeleteMarketingEmailLists($createdBy: String) {
    onDeleteMarketingEmailLists(createdBy: $createdBy) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSharePermissions = /* GraphQL */ `
  subscription OnCreateSharePermissions($owner: String) {
    onCreateSharePermissions(owner: $owner) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateSharePermissions = /* GraphQL */ `
  subscription OnUpdateSharePermissions($owner: String) {
    onUpdateSharePermissions(owner: $owner) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteSharePermissions = /* GraphQL */ `
  subscription OnDeleteSharePermissions($owner: String) {
    onDeleteSharePermissions(owner: $owner) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateTextRecognition = /* GraphQL */ `
  subscription OnCreateTextRecognition($owner: String) {
    onCreateTextRecognition(owner: $owner) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTextRecognition = /* GraphQL */ `
  subscription OnUpdateTextRecognition($owner: String) {
    onUpdateTextRecognition(owner: $owner) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTextRecognition = /* GraphQL */ `
  subscription OnDeleteTextRecognition($owner: String) {
    onDeleteTextRecognition(owner: $owner) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
