<template>
  <div class="card"
       :style="{width: width}"
       @click="onClick">
    <BImg v-if="imgSrc"
          :src="imgSrc"
          :style="{ objectFit:'cover', maxHeight: '150px', marginTop:'-0.5px' }"
          class="card-img-top" />
    <div class="card-body">
      <h5 v-if="title"
          class="card-title">
        {{ title }}
      </h5>
      <p v-if="description"
         class="d-none d-sm-block card-text">
        {{ description }}
      </p>
      <slot name="body" />
    </div>
    <div v-if="footer"
         class="card-footer">
      {{ footer }}
    </div>
  </div>
</template>

<script lang="ts">
  import { EmitsOptions, SetupContext } from "@vue/runtime-core";
  import BImg from "./b-img.vue";


  export default {
    components: { BImg },
    props: {
      width: {
        type: String,
        default: "15rem",
      },
      imgSrc: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      description: {
        type: String,
        default: undefined,
      },
      footer: {
        type: String,
        default: undefined,
      },
    },
    emits: ["click"],
    setup(_: unknown, context: SetupContext<EmitsOptions>) {
      const onClick = () => {
        context.emit("click");
      };
      return {
        onClick,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .card-title {
    font-weight: 550;
    text-align: center;
  }

  .card {
    cursor: pointer;
  }
</style>