
  import { useVModel } from '@vueuse/core'
  import { defineComponent, PropType } from 'vue'

  export interface ButtonGroupOptions {
    label: string,
    value: string
  }

  export default defineComponent({
    props: {
      buttons: {
        type: Array as PropType<ButtonGroupOptions[]>,
        required: true
      },
      buttonClass: {
        type: String,
        default: "btn-primary"
      },
      value: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const btnValue = useVModel(props, 'value', emit);

      return {
        btnValue
      }
    }
  })
