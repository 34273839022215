import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';

import { Control_Base } from "./Control_Base";


export interface ControlSliderOptions {
    onChange: (value: number) => void,
    value?: {
        min?: number,
        max?: number
    },
    sliderOptions?: {
        color?: string,
        horizontalAlignment?: number,
        verticalAlignment?: number,
        width?: string,  // "200px" or "10%"
        height?: string, // "200px" or "10%"
        top?: string,    // "200px" or "10%"
        left?: string,    // "200px" or "10%"
        title?: string,
        sliderTitle?: string,
        sliderUnit?: string
    }
}

export interface BaseControlOptions {
    getTargetMesh: () => BABYLON.Mesh;
    min: number;
    max: number;
    title: string;
    sliderTitle: string;
    sliderUnit: string;
}

export class Control_Slider extends Control_Base {

    public options: ControlSliderOptions = {
        value: { min: 0, max: 1 },
        sliderOptions: {
            color: "red",
            horizontalAlignment: GUI.Control.HORIZONTAL_ALIGNMENT_CENTER,
            verticalAlignment: GUI.Control.VERTICAL_ALIGNMENT_TOP,
            width: "100%",
            height: "20px",
            top: "10%",
            left: "0%"
        },
        onChange: (valude: number) => { return }
    };

    constructor(name: string, scene: BABYLON.Scene, options: ControlSliderOptions) {
        super(name, scene);
        this.options = { ...this.options, ...options };
        return this;
    }

    public renderGUI() {
        const orientateY = (angle: unknown) => {
            this.instrument?.setProperty(this.property, angle);
        }

        const displayValue = (value: number) => {
            return BABYLON.Tools.ToDegrees(value) | 0;
        }

        this.gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI_SLIDER");

        const rect1 = new GUI.Rectangle();
        rect1.widthInPixels = 150;
        rect1.heightInPixels = 150;
        rect1.color = "gray";
        rect1.thickness = 1;
        rect1.cornerRadius = 4;
        rect1.background = "white";
        this.gui.addControl(rect1);

        const stack = new GUI.StackPanel();
        rect1.addControl(stack);

        const rotateGroup = new GUI.SliderGroup((this.baseOptions as BaseControlOptions).title);

        const minVal = (this.baseOptions as BaseControlOptions).min;
        const maxVal = (this.baseOptions as BaseControlOptions).max;

        rotateGroup.addSlider((this.baseOptions as BaseControlOptions).sliderTitle,
            orientateY, (this.baseOptions as BaseControlOptions).sliderUnit, minVal, maxVal, this.instrument?.getProperty(this.property), displayValue);
        const selectBox = new GUI.SelectionPanel("sp", [rotateGroup]);
        selectBox.heightInPixels = 100;
        stack.addControl(selectBox);

        stack.addControl(this.createDoneButton());

        if (this.baseOptions.getTargetMesh) {
            rect1.linkWithMesh(this.baseOptions.getTargetMesh());
        } else if (this.instrument) {
            rect1.linkWithMesh(this.instrument.getContainerMesh());
        }
        rect1.linkOffsetYInPixels = 50;
        rect1.linkOffsetXInPixels = 110;
    }

}