
  import { computed, defineComponent, PropType } from "vue";
  import { FormSchemaItem } from "../form-schema.interface";
  import { FormDisplayBehavior } from "../form.interface";
  import FromInputLayoutBaseLabel from "./form-input-layout-baseLabel.vue";
  import FormInputLayoutBaseComponent from "./form-input-layout-baseComponent.vue";

  export default defineComponent({
    name: "FormInputLayout",
    components: {
      FromInputLayoutBaseLabel,
      FormInputLayoutBaseComponent,
    },
    props: {
      fid: {
        type: String,
        required: true,
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        default: null,
      },
      displaybehavior: {
        type: Object as PropType<FormDisplayBehavior>,
        default: () => {
          return {
            displayHints: false,
            hasChanges: false,
            showJSONButton: false,
            rootTitle: "",
            buttonAlign: "top",
          };
        },
      },
      feedback: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const isLeftOrTop = computed(() => {
        return (
          props.schemaitem?.properties?.labelAlign == "left" ||
          props.schemaitem?.properties?.labelAlign == "top"
        );
      });

      const isRightOrBottom = computed(() => {
        return (
          props.schemaitem?.properties?.labelAlign == "right" ||
          props.schemaitem?.properties?.labelAlign == "bottom"
        );
      });

      return {
        isRightOrBottom,
        isLeftOrTop,
      };
    },
  });
