<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3>
          {{ error }}
        </h3>
      </div>
    </div>
    <div class="row g-0">
      <div class="col-12 col-md-4">
        <h2>
          Laboratories
        </h2>
        <hr class="d-md-none">
      </div>
    </div>
  </div>
  <div v-if="error === undefined"
       class=" container mt-3">
    <div class="row">
      <div v-for="lab in listOfLaboratories"
           :key="lab"
           class="card mb-3"
           style="max-width: 540px;"
           @click="onLaboratoryClick(lab)">
        <div class="row g-0">
          <div class="col-md-4">
            <BImg :src="resolveURL(lab._data.textID, lab._data.lab_pictureURL)"
                  :style="{}"
                  class="img-fluid rounded-start" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">
                {{ lab._data.name }}
              </h5>
              <p class="card-text">
                {{ lab._data.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import BImg from "@/components/bootstrap/b-img.vue";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { CourseRecord } from "@/store/database/Course/record-course";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent } from "vue";

  export default defineComponent({
    components: { BImg },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      const { userName } = useUserStore();

      if (!userName) return null;

      const {
        error,
        course,
        laboratory,
      } = await useDatabaseMappings().getCourseMaterials(userName, props.courseId);
      console.debug("Course - Pulled Course Materials", course);

      const courseData = course?.data();
      if (!courseData) return null;

      const onLaboratoryClick = (clickLab: { _data: { textID: string, id: string } }): void => {
        console.log(clickLab._data)
        router.push({
          name: "AssignmentViewer",
          params: {
            courseId: props.courseId,
            textId: clickLab._data.textID,
            rootPageId: laboratory?.getRootPageID() ?? "",
            pageId: clickLab._data.id,
          },
        });
      };

      const { resolveURL } = useS3();


      const listOfLaboratories = laboratory?.getFirstLevelPages() ?? [];

      return {
        error,
        resolveURL,
        course: course as CourseRecord,
        listOfLaboratories,
        userName,
        onLaboratoryClick
      };
    },
  });
</script>

<style>
</style>