<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <div class="container">
      <div class="row">
        <div class="col">
          <input :id="fid"
                 :class="'form-control ' + validationState"
                 :type=" schemaitem.formGen.inputType"
                 :value="currentValue"
                 :aria-describedby="fid"
                 :readonly="schemaitem.properties.disabled"
                 required
                 @input="currentValue=$event.target.value">
        </div>
        <div class="col">
          <BFileUpload />
        </div>
      </div>
    </div>
  </form-input-group>
</template>

<script>
  import formInputGroup from "./form-input-layout.vue";
  import BFileUpload from "../../bootstrap/b-file-upload.vue";

  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormInputFile",
    components: { formInputGroup, BFileUpload },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context) {
      return {
        ...useValidation(props, context),
      };
    },
  });
</script>
<style lang="">
</style>