import { MjsMath } from "../math-core";
import { MathJSTree } from "../math-node-tree";
import { MjsNode, mjsUnknown } from "../math-types";
import { VariableTable } from "../mathjs-variable-table";

export class mjsParser {

   // parse
   // - replace all bracketed units with a function node that 
   //   evaluates the unit
   // - decorate the expression tree with parent information 
   //   which is used for inversion
   static parseEnforceBracketedUnits(eqn: string, scope: Record<string, mjsUnknown>) {
      const parsedEqn = MjsMath.getMathJS()
         .parse(decorateUnits(eqn)) as unknown as MjsNode;

      const filteredEqn = MathJSTree
         .processBracUnitAndExtendedFunctions(parsedEqn, scope);

      MathJSTree.addParentInfoToNodeTree(filteredEqn, 0, null);
      return filteredEqn;

      function decorateUnits(inputEqn: string) {
         // replace [cm / s] with cUnit(cm/s) 
         const replacer = (match: string): string => {
            return `cUnit(${match})`
         }

         return inputEqn
            .replace(/\[([A-Za-z1-9/^-\s]+)\]/g, replacer);
      }
   }
}