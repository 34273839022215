import { FieldType } from "../../../API";
import { createCourse, deleteCourse, updateCourse } from '../../../graphql/mutations';
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { NotebookFromCourseTable } from "./table-course.interface";
import { getDefaultBaseCourseValues } from "./table-course-utils";

export interface CourseNotebookCreateOptions {
    courseID: string
}

const notebookRecordCreator: RecordCreatorParameters<NotebookFromCourseTable, CourseNotebookCreateOptions> = {
    defaultValues: {
        ...getDefaultBaseCourseValues(),
        note_bookID: ""
    },
    objectType: RecordTypes.NOTEBOOK,
    createQuery: createCourse,
    updateQuery: updateCourse,
    deleteQuery: deleteCourse,

    getDeleteItemParameters: function (data: NotebookFromCourseTable) {
        return {
            id: data.id,
            courseID: data.courseID,
            userEmail: data.userEmail,
            fieldType: data.fieldType
        }
    },

    onAfterCreatedModifyData: function (data: NotebookFromCourseTable,
        createOptions: CourseNotebookCreateOptions,
        self: NotebookRecord) {
        self.data().fieldType = FieldType.NOTEBOOK;
        self.data().courseID = createOptions.courseID;
    },

    onBeforeSaveToDB: function (data: NotebookFromCourseTable, self: NotebookRecord) {
        return data;
    },

    onAfterRecordWrapFromDB(data: NotebookFromCourseTable, self: NotebookRecord) {
        return;
    }
}

setRecordCreator(RecordTypes.NOTEBOOK, notebookRecordCreator);

/**
 * Class used to hold, update, and create database 
 * Course items. 
 * */
export class NotebookRecord extends WrappedRecord<NotebookFromCourseTable, CourseNotebookCreateOptions> {
    constructor() {
        super(RecordTypes.NOTEBOOK);
    }


    static loadFromJSON(data: Record<string, unknown>) {
        const newBook = new NotebookRecord();
        Object.assign(newBook, data);
        return newBook;
    }
}