// import { createApp } from 'vue'  // use if you don't need the runtime compiler
import { createApp } from "vue/dist/vue.esm-bundler"; // used for runtime compiler
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import katexPlugin from "./components/contentGenerator/katex/katex-plugin";
import toolTipPlugin from "./components/bootstrap/tooltip/b-tooltip-plugin";

import "bootstrap";

// -------------- Amplify -------------------
import Amplify from "aws-amplify";
import awsconfig from "@/aws-exports";
import { DebugLevel, setConsoleLevel } from "./utils/logger";

Amplify.configure(awsconfig);

// -------------- Log Level ----------------
const isProduction = process.env.NODE_ENV === "production";
const level = isProduction ? DebugLevel.NONE : DebugLevel.DEBUG;
setConsoleLevel(level);


const app = createApp(App);
app.use(router)
   .use(createPinia())
   .use(katexPlugin)
   .use(toolTipPlugin)
   .mount("#app");
