import * as BABYLON from 'babylonjs';

export class CylinderGenerator {

    private static rotateCylinderToPoints(startPoint: BABYLON.Vector3, endPoint: BABYLON.Vector3) {
        // cylinders point along y-axis by default
        const v1 = endPoint.subtract(startPoint).normalize();
        const v2 = new BABYLON.Vector3(0, 1, 0);

        const distance = BABYLON.Vector3.Distance(startPoint, endPoint);

        const axis = BABYLON.Vector3.Cross(v1, v2);
        axis.normalize()
        const angle = BABYLON.Vector3.Dot(v1, v2);

        return {
            distance: distance,
            angle: angle,
            quaternion: BABYLON.Quaternion.RotationAxis(axis, -Math.PI / 2 + angle)
        };
    }

    /**
     *  Creates a Cylinder oriented along axis defined by the Two Endpoints.
     *  
     * @param scene - babylon scene object
     * @param startPoint - first point
     * @param endPoint - second point
     * @param diameter - diameter of the cylinder
     * @param tesselation - divide the cylinder into this many flat surfaces
     * @returns A new cylinder object oriented along given axis
     */
    public static create(scene: BABYLON.Scene,
        startPoint: BABYLON.Vector3, endPoint: BABYLON.Vector3,
        diameter: number, tesselation = 10): BABYLON.Mesh {

        const rotQ = CylinderGenerator.rotateCylinderToPoints(startPoint, endPoint);

        const cylinder = BABYLON.MeshBuilder.CreateCylinder("Pipe", {
            diameter: diameter,
            height: rotQ.distance,
            tessellation: tesselation
        }, scene);

        cylinder.position = startPoint.add(endPoint).scale(0.5);
        cylinder.rotationQuaternion = rotQ.quaternion;
        return cylinder;
    }

    public static createTapered(scene: BABYLON.Scene,
        startPoint: BABYLON.Vector3, endPoint: BABYLON.Vector3,
        diameterTop: number,
        diameterBottom: number,
        tesselation = 10): BABYLON.Mesh {

        const rotQ = CylinderGenerator.rotateCylinderToPoints(startPoint, endPoint);

        const cylinder = BABYLON.MeshBuilder.CreateCylinder("Pipe", {
            diameterTop: diameterTop,
            diameterBottom: diameterBottom,
            height: rotQ.distance,
            tessellation: tesselation
        }, scene);

        cylinder.position = startPoint.add(endPoint).scale(0.5);
        cylinder.rotationQuaternion = rotQ.quaternion;
        return cylinder;
    }


    public static createTaperedTube(scene: BABYLON.Scene,
        startPoint: BABYLON.Vector3, endPoint: BABYLON.Vector3,
        diameterTop: number,
        diameterBottom: number,
        tesselation = 10): BABYLON.Mesh {

        const rotQ = CylinderGenerator.rotateCylinderToPoints(startPoint, endPoint);
        const totalLength = rotQ.distance;

        const path = [];
        for (let i = 0; i < 2; i++) {
            const x = 0;
            const y = i * totalLength;
            const z = 0;
            path.push(new BABYLON.Vector3(x, y, z));

        }

        const deltaRadius = diameterTop - diameterBottom;
        const cylinder = BABYLON.MeshBuilder.CreateTube("Pipe", {
            radiusFunction: (i, _distance) => {
                return 0.5 * (diameterBottom + deltaRadius * (i));
            },
            path,
            tessellation: tesselation
        }, scene);

        cylinder.position = endPoint; //startPoint.add(endPoint).scale(0.5);
        cylinder.rotationQuaternion = rotQ.quaternion;
        return cylinder;
    }


}