import { Ref, ref } from '@vue/reactivity';
import { QuestionModel } from '../model/model-question';
import { AnswerState, UserResponseType, UserResponseSetupData } from './user-response.interface';
import { mjsDenseMatrix, mjsVectorIJK } from '../../mathjs/math-types';
import { MjsMath } from '../../mathjs/math-core';
import { SumissionQuestionPart } from '@/store/database/Submission/submitted-item.interface';

export interface NumericValidityCheck {
   hasError: boolean,
   error: string,
   component: number
}

export function useNumericResponses(model: QuestionModel, responseData: UserResponseSetupData, submissionRecord: SumissionQuestionPart) {
   const isScalar = responseData.inputType === UserResponseType.SCALAR;
   const choice = ref(isScalar ? "" : { i: "", j: "", k: "" });
   const ansState: Ref<AnswerState> = ref(AnswerState.NOT_CHECKED);


   const compareNumeric = (responseData: UserResponseSetupData,
      numericAns: number,
      numericSubmission: number) => {

      if (numericAns === numericSubmission) { return AnswerState.CORRECT; }

      const pmCorrect = Math.abs(numericAns) * (responseData.correctPMper ?? 1) / 100.0;
      if (numericAns - pmCorrect < numericSubmission &&
         numericSubmission < numericAns + pmCorrect) {
         return AnswerState.CORRECT;
      }


      const pmPartial = Math.abs(numericAns) * (responseData.partialPMper ?? 5) / 100.0;
      if (numericAns - pmPartial < numericSubmission &&
         numericSubmission < numericAns + pmPartial) {
         return AnswerState.PARTIAL_CORRECT;
      }

      return AnswerState.WRONG;
   }

   const getCorrectUnit = () => {
      return MjsMath
         .getOnlyUnitString(model.evalToNumeric(responseData.correctAnswer));
   }


   const getCorrectAnswer = () => {
      if (isScalar) {

         return MjsMath
            .getOnlyNumericPart(model.evalToNumeric(responseData.correctAnswer))
            .toString();
      } else {
         const matrixCorrectAnswer = model
            .evalToNumeric(responseData.correctAnswer) as mjsDenseMatrix;
         const numberArray = MjsMath.getOnlyNumericPart(matrixCorrectAnswer);

         return {
            i: numberArray[0].toString(),
            j: numberArray[1].toString(),
            k: numberArray[2].toString()
         };
      }
   }


   const checkScalarAnswer = (ans: string): AnswerState => {

      const scalarValue = model.evalToNumeric(responseData.correctAnswer)
         .toString();

      console.debug("Check Scalar Value:", ans, scalarValue);

      if (ans === scalarValue) { return AnswerState.CORRECT; }
      const numericAnswer = parseFloat(scalarValue);
      const numericSubmission = parseFloat(ans);

      return compareNumeric(responseData, numericAnswer, numericSubmission);
   }

   const checkVectorAnswer = (ans: mjsVectorIJK): AnswerState => {
      if (ans === undefined) { return AnswerState.WRONG; }

      const mdlTerm = model.evalToNumeric(responseData.correctAnswer) as mjsDenseMatrix;
      let state = AnswerState.CORRECT;

      const submission = [ans.i, ans.j, ans.k];
      const numberArray = MjsMath.getOnlyNumericPart(mdlTerm);
      for (let i = 0; i < 3; i++) {
         const tmpState = compareNumeric(responseData, parseFloat(submission[i]), numberArray[i]);
         if (state === AnswerState.PARTIAL_CORRECT ||
            tmpState === AnswerState.PARTIAL_CORRECT) {
            state = AnswerState.PARTIAL_CORRECT;
         } else if (tmpState === AnswerState.WRONG) {
            state = AnswerState.WRONG;
         }
      }

      return state;
   }

   const isValidNumericInput = (): NumericValidityCheck => {
      console.log({ submissionRecord })
      if (isScalar) {
         try {
            if ((choice.value as string).length === 0) {
               return { hasError: true, error: "Your entry is blank. Please enter a decimal value.", component: 0 };
            }

            if (isNaN(parseFloat(choice.value as string))) {
               return { hasError: true, error: `Enter a decimal value. ${choice.value} cannot be interpreted. `, component: 0 };
            }

            if (submissionRecord.entries.includes(choice.value as string)) {
               return { hasError: true, error: `You have already tried the value: ${choice.value}`, component: 0 };
            }

         } catch (err) {
            return { hasError: true, error: err as string, component: 0 };
         }
      } else {
         try {
            const ans = choice.value as mjsVectorIJK;
            const submission = [ans.i, ans.j, ans.k];
            for (let i = 0; i < 3; i++) {
               if (submission[i].length === 0) {
                  return { hasError: true, error: "One component has been left blank. Enter zero if this component has no value.", component: i };
               }
               if (isNaN(parseFloat(submission[i]))) {
                  return { hasError: true, error: `Enter a decimal value. ${submission[i]} cannot be interpreted. `, component: i };
               }
            }

            let studentAlreadySubmittedVector = false;
            submissionRecord.entries.forEach((v) => {
               const tmpV = v as unknown as mjsVectorIJK;
               if (tmpV.i === ans.i && tmpV.j === ans.j && tmpV.k === ans.k) {
                  studentAlreadySubmittedVector = true;
               }
            });
            if (studentAlreadySubmittedVector) {
               return { hasError: true, error: `You have already tried the vector: $${ans.i} \\hat{i} + ${ans.j} \\hat{j} + ${ans.k} \\hat{k}$`, component: 0 };
            }
         } catch (err) {
            return { hasError: true, error: err as string, component: 0 };
         }
      }

      return { hasError: false, error: "", component: 0 };
   }

   const formatPreviousEntry = (entryIndex: number) => {
      if (isScalar) {
         const tmpEntry: string = submissionRecord.entries[entryIndex];
         return tmpEntry;
      } else {
         const tmpEntry: mjsVectorIJK = submissionRecord.entries[entryIndex] as unknown as mjsVectorIJK;
         return `$${tmpEntry.i} \\hat{i} + ${tmpEntry.j} \\hat{j} + ${tmpEntry.k} \\hat{k}$`;
      }
   }

   const registerEntryAsSubmitted = () => {
      if (isScalar)
         submissionRecord.entries.push(choice.value as string);
      else
         submissionRecord.entries.push({ ...(choice.value as any) });
   }

   const evalulateCurrentState = () => {
      ansState.value = isScalar ?
         checkScalarAnswer(choice.value as string) :
         checkVectorAnswer(choice.value as mjsVectorIJK);
   };

   return {
      getCorrectUnit,
      isScalar,
      evalulateCurrentState,
      choice,
      ansState,
      checkScalarAnswer,
      checkVectorAnswer,
      getCorrectAnswer,
      isValidNumericInput,
      registerEntryAsSubmitted,
      formatPreviousEntry
   }
}
