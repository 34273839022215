import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";

import aws_exports from "@/aws-exports";

Auth.configure(aws_exports);
Storage.configure(aws_exports);

type S3Level = "public" | "private" | "protected";

export class S3Manager {
   static async saveFile(fileName: string, file: any, level: S3Level = "protected") {
      return await Storage.put(fileName, file, { level: level });
   }

   static async list(directory: string, level: S3Level = "protected") {
      return await Storage.list(directory, { level: level });
   }

   static async getFile(fileName: string, level: S3Level = "protected") {
      return await Storage.get(fileName, { level: level });
   }

   static async deleteFile(fileName: string, level: S3Level = "protected") {
      return await Storage.remove(fileName, { level: level });
   }
}
