<template>
  <draggable v-model="reactiveList"
             item-key="id"
             tag="ul"
             class="list-group list-group-flush item-container dragArea"
             :group="{ name: 'nestableitems' }"
             handle=".handle"
             @change="onChange">
    <template #item="{element, index}">
      <li class="list-group-item item-group"
          :class="{ active: activeId === element.id }"
          :data-id="element.id">
        <div @click="onClick(element.id)">
          <span v-if="activeId === element.id">
            <i class="bi bi-arrows-move handle" />
          </span>
          <span class="prefix">
            {{ prefix + (index+1) }}
          </span>
          <span class="list-item-name">
            {{ " " + element.name }}
          </span>
        </div>
        <BSortableList v-if="nestable"
                       v-model="element.children"
                       :nestable="true"
                       :active-id="activeId"
                       :prefix="prefix + (index+1) + '.'"
                       @click="onClick"
                       @change="onChange" />
      </li>
    </template>
  </draggable>
</template> 

<script lang="ts">
  import draggable from "vuedraggable";
  import { defineComponent, PropType, ref } from "vue";
  import { ChildBearing } from "@/utils/arrays";

  export default defineComponent({
    name: "BSortableList",
    components: {
      draggable,
    },
    props: {
      activeId: {
        type: String,
        required: true
      },
      modelValue: {
        type: Array as PropType<ChildBearing[]>,
        required: true,
      },
      prefix: {
        required: false,
        type: String,
        default: "",
      },
      nestable: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ["click", "change", "update:modelValue"],
    setup(props, context) {
      const reactiveList = ref(props.modelValue)

      const dragging = ref(false);
      const dragOptions = ref({
        animation: 0,
        group: "description",
        disabled: false,
      });

      const onClick = (id: string): void => {
        context.emit("click", id);
      }

      const onChange = (data: unknown): void => {
        context.emit("update:modelValue", reactiveList.value)
        context.emit("change", data);
      }

      return {
        onClick,
        onChange,
        reactiveList,
        dragging,
        dragOptions,
      };
    },
  });
</script>

<style scoped>
  .item-container {
    margin: 0;
  }

  .item {
    background-color: #fefefe;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .dragArea {
    min-height: 5px;
  }

  .list-group-item {
    margin: 0px;
    padding: 0rem 0rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
  }
</style>