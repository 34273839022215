
  import draggable from "vuedraggable";
  import { defineComponent, ref } from 'vue'
  import { bookEditorProps, usePageEditorFunctions } from './usePageEditorFunctions'
  import useCourseMaterials from "@/store/connectors/useCourseMaterials";
  import router from "@/router";
  import BButton from "@/components/bootstrap/b-button.vue";
  import { PageIndex } from "@/store/database/Textbook/table-textbook.interface";
  import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
  import useConfirmationDialog from "@/components/use/useConfirmationDialog";
  import BConfirmDialog from "@/components/bootstrap/b-confirm-dialog.vue";

  export default defineComponent({
    name: "AssignmentEditor",
    components: {
      draggable,
      BButton,
      BConfirmDialog
    },
    props: {
      ...bookEditorProps
    },
    async setup(props) {
      const activeIndex = ref(0);
      const { findBook, findPage } = useCourseMaterials();

      const book = await findBook(props.courseId, props.textId);
      const toc = book?.getTableOfContents();
      if (!toc || TypeGuard.isNullOrUndefined(book)) return;

      const assignmentList = ref(toc.getFlatPageIndexArray() as PageIndex[]);
      console.log(assignmentList)

      function routeTo(inputPageId: string) {
        router.push({
          name: "AssignmentPageEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
          },
        });
      }


      function reloadPage(pageID: string) {
        router.push({
          name: "AssignmentEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId
          },
        });
      }

      const {
        deletePageAndUpdateTOC,
        addItemAndUpdateTOC,
        updateTOC
      } = usePageEditorFunctions(reloadPage);

      // check for consistancy
      const processAssignmentList = () => {
        assignmentList.value = toc.getFlatPageIndexArray() as PageIndex[];

        assignmentList.value.forEach((a) => {
          if (TypeGuard.isNullOrUndefined(book.getPageByID(a.pageID))) {
            console.log("Page not found: ", a.name);
            toc.deleteEntryFor(a.pageID);
            updateTOC({
              name: "Update TOC",
              book,
              toc
            })
          }
        });
      }

      processAssignmentList();


      const onClick = (index: number) => {
        activeIndex.value = index;
      }

      const { confirmDialog, deleteConfirmationModal } = useConfirmationDialog();

      const onDelete = async (index: number) => {
        deleteConfirmationModal(async () => {
          const pageID = assignmentList.value[index].pageID;
          console.log("Assignment Editor", index)
          const pageData = await findPage(props.courseId, props.textId, pageID);
          deletePageAndUpdateTOC({
            book,
            pageData,
            toc
          });
        })
      }


      const onEdit = (index: number) => {
        routeTo(assignmentList.value[index].pageID);
      }

      const onCreate = async () => {
        await addItemAndUpdateTOC({
          book,
          toc
        });

        processAssignmentList();
        return;
      }

      const onChange = (data: { moved: { newIndex: number, oldIndex: number } }) => {
        console.log({ data, toc })

        const newInd = data.moved.newIndex;
        const oldInd = data.moved.oldIndex;

        activeIndex.value = newInd;

        const tocChildren = toc.getRecord()?.pageIndex[0].children;

        if (!TypeGuard.isNullOrUndefined(tocChildren)) {
          const r1 = tocChildren.splice(oldInd, 1);
          tocChildren.splice(newInd, 0, r1[0]);
        }

        updateTOC({
          name: "Update TOC",
          book,
          toc
        })
      }

      return {
        confirmDialog,
        assignmentList,
        activeIndex,
        onCreate,
        onClick,
        onDelete,
        onEdit,
        onChange
      }
    }
  })
