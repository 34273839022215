import * as BABYLON from 'babylonjs';
import { ExperimentInterface } from "../experiments/ExperimentInterface";
import { BaseRenderedObject, RenderedObject, RenderedObjectTypes } from "../primatives/BaseRenderedObject";
import { LabBench } from "../primatives/Furniture/LabBench";
import { MaterialRegistry } from '../primatives/Materials/MaterialRegistry';
import { SceneMetaData } from '../engine/SceneMetaData';
import { Nullable } from 'babylonjs/types';

interface LaboratoryOptions {
	camera: Nullable<BABYLON.UniversalCamera>,
	location?: BABYLON.Vector3,
	floorLength?: number,
	floorWidth?: number,
	ceilingHeight?: number
}


export class BaseLaboratory extends BaseRenderedObject {
	protected experiments: ExperimentInterface[] = [];

	protected options: LaboratoryOptions = {
		camera: null,
		floorLength: 6,   // meters
		floorWidth: 7,    // meters
		ceilingHeight: 5, // meters
		location: BABYLON.Vector3.Zero()
	};


	constructor(name: string, scene: BABYLON.Scene, parent: Nullable<RenderedObject>) {
		super(name, scene, parent);

		this.type = RenderedObjectTypes.LABORATORY;

		// create children
		this.addChild(new LabBench("Lab Bench 1", this.scene, this));
		this.addChild(new LabBench("Lab Bench 2", this.scene, this));
		this.addChild(new LabBench("Lab Bench 3", this.scene, this));
		this.addChild(new LabBench("Lab Bench 4", this.scene, this));
	}

	public setCamera(xPos: number, zPos: number, xTarg: number, yTarg: number, zTarg: number) {
		(this.scene.metadata as SceneMetaData).camera.position.set(xPos, 1.4, zPos);
		(this.scene.metadata as SceneMetaData).camera.setTarget(new BABYLON.Vector3(xTarg, yTarg, zTarg));
	}

	public setOptions(options: LaboratoryOptions) {
		this.options = { ...this.options, ...options };
		return this;
	}


	public updateTime(currentTime: number, deltaTime: number) {
		this.experiments.forEach((v) => {
			v.updateTime(currentTime, deltaTime);
		});
	}

	public render() {
		const floorLength = this.options.floorLength ?? 0;
		const floorWidth = this.options.floorWidth ?? 0;
		const ceilingHeight = this.options.ceilingHeight ?? 0;
		const location = this.options.location ?? BABYLON.Vector3.Zero();


		this.scene.gravity = new BABYLON.Vector3(0, -9.81, 0);
		this.scene.collisionsEnabled = true;

		if (this.options.camera) {
			this.options.camera.applyGravity = true;
			this.options.camera.checkCollisions = true;
		}

		const ground = BABYLON.MeshBuilder.CreateGround("ground", {
			width: this.options.floorLength,
			height: this.options.floorWidth
		}, this.scene);

		const backgroundMaterial = MaterialRegistry.generateDiffTexture(this.scene,
			"groundMaterial",
			"https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Concrete.jpg",
			5,
			5);

		ground.material = backgroundMaterial;

		const room = BABYLON.MeshBuilder.CreateBox("Room", {
			width: this.options.floorLength,
			height: this.options.ceilingHeight,
			depth: this.options.floorWidth
		}, this.scene);

		room.position = location.clone();
		room.position.y += 1.02 * ceilingHeight / 2.0;

		ground.addChild(room);
		ground.checkCollisions = true;

		// place the room just below the ground surfacee
		const roomMaterial = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(1, 1, 0.95)).clone("Room");
		roomMaterial.backFaceCulling = false;
		room.material = roomMaterial;

		room.position.y = -0.01 * ceilingHeight;

		// add a skybox for liquid reflections
		const skybox = BABYLON.Mesh.CreateBox("reflectionSkyBox", 900.0, this.scene);
		const skyboxMaterial = new BABYLON.StandardMaterial("skyBox", this.scene);
		skyboxMaterial.backFaceCulling = false;
		skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("//www.babylonjs.com/assets/environment.dds", this.scene);
		skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
		skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
		skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
		skyboxMaterial.disableLighting = true;
		skybox.material = skyboxMaterial;

		// add a bench
		this.children[0].setOptions({
			width: floorLength - 2 * 0.5,
			bottomCenterLocation: new BABYLON.Vector3(0, 0, floorWidth / 2.0 - 0.25)
		});

		// sidebench
		this.children[1].setOptions({
			width: floorWidth - 2 * 0.5,
			rotationY: -Math.PI / 2.0,
			bottomCenterLocation: new BABYLON.Vector3(-floorLength / 2.0 + 0.25, 0, 0)
		});

		// side bench
		this.children[2].setOptions({
			width: floorWidth - 2 * 0.5,
			rotationY: Math.PI / 2.0,
			bottomCenterLocation: new BABYLON.Vector3(floorLength / 2.0 - 0.25, 0, 0)
		});

		// back bench
		this.children[3].setOptions({
			width: floorLength - 2 * 0.5,
			rotationY: Math.PI,
			bottomCenterLocation: new BABYLON.Vector3(0, 0, - floorWidth / 2.0 + 0.25)
		});

		this.myContainerNode = ground;

		this.children.forEach((v) => {
			v.render();
		});

		return super.render();
	}
}
