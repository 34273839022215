<template>
  <div v-for="(item, index) in listOfRequestedUserResponses"
       :key="item"
       class="container-fluid">
    <component :is="getResponseType(item.selectedTypeIndicator)"
               :part-nr="listOfRequestedUserResponses.length > 1 ? index : -1"
               :question-part-data="item"
               :question-model="questionModel"
               :is-finished="isFinished(index)"
               :submission-record="submissionRecords[index]"
               @submit="emitWithAddToPayload('submit', $event, {index})" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import MultipleChoice from "./UserResponseMultipleChoice.vue";
  import NumericalInput from "./UserResponseNumericalInput.vue";
  import HandwritingInput from "./UserResponseHandwriting.vue";


  import BButton from "@/components/bootstrap/b-button.vue";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import useEvents from "@/components/use/useEvents";
  import {
    UserResponseSetupData,
    UserResponseType,
  } from "./user-response.interface";
  import { TypeGuard } from "../../mathjs/Type-guards";

  export default defineComponent({
    components: {
      MultipleChoice,
      NumericalInput,
      HandwritingInput,
      BButton,
    },
    props: {
      requestedUserResponses: {
        type: Array as PropType<UserResponseSetupData[]>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecords: {
        type: Object as PropType<SumissionQuestionPart[]>,
        required: true,
      },
      allowInput: {
        type: Boolean,
        required: true
      }
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const listOfRequestedUserResponses = ref(props.requestedUserResponses);
      console.debug("List of Requested User Responses: ", props.requestedUserResponses);

      const getResponseType = (type: UserResponseType) => {
        console.debug("Response type", type);
        switch (type) {
          case UserResponseType.MULTIPLE_CHOICE:
            return "MultipleChoice";
          case UserResponseType.HANDWRITING:
            return "HandwritingInput";
          case UserResponseType.INPUT_TEXT:
          case UserResponseType.VECTOR:
          case UserResponseType.SCALAR:
            return "NumericalInput";
          default:
            break;
        }
      };

      const { emitWithAddToPayload } = useEvents(context);

      const isFinished = (index: number) => {
        if (!props.allowInput) {
          return true;
        }

        if (TypeGuard.isNullOrUndefined(props.submissionRecords)) {
          return false;
        }


        const maxNrAttempts = Math.floor(100.0 / listOfRequestedUserResponses.value[index].partialPMper);
        //TODO add max nr attempts (from requestedUserResponses here)
        const nrAttemptsLeft = maxNrAttempts - props.submissionRecords[index].nrAttempts;
        const val = props.submissionRecords[index].scoreInPercent !== 0 || nrAttemptsLeft <= 0;
        return val;
      };

      return {
        isFinished,
        emitWithAddToPayload,
        getResponseType,
        listOfRequestedUserResponses,
      };
    },
  });
</script>

<style scoped>
</style>