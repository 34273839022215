
  import { defineComponent } from 'vue'


  export default defineComponent({
    props: {
      label: {
        type: String,
        default: ""
      },
      ulClass: {
        type: String,
        default: ""
      }
    },
    emits: ["click"],
    setup(props) {
      return {}
    }
  })
