<template>
  <button v-for="item in buttonList"
          :key="item"
          type="button"
          :class="'btn ' + item.class"
          :disabled="!item.isEnabled"
          @click="handleClick(item)">
    {{ item.label }}
  </button>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import { FormButtonAction } from "../form.interface";

  export default defineComponent({
    name: "FormButtonGroup",
    props: {
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        required: true,
      },
    },
    emits: ["btnPress"],
    methods: {
      handleClick(buttonItem: FormButtonAction) {
        this.$emit("btnPress", buttonItem);
      },
    },
  });
</script>

<style lang="">
</style>