import { Submissions, SubmissionType, UserPermissions } from "@/API";
import { bTableFields } from "@/components/bootstrap/table/b-table.interface";
import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { PageComponent, PageComponentTypes, Page_QuizComponent } from "@/components/contentGenerator/pages/pages.interface";
import { BaseEventI } from "@/components/global/event.interface";
import usePageComponents from "@/components/use/usePageComponents";
import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
import { PageRecord } from "@/store/database/Textbook/record-page";
import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
import { subscribeSubmission } from '@/graphql/subscriptions';
import API from "@aws-amplify/api";
import { Observable } from 'zen-observable-ts';
import { getAllCourseAssignmentSubmissions, getBatchOfAssignmentSubmissions } from "@/graphql/queries";
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';

export interface userLookupRecord {
   firstName: string;
   lastName: string;
   userName: string;
}

export interface QuestionWeight {
   qID: string;
   percentOfGrade: number;
}

export interface ReturnAssignmentQuestionData {
   earnedPercent: number;
}

export interface ReturnAssignmentData {
   searchID: string;
   seed: number;
   fieldType: SubmissionType;
   userEmail: string;
   data: Record<string, ReturnAssignmentQuestionData>;
}

export interface GradedQuestion {
   score: number;
   version: string;
}

export interface GradedAssignement {
   componentID: string;
   grade: number;
   hasAccomodation: boolean;
   questionGrades: Record<string, GradedQuestion>;
}

export interface SetupReturnData {
   isAdmin: boolean | undefined,
   downloadExcel: () => void,
   assignFields: Partial<bTableFields>[],
   assignmentScores: Record<string, unknown>[],
   handleClick: (event: BaseEventI<string, {
      rowIndex: number,
      colIndex: number,
      rowData: Record<string, unknown>,
      field: unknown,
   }>) => void
}

export async function useGradedAssignments(courseId: string, userName: string) {
   const { assignmentBook } = await useDatabaseMappings()
      .getCourseMaterials(
         userName,
         courseId
      );

   const assignments = assignmentBook?.getFirstLevelPages();

   const { getActiveComponentsFromPageData } = usePageComponents();
   const pageComponents: PageComponent[] = [];
   let lookupComponentData: string[] = [];

   const assignmentToComponentMap: Record<string, string> = {};

   const assignFields: Partial<bTableFields>[] = [];
   const sortable = false;

   assignFields.push({
      key: "lastName",
      label: "Last Name",
      sortable: sortable,
      freezeColumn: true,
      includeInFilter: true,
   });

   assignFields.push({
      key: "firstName",
      label: "First Name",
      sortable: sortable,
      freezeColumn: false,
      includeInFilter: true,
   });


   assignFields.push({
      key: "userEmail",
      label: "Email",
      sortable: sortable,
      freezeColumn: false,
      includeInFilter: true,
   });

   const createListOfComponents = () => {
      lookupComponentData = [];
      assignments?.forEach((v) => {
         const component = getActiveComponentsFromPageData(
            (v.data() as unknown) as PageFromTextbookTable
         ).components[0];
         if (!TypeGuard.isNullOrUndefined(component)) {
            lookupComponentData.push(component.id);
            assignmentToComponentMap[v.id()] = component.id;
         }
      });
   }

   const getAssignmentToComponentMap = () => {
      return assignmentToComponentMap;
   }

   const addAssignmentPartsToTable = (assignment: PageRecord) => {

      const component = getActiveComponentsFromPageData(
         (assignment.data() as unknown) as PageFromTextbookTable
      ).components[0];

      if (component && component.id && component.type === PageComponentTypes.QUESTION_BLOCK) {
         pageComponents.push(component);

         (component.data as any).array.forEach((v: any) => {
            assignFields.push({
               key: v.id,
               label: v.name,
               sortable: sortable,
               dynamicItemClass: (value: unknown, key: string, row: Record<string, unknown>) => {
                  return parseFloat(value as string) < 60 ? "text-danger" : ""
               },
               meta: {
                  textID: assignment.data().textID as string,
                  assignmentID: assignment.id(),
                  componentName: component.name
               }
            });
         })


         lookupComponentData.push(component.id);

         assignmentToComponentMap[assignment.id()] = component.id;
      }
   }

   const addAssignmentToTable = (assignment: PageRecord) => {
      const component = getActiveComponentsFromPageData(
         (assignment.data() as unknown) as PageFromTextbookTable
      ).components[0];

      if (component && component.id) {
         pageComponents.push(component);

         assignFields.push({
            key: component.id,
            label: assignment.data().name,
            sortable: sortable,
            dynamicItemClass: (value: any, key: string, row: Record<string, unknown>) => {
               return (value && parseFloat(value.grade as string) < 60) ? "text-danger" : ""
            },
            dynamicHTML: (value: any, key: string, row: Record<string, unknown>) => {
               if (value && value.hasAccomodation) {
                  return `<span>${value.grade}</span><span class="badge rounded-pill bg-warning text-dark float-end">A</span>`;
               }

               return `<div>${value ? value.grade : 0}</div>`;
            },
            meta: {
               textID: assignment.data().textID as string,
               assignmentID: assignment.id()
            }
         });

         lookupComponentData.push(component.id);

         assignmentToComponentMap[assignment.id()] = component.id;
      }
   }

   const getAllStudentAssignments = async () => {

      const result = await GraphQLAPI.graphql(graphqlOperation(getAllCourseAssignmentSubmissions,
         {
            courseID: courseId,
         })) as { data: { getAllCourseAssignmentSubmissions: [ReturnAssignmentData] } };

      console.log({ StudentAssignments: result.data.getAllCourseAssignmentSubmissions });
      return result.data.getAllCourseAssignmentSubmissions;
   }

   const getAllStudentAssignmentsFor = async () => {
      const result = await GraphQLAPI.graphql(graphqlOperation(getBatchOfAssignmentSubmissions,
         {
            courseID: courseId,
            assignments: lookupComponentData,
         })) as { data: { getBatchOfAssignmentSubmissions: [ReturnAssignmentData] } };

      console.log(result)
      return result.data.getBatchOfAssignmentSubmissions;
   }


   const getStudentAssignments = async () => {
      const result = await GraphQLAPI.graphql(graphqlOperation(getBatchOfAssignmentSubmissions,
         {
            courseID: courseId,
            assignments: lookupComponentData,
         })) as { data: { getBatchOfAssignmentSubmissions: [ReturnAssignmentData] } };

      console.log({ result })
      return result.data.getBatchOfAssignmentSubmissions;
   }

   const findMostRecentGradedAssignments = (unorganizedAssignmentGrades: ReturnAssignmentData[]) => {
      // pick out the latest question version for each assignment
      const gradedAssignments: Record<string, GradedAssignement[]> = {};
      const userOrganizedAssignmentGrades: Record<string, ReturnAssignmentData[]> = {};


      unorganizedAssignmentGrades
         .forEach((assignmentGrade) => {
            const tmpUserName = assignmentGrade.userEmail;
            if (TypeGuard.isString(assignmentGrade.data)) {
               assignmentGrade.data = JSON.parse(assignmentGrade.data);
            }

            if (TypeGuard.hasProp(userOrganizedAssignmentGrades, tmpUserName)) {
               userOrganizedAssignmentGrades[tmpUserName].push(assignmentGrade);
            } else {
               gradedAssignments[tmpUserName] = [];
               userOrganizedAssignmentGrades[tmpUserName] = [assignmentGrade];
            }
         });

      Object.keys(userOrganizedAssignmentGrades)
         .forEach(userEmail => {
            userOrganizedAssignmentGrades[userEmail]
               .forEach((assignmentGrade) => {
                  if (assignmentGrade.fieldType === SubmissionType.QUESTION)
                     return;

                  gradedAssignments[userEmail]
                     .push(reduceToGradedAssignment(assignmentGrade));
               })
         });

      return gradedAssignments;
   }

   function reduceToGradedAssignment(sumbission: ReturnAssignmentData) {
      const keys = Object.keys(sumbission.data);
      const qScores: Record<string, GradedQuestion> = {};

      keys.forEach((key: string) => {
         const spl = key.split("#");
         const qID = spl[0];
         const version = spl[1];
         const score = sumbission.data[key].earnedPercent;
         if (
            !Object.prototype.hasOwnProperty.call(qScores, qID) ||
            qScores[qID].version < version
         ) {
            qScores[qID] = {
               score,
               version,
            };
         }
      });

      return {
         componentID: sumbission.searchID.split("#")[0],
         grade: 0,
         questionGrades: qScores,
         hasAccomodation: !TypeGuard.isNullOrUndefined((sumbission as any).accomodationType)
      };
   }

   const computeGradesForAssignmentParts = (registeredUsers: UserPermissions[],
      assignmentId: string,
      userSortedGradedAssignments: Record<string, GradedAssignement[]>) => {

      const rows: Record<string, unknown>[] = [];
      const componentId = assignmentToComponentMap[assignmentId];


      registeredUsers.forEach((user) => {
         const profileData = JSON.parse(user.profile as string);
         const scoreRow: Record<string, unknown> = {
            firstName: profileData.first_name,
            lastName: profileData.last_name,
            userEmail: user?.userEmail,
            userID: user?.id
         };

         const gradedAssignments = userSortedGradedAssignments[user.id as string];

         if (gradedAssignments) {
            // find the stored assignment grade that cooresponds to this component
            const gradedAssignment = gradedAssignments.find(
               (v) => v.componentID === componentId
            );

            const questionGrades = gradedAssignment?.questionGrades;

            Object.entries(questionGrades ?? [])
               .forEach((v) => scoreRow[v[0]] = Math.floor(v[1].score * 10) / 10);

            rows.push(scoreRow);
         }
      });

      return rows;
   }


   const computeGradesForEachAssignment = (registeredUsers: UserPermissions[],
      userSortedGradedAssignments: Record<string, GradedAssignement[]>) => {

      const rows: Record<string, unknown>[] = [];


      registeredUsers.forEach((user) => {
         const profileData = JSON.parse(user.profile as string);
         const scoreRow: Record<string, unknown> = {
            firstName: profileData.first_name,
            lastName: profileData.last_name,
            userEmail: user?.userEmail,
            userID: user?.id
         };

         //console.log("Compute assignment part scores")
         const gradedAssignments = userSortedGradedAssignments[user.id as string];

         if (gradedAssignments) {
            pageComponents?.forEach((component) => {
               if (component.type !== PageComponentTypes.QUESTION_BLOCK)
                  return;

               // find the stored assignment grade that cooresponds to this component
               const gradedAssignment = gradedAssignments.find(
                  (v) => v.componentID === component.id
               );

               const questionWeightList = getQuestionWeigthList(component as Page_QuizComponent);

               // sum the questions for this component using the question weights
               const assignmentScore = reduceToNumericalGrade(gradedAssignment as GradedAssignement, questionWeightList);
               scoreRow[component.id as string] = {
                  hasAccomodation: gradedAssignment ? gradedAssignment.hasAccomodation : false,
                  grade: Math.floor((assignmentScore ?? 0) * 100) / 100
               }
            });

            rows.push(scoreRow);
         }
      });

      return rows;
   }



   const reComputeSubmissionGrade = (submission: ReturnAssignmentData) => {
      if (TypeGuard.isString(submission.data)) {
         submission.data = JSON.parse(submission.data);
      }

      const gradedAssignmentSubmission = reduceToGradedAssignment(submission);
      const filteredList = pageComponents?.filter((component) => gradedAssignmentSubmission.componentID === component.id);
      if (filteredList.length !== 1)
         return 0;

      const component = filteredList[0];
      const questionWeightList = getQuestionWeigthList(component as Page_QuizComponent);
      return reduceToNumericalGrade(gradedAssignmentSubmission, questionWeightList);
   }

   const getQuestionWeigthList = (component: Page_QuizComponent) => {
      // find all questions listed in this component
      const qBankInfo = (component as Page_QuizComponent).data?.array;
      //console.log("qBankInfo ", qBankInfo);

      return qBankInfo?.map((v) => {
         return { qID: v.id };
      }) as { qID: string; percentOfGrade: number }[];

   }

   const reduceToNumericalGrade = (gradedAssignmentSubmission: GradedAssignement, questionWeightList: QuestionWeight[]) => {
      // sum the questions for this component using the question weights
      return questionWeightList?.reduce((accumulator, qData) => {
         const qID = qData.qID;
         const qScore = gradedAssignmentSubmission?.questionGrades[qID]?.score;
         const weight = qData.percentOfGrade
            ? qData.percentOfGrade
            : 100 / questionWeightList.length;

         //console.log(qID, qScore, weight);
         return accumulator + (qScore ? ((qScore as number) * weight) / 100 : 0);
      }, 0);
   }

   const subscribeToSubmissions = async (courseID: string, onNewData: (item: Submissions) => void) => {

      const observable = await (API
         .graphql(graphqlOperation(subscribeSubmission, {
            courseID
         })) as Observable<unknown>)

      const subscription = observable
         .subscribe({
            next: (data: { value: { data: { subscribeSubmission: Submissions } } }) => {
               console.log({ data })
               onNewData(data.value.data.subscribeSubmission);
            },
            error: error => console.warn(error)
         });

      return () => { subscription.unsubscribe() };
   }

   return {
      getStudentAssignments,
      getAssignmentToComponentMap,
      addAssignmentPartsToTable,
      addAssignmentToTable,
      assignFields,
      sortable,
      pageComponents,
      createListOfComponents,
      lookupComponentData,
      assignments,
      getAllStudentAssignmentsFor,
      getAllStudentAssignments,
      findMostRecentGradedAssignments,
      computeGradesForEachAssignment,
      subscribeToSubmissions,
      reComputeSubmissionGrade,
      computeGradesForAssignmentParts
   }
}