<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="[]">
    <hr>
  </form-input-group>
</template>

<script lang="ts">
  import formInputGroup from "./form-input-layout.vue";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormInputDivider",
    components: { formInputGroup },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(): Record<string, unknown> {
      return {};
    },
  });
</script>
<style lang="">
</style>