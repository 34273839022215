export const listTextbooksAllContent = /* GraphQL */ `
  query ListTextbooks2(
    $textID: ID
    $fieldTypeId: ModelTextbookPrimaryCompositeKeyConditionInput
    $filter: ModelTextbookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTextbooks(
      textID: $textID
      fieldTypeId: $fieldTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
         id
         textID
         name
         description
         long_description
         fieldType
         txt_pictureURL
         lab_pictureURL
         toc_pageIndex
         page_isActive
         page_activeContent {
            createdOn
            updatedOn
            creator
            content
         }
         page_draftContent {
            createdOn
            updatedOn
            creator
            content
         }
         page_history {
            createdOn
            updatedOn
            creator
            content
         }
         asn_options {
            isGroup
            formOwnGroups
            maxGroupMembers
            requiresGroupNotebook
            openOn
            dueOn
            isTimed
            timeLimit
            maxPoints
            maxAttempts
            catagory
            showSolutions
            showScoreBreakdown
            useHandwritingEntry
            randomizeQuestions
            randomizeAnswers
         }
         owner
         ttlExpireTime
         createdAt
         updatedAt
      }
      nextToken
    }
  }
`;
export const byCourseFieldType2 = /* GraphQL */ `
  query ByCourseFieldType(
    $fieldType: FieldType
    $userEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCourseFieldType(
      fieldType: $fieldType
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        fieldType
        name
        description
        long_description
        availibility {
          restrictionType
          inputCode
          userEmailMatch
          userEmailList
          cost
          stripeProductID
          stripePriceID
          restrictToDates
          showOn
          openOn
          closeOn
          hideOn
        }
        isActive
        userEmail
        co_editors
        co_pictureURL
        co_includeText
        co_textbookID
        co_includeAssignments
        co_assignmentBook
        co_includeLab
        co_laboratoryID
        co_questionBankID
        co_includeNotebook
        co_topics {
          id
          name
          subTopics {
            id
            name
          }
        }
        assign_data
        reg_initDate
        reg_expiration
        reg_role
        note_bookID
        ttlExpireTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
