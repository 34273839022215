import * as BABYLON from 'babylonjs';

import { BasePrimativeObjectGenerator } from "../Factories/PrimativeFactoryInterface";
import { CylinderGenerator } from "../CylinderGenerator";
import { MaterialRegistry } from "../Materials/MaterialRegistry";



export interface PipeNozzleOptions {
    name?: string,
    startPoint: BABYLON.Vector3,
    endPoint: BABYLON.Vector3,
    baseDiameter: number,
    nozzleDiameter: number,
}

export class Pipe_Nozzle extends BasePrimativeObjectGenerator {
    public defaultPrimOptions: PipeNozzleOptions = {
        name: "",
        startPoint: BABYLON.Vector3.Zero(),
        endPoint: BABYLON.Vector3.Zero(),
        nozzleDiameter: 0.0254,
        baseDiameter: 0.0254
    }

    constructor(scene: BABYLON.Scene) {
        super(scene);
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        return;
    }

    public create(parent: BABYLON.Mesh, options: PipeNozzleOptions) {
        options = { ...this.defaultPrimOptions, ...options };
        const midPoint = options.endPoint.subtract(options.startPoint).scale(0.5).add(options.startPoint);
        const base = CylinderGenerator.create(this.scene,
            midPoint, options.endPoint, options.baseDiameter, 10);

        const nozzle = CylinderGenerator.createTapered(this.scene,
            options.startPoint, midPoint, options.nozzleDiameter, options.baseDiameter, 10);
        nozzle.material = MaterialRegistry.getSilver(this.scene);
        nozzle.material.backFaceCulling = false;
        nozzle.setParent(base);

        base.setParent(parent);
        base.material = MaterialRegistry.getSilver(this.scene);
        return base;
    }
}