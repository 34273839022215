<template>
  <div :class="getLabelColumnClasses()">
    <label v-show="!schemaitem.properties.hideLabel"
           :for="fid"
           class="form-label">
      {{ label }}
    </label>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType, ref } from "vue";
  import { FormSchemaItem } from "../form-schema.interface";

  export default defineComponent({
    name: "FormInputLayoutBaseLabel",
    props: {
      fid: {
        type: String,
        required: true,
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        default: null,
      },
    },
    setup(props) {
      const label = ref(props.schemaitem.label);

      const getLabelColumnClasses = (): string => {
        switch (props.schemaitem?.properties?.labelAlign) {
          case "right":
          case "left":
            return "col-" + props.schemaitem?.properties?.labelCols;
          case "top":
          case "bottom":
            return "col-12";
          default:
            return "col-12";
        }
      };

      return {
        label,
        getLabelColumnClasses,
      };
    },
  });
</script>
<style lang="css">
</style>