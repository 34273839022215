<template>
  <BTable :fields="assignFields"
          class="m-0 p-0"
          style="max-width:2000px"
          :data="assignmentGrades"
          table-classes="table-bordered table-striped table-hover"
          @click="handleClick" />
  <b-modal-view v-if="showQuestion"
                :key="showQuestion"
                v-model:show="showQuestion"
                :title="modalTitle">
    <template #body>
      <div class="questionContainer">
        <QuestionViewer :key="injectQuestionID"
                        :question-data="injectQuestionData"
                        :course-id="courseId"
                        :question-bank-id="injectQuestionBankID"
                        :component-id="quizComponentId"
                        :injected-user-id="injectUserId"
                        :show-solution="true" />
      </div>
    </template>
  </b-modal-view>
</template>

<script lang="ts">
  import { UserPermissions } from "@/API";
  import BTable from "@/components/bootstrap/table/b-table.vue";
  import { PageRecord } from "@/store/database/Textbook/record-page";
  import { defineComponent, PropType, reactive, Ref, ref } from "vue";
  import { GradedAssignement, useGradedAssignments } from "./useGradedAssignments";
  import { sort } from 'fast-sort';
  import { BaseEventI } from "@/components/global/event.interface";
  import { SubmissionItem } from "@/store/database/Submission/submitted-item.interface";
  import BModalView from "@/components/bootstrap/b-modal-view.vue";
  import QuestionViewer from "@/components/contentGenerator/questions/QuestionViewer.vue";
  import usePageComponents from "@/components/use/usePageComponents";
  import { PageComponentTypes, Page_QuizComponent } from "@/components/contentGenerator/pages/pages.interface";
  import useQuestionsFromQuizComponent from "@/components/use/useQuestionsFromQuizComponent";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";

  export default defineComponent({
    components: { BTable, BModalView, QuestionViewer },
    props: {
      userName: {
        type: String,
        required: true
      },
      courseId: {
        type: String,
        required: true,
      },
      assignmentId: {
        type: String,
        required: true
      },
      assignments: {
        type: Array as PropType<PageRecord[]>,
        required: true
      },
      gradedAssignments: {
        type: Object as PropType<Record<string, GradedAssignement[]>>,
        required: true
      },
      registeredUsers: {
        type: Array as PropType<UserPermissions[]>,
        required: true
      }
    },
    async setup(props) {
      const modalTitle = ref("");
      const showQuestion: Ref<boolean> = ref(false);
      const inFocusAssignment: Ref<null | PageRecord> = ref(null);
      const injectSubmission: Ref<null | SubmissionItem> = ref(null);
      const injectQuestionData: Ref<null | PageFromTextbookTable> = ref(null);
      const injectQuestionBankID: Ref<string> = ref("");
      const injectQuestionID: Ref<string> = ref("");
      const injectUserId: Ref<string> = ref("");

      const {
        assignFields,
        addAssignmentPartsToTable,
        assignments,
        computeGradesForAssignmentParts
      } = await useGradedAssignments(props.courseId, props.userName);

      assignments?.forEach((assignment): void => {
        if (assignment.data().id === props.assignmentId) {
          addAssignmentPartsToTable(assignment);
          inFocusAssignment.value = assignment;
        }
      });

      let assignmentGrades =
        reactive(computeGradesForAssignmentParts(props.registeredUsers,
          props.assignmentId,
          props.gradedAssignments));

      assignmentGrades = sort(assignmentGrades).asc((u: any) => u.lastName.toLowerCase());


      // pull questions
      const { getFirstComponentOfType } = usePageComponents();

      const quizComponent = getFirstComponentOfType(PageComponentTypes.QUESTION_BLOCK,
        inFocusAssignment.value!);

      const {
        quizComponentId,
        questionIDs,
        questionBankInfo,
        pullQuestions
      } = useQuestionsFromQuizComponent(quizComponent as Page_QuizComponent);

      const questions = await pullQuestions();


      const handleClick = (event: BaseEventI<string, {
        rowIndex: number,
        colIndex: number,
        rowData: Record<string, unknown>,
        field: unknown,
      }>) => {
        if (!event.payload) return;

        if (event.type === "clickRow") {
          injectQuestionData.value = questions[event.payload.colIndex - 3];
          injectQuestionBankID.value = questionBankInfo.value[0].questionBankID;
          injectQuestionID.value = "";

          console.log()

          modalTitle.value = (event.payload.rowData["lastName"] as string) + ":  "
            + (event.payload.field as any).label;
          injectUserId.value = event.payload.rowData["userID"] as string;

          showQuestion.value = true;
          console.log({ injectUserId, showQuestion })

        }
      }

      return {
        modalTitle,
        showQuestion,
        quizComponentId,
        injectSubmission,
        injectQuestionData,
        handleClick,
        assignFields,
        assignmentGrades,
        injectQuestionBankID,
        injectQuestionID,
        injectUserId
      };
    },
  });
</script>

<style scoped>
  .questionContainer {
    max-height: 500px;
  }
</style>