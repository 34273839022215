
  import { defineComponent, EmitsOptions, onMounted, ref, Ref, SetupContext } from 'vue'
  import Editor from '@toast-ui/editor';
  import '@toast-ui/editor/dist/toastui-editor.css';

  export default defineComponent({
    name: "BMarkdownEditor",
    props: {
      content: {
        type: String,
        required: true
      }
    },
    emits: ["change", "update:content"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const mdeditor: Ref<HTMLElement | null> = ref(null);

      onMounted(() => {
        if (mdeditor.value === null) {
          console.debug("Unable to find reference to toastuiEditor")
          return;
        }
        let editor: Editor | null = null;
        editor = new Editor({
          el: mdeditor.value,
          initialEditType: 'markdown',
          initialValue: props.content,
          height: '800px',
          previewStyle: 'vertical',
          events: {
            change: () => {
              if (editor === null) return;
              context.emit("change");
              context.emit("update:content", editor.getMarkdown());
            }
          }
        })
      });


      return {
        mdeditor
      }
    }
  })
