/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f6ec6c49-df32-4979-858a-89e72c3e009d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ia8DFnaOJ",
    "aws_user_pools_web_client_id": "5ge30ium2uq74s14fthismgh8o",
    "oauth": {},
    "aws_content_delivery_bucket": "vengla-com",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dy6ou97gxznzx.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://fjjkpcdhw5ffhij5kp7xsby35y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456",
    "aws_cloud_logic_custom": [
        {
            "name": "CheckoutComplete",
            "endpoint": "https://76ms92dqv3.execute-api.us-east-1.amazonaws.com/com",
            "region": "us-east-1"
        },
        {
            "name": "UpdateCoursePrice",
            "endpoint": "https://vae1iwr4l5.execute-api.us-east-1.amazonaws.com/com",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "vegla-media-2312j-an38-jkp3u175728-com",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
