<template>
  <div>
    <form-input-array-of-objects v-model:modelValue="selectedItems"
                                 :displaybehavior="displaybehavior"
                                 :schemaitem="schemaitem"
                                 :triggers="triggers"
                                 add-button-label="Select"
                                 :add-function="addFunction"
                                 @update:modelValue="onChange" />
  </div>
  <div v-if="showCatalog"
       class="modal fade show modal-active"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            <h4> {{ schemaitem.label }} </h4>
          </div>
          <input v-model="filterText"
                 type="search"
                 placeholder="Search"
                 class="form-control">
        </div>
        <div class="modal-body">
          <b-catalog v-if="showCatalog"
                     :model-value="selectedItems"
                     :item-list="filterItemList"
                     :item-entry-maker="makeNewItemEntry" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  type="button"
                  @click="showCatalog=false">
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import BCatalog from '@/components/bootstrap/b-catalog.vue'
  import { defineComponent, EmitsOptions, reactive, ref, SetupContext, watch } from 'vue'
  import { formItemMixinProps } from '../mixins/form.mixins'
  import formInputArrayOfObjects from './form-input-arrayOfObjects.vue'

  export default defineComponent({
    name: "FormSelectCatalog",
    components: { formInputArrayOfObjects, BCatalog },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const emptyFunc = () => { return; }
      const showCatalog = ref(false);
      const catalogItemList = ref(props.schemaitem.catalog?.getCatalogRecords() || []);
      const filterItemList = ref(catalogItemList.value);
      const makeNewItemEntry = ref(props.schemaitem.catalog?.makeNewItemEntry || emptyFunc);

      const selectedItems = reactive((props.schemaitem.value as []).map((v) => {
        return makeNewItemEntry.value(v);
      }));

      const filterText = ref("");

      watch(filterText, () => {
        const searchTerm = filterText.value.toLowerCase();
        filterItemList.value = catalogItemList.value.filter((v) => {
          return v.name.toLowerCase().includes(searchTerm);
        });
      });

      const addFunction = () => {
        showCatalog.value = true;
        return;
      }

      const onChange = () => {
        context.emit("update:modelValue", selectedItems);
      }


      return {
        showCatalog,
        onChange,
        selectedItems,
        addFunction,
        makeNewItemEntry,
        filterText,
        filterItemList
      }
    }
  })
</script>

<style scoped>
  .b-overlay {
    background-color: #0000004a;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in;
    left: 0;
    top: 0;
    z-index: 999999999999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: baseline;
  }
  .modal-active {
    display: block;
  }
</style>