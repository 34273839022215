import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface"
import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker"
import useLogin from "@/components/use/useLogin";
import useSchema from "@/components/use/useSchema";
import { LoginActionTypes, LoginPageParameterType } from "./login.interface";

export const submitResetPasswordButton = {
    class: "btn-success m-2 save float-end",
    label: "Submit",
    triggerEvent: {
        originatingAction: "Submit Pass Reset Button",
        type: LoginActionTypes.RESET_PASSWORD_SUBMIT_CONFIRMATION_ACTION,
        payload: null
    },
    isEnabled: true,
    enabledWhenFormIsValid: true,
};

export const submitResetPasswordSchema = (userEmail: string): FormSchemaItem => {
    return FormSchemaMaker.Group(
        {
            label: "Reset your password",
            field: "data",
            properties: {
                classes: "m-1",
                hideLabel: false
            },
        },
        [
            FormSchemaMaker.ReadOnly({
                name: "username",
                label: "Email Address",
                field: "emailValue",
                properties: { labelAlign: "top" },
                rules: { required: true, email: true },
                value: userEmail,
                placeHolder: "Enter your email address",
                autocomplete: "OFF"
            }),
            FormSchemaMaker.Text({
                label: "Verification code",
                field: "verificationCode",
                properties: { labelAlign: "top" },
                rules: { required: true },
                placeHolder: "Check your email for a verification code.",
                value: "",
                autocomplete: "OFF"
            }),
            FormSchemaMaker.Password({
                name: "password",
                label: "New Password",
                field: "passwordValue",
                properties: { labelAlign: "top" },
                rules: { required: true, min: 8, max: 32 },
                value: "",
                placeHolder: "Enter your password",
                autocomplete: "OFF"
            }),
        ]
    )
}

export const submitPasswordResetConfirmationPage = (options: LoginPageParameterType): void => {
    const userName = options.originationEvent?.payload?.username;
    options.currentSchema.value = submitResetPasswordSchema((userName) ? userName : "");

    options.buttonFooter.value = {
        infoText: "",
        linkText: "Back to Sign In",
        eventType: {
            originatingAction: "Submit Pass Reset Link",
            type: LoginActionTypes.GOTO_SIGN_IN_PAGE
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
            originatingAction: "Submit Pass Reset Footer",
            type: LoginActionTypes.EMPTY
        },
    };
    options.buttonFooter2.value = null;
    options.buttonList[0] = submitResetPasswordButton;
    options.enterKeyAction.value.triggerEvent.type = LoginActionTypes.GOTO_RESET_PASSWORD_PAGE;
}

export const submitPasswordResetConfirmationAction = async (options: LoginPageParameterType): Promise<void> => {
    const { resetPasswordWithConf } = useLogin();
    const { schema_getJSON } = useSchema();

    if (options.originationEvent?.payload?.schemaItem) {
        const data = schema_getJSON(options.originationEvent.payload.schemaItem).data;
        const result = await resetPasswordWithConf(
            data.emailValue as string,
            data.verificationCode as string,
            data.passwordValue as string
        );

        if (result.hasError()) {
            options.errorMessage.value = result.getFlatErrorMessage();
        } else {
            options.onPress({
                originatingAction: "Submit Pass Reset On Success",
                type: LoginActionTypes.GOTO_SIGN_IN_PAGE
            });
        }

    }
}

