
  import { defineComponent, Ref, ref } from "vue";

  export default defineComponent({
    setup() {
      const file = ref(null) as any;
      const filelist = ref([]) as Ref<string[]>;
      const onChange = () => {
        if (file.value && file.value?.files) {
          filelist.value = [...file.value?.files];
        }
        console.log("test");
      };

      const remove = (i: number) => {
        filelist.value.splice(i, 1);
      };

      const drop = (event: DragEvent) => {
        event.preventDefault();
        file.value.files = event.dataTransfer?.files;
        onChange();
      };

      const dragover = () => {
        //event.preventDefault();
      };

      return {
        file,
        filelist,
        onChange,
        remove,
        drop,
        dragover,
      };
    },
  });
