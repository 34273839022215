import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useLogin from "@/components/use/useLogin";
import useSchema from "@/components/use/useSchema";
import { LoginActionTypes, LoginPageParameterType } from "./login.interface";
import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";

export const confirmUserButton = {
    class: "btn-success m-2 save float-end",
    label: "Confirm",
    triggerEvent: {
        originatingAction: "Confirmation Page Button",
        type: LoginActionTypes.CONFIRM_USER_ACTION,
        payload: null
    },
    isEnabled: true,
    enabledWhenFormIsValid: true,
};

export const confirmUserSchema = (userEmail: string): FormSchemaItem => {
    return FormSchemaMaker.Group(
        {
            label: "Confirm your Email Address",
            field: "data",
            properties: {
                classes: "m-1",
                hideLabel: false
            },
        },
        [
            FormSchemaMaker.Text({
                name: "username",
                label: "Email Address",
                field: "emailValue",
                properties: { labelAlign: "top" },
                rules: { required: true, email: true },
                value: userEmail,
                placeHolder: "Enter your email address",
                autocomplete: "OFF"
            }),
            FormSchemaMaker.Text({
                label: "Confirmation ID",
                field: "confirmation",
                properties: { labelAlign: "top" },
                rules: { required: true },
                placeHolder: "Enter validation code (sent to your email)",
                value: "",
                autocomplete: "OFF"
            }),
        ]
    )
}

export const confirmUserPage = (options: LoginPageParameterType): void => {
    // TODO
    console.log(options)
    options.currentSchema.value = confirmUserSchema(options.originationEvent.payload);
    options.buttonFooter.value = {
        infoText: "Lost your code?",
        linkText: "Resend Code",
        eventType: {
            type: LoginActionTypes.RESEND_CONFIRMATION_ACTION,
            originatingAction: "Confirmation Page Footer",
            payload: options.originationEvent.payload
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
            originatingAction: "Confirm user page",
            type: LoginActionTypes.EMPTY
        },
    };

    options.buttonFooter2.value = null;
    options.buttonList[0] = confirmUserButton;
    options.enterKeyAction.value.triggerEvent.type = LoginActionTypes.CONFIRM_USER_ACTION;
}

export const confirmUserAction = async (options: LoginPageParameterType): Promise<void> => {
    const { confirmSignUp } = useLogin();
    const { schema_getJSON } = useSchema();

    if (options.originationEvent.payload?.schemaItem) {
        const data = schema_getJSON(options.originationEvent.payload.schemaItem).data;
        const result = await confirmSignUp(
            data.emailValue as string,
            data.confirmation as string
        );

        if (result.hasError()) {
            options.errorMessage.value = result.getFlatErrorMessage();
        } else {
            options.onPress({
                originatingAction: LoginActionTypes.CONFIRM_USER_ACTION,
                type: LoginActionTypes.GOTO_SIGN_IN_PAGE
            });
        }
    }
}

export const resendConfirmationAction = async (options: LoginPageParameterType): Promise<void> => {
    const { resendConfirmation } = useLogin();
    console.debug("Resending confirmtion code.");


    const emailValue = options.originationEvent.payload;

    const result = await resendConfirmation(emailValue as string);

    if (result.hasError()) {
        options.errorMessage.value = result.getFlatErrorMessage();
    } else {
        options.onPress({
            originatingAction: LoginActionTypes.RESEND_CONFIRMATION_ACTION,
            type: LoginActionTypes.CONFIRM_USER_ACTION,
            payload: emailValue
        });
    }

}