import { LookupTableType, mjsUnknown } from '../../mathjs/math-types';
import { MjsMath } from '../../mathjs/math-core';
import { TypeGuard } from '../../mathjs/Type-guards';


export class BaseVariable {
   public name: string;

   constructor(name: string) {
      this.name = name;
   }

   static reduceVariable(variable: mjsUnknown, lookupTable: LookupTableType) {
      return MjsMath.reduceIfPossible(variable, lookupTable);
   }

   static preciseSTR(x: string, nrSigFigs: number): string {
      if (nrSigFigs === -1) { return x; }

      return Number.parseFloat(x).toPrecision(nrSigFigs);
   }

   static precise(x: mjsUnknown, nrSigFigs: number): number {
      if (TypeGuard.isNumber(x)) {
         return (nrSigFigs === -1) ? x : Number.parseFloat(x.toPrecision(nrSigFigs));
      }

      if (TypeGuard.isString(x)) {
         return (nrSigFigs === -1) ? Number.parseFloat(x) : Number.parseFloat(Number.parseFloat(x).toPrecision(nrSigFigs))
      }

      if (TypeGuard.isUnit(x)) {
         return this.precise(MjsMath.getValueFromUnitType(x), nrSigFigs);
      }

      return 0;
   }
}
