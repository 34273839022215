<template>
  <h4>Last revision made on January 10, 2021</h4>
  <h2>User Accounts</h2>
  <p>
    In order to create a user account, you must provide a name, an email address, and the name of your educational instituion.
    You agree that you will never share access or access information for your user account with any third party for any reason.
  </p>
  <h2>Anonymous Registration</h2>
  <p>
    You are free to register for an account using a pseudonym, if you wish to remain anonymous. In order to register anonymously:
    <ul>
      <li>
        Setup an email for your pseudonym (fake name used for registration). Inform your instructor of this email and your selected pseudonym so that they can associate
        your work with your name. Use the email service of your choice. Gmail or Hotmail are two of many free email services available to you.
      </li>
      <li>
        Enter the pseudonym and the newly created email address in place of your true personal information.
      </li>
      <li>
        If you have previously registered for an account but would like to use a pseudonym, register for a new account using the pseudonym.
      </li>
      <li>
        In order to receive credit for completed work, it is important that you notify your instructor of both the email and pseudonym you have selected.
      </li>
    </ul>
  </p>
  <h2>Privacy Policy</h2>
  <p>
    Sigma Learning Systems, LLC directly collects information including IP addresses, the type of browser software and operating system, and the date and time you
    access our site when you:
    <ul>
      <li> sign up for a Vengla user account and create a Vengla user profile; </li>
      <li> register for a module or course</li>
      <li> send an email message to Vengla</li>
      <li> particpate in user surveys </li>
      <li> navigate the Vengla site </li>
    </ul>
  </p>

  <h2>Cookies and Tracking Technologies</h2>

  Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.
  Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
  However, if you do not accept cookies, you may not be able to use some portions of our Site. For example, cookies are used on this site to enable authenticated login.
  They allow us to recognize registered users and protect the site from unauthorized users.

  <br>

  This site uses Google Analytics to analyze web traffic to the site. Google Analytics collects only the IP address assigned to you on the date you visit this site,
  rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics
  with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique
  user the next time you visit this site, the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by
  Google Analytics about your visits to this site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.
  You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.

  <br>

  This site also uses the AdWords and Remarketing Lists features of Google Analytics for Display Advertisers Vengla and Google use first-party cookies (such as the Google Analytics cookie)
  to inform, optimize, and serve ads based on your past visits to our Site. This means that vendors including google will display Vengla promotional material on other sites you
  visit across the internet. Additional third-party widgets such as YouTube may install cookies depending on their configuration.

  <h2>How Your Information is Used</h2>
  <p>
    <ul>
      <li> Communicate with you - Course updates or reminders may be emailed to you. Vengla may also send you updates on new content or features that has been added to the site. </li>
      <li> Support scientific research - This includes collaborating to enable and conduct research about how learners access and master course materials online.</li>
      <li> Enforce and comply with legal rights and oblications - This includes resopnding to subpoenas, court orders, or other legal processes. </li>
    </ul>
  </p>

  <h2>How Your Information is Shared</h2>
  <p>
    Your information may be shared with the course instructors, teaching assistants, and graders. Your information may also be shared with other members of your course during group projects. You may also
    select to share some content with others. For example, you may share your lab notebook with other users or allow the instructor to review and comment on your work.

    If your communication relates to a law enforcement matter, we may disclose the information to law enforcement agences that we deem appropriate.
  </p>

  <h2>How Long We Keep Information</h2>

  You may delete your account at any time by clicking on the Delete button on the Profile page.
  Note that your account, all course registrations, and all course submissions including notebooks, homework, exams, and assignments will be deleted.
  Pressing the delete account button will trigger an immediate account removal, but course data will be permanently removed
  after a 5 day waiting period. Should you accidentally delete your account, reach out the the site administrator immediately
  to see if the delete process can be halted or reversed.

  De-identified usage statistics, data, and analysis may be kept for an unlimited period of time.

  <h2>Security</h2>
  <ul>
    <li>
      <h5>User Data</h5>
      User data, student assignments, and course information are encrypted and stored on a third party web hosting platform.
    </li>

    <li>
      <h5>Purchases</h5>
      Sensitive information such as credit cards are transmitted directly from you to Stripe, Inc for processing.
      Please refer to Strip's Privacy Policy at stripe.com/privacy for more details.
    </li>
  </ul>

  <h2>Privacy Policy Updates</h2>
  <p>
    This policy will be updated periodically. When changes are made, the revision date at the top of this document will be changed.
    By access the Vengla.com site after any changes have been made, you accept the modified Privacy Policy and any changes contained therein.
  </p>
</template>

<script lang="ts">
  export default {
    name: "PrivacyStatement",
    setup() : unknown {
      return {};
    },
  };
</script>
