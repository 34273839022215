import * as BABYLON from 'babylonjs';
import { BaseRenderedObject } from "../primatives/BaseRenderedObject";
import { Fluid, Fluids } from '../properties/Standard_Fluids';
import { VariableTable } from "../values/VariableTable";
import { ExperimentInterface } from "./ExperimentInterface";
import { ClearWaterTank } from '../primatives/LabEquipment/ClearWaterTank';
import { FlowMeterElectronic } from '../instruments/Electronic/FlowMeter';
import { BallValve } from '../primatives/Pipe/Pipe_C_BallValve';
import { PointListGenerator } from '../primatives/PointListGenerator';
import { VisualFlowMeterModel } from '../instruments/Analog/VisualFlowMeter';
import { PipeGeneratorType, PipeGenerator } from '../primatives/Factories/PipeGenerator';
import { FluidJet } from '../primatives/Pipe/FluidJet';
import { LoadCell } from '../instruments/Analog/LoadCell';
import { JetDeflector, JetDeflectorModel } from '../primatives/Pipe/JetDeflector';
import { ForceMeterElectronic } from '../instruments/Electronic/ForceMeter';
import { _u } from '../values/ScalarValue';
import { UBearingFrame } from '../primatives/Structure/UBearingFrame';
import { Vector3 } from 'babylonjs/Maths/math.vector';


interface LinearMomentumStationOptions {
    location?: BABYLON.Vector3,
    rotationY?: number,
    fluid: Fluid
}

interface LinearMomentumResults {
    rpm: number,
    torque: number,
    viscosity: number,
    temperature: number
}

export class LinearMomentumStation extends BaseRenderedObject implements ExperimentInterface {
    private pipeGenerator: PipeGenerator;
    private variableTable: VariableTable;
    private pipeDiameter = 0.0254;
    private nozzleDiameter: number = 0.0254 / 4;
    private tank: ClearWaterTank;

    private options: LinearMomentumStationOptions;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);

        const fluid = new Fluid(Fluids.WATER);

        this.options = {
            fluid: fluid,
            location: new BABYLON.Vector3(0, 0, 0),
            rotationY: 0
        }

        this.variableTable = new VariableTable();

        this.pipeGenerator = new PipeGenerator(scene);

        this.tank = new ClearWaterTank('TANK', scene, this);
        this.addChild(this.tank);
        this.addChild(new FlowMeterElectronic('FLOW1', scene, this));
        this.addChild(new BallValve("V1", this.scene, this));
        this.addChild(new FluidJet("JET1", scene, this));
        this.addChild(new LoadCell("LOAD_CELL", scene, this));
        this.addChild(new JetDeflector("JET_DEFLECTOR", scene, this));
        this.addChild(new ForceMeterElectronic("METER", scene, this));
        this.addChild(new UBearingFrame("FRAME", scene, this));
    }

    public setOptions(options: LinearMomentumStationOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.pipeGenerator.addPreloadAsset(this.scene, assetManager);
        super.addPreloadAssets(assetManager);
    }


    public render() {

        const x0 = -0.5, xD = -0.1, x1 = 0, x2 = 0.6;
        const y1 = 0.8, y2 = 0.7, ylc = 0.61, yfr = 0.57, y3 = 0.5, ynz = 0.45, y4 = 0.4, yM = 0.3, y5 = 0.1, yD = 0.055;
        const z1 = 0.3, zD = 0.1, z2 = 0.0;


        const pointList = PointListGenerator.fromJSON({
            W1: [x2, y1, z1],
            A: [x2, y1, z2],
            B: [x2, y5, z2],
            C: [x1, y5, z2],
            D: [x1, y4, z2],
            E: [x1, ynz, z2],

            DL: [xD, yD, z1],
            DR: [xD, yD, zD],

            VALVE: [x2, y2, z2],
            FLOW_METER: [x2, yM, z2],
            LOAD_CELL: [x1, ylc, z2],
            DEFLECTOR: [x1, y3, z2],

            METER: [x0, y4, z2],
            FRAME: [x1, yfr, z2]
        });

        const pairTable =
            [
                ["W1", "A", this.pipeDiameter],
                ["A", "B", this.pipeDiameter],
                ["B", "C", this.pipeDiameter],
                ["C", "D", this.pipeDiameter],
                ["DL", "DR", this.pipeDiameter]
            ];

        this.myContainerNode = BABYLON.MeshBuilder.CreateSphere('Linear Momentum Origin', {
            diameter: 0.001
        }, this.scene);


        for (let i = 0; i < pairTable.length; i++) {

            this.pipeGenerator.create(PipeGeneratorType.Pipe,
                this.myContainerNode,
                {
                    startPoint: pointList[pairTable[i][0]],
                    endPoint: pointList[pairTable[i][1]],
                    diameter: pairTable[i][2]
                });
        }

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["C"], rotateX: 0, rotateY: 0, rotateZ: 0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["B"], rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["A"], rotateX: Math.PI / 2.0, rotateY: 0, rotateZ: Math.PI / 2.0 });

        this.pipeGenerator.create(PipeGeneratorType.Nozzle,
            this.myContainerNode,
            {
                startPoint: pointList["E"],
                endPoint: pointList["D"],
                baseDiameter: 0.027,
                nozzleDiameter: 0.01
            });

        this.getChildByName("TANK")
            ?.setOptions({
                size: {
                    width: 0.5,
                    height: 0.5,
                    depth: 0.5,
                    wallThickness: 0.01
                },
                hasDrain: true,
                drainDiameter: 0.0254,
                drainHeight: 0.05,
                location: new BABYLON.Vector3(0, 0.0, -0.0),
                fluidHeight: 0.05
            })
            .render();

        // create instruments and meters
        this.getChildByName("FLOW1")
            ?.setOptions({
                location: pointList["FLOW_METER"],
                model: VisualFlowMeterModel.FourToTwentyEightGPM
            })
            .render();

        this.getChildByName("V1")
            ?.setOptions({
                pipeDiameter: this.pipeDiameter,
                location: pointList["VALVE"],
                rotateZ: -Math.PI / 2.0
            })
            .render();

        this.getChildByName("JET1")
            ?.setOptions({
                location: pointList["D"],
                diameter: this.nozzleDiameter,
                maxLength: y3 - y4,
                target: this.getChildByName("JET_DEFLECTOR")
            })
            .render();

        this.getChildByName("LOAD_CELL")
            ?.setOptions({
                location: pointList["LOAD_CELL"]
            })
            .render();

        this.getChildByName("JET_DEFLECTOR")
            ?.setOptions({
                location: pointList["DEFLECTOR"],
                maxLength: 1,
                diameter: 0.1,
                jetDiameter: [0.4, 0.1]
            })
            .render();


        this.getChildByName("METER")
            ?.setOptions({
                location: pointList["METER"]
            })
            .render();


        this.getChildByName("FRAME")
            ?.setOptions({
                location: pointList["FRAME"],
                width: 0.25,
                innerWidth: 0.2,
                depth: 0.1,
                height: 0.08,
                barThickness: 0.04,
                supportThickness: 0.02,
            })
            .render();

        // move container only after all children are added
        this.myContainerNode.addRotation(0, this.options.rotationY ?? 0, 0);
        this.myContainerNode.position = this.options.location as Vector3;

        return super.render();
    }

    public updateTime(time: number, deltaT: number) {
        this.variableTable.set("TIME", time, "ms");
        this.variableTable.set("DELTA_T", deltaT, "ms");
        this.onChange();
    }

    public onChange() {
        this.setTableFromObjectState(this.variableTable);
        this.reComputeSystemState(this.variableTable);
        this.setObjectStateFromTable(this.variableTable);
    }


    private reComputeSystemState(variableTable: VariableTable) {

        const mainValve = this.variableTable.get("V1")?.value ?? 0;
        const Q = 0.001 * mainValve;

        const nD = this.nozzleDiameter;
        const An = Math.PI * nD * nD / 4.0;
        const Vn = Q / An;

        const D1 = this.pipeDiameter;
        const A1 = Math.PI * D1 * D1 / 4.0;
        const V1 = Q / A1; // m^3
        const rho = 1000; // kg/m^3

        variableTable.set("FLOW1", Q, "m3/s");

        this.getChildByName("JET1")?.setProperty("VELOCITY", _u(Vn, "m/s"));

        const deltaT = this.variableTable.get("DELTA_T")?.in('s') ?? 0;
        this.tank.addFluidVolume(Q * deltaT);

        const deflectorType = this.getChildByName("JET_DEFLECTOR")?.getProperty("DEFL_GEOM");

        let factor = 1;
        if (deflectorType === JetDeflectorModel.Hemisphere) { factor = 2; }

        const F = factor * rho * Q * Vn;
        variableTable.set("LOAD_CELL0", F, "N");
    }
}