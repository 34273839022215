
  import { bNavbarDropdownPropTypes, bNavbarDropdownType } from '@/components/bootstrap/b-navbar.interface';
  import { defineComponent, EmitsOptions, ref, SetupContext } from 'vue'
  import BToolbarDropdown from '@/components/bootstrap/b-toolbar-dropdown.vue';
  import BNavbarDropdown from '@/components/bootstrap/b-navbar-dropdown.vue';
  import BToolbarItem from '@/components/bootstrap/b-toolbar-item.vue';

  export default defineComponent({
    components: { BNavbarDropdown, BToolbarDropdown, BToolbarItem },
    props: {
      allowCommit: {
        type: Boolean,
        default: true
      },
      allowDiscard: {
        type: Boolean,
        default: true
      }
    },
    emits: ["activeToolNr", "brushColor", "brushWidth", "commit", "discard"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const colors = ref(['#000000', '#808080', '#d4f713', '#13f7ab', '#13f3f7', '#13c5f7', '#138cf7', '#1353f7', '#2d13f7', '#7513f7', '#a713f7', '#d413f7', '#f713e0', '#f71397', '#f7135b', '#f71313', '#f76213', '#f79413', '#f7e013']);
      const brushSizes = ref([2, 4, 8]);

      const toolItems: bNavbarDropdownPropTypes[] = [
        {
          type: bNavbarDropdownType.ACTION,
          label: "Pencil",
          iconName: "bi-pencil",
          action: () => {
            context.emit("activeToolNr", 0);
          }
        },
        {
          type: bNavbarDropdownType.ACTION,
          label: "Line",
          iconName: "bi-arrow-up-left",
          action: () => {
            context.emit("activeToolNr", 1);
          }
        },
        {
          type: bNavbarDropdownType.ACTION,
          label: "Rectangle",
          iconName: "bi-square",
          action: () => {
            context.emit("activeToolNr", 3);
          }
        },
        {
          type: bNavbarDropdownType.ACTION,
          label: "Circle",
          iconName: "bi-circle",
          action: () => {
            context.emit("activeToolNr", 2);
          }
        },
        {
          type: bNavbarDropdownType.ACTION,
          label: "Pan",
          iconName: "bi-arrows-move",
          action: () => {
            context.emit("activeToolNr", 4);
          }
        }
      ];

      const toolDropdownStyle = {
        label: "Draw",
        iconName: "bi-pencil",
        rightSideDrop: false,
      };

      return {
        toolDropdownStyle,
        toolItems,
        colors,
        brushSizes
      }
    }
  })
