<template>
  <div id="nav">
    <PNavbar />
  </div>
  <div v-if="error">
    {{ error }}
  </div>

  <router-view v-slot="{ Component }">
    <template v-if="Component">
      <suspense>
        <component :is="Component" />
        <template #fallback>
          <b-loading label="Loading Page ... " />
        </template>
      </suspense>
    </template>
  </router-view>
</template>

<script lang="ts">
  import { defineComponent, onErrorCaptured, Ref, ref } from "vue";
  import PNavbar from "./views/components/p-navbar.vue";
  import { signInExistingUser } from "./views/components/login/login-sign-in";
  import BLoading from "./components/bootstrap/b-loading.vue";

  export default defineComponent({
    components: {
      PNavbar,
      BLoading
    },
    setup() {
      const error: Ref<null | unknown> = ref(null);
      signInExistingUser();
      onErrorCaptured((e) => {
        error.value = e;
        return true;
      });

      return {
        error,
      };
    },
  });
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #nav {
    padding: 0px;
    margin: 0px;

    a {
      &.router-link-exact-active {
        color: #e6f2fd;
        background-color: #2c3e50;
      }
    }
  }

  // modify any default color variables
  $info: #7ac1ff;

  @import "~bootstrap/scss/bootstrap";
  @import "~bootstrap-icons/font/bootstrap-icons.css";
  @import "../node_modules/katex/dist/katex.min.css";

  // add any custom colors by creating your own map and merging with the (now-defined) $theme-colors map
  // Create your own map
  $custom-colors: (
    "custom-color": #900,
  );
  // Merge the maps
  $theme-colors: map-merge($theme-colors, $custom-colors);

  @media (max-width: 500px) {
    body {
      font-size: 0.8rem; /* 1.125rem is small enough, so RFS won't rescale this */
    }
  }
</style>
