import * as BABYLON from 'babylonjs';
import { BaseLaboratory } from './BaseLaboratory';
import { RenderedObject } from '../primatives/BaseRenderedObject';
import { WindTunnelstation } from '../experiments/WindTunnelStation';
import { Nullable } from 'babylonjs/types';

export class WindTunnelLaboratory extends BaseLaboratory {
	constructor(name: string, scene: BABYLON.Scene, parent: Nullable<RenderedObject>) {
		super(name, scene, parent);

		// create experiments
		this.experiments.push(new WindTunnelstation("Wind Tunnel Station", this.scene, this));

		this.experiments.forEach((v) => {
			this.addChild(v);
		});
	}

	public render() {
		const floorWidth = this.options.floorWidth ?? 0;
		this.setCamera(1, floorWidth / 2.0 - 5, 0.5, 1.0, floorWidth / 2.0 - 2);

		// set options on components and experiments here
		this.experiments[0].setOptions({
			location: new BABYLON.Vector3(0, 0.0, floorWidth / 2.0 - 2),
			rotationY: Math.PI
		});

		return super.render();
	}
}
