<template>
  <li class="nav-item">
    <a class="nav-link"
       href="#"
       role="button"
       @click="$emit('click')">
      {{ label }}
    </a>
  </li>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'


  export default defineComponent({
    props: {
      label: {
        type: String,
        default: ""
      },
      ulClass: {
        type: String,
        default: ""
      }
    },
    emits: ["click"],
    setup(props) {
      return {}
    }
  })
</script>

<style scoped>
</style>