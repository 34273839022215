<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input id="assetsFieldHandle"
           ref="file"
           type="file"
           multiple
           name="fields"
           class="w-px h-px opacity-0 overflow-hidden absolute"
           accept=".pdf,.jpg,.jpeg,.png"
           @change="onChange">
    <ul v-if="filelist.length"
        v-cloak
        class="mt-4">
      <li v-for="(file, index) in filelist"
          :key="index"
          class="text-sm p-1">
        {{ file.name }}
        <button type="button"
                title="Remove file"
                @click="remove(filelist.indexOf(file))">
          x
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, Ref, ref } from "vue";

  export default defineComponent({
    setup() {
      const file = ref(null) as any;
      const filelist = ref([]) as Ref<string[]>;
      const onChange = () => {
        if (file.value && file.value?.files) {
          filelist.value = [...file.value?.files];
        }
        console.log("test");
      };

      const remove = (i: number) => {
        filelist.value.splice(i, 1);
      };

      const drop = (event: DragEvent) => {
        event.preventDefault();
        file.value.files = event.dataTransfer?.files;
        onChange();
      };

      const dragover = () => {
        //event.preventDefault();
      };

      return {
        file,
        filelist,
        onChange,
        remove,
        drop,
        dragover,
      };
    },
  });
</script>

<style scoped>
</style>
