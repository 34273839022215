<template>
  <div class="dropdown">
    <button v-if="show"
            class="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="$emit('click')">
      <span>{{ label }}</span>
      <i v-if="icon"
         class=" bi"
         :class="icon" />
    </button>
    <ul class="dropdown-menu">
      <li v-for="(option) in options"
          :key="option"
          @click="onClick(option.value)">
        <a class="dropdown-item">
          <i v-if="option.icon"
             class="bi"
             :class="option.icon" />
          {{ option.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";

  export interface DropOption {
    label: string,
    value: number,
    icon: string
  }

  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: undefined,
      },
      label: {
        type: String,
        default: undefined,
      },
      show: {
        type: Boolean,
        default: true,
      },
      options: {
        type: Array as PropType<DropOption[]>,
        default: () => []
      },
      onClickCallback: {
        type: Function,
        requited: true,
        default: () => {
          return;
        },
      },
    },
    emits: ["click"],
    setup(props, context) {
      const onClick = (index: number) => {
        context.emit("click", index);
      }

      return { onClick };
    },
  });
</script>

<style scoped>
</style>