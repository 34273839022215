import { Nullable } from 'babylonjs/types';
import * as paper from 'paper';
import { Ref, ref } from 'vue';
import { TypeGuard } from '../mathjs/Type-guards';

interface StrokeOptions {
   lineColor: paper.Color;
   strokeWidth: number;
}

export default function usePaperJS(originalPageSize: paper.Size) {
   let scope: Nullable<paper.PaperScope> = null;
   const tools: paper.Tool[] = [];

   const history: paper.Path[] = [];
   const uncommittedLayer: Ref<Nullable<paper.Layer>> = ref(null);

   const currentPath: Ref<paper.Path | null> = ref(null);
   const mostRecentStroke: Ref<paper.Path | null> = ref(null);

   let strokeOptions: Nullable<StrokeOptions> = null;

   const setScope = (inputScope: paper.PaperScope) => {
      scope = inputScope;
      uncommittedLayer.value = new paper.Layer();
      strokeOptions = {
         lineColor: new paper.Color(0, 0, 0),
         strokeWidth: 2
      }
   }

   const setBrushSize = (strokeWidth: number) => {
      if (strokeOptions)
         strokeOptions.strokeWidth = strokeWidth;
   }

   const setBrushColor = (color: paper.Color) => {
      if (strokeOptions)
         strokeOptions.lineColor = color;
   }


   const scaleEventCoordinates = (point: paper.Point) => {
      return point;
      // const tmpPnt = new paper.Point(point.x * canvasSize.width / originalPageSize.width,
      //    point.y * originalPageSize.height / canvasSize.height);
      // console.log({ point, tmpPnt, canvasSize, originalPageSize })
      // return tmpPnt;
   }

   const discardChanges = () => {
      //uncommittedLayer.clear();
      history.splice(0, history.length);
      (uncommittedLayer.value as any).clear();
   }

   const commitChanges = () => {
      uncommittedLayer.value = new paper.Layer();
      scope?.project.addLayer(uncommittedLayer.value);
   }


   const setStroke = (path: paper.Path, strokeOptions: StrokeOptions) => {
      path.strokeColor = strokeOptions.lineColor;
      path.strokeWidth = strokeOptions.strokeWidth;
      path.strokeCap = 'round';
      path.strokeJoin = 'round';
   }

   const createTools = () => {
      function commitPath() {
         history.push(currentPath.value!);
         mostRecentStroke.value = currentPath.value;
         currentPath.value = null;
      }

      const drawTool = new paper.Tool();
      drawTool.onMouseDown = (event: paper.MouseEvent) => {
         currentPath.value = new paper.Path();
         currentPath.value.addTo(uncommittedLayer.value!);
         setStroke(currentPath.value, strokeOptions!)
         currentPath.value.add(scaleEventCoordinates(event.point));
      };

      drawTool.onMouseDrag = (event: paper.MouseEvent) => {
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         currentPath.value.add(scaleEventCoordinates(event.point));
      }

      drawTool.onMouseUp = (event: paper.MouseEvent) => {
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         const segmentCount = currentPath.value.segments.length;

         // When the mouse is released, simplify it:
         const errTol = 3;
         currentPath.value.simplify(errTol);
         //currentPath.value.flatten(5);

         const newSegmentCount = currentPath.value.segments.length;
         const difference = segmentCount - newSegmentCount;
         const percentage = 100 - Math.round(newSegmentCount / segmentCount * 100);
         console.log('simplify set to ', errTol);
         console.log('final Path', currentPath.value);
         console.log(difference + ' of the ' + segmentCount + ' segments were removed. Saving ' + percentage + '%');
         commitPath();
      }


      const lineTool = new paper.Tool();

      lineTool.onMouseDown = (event: paper.MouseEvent) => {
         // The mouse was clicked, so let's put a newly created Path into
         // myPath, give it the color black and add the location as the
         // path's first segment.
         currentPath.value = new paper.Path();
         currentPath.value.addTo(uncommittedLayer.value!);
         setStroke(currentPath.value, strokeOptions!);
         currentPath.value.add(scaleEventCoordinates(event.point));
      }

      lineTool.onMouseDrag = (event: paper.MouseEvent) => {
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         currentPath.value.removeSegment(1);
         currentPath.value.add(scaleEventCoordinates(event.point));
      }

      lineTool.onMouseUp = (event: paper.MouseEvent) => {
         // The mouse was released, so we add the new location as the end
         // segment of the line.
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         commitPath();
      }


      const circleTool = new paper.Tool();

      circleTool.onMouseDrag = (event: paper.ToolEvent) => {
         if (currentPath.value) {
            currentPath.value.remove();
         }

         const dist = scaleEventCoordinates(event.downPoint).subtract(scaleEventCoordinates(event.point)).length;

         currentPath.value = new paper.Path.Circle({
            center: scaleEventCoordinates(event.downPoint),
            radius: dist
         });

         setStroke(currentPath.value, strokeOptions!);
      }

      circleTool.onMouseUp = (event: paper.ToolEvent) => {
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         // The mouse was released, so we add the new location as the end
         // segment of the line.
         currentPath.value.addTo(uncommittedLayer.value!);
         commitPath();
      }


      const rectangleTool = new paper.Tool();

      rectangleTool.onMouseDrag = (event: paper.ToolEvent) => {
         if (currentPath.value) {
            currentPath.value.remove();
         }

         currentPath.value = new paper.Path.Rectangle({
            from: scaleEventCoordinates(event.downPoint),
            to: scaleEventCoordinates(event.point)
         });

         setStroke(currentPath.value, strokeOptions!);
      }

      rectangleTool.onMouseUp = (event: paper.MouseEvent) => {
         if (TypeGuard.isNullOrUndefined(currentPath.value))
            return;

         // The mouse was released, so we add the new location as the end
         // segment of the line.
         currentPath.value.addTo(uncommittedLayer.value!);
         commitPath();
      }

      // Create a Tool so we can listen for events
      const toolPan = new paper.Tool()
      toolPan.activate()

      // On drag, scroll the View by the difference between mousedown 
      // and mouseup
      toolPan.onMouseDrag = function (event: paper.ToolEvent) {
         const delta: any = event.downPoint.subtract(event.point);
         (scope?.view as any).scrollBy(delta);
      }

      tools.push(
         drawTool,
         lineTool,
         circleTool,
         rectangleTool,
         toolPan,
      )
      return tools;

   }

   const activateTool = (activeToolNr: number) => {
      console.log("Change tool")
      tools[activeToolNr].activate();
   }

   const captureOnlyPencil = (e: any) => {
      if (e.touches && e.touches[0] && typeof e.touches[0]["force"] !== "undefined") {
         if (e.touches[0]["force"] > 0) {
            const pressure = e.touches[0]["force"];
            console.log("Detected Pencil with Pressure =", pressure);
            return;
         }
      }

      console.log('Not pencil?')
      // e.stopPropagation();
      // e.stopImmediatePropagation();
      // e.preventDefault();
   }



   return {
      setScope,
      setBrushColor,
      setBrushSize,
      createTools,
      activateTool,
      captureOnlyPencil,
      discardChanges,
      commitChanges,
      mostRecentStroke,
      uncommittedLayer
   }
}
