
  import { defineComponent, onMounted, Ref, ref, watch } from 'vue'
  import * as paper from 'paper';
  import usePaperJS from '../usePaperJS';
  import NotebookToolbar from './NotebookToolbar.vue';

  export default defineComponent({
    components: { NotebookToolbar },
    props: {
      allowCommit: {
        type: Boolean,
        default: true
      },
      allowDiscard: {
        type: Boolean,
        default: true
      }
    },
    emits: ["update:uncommittedLayer"],
    setup(props, { emit }) {
      console.log("Setup Notebook");

      const canvasRef: Ref<HTMLCanvasElement | null> = ref(null);
      const scope: Ref<paper.PaperScope | null> = ref(null);

      const {
        createTools,
        captureOnlyPencil,
        activateTool,
        setBrushColor,
        setBrushSize,
        setScope,
        discardChanges,
        commitChanges,
        uncommittedLayer,
        mostRecentStroke
      } = usePaperJS(new paper.Size({ width: 1000, height: 800 }));

      watch(mostRecentStroke, () => {
        emit("update:uncommittedLayer", uncommittedLayer);
      });

      onMounted(() => {
        console.log("Mount Notebook.")
        scope.value = new paper.PaperScope();
        scope.value?.setup("myCanvas");
        setScope(scope.value);
        createTools();
        activateTool(0);
      });

      return {
        scope,
        discardChanges,
        commitChanges,
        setBrushColor,
        setBrushSize,
        activateTool,
        canvasRef,
        captureOnlyPencil
      }
    }
  })
