import { equal } from "mathjs";
import { convertEqnCharToKatex, flattenEqnCharTree } from "./textUtilities";
import { EqnCharacter, EqnCharacterType } from "./useEqnCharacterAnalysis";


export interface HandwritingTrainer {
   id: string,
   flatArray: EqnCharacter[],
   rootEquation: EqnCharacter,
   equation: (index: number) => string,
   char: (index: number) => string,
   displayChar: (index: number) => string,
   index: number,
   maxNrItems: number,
   trainingLabels: []
}

export function useTextTrainer() {
   let id = 0;

   function getNextID() {
      id++;
      return `e-${id}`;
   }

   function rndV(min: number, max: number) {
      return Math.floor((max - min + 1) * Math.random() + min);
   }


   function getDigit(options?: { min?: number, max?: number }): EqnCharacter {
      const defaultVals = { min: 0, max: 9 };
      const { min, max } = { ...defaultVals, ...options };

      const digit = rndV(min, max);
      const charCode = `${digit}`;

      return {
         id: getNextID(),
         type: EqnCharacterType.character,
         kcc: charCode,
         dc: charCode,
         children: []
      }
   }


   function pickFrom(arr: string[]) {
      const item = arr[Math.floor(rndV(0, arr.length - 1))];
      return getRawChar(item);
   }


   const operators = ["-", "+", "\\cdot", "\\times", "/", "*", "\\div"];
   function getOperator(): EqnCharacter {
      return pickFrom(operators);
   }

   const equality = ["=", "\\ne", "\\leq", "\\geq", "<", ">", "\\sim", "\\simeq", "\\approx", "\\propto"];
   function getOperatorWEqual(): EqnCharacter {
      return pickFrom(operators.concat(equality));
   }


   const alphaChars = ["A", "B", "C", "D", "E", "F", "G", "H", "K", "L",
      "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
      "a", "b", "c", "d", "e", "f", "g", "h", "k", "m",
      "n", "p", "q", "r", "s", "t", "u", "v",
      "w", "x", "y", "z"];
   function getLetter(): EqnCharacter {
      return pickFrom(alphaChars);
   }

   function getXYZ(): EqnCharacter {
      return pickFrom(["x", "y", "x"]);
   }

   const greekChars = ["\\alpha", "\\beta", "\\gamma", "\\delta", "\\epsilon", "\\theta", "\\lambda", "\\mu", "\\nu", "\\pi", "\\sigma", "\\tau", "\\phi", "\\psi", "\\omega", "\\Delta", "\\Sigma",]
   function getGreek(): EqnCharacter {
      return pickFrom(greekChars);
   }

   function getMultiDigit(nrDigits: number): EqnCharacter[] {
      const eqnComponents: EqnCharacter[] = [];

      [...Array(nrDigits)].forEach((v, index) => {
         eqnComponents.push(getDigit({ min: (index === 0) ? 1 : 0 }));
      });

      return eqnComponents;
   }

   function getRawChar(char: string): EqnCharacter {
      return {
         id: getNextID(),
         type: EqnCharacterType.character,
         dc: `${char}`,
         kcc: `${char}`,
         children: []
      }
   }

   function getDecimalDigit(nrDigitsBefore: number, nrDigitsAfter: number): EqnCharacter[] {
      let eqnComponents: EqnCharacter[] = [];
      if (nrDigitsBefore === 0) {
         eqnComponents.push(getRawChar('0'))
      } else
         eqnComponents = eqnComponents.concat(getMultiDigit(nrDigitsBefore));

      if (nrDigitsAfter > 0) {
         eqnComponents.push(getRawChar('.'))
         eqnComponents = eqnComponents.concat(getMultiDigit(nrDigitsAfter));
      }

      return eqnComponents;
   }

   function getNumber(nrDigits: number): EqnCharacter[] {
      const type = rndV(1, 2);
      switch (type) {
         case 1:
            return getMultiDigit(nrDigits);
         default: {
            const nrBefore = rndV(0, nrDigits);
            const nrAfter = nrDigits - nrBefore;
            return getDecimalDigit(nrBefore, nrAfter);
         }
      }
   }

   function getParen(eqnComponents: EqnCharacter[]): EqnCharacter[] {
      const type = rndV(1, 2);
      switch (type) {
         case 1:
            return [
               getRawChar('('),
               ...eqnComponents,
               getRawChar(')')
            ];
         default: {
            return [
               getRawChar('['),
               ...eqnComponents,
               getRawChar(']')
            ];
         }
      }
   }

   function getGroup(eqnComponents: EqnCharacter[]): EqnCharacter {
      return {
         id: getNextID(),
         type: EqnCharacterType.group,
         dc: ``,
         kcc: ``,
         children: eqnComponents
      }
   }

   function makeSuperScript(eqnComponents: EqnCharacter[]): EqnCharacter {
      return {
         id: getNextID(),
         type: EqnCharacterType.superscript,
         kcc: '^',
         dc: '',
         children: eqnComponents
      }
   }


   function makeSubScript(eqnComponents: EqnCharacter[]): EqnCharacter {
      return {
         id: getNextID(),
         type: EqnCharacterType.subscript,
         kcc: '_',
         dc: '',
         children: eqnComponents
      }
   }

   function getOperatedNum(): EqnCharacter[] {
      return [
         ...getNumber(rndV(1, 3)),
         getOperator(),
         ...getNumber(rndV(1, 3))
      ];
   }


   function getFraction(num: EqnCharacter[], den: EqnCharacter[]): EqnCharacter {
      return {
         id: getNextID(),
         type: EqnCharacterType.fraction,
         kcc: '\frac',
         dc: '---',
         children: [getGroup(num), getGroup(den)]
      }
   }

   function getAlphaGreekWSubOrSuper(minNrEl: number, maxNrEl: number): EqnCharacter[] {

      const eqnArr: EqnCharacter[] = [];
      const number = rndV(minNrEl, maxNrEl);
      const useSubOrSuper = rndV(0, 3);
      const subOrSuperPos = rndV(1, number);

      for (let i = 0; i <= number; i++) {
         const char = pickFrom(alphaChars.concat(greekChars))
         if (useSubOrSuper === 0 || subOrSuperPos !== i)
            eqnArr.push(char)
         else
            if (useSubOrSuper === 1)
               eqnArr.push(makeSuperScript([char]))
            else
               eqnArr.push(makeSubScript([char]))
      }

      return eqnArr;
   }

   function getAlphaGreek(minNrEl: number, maxNrEl: number): EqnCharacter[] {

      const eqnArr: EqnCharacter[] = [];
      const number = rndV(minNrEl, maxNrEl);

      for (let i = 0; i <= number; i++) {
         eqnArr.push(pickFrom(alphaChars.concat(greekChars)))
      }

      return eqnArr;
   }


   function getGroupTerm(allowFrac = true): EqnCharacter[] {
      let eqnArr: EqnCharacter[] = [];
      let type = rndV(0, 12);
      if (!allowFrac)
         type = rndV(2, 12);

      switch (type) {
         case 0:
            eqnArr.push(getFraction(getGroupTerm(false), getGroupTerm(false)));
            break;
         case 1:
            eqnArr.push(getFraction(getAlphaGreek(1, 3), getAlphaGreek(1, 3)));
            break;
         case 2:
         case 3:
         case 4:
         case 5:
         case 6:
         case 7:
         case 8:
            eqnArr.push(getAlphaGreek(1, 1)[0]);
            break;
         case 9:
            eqnArr.push(getAlphaGreek(1, 1)[0]);
            eqnArr.push(makeSubScript(getAlphaGreek(1, 2)));
            break;
         case 10:
            eqnArr.push(getAlphaGreek(1, 1)[0]);
            eqnArr.push(makeSubScript([getXYZ()]));
            break;
         case 11:
            eqnArr = getNumber(rndV(1, 3));
            eqnArr.push(makeSuperScript(getNumber(rndV(1, 1))));
            break;
         case 12:
            eqnArr = getParen(getOperatedNum())
            break;
         default:
            eqnArr = getNumber(rndV(1, 3));
            break;
      }

      return eqnArr;
   }


   const getBasicEquation = (): EqnCharacter => {
      const equation: EqnCharacter = {
         kcc: "",
         dc: "",
         children: [],
         id: getNextID(),
         type: EqnCharacterType.equation
      };

      equation.children = equation.children.concat(getGroupTerm());
      equation.children.push(getOperator());
      equation.children = equation.children.concat(getGroupTerm());
      equation.children.push(getOperatorWEqual());
      equation.children = equation.children.concat(getGroupTerm());
      equation.children.push(getOperator());
      equation.children = equation.children.concat(getGroupTerm());

      return equation;
   }

   const getAlphaHeavyEquation = (): EqnCharacter => {
      const equation: EqnCharacter = {
         kcc: "",
         dc: "",
         children: [],
         id: getNextID(),
         type: EqnCharacterType.equation
      };

      equation.children = equation.children.concat(getAlphaGreekWSubOrSuper(1, 4));
      equation.children.push(getOperatorWEqual());
      equation.children = equation.children.concat(getAlphaGreek(1, 4));

      return equation;
   }

   const getRandomEqnTypes = () => {
      const type = rndV(0, 1);
      switch (type) {
         case 0:
            return getBasicEquation();
         case 1:
         default:
            return getAlphaHeavyEquation();
      }
   }

   const generateTrainer = (): HandwritingTrainer => {
      const equation = getRandomEqnTypes();

      const flatArray = flattenEqnCharTree(equation);

      const getEquation = (index: number) => {
         flatArray.forEach((v) => { delete v.highlight; })

         if (index > 0 && index < flatArray.length)
            flatArray[index].highlight = true;
         return convertEqnCharToKatex(equation) ?? "";
      }

      const getKatexCharacter = (index: number) => {
         return flatArray[index].kcc;
      }

      const displayChar = (index: number) => {
         return flatArray[index].dc;
      }

      return {
         id: getNextID(),
         rootEquation: equation,
         flatArray,
         equation: getEquation,
         char: getKatexCharacter,
         displayChar,
         index: 0,
         maxNrItems: flatArray.length,
         trainingLabels: []
      }
   }

   return {
      generateTrainer
   }
}