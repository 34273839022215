import { v4 as uuidv4 } from 'uuid';
import { PageComponentTypes, PageLaboratoryTypes, Page_BabylonComponent, Page_MarkdownComponent, Page_QuizComponent } from '../pages/pages.interface';

export function getEmptyQuiz(): Page_QuizComponent {
   return {
      name: "Quiz",
      id: uuidv4(),
      type: PageComponentTypes.QUESTION_BLOCK,
      data: {
         displayType: "QUIZ",
         setSeed: false,
         seedValue: Math.random(),
         randomizeOrder: false,
         showScoreBreakdown: true,
         showSolutions: true,
         array: []
      }
   }
}

export function getEmptyMarkdown(): Page_MarkdownComponent {
   return {
      name: "Text",
      id: uuidv4(),
      isActive: true,
      type: PageComponentTypes.MARKDOWN,
      data: {
         content: ""
      },
   }
}

export function getEmptyBabylon(): Page_BabylonComponent {
   return {
      name: "Lab",
      id: uuidv4(),
      isActive: true,
      type: PageComponentTypes.BABYLON,
      data: {
         seedValue: Math.random(),
         selectedLab: PageLaboratoryTypes.WIND_TUNNEL,
         content: ""
      },
   }
}

export function getEmptyQuestion() {
   return {
      showSolution: false,
      seed: "",
      name: "QComponent",
      isInitialized: false,
      id: uuidv4(),
      isActive: true,
      type: 7,
      data: {
         container: {
            tabTitle: "Container",
            meta: {
               style: "",
               classes: ""
            },
         },
         model: {
            tabTitle: "Model",
            meta: {
               formulas: [],
               parameters: [],
               changeOrder: false,
               changeValue: false,
               changeInteger: false,
               changeSign: false
            }
         },
         view: {
            tabTitle: "View",
            meta: {
               problemStatement: "",
               nrChoices: 4,
               nrSigFigs: 3,
               correctAnswer: "",
               correctUnit: "",
               correctPMper: 1,
               partialPMper: 5,
               incorrectAnswers: [],
               requestedResponses: []
            }
         },
         figure: {
            tabTitle: "Figure", meta: {
               include: false,
               caption: "",
               figureObjects: [],
               type: "URL",
               url: ""
            }
         },
         solution: {
            tabTitle: "Solution", meta: {
               solutionStatement: ""
            }
         }
      }
   }
}

