import { RepackageErrorResponse, RequestResponse, RequestResponseErrorTypes } from "@/components/global/response.interface";
import { MjsMath } from "./math-core";
import { TypeGuard } from "./Type-guards";
import { MathJSTree } from "./math-node-tree";
import { ModelVariable } from "../questions/model/model-variable";
import { EquationStack, processEquationsInStack } from "../questions/equations/equation-template-helpers";
import { VariableTable } from "./mathjs-variable-table";
import { LookupTableType, mjsUnknown, MjsNode, ParsedEquationType } from "./math-types";
import { invertMJSNodes } from "./math-invert";
import { ModelHealthReport } from "../questions/model/model-health-report";
import { mjsParser } from "./extension/parse";
import { mjsSimplify } from "./extension/simplify";
import { extendedMathJSFunctions } from "./extension/math-util-functions";

function _getUnknownRemainingVarsInList(variables: LookupTableType, knownVariableTable: LookupTableType) {
   const unknownVariables: Record<string, boolean> = {};
   Object.keys(variables).forEach((varName) => {
      if (!TypeGuard.hasProp(knownVariableTable, varName)) {
         unknownVariables[varName] = true;
      }
   });

   return Object.keys(unknownVariables);
}

function _getVariablesInEquation(nodes: MjsNode[]) {
   const variableList: Record<string, number> = {};
   const mathJS = MjsMath.getMathJS();
   nodes.forEach((eqnNode) => {
      const filtered = eqnNode.filter(function (node) {
         return node.isSymbolNode;
      });

      filtered.forEach((symNode) => {
         const varName = symNode.name as string;

         if (//!MjsMath.isValuelessUnit(varName) &&
            !(varName in mathJS) &&
            !(varName in extendedMathJSFunctions) &&
            varName !== "pi" &&
            varName !== "PI") {
            if (!TypeGuard.hasProp(variableList, varName)) {
               variableList[varName] = 1;
            } else {
               variableList[varName]++;
            }
         }
      })
   });

   return variableList;
}


function _invertEquation(lhs: MjsNode, rhs: MjsNode, solveForVariable: string) {
   const varInLHS = MathJSTree.findVariable(lhs, solveForVariable);
   const varInRHS = MathJSTree.findVariable(rhs, solveForVariable);

   let inv: MjsNode | undefined;
   if (varInLHS.length === 1 && varInRHS.length === 0) {
      inv = invertMJSNodes(varInLHS[0], rhs);
   } else if (varInRHS.length === 1 && varInLHS.length === 0) {
      inv = invertMJSNodes(varInRHS[0], lhs);
   }
   return inv;
}


function _internalSolve(lhs: MjsNode, rhs: MjsNode, solveForVariable: string, variableTable: LookupTableType) {
   const inv = _invertEquation(lhs, rhs, solveForVariable);

   if (inv) {
      const reduced = MjsMath.evalNodes(inv, variableTable);
      return new RequestResponse(reduced, null);
   } else {
      return new RequestResponse<number>(null, {
         type: RequestResponseErrorTypes.CATCH,
         message: `Unable to invert equation: ${lhs.toString()}=${rhs.toString()} for variable: ${solveForVariable}`
      });
   }
}

function _parseEquality(eqn: string, scope: Record<string, mjsUnknown>) {
   const sides = eqn.split("=");
   if (sides.length !== 2) {
      return new RequestResponse<[MjsNode, MjsNode]>(null, {
         type: RequestResponseErrorTypes.CATCH,
         message: "Unable to find an equal sign in this equation"
      });
   }

   const lhs = mjsParser.parseEnforceBracketedUnits(sides[0], scope);
   const rhs = mjsParser.parseEnforceBracketedUnits(sides[1], scope);

   return new RequestResponse<[MjsNode, MjsNode]>([lhs, rhs], null);
}

function _parseEquations(equations: string[], modelHealthReport: ModelHealthReport, scope: Record<string, mjsUnknown>) {
   const parsedEquations: ParsedEquationType = [];

   equations.forEach((equation: string, index) => {
      const result = _parseEquality(equation, scope);
      if (result.hasError()) {
         modelHealthReport.addEquationError(equation, index, `Cannot parse equation.`)
      } else {
         const varInEquation = _getVariablesInEquation(result.getData());
         parsedEquations.push([...result.getData(), varInEquation, equation]);
      }
   });

   return parsedEquations;
}



function _solveSimultaneousEquations(unsolvedEquations: ParsedEquationType,
   variableTable: Record<string, mjsUnknown>,
   modelHealthReport: ModelHealthReport) {
   const mjs = MjsMath.getMathJS() as any;
   const substitutions = [];

   // now solve simultaneous equations
   let listOfAllMissingVariables: string[] = [];
   const indexedEqnMissingVariables: string[][] = [];
   let flag = true;
   while (flag) {

      unsolvedEquations.forEach((eqn, index: number) => {
         const missingVariables = _getUnknownRemainingVarsInList(eqn[2], variableTable);
         indexedEqnMissingVariables[index] = missingVariables;
         listOfAllMissingVariables = listOfAllMissingVariables.concat(missingVariables.filter((item) => listOfAllMissingVariables.indexOf(item) < 0));
      });


      // check that the number of equations equals the number of unknowns
      if (listOfAllMissingVariables.length !== unsolvedEquations.length) {
         modelHealthReport.addGeneralError(`The number of unknown variables: ${listOfAllMissingVariables.length} does not equal the number of unsolved equations ${unsolvedEquations.length}`);
         modelHealthReport.addGeneralError(`The remaining variables are: ${JSON.stringify(listOfAllMissingVariables)}`);
      }

      // solve the set of equations
      console.debug("List of Missing Variables", listOfAllMissingVariables);
      console.debug(indexedEqnMissingVariables);

      const indexOfMinVarEqn = findIndexOfMinNrVariables(indexedEqnMissingVariables);

      const curEquation = unsolvedEquations[indexOfMinVarEqn];
      const curVariableToEliminate = indexedEqnMissingVariables[indexOfMinVarEqn][0];

      const subNode = _invertEquation(curEquation[0], curEquation[1], curVariableToEliminate);
      substitutions.push({ varName: curVariableToEliminate, subNode });


      if (!subNode) {
         modelHealthReport.addGeneralError(`Unable to invert equation: ${curEquation[3]}`);
         return;
      } else {
         // sub into all of the other equations
         unsolvedEquations.forEach((eqn, eqnIndex) => {
            if (eqnIndex === indexOfMinVarEqn)
               return;

            const subLeftSide = MathJSTree.substituteNode(eqn[0], subNode, curVariableToEliminate);
            // ensure units have non-null value
            const subLeftSideWithUnitFix = mjsSimplify.ensureUnitHasNumericalValue(subLeftSide);
            eqn[0] = mjsSimplify.simplifyTerms(mjs.simplify(subLeftSideWithUnitFix, variableTable), variableTable, true);

            const subRightSide = MathJSTree.substituteNode(eqn[1], subNode, curVariableToEliminate);
            const subRightSideWithUnitFix = mjsSimplify.ensureUnitHasNumericalValue(subRightSide);

            eqn[1] = mjsSimplify.simplifyTerms(mjs.simplify(subRightSideWithUnitFix, variableTable), variableTable, true);

            // decorate
            MathJSTree.addParentInfoToNodeTree(eqn[0], 0, null);
            MathJSTree.addParentInfoToNodeTree(eqn[1], 0, null);

            delete eqn[2][curVariableToEliminate];
         });

         unsolvedEquations.splice(indexOfMinVarEqn, 1);
         listOfAllMissingVariables = listOfAllMissingVariables.filter((item) => item !== curVariableToEliminate);

         unsolvedEquations = _solveSingleVariableEquations(unsolvedEquations, variableTable, modelHealthReport);
      }

      if (unsolvedEquations.length === 0) {
         console.debug("Done solving system.");
         // backsubstitutions
         console.debug(substitutions);
         substitutions.reverse().forEach((item) => {
            if (item.subNode) {
               const reduced = MjsMath.evalNodes(item.subNode, variableTable);
               variableTable[item.varName] = reduced;
            }
         });


         flag = false;
      }
   }
   function findIndexOfMinNrVariables(listOfIndexedEqnVar: string[][]) {
      let index = 0;
      let minNrVar = 0;
      listOfIndexedEqnVar.forEach((listOfVars, listIndex: number) => {
         if (listOfVars.length > minNrVar) {
            minNrVar = listOfVars.length;
            index = listIndex;
         }
      })
      return index;
   }

}


function _solveSingleVariableEquations(parsedEquations: ParsedEquationType,
   variableTable: LookupTableType,
   modelHealthReport: ModelHealthReport) {

   let unsolvedEquations = parsedEquations.map((eqn, index) => { return { eqn, index } });
   let internalParsedEquations;
   let changed = true;

   while (changed) {
      changed = false;
      internalParsedEquations = unsolvedEquations;
      unsolvedEquations = [];

      internalParsedEquations.forEach((parsedEquation) => {
         try {
            const variables = _getUnknownRemainingVarsInList(parsedEquation.eqn[2], variableTable);
            if (variables.length === 1 && parsedEquation.eqn[2][variables[0]] === 1) {
               const result = _internalSolve(parsedEquation.eqn[0], parsedEquation.eqn[1], variables[0], variableTable);

               if (result.hasError()) {
                  modelHealthReport.addEquationError(parsedEquation.eqn[3].toString(),
                     parsedEquation.index,
                     `Cannot solve: ${parsedEquation.eqn[3]} for ${variables[0]}`
                  );
               } else {
                  const data = result.getData();
                  variableTable[variables[0]] = data;
               }

               changed = true;
            } else {
               unsolvedEquations.push(parsedEquation);
            }
         } catch (err) {
            throw new Error("Err solving: " + parsedEquation.eqn[3] + ". " + err)
         }
      });
   }
   return unsolvedEquations.map((v) => v.eqn);
}

export class MathJSSolve {

   static solveFor(eqn: string, variable: string, variableTable: LookupTableType): RequestResponse<mjsUnknown> {
      const result = _parseEquality(eqn, variableTable);
      if (result.hasError())
         return RepackageErrorResponse<mjsUnknown, [MjsNode, MjsNode]>(result);

      const [lhs, rhs] = result.getData();
      return _internalSolve(lhs, rhs, variable, variableTable);
   }


   static solveStack(knownValues: LookupTableType,
      equationStack: EquationStack,
      modelVariableTable: ModelVariable[],
      modelHealthReport: ModelHealthReport,
      testOptions: boolean[] | null = null) {

      const outputVariableTable: VariableTable = new VariableTable();

      Object.keys(knownValues).forEach((key) => {
         outputVariableTable.setValue(key, knownValues[key]);
      });

      // prepare random variables. Note that each random variable may depend on all 
      // prevoiusly defined random variables.
      modelVariableTable.forEach((v, index) => {
         let newValue;
         if (!TypeGuard.isNullOrUndefined(testOptions) && testOptions.length > index)
            newValue = v.getTestValue(outputVariableTable.values, testOptions[index]);
         else {
            newValue = v.getValue(outputVariableTable.values);
         }

         if (TypeGuard.isString(newValue)) {
            modelHealthReport.addVariableError(v.name, index, newValue);
         } else {
            outputVariableTable.setUnitValue(v.name, newValue, v.options.unit);
         }
      });

      console.debug("Initial Variable Table", outputVariableTable);

      // prepare string formulas by making variable substituions and storing units 
      const formulas = processEquationsInStack(equationStack,
         outputVariableTable,
         modelHealthReport);

      this.solveSet(formulas, outputVariableTable.values, modelHealthReport);

      return outputVariableTable;
   }

   static solveSet(equations: string[],
      variableTable: Record<string, mjsUnknown>,
      modelHealthReport: ModelHealthReport): LookupTableType {

      // parse all equations
      const parsedEquations: ParsedEquationType = _parseEquations(equations, modelHealthReport, variableTable);

      // solve all single variable equations first
      let unsolvedEquations;
      try {
         unsolvedEquations =
            _solveSingleVariableEquations(parsedEquations, variableTable, modelHealthReport);
      } catch (err) { throw new Error("solving single variable equations." + err) }

      if (unsolvedEquations.length > 0) {
         // solve simultenous equations
         _solveSimultaneousEquations(unsolvedEquations, variableTable, modelHealthReport);
      }

      return variableTable;
   }

}