import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import { ConnectableObjectInterface } from "../instruments/Electronic/CordConnector";
import { ControlResponseRequested, Control_Base, DynamicGui, ControlTypes, ControlPanelModes, ControlPayloadData } from "./Control_Base";
import { Control_Slider } from "./Control_Slider";
import { HitSequence } from "./HitSequence";
import { BaseRenderedObject } from '../primatives/BaseRenderedObject';
import { Control_Select } from './Control_Select';
import { Fluid } from '../properties/Standard_Fluids';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
import { _TypeStore } from 'babylonjs/Misc/typeStore';
import { Scalar } from 'babylonjs/Maths/math.scalar';
import { ScalarValue } from '../values/ScalarValue';

export interface HitParameters {
    controlParams: Record<string, unknown>;
}

export class Control_Panel {
    public currentMode: ControlPanelModes = ControlPanelModes.WAITING;
    public controls: Map<ControlTypes, Control_Base> = new Map();
    public hitSequence: HitSequence = new HitSequence();
    public scene: BABYLON.Scene;

    private gui: DynamicGui = { manager: null, title: null };

    constructor(scene: BABYLON.Scene) {
        this.scene = scene;

        // add controls
        this.controls.set(ControlTypes.VALVE_SLIDER,
            new Control_Slider("Valve", this.scene, {
                sliderOptions: {},
                onChange: (value) => { return }
            }));

        // select fluid
        this.controls.set(ControlTypes.SELECT_FLUID,
            new Control_Select("Fluid", this.scene, {
                selectOptions: {
                    title: "Select Fluid"
                },
                value: {
                    list: Fluid.getListOfFluids(),
                    initialValue: "Air"
                },
                onChange: (value) => { return }
            }));


        // select fluid
        this.controls.set(ControlTypes.SELECT_JET_DEFLECTOR_GEOM,
            new Control_Select("Target Geometry", this.scene, {
                selectOptions: {
                    title: "Target"
                },
                value: {
                    list: ["Flat", "Hemisphere", "EMPTY"],
                    initialValue: "Flat"
                },
                onChange: (value) => { return }
            }));
    }


    public renderHelpWindow() {
        this.gui.manager = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

        const panel = new GUI.Rectangle();
        panel.width = "32%";
        panel.heightInPixels = 100;
        panel.top = "5px";
        panel.left = "5px";
        panel.fontSizeInPixels = 20;
        panel.cornerRadius = 4;
        panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        panel.background = "White";

        this.gui.title = new GUI.TextBlock();
        this.gui.title.width = '100%';
        this.gui.title.height = '100%';
        this.gui.title.text = "Use arrow keys to move and the mouse to interact with the scene.";
        this.gui.title.textWrapping = true;
        this.gui.title.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        this.gui.title.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        panel.addControl(this.gui.title);

        this.gui.manager.addControl(panel);
    }

    private setGuiTitleText(text: string) {
        if (!TypeGuard.isNullOrUndefined(this.gui.title)) {
            this.gui.title.text = text;
        }
    }

    public closeAllGUIs() {
        this.setGuiTitleText("");
        this.controls.forEach((v) => {
            v.closeGUI();
        });
    }

    public processHit(requestResponseType: ControlResponseRequested,
        params: HitParameters,
        instrument: BaseRenderedObject,
        inputData: Partial<ControlPayloadData>) {

        const defaultData: ControlPayloadData = { port: { name: "" }, properties: [] };
        const data = { ...defaultData, ...inputData };

        this.closeAllGUIs();

        switch (requestResponseType) {
            case ControlResponseRequested.CANCEL:
                this.hitSequence.clear();
                this.setGuiTitleText("");
                break;

            case ControlResponseRequested.OPEN_VALVE_SLIDER:
                this.controls
                    .get(ControlTypes.VALVE_SLIDER)
                    ?.respond(this.hitSequence, this.gui, instrument, params.controlParams, data);
                this.hitSequence.clear();
                break;

            case ControlResponseRequested.PRIMARY_PRESSURE_CONNECTION:
                this.setGuiTitleText("Select a Quick Connect Valve on the Pipe Rack to Complete the Connection");
                this.hitSequence.clear();
                this.hitSequence.add({
                    primaryRenderedObject: instrument,
                    port: data.port.name,
                    type: ControlResponseRequested.PRIMARY_PRESSURE_CONNECTION
                });
                break;

            case ControlResponseRequested.FORCE_CONNNECTION:
                this.setGuiTitleText("Select a Load Cell to Complete the Connection");
                this.hitSequence.clear();
                this.hitSequence.add({
                    primaryRenderedObject: instrument,
                    port: data.port.name,
                    type: ControlResponseRequested.FORCE_CONNNECTION
                });
                break;


            case ControlResponseRequested.SECONDARY_FORCE_CONNECTION:
                if (this.hitSequence.has(1) &&
                    this.hitSequence.connectionIsType(0, ControlResponseRequested.FORCE_CONNNECTION)) {
                    this.setGuiTitleText("");

                    const PRO: ConnectableObjectInterface = (this.hitSequence.getObjectFrom(0) as unknown as ConnectableObjectInterface);
                    const INS: ConnectableObjectInterface = (instrument as unknown as ConnectableObjectInterface);

                    const conPort = this.hitSequence.getPortFrom(0);
                    if (!TypeGuard.isNullOrUndefined(conPort) && data.port.name) {
                        PRO.registerConnection(conPort, data.port.name, INS);
                        (instrument as unknown as ConnectableObjectInterface)
                            .registerConnection(data.port.name, conPort, PRO);
                    }
                    this.hitSequence.clear();
                } else {
                    this.setGuiTitleText("Connect a meter to this load cell by clicking on the force meter's port first.");
                }
                break;


            case ControlResponseRequested.SECONDARY_PRESSURE_CONNECTION:
                if (this.hitSequence.has(1) &&
                    this.hitSequence.connectionIsType(0, ControlResponseRequested.PRIMARY_PRESSURE_CONNECTION)) {
                    this.setGuiTitleText("");

                    const PRO: ConnectableObjectInterface = (this.hitSequence.getObjectFrom(0) as unknown as ConnectableObjectInterface);
                    const INS: ConnectableObjectInterface = (instrument as unknown as ConnectableObjectInterface);

                    const conPort = this.hitSequence.getPortFrom(0);
                    if (!TypeGuard.isNullOrUndefined(conPort) && data.port.name) {
                        PRO.registerConnection(conPort, data.port.name, INS);
                        (instrument as unknown as ConnectableObjectInterface)
                            .registerConnection(data.port.name, conPort, PRO);
                    }
                    this.hitSequence.clear();

                } else {
                    this.setGuiTitleText("Connect a meter to this quick connection port by clicking on the meter's port first.");
                }
                break;

            case ControlResponseRequested.REMOVE_CONNECTION: {
                const tmpPort = data.port;
                if (tmpPort.connection?.isConnected) {
                    tmpPort.connection.connectedObject?.removeConnection(tmpPort.connection.connectedPort);
                    tmpPort.connection.isConnected = false;
                }
                break;
            }
            case ControlResponseRequested.SELECT_FLUID:
                this.setGuiTitleText("Select the type of fluid from the list.");
                this.controls
                    .get(ControlTypes.SELECT_FLUID)
                    ?.respond(this.hitSequence, this.gui, instrument, {}, data);
                this.hitSequence.clear();
                break;

            case ControlResponseRequested.SELECT_JET_DEFLECTOR_GEOM:
                this.setGuiTitleText("Select the target plate geometry.");
                this.controls
                    .get(ControlTypes.SELECT_JET_DEFLECTOR_GEOM)
                    ?.respond(this.hitSequence, this.gui, instrument, {}, data);
                this.hitSequence.clear();
                break;



            default:
                this.setGuiTitleText("");
                break;
        }
    }
}
