
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "BToolbarDropdown",
    props: {
      label: {
        type: String,
        default: ""
      },
      ulClass: {
        type: String,
        default: ""
      }
    },
    setup(props) {
      return {}
    }
  })
