/**
 * The expression parser of math.js has support for letting functions
 * parse and evaluate arguments themselves, instead of calling them with
 * evaluated arguments.
 *
 * By adding a property `raw` with value true to a function, the function
 * will be invoked with unevaluated arguments, allowing the function
 * to process the arguments in a customized way.
 */

import { MathNode } from "mathjs"

/**
  * Calculate the numeric integration of a function
  * @param {Function} f
  * @param {number} start
  * @param {number} end
  * @param {number} [step=0.01]
  */
export function intWorker(f: (x: number) => number, start: number, end: number, step: number) {
   let total = 0
   step = step || 0.01
   for (let x = start; x < end; x += step) {
      total += f(x + step / 2) * step
   }
   return total
}

/**
 * A transformation for the integrate function. This transformation will be
 * invoked when the function is used via the expression parser of math.js.
 *
 * Syntax:
 *
 *     integrate(integrand, variable, start, end)
 *     integrate(integrand, variable, start, end, step)
 *
 * Usage:
 *
 *     math.evaluate('integrate(2*x, x, 0, 2)')
 *     math.evaluate('integrate(2*x, x, 0, 2, 0.01)')
 *
 * @param {Array.<math.Node>} args
 *            Expects the following arguments: [f, x, start, end, step]
 * @param {Object} math
 * @param {Object} [scope]
 */
export function integrate(args: MathNode[], math: unknown, scope: Record<string, unknown> | null) {
   // determine the variable name
   if (!args[1].isSymbolNode) {
      throw new Error('Second argument must be a symbol')
   }
   const variable: string = args[1].name!;

   // evaluate start, end, and step 
   const start = args[2].compile().evaluate(scope)
   const end = args[3].compile().evaluate(scope)
   const step = args[4] && args[4].compile().evaluate(scope) // step is optional

   // create a new scope, linked to the provided scope. We use this new scope
   // to apply the variable.
   const fnScope = Object.create(scope === null ? {} : scope);

   // construct a function which evaluates the first parameter f after applying
   // a value for parameter x.
   const fnCode = args[0].compile();
   const f = function (x: number) {
      const fnScope: Record<string, any> = {};
      fnScope[variable] = x;

      return fnCode.evaluate(fnScope)
   }

   // execute the integration
   return intWorker(f, start, end, step)
}


// mark the transform function with a "rawArgs" property, so it will be called
// with uncompiled, unevaluated arguments.
integrate.rawArgs = true;
//integrate.transform.rawArgs = true

