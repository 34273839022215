
  import FormInputGroup from "./form-input-layout.vue";
  import BConfirmDialog from "../../bootstrap/b-confirm-dialog.vue";

  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps, formItemMixinInterface } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, reactive, ref, SetupContext, watch } from "vue";
  import { ConfirmDialogOptions } from "@/components/bootstrap/b-confirm-dialog.interface";
  import { FormSchemaItem } from "../form-schema.interface";
  import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
  import InternalArrayElement from "./internal-array-element.vue";
  import draggable from "vuedraggable";

  export default defineComponent({
    name: "ArrayOfObjects",
    components: {
      FormInputGroup,
      BConfirmDialog,
      InternalArrayElement,
      draggable
    },
    props: {
      ...formItemMixinProps,
      addButtonLabel: {
        type: String,
        default: "Add Row"
      },
      addFunction: {
        type: Function,
        default: null
      }
    },
    emits: ["update:modelValue", "change"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const confirmDialog = ref<ConfirmDialogOptions | null>(null);
      //const arrayValue: Ref<Record<string, unknown>[]> = ref(props.modelValue as []);
      const arrayValue = reactive(props.modelValue as Record<string, unknown>[]);

      const { isSchemaValid } = useValidation(props as unknown as formItemMixinInterface, context);

      const addArrayRow = () => {
        var newRow: Record<string, unknown> = {};
        if (!props.schemaitem) return;

        if (props.addFunction) {
          props.addFunction(arrayValue);
        } else {
          (props.schemaitem as FormSchemaItem)
            ?.children?.forEach((v: FormSchemaItem) => {
              if (Array.isArray(v.value)) {
                newRow[v.field] = v.value.slice();
              } else {
                if (TypeGuard.isFunction(v.value)) {
                  newRow[v.field] = v.value();
                } else {
                  newRow[v.field] = v.value;
                }
              }
            });

          arrayValue.push(newRow);

          isSchemaValid(props.schemaitem);
        }
      };

      const deleteRow = (index: number) => {
        confirmDialog.value
          ?.open(
            "Confirm Delete",
            "Area you sure you want to delete this item?",
            {
              cancel_class: "btn btn-secondary",
              ok_class: "btn btn-danger deleteArrayRowButton",
              ok_text: "Delete",
            }
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              arrayValue.splice(index, 1);
            }
          });
      };

      const getChildren = (item: FormSchemaItem) => {
        return item.children ? item.children : [];
      }

      const getNrChildren = (item: FormSchemaItem) => {
        return item.children ? item.children.length : 0;
      }

      const onChange = () => {
        context.emit("change");
      }

      watch(arrayValue, () => {
        context.emit("update:modelValue", arrayValue);
      });


      return {
        onChange,
        getNrChildren,
        getChildren,
        deleteRow,
        addArrayRow: addArrayRow,
        arrayValue,
        confirmDialog,
        ...useValidation(props, context)
      };
    },
    methods: {

    },
  });
