
import { listTextRecognitions, textRecognitionByDate } from '@/graphql/queries';
import { sort } from 'fast-sort';
import { DynamoSearchKernal, DynamoSearchResults } from '../dynamoSearchResult';
import { CharRecord } from './record-char';
import { TextRecognitionBaseTableType, TextRecognitionSearchTypes } from './table-textrecognition.interface';

const charSearchKernal = () => {
   return {
      distributeDBItems: function (fetchedData: any, type: TextRecognitionSearchTypes, obj: TextRecognitionSearchResults) {
         let items: TextRecognitionBaseTableType[];

         switch (type) {
            case TextRecognitionSearchTypes.BY_CHAR:
            case TextRecognitionSearchTypes.BY_EQN:
               items = fetchedData.data.listTextRecognitions.items;
               break;
            case TextRecognitionSearchTypes.BY_DATE:
            default:
               items = fetchedData.data.textRecognitionByDate.items;
               break;
         }

         items.forEach((v) => {
            switch (v.fieldType) {
               case "CHAR":
                  obj.chars.push(new CharRecord().wrapDBRecord(v));
                  break;
               case "EQN":
                  obj.eqns.push(new CharRecord().wrapDBRecord(v));
                  break;
               case "EQN_2":
                  obj.eqns_2.push(new CharRecord().wrapDBRecord(v));
                  break;
            }
         });

         // sort
         obj.chars = sort(obj.chars).desc(u => u.data().createdAt);

      },
      extraQueryData: {
      },
      getQueryData: function (type: TextRecognitionSearchTypes, extraQueryData: any) {
         switch (type) {
            case TextRecognitionSearchTypes.BY_CHAR:
            case TextRecognitionSearchTypes.BY_EQN:
               return {
                  query: listTextRecognitions,
                  queryData: {}
               };
            // case TextRecognitionSearchTypes.BY_DATE:
            // return {
            //    query:textRecognitionByDate,
            //    queryData: { fieldType: "CHAR" }
            // };
         }
      }
   }
}


export class TextRecognitionSearchResults extends DynamoSearchResults<TextRecognitionSearchTypes>{
   public queryType: TextRecognitionSearchTypes = TextRecognitionSearchTypes.BY_CHAR;
   public chars: CharRecord[] = [];
   public eqns: CharRecord[] = [];
   public eqns_2: CharRecord[] = [];

   public clear() {
      this.chars = [];
      this.eqns = [];
      this.eqns_2 = [];
      return true;
   }

   constructor() {
      super(charSearchKernal() as unknown as DynamoSearchKernal<TextRecognitionSearchTypes>);
   }

   getCharWithID(id: string) {
      return this.chars.find((v) => v.data().id === id)
   }

   getAllChars() {
      return this.chars;
   }

   getAllEqns() {
      return this.eqns;
   }

   getAllEqns_2() {
      return this.eqns_2;
   }
}

