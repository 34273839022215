<template>
  <img crossorigin="anaonymous"
       :src="imageURL">
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import { S3Manager } from "@/store/S3/S3Manager";

  interface ImageOptions {
    class?: unknown;
    width?: unknown;
    alt?: unknown;
    style?: unknown;
  }

  export default defineComponent({
    props: {
      src: {
        type: String,
        required: true,
      },
      level: {
        type: String as PropType<"public" | "private" | "protected">,
        default: "public",
      },
      imageOptions: {
        type: Object as PropType<ImageOptions>,
        default: () => {
          return;
        },
      },
    },
    async setup(props) {
      const imageURL = await S3Manager.getFile(props.src, props.level);
      return { imageURL };
    },
  });
</script>

<style scoped>
</style>