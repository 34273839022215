import { Ref, ref, shallowReadonly } from "@vue/reactivity";

export default function usePagination(startPageNr: number, maxNrPages: Ref<number | (() => number)>, emitter: () => void) {
   const _activePageNr = ref(startPageNr);

   const getMaxNrPages = (): number => {
      if (typeof maxNrPages.value === "number") {
         return maxNrPages.value;
      } else {
         return maxNrPages.value();
      }
   }

   const setPageNr = (newPageNr: number) => {
      if (newPageNr < startPageNr) newPageNr = startPageNr;
      if (newPageNr >= getMaxNrPages()) newPageNr = getMaxNrPages() - 1;
      _activePageNr.value = newPageNr;
      emitter();
   }

   const incrementPageNr = () => {
      setPageNr(_activePageNr.value += 1);
   }

   const decrementPageNr = () => {
      setPageNr(_activePageNr.value -= 1);
   }

   return {
      activePageNr: shallowReadonly(_activePageNr),
      setPageNr,
      getMaxNrPages,
      incrementPageNr,
      decrementPageNr
   }
}