import { RequestResponse, RequestResponseErrorTypes } from "@/components/global/response.interface";
import { ModelVariable } from "../../questions/model/model-variable";
import { MjsMath } from "../math-core";
import { LookupTableType, mjsDenseMatrix, MjsNode, mjsUnit, mjsUnknown } from "../math-types";
import { TypeGuard } from "../Type-guards";

const unitColor = "green";
interface InternalUnit {
   power: number,
   prefix: { name: string },
   unit: { name: string }
}

export class mjsFormatting {
   public static _isExactMatche(v1: InternalUnit, v2: InternalUnit) {
      return v1.unit.name == v2.unit.name && v1.prefix.name === v2.prefix.name;
   }

   public static _isBaseMatch(v1: InternalUnit, v2: InternalUnit) {
      return v1.unit.name == v2.unit.name;
   }

   /**
    * Get a string representation of the units of this Unit, without the value. The unit list is formatted as-is without first being simplified.
    * @memberof Unit
    * @return {string}
    */
   public static _unitToString(value: mjsUnit) {
      const initialUnits = [...value.units];

      let strNum = ''
      let strDen = ''
      let nNum = 0
      let nDen = 0

      // handle exact matches first
      const units: InternalUnit[] = [];

      const processMatches = (element: InternalUnit) => {
         for (let index = 0; index < initialUnits.length; index++) {
            const el2 = initialUnits[index];
            if (this._isExactMatche(element, el2)) {
               element.power += el2.power;
               initialUnits.splice(index, 1);
            }
         }
      }

      while (initialUnits.length > 0) {
         const element = initialUnits[0];
         units.push(element);
         initialUnits.splice(0, 1);
         processMatches(element);
      }

      for (let i = 0; i < units.length; i++) {
         if (units[i].power > 0) {
            if (nNum > 0)
               strNum += ' \\cdot '

            nNum++
            strNum += ' ' + units[i].prefix.name + units[i].unit.name
            if (Math.abs(units[i].power - 1.0) > 1e-15) {
               strNum += '^{' + getPower(units[i].power) + '}'
            }
         } else if (units[i].power < 0) {
            nDen++
         }
      }

      let nDenProcessed = 0;
      if (nDen > 0) {
         for (let i = 0; i < units.length; i++) {
            if (units[i].power < 0) {
               if (nDenProcessed > 0)
                  strDen += ' \\cdot '

               nDenProcessed++;
               if (nNum > 0) {
                  strDen += ' ' + units[i].prefix.name + units[i].unit.name
                  if (Math.abs(units[i].power + 1.0) > 1e-15) {
                     strDen += ('^{' + getPower(-units[i].power) + '}')
                  }
               } else {
                  strDen += ' ' + units[i].prefix.name + units[i].unit.name
                  strDen += ('^{' + getPower(units[i].power) + '}')
               }
            }
         }
      }
      // Remove leading " "
      strNum = strNum.substr(1)
      strDen = strDen.substr(1)

      // Add parans for better copy/paste back into evaluate, for example, or for better pretty print formatting
      if (nNum > 1 && nDen > 0) {
         strNum = '(' + strNum + ')'
      }
      if (nDen > 1 && nNum > 0) {
         strDen = '(' + strDen + ')'
      }

      let str = strNum
      if (nNum > 0 && nDen > 0) {
         str += ' / '
      }
      str += strDen
      console.log(str)
      return str

      function getPower(power: number) {
         if (Number.isInteger(power)) {
            return power;
         }

         const tmpStr = power.toString();

         if (tmpStr.length > 3)
            return power.toPrecision(3);
         else
            return tmpStr;
      }
   }


   private static _formatKatexUnit(val: mjsUnit,
      nrSigFigs: number,
      stripUnit = false,
      simplifyUnit = false,
      katexEncase = false) {

      const normVal = simplifyUnit ? val.clone().toSI() : val;
      const unitStr = normVal.formatUnits();

      if (stripUnit) {
         const numerVal = ModelVariable.precise(normVal.toNumber(unitStr), nrSigFigs).toString();
         return `${numerVal}`;
      } else {
         const displayUnitStr = mjsFormatting._unitToString(normVal as mjsUnit);

         const numerVal = ModelVariable.precise(normVal.toNumber(unitStr), nrSigFigs).toString();
         const unitKatex = displayUnitStr.trim().length > 0 ? ` \\medspace \\textcolor{${unitColor}}{\\mathrm{[${displayUnitStr}]}}` : "";
         const internalUnitStr = `${numerVal}${unitKatex}`;
         return katexEncase ?
            `$ ${internalUnitStr} $` :
            internalUnitStr;
      }

   }

   private static _formatKatexMatrix(val: mjsDenseMatrix,
      nrSigFigs: number,
      stripUnit = false,
      simplifyUnit = false,
      katexEncase = false) {

      const tmpMatrix = val;
      const i = this.toText(tmpMatrix._data[0], nrSigFigs, true, simplifyUnit);
      const j = this.toText(tmpMatrix._data[1], nrSigFigs, true, simplifyUnit);
      const k = this.toText(tmpMatrix._data[2], nrSigFigs, true, simplifyUnit);

      let unitStr = "";
      console.log(val)
      if (!stripUnit && TypeGuard.isUnit(tmpMatrix._data[0])) {
         const normVal = simplifyUnit ? tmpMatrix._data[0].clone().toSI() : tmpMatrix._data[0];
         const internalUnitStr = mjsFormatting._unitToString(normVal as mjsUnit);
         unitStr = `[${internalUnitStr}]`;
      }
      const internalUnitStr = `${i} \\hat{i} + ${j} \\hat{j} + ${k} \\hat{k} \\medspace \\textcolor{${unitColor}}{\\mathrm{${unitStr}}}`;

      return katexEncase ?
         `$ ${internalUnitStr} $` :
         internalUnitStr;
   }

   static evalToText(term: mjsUnknown, nrSigFigs: number, simplifyUnit = false, katexEncase = false): string {
      if (TypeGuard.isNumber(term)) {
         return ModelVariable.precise(term, nrSigFigs).toString();
      }

      if (TypeGuard.isUnit(term)) {
         return this._formatKatexUnit(term, nrSigFigs, false, simplifyUnit, katexEncase);
      }

      if (TypeGuard.isMatrix(term)) {
         return this._formatKatexMatrix(term, nrSigFigs, false, simplifyUnit, katexEncase);
      }

      if (TypeGuard.isMathJSNode(term)) {
         return MjsMath.evalNodes(term as MjsNode, {}).toString();
      }

      return "EvalToText - Error evaluating term: " + term;
   }

   static toText(val: unknown,
      nrSigFigs: number,
      stripUnit = false,
      simplifyUnit = false,
      katexEncase = false): string {
      if (TypeGuard.isNumber(val)) {
         return ModelVariable.precise(val, nrSigFigs).toString();
      }

      if (TypeGuard.isUnit(val)) {
         return this._formatKatexUnit(val, nrSigFigs, stripUnit, simplifyUnit, katexEncase);
      }

      if (TypeGuard.isMatrix(val)) {
         return this._formatKatexMatrix(val, nrSigFigs, stripUnit, simplifyUnit, katexEncase);
      }

      return `Unable to resolve value: ${val}`;
   }

   static evalStatement(statement: string,
      variableTable: LookupTableType,
      nrSigFigs = 3): RequestResponse<string> {

      const errors: string[] = [];

      // ${x}  translates to x = 3 [m] 
      const replacer2 = (match: string, internal: string): string => {
         const key = internal.trim();

         if (!TypeGuard.hasProp(variableTable, key)) {
            errors.push(`The variable "${key}" is not defined`);
            return `["${key}" is not defined]`;
         }

         const val = variableTable[key];
         if (TypeGuard.isNumber(val) ||
            TypeGuard.isUnit(val) ||
            TypeGuard.isMatrix(val)) {
            return `$ ${key} = ${this.toText(val, nrSigFigs, false, false, false)} $`;
         }

         return `Unknown Type`;
      }

      // used to evaluate mathematical expressions
      // {{ x }} translates to 3
      const replacer = (match: string, internal: string): string => {
         try {
            const val = MjsMath.toNumeric(internal, variableTable);
            return this.toText(val, nrSigFigs, false, false, false);
         } catch (err) {
            const errText = `Cannot evaluate ${match} `;
            errors.push(errText);
            return errText;
         }
      }

      let newstr = ""
      try {
         newstr = statement
            .replace(/\{\{(.+?)\}\}/g, replacer)
            .replace(/\$\{(.+?)\}/g, replacer2);
      } catch (err) {
         errors.push(err as string);
      }

      const errorObj = errors.length === 0 ? null : { type: RequestResponseErrorTypes.CATCH, message: errors };

      return new RequestResponse(newstr, errorObj);
   }
}