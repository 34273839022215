import { BaseErrorI } from "./error.interface";

export enum ResponseStatus {
    DATA = "data",
    ERROR = "error",
    EMPTY = "empty",
    BOTH = "both"
}

export enum RequestResponseErrorTypes {
    CATCH
}

type ResponseError = BaseErrorI<RequestResponseErrorTypes, unknown>;

export function RepackageErrorResponse<T, S>(origResponse: RequestResponse<S>) {
    if (origResponse.hasError()) {
        return new RequestResponse<T>(null, {
            type: origResponse.getErrorType(),
            message: origResponse.getErrorMessage()
        });
    }

    return new RequestResponse<T>(null, {
        type: RequestResponseErrorTypes.CATCH,
        message: "Unknown."
    });
}
export class RequestResponse<T> {
    private static check<T>(value: T | null): value is T {
        return value !== null;
    }

    private type: ResponseStatus;
    private data: T | null;
    private error: ResponseError | null;

    constructor(data: T | null, error: ResponseError | null) {
        this.data = data;
        this.error = error;

        if (data === null) {
            if (error != null) {
                this.type = ResponseStatus.ERROR;
            } else {
                this.type = ResponseStatus.EMPTY;
                this.error = { type: RequestResponseErrorTypes.CATCH, message: "Empty response." }
            }
        } else {
            if (error === null) {
                this.type = ResponseStatus.DATA;
            } else {
                this.type = ResponseStatus.BOTH;
            }
        }
    }
    getErrorType() { return this.error?.type; }

    hasData(): this is ResponseStatus | { data: T, error: ResponseError | null } { return this.type === ResponseStatus.DATA || this.type === ResponseStatus.BOTH }
    hasError(): this is ResponseStatus | { data: T | null, error: ResponseError } { return this.type === ResponseStatus.ERROR || this.type === ResponseStatus.BOTH || this.type === ResponseStatus.EMPTY }
    isEmpty(): this is ResponseStatus | { data: null, error: null } { return this.type === ResponseStatus.EMPTY }

    getData(): T { return this.data as T; }
    getErrorMessage(): string | string[] {
        if (this.type === ResponseStatus.ERROR || this.type === ResponseStatus.BOTH) {
            return (this.error as ResponseError).message;
        }

        return "Response does not contain an error:" + this.type;
    }

    getFlatErrorMessage(): string {
        return JSON.stringify(this.getErrorMessage());
    }


    // getError() {
    //     if (this.type !== ResponseStatus.ERROR)
    //         return "No Error Exists";

    //     return this.data;
    // }
}

export type VResponse = Promise<RequestResponse<unknown>>;

export const createDataResponse = <T>(data: T): RequestResponse<T> => {
    return new RequestResponse<T>(data, null);
}

export const createErrorResponse = (error: string | ResponseError | Error): RequestResponse<null> => {
    if (typeof error === "string") {
        return new RequestResponse<null>(null, { type: RequestResponseErrorTypes.CATCH, message: error });
    } else {
        return new RequestResponse<null>(null, { type: RequestResponseErrorTypes.CATCH, message: (error as Error).message });
    }
}

export const failable = async <T>(callback: () => T, customError?: () => string | ResponseError) => {
    try {
        const data = await callback();
        return createDataResponse<T>(data);
    } catch (err) {
        const error: any = err;
        console.debug("Error caught in failable call", error);
        if (customError) {
            return createErrorResponse(customError());
        }

        if (error.errors) {
            return createErrorResponse((error.errors as []).map((v: any) => v.message).join('\n '));
        }

        return createErrorResponse(error as ResponseError);
    }
}

