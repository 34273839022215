
  import FormInputGroup from "./form-input-layout.vue";
  import BConfirmDialog from "../../bootstrap/b-confirm-dialog.vue";

  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, ref, SetupContext, watch } from "vue";
  import { FormSchemaItem, FormTypes } from "../form-schema.interface";
  import { ConfirmDialogOptions } from "@/components/bootstrap/b-confirm-dialog.interface";
  import draggable from "vuedraggable";

  export default defineComponent({
    name: "FormInputArray",
    components: {
      FormInputGroup,
      BConfirmDialog,
      draggable
    },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const confirmDialog = ref<ConfirmDialogOptions | null>(null);
      const arrayValue = (ref(props.modelValue as unknown as unknown[]));

      const deleteRow = (index: number) => {
        confirmDialog.value
          ?.open(
            "Confirm Delete",
            "Area you sure you want to delete this item?",
            {
              cancel_class: "btn btn-secondary",
              ok_class: "btn btn-danger deleteArrayRowButton",
              ok_text: "Delete",
            }
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              arrayValue.value.splice(index, 1);
            }
          });
      };

      const { isSchemaValid } = useValidation(props, context);

      const addArrayRow = () => {
        let newVal: string | number = "";
        switch ((props.schemaitem as FormSchemaItem).fieldType) {
          case FormTypes.EMAIL:
          case FormTypes.TEXT:
            newVal = "";
            break;
          case FormTypes.NUMBER:
          case FormTypes.INTEGER:
            newVal = 0;
            break;
        }

        arrayValue.value.push(newVal);
        isSchemaValid(props.schemaitem);
      }

      watch(arrayValue, () => {
        console.log("Change array", arrayValue)
        context.emit("update:modelValue", arrayValue);
      });

      return {
        deleteRow,
        addArrayRow,
        arrayValue,
        confirmDialog,
        ...useValidation(props, context)
      };
    },
  });
