<template>
  <div class="row align-items-stretch border-top pt-2">
    <div class="col">
      {{ partLabel(partNr) + evaluateStatement(questionPartData.description) }}
    </div>
    <div class="col text-end">
      <SubmissionButtonGroup :loss-per-guess="questionPartData.partialPMper"
                             :is-finished="isFinished"
                             :score="submissionRecord.scoreInPercent"
                             :max-possible-score="100"
                             :max-nr-attempts="Math.floor(100.0/questionPartData.partialPMper)"
                             :nr-attempts-made="submissionRecord.nrAttempts"
                             @click="onSubmit" />
    </div>
  </div>
  <div :key="reRender"
       class="row pt-2">
    <div v-for="(option, index) in options"
         :key="option">
      <div v-if="!isFinished || (index === correctIndex)"
           class="row border m-1 p-2"
           :class="(index === choice)?selectedColor : ''"
           @click="select(index)">
        <div class="col-2">
          {{ String.fromCharCode(65 + index) + ') ' }}
        </div>
        <div class="col">
          {{ option }}
        </div>
        <div class="col-1">
          <i v-if="isFinished && index == correctIndex"
             class="bi bi-check" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import { generateFixedMultipleChoice } from "./user-response-fixed-selection";
  import { generateNumericalMultipleChoice } from "./user-response-multiple-choice";
  import SubmissionButtonGroup from "./SubmissionButtonGroup.vue";
  import {
    UserResponseSetupData,
    MultipleChoiceType,
    AnswerState,
  } from "./user-response.interface";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import useEvents from "@/components/use/useEvents";

  export default defineComponent({
    components: { SubmissionButtonGroup },
    props: {
      partNr: {
        type: Number,
        required: true,
      },
      questionPartData: {
        type: Object as PropType<UserResponseSetupData>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecord: {
        type: Object as PropType<SumissionQuestionPart>,
        required: true,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const mct = ref(props.questionPartData.multipleChoiceType);
      const choice = ref(-1);
      const ansState = ref(AnswerState.NOT_CHECKED);

      const evaluateStatement = (statement: string) => {
        return props.questionModel.evaluateStatement(statement).getData();
      };

      const partLabel = (index: number) => {
        return index !== -1 ? "Part " + (index + 1) + ". " : "";
      };

      const { array, index } =
        mct.value === MultipleChoiceType.SPECIFY_ANSWERS
          ? generateFixedMultipleChoice(
            props.questionPartData,
            props.questionModel
          )
          : generateNumericalMultipleChoice(
            props.questionPartData,
            props.questionModel
          );

      const correctIndex = ref(index);
      const options = ref(array);

      const { emitEvent } = useEvents(context);

      const reRender = ref(false);
      const selectedColor = ref("bg-primary");

      const select = (index: number) => {
        selectedColor.value = "bg-primary";
        choice.value = index;
      };

      const evalulateCurrentState = () => {
        reRender.value = !reRender.value;
        const isCorrect = choice.value === correctIndex.value;
        if (isCorrect) {
          selectedColor.value = "bg-success";
          ansState.value = AnswerState.CORRECT;
        } else {
          selectedColor.value = "bg-danger";
          ansState.value = AnswerState.WRONG;
        }
      };

      const onSubmit = (): void => {
        evalulateCurrentState();
        emitEvent("submit", {
          type: "submit",
          payload: {
            entry: JSON.stringify(choice.value),
            answerState: ansState.value,
          },
        });
      };

      return {
        reRender,
        onSubmit,
        partLabel,
        evaluateStatement,
        correctIndex,
        options,
        choice,
        select,
        selectedColor
      };
    },
  });
</script>

<style scoped>
</style>