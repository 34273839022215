
  import { computed, defineComponent, EmitsOptions, PropType, ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import SubmissionButtonGroup from "./SubmissionButtonGroup.vue";
  import useEvents from "@/components/use/useEvents";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import { AnswerState, UserResponseSetupData } from "./user-response.interface";
  import NotebookPage from "../../handwriting/Notebook/NotebookPage.vue";

  export default defineComponent({
    components: { SubmissionButtonGroup, NotebookPage },
    props: {
      partNr: {
        type: Number,
        required: true,
      },
      questionPartData: {
        type: Object as PropType<UserResponseSetupData>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecord: {
        type: Object as PropType<SumissionQuestionPart>,
        required: true,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const ansContent = ref(-1);
      const ansState = ref(AnswerState.NOT_CHECKED);

      console.debug("Setup User Numeric Input.")
      const evaluateStatement = (statement: string) => {
        return props.questionModel.evaluateStatement(statement).getData();
      };

      const partLabel = (index: number) => {
        return index !== -1 ? "Part " + (index + 1) + ". " : "";
      };


      if (props.isFinished) {
        // display something?
      }

      const isCorrect = computed(() => {
        return true;
      });

      const validationClass = computed(() => {
        switch (ansState.value) {
          case AnswerState.CORRECT:
            return "correct";
          case AnswerState.WRONG:
            return "wrong";
        }

        return "";
      });

      const { emitEvent } = useEvents(context);

      const onSubmit = (): void => {
        emitEvent("submit", {
          type: "submit",
          payload: {
            entry: JSON.stringify(ansContent.value),
            answerState: ansState.value,
          },
        });
      };

      return {
        validationClass,
        isCorrect,
        onSubmit,
        evaluateStatement,
        partLabel,
      };
    },
  });
