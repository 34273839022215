<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <input :id="fid"
           v-model="currentValue"
           :class="'form-control-input ' + validationState"
           type="checkbox"
           required
           :aria-describedby="fid"
           :readonly="schemaitem.properties.disabled">
  </form-input-group>
</template>

<script>
  import formInputGroup from "./form-input-layout.vue";
  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormInputCheckbox",
    components: { formInputGroup },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context) {
      return {
        ...useValidation(props, context),
      };
    },
  });
</script>
<style lang="">
</style>