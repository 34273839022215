
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, SetupContext } from "vue";
  import FormInputGroup from "./form-input-group.vue";

  export default defineComponent({
    name: "FormTab",
    components: {
      FormInputGroup
    },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const onChange = (data: unknown) => {
        context.emit("change");
      }

      return {
        onChange
      };
    }
  });
