import * as BABYLON from 'babylonjs';
import { v4 as uuid } from 'uuid';
import { Control_Panel } from "../controls/Control_Panel";
import { CompleteLaboratory } from "../laboratories/CompleteLaboratory";
import { MaterialRegistry } from "../primatives/Materials/MaterialRegistry";
import { CameraFactory, UniversalCamera } from "./CameraFactory";
import { SceneMetaData } from "./SceneMetaData";
import { ViscometerLaboratory } from '../laboratories/ViscometerLaboratory';
import { WindTunnelLaboratory } from '../laboratories/WindTunnelLaboratory';
import { MajorLossesLaboratory } from '../laboratories/MajorLossesLaboratory';
import { MinorLossesLaboratory } from '../laboratories/MinorLossesLaboratory';
import { BaseLaboratory } from '../laboratories/BaseLaboratory';
import { LinearMomentumLaboratory } from '../laboratories/LinearMomentumLaboratory';
import { LaboratoryTypes } from '../laboratories/LaboratoryTypes';

export class GameEngine {
    private scene: BABYLON.Scene;
    private engine: BABYLON.Engine;
    private canvas: HTMLElement;
    private assetManager: BABYLON.AssetsManager;
    private camera: BABYLON.UniversalCamera;
    private lights: BABYLON.Light[] = [];
    private laboratory: BaseLaboratory;
    private time = 0;
    private controlPanel: Control_Panel;

    constructor(canvas: HTMLCanvasElement, labType: LaboratoryTypes) {
        this.canvas = canvas;
        this.engine = new BABYLON.Engine(<HTMLCanvasElement>this.canvas, true, {}, true);
        this.scene = new BABYLON.Scene(this.engine);

        this.assetManager = new BABYLON.AssetsManager(this.scene);
        this.scene.actionManager = new BABYLON.ActionManager(this.scene);
        const univCam = new UniversalCamera(this.scene as any, this.canvas, {
            location: new BABYLON.Vector3(0, 1.4, -2),
            ellipsoid: new BABYLON.Vector3(0.2, 0.6, 0.2),
            target: new BABYLON.Vector3(0, 1.6, 0)
        } as any);

        this.camera = univCam.camera as any;
        this.controlPanel = new Control_Panel(this.scene);

        (this.scene.metadata as SceneMetaData) = {
            controlPanel: this.controlPanel,
            camera: this.camera,
            id: uuid()
        };

        // create laboratory
        switch (labType) {
            case LaboratoryTypes.EVERYTHING:
                this.laboratory = new CompleteLaboratory("Laboratory", this.scene, null);
                break;
            case LaboratoryTypes.VISCOMETER:
                this.laboratory = new ViscometerLaboratory("Laboratory", this.scene, null);
                break;
            case LaboratoryTypes.WINDTUNNEL_1:
                this.laboratory = new WindTunnelLaboratory("Laboratory", this.scene, null);
                break;
            case LaboratoryTypes.PIPE_RACK_MAJOR:
                this.laboratory = new MajorLossesLaboratory("Laboratory", this.scene, null);
                break;
            case LaboratoryTypes.PIPE_RACK_MINOR:
                this.laboratory = new MinorLossesLaboratory("Laboratory", this.scene, null);
                break;
            default:
            case LaboratoryTypes.MOMENTUM:
                this.laboratory = new LinearMomentumLaboratory("Laboratory", this.scene, null);
                break;
        }
    }

    public destroy() {
        window.removeEventListener('resize', this.resize);
        this.engine.stopRenderLoop();
        this.scene.dispose();
        this.engine.dispose();
    }

    /**
     *  1. Asyncronous loading of all assets needed by objects in the game
     *  2. Create objects and add them to the scene 
     *  3. Render loop to update and draw objects
     *  4. add gridpoints to the scene 
     *  5. start rendering the scene.
     * */
    public run() {

        // load assets into the assetmanager
        MaterialRegistry.addPreloadAssets(this.assetManager);
        this.laboratory.addPreloadAssets(this.assetManager);

        this.assetManager.onFinish = (tasks) => {
            this.render();
            this.setupRenderLoop();
            this.postObjectPositioning();

            // Watch for browser/canvas resize events
            window.addEventListener("resize", this.resize);

            this.resize();
        };

        this.assetManager.load();
    }

    /**
     * Render the laboratory and 
     * have each experiment render itself
     * */
    private render() {

        // render dynamic gui controls
        this.controlPanel.renderHelpWindow();

        // render lights (should be part of the lab)
        this.lights.push(CameraFactory
            .HemiLight(this.scene as any,
                new BABYLON.Vector3(0.5, 1, 0) as any) as any);
        this.lights[0].intensity = 1.4;

        this.laboratory.setOptions({
            camera: this.camera,
            floorWidth: 10
        });

        // render scene
        this.laboratory.render();
    }

    private postObjectPositioning() {
        this.laboratory.registerAbsoluteSnapPoints();
    }

    /**
     * Start the rendering of the scene 
     * */
    private setupRenderLoop() {

        // Register a render loop to repeatedly render the scene
        this.engine.runRenderLoop(() => {
            this.scene.render();
            const dT = this.engine.getDeltaTime();
            this.time += dT;
            this.laboratory.updateTime(this.time, dT);
        });
    }

    private resize() {
        this.engine.resize();
    }
}
