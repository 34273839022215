import { mjsUnknown, mjsUnit, mjsDenseMatrix } from '../../mathjs/math-types';
import { TypeGuard } from "../../mathjs/Type-guards";
import { QuestionModel } from "../model/model-question";
import { QRandom } from "../model/qRandom";
import { UserResponseSetupData } from "./user-response.interface";

export function generateNumericalMultipleChoice(rrd: UserResponseSetupData, model: QuestionModel): { index: number, array: string[] } {
   const answers: string[] = [];

   const assembleChoices = () => {

      // evaluate ans statement
      let term = model.evalToNumeric(rrd.correctAnswer);

      const alterations: ((term: mjsUnknown) => mjsUnknown)[] = [];
      if (rrd.changeSign) { alterations.push(changeSign) }
      if (rrd.changeOrder) { alterations.push(changeOrder) }
      if (rrd.changeValue) { alterations.push(changeValue) }
      if (rrd.changeInteger) { alterations.push(changeInteger) }

      recordAnswer(term);

      let counter = 0;
      while (answers.length < rrd.nrChoices && counter < 100) {
         counter++;
         const nrAlterationsBeforeRecord = Math.floor(2 * QRandom.getRnd()) + 1;
         for (let i = 0; i < nrAlterationsBeforeRecord; i++) {
            term = alterAns(term);
         }
         recordAnswer(term);
      }

      function alterAns(ans: mjsUnknown) {
         const alterationType = Math.floor(QRandom.getRnd() * alterations.length);
         return alterations[alterationType](ans);
      }

      function recordAnswer(nTerm: mjsUnknown) {
         // convert to string
         const ans = model.evalToText(nTerm, 3, false, true)
         if (answers.findIndex(k => k === ans) !== -1) return false;
         answers.push(ans);
         return true;
      }

      function multiplyByScalar(curAns: mjsUnknown, scalarVal: number): mjsUnknown {

         if (TypeGuard.isNumber(curAns))
            return curAns as number * scalarVal;

         if (TypeGuard.isUnit(curAns)) {
            (curAns as mjsUnit).value *= scalarVal;
            return curAns;
         }

         if (TypeGuard.isMatrix(curAns)) {
            const ind = Math.floor(3 * QRandom.getRnd());
            const matrixCurAns = curAns as mjsDenseMatrix;
            matrixCurAns._data[ind] =
               multiplyByScalar(matrixCurAns._data[ind], scalarVal) as number | mjsUnit;
            return curAns;

         }

         return 0;
      }


      function addScalar(curAns: mjsUnknown, scalarVal: number): mjsUnknown {
         if (TypeGuard.isNumber(curAns))
            return curAns as number + scalarVal;

         if (TypeGuard.isUnit(curAns)) {
            (curAns as mjsUnit).value += scalarVal;
            return curAns;
         }

         if (TypeGuard.isMatrix(curAns)) {
            const ind = Math.floor(3 * QRandom.getRnd());
            const matrixCurAns = curAns as mjsDenseMatrix;
            matrixCurAns._data[ind] =
               addScalar(matrixCurAns._data[ind], scalarVal) as number | mjsUnit;
            return curAns;

         }

         return 0;
      }

      function changeSign(curAns: mjsUnknown): mjsUnknown {
         return multiplyByScalar(curAns, -1);
      }

      function changeOrder(curAns: mjsUnknown): mjsUnknown {
         const scalarMultiple = Math.pow(10, (Math.floor(QRandom.getRnd() * 3) + 1) * ((QRandom.getRnd() < 0.5) ? -1 : 1));
         return multiplyByScalar(curAns, scalarMultiple);
      }

      function changeValue(curAns: mjsUnknown) {
         let scalarMultiple = 1;

         if (QRandom.getRnd() > 0.3) {
            // 10% change
            scalarMultiple = (1 + (QRandom.getRnd() - 0.5) * 0.1);
         } else {
            // half and double
            scalarMultiple = QRandom.getRnd() > 0.5 ? 2 : 0.5;
         }

         return multiplyByScalar(curAns, scalarMultiple);
      }

      function changeInteger(nTerm: mjsUnknown): mjsUnknown {
         const maxVariationD2 = 5;
         const tmpAdd = (Math.floor(2 * maxVariationD2 * QRandom.getRnd() - maxVariationD2));
         return addScalar(nTerm, tmpAdd);
      }
   }

   const shuffle = (array: string[], index: number) => {
      for (let i = array.length - 1; i > 0; i--) {
         const j = Math.floor(QRandom.getRnd() * i);

         if (index === i) {
            index = j;
         }

         if (index === j) {
            index = i;
         }

         const temp = array[i];
         array[i] = array[j];
         array[j] = temp;
      }
      return { index, array };
   };

   assembleChoices();
   return shuffle(answers, 0);
}