import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import { BaseRenderedObject, RenderedObjectTypes } from "../../primatives/BaseRenderedObject";
import { MaterialRegistry } from "../../primatives/Materials/MaterialRegistry";
import { BUtil } from '../../utils/BabylonUtils';
import { VariableTable } from "../../values/VariableTable";
import { ScalarValue, _u } from '../../values/ScalarValue';
import { ControlResponseRequested } from '../../controls/Control_Base';
import { Nullable } from 'babylonjs/types';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';



export enum JetDeflectorModel {
    Flat,
    Hemisphere
}

export interface JetDeflectorOptions {
    model: JetDeflectorModel,
    location: BABYLON.Vector3,
    diameter: number,
    jetDiameter: number[],
    maxLength: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    velocity: ScalarValue,
    rodHeight: number,
    rodDiameter: number
}


export class JetDeflector extends BaseRenderedObject {
    public fluidMesh: Nullable<BABYLON.Mesh> = null;
    public currentLength = 0;
    public waterTransformNode: Nullable<BABYLON.Mesh> = null;
    public curvedDeflector: Nullable<BABYLON.Mesh> = null;
    public flatDeflector: Nullable<BABYLON.Mesh> = null;

    private options: JetDeflectorOptions = {
        model: JetDeflectorModel.Flat,
        diameter: 0.02,
        jetDiameter: [0.02, 0.01],
        maxLength: 1,
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        velocity: _u(0, 'm/s'),
        rodHeight: 0.1,
        rodDiameter: 0.02
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.JET_DEFLECTOR;
    }

    public setOptions(options: JetDeflectorOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }


    render() {
        const diameter = 1.2;

        const height = 0.05;
        const shape = [
            BUtil.V3(this.options.diameter * 0.5, -height / 2.0, 0),
            BUtil.V3(this.options.diameter * 0.4, 0, 0),
            BUtil.V3(this.options.diameter * 0.25, height / 2.0, 0),

        ];

        const base = new BABYLON.Mesh("JD_root");
        const blackMaterial = MaterialRegistry.getColor(this.scene, BABYLON.Color3.BlackReadOnly);

        const curvedDeflector = BABYLON.MeshBuilder.CreateLathe("JD_Curved Deflector", {
            shape: shape,
        }, this.scene);
        curvedDeflector.material = blackMaterial;
        curvedDeflector.setParent(base);
        this.curvedDeflector = curvedDeflector;
        curvedDeflector.isVisible = false;

        const flatDeflector = BUtil.Cylinder("JD_deflector", 0.03, this.options.diameter, this.scene);
        flatDeflector.material = blackMaterial;
        flatDeflector.setParent(base);
        this.flatDeflector = flatDeflector;

        const rod = BUtil.Cylinder("JD_rod", this.options.rodHeight, this.options.rodDiameter, this.scene);
        BUtil.setParentAndTranslate(rod, base, 0, this.options.rodHeight / 2.0, 0);

        this.fluidMesh = BABYLON.Mesh.CreateHemisphere("water", 32, diameter, this.scene);
        this.fluidMesh.scaling.x = this.options.jetDiameter[this.options.model];
        this.fluidMesh.scaling.z = this.options.jetDiameter[this.options.model];
        this.fluidMesh.scaling.y = 1;
        //this.fluidMesh.setPivotMatrix(BABYLON.Matrix.Translation(0, 0.5, 0), false);


        this.waterTransformNode = new BABYLON.Mesh("JD_tranformNode");
        BUtil.setParentAndTranslate(this.fluidMesh, this.waterTransformNode, 0, -diameter / 2, 0);
        this.waterTransformNode.setParent(base);


        // Water material
        const waterMaterial = new Materials.WaterMaterial("waterMaterial", this.scene, new BABYLON.Vector2(100, 100));
        waterMaterial.bumpTexture = new BABYLON.Texture("//www.babylonjs.com/assets/waterbump.png", this.scene);
        waterMaterial.backFaceCulling = true;

        waterMaterial.windForce = 50.4;
        waterMaterial.waveHeight = 0.0;
        waterMaterial.bumpHeight = 0.5;
        waterMaterial.waveLength = 0.25;
        waterMaterial.waveSpeed = 100.0;
        waterMaterial.waterColor = new BABYLON.Color3(0.1, 0.1, 0.7);
        waterMaterial.windDirection = new BABYLON.Vector2(0, -1);
        waterMaterial.colorBlendFactor = 0.1;
        const skybox = this.scene.getMeshByName("reflectionSkyBox");
        waterMaterial.addToRenderList(skybox);
        waterMaterial.alpha = 0.4;

        this.fluidMesh.material = waterMaterial;


        base.setParent(this.parent?.getContainerMesh() ?? null);
        base.position = this.options.location.clone();

        const setupActionManager = (geom: BABYLON.Mesh) => {
            // register hit response
            geom.actionManager = new BABYLON.ActionManager(this.scene);

            // cannot set material which has only a getter
            geom.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                    (evt: BABYLON.ActionEvent) => {
                        geom.material = blackMaterial;
                    }));

            geom.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                    (evt: BABYLON.ActionEvent) => {
                        geom.material = MaterialRegistry.getHighlight(this.scene);
                    }));

            geom.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                    (evt: BABYLON.ActionEvent) => {
                        // select jetdeflector geometry
                        this.getControlPanel()
                            .processHit(ControlResponseRequested.SELECT_JET_DEFLECTOR_GEOM,
                                {
                                    controlParams: {}
                                },
                                this,
                                { properties: ["DEFL_GEOM"] });
                    }));



        }

        setupActionManager(curvedDeflector);
        setupActionManager(flatDeflector);

        return super.render();
    }

    private selectModel(modelType: number) {
        if (this.curvedDeflector && this.flatDeflector)
            switch (modelType) {
                case 0:
                default:
                    this.options.model = JetDeflectorModel.Flat;
                    this.curvedDeflector.isVisible = false;
                    this.flatDeflector.isVisible = true;
                    break;
                case 1:
                    this.options.model = JetDeflectorModel.Hemisphere;
                    this.curvedDeflector.isVisible = true;
                    this.flatDeflector.isVisible = false;
                    break;
            }
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "DEFL_GEOM":
                this.selectModel(value);
                break;
            case "VELOCITY":
                this.options.velocity = value;
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "DEFL_GEOM":
                return this.options.model;
        }

        super.getProperty(name);
    }

    setObjectStateFromTable(variableTable: VariableTable) {
        const time = variableTable.get("TIME");
        super.setObjectStateFromTable(variableTable);
    }

    setTableFromObjectState(variableTable: VariableTable) {
        const velocity = this.options.velocity.in("m/s");
        if (TypeGuard.isNullOrUndefined(this.fluidMesh) || TypeGuard.isNullOrUndefined(this.waterTransformNode))
            return;

        this.fluidMesh.scaling.x = this.options.jetDiameter[this.options.model];
        this.fluidMesh.scaling.z = this.options.jetDiameter[this.options.model];
        console.log(this.options.model);
        if (velocity > 0) {
            this.waterTransformNode.isVisible = true;
            if (this.currentLength < this.options.maxLength) {
                const deltaT = variableTable.get("DELTA_T")?.in('s') ?? 0;
                this.currentLength = Math.min(this.options.maxLength, this.currentLength + 10 * velocity * deltaT);
                (this.fluidMesh.material as any).windForce = 200 * velocity;
                this.waterTransformNode.scaling.y = this.currentLength;
            }
        } else {
            this.waterTransformNode.isVisible = false;
            this.currentLength = 0;
            this.waterTransformNode.scaling.y = this.currentLength;
        }

        super.setTableFromObjectState(variableTable);
    }
}

