<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <b-select :id="fid"
              v-model="currentValue"
              :class="'form-control ' + validationState"
              :is-disabled="schemaitem.properties.disabled"
              :options="schemaitem.choices" />
  </form-input-group>
</template>

<script lang="ts">
  import formInputGroup from "./form-input-layout.vue";
  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps, formItemMixinInterface } from "../mixins/form.mixins";
  import { defineComponent, SetupContext } from "vue";
  import BSelect from "../../bootstrap/b-select.vue";

  export default defineComponent({
    name: "FormInputSelectlist",
    components: { formInputGroup, BSelect },
    props: {
      ...formItemMixinProps
    },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext) {
      return {
        ...useValidation(props as unknown as formItemMixinInterface, context),
      };
    },
  });
</script>
<style lang="">
</style>