import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { BaseRecord, lookupRecordCreator, RecordTypes } from "./database.interface";
import { DatabaseUtility } from "./databaseUtility";
import { v4 as uuidv4 } from 'uuid';

export class WrappedRecord<T extends BaseRecord, U> {
    private objectType: RecordTypes;
    private _data: T | null = null;

    constructor(objectType: RecordTypes) {
        this.objectType = objectType;
        return this;
    }

    // getters
    id(): string { return (this.checkForNullData()) ? "" : this._data!.id; }
    data(): T { return this._data!; }
    setData(data: T) {
        this._data = data;
    }

    myCreator() { return lookupRecordCreator<T, U>(this.objectType); }

    checkForNullData() {
        const isNull = (!this._data);
        if (isNull) console.debug("Wrapped Record: Attempting to perform database action on null data.");
        return isNull;
    }

    updateFromSchema(schema: FormSchemaItem) {
        if (this.checkForNullData()) return null;
        this._data = DatabaseUtility.createUpdated<T>(this._data!, schema);
        return this;
    }

    async deleteRecordFromDB() {
        if (this.checkForNullData()) return null;
        const creator = this.myCreator();
        return await DatabaseUtility.executeOnDB(creator.deleteQuery,
            creator.getDeleteItemParameters(this.data()) as unknown as BaseRecord,
            "Error deleting " + this.objectType + " from database.");
    }

    async saveToDB() {
        if (this.checkForNullData()) return null;

        const modifiedData = this.myCreator().onBeforeSaveToDB(this._data!, this);
        return await DatabaseUtility.executeOnDB(this.myCreator().createQuery,
            modifiedData,
            "Error saving " + this.objectType + " to database.");
    }

    initialize(options: Partial<T>, createOptions: U) {
        this._data = { ...this._data, ...this.myCreator().defaultValues, ...options };
        this._data.id = uuidv4();
        // some classes require id to be used to initialize other data members.
        // those can be initizlied in onAfterCreatedModifyData.
        this.myCreator().onAfterCreatedModifyData(this._data, createOptions, this);
        return this;
    }

    makeUnique(options: Partial<T>, createOptions: U) {
        if (this._data === null) {
            return this.initialize(options, createOptions);
        } else {
            this._data.id = uuidv4();
            this.myCreator().onAfterCreatedModifyData(this._data, createOptions, this);
            return this;
        }
    }


    async createNewInDB(options: Partial<T>, createOptions: U) {
        this.initialize(options, createOptions);
        console.log(this._data)
        return await DatabaseUtility.executeOnDB(this.myCreator().createQuery,
            this.myCreator().onBeforeSaveToDB(this._data!, this),
            "Error creating " + this.objectType + " in the database.");
    }

    wrapDBRecord(recordFromDatabase: Partial<T>) {
        this._data = { ...this._data, ...this.myCreator().defaultValues, ...recordFromDatabase };
        this.myCreator().onAfterRecordWrapFromDB(this._data, this)
        return this;
    }

    async updateInDB() {
        if (this.checkForNullData()) return null;

        return await DatabaseUtility.updateInDB(this.myCreator().updateQuery,
            this.myCreator().onBeforeSaveToDB(this._data!, this),
            "Error updating " + this.objectType + " in database");
    }

    debugInfo() {
        console.debug("Wrapper Type:", this.objectType);
        console.debug("Creator Object:", this.myCreator());
    }


}

