import { create, all, Unit, MathType } from "mathjs";
import { TypeGuard } from "./Type-guards";
import { ExtendedMathJsStatic, LookupTableType, mjsUnknown, mjsDenseMatrix, MjsNode } from "./math-types";
import { MathJSTree } from "./math-node-tree";
import { mjsParser } from "./extension/parse";
import { integrate } from "./extension/integrate";
import { extendedNumericMathJSFunctions } from "./extension/math-util-functions";

const mathjs = create(all) as ExtendedMathJsStatic;



mathjs.import({
   integrate: integrate,
});

mathjs.import(extendedNumericMathJSFunctions);

mathjs.createUnit({
   mph: {
      definition: '1 mile/hour'
   },
   gpm: {
      definition: '1 gal/minute'
   }
});


export class MjsMath {
   static getMathJS(): ExtendedMathJsStatic {
      return mathjs;
   }

   // ----- Units ------------------
   static getValueFromUnitType(unit: Unit): number {
      return (unit as unknown as { value: number }).value;
   }

   static isParseableUnit(unit: string): boolean {
      try {
         mathjs.Unit.parse(unit);
         return true;
      } catch (err) {
         return false;
      }
   }

   static compare(value1: mjsUnknown, value2: mjsUnknown) {
      return mathjs.compare(value1 as MathType, value2 as MathType);
   }

   static isValuelessUnit(unit: string): boolean {
      return mathjs.Unit.isValuelessUnit(unit);
   }

   static createUnit(inValue: number, inUnit: string): Unit {
      return mathjs.unit(inValue, inUnit);
   }

   static getSIUnitAndValueFor(inValue: number, inUnit: string): [string, string] {
      const siUnit = mathjs.unit(inValue, inUnit).toSI();
      const value = this.getValueFromUnitType(siUnit).toString();
      return [value, siUnit.formatUnits()]
   }

   static getSIUnitFor(unit: string): string {
      return mathjs.unit(unit).toSI().formatUnits();
   }

   // ------------ Vectors ------------------
   static getDataArrayFromMatrix(val: mjsUnknown) {
      if (TypeGuard.isMatrix(val)) {
         return val._data;
      }

      throw new Error("Trying to get data from item that is not a matrix.");
   }

   // expressions 


   static evaluate(expression: string) {
      const parsed = mjsParser.parseEnforceBracketedUnits(expression, {});
      return parsed.evaluate();
   }

   // utility
   static toExpression(expression: string, variableTable: LookupTableType) {
      const nodes = mjsParser.parseEnforceBracketedUnits(expression, variableTable);
      return MathJSTree.replaceWithLookupInfo(nodes, variableTable);
   }

   static toNumeric(expression: string, variableTable: LookupTableType) {
      const nodes = mjsParser.parseEnforceBracketedUnits(expression, variableTable);
      return this.evalNodes(nodes, variableTable);
   }

   static evalNodes(node: MjsNode, variableTable: LookupTableType) {
      const newExp = MathJSTree.replaceWithLookupInfo(node, variableTable);
      return newExp.compile().evaluate();
   }

   static getOnlyUnitString(val: mjsUnknown): string {
      if (TypeGuard.isNumber(val)) { return ""; }
      if (TypeGuard.isUnit(val)) { return val.formatUnits(); }
      if (TypeGuard.isMatrix(val)) { return this.getOnlyUnitString((val as unknown as { _data: mjsUnknown[] })._data[0]); }

      return "Unable to find unit";
   }

   static getOnlyNumericPart(val: number): number
   static getOnlyNumericPart(val: Unit): number
   static getOnlyNumericPart(val: mjsDenseMatrix): number[]
   static getOnlyNumericPart(val: mjsUnknown): number | number[]
   static getOnlyNumericPart(val: mjsUnknown) {
      if (TypeGuard.isNumber(val)) {
         return val;
      }

      if (TypeGuard.isUnit(val)) {
         return this.getValueFromUnitType(val);
      }

      if (TypeGuard.isMatrix(val)) {
         const arr = this.getDataArrayFromMatrix(val);
         const nrArr = (arr as mjsUnknown[])
            .map((v) => {
               return this.getOnlyNumericPart(v);
            });

         return nrArr as number[];
      }

      return 0;
   }


   static typeOf(x: unknown): string {
      return mathjs.typeOf(x);
   }

   static reduceIfPossible(val: mjsUnknown, lookupTable: LookupTableType) {
      if (TypeGuard.isNumber(val) || TypeGuard.isUnit(val))
         return val;

      if (TypeGuard.isString(val)) {
         return MjsMath.toNumeric(val, lookupTable);
      }

      return val;
   }

   static isClose(val1: mjsUnknown, val2: mjsUnknown, epsilon = 0.01): boolean {
      if (TypeGuard.isNumber(val1) && TypeGuard.isNumber(val2)) {
         return Math.abs(val1 - val2) < epsilon;
      }

      if (TypeGuard.isUnit(val1) && TypeGuard.isUnit(val2)) {
         if (!val1.equalBase(val2)) return false;
         const uVal1 = this.getValueFromUnitType(val1.toSI());
         const uVal2 = this.getValueFromUnitType(val2.toSI());
         return Math.abs(uVal1 - uVal2) < epsilon;
      }

      if (TypeGuard.isMatrix(val1) && TypeGuard.isMatrix(val2)) {
         return this.isClose(val1.get([0]), val2.get([0])) &&
            this.isClose(val1.get([1]), val2.get([1])) &&
            this.isClose(val1.get([2]), val2.get([2]));
      }

      return false;
   }



}

