import * as BABYLON from 'babylonjs';
export class BUtil {
    static Box(name: string, width: number, height: number, depth: number, scene: BABYLON.Scene) {
        return BABYLON.MeshBuilder.CreateBox(name, { width: width, height: height, depth: depth }, scene);
    }

    static Cylinder(name: string, height: number, diameter: number, scene: BABYLON.Scene) {
        return BABYLON.MeshBuilder.CreateCylinder(name, { height: height, diameter: diameter }, scene);
    }

    static V3(x: number, y: number, z: number) {
        return new BABYLON.Vector3(x, y, z);
    }

    static cloneAndTranslate(obj: BABYLON.Mesh, cloneParent: BABYLON.Mesh,
        newName: string, x: number, y: number, z: number): BABYLON.Mesh {
        const newObj = obj.clone(newName);
        newObj.setParent(cloneParent);
        newObj.locallyTranslate(BUtil.V3(x, y, z));
        return newObj;
    }

    static setParentAndTranslate(obj: BABYLON.Mesh, parent: BABYLON.Mesh, x: number, y: number, z: number): BABYLON.Mesh {
        obj.setParent(parent);
        obj.locallyTranslate(BUtil.V3(x, y, z));
        return obj;
    }
}