import * as BABYLON from 'babylonjs';
import { BaseRenderedObject, RenderedObjectTypes } from "../../primatives/BaseRenderedObject";
import { MaterialRegistry } from "../../primatives/Materials/MaterialRegistry";
import { ScalarValue, _u } from "../../values/ScalarValue";
import { VariableTable } from "../../values/VariableTable";
import { BUtil } from '../../utils/BabylonUtils';
import { ConnectableObjectInterface, PortInternals } from '../Electronic/CordConnector';
import { ControlResponseRequested } from '../../controls/Control_Base';
import { SceneMetaData } from '../../engine/SceneMetaData';
import { CylinderGenerator } from '../../primatives/CylinderGenerator';
import { Nullable } from 'babylonjs/types';



export enum LoadCellModel {
    Sbeam,
    circular_50N,
    bar
}

export interface LoadCellOptions {
    model: LoadCellModel,
    location: BABYLON.Vector3,
    minLoad: ScalarValue,
    maxLoad: ScalarValue
}


export class LoadCell extends BaseRenderedObject implements ConnectableObjectInterface {
    public portMap: Map<string, PortInternals> = new Map();
    public static connectorTemplate: Nullable<BABYLON.Mesh> = null;
    private options: LoadCellOptions;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.LOAD_CELL;
        this.portMap.set("0", { name: "0", value: null, connection: { isConnected: false, connectedPort: "", connectedObject: null } });
        this.options = this.defaultOptions(LoadCellModel.Sbeam);
    }

    public setOptions(options: LoadCellOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public getBezierPoints(port: string) {
        const local_pos = new BABYLON.Vector3(-0.05, 0, 0);

        if (this.myContainerNode === null) { return [local_pos, local_pos]; }

        const pos = [];
        const matrix = this.myContainerNode.computeWorldMatrix(true);

        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        local_pos.x -= 0.2;
        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        return pos;
    }

    public registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface) {
        const tmpPort = this.portMap.get(myPort)
        if (tmpPort)
            tmpPort.connection = { isConnected: true, connectedPort: connectPort, connectedObject: connectToObj };
    }

    public removeConnection(myPort: string) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.connectedObject?.removeConnection(tmpPort.connection.connectedPort);
            tmpPort.connection.isConnected = false;
        }
    }

    public defaultOptions(model: LoadCellModel): LoadCellOptions {
        switch (model) {
            case LoadCellModel.Sbeam:
                return {
                    model: model,
                    location: BABYLON.Vector3.Zero(),
                    maxLoad: _u(10, "N"),
                    minLoad: _u(1, "N")
                };
            case LoadCellModel.bar:
                return {
                    model: model,
                    location: BABYLON.Vector3.Zero(),
                    maxLoad: _u(28, "N"),
                    minLoad: _u(4, "N")
                };
        }
        throw new Error("Unable to find model type.")
    }

    render() {

        const loadCellMaterial = new BABYLON.StandardMaterial("material", this.scene);
        loadCellMaterial.diffuseTexture = new BABYLON.Texture("https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/LoadCellTexture_50N.png", this.scene);

        const faceUV = [];
        faceUV[0] = new BABYLON.Vector4(0, 0, 0, 0);
        faceUV[1] = new BABYLON.Vector4(1, 0, 0.0, 1.0);
        faceUV[2] = new BABYLON.Vector4(0, 0, 0.25, 1);

        const faceColors = [];
        faceColors[0] = new BABYLON.Color4(0.5, 0.5, 0.5, 1)

        const loadCell = BABYLON.MeshBuilder.CreateCylinder("loadCell", { diameter: 0.1, height: 0.04, faceUV: faceUV, faceColors: faceColors }, this.scene);
        loadCell.material = loadCellMaterial;


        const baseCylinder = loadCell; // BUtil.Cylinder("Ring", 0.04, 0.1, this.scene);
        //baseCylinder.material = MaterialRegistry.getSilver(this.scene);

        const port = CylinderGenerator.create(this.scene, BUtil.V3(-0.08, 0, 0), BUtil.V3(0, 0, 0), 0.01);
        port.setParent(baseCylinder);

        // position parts
        baseCylinder.position = this.options.location;
        baseCylinder.setParent(this.parent?.getContainerMesh() ?? null);

        this.myContainerNode = baseCylinder;


        // register hit response
        baseCylinder.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        baseCylinder.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    baseCylinder.material = loadCellMaterial;
                }));

        baseCylinder.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    baseCylinder.material = MaterialRegistry.getHighlight(this.scene);
                }));

        baseCylinder.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    let command = null;
                    if (this.portMap.get("0")?.connection?.isConnected) {
                        command = ControlResponseRequested.REMOVE_CONNECTION;
                    } else {
                        command = ControlResponseRequested.SECONDARY_FORCE_CONNECTION;
                    }
                    (this.scene.metadata as SceneMetaData).controlPanel
                        .processHit(command,
                            {
                                controlParams: {}
                            },
                            this,
                            { port: this.portMap.get("0") });
                }));

        return super.render();
    }

    setObjectStateFromTable(variableTable: VariableTable) {
        const time = variableTable.get("TIME");
        super.setObjectStateFromTable(variableTable);
    }

    setTableFromObjectState(variableTable: VariableTable) {
        super.setTableFromObjectState(variableTable);
    }
}

