interface CameraOptions {
    location: BABYLON.Vector3,
    ellipsoid: BABYLON.Vector3,
    minZ: number,
    speed: number,
    target: BABYLON.Vector3
}

export class UniversalCamera {
    public camera: BABYLON.UniversalCamera;

    public options: CameraOptions = {
        location: BABYLON.Vector3.Zero(),
        ellipsoid: new BABYLON.Vector3(0.2, 0.5, 0.2),
        minZ: 0.01,
        speed: 0.05,
        target: BABYLON.Vector3.Zero()
    };

    constructor(scene: BABYLON.Scene, canvas: any, options: CameraOptions) {
        this.options = { ...this.options, ...options };

        const camera: BABYLON.UniversalCamera = new BABYLON.UniversalCamera("UniversalCamera", this.options.location, scene);
        camera.setTarget(this.options.target);
        camera.speed = this.options.speed;
        camera.minZ = this.options.minZ;
        camera.ellipsoid = this.options.ellipsoid;
        camera.attachControl(canvas, true);

        this.camera = camera;
    }
}

export class CameraFactory {
    public static ArcRotate(scene: BABYLON.Scene, canvas: any, radius: number): BABYLON.Camera {
        const camera: BABYLON.Camera = new BABYLON.ArcRotateCamera("Camera", -Math.PI, Math.PI / 2.0, radius, BABYLON.Vector3.Zero(), scene);
        camera.attachControl(canvas, true);
        return camera;
    }

    public static Universal(scene: BABYLON.Scene, canvas: any, location: BABYLON.Vector3): BABYLON.UniversalCamera {
        const camera: BABYLON.UniversalCamera = new BABYLON.UniversalCamera("UniversalCamera", location, scene);
        camera.setTarget(BABYLON.Vector3.Zero());
        camera.speed = 0.05;
        camera.minZ = 0.01;
        camera.ellipsoid = new BABYLON.Vector3(0.2, 0.5, 0.2);
        camera.attachControl(canvas, true);
        return camera;
    }

    public static PointLight(scene: BABYLON.Scene, x: number, y: number, z: number, intensity = 0.7): BABYLON.Light {
        const pointLight = new BABYLON.PointLight("Omni", new BABYLON.Vector3(x, y, z), scene);
        pointLight.intensity = intensity;
        return pointLight;
    }

    public static HemiLight(scene: BABYLON.Scene, upVec: BABYLON.Vector3, intensity = 0.7): BABYLON.Light {
        const light = new BABYLON.HemisphericLight("HemiLight", upVec, scene);
        light.intensity = intensity;
        return light;
    }
}

