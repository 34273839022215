
  import useEvents from "@/components/use/useEvents";
  import { ChildBearing } from "@/utils/arrays";
  import { defineComponent, EmitsOptions, PropType, Ref, ref, SetupContext } from "vue";
  import BButton from "./b-button.vue";
  import BSortableList from "./b-sortable-list.vue";
  import { useMediaQuery } from "@vueuse/core";

  export default defineComponent({
    name: "BOffCanvasList",
    components: { BSortableList, BButton },
    props: {
      modelValue: {
        type: Array as PropType<ChildBearing[]>,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      activeId: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        required: true
      },
      nestable: {
        type: Boolean,
        required: true
      }
    },
    emits: ["click", "addItem", "deleteItem", "reorderItems", "update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const { emitTyped } = useEvents(context);
      let arrayData: Ref<ChildBearing[]>;

      if (props.editable)
        arrayData = ref(props.modelValue[0].children);
      else
        arrayData = ref(props.modelValue);

      console.log("OffCanvas active:", props.activeId, props.modelValue)

      const onClick = (itemID: string) => {
        emitTyped('click', itemID);
      }

      function getTypedEvent(eventString: string) {
        return (activeItemID: string) => {
          context.emit('update:modelValue', arrayData.value);
          emitTyped(eventString, activeItemID);
        }
      }

      const onReorderItems = getTypedEvent('reorderItems');
      const onAddItem = getTypedEvent('addItem');
      const onDeleteItem = getTypedEvent('deleteItem');

      const updateModelValue = (newArray: ChildBearing[]) => {
        console.log("Update Model Value in OffCanvas")
        arrayData.value = newArray;
      }

      const isLargeScreen = useMediaQuery('(min-width: 1024px)')

      return {
        isLargeScreen,
        onAddItem,
        onDeleteItem,
        onReorderItems,
        onClick,
        arrayData,
        updateModelValue
      };
    },
  });
