
  import LoginForm from "../components/LoginForm.vue";

  export default {
    components: {
      LoginForm,
    },
    setup(): unknown {
      return {};
    },
  };
