<template>
  <div class="p-4">
    <h2>Assignment Editor </h2>
    <hr>
    <div class="contaier-fluid">
      <div class="row">
        <div class="col-2">
          Shareable Link
        </div>
        <div class="col">
          {{ 'https://www.vengla.com/assignment/' + courseId + '/' + textId + '/' + pageId + '/' + pageId }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <FormRoot :key="formKey"
                    :is-loading="isLoading"
                    :schemaitem="schema"
                    :button-list="buttonList"
                    :triggers="triggers"
                    :displaybehavior="displaybehavior"
                    @change="onChange"
                    @btnPress="onPress" />
        </div>
      </div>
      <hr>
      <div class="row g-0">
        <div class="col-4 p-2">
          <b-button-dropdown label="Add"
                             icon="bi-plus"
                             class="float-start"
                             :options="dropdownOptions"
                             @click="onInsertComponent" />
          <b-button class="btn btn-danger float-end"
                    icon="bi-trash"
                    @click="onDeleteComponent" />
        </div>
        <div class="col-8 d-flex align-items-center">
          <span>Item #{{ activeIndex + 1 }} Name: </span>
          <input v-if="componentList.length>0"
                 placeholder="Enter Name Here..."
                 class="titleInput "
                 :value="activeComponent.name"
                 @input="onChangeItemName(activeIndex, $event.target.value)">
        </div>
      </div>
      <div class="row g-0">
        <div class="col-4  overflow-auto border p-2">
          <table class="table"
                 :key="componentList.length">
            <draggable v-model="componentList"
                       item-key="id"
                       tag="tbody"
                       class=""
                       handle=".handle">
              <template #item="{element, index}">
                <tr :class="{ active: activeIndex === index}"
                    @click="onClick(index)">
                  <td class="thinColumn">
                    <i v-if="activeIndex === index"
                       class="bi bi-arrows-move handle" />
                  </td>
                  <td class="thinColumn">
                    <i class="bi"
                       :class="getIconForComponentType(element.type)" />
                  </td>
                  <td class="prefix">
                    {{ (index+1) }}
                  </td>
                  <td class="itemName">
                    {{ element.name }}
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
        <div v-if="componentList.length>0"
             :key="activeIndex"
             class="col-8">
          <component :is="getComponentEditor(activeComponent.type)"
                     :component="activeComponent"
                     :course-id="courseId"
                     @update:component="componentList[activeIndex]=$event"
                     @change="onComponentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import BButtonDropdown from '@/components/bootstrap/b-button-dropdown.vue';
  import BButton from '@/components/bootstrap/b-button.vue';
  import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
  import MarkdownComponentEditor from '@/components/contentGenerator/pageComponents/MarkdownComponentEditor.vue';
  import BabylonComponentEditor from '@/components/contentGenerator/pageComponents/BabylonComponentEditor.vue';
  import QuizComponentEditor from '@/components/contentGenerator/pageComponents/QuizComponentEditor.vue';
  import { PageComponentTypes } from '@/components/contentGenerator/pages/pages.interface';
  import useCourseMaterials from '@/store/connectors/useCourseMaterials';
  import { computed, defineComponent, reactive, ref } from 'vue'
  import draggable from "vuedraggable";
  import { bookEditorProps, usePageEditorFunctions } from './usePageEditorFunctions';
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import { FormButtonAction } from '@/components/formGenerator/form.interface';
  import useSchema from '@/components/use/useSchema';
  import { getAssignmentSchema } from '@/store/database/Textbook/record-assignment-schema'
  import useReactiveForm from '@/components/use/useReactiveForm';
  import { FormSchemaItem } from '@/components/formGenerator/form-schema.interface';
  import { AssignmentOptionsInput } from '@/API';

  export default defineComponent({
    name: "AssignmentPageEditor",
    components: {
      draggable,
      BButtonDropdown,
      BButton,
      MarkdownComponentEditor,
      QuizComponentEditor,
      BabylonComponentEditor,
      FormRoot
    },
    props: {
      ...bookEditorProps,
      pageId: {
        type: String,
        required: true
      }
    },
    async setup(props) {
      console.debug("Load Assignment Page Editor");
      const { findPage, findBook } = useCourseMaterials();
      const { pageHasComponent,
        getComponentList,
        getEmptyComponent,
        getIconForComponentType,
        changeItemNameAndUpdateTOC } = usePageEditorFunctions(() => { return; });
      const assignmentPage = await findPage(props.courseId, props.textId, props.pageId);
      const assignmentBook = await findBook(props.courseId, props.textId);
      const toc = assignmentBook?.getTableOfContents();

      const componentList = reactive(getComponentList(assignmentPage));


      // ------ Assignment -------
      const { schema_getJSON } = useSchema();

      const buttonList: FormButtonAction[] = [
        {
          class: 'btn-primary m-1 save',
          label: 'Hints',
          triggerEvent: { type: 'hints', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: false
        },
        {
          class: 'btn-success',
          label: 'Save',
          triggerEvent: { type: 'save', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: true
        }
      ];

      const {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
      } = useReactiveForm(getAssignmentSchema(props.courseId, props.textId),
        assignmentPage?.data() as unknown as Record<string, unknown>);
      displaybehavior.showJSONButton = false;

      const updatePageFromFormData = async (formSchema: FormSchemaItem) => {
        const jsonData = schema_getJSON(formSchema).data;

        const content = {
          content: { components: componentList }
        };

        if (toc && assignmentPage && assignmentBook) {
          const pageIndex = toc.findPageIndexOf(assignmentPage.data().id);
          const tmpData = assignmentPage.data();
          if (tmpData && pageIndex) {
            tmpData.name = jsonData.name as string;
            tmpData.asn_options = jsonData.asn_options as AssignmentOptionsInput;
            tmpData.page_activeContent = content;

            await changeItemNameAndUpdateTOC({
              pageData: assignmentPage,
              pageIndex,
              toc,
              book: assignmentBook,
              name: tmpData.name
            });
          }
        }
        console.log("JSON Assignment Data", jsonData);
      }

      const onPress = (v: { type: string; schemaItem: FormSchemaItem }): void => {
        console.log("press", v.type)

        switch (v.type) {
          case "save":
            buttonList[0].isEnabled = false;
            triggers.validate = !triggers.validate;
            updatePageFromFormData(schema.value);
            //context.emit("saveQuestion", props.dataPage);
            break;
          case "hints":
            displaybehavior.displayHints = !displaybehavior.displayHints;
            break;
          case "dialog":
            confirmDialog?.value
              ?.open("test", "test", {})
              .then((isConfirmed: boolean) => {
                console.log(isConfirmed);
              });
            break;
        }
      };
      // ------ Components ------
      const activeIndex = ref(0);
      console.log("Component List", componentList)

      const onClick = (index: number) => {
        activeIndex.value = index;
      }

      const onInsertComponent = (type: number) => {
        const newComp = getEmptyComponent(type);
        if (TypeGuard.isNullOrUndefined(newComp)) return;
        componentList.push(newComp);
      }

      const onDeleteComponent = () => {
        if (activeIndex.value >= 0 && activeIndex.value < componentList.length) {
          componentList.splice(activeIndex.value, 1);
        }
        activeIndex.value = 0;
      }

      const activeComponent = computed(() => {
        return componentList[activeIndex.value]
      });

      const dropdownOptions = [
        { icon: "bi-question-diamond", value: PageComponentTypes.QUESTION_BLOCK, label: "Quiz" },
        { icon: "bi-wrench", value: PageComponentTypes.BABYLON, label: "Lab" },
        { icon: "bi-file-text", value: PageComponentTypes.MARKDOWN, label: "Document" }
      ];

      const getComponentEditor = (type: PageComponentTypes) => {
        switch (type) {
          case PageComponentTypes.BABYLON:
            return "babylon-component-editor";
          case PageComponentTypes.MARKDOWN:
            return "markdown-component-editor";
          case PageComponentTypes.QUESTION_BLOCK:
            return "quiz-component-editor";
        }

        return "";
      }

      const onChangeItemName = (index: number, name: string) => {
        componentList[index].name = name;
        console.log(componentList)
        return;
      }

      const onChange = () => {
        buttonList[0].isEnabled = true;
        console.log("Assignment changed.")
      };

      const onComponentChange = () => {
        console.log("Component changed.")
        console.log(componentList)
        return;
      };

      return {
        onComponentChange,
        onChange,
        onChangeItemName,
        dropdownOptions,
        activeIndex,
        onClick,
        onInsertComponent,
        onDeleteComponent,
        pageHasComponent,
        assignmentPage,
        componentList,
        activeComponent,
        getIconForComponentType,
        getComponentEditor,
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
        buttonList,
        onPress
      }
    }
  })
</script>

<style scoped>
  .thinColumn {
    width: 20px;
  }
  tr.active td {
    background-color: #ddd;
  }
</style>