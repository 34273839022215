<template>
  <b-select v-if="schemaitem.formGen?.componentName === 'SelectList'"
            :id="index + schemaitem.field"
            v-model="schemaValue[schemaitem.field]"
            :class="'form-control ' + validationClass"
            :options="schemaitem.choices" />
  <b-item-subitem v-else-if="schemaitem.formGen?.componentName === 'SelectItemSubItem'"
                  v-model="schemaValue[schemaitem.field]"
                  :class="'form-control text-center ' + validationClass"
                  :choices="schemaitem.meta?.topics" />
  <input v-else
         v-model="schemaValue[schemaitem.field]"
         :type="schemaitem.formGen?.inputType"
         :class="'form-control text-center ' + validationClass"
         :readonly="schemaitem.properties?.disabled"
         required
         @change="onChange">
</template>

<script lang="ts">
  import BItemSubitem from '@/components/bootstrap/b-item-subitem.vue'
  import bSelect from '@/components/bootstrap/b-select.vue'
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext } from 'vue'
  import { FormSchemaItem } from '../form-schema.interface'

  export default defineComponent({
    components: { bSelect, BItemSubitem },
    props: {
      modelValue: {
        type: Object as PropType<Record<string, unknown>>,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        required: true
      },
      validationClass: {
        type: String,
        default: ""
      }
    },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const schemaValue = ref(props.modelValue);


      const onChange = () => {
        context.emit("update:modelValue");
      }

      return {
        onChange,
        schemaValue
      }
    }
  })
</script>

<style scoped>
</style>