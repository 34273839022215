import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { Maybe } from "@/utils/maybe";
import { convert_util, convert_to_base_unit } from "./Conversion";

/**
 * Helper function to handle units. 
 * 
 */
export function _u(value: Maybe<number>, unit: string) {
    if (TypeGuard.isNullOrUndefined(value))
        return new ScalarValue(0, unit);

    return new ScalarValue(value, unit);
}



export class ScalarValue {
    public value: number;
    public unit: string;

    constructor(value: number, unit: string) {
        this.value = value;
        this.unit = unit;
        return this;
    }

    in(outUnit: string) {
        return convert_util(this.value, this.unit, outUnit);
    }

    inSameAs(v: ScalarValue) {
        return convert_util(this.value, this.unit, v.unit);
    }

    inPlaceConvertTo(unit: string) {
        this.value = convert_util(this.value, this.unit, unit);
        this.unit = unit;
        return this;
    }

    getValueInBaseUnit() {
        return convert_to_base_unit(this.value, this.unit);
    }

    getUnit() {
        return this.unit;
    }

    getValue() {
        return this.value;
    }

    set(value: number, unit: string) {
        this.value = value;
        this.unit = unit;
        return this;
    }

    setValue(value: number) {
        this.value = value;
        return this;
    }

    clone() {
        return new ScalarValue(this.value, this.unit);
    }


    copy(from: Maybe<ScalarValue>) {
        if (TypeGuard.isNullOrUndefined(from))
            return this;

        this.value = from.value;
        this.unit = from.unit;
        return this;
    }
}