import { registeredTemplates } from "./equation-template";
import { TypeGuard } from "../../mathjs/Type-guards";
import { VariableTable } from "../../mathjs/mathjs-variable-table";
import { LookupTableType, MjsNode } from "../../mathjs/math-types";
import { MjsMath } from "../../mathjs/math-core";
import { ModelHealthReport } from "../model/model-health-report";

export type EvaluatableEquation = (lookupTable: LookupTableType) => MjsNode;
export type SubstitutionType = Record<string, string | string[]>;
export type EquationStack = {
   templateID: string,
   unitSubstitutions: SubstitutionType,
   varSubstitutions: SubstitutionType
}[];

export interface EquationTemplateDefinition {
   id: string;
   name: string;
   katex: string;
   description: string;
   variables: { [x: string]: string };
   help: string;
   equation: (sub: SubstitutionType) => string;
}

function processUnit(unit: string, unitSubstitutions: SubstitutionType): string {
   if (unit.length > 1 && unit[0] === "*") {
      const subName = unit.substring(1);
      if (!Object.prototype.hasOwnProperty.call(unitSubstitutions, subName)) {
         console.error("Equation Template: Unable to find unit substitution for:", unit);
      }

      return unitSubstitutions[subName] as string;
   }

   return unit;
}

export const processEquationsInStack = (stack: EquationStack,
   variableTable: VariableTable,
   modelHealthReport: ModelHealthReport): string[] => {

   const eqnStringArray = stack.map((item) => {
      if (TypeGuard.isString(item)) {
         return item;
      }

      // TODO: work on optional templates
      const equationTemplate = registeredTemplates[item.templateID];
      const substitutions: SubstitutionType = item.varSubstitutions ? item.varSubstitutions : {};

      return equationTemplate.equation(substitutions);
   });
   console.debug("Equation String Array becomes: ", eqnStringArray);
   return eqnStringArray;
}

const getEquation = (template: EquationTemplateDefinition, substitutions: SubstitutionType): EvaluatableEquation => {
   const substitutedEquation = template.equation(substitutions);

   return (lookupTable: LookupTableType) => {
      return MjsMath.toExpression(substitutedEquation, lookupTable);
   }
}

export const evalEquationStack = (stack: EquationStack): EvaluatableEquation[] => {
   const equationArray: EvaluatableEquation[] = [];
   stack.forEach((item) => {
      const template = registeredTemplates[item.templateID];
      equationArray.push(getEquation(template, item.varSubstitutions));
   });
   return equationArray;
}

export const evalEquationArray = (array: EvaluatableEquation[], lookupTable: LookupTableType): MjsNode[] => {
   const arrayOfEquations = array.map((eqn) => {
      return eqn(lookupTable)
   });

   return arrayOfEquations;
}