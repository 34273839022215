import * as BABYLON from 'babylonjs';

export class CurvedRectangularMesh {
    static Create(name: string, scene: BABYLON.Scene, width: number, height: number, depth: number, radius: number) {
        let angle: number;
        const deltaAngle = Math.PI / 16;

        //Polygon shape in XoZ plane held in array
        const shape: BABYLON.Vector3[] = [];

        function getVec(x: number, y: number, z: number) {
            shape.push(new BABYLON.Vector3(x, y, z));
        }

        //bottom edge
        getVec(-width / 2 + radius, 0, -height / 2);
        getVec(width / 2 - radius, 0, -height / 2);
        //bottom right corner
        for (angle = -Math.PI / 2 + deltaAngle; angle < 0; angle += deltaAngle) {
            getVec(width / 2 - radius + radius * Math.cos(angle), 0, -height / 2 + radius + radius * Math.sin(angle));
        }

        //right edge;
        getVec(width / 2, 0, -height / 2 + radius);
        getVec(width / 2, 0, height / 2 - radius);
        //top right corner
        for (angle = deltaAngle; angle < Math.PI; angle += deltaAngle) {
            getVec(width / 2 - radius + radius * Math.cos(angle), 0, height / 2 - radius + radius * Math.sin(angle));
        }

        //top edge
        getVec(width / 2 - radius, 0, height / 2);
        getVec(-width / 2 + radius, 0, height / 2);
        //top left corner
        for (angle = Math.PI / 2 + deltaAngle; angle < Math.PI; angle += deltaAngle) {
            getVec(-width / 2 + radius + radius * Math.cos(angle), 0, height / 2 - radius + radius * Math.sin(angle));
        }

        //left edge;
        getVec(-width / 2, 0, height / 2 - radius);
        getVec(-width / 2, 0, -height / 2 + radius);
        //bottom left corner
        for (angle = Math.PI + deltaAngle; angle < 3 * Math.PI / 2; angle += deltaAngle) {
            getVec(-width / 2 + radius + radius * Math.cos(angle), 0, -height / 2 + radius + radius * Math.sin(angle));
        }

        const faceUV = [];
        faceUV.push(new BABYLON.Vector4(0, 0, 1, 1));
        faceUV.push(new BABYLON.Vector4(467 / 469, 0, 1, 1));
        faceUV.push(new BABYLON.Vector4(467 / 469, 0, 1, 1));
        faceUV.push(new BABYLON.Vector4(467 / 469, 0, 1, 1));

        const polygon = BABYLON.MeshBuilder.ExtrudePolygon(name,
            { shape: shape, depth: depth, faceUV: faceUV, sideOrientation: BABYLON.Mesh.FRONTSIDE },
            scene,
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('earcut'));

        polygon.addRotation(-Math.PI / 2.0, 0, 0);

        return polygon;
    }


}