import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { UserResponseSetupData } from "../questions/components/user-response.interface";
import { EquationStack } from "../questions/equations/equation-template-helpers";
import { ModelVariableOptions } from "../questions/model/model-variable";
import { ModelVectorVariableOptions } from "../questions/model/model-vector";
import { QuestionFigureType } from "../questions/QuestionViewer.interface";

export enum PageComponentTypes {
   MARKDOWN,
   VIDEO,
   IMG,
   CHART,
   BABYLON,
   DRAW,
   QUESTION_BLOCK,
   QUESTION,
   FIGURE
}

export enum PageLaboratoryTypes {
   WIND_TUNNEL
}

export interface PageComponentEventI {
   component: string;
   type: string;
   data: any;
}


export interface PC_Data_Interface {
   tabTitle?: string;
   meta?: any; // make this a flat object (no nesting)
}

export interface PageComponent {
   id: string,
   name: string,
   type: PageComponentTypes,
   data: {
      [x: string]: unknown;
   }
}


export interface PageComponentObjectI extends PageComponent {
   getSchema(key: string): FormSchemaItem | undefined;
   commitChanges(): void;
   discardChanges(): void;

   afterCreate(input: { pageID: string, courseID: string }): void;
   beforeDestroy(input: { pageID: string, courseID: string }): void;
   onRename(input: { pageID: string, courseID: string }): void;
}

export interface PageContentI {
   components: PageComponent[];
}

export interface EditableData {
   tabTitle?: string;
   meta?: any; // make this a flat object (no nesting)
}


export interface Content {
   content: {
      components: PageComponent[];
   }
   createdOn: string;
   creator: string;
   updatedOn: string;
}

export interface Page_QuizQuestionEntry {
   id: string,
   points: number,
   questionBankID: string
}

export interface Page_QuizComponent extends PageComponent {
   data: {
      displayType: string,
      setSeed: boolean,
      seedValue: number,
      randomizeOrder: boolean,
      showScoreBreakdown: boolean,
      showSolutions: boolean,
      array: Page_QuizQuestionEntry[]
   }
}


export interface Page_BabylonComponent extends PageComponent {
   isActive: boolean,
   data: {
      seedValue: number,
      selectedLab: PageLaboratoryTypes,
      content: string
   }
}

export interface Page_MarkdownComponent extends PageComponent {
   isActive: boolean,
   data: {
      content: string
   }
}

export interface Page_VideoComponent extends PageComponent {
   data: {
      src: string
   }
}


export interface Page_ImageComponent extends PageComponent {
   data: {
      src: string,
      caption: string
   }
}


export interface Page_QuestionComponent extends PageComponent {
   data: {
      figure: {
         meta: {
            url: string,
            include: boolean,
            type: QuestionFigureType
         }
      },
      view: {
         meta: {
            requestedResponses: UserResponseSetupData[],
            problemStatement: string
         }
      },
      model: {
         meta: {
            formulas: EquationStack;
            parameters: ModelVariableOptions[];
            vector_parameters: ModelVectorVariableOptions[];
         }
      },
      solution: {
         meta: {
            solutionStatement: string
         }
      }
   }
}