<template>
  <Page :page-data="pageData" />
</template>

<script lang="ts">
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import { defineComponent } from "vue";
  import Page from "./Page.vue";
  import { PageComponentTypes } from './pages.interface';

  export default defineComponent({
    components: {
      Page,
    },
    setup() {
      const pageData = {
        __typename: "Textbook",
        asn_options: {
          dueOn: "2020-09-01T12:00:00.000Z",
          formOwnGroups: false,
          isGroup: false,
          isTimed: false,
          maxGroupMembers: 1,
          maxPoints: 100,
          openOn: "2019-09-24T18:26:00.000Z",
          requiresGroupNotebook: false,
        },
        createdAt: "2020-08-13T02:04:41.313Z",
        description: "",
        fieldType: "ASSIGNMENT",
        "fieldType#id": "ASSIGNMENT#af1c201a-8086-4773-88b5-f832aaa0c425",
        id: "af1c201a-8086-4773-88b5-f832aaa0c425",
        lab_pictureURL: "",
        long_description: "",
        name: "Assignment 1 - Needs / Specs",
        page_activeContent: {
          content: {
            components: [

              {
                data: {
                  content: "# headline 1  \n## headline 2  \n#### headline 4  \n@[toc]( )  \n  $$1 + \\frac{3}{2} $$ ",
                },
                id: "3cf6a340-1ba7-4cc1-b0cc-6c32052457b1",
                isActive: true,
                name: "",
                type: 0 as PageComponentTypes,
              },
              {
                data: {
                  src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                },
                id: "3df3a340-1ba7-4cc1-b0cc-6c32052457b2",
                //isActive: true,
                //name: "",
                type: 1 as PageComponentTypes,
              },
              {
                data: {
                  src: "photos/shutterstock_118337104.jpg",
                  caption: "test"
                },
                id: "3df4a340-1ba7-4cc1-b0cc-6c32052457b2",
                //isActive: true,
                //name: "",
                type: 2 as PageComponentTypes,
              },
            ],
          },
          createdOn: "2020-08-13T02:04:42.328Z",
          creator: "",
          updatedOn: "2020-08-13T02:04:42.328Z",
        },
        page_draftContent: {
          content: {
            components: [],
          },
          createdOn: "2020-08-13T02:04:42.328Z",
          creator: "",
          updatedOn: "2020-08-13T02:04:42.328Z",
        },
        page_history: [],
        page_isActive: true,
        textID: "ad5ce1cb-9fb9-4ecd-8006-1929ad5fb161",
        updatedAt: "2020-08-18T20:14:59.657Z",
      } as PageFromTextbookTable;

      return {
        pageData,
      };
    },
  });
</script>

<style scoped>
</style>