import * as BABYLON from 'babylonjs';

import { VariableTable } from "../values/VariableTable";
import { SceneMetaData } from "../engine/SceneMetaData";
import { OBJFileLoader } from 'babylonjs-loaders';
import { Nullable } from 'babylonjs/types';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';

export enum RenderedObjectTypes {
    BEAKER,
    HOT_PLATE,
    VISCOMETER,
    VISCOMETER_CONTROLLER,
    LABORATORY,
    VISUAL_FLOW_METER,
    PIPE_PRESSURE_CONNECTOR,
    FLOW_METER_ELECTRONIC,
    PRESSURE_METER_ELECTRONIC,
    PIPE_BALL_VALVE,
    CORD_CONNECTOR,
    LAB_BENCH,
    WIND_TUNNEL,
    WIND_TUNNEL_CONTROLLER,
    WT_GEOMETRY,
    CLEAR_WATER_TANK,
    LOAD_CELL,
    JET_DEFLECTOR,
    FORCE_METER_ELECTRONIC,
    NONE
}

export enum OBSERVABLETYPE {
    onBeforeRenderObservable
}
export enum SnapBehaviorType {
    AXIS,
    PLANE,
    THREE_D
}

export interface AssetTaskTiedToScene {
    sceneID: string,
    task: BABYLON.MeshAssetTask
}

export interface SnapBehavior {
    gridPoints: BABYLON.Vector3[],
    gridOwner: BaseRenderedObject[],
    gridAcceptTypes: [RenderedObjectTypes[]],
    gridOccupied: boolean[],
    onSnapTo: ((obj: BaseRenderedObject) => void)[],
    onSnapOff: ((obj: BaseRenderedObject) => void)[]
}

export interface RenderedObject {
    // setup and identification
    getName(): string;
    getType(): RenderedObjectTypes;
    setOptions(options: any): RenderedObject;

    // rendereing 
    scene: BABYLON.Scene;
    addPreloadAssets(assetManager: BABYLON.AssetsManager): void;
    myContainerNode: Nullable<BABYLON.Mesh>;
    render(): void;
    dispose(): void;

    // heirarchical functions 
    getParent(): RenderedObject;
    addChild(obj: RenderedObject): RenderedObject;
    getContainerMesh(): Nullable<BABYLON.Mesh>;

    // system manipulation and cross talk between objects
    setProperty(name: string, value: any): void;
    getProperty(name: string): unknown;
    setTableFromObjectState(variableTable: VariableTable): void;
    setObjectStateFromTable(variableTable: VariableTable): void;

    // observables used for animations that run in the render loop
    addObservable(name: string, type: OBSERVABLETYPE, func: () => void): void;
    removeObservable(name: string): void;

    // snap points
    registerAbsoluteSnapPoints(): void;
    dragTo(absPosition: BABYLON.Vector3, orientation: BABYLON.Vector3, alignment: any): void;
    getDragPoint(): BABYLON.Vector3;

}

export class BaseRenderedObject implements RenderedObject {
    public name = "";
    public type: RenderedObjectTypes = RenderedObjectTypes.NONE;
    public scene: BABYLON.Scene;
    public parent: Nullable<RenderedObject>;
    public myContainerNode: Nullable<BABYLON.Mesh> = null;
    public children: RenderedObject[] = [];
    public isRendered = true;
    public observers: { [key: string]: { type: OBSERVABLETYPE, observer: BABYLON.Observer<any> } } = {};

    constructor(name: string, scene: BABYLON.Scene, parent: Nullable<RenderedObject>) {
        this.name = name;
        this.scene = scene;
        this.parent = parent;
    }

    public getParent(): RenderedObject {
        return this;
    }

    public addChild(obj: RenderedObject): RenderedObject {
        this.children.push(obj);
        return this;
    }

    public getContainerMesh() {
        return this.myContainerNode;
    }

    public getName() {
        return this.name;
    }

    public getType() {
        return this.type;
    }

    public getChildByName(name: string): Nullable<RenderedObject> {
        const foundObj = this.children.find(item => item.getName() == name);
        return foundObj ?? null;
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.children.forEach(function (v, i, a) {
            v.addPreloadAssets(assetManager);
        });
    }

    protected preloadAssetWorker(assetManager: BABYLON.AssetsManager,
        assetTask: Nullable<AssetTaskTiedToScene>,
        taskName: string,
        directory: string,
        fileName: string,
        onSuccess: (task: { loadedMeshes: unknown[] }) => void) {
        const sceneID = (this.scene.metadata as SceneMetaData).id;

        if (assetTask === null) {
            assetTask = {
                sceneID: sceneID,
                task: getNewTask()
            }
        }

        if (sceneID !== assetTask.sceneID) {
            assetTask.task = getNewTask();
        }

        function getNewTask() {
            OBJFileLoader.COMPUTE_NORMALS = true;
            const task = assetManager.addMeshTask(taskName, "", directory, fileName);
            task.onSuccess = onSuccess;
            task.onError = function (task) {
                console.log("Error loading asset task named: " + task.name, task.errorObject);
            }
            return task;
        }
    }

    public setOptions(options: any) {
        return this;
    }

    public setTableFromObjectState(variableTable: VariableTable) {
        this.children.forEach(function (v) {
            v.setTableFromObjectState(variableTable);
        });
    }

    public setObjectStateFromTable(variableTable: VariableTable) {
        this.children.forEach(function (v) {
            v.setObjectStateFromTable(variableTable);
        });
    }

    public getControlPanel() {
        return (this.scene.metadata as SceneMetaData).controlPanel;
    }

    public getProperty(name: string): any { return null; }
    public setProperty(name: string, value: any) { return; }

    public render(): BaseRenderedObject {
        this.isRendered = true;
        return this;
    }

    public dispose() {
        Object.keys(this.observers).forEach((v) => {
            this.removeObservable(v);
        });
        this.myContainerNode?.dispose();
    }

    public getDragPoint(): BABYLON.Vector3 {
        return BABYLON.Vector3.Zero();
    }

    public dragTo(position: BABYLON.Vector3, orientation: Nullable<BABYLON.Vector3>, alignment: any) {
        return;
    }

    public addObservable(name: string, type: OBSERVABLETYPE, func: () => void) {
        switch (type) {
            case OBSERVABLETYPE.onBeforeRenderObservable:
                this.observers[name] = { type: type, observer: this.scene.onBeforeRenderObservable.add(func)! };
                break;
        }
    }

    public removeObservable(name: string) {
        const obs = this.observers[name];
        switch (obs.type) {
            case OBSERVABLETYPE.onBeforeRenderObservable:
                this.scene.onBeforeRenderObservable.remove(obs.observer);
                delete this.observers[name];
                break;
        }
    }

    public registerSnapPoint(gridPoint: BABYLON.Vector3,
        gridAcceptTypes: RenderedObjectTypes[],
        onSnapTo: (obj: BaseRenderedObject) => void,
        onSnapOff: (off: BaseRenderedObject) => void) {

        const meta = this.scene.metadata;
        if (!TypeGuard.hasProp(meta, 'snapBehavior')) {
            const snapBehavior = {
                gridPoints: [],
                gridOwner: [],
                gridAcceptTypes: [],
                gridOccupied: [],
                onSnapTo: [],
                onSnapOff: []
            };
            meta.snapBehavior = snapBehavior;
        }

        meta.snapBehavior.gridPoints.push(gridPoint);
        meta.snapBehavior.gridOwner.push(self);
        meta.snapBehavior.gridOccupied.push(false);
        meta.snapBehavior.gridAcceptTypes.push(gridAcceptTypes);
        meta.snapBehavior.onSnapTo.push(onSnapTo);
        meta.snapBehavior.onSnapOff.push(onSnapOff);
    }

    public registerAbsoluteSnapPoints() {
        this.children.forEach(function (v, i, a) {
            v.registerAbsoluteSnapPoints();
        });
    }


    public curSnapIndex = -1;
    public addSnapBehaviorToMesh(mesh: Nullable<BABYLON.Mesh>, type: SnapBehaviorType, axis: BABYLON.Vector3) {
        const scene = this.scene;


        let pointerDragBehavior;
        switch (type) {
            case SnapBehaviorType.AXIS:
                pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: axis });
                break;
            case SnapBehaviorType.PLANE:
                pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragPlaneNormal: axis });
                break;
            case SnapBehaviorType.THREE_D:
                pointerDragBehavior = new BABYLON.PointerDragBehavior();
                break;
        }

        // Use drag plane in world space
        pointerDragBehavior.useObjectOrientationForDragging = false;
        pointerDragBehavior.moveAttached = false;

        const dragPoint = BABYLON.Vector3.Zero();


        // start dragging, disconnect from current snap using the onsnapoff function
        pointerDragBehavior.onDragStartObservable.add((event) => {
            const snapBehavior = scene.metadata.snapBehavior as SnapBehavior;
            dragPoint.copyFrom(this.getDragPoint());
            if (this.curSnapIndex !== -1) {
                snapBehavior.gridOccupied[this.curSnapIndex] = false;
                snapBehavior.onSnapOff[this.curSnapIndex](this);
            }
        });


        // during drag update position and snap to new grid if get close
        pointerDragBehavior.onDragObservable.add((event) => {
            const snapBehavior = scene.metadata.snapBehavior as SnapBehavior;

            dragPoint.addInPlace(event.delta);
            console.log(dragPoint, snapBehavior.gridPoints[1]);
            this.curSnapIndex = snapBehavior.gridPoints.findIndex((x, index) => {
                return (dragPoint.subtract(x).lengthSquared() < 0.1 && !snapBehavior.gridOccupied[index])
            });

            if (foundValidSnap(this.curSnapIndex, snapBehavior)) {
                this.dragTo(snapBehavior.gridPoints[this.curSnapIndex], null, null);
            } else {
                this.dragTo(dragPoint, null, null);
                this.curSnapIndex = -1;
            }
        });

        // on drop fire the onsnapto function
        pointerDragBehavior.onDragEndObservable.add((event) => {
            const snapBehavior = scene.metadata.snapBehavior as SnapBehavior;

            if (foundValidSnap(this.curSnapIndex, snapBehavior)) {
                snapBehavior.gridOccupied[this.curSnapIndex] = true;
                snapBehavior.onSnapTo[this.curSnapIndex](this);
            }
        });

        mesh?.addBehavior(pointerDragBehavior);

        const foundValidSnap = (snapIndex: number, snapBehavior: SnapBehavior) => {
            console.log(snapIndex, snapBehavior.gridOccupied[0], snapBehavior.gridOccupied[1])
            return snapIndex !== -1
                && !snapBehavior.gridOccupied[snapIndex]
                && snapBehavior.gridAcceptTypes[snapIndex].findIndex((v) => { return v === this.getType() as any }) !== -1
                ;
        }
    }
}