import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import { Nullable } from 'babylonjs/types';
import { Fluid, Fluids } from '../../properties/Standard_Fluids';
import { BUtil } from '../../utils/BabylonUtils';
import { ScalarValue, _u } from '../../values/ScalarValue';
import { VariableTable } from '../../values/VariableTable';
import { BaseRenderedObject, RenderedObjectTypes } from "../BaseRenderedObject";
import { MaterialRegistry } from "../Materials/MaterialRegistry";


interface FluidJetOptions {
    location: BABYLON.Vector3,
    diameter: number,
    maxLength: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    fluid: Fluids,
    temperature: ScalarValue,
    target: Nullable<BaseRenderedObject>,
    velocity: ScalarValue
}

export class FluidJet extends BaseRenderedObject {
    public fluidMesh: BABYLON.Mesh | undefined;
    public fluid: Nullable<Fluid> = null;
    public currentLength = 0;


    private options: FluidJetOptions = {
        diameter: 0.02,
        maxLength: 0.5,
        location: new BABYLON.Vector3(0, 0, 0),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        fluid: Fluids.WATER,
        temperature: _u(20, 'C'),
        target: null,
        velocity: _u(0, 'm/s')
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.CLEAR_WATER_TANK;
    }

    public setOptions(options: FluidJetOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    private setFluidColor() {
        if (this.fluidMesh)
            this.fluidMesh.material = MaterialRegistry.getFluid(this.scene, this.options.fluid);
    }

    public render() {

        const transformNode = new BABYLON.Mesh("JetTranformNode");
        this.fluidMesh = BUtil.Cylinder("Jet", 1, this.options.diameter ?? 0, this.scene);
        this.setFluidColor();

        // position and orient the valve
        this.myContainerNode = transformNode;

        const waterMaterial = new Materials.WaterMaterial("waterMaterial", this.scene, new BABYLON.Vector2(100, 100));
        waterMaterial.bumpTexture = new BABYLON.Texture("//www.babylonjs.com/assets/waterbump.png", this.scene);
        waterMaterial.backFaceCulling = true;

        waterMaterial.windForce = 50.4;
        waterMaterial.waveHeight = 0;
        waterMaterial.bumpHeight = 0.5;
        waterMaterial.waveLength = 0.25;
        waterMaterial.waterColor = new BABYLON.Color3(0.1, 0.1, 0.7);
        waterMaterial.windDirection = new BABYLON.Vector2(0, -1);
        waterMaterial.colorBlendFactor = 0.1;
        const skybox = this.scene.getMeshByName("reflectionSkyBox");
        waterMaterial.addToRenderList(skybox);
        waterMaterial.alpha = 0.9;

        this.fluidMesh.material = waterMaterial;

        this.fluidMesh.setPivotMatrix(BABYLON.Matrix.Translation(0,
            0.5, 0), false);


        this.fluidMesh.locallyTranslate(BUtil.V3(0, -1.0, 0));

        this.fluidMesh.setParent(transformNode);
        transformNode.position = this.options.location;
        transformNode.setParent(this.parent?.getContainerMesh() ?? null);

        super.render();

        return this;
    }

    public setTemperature(temp: ScalarValue) {
        this.options.temperature = temp;
    }

    public setFluidLength(newLength: number) {
        if (this.fluidMesh)
            this.fluidMesh.scaling.y = newLength;
        this.currentLength = newLength;
    }

    public getFluidLength() {
        return this.currentLength;
    }

    public setFluid(val: Fluids) {
        this.options.fluid = val;
        this.options.temperature = _u(20, 'C');
        this.setFluidColor();
    }

    public getFluidType() { return this.options.fluid; }
    public getFluidProps() {
        if (this.fluid === null) {
            this.fluid = new Fluid(this.getFluidType(), this.options.temperature);
        }

        this.fluid.setType(this.getFluidType());
        this.fluid.setTemp(this.options.temperature);
        return this.fluid;
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "FLUID":
                this.setFluid(value);
                break;
            case "TEMPERATURE":
                this.setTemperature(value);
                break;
            case "LENGTH":
                this.setFluidLength(value);
                break;
            case "VELOCITY":
                this.options.velocity = value;
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "FLUID":
                return this.getFluidType();
            case "TEMPERATURE":
                return this.options.temperature;
            case "LENGTH":
                return this.getFluidLength();
        }

        super.getProperty(name);
    }

    setObjectStateFromTable(variableTable: VariableTable) {
        if (TypeGuard.isNullOrUndefined(this.fluidMesh))
            return;

        const velocity = this.options.velocity.in("m/s");
        (this.fluidMesh.material as Materials.WaterMaterial).windForce = 100 * velocity;
        this.fluidMesh.isVisible = true;

        if (velocity > 0) {
            if (this.currentLength < this.options.maxLength) {
                const deltaT = variableTable.get("DELTA_T")?.in('s') ?? 0;
                this.setFluidLength(Math.min(this.options.maxLength, this.currentLength + velocity * deltaT));
            } else {
                if (this.options.target) {
                    this.options.target.setProperty("VELOCITY", this.options.velocity);
                }
            }
        } else {
            this.setFluidLength(0);
            if (this.options.target) {
                this.options.target.setProperty("VELOCITY", _u(0, 'm/s'));
            }
        }


        super.setObjectStateFromTable(variableTable);
    }

}

