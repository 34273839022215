
  import { computed, defineComponent, EmitsOptions, Ref, ref, SetupContext } from "vue";

  import { formItemMixinProps } from "../mixins/form.mixins";
  import { useValidation } from "../use/useValidation";
  import { FormSchemaItem } from "../form-schema.interface";

  import BDragList, { BDragListInterface } from "../../bootstrap/b-drag-list.vue";
  import BConfirmDialog from "../../bootstrap/b-confirm-dialog.vue";

  import FormInputGroup from "./form-input-layout.vue";
  import { ConfirmDialogOptions } from "@/components/bootstrap/b-confirm-dialog.interface";
  import ObjectEditorDetail from "./object-editor-detail.vue";

  export default defineComponent({
    name: "FormInputObjecteditor",
    components: {
      FormInputGroup,
      BDragList,
      BConfirmDialog,
      ObjectEditorDetail,
    },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const confirmDialog = ref<ConfirmDialogOptions | null>(null);

      const arrayValue = (ref(props.modelValue) as unknown) as Ref<
        Record<string, unknown>[]
      >;

      const selectedItem = ref(0);

      const hasSelectableTypeInfo = () => {
        return Object.prototype.hasOwnProperty.call(
          props.schemaitem,
          "typeSelectorInfo"
        ) && props.schemaitem?.typeSelectorInfo?.choices.length as number > 0;
      };

      const getSelectableObjectTypes = () => {
        if (hasSelectableTypeInfo()) {
          return props.schemaitem.typeSelectorInfo?.choices.map(
            (x: { typeName: string }) => {
              return x.typeName;
            }
          );
        } else {
          return [];
        }
      };

      const typeSelectorIncludes = (typeSelectorName: string, field: string) => {
        let typeSel = props.schemaitem.typeSelectorInfo?.choices.find(
          (x: { typeName: string }) => {
            return x.typeName === typeSelectorName;
          }
        );
        if (typeSel) {
          return typeSel.visibleFields.includes(field);
        } else {
          return true;
        }
      };

      const getSelectedTypeIndicator = (): string => {
        const arrayObj = arrayValue.value[selectedItem.value];

        if (arrayValue.value.length > 0) {
          if (
            !Object.prototype.hasOwnProperty.call(
              arrayObj,
              "selectedTypeIndicator"
            ) && hasSelectableTypeInfo()
          ) {
            let defaultType = (props.schemaitem
              .typeSelectorInfo as any).choices[0].typeName;
            arrayObj["selectedTypeIndicator"] = defaultType;
          }
          return arrayObj["selectedTypeIndicator"] as string;
        } else {
          return "";
        }
      };

      const selectedType = computed({
        get: () => {
          return getSelectedTypeIndicator();
        },
        set: (v: string) => {
          arrayValue.value[selectedItem.value]["selectedTypeIndicator"] = v;
        },
      });

      const getVisibleSchemaItems = (): FormSchemaItem[] => {
        if (hasSelectableTypeInfo() === null) {
          return props.schemaitem?.children ? props.schemaitem?.children : [];
        } else {
          let returnChildren: FormSchemaItem[] = [];
          props.schemaitem.children?.forEach((v: FormSchemaItem) => {
            if (typeSelectorIncludes(selectedType.value as string, v.field)) {
              returnChildren.push(v);
            }
          });
          return returnChildren;
        }
      };

      const dragListRef = ref<BDragListInterface | null>(null);

      const addNewItem = () => {
        let newRow: Record<string, unknown> = {};
        if (hasSelectableTypeInfo()) {
          newRow["selectedTypeIndicator"] = (props.schemaitem
            .typeSelectorInfo as any).choices[0].typeName;
        }

        props.schemaitem.children?.forEach((v) => {
          if (Array.isArray(v.value)) {
            newRow[v.field] = v.value.slice();
          } else {
            if (isFunction(v.value)) {
              newRow[v.field] = (v.value as () => unknown)();
            } else {
              newRow[v.field] = v.value;
            }
          }
        });

        arrayValue.value.push(newRow);

        let curItemNr = arrayValue.value.length - 1;
        registerSelection(curItemNr);
        dragListRef.value?.selectItem(curItemNr);

        function isFunction(obj: unknown) {
          return !!(
            (obj as () => void) &&
            (obj as () => void).constructor &&
            (obj as () => void).call &&
            (obj as () => void).apply
          );
        }
      };

      const deleteCurrentItem = () => {
        let deleteItemNr = selectedItem.value;

        if (deleteItemNr >= 0 && deleteItemNr < arrayValue.value.length) {
          arrayValue.value.splice(deleteItemNr, 1);
        }

        if (deleteItemNr > arrayValue.value.length - 1) {
          selectedItem.value = arrayValue.value.length - 1;
        }
      };

      const registerSelection = (index: number) => {
        selectedItem.value = index;
        selectedType.value = getSelectedTypeIndicator();
      };

      const handleArrayChange = () => {
        context.emit("update:modelValue", arrayValue);
      };

      const deleteObject = () => {
        confirmDialog.value
          ?.open(
            "Confirm Delete",
            "Area you sure you want to delete this item?",
            {
              cancel_class: "btn btn-secondary",
              ok_class: "btn btn-danger",
              ok_text: "Delete",
            }
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              deleteCurrentItem();
            }
          });
      };

      return {
        deleteObject,
        handleArrayChange,
        dragListRef,
        confirmDialog,
        selectedType,
        arrayValue,
        selectedItem,
        registerSelection,
        getSelectableObjectTypes,
        getSelectedTypeIndicator,
        getVisibleSchemaItems,
        deleteCurrentItem,
        addNewItem,
        ...useValidation(props, context),
      };
    },
  });
