import { ModelVariable, ModelVariableOptions } from './model-variable';
import { ModelVectorVariable, ModelVectorVariableOptions } from './model-vector';

import { TypeGuard } from '../../mathjs/Type-guards';
import { VariableTable } from '../../mathjs/mathjs-variable-table';
import { EquationStack } from '../equations/equation-template-helpers';
import { RequestResponse } from '@/components/global/response.interface';
import { MathJSSolve } from '../../mathjs/math-solve';
import { mjsUnknown, MjsNode } from '../../mathjs/math-types';
import { MjsMath } from '../../mathjs/math-core';
import { ModelHealthReport } from './model-health-report';
import { mjsFormatting } from '../../mathjs/extension/formatting';

export class QuestionModel {
   private modelVariableTable: ModelVariable[] = [];
   private modelVectorVariableTable: ModelVectorVariable[] = [];
   private formulas: EquationStack = [];
   private variableTable: VariableTable = new VariableTable();
   private modelHealthReport: ModelHealthReport;

   getListOfAllComputedQuantities() {
      return Object.keys(this.variableTable.values);
   }

   getListOfRandomVariables() {
      return this.modelVariableTable.map((v) => v.name);
   }

   getVariableValue(varName: string, simplifyUnit: boolean) {
      if (this.variableTable.hasValueKey(varName))
         return mjsFormatting.toText(this.variableTable.values[varName], 3, false, simplifyUnit);
      else
         return "Does not have variable";
   }

   constructor(formulas: EquationStack,
      inModelVariableTable: ModelVariableOptions[],
      inVectorVariableTable: ModelVectorVariableOptions[],
      modelHealthReport: ModelHealthReport) {
      this.modelHealthReport = modelHealthReport;
      const startTime = Date.now();

      if (TypeGuard.isNullOrUndefined(inModelVariableTable)) { inModelVariableTable = []; }
      if (TypeGuard.isNullOrUndefined(inVectorVariableTable)) { inVectorVariableTable = []; }

      this.formulas = formulas;

      try {
         inModelVariableTable.forEach((v) => {
            this.modelVariableTable.push(new ModelVariable(v.name, v));
         });

         inVectorVariableTable.forEach((v) => {
            this.modelVectorVariableTable.push(new ModelVectorVariable(v.name, v));
         })

      } catch (err: any) {
         throw new Error("There is an error in one of the variables in your model.\n" + err.message)
      }

      try {
         this.solveSystem();
      } catch (err: any) {
         throw new Error("There is an error solving the system.\n" + err.message)
      }

      modelHealthReport.recordModelCreationTime(startTime);
   }

   solveForMinMax(): Record<string, { min: mjsUnknown, max: mjsUnknown }> {
      const nrVariables = this.modelVariableTable.length;
      const testOptions = Array(nrVariables).fill(true);

      console.log("Original Settings", testOptions)
      const maxMinTable: Record<string, { min: mjsUnknown, max: mjsUnknown }> = {};

      const storeMinMaxForVariableSettings = (varTable: VariableTable) => {
         varTable.listOfVariableNames().forEach((name) => {
            const tmpVal = varTable.values[name] as mjsUnknown;

            if (TypeGuard.isNumber(tmpVal) || TypeGuard.isUnit(tmpVal)) {
               if (TypeGuard.hasProp(maxMinTable, name)) {
                  const tmpObj = maxMinTable[name] as { min: mjsUnknown, max: mjsUnknown };
                  if (MjsMath.compare(tmpVal, tmpObj.min) === -1) { tmpObj.min = tmpVal; }
                  if (MjsMath.compare(tmpVal, tmpObj.max) === 1) { tmpObj.max = tmpVal; }
               } else {
                  maxMinTable[name] = {
                     min: tmpVal,
                     max: tmpVal
                  };
               }
            }

         });
      }

      const evalMaxMinForVariableSettings = (settings: boolean[], curIndex: number) => {
         if (curIndex === nrVariables) {
            this.variableTable = MathJSSolve.solveStack({},
               this.formulas,
               this.modelVariableTable,
               this.modelHealthReport,
               settings);

            storeMinMaxForVariableSettings(this.variableTable);
         } else {
            const copyOfSettings = [...settings];
            copyOfSettings[curIndex] = false;

            curIndex++;
            evalMaxMinForVariableSettings([...settings], curIndex);
            evalMaxMinForVariableSettings(copyOfSettings, curIndex);
            console.debug("Copy Of Settings", copyOfSettings);
         }
      }

      evalMaxMinForVariableSettings(testOptions, 0);



      return maxMinTable;
   }

   solveSystem() {
      this.variableTable = MathJSSolve.solveStack({},
         this.formulas,
         this.modelVariableTable,
         this.modelHealthReport);
   }

   evaluateStatement(statement: string): RequestResponse<string> {
      return mjsFormatting.evalStatement(statement, this.variableTable.values);
   }

   evalToNumeric(statement: string) {
      return MjsMath.toNumeric(statement, this.variableTable.values);
   }

   evalToTerm(statement: string): MjsNode {
      return MjsMath.toExpression(statement, this.variableTable.values);
   }

   evalToText(term: mjsUnknown, nrSigFigs: number, simplifyUnit = false, katexEncase = false): string {
      if (TypeGuard.isString(term)) {
         term = this.evalToTerm(term);
      }
      return mjsFormatting.evalToText(term, nrSigFigs, simplifyUnit, katexEncase);
   }
}

