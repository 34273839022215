
  import { EmitsOptions, SetupContext } from "@vue/runtime-core";
  import BImg from "./b-img.vue";


  export default {
    components: { BImg },
    props: {
      width: {
        type: String,
        default: "15rem",
      },
      imgSrc: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      description: {
        type: String,
        default: undefined,
      },
      footer: {
        type: String,
        default: undefined,
      },
    },
    emits: ["click"],
    setup(_: unknown, context: SetupContext<EmitsOptions>) {
      const onClick = () => {
        context.emit("click");
      };
      return {
        onClick,
      };
    },
  };
