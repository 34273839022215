import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";

export function getBabylonComponentSchema() {
   return FormSchemaMaker.Group({
      label: "Laboratory Component",
      field: "data",
      properties: {
         classes: "m-1 p-1 border formColorGroup",
         customButtons: [
            { title: 'Thumbnail', emit: 'view-preview' },
            { title: 'Sample', emit: 'view-sample' }
         ]
      }
   },
      [
         FormSchemaMaker.Select({
            label: "Laboratory Type",
            field: "displayType",
            choices: ["All Labs In Same Room",
               "Viscometer",
               "Pipe Rack Major",
               "Pipe Rack Minor",
               "Windtunnel",
               "Linear Momentum"
            ],
            properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
            hint: ""
         }),
         FormSchemaMaker.Text({
            label: "Seed Value",
            field: "seedValue",
            properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
            hint: "Value of the seed used if it is the same for all students. "
         })
      ]);
}