
  import * as paper from 'paper';
  import BButton from '@/components/bootstrap/b-button.vue'
  //import { Gzip, Gunzip } from 'zlibt2'



  import { defineComponent, ref, Ref } from 'vue'
  import NotebookPage from '../Notebook/NotebookPage.vue'
  import { EqnCharacter } from './useEqnCharacterAnalysis'
  import { TypeGuard } from '../../mathjs/Type-guards';
  import { CharRecord } from '@/store/database/TextRecognition/record-char';
  import { TextRecognitionFieldType } from '@/store/database/TextRecognition/table-textrecognition.interface';
  import { useUserStore } from '@/store/vueStore/useUserStore';
  import { useTextTrainer } from './useTextTrainer';
  import { usePossibleStrokes } from './storkeLabels';

  export default defineComponent({
    components: { NotebookPage, BButton },
    setup() {
      const { userName } = useUserStore();
      const tmpColors: paper.Color[] = [];
      for (let i = 0; i < 5 * 360; i += 70) {
        tmpColors.push(getColor(i));
      }

      function getColor(angle: number) {
        return new paper.Color({ hue: angle, saturation: 1, brightness: 1 })
      }

      const { generateTrainer } = useTextTrainer();
      const trainer = ref(generateTrainer());
      const characters = ref([...Array(trainer.value.maxNrItems)]);
      const currentCharacterLayer: Ref<paper.Layer | null> = ref(null);


      const incrementTrainer = (val: number) => {
        trainer.value.index += val;
        trainer.value.index = Math.min(Math.max(trainer.value.index, 0), trainer.value.maxNrItems - 1);
      }

      const onNewStroke = (uncommittedLayer: Ref<paper.Layer>) => {
        currentCharacterLayer.value = uncommittedLayer.value;
      }

      const onPrev = () => {
        if (!TypeGuard.isNullOrUndefined(currentCharacterLayer.value) && currentCharacterLayer.value.children.length > 0) {
          (currentCharacterLayer.value as any).clear();
          return;
        }

        incrementTrainer(-1);
        if (!TypeGuard.isNullOrUndefined(characters.value[trainer.value.index])) {
          characters.value[trainer.value.index].clear();
          characters.value[trainer.value.index] = null;
        }
      }

      const { possibleStrokes } = usePossibleStrokes();

      const onNext = async () => {
        // reject based on possible stroke numbers

        console.log("Analyze Index", trainer.value.index);

        if (!isValid(currentCharacterLayer.value)) {
          return;
        }

        function isValid(layer: paper.Layer | undefined | null): layer is paper.Layer {
          if (TypeGuard.isNullOrUndefined(layer) || layer.children.length === 0) {
            return false;
          }

          const nrStrokes = layer.children.length;
          const curCar = trainer.value.flatArray[trainer.value.index].dc;

          if (!TypeGuard.hasProp(possibleStrokes, curCar)) {
            console.log("Unable to find character ", curCar, " in psossibleStrokes.")
            return false;
          }

          const curPossStroke = possibleStrokes[curCar];
          if (nrStrokes < curPossStroke.minNrStrokes) {
            console.log("You do not have enough strokes.");
            return false;
          }

          if (nrStrokes > curPossStroke.maxNrStrokes) {
            console.log("You have too many strokes:", nrStrokes);
            return false;
          }

          return true;
        }


        characters.value[trainer.value.index] = (currentCharacterLayer.value as any).clone();
        characters.value[trainer.value.index].children.forEach((v: paper.Item) => {
          v.strokeColor = tmpColors[trainer.value.index];
          v.strokeWidth = 3;
        });

        (currentCharacterLayer.value as any).clear();

        if (trainer.value.index >= trainer.value.maxNrItems - 1) {
          trainer.value.flatArray.forEach((v, index) => {
            (trainer.value.flatArray[index] as any).meta = { path: characters.value[index].exportJSON() };
          });

          console.log(JSON.stringify({ eqn: trainer.value.flatArray }))

          await saveToDB(trainer.value.rootEquation);
          clearCharacters();
          generateNewFormula();
          trainer.value.index = 0;

        } else
          incrementTrainer(1);
        return;
      }

      const isCharacterDone = (index: number) => {
        return !TypeGuard.isNullOrUndefined(characters.value[index]) && characters.value[index].children && characters.value[index].children.length > 0;
      }

      function generateNewFormula() {
        trainer.value = generateTrainer();
        characters.value = [...Array(trainer.value.maxNrItems)];
        currentCharacterLayer.value = null;
      }

      function clearCharacters() {
        characters.value.forEach((v) => {
          if (!TypeGuard.isNullOrUndefined(v))
            v.clear();
        });
      }

      async function saveToDB(equation: EqnCharacter) {
        const cr = new CharRecord();

        await cr.createNewInDB({
          fieldType: "EQN_2",
          character: trainer.value.equation(-1),
          userEmail: userName,
          dateIndex: new Date().toISOString().split('T')[0],
          meta: JSON.stringify(equation)
        }, {
          type: TextRecognitionFieldType.EQN_2
        });

        return;
      }

      return {
        isCharacterDone,
        onNext,
        onPrev,
        trainer,
        incrementTrainer,
        onNewStroke,
        characters
      }
    }
  })
