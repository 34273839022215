<template>
  <div class="container-fluid p-0 m-0 condensedForm">
    <div class="row g-0">
      <div class="col">
        <div v-for="(err) in generalErrors"
             :key="err">
          {{ err }}
        </div>
        <FormRoot :key="formKey"
                  :is-loading="isLoading"
                  :schemaitem="schema"
                  :button-list="buttonList"
                  :triggers="triggers"
                  :displaybehavior="displaybehavior"
                  :validation-hooks="validationHooks"
                  @change="onChange"
                  @btnPress="onPress" />
      </div>
      <div class="col">
        <QuestionAnalysis :key="questionKey"
                          :question-data="previewQuestionData"
                          class="p-4 border" />
        <QuestionViewer :key="questionKey"
                        :question-data="previewQuestionData"
                        course-id="abc"
                        :question-bank-id="dataPage.data().textID"
                        component-id="abc"
                        :mock-submissions="true"
                        :show-solution="true" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { EquationStack } from '@/components/contentGenerator/questions/equations/equation-template-helpers';
  import { ModelHealthReport, ModelHealthErrorTypes } from '@/components/contentGenerator/questions/model/model-health-report';
  import { QuestionModel } from '@/components/contentGenerator/questions/model/model-question';
  import { ModelVariableOptions } from '@/components/contentGenerator/questions/model/model-variable';
  import { ModelVectorVariableOptions } from '@/components/contentGenerator/questions/model/model-vector';
  import QuestionViewer from '@/components/contentGenerator/questions/QuestionViewer.vue';
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import { FormSchemaItem, ValidationHooks, ValidationMetaData } from '@/components/formGenerator/form-schema.interface';
  import { FormButtonAction } from '@/components/formGenerator/form.interface';
  import useSchema from '@/components/use/useSchema';
  import { defineComponent, PropType, Ref, ref } from 'vue'
  import { questionCreatorSchema } from '../../components/contentGenerator/questions/schemas/QuestionCreator.schema';
  import useReactiveForm from '../../components/use/useReactiveForm';
  import { Either, Maybe } from "@/utils/maybe"
  import { UserResponseSetupData } from '@/components/contentGenerator/questions/components/user-response.interface';
  import { PageFromTextbookTable } from '@/store/database/Textbook/table-textbook.interface';
  import usePageComponents from '@/components/use/usePageComponents';
  import { QuestionDataInterface, QuestionFigureType } from '@/components/contentGenerator/questions/QuestionViewer.interface';
  import { Page_QuestionComponent } from '@/components/contentGenerator/pages/pages.interface';
  import { PageRecord } from '@/store/database/Textbook/record-page';
  import QuestionAnalysis from './QuestionAnalysis.vue';


  type QuestionJSONType = {
    data: {
      long_description: string
    },
    model: {
      formulas: EquationStack,
      parameters: ModelVariableOptions[],
      vector_parameters: ModelVectorVariableOptions[]
    },
    prob_data: {
      problemStatement: string,
      url: string,
      requestedResponses: UserResponseSetupData[]
    },
    soln_data: {
      solutionStatement: string
    }
  };

  export default defineComponent({
    components: {
      FormRoot,
      QuestionViewer,
      QuestionAnalysis
    },
    props: {
      dataPage: {
        type: Object as PropType<PageRecord>,
        required: true
      }
    },
    emits: ["saveQuestion"],
    setup(props, context) {

      const questionData = props.dataPage.data();
      console.log("Question Creator Form Data:", questionData);


      const { schema_mergeWithData, schema_getJSON } = useSchema();
      const { getActiveComponentsFromPageData } = usePageComponents();
      const componentObj = getActiveComponentsFromPageData(questionData).components[0];
      questionData.page_activeContent.content = { components: [componentObj] };

      const previousNrQuestionParts = (componentObj as Page_QuestionComponent).data.view.meta.requestedResponses.length;

      const buttonList: FormButtonAction[] = [
        {
          class: 'btn-primary save m-1',
          label: 'Analyze',
          triggerEvent: { type: 'analyze', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: true
        },
        {
          class: 'btn-primary save',
          label: 'Preview',
          triggerEvent: { type: 'preview', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: true
        },
        {
          class: 'btn-primary m-1 save',
          label: 'Hints',
          triggerEvent: { type: 'hints', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: false
        },
        {
          class: 'btn-success',
          label: 'Save',
          triggerEvent: { type: 'save', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: true
        }
      ];

      const questionModel: Ref<QuestionModel | undefined> = ref(undefined);
      const jsonData: Ref<QuestionJSONType> = ref({
        data: {
          long_description: ""
        },
        model: {
          formulas: [],
          parameters: [],
          vector_parameters: []
        },
        prob_data: {
          problemStatement: "",
          url: "",
          requestedResponses: []
        },
        soln_data: {
          solutionStatement: ""
        }
      });

      let modelHealthReport = new ModelHealthReport();

      function packageInfo(isValid: boolean, errorMessage: string | string[]) {
        return { isValid, errorMessage };
      }

      const recreateQuestionModel = () => {
        modelHealthReport.clear();
        jsonData.value = schema_getJSON(schema.value).data as QuestionJSONType;

        try {
          questionModel.value = new QuestionModel(
            jsonData.value.model.formulas,
            jsonData.value.model.parameters,
            jsonData.value.model.vector_parameters,
            modelHealthReport
          );
        } catch (err) {
          return packageInfo(false, ["Question Model has an error.", "Check variables and formulas for the following error: ", err as string]);
        }

        if (modelHealthReport.hasErrors(ModelHealthErrorTypes.GENERAL)) {
          return packageInfo(false, modelHealthReport.getErrors(ModelHealthErrorTypes.GENERAL))
        }
      }

      const validateStatments = (text: unknown) => {
        const response = questionModel.value?.evaluateStatement(
          jsonData.value.prob_data.problemStatement
        )

        return response?.hasError() ?
          packageInfo(false, response.getErrorMessage()) :
          packageInfo(true, "");
      }

      const getCustomValidationFn = (ofType: ModelHealthErrorTypes) => {
        return (text: unknown, meta: Maybe<ValidationMetaData>) => {
          return Either(meta,
            (x) => {
              return packageInfo(!modelHealthReport.hasErrors(ofType, x.rowIndex),
                modelHealthReport.getErrors(ofType, x.rowIndex));
            },
            (x) => { return packageInfo(true, []); });
        }
      };

      const {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
      } = useReactiveForm(questionCreatorSchema(validateStatments,
        getCustomValidationFn(ModelHealthErrorTypes.EQUATIONS),
        getCustomValidationFn(ModelHealthErrorTypes.VARIABLES)), questionData as unknown as Record<string, unknown>);//componentObj 

      let previewQuestionData = ref({});
      const questionKey = ref(1000);

      const updatePageFromFormData = (jsonObj: Page_QuestionComponent, pageRecord: PageFromTextbookTable, formSchema: FormSchemaItem) => {
        jsonData.value = schema_getJSON(formSchema).data as QuestionJSONType;

        pageRecord.long_description = jsonData.value.data.long_description as string;

        jsonObj.data.view.meta.problemStatement = jsonData.value.prob_data.problemStatement;
        jsonObj.data.figure.meta.url = jsonData.value.prob_data.url;

        jsonObj.data.figure.meta.include = (jsonData.value.prob_data.url && jsonData.value.prob_data.url.trim().length !== 0) as boolean;
        jsonObj.data.figure.meta.type = QuestionFigureType.IMAGE;

        jsonObj.data.view.meta.requestedResponses = jsonData.value.prob_data.requestedResponses;
        jsonObj.data.model.meta = jsonData.value.model;

        if (!jsonObj.data.solution) {
          jsonObj.data.solution = { meta: { solutionStatement: "" } };
        }

        jsonObj.data.solution.meta = jsonData.value.soln_data;
        pageRecord.page_activeContent.content = JSON.stringify({ components: [jsonObj] });

        return pageRecord;
      }

      const createPreview = () => {
        previewQuestionData.value = {
          page_activeContent: {
            content: {
              components: [{
                id: "abc",
                isActive: true,
                isInitialized: true,
                name: "First",
                seed: "23413",
                showSolution: true,
                data: {
                  figure: {
                    meta: {
                      caption: "",
                      figureObjects: [],
                      include: true,
                      type: QuestionFigureType.IMAGE,
                      url: jsonData.value.prob_data.url
                    },
                    tabTitle: "figure"
                  },
                  model: {
                    meta: jsonData.value.model,
                    tabTitle: "model",
                  },
                  solution: {
                    meta: {
                      solutionStatement: jsonData.value.soln_data.solutionStatement
                    },
                    tabTitle: "Solution"
                  },
                  view: {
                    meta: {
                      problemStatement: jsonData.value.prob_data.problemStatement,
                      requestedResponses: jsonData.value.prob_data.requestedResponses
                    },
                    tabTitle: "view"
                  },
                }
              } as QuestionDataInterface
              ]
            }
          }
        }
        questionKey.value++;
      }

      const generalErrors: Ref<string[]> = ref([]);

      createPreview();

      const onPress = (v: { type: string; schemaItem: FormSchemaItem }): void => {
        console.log("press", v.type)

        switch (v.type) {
          case "analyze":
            break;
          case "preview":
            recreateQuestionModel();
            generalErrors.value = modelHealthReport.getErrors(ModelHealthErrorTypes.GENERAL);
            triggers.validate = !triggers.validate;
            createPreview();
            break;
          case "save": {
            buttonList[0].isEnabled = false;
            triggers.validate = !triggers.validate;

            const qContent = questionData.page_activeContent?.content as { components: Page_QuestionComponent[] };
            if (previousNrQuestionParts !== qContent.components[0].data.view.meta.requestedResponses.length) {
              if (!questionData.version || questionData.version.length === 0) { questionData.version = "1"; }
              else questionData.version = `${parseInt(questionData.version) + 1}`;
            }

            console.debug("Save Initiated", questionData);
            updatePageFromFormData(componentObj as Page_QuestionComponent, questionData, schema.value);
            context.emit("saveQuestion", props.dataPage);
            break;
          }
          case "reset":
            schema.value = schema_mergeWithData(rawSchema, {});
            formKey.value++;
            break;
          case "hints":
            displaybehavior.displayHints = !displaybehavior.displayHints;
            break;
          case "dialog":
            confirmDialog?.value
              ?.open("test", "test", {})
              .then((isConfirmed: boolean) => {
                console.log(isConfirmed);
              });
            break;
        }
      };

      const validationHooks: ValidationHooks = {
        onBeforeValidate: () => { recreateQuestionModel(); },
        onAfterValidate: () => { return; },
      }

      const onChange = () => {
        buttonList[0].isEnabled = true;
      };

      return {
        modelHealthReport,
        isFormValid,
        schema,
        formKey,
        onPress,
        onChange,
        buttonList,
        displaybehavior,
        triggers,
        confirmDialog,
        isLoading,
        questionKey,
        previewQuestionData,
        validationHooks,
        generalErrors
      }
    }
  })
</script>

<style  lang="scss" >
  .condensedForm {
    font-size: 0.7rem;
  }

  .form-control {
    font-size: 0.7rem;
    padding: 0.2rem 0.2rem;
  }

  .btn {
    font-size: 0.7rem;
  }

  .formColorGroup {
    background-color: #f3f7fb;
  }
</style>