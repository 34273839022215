import * as BABYLON from 'babylonjs';
import { WindTunnel } from '../instruments/Electronic/WindTunnel';
import { BaseRenderedObject } from "../primatives/BaseRenderedObject";
import { Fluid, Fluids } from '../properties/Standard_Fluids';
import { VariableTable } from "../values/VariableTable";
import { ExperimentInterface } from "./ExperimentInterface";
import { WindTunnelGeometry_Sphere, WindTunnelGeometry_GolfBall, WindTunnelGeometry_AirFoil } from '../properties/Geometries_Drag';

interface WindTunnelOptions {
    location?: BABYLON.Vector3,
    rotationY?: number,
    fluid: Fluid
}

export class WindTunnelstation extends BaseRenderedObject implements ExperimentInterface {
    private variableTable: VariableTable;

    private options: WindTunnelOptions;


    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        const fluid = new Fluid(Fluids.AIR);

        this.options = {
            fluid: fluid,
            location: new BABYLON.Vector3(0, 0, 0),
            rotationY: 0
        }

        this.variableTable = new VariableTable();

        this.addChild(new WindTunnel('WIND_TUNNEL', scene, this));
        this.addChild(new WindTunnelGeometry_Sphere('WT_Sphere', scene, this));
        this.addChild(new WindTunnelGeometry_GolfBall('WT_Golf', scene, this));
        this.addChild(new WindTunnelGeometry_AirFoil('WT_AirFoil', scene, this));
    }


    public setOptions(options: WindTunnelOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        super.addPreloadAssets(assetManager);
    }


    public render() {

        this.myContainerNode = BABYLON.MeshBuilder.CreateSphere('WindTunnel Origin', {
            diameter: 0.001
        }, this.scene);

        this.getChildByName("WIND_TUNNEL")
            ?.setOptions({
                location: new BABYLON.Vector3(0, 0, 0)
            })
            .render();

        this.getChildByName("WT_Sphere")
            ?.setOptions({
                location: new BABYLON.Vector3(0.5, 0.2, 0)
            })
            .render();

        this.getChildByName("WT_Golf")
            ?.setOptions({
                location: new BABYLON.Vector3(0.3, 0.2, 0)
            })
            .render();

        this.getChildByName("WT_AirFoil")
            ?.setOptions({
                location: new BABYLON.Vector3(0.1, 0.2, 0)
            })
            .render();

        // move container only after all children are added
        this.myContainerNode.addRotation(0, this.options.rotationY ?? 0, 0);
        this.myContainerNode.position = this.options.location ?? BABYLON.Vector3.Zero();

        return super.render();
    }

    public updateTime(time: number, deltaT: number) {
        this.variableTable.set("TIME", time, "ms");
        this.variableTable.set("DELTA_T", deltaT, "ms");
        this.onChange();
    }

    public onChange() {
        this.setTableFromObjectState(this.variableTable);
        this.reComputeSystemState(this.variableTable);
        this.setObjectStateFromTable(this.variableTable);
    }


    private reComputeSystemState(variableTable: VariableTable) {

        //variableTable.set("PC40", values.P4, "Pa");
    }
}