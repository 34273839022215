import { TextType } from "@/API";
import { createTextbook, deleteTextbook, updateTextbook } from "@/graphql/mutations";
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { getDefaultBaseTextbookDBRecord } from "./table-textbook-utils";
import { PageIndex, TOCFromTextbookTable } from "./table-textbook.interface";
import { v4 as uuidv4 } from 'uuid';

export interface TOCCreationOptioins {
   textID: string;
}

const tocRecordCreator: RecordCreatorParameters<TOCFromTextbookTable, TOCCreationOptioins> = {
   defaultValues: {
      ...getDefaultBaseTextbookDBRecord(),
      toc_pageIndex: ""
   },

   objectType: RecordTypes.TOC,
   createQuery: createTextbook,
   updateQuery: updateTextbook,
   deleteQuery: deleteTextbook,

   getDeleteItemParameters: function (data: TOCFromTextbookTable) {
      return {};
   },

   onAfterCreatedModifyData: function (data: TOCFromTextbookTable,
      createOptions: TOCCreationOptioins,
      selfobj: WrappedRecord<TOCFromTextbookTable, TOCCreationOptioins>) {
      selfobj.data().fieldType = TextType.TOC;
      selfobj.data().textID = createOptions.textID;

      //add root page (seems this root page does not actaully exist in the database)
      const tmpTOCitem = TOC_Record.createTOCPageIndex();
      tmpTOCitem.name = "ROOT";
      tmpTOCitem.pageID = uuidv4();

      (selfobj as TOC_Record).pageIndex = [tmpTOCitem];
   },

   onBeforeSaveToDB: function (data: TOCFromTextbookTable,
      selfobj: WrappedRecord<TOCFromTextbookTable, TOCCreationOptioins>) {
      data.toc_pageIndex = JSON.stringify((selfobj as TOC_Record).pageIndex);
      return data;
   },

   onAfterRecordWrapFromDB(data: TOCFromTextbookTable,
      selfobj: WrappedRecord<TOCFromTextbookTable, TOCCreationOptioins>) {
      (selfobj as TOC_Record).pageIndex = JSON.parse(data.toc_pageIndex || "");
   },

}

setRecordCreator(RecordTypes.TOC, tocRecordCreator);


/**
 * Class used to hold, update, and create database 
 * Table of Contents items. 
 * */
export class TOC_Record extends WrappedRecord<TOCFromTextbookTable, TOCCreationOptioins> {
   public pageIndex: PageIndex[] = [];

   constructor() {
      super(RecordTypes.TOC)
   }

   static createTOCPageIndex(): PageIndex {
      //add root page
      return {
         id: uuidv4(),
         name: "",
         prefix: "",
         pageID: uuidv4(),
         isActive: false,
         children: []
      };
   }

   static loadFromJSON(data: Record<string, unknown>) {
      const newTOC = new TOC_Record();
      Object.assign(newTOC, data);
      return newTOC;
   }

}