import * as BABYLON from 'babylonjs';
import { Nullable } from 'babylonjs/types';
import { ConnectableObjectInterface, PortInternals } from '../instruments/Electronic/CordConnector';
import { BaseRenderedObject, RenderedObject, RenderedObjectTypes, SnapBehaviorType } from '../primatives/BaseRenderedObject';
import { MaterialRegistry, BallType } from '../primatives/Materials/MaterialRegistry';
import { ScalarValue, _u } from "../values/ScalarValue";
import { interpolate2DObjectTable, interpolateObjectTable } from './interpolateObjectTable';


interface WindTunnelGeometry {
    dragCoef: (Re: number, angle: number) => number;
    liftCoef: (Re: number, angle: number) => number;
    characteristicLength: () => ScalarValue;
    crossSectionalArea: (angle: ScalarValue) => ScalarValue;
}

interface WindTunnelGeometryOptions {
    location: BABYLON.Vector3,
    pipeDiameter?: number,
    rotateZ: number,
}

export interface DragProperties {
    Re: number,
    dragCoef: number,
    liftCoef: number
}

export class SphereDrag {
    static dragTable: DragProperties[] = [
        { Re: 0.1, dragCoef: 240, liftCoef: 0.0 },
        { Re: 0.4, dragCoef: 60, liftCoef: 0.0 },
        { Re: 0.8, dragCoef: 32, liftCoef: 0.0 },
        { Re: 2, dragCoef: 15, liftCoef: 0.0 },
        { Re: 10, dragCoef: 4, liftCoef: 0.0 },
        { Re: 40, dragCoef: 1.7, liftCoef: 0.0 },
        { Re: 100, dragCoef: 1, liftCoef: 0.0 },
        { Re: 400, dragCoef: 0.6, liftCoef: 0.0 },
        { Re: 1000, dragCoef: 0.45, liftCoef: 0.0 },
        { Re: 4000, dragCoef: 0.4, liftCoef: 0.0 },
        { Re: 10000, dragCoef: 0.4, liftCoef: 0.0 },
        { Re: 40000, dragCoef: 0.5, liftCoef: 0.0 },
        { Re: 200000, dragCoef: 0.43, liftCoef: 0.0 },
        { Re: 250000, dragCoef: 0.18, liftCoef: 0.0 },
        { Re: 400000, dragCoef: 0.08, liftCoef: 0.0 },
        { Re: 1000000, dragCoef: 0.1, liftCoef: 0.0 },
        { Re: 4000000, dragCoef: 0.2, liftCoef: 0.0 },
    ];

    static getDrag(Re: number) {
        return interpolateObjectTable(SphereDrag.dragTable, 'Re', Re, 'dragCoef');
    }

    static getLift(Re: number) {
        return interpolateObjectTable(SphereDrag.dragTable, 'Re', Re, 'liftCoef');
    }
}


export class CylinderDrag {
    static dragTable: DragProperties[] = [
        { Re: 0.1, dragCoef: 65.1, liftCoef: 0.0 },
        { Re: 0.21, dragCoef: 34.6, liftCoef: 0.0 },
        { Re: 0.69, dragCoef: 15.8, liftCoef: 0.0 },
        { Re: 1.6, dragCoef: 9.76, liftCoef: 0.0 },
        { Re: 9.7, dragCoef: 4.3, liftCoef: 0.0 },
        { Re: 43.4, dragCoef: 2.71, liftCoef: 0.0 },
        { Re: 1662, dragCoef: 1.148, liftCoef: 0.0 },
        { Re: 3713, dragCoef: 1.025, liftCoef: 0.0 },
        { Re: 23808, dragCoef: 1.286, liftCoef: 0.0 },
        { Re: 51326, dragCoef: 1.328, liftCoef: 0.0 },
        { Re: 295000, dragCoef: 1.417, liftCoef: 0.0 },
        { Re: 697000, dragCoef: 1.328, liftCoef: 0.0 },
        { Re: 1828000, dragCoef: 1.058, liftCoef: 0.0 },
        { Re: 2760000, dragCoef: 0.562, liftCoef: 0.0 },
        { Re: 3870000, dragCoef: 0.234, liftCoef: 0.0 },
        { Re: 5940000, dragCoef: 0.294, liftCoef: 0.0 },
        { Re: 12800000, dragCoef: 0.486, liftCoef: 0.0 },
        { Re: 28630000, dragCoef: 0.661, liftCoef: 0.0 },
        { Re: 86680000, dragCoef: 0.829, liftCoef: 0.0 }
    ];

    static getDrag(Re: number) {
        return interpolateObjectTable(CylinderDrag.dragTable, 'Re', Re, 'dragCoef');
    }

    static getLift(Re: number) {
        return interpolateObjectTable(CylinderDrag.dragTable, 'Re', Re, 'liftCoef');
    }
}




export class GolfBallDrag {
    static dragTable: DragProperties[] = [
        { Re: 0.4, dragCoef: 60, liftCoef: 0.0 },
        { Re: 0.8, dragCoef: 32, liftCoef: 0.0 },
        { Re: 2, dragCoef: 15, liftCoef: 0.0 },
        { Re: 10, dragCoef: 4, liftCoef: 0.0 },
        { Re: 40, dragCoef: 1.7, liftCoef: 0.0 },
        { Re: 100, dragCoef: 1, liftCoef: 0.0 },
        { Re: 400, dragCoef: 0.6, liftCoef: 0.0 },
        { Re: 1000, dragCoef: 0.45, liftCoef: 0.0 },
        { Re: 4000, dragCoef: 0.4, liftCoef: 0.0 },
        { Re: 10000, dragCoef: 0.4, liftCoef: 0.0 },
        { Re: 40000, dragCoef: 0.5, liftCoef: 0.0 },
        { Re: 50000, dragCoef: 0.43, liftCoef: 0.0 },
        { Re: 60000, dragCoef: 0.3, liftCoef: 0.0 },
        { Re: 70000, dragCoef: 0.25, liftCoef: 0.0 },
        { Re: 90000, dragCoef: 0.22, liftCoef: 0.0 },
        { Re: 4000000, dragCoef: 0.27, liftCoef: 0.0 },
    ];

    static getDrag(Re: number) {
        return interpolateObjectTable(GolfBallDrag.dragTable, 'Re', Re, 'dragCoef');
    }

    static getLift(Re: number) {
        return interpolateObjectTable(GolfBallDrag.dragTable, 'Re', Re, 'liftCoef');
    }
}


export class BaseWindTunnelGeometry extends BaseRenderedObject implements WindTunnelGeometry {
    public portMap: Map<string, PortInternals> = new Map();
    public dragTable: DragProperties[] = [];

    public dragCoef(Re: number, angle: number) {
        return BaseWindTunnelGeometry.interpolate(this.dragTable, Re, 'dragCoef');
    }

    public liftCoef(Re: number, angle: number) {
        return BaseWindTunnelGeometry.interpolate(this.dragTable, Re, 'liftCoef');
    }

    public characteristicLength: () => ScalarValue = () => { return new ScalarValue(1, "m"); };

    public crossSectionalArea: (angle: ScalarValue) => ScalarValue = () => { return new ScalarValue(1, "m^2"); };

    public getGeometry: Nullable<() => BABYLON.Mesh> = null;

    private options: WindTunnelGeometryOptions = {
        pipeDiameter: 0.0254,
        location: BABYLON.Vector3.Zero(),
        rotateZ: 0
    };

    public setOptions(options: WindTunnelGeometryOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface) {
        const tmpPort = this.portMap.get(myPort)
        if (tmpPort)
            tmpPort.connection = { isConnected: true, connectedPort: connectPort, connectedObject: connectToObj };
    }

    public removeConnection(myPort: string) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.connectedObject?.removeConnection(tmpPort.connection.connectedPort);
            tmpPort.connection.isConnected = false;
        }
    }

    public render() {
        if (this.getGeometry === null) {
            super.render();
            return this;
        }

        const newBase = this.getGeometry();
        newBase.name = this.name;
        newBase.addRotation(0, 0, this.options.rotateZ);
        newBase.setEnabled(true);

        // position and orient the valve
        newBase.position = this.options.location;
        newBase.setParent(this.parent?.getContainerMesh() ?? null);

        this.myContainerNode = newBase;

        // register hit response
        newBase.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(0.1, 0.8, 0.01));
                }));

        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getHighlight(this.scene);
                }));

        this.addSnapBehaviorToMesh(newBase, SnapBehaviorType.PLANE, new BABYLON.Vector3(0, 0, 1));

        super.render();
        return this;
    }

    public getDragPoint(): BABYLON.Vector3 {
        return this.myContainerNode?.getAbsolutePosition()?.clone() ?? BABYLON.Vector3.Zero();
    }

    public dragTo(absPosition: BABYLON.Vector3, orientation: BABYLON.Vector3, alignment: any) {
        this.myContainerNode
            ?.setAbsolutePosition(absPosition);
    }

    public static dragEntry(Re: number, dragCoef: number, liftCoef: number): DragProperties {
        return {
            Re: Re,
            dragCoef: dragCoef,
            liftCoef: liftCoef
        };
    }

    static interpolate(table: DragProperties[], Re: number, prop: string) {
        return interpolateObjectTable(table, 'Re', Re, prop);
    }
}




export class WindTunnelGeometry_Sphere extends BaseWindTunnelGeometry {
    private diameter = _u(0.3, 'm');


    constructor(name: string, scene: BABYLON.Scene, parent: RenderedObject) {
        super(name, scene, parent);

        this.type = RenderedObjectTypes.WT_GEOMETRY;

        this.dragTable = [
            BaseWindTunnelGeometry.dragEntry(0.4, 60, 0.0),
            BaseWindTunnelGeometry.dragEntry(0.8, 32, 0.0),
            BaseWindTunnelGeometry.dragEntry(2, 15, 0.0),
            BaseWindTunnelGeometry.dragEntry(10, 4, 0.0),
            BaseWindTunnelGeometry.dragEntry(40, 1.7, 0.0),
            BaseWindTunnelGeometry.dragEntry(100, 1, 0.0),
            BaseWindTunnelGeometry.dragEntry(400, 0.6, 0.0),
            BaseWindTunnelGeometry.dragEntry(1000, 0.45, 0.0),
            BaseWindTunnelGeometry.dragEntry(4000, 0.4, 0.0),
            BaseWindTunnelGeometry.dragEntry(10000, 0.4, 0.0),
            BaseWindTunnelGeometry.dragEntry(40000, 0.5, 0.0),
            BaseWindTunnelGeometry.dragEntry(200000, 0.43, 0.0),
            BaseWindTunnelGeometry.dragEntry(250000, 0.18, 0.0),
            BaseWindTunnelGeometry.dragEntry(400000, 0.08, 0.0),
            BaseWindTunnelGeometry.dragEntry(1000000, 0.1, 0.0),
            BaseWindTunnelGeometry.dragEntry(4000000, 0.2, 0.0),
        ];

        this.getGeometry = () => {
            const sphere = BABYLON.MeshBuilder.CreateSphere('WT_Sphere', { diameter: this.diameter.in('m') }, this.scene);
            sphere.material = MaterialRegistry.getColor(this.scene, new BABYLON.Color3(0.1, 0.8, 0.01));
            return sphere;
        }

        this.crossSectionalArea = function (angle: ScalarValue) {
            const d = this.diameter.in('m');
            return _u(Math.PI * d * d / 4.0, 'm^2');
        }

        this.characteristicLength = () => {
            return this.diameter;
        };
    }
}


export class WindTunnelGeometry_GolfBall extends BaseWindTunnelGeometry {
    private diameter = _u(0.04267, 'm');

    constructor(name: string, scene: BABYLON.Scene, parent: RenderedObject) {
        super(name, scene, parent);

        this.type = RenderedObjectTypes.WT_GEOMETRY;

        this.dragTable = [
            BaseWindTunnelGeometry.dragEntry(0.4, 60, 0.0),
            BaseWindTunnelGeometry.dragEntry(0.8, 32, 0.0),
            BaseWindTunnelGeometry.dragEntry(2, 15, 0.0),
            BaseWindTunnelGeometry.dragEntry(10, 4, 0.0),
            BaseWindTunnelGeometry.dragEntry(40, 1.7, 0.0),
            BaseWindTunnelGeometry.dragEntry(100, 1, 0.0),
            BaseWindTunnelGeometry.dragEntry(400, 0.6, 0.0),
            BaseWindTunnelGeometry.dragEntry(1000, 0.45, 0.0),
            BaseWindTunnelGeometry.dragEntry(4000, 0.4, 0.0),
            BaseWindTunnelGeometry.dragEntry(10000, 0.4, 0.0),
            BaseWindTunnelGeometry.dragEntry(40000, 0.5, 0.0),
            BaseWindTunnelGeometry.dragEntry(50000, 0.43, 0.0),
            BaseWindTunnelGeometry.dragEntry(60000, 0.3, 0.0),
            BaseWindTunnelGeometry.dragEntry(70000, 0.25, 0.0),
            BaseWindTunnelGeometry.dragEntry(90000, 0.22, 0.0),
            BaseWindTunnelGeometry.dragEntry(4000000, 0.27, 0.0),
        ];

        this.getGeometry = () => {
            const golfBall = BABYLON.MeshBuilder.CreateSphere('WT_Sphere', { diameter: this.diameter.in('m') }, this.scene);
            golfBall.material = MaterialRegistry.getBall(this.scene, BallType.GOLF, {});
            return golfBall;
        }

        this.crossSectionalArea = function (_angle: ScalarValue) {
            const d = this.diameter.in('m');
            return _u(Math.PI * d * d / 4.0, 'm^2');
        }

        this.characteristicLength = () => {
            return this.diameter;
        };
    }
}


export class WindTunnelGeometry_AirFoil extends BaseWindTunnelGeometry {
    private diameter = _u(0.3, 'm'); // chord length
    private wingLength = _u(0.2, 'm'); // wing width

    public dragLiftTable: unknown[] = [];

    public dragCoef(Re: number, angle: number) {
        return interpolate2DObjectTable(this.dragLiftTable, 'angleOfAttack', Math.abs(angle), 'Re', Re, 'dragCoef');
    }

    public liftCoef(Re: number, angle: number) {
        const lift = interpolate2DObjectTable(this.dragLiftTable, 'angleOfAttack', Math.abs(angle), 'Re', Re, 'liftCoef');
        const Re1 = 3E4;

        return -lift * (angle > 0 ? 1 : -1);
    }


    constructor(name: string, scene: BABYLON.Scene, parent: RenderedObject) {
        super(name, scene, parent);

        this.type = RenderedObjectTypes.WT_GEOMETRY;
        // https://m-selig.ae.illinois.edu/uiuc_lsat/Low-Speed-Airfoil-Data-V3.pdf
        // lift angle of attack, CL, CD
        const data_Re3E4 = [
            [0, 0, 0.04],
            [2, 0.191, 0.045],
            [4, 0.377, 0.052],
            [6, 0.556, 0.0637],
            [8, 0.716, 0.0779],
            [10, 0.850, 0.0982],
            [12, 0.933, 0.131],
            [14, 0.946, 0.200],
            [15, 0.892, 0.25],
            [16, 0.832, 0.3],
            [21, 0, 0.4],
            [25, 0, 0.6],
            [45, 0, 1.6],
        ];

        // lift angle of attack, CL, CD
        const data_Re3E5 = [
            [0, 0, 0.0161],
            [2, 0.194, 0.0168],
            [4, 0.411, 0.0184],
            [6, 0.609, 0.0214],
            [8, 0.788, 0.0263],
            [10, 0.950, 0.0328],
            [12, 1.08, 0.0415],
            [14, 1.116, 0.0574],
            [15, 1.05, 0.080],
            [16, 0.991, 0.108],
            [21, 0, 0.2],
            [25, 0, 0.5],
            [45, 0, 1.6],
        ];

        this.dragLiftTable = convertToTable(3E4, data_Re3E4, 3E5, data_Re3E5);
        function convertToTable(Re1: number, arr1: number[][], Re2: number, arr2: number[][]) {
            const coefTable: unknown[] = [];
            arr1.forEach((val, index) => {
                coefTable.push({
                    angleOfAttack: val[0],
                    table: [
                        BaseWindTunnelGeometry.dragEntry(Re1, val[2], val[1]),
                        BaseWindTunnelGeometry.dragEntry(Re2, arr2[index][2], arr2[index][1]),
                    ]
                })
            });
            return coefTable;
        }


        this.getGeometry = () => {
            const shape = [
                [1.000084, 0.001257],
                [0.989256, 0.003499],
                [0.957215, 0.009932],
                [0.905287, 0.019752],
                [0.835642, 0.031805],
                [0.751228, 0.044774],
                [0.655665, 0.057302],
                [0.553099, 0.068063],
                [0.448032, 0.075795],
                [0.344680, 0.079198],
                [0.247774, 0.076558],
                [0.162221, 0.067930],
                [0.091996, 0.054325],
                [0.040261, 0.037346],
                [0.009206, 0.018764],
                [0.000000, 0.000000],
                [0.012647, -0.016609],
                [0.046194, -0.029168],
                [0.098987, -0.037507],
                [0.168649, -0.041686],
                [0.252226, -0.042183],
                [0.346303, -0.039941],
                [0.447439, -0.036049],
                [0.551429, -0.030639],
                [0.653352, -0.024500],
                [0.748772, -0.018385],
                [0.833489, -0.012788],
                [0.903730, -0.008033],
                [0.956330, -0.004376],
                [0.988892, -0.002055],
                [0.999916, -0.001257]
            ];


            //Array of paths to construct extrusion
            const myShape: BABYLON.Vector3[] = [];
            shape.forEach((v) => { myShape.push(new BABYLON.Vector3(-v[0] + 0.2, v[1], 0).scale(this.diameter.in('m'))); })

            const myPath = [
                new BABYLON.Vector3(0, 0, 0),
                new BABYLON.Vector3(0, 0, this.wingLength.in('m'))
            ];

            //Create extrusion with updatable parameter set to true for later changes
            const extrusion = BABYLON.MeshBuilder.ExtrudeShape("airfoil", {
                shape: myShape,
                path: myPath,
                sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                updatable: true,
                cap: BABYLON.Mesh.CAP_ALL
            }, scene);
            extrusion.material = MaterialRegistry.getBall(this.scene, BallType.GOLF, {});
            return extrusion;
        }

        this.crossSectionalArea = function (angle: ScalarValue) {
            const c = this.diameter.in('m');
            const b = this.wingLength.in('m');
            return _u(c * b, 'm^2');
        }

        this.characteristicLength = () => {
            return this.diameter;
        };
    }

}
