export interface QueryItems<T> {
   Count: number;
   ScannedCount: number;
   Items: T[];
}

export function MockQueryItems<T>(items: T[]) {
   return {
      Count: items.length,
      ScannedCount: items.length,
      Items: items
   }
}