
  import { defineComponent, PropType, ref } from 'vue'
  import { FormSchemaItem } from '../form-schema.interface'
  import { FormDisplayBehavior } from '../form.interface';
  import TextInput from "./form-input-text.vue";
  import TextBox from "./form-input-textbox.vue";
  import SelectList from "./form-input-selectlist.vue";
  import CheckBox from "./form-input-checkbox.vue";
  import DateTime from "./form-input-datetime.vue";
  import MarkdownIt from "./form-input-markdown.vue";
  import ArrayInput from "./form-input-array.vue";
  import ArrayOfObjects from "./form-input-arrayOfObjects.vue";
  import BSelect from "../../bootstrap/b-select.vue";

  export default defineComponent({
    name: "ObjectEditorDetail",
    components: {
      BSelect,
      TextInput,
      TextBox,
      SelectList,
      CheckBox,
      DateTime,
      MarkdownIt,
      ArrayInput,
      ArrayOfObjects
    },
    props: {
      modelValue: {
        type: Object as PropType<Record<string, unknown>>,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        required: true
      },
      displaybehavior: {
        type: Object as PropType<FormDisplayBehavior>,
        default: () => {
          return {
            displayHints: false,
            hasChanges: false,
            showJSONButton: false,
            buttonAlign: 'top'
          }
        }
      },
    },
    emits: ["update:modelValue"],
    setup(props) {
      const schemaValue = ref(props.modelValue);
      const selectedType = ref(props.modelValue.selectedTypeIndicator as string || "");

      const hasSelectableTypeInfo = () => {
        return Object.prototype.hasOwnProperty.call(
          props.schemaitem,
          "typeSelectorInfo"
        );
      };

      const getSelectableObjectTypes = () => {
        if (hasSelectableTypeInfo()) {
          return props.schemaitem.typeSelectorInfo?.choices.map(
            (x: { typeName: string }) => {
              return x.typeName;
            }
          );
        } else {
          return [];
        }
      };

      const typeSelectorIncludes = (typeSelectorName: string, field: string) => {
        let typeSel = props.schemaitem.typeSelectorInfo?.choices.find(
          (x: { typeName: string }) => {
            return x.typeName === typeSelectorName;
          }
        );
        if (typeSel) {
          return typeSel.visibleFields.includes(field);
        } else {
          return true;
        }
      };

      const getVisibleSchemaItems = (): FormSchemaItem[] => {
        if (hasSelectableTypeInfo() === null) {
          return props.schemaitem?.children ? props.schemaitem?.children : [];
        } else {
          let returnChildren: FormSchemaItem[] = [];
          props.schemaitem.children?.forEach((v: FormSchemaItem) => {
            if (typeSelectorIncludes(selectedType.value, v.field)) {
              returnChildren.push(v);
            }
          });
          return returnChildren;
        }
      };

      return {
        selectedType,
        schemaValue,
        getVisibleSchemaItems,
        getSelectableObjectTypes
      }
    }
  })
