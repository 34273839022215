
  import { defineComponent, PropType } from "vue";
  import { FormButtonAction } from "../form.interface";

  export default defineComponent({
    name: "FormButtonGroup",
    props: {
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        required: true,
      },
    },
    emits: ["btnPress"],
    methods: {
      handleClick(buttonItem: FormButtonAction) {
        this.$emit("btnPress", buttonItem);
      },
    },
  });
