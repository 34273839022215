import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { FormButtonAction, FormErrorEvent } from "@/components/formGenerator/form.interface";
import { BaseEventI } from "@/components/global/event.interface";
import { Ref } from "@vue/reactivity";

export enum LoginActionTypes {
    EMPTY = "empty",

    SIGN_IN_ACTION = "sign-in-action",

    RESEND_CONFIRMATION_ACTION = "resend-confirmation-action",
    CONFIRM_USER_ACTION = "confirm-user-action",
    SIGN_UP_ACTION = "sign-up-action",
    SIGN_OUT_ACTION = "sign-out-action",

    GOTO_USER_CONFIRM_PAGE = "goto-user-confirm-page",

    GOTO_RESET_PAGE = "goto-reset-page",
    REQUEST_RESET_PASSWORD_ACTION = "request-reset-password-action",
    GOTO_RESET_PASSWORD_PAGE = "goto-reset-password-page",
    GOTO_RESET_PASSWORD_CONFIRMATION_PAGE = "goto-reset-password-page",
    RESET_PASSWORD_SUBMIT_CONFIRMATION_ACTION = "reset-password-submit-confirmation-action",

    GOTO_SIGN_UP_PAGE = "goto-sign-up-page",
    GOTO_SIGN_IN_PAGE = "goto-sign-in-page",
}


interface LoginClickPayload {
    [x: string]: unknown;
    schemaItem?: FormSchemaItem;
    username?: string;
    password?: string;
}

export interface LoginPageEvent extends BaseEventI<LoginActionTypes, LoginClickPayload> {
    originatingAction: string;
    targetAction?: string;
    payload?: any;
}

export interface LoginButtonFooterType {
    infoText: string;
    linkText: string;
    eventType: LoginPageEvent;
    footerInfoText: string;
    footerLinkText: string;
    footerEventType: LoginPageEvent;
}

export interface LoginPageParameterType {
    originationEvent: LoginPageEvent,
    currentSchema: Ref<FormSchemaItem>,
    buttonFooter: Ref<LoginButtonFooterType>,
    buttonFooter2: Ref<LoginButtonFooterType | null>,
    buttonList: FormButtonAction[],
    enterKeyAction: Ref<FormButtonAction>,
    errorMessage: Ref<string>,
    onPress: (event: LoginPageEvent) => void,
    emitError: (event: FormErrorEvent) => void
}

export interface AmplifyUserCredentials {
    accessKeyId: string,
    authenticated: true,
    expiration: Date,
    identityId: string,
    secretAccessKey: string,
    sessionToken: string
}

export interface AmplifyUser {
    attributes: {
        email: string;
        email_verified: boolean;
        family_name: string;
        name: string;
        sub: string;
    },
    signInUserSession: {
        accessToken: {
            payload: {
                auth_time: number;
                client_id: string;
                "cognito:groups": string[];
                sub: string;
                token_use: string;
                username: string;
            }

            jwtToken: string;
        },
        idToken: {
            jwtToken: string;
        }
    },
    pool: {
        userPoolId: string;
        clientId: string;
        username: string;
        userDataKey: string;
    },
    username: string,
    userDataKey: string
}