
  import { defineComponent, reactive, toRefs } from "vue";
  import { ConfirmDialogButtonOptions } from "./b-confirm-dialog.interface";

  export default defineComponent({
    props: {
      requestValue: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const state = reactive({
        title: "",
        message: "",
        inputValue: "",
        dialog: false,
        resolve: null as null | ((v: unknown) => void),
        reject: null as null | ((v: unknown) => void),
        options: {
          cancel_text: "Cancel",
          cancel_class: "btn btn-danger",
          ok_text: "Ok",
          ok_class: "btn btn-success",
        },
      });

      const open = (
        title: string,
        message: string,
        options: ConfirmDialogButtonOptions
      ) => {
        state.dialog = true;
        state.title = title;
        (state.message = message),
          (state.options = Object.assign(state.options, options));
        return new Promise((resolve, reject) => {
          state.resolve = resolve;
          state.reject = reject;
        });
      };

      const agree = () => {
        if (props.requestValue) {
          state.resolve?.({ isConfirmed: true, value: state.inputValue });
        } else {
          state.resolve?.(true);
        }
        state.dialog = false;
      };

      const cancel = () => {
        state.resolve?.(false);
        state.dialog = false;
      };

      return {
        ...toRefs(state),
        open,
        agree,
        cancel,
      };
    },
  });
