
  import BButton from "@/components/bootstrap/b-button.vue";
  import { defineComponent } from "vue";

  export default defineComponent({
    components: { BButton },
    props: {
      lossPerGuess: {
        type: Number,
        required: true,
      },
      maxPossibleScore: {
        type: Number,
        required: true,
      },
      nrAttemptsMade: {
        type: Number,
        required: true,
      },
      maxNrAttempts: {
        type: Number,
        required: true,
      },
      score: {
        type: Number,
        default: undefined,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
      showPreviousAnswers: {
        type: Boolean,
        required: true
      }
    },
    emits: ["click", "update:showPreviousAnswers"],
    setup() {
      return {};
    },
  });
