import { FieldType } from "../../../API";
import { createCourse, deleteCourse, updateCourse } from '../../../graphql/mutations';
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { CourseFromCourseTable, CourseTableBaseType } from "./table-course.interface";
import { getDefaultBaseCourseValues } from "./table-course-utils";

export interface CourseCreateOptions {
    empty?: string;
}

const courseRecordCreator: RecordCreatorParameters<CourseFromCourseTable, CourseCreateOptions> = {
    defaultValues: {
        ...getDefaultBaseCourseValues(),
        co_textbookID: "",
        co_includeText: false,
        co_assignmentBook: "",
        co_includeAssignments: false,
        co_laboratoryID: "",
        co_includeLab: false,
        co_pictureURL: "",
        co_includeNotebook: false,
        co_editors: [""],
        co_topics: []
    },
    objectType: RecordTypes.COURSE,
    createQuery: createCourse,
    updateQuery: updateCourse,
    deleteQuery: deleteCourse,

    getDeleteItemParameters: function (data: CourseFromCourseTable) {
        return {
            id: data.id,
            courseID: data.courseID,
            userEmail: data.userEmail,
            fieldType: data.fieldType
        }
    },

    onAfterCreatedModifyData: function (data: CourseFromCourseTable,
        createOptions: CourseCreateOptions,
        self: CourseRecord) {

        self.data().fieldType = FieldType.COURSE;
        self.data().courseID = data.id;
    },

    onBeforeSaveToDB: function (data: CourseFromCourseTable, self: CourseRecord) {
        return data;
    },

    onAfterRecordWrapFromDB: function (data: CourseFromCourseTable, self: CourseRecord) {
        return;
    }
};

setRecordCreator(RecordTypes.COURSE, courseRecordCreator);

/**
 * Class used to hold, update, and create database 
 * Course items. 
 * */
export class CourseRecord extends WrappedRecord<CourseFromCourseTable, CourseCreateOptions> {
    constructor() {
        super(RecordTypes.COURSE);
    }


    static loadFromJSON(data: Record<string, unknown>) {
        const newBook = new CourseRecord();
        Object.assign(newBook, data);
        return newBook;
    }
}