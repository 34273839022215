
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: undefined,
      },
      label: {
        type: String,
        default: undefined,
      },
      show: {
        type: Boolean,
        default: true,
      },
      onClickCallback: {
        type: Function,
        requited: true,
        default: () => {
          return;
        },
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ["click"],
    setup() {
      return {};
    },
  });
