import { useUserStore } from "@/store/vueStore/useUserStore";
import { Auth } from "aws-amplify";
import { VResponse, failable, RequestResponse } from "../global/response.interface";



export interface SignUpAttributes {
    username: string;
    password: string;
    school: string;
    firstName: string;
    lastName: string;
    confirmation: boolean;
    agree: string;
}

export interface useLoginReturnFunctions {
    signIn: (username: string, password: string) => VResponse;
    signOut: () => void;
    resendConfirmation: (username: string) => VResponse;
    signUp: (signUpAttributes: SignUpAttributes) => VResponse;
    confirmSignUp: (username: string, code: string) => VResponse;
    verifyConfirmationCode: (username: string, code: string) => VResponse;
    changePassword: (oldPassword: string, newPassword: string) => VResponse;
    requestReset: (username: string) => VResponse;
    resetPasswordWithConf: (username: string, code: string, newPassword: string) => VResponse;
    getCurrentCredentials: () => VResponse;
    getCurrentUser: () => VResponse;
    hasAuthenticatedUser: () => VResponse;
}

export default function useLogin(): useLoginReturnFunctions {
    const getCurrentUser = async (): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.currentAuthenticatedUser();
        });
    }

    const hasAuthenticatedUser = async (): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.currentUserInfo() !== null;
        });
    }


    const getCurrentCredentials = async (): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.currentCredentials();
        });
    }

    const signIn = async (username: string, password: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.signIn({ username, password });
        });
    }

    const signOut = async (): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            const { logout } = useUserStore();
            logout();
            return await Auth.signOut();
        });
    }

    const resendConfirmation = async (username: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.resendSignUp(username);
        });
    }

    const signUp = async (signUpAttributes: SignUpAttributes): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            const { user } = await Auth.signUp({
                username: signUpAttributes.username,
                password: signUpAttributes.password,
                attributes: {
                    'email': signUpAttributes.username,
                    'name': signUpAttributes.firstName,
                    'family_name': signUpAttributes.lastName,
                    'custom:School': signUpAttributes.school,
                    'custom:Agree': signUpAttributes.agree ? 'YES' : 'NO'
                }
            });

            return user;
        });
    }

    const confirmSignUp = async (username: string, code: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            if (username.length === 0) { throw new Error("You must enter an email address.") }
            if (code.length === 0) { throw new Error("You must enter a confirmation code.") }
            return await Auth.confirmSignUp(username, code);
        });
    };


    const verifyConfirmationCode = async (username: string, code: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.verifyCurrentUserAttributeSubmit(username, code);
        });
    }


    const changePassword = async (oldPassword: string, newPassword: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            const user = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(user, oldPassword, newPassword);
            return data;
        });
    }

    const requestReset = async (username: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.forgotPassword(username);
        });
    }

    const resetPasswordWithConf = async (username: string, code: string, newPassword: string): Promise<RequestResponse<unknown>> => {
        return await failable(async () => {
            return await Auth.forgotPasswordSubmit(username, code, newPassword);
        });
    }

    return {
        signIn,
        signOut,
        resendConfirmation,
        requestReset,
        resetPasswordWithConf,
        changePassword,
        verifyConfirmationCode,
        confirmSignUp,
        signUp,
        getCurrentCredentials,
        getCurrentUser,
        hasAuthenticatedUser
    }

}