<template>
  <FormGroupButtons :button-list="buttonList"
                    @btnPress="onBtnPress" />
  <button v-if="displaybehavior.showJSONButton"
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#jsonModal">
    JSON
  </button>
  <teleport to="body">
    <BModal v-if="schemaitem && schemaitem!== null"
            mid="jsonModal">
      <form-schema-json :schema="schemaitem" />
    </BModal>
  </teleport>
</template>

<script lang="ts">
  import FormGroupButtons from "./form-group-buttons.vue";
  import FormSchemaJson from "../form-schema-json.vue";
  import BModal from "../../bootstrap/b-modal.vue";

  import { defineComponent, PropType } from "vue";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { FormButtonAction } from "../form.interface";

  export default defineComponent({
    components: {
      FormGroupButtons,
      FormSchemaJson,
      BModal,
    },
    props: {
      ...formItemMixinProps,
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        default: () => {
          return [];
        },
      },
    },
    emits: ["btnPress"],
    setup() {
      return {};
    },
    methods: {
      onBtnPress(btnAction: FormButtonAction) {
        this.$emit("btnPress", btnAction);
      },
    },
  });
</script>

<style scoped>
</style>