
  import { toRefs } from "@vueuse/core";
  import {
    defineComponent,
    onBeforeMount,
    onMounted,
    provide,
    reactive,
    VNode,
  } from "vue";

  interface TabProps {
    title: string;
  }

  export default defineComponent({
    name: "BTabs",
    props: {
      isVertical: {
        type: Boolean,
        default: false,
      },
      menuWidth: {
        type: String,
        default: "100px",
      },
    },
    setup(_, context) {
      const state = reactive({
        selectedIndex: 0,
        tabs: [] as VNode<TabProps>[],
        count: 0,
      });

      provide("TabsProvider", state);

      const selectTab = (i: number) => {
        state.selectedIndex = i;
      };

      onBeforeMount(() => {
        if (context.slots.default) {
          state.tabs = context.slots
            .default()
            .filter(
              (child) => (child.type as { name: string }).name === "BTab"
            ) as VNode<TabProps>[];
        }
      });

      onMounted(() => {
        selectTab(0);
      });

      return { ...toRefs(state), selectTab };
    },
  });
