
  import { defineComponent, PropType } from "vue";
  import {
    bNavbarDropdownPropTypes,
    bNavbarDropdownType,
  } from "./b-navbar.interface";

  export default defineComponent({
    props: {
      isAdmin: {
        type: Boolean,
        default: false
      },
      dropButtonStyle: {
        type: Object as PropType<{
          label: string;
          iconName: string;
          rightSideDrop: boolean;
        }>,
        default: () => {
          return { label: "Dropdown", iconName: "bi-person-fill" };
        },
      },
      items: {
        type: Array as PropType<Array<bNavbarDropdownPropTypes>>,
        required: true,
      },
    },
    setup() {
      return {
        bNavbarDropdownType,
      };
    },
  });
