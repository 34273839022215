
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    props: {
      show: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ""
      }
    },
    emits: ["update:show"],
    setup(props, context) {
      const showModal = ref(props.show);

      const cancel = () => {
        showModal.value = false;
        context.emit("update:show", false);
      };



      return {
        showModal,
        cancel,
      };
    },
  });
