import Rand from 'rand-seed';

export class QRandom {
   static rnd: Rand = new Rand();

   static setSeed(seed: string) {
      this.rnd = new Rand(seed);
   }

   static getRnd(): number {
      return this.rnd.next();
   }
}