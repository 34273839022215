import { TextType } from "@/API";
import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { getEmptyBabylon, getEmptyMarkdown, getEmptyQuestion, getEmptyQuiz } from "@/components/contentGenerator/pageComponents/EmptyComponentFactory";
import { PageComponent, PageComponentTypes } from "@/components/contentGenerator/pages/pages.interface";
import { TableOfContents } from "@/components/contentGenerator/tableOfContents";
import { PageRecord } from "@/store/database/Textbook/record-page";
import { CreateEmptyPageIndex } from "@/store/database/Textbook/table-textbook.interface";
import { LocalStorageClass } from "@/store/local/localStorage";
import { ItemCreationEventType, NameChangeEventType } from "../components/p-page-iterator.types";

const namespace = "Vengla-DB";
const lsc = new LocalStorageClass().config(namespace);

export const bookEditorProps = {
   courseId: {
      type: String,
      required: true,
      default: ""
   },
   textId: {
      type: String,
      required: true,
      default: ""
   },
}



export const pageEditorProps = {
   pageId: {
      type: String,
      required: true,
      default: ""
   },
   rootPageId: {
      type: String,
      required: true,
      default: ""
   },
};

export function usePageEditorFunctions(reloadPage: (pageID: string) => void) {

   const getEmptyComponent = (type: PageComponentTypes) => {
      switch (type) {
         case PageComponentTypes.MARKDOWN:
            return getEmptyMarkdown();
         case PageComponentTypes.QUESTION:
            return getEmptyQuestion();
         case PageComponentTypes.QUESTION_BLOCK:
            return getEmptyQuiz();
         case PageComponentTypes.BABYLON:
            return getEmptyBabylon();
      }

      return null;
   }

   const getIconForComponentType = (type: PageComponentTypes) => {
      switch (type) {
         case PageComponentTypes.QUESTION_BLOCK:
            return "bi-question-diamond";
         case PageComponentTypes.BABYLON:
            return "bi-wrench";
         case PageComponentTypes.MARKDOWN:
            return "bi-pencil";
         case PageComponentTypes.VIDEO:
            return "bi-camera-video";
         case PageComponentTypes.FIGURE:
            return "bi-card-image";
         case PageComponentTypes.CHART:
            return "bi-bar-chart";
         case PageComponentTypes.IMG:
            return "bi-card-image";

      }

      return "";
   }

   const pageHasComponent = (data: PageRecord) => {
      const currentPageData = data?.data();
      if (TypeGuard.isNullOrUndefined(currentPageData))
         return false;

      const contentStr = currentPageData?.page_activeContent.content;
      if (TypeGuard.isString(contentStr)) {
         return (JSON.parse(contentStr).components.length === 1);
      } else {
         if (!TypeGuard.isNullOrUndefined(contentStr)) {
            return contentStr.components.length === 1
         }
      }

      return false;
   };


   const getComponentList = (data: PageRecord | undefined) => {
      if (TypeGuard.isNullOrUndefined(data))
         return [];

      const currentPageData = data.data();

      const contentStr = currentPageData?.page_activeContent.content;
      if (TypeGuard.isString(contentStr)) {
         return (JSON.parse(contentStr).components);
      } else {
         if (!TypeGuard.isNullOrUndefined(contentStr)) {
            return contentStr.components
         }
      }

      return [];
   }

   const deleteLocalAndDBPage = async (pageRecord: PageRecord,
      toc: TableOfContents) => {
      console.debug("Delete Object", pageRecord);

      const textID = pageRecord.data().textID;
      const deletePageID = pageRecord.data().id;
      // modify the table of contents
      toc.deleteEntryFor(deletePageID);
      await toc.updateInDB();

      const localBookRecord = await lsc.get(`Book-${textID}`);

      const pIndex = localBookRecord.pages.findIndex((page: { _data: { id: string } }) => page._data.id === deletePageID);
      if (pIndex === -1) return;

      localBookRecord.pages.splice(pIndex, 1);
      localBookRecord.tableOfContents = toc.getRecord();

      await lsc.set(`Book-${textID}`, localBookRecord);

      await pageRecord
         .deleteRecordFromDB();

   }

   const insertLocalAndDBPage = async (pageRecord: PageRecord,
      toc: TableOfContents) => {

      await pageRecord.saveToDB();
      const textID = pageRecord.data().textID;

      const localBook = await lsc.get(`Book-${textID}`);

      localBook.pages.push(pageRecord);
      localBook.tableOfContents = toc.getRecord();

      await lsc.set(`Book-${textID}`, localBook);
   }


   const updateLocalAndDBPage = async (pageRecord: PageRecord,
      toc: TableOfContents | undefined = undefined, hasNameChanged = false) => {

      const result = await pageRecord.updateInDB();
      const updatedDBData = (await result?.getData())?.data?.updateTextbook;

      if (!updatedDBData)
         return;

      const pageID = pageRecord.data().id;
      const textID = pageRecord.data().textID;
      const localBook = await lsc.get(`Book-${textID}`);

      if (hasNameChanged && toc) {
         await toc.updateInDB();

         localBook.tableOfContents = toc.getRecord();
      }

      const foundPage = localBook.pages.find((page: any) => page._data.id === pageID);
      foundPage._data = updatedDBData;

      await lsc.set(`Book-${textID}`, localBook);
   }

   const deletePageAndUpdateTOC = async (itemData: ItemCreationEventType) => {
      if (!itemData.pageData || !itemData.toc || !itemData.book)
         return null;

      const deletePageID = itemData.pageData.data().id;
      const deletePageNr = itemData.book.findPageNrOf(deletePageID);
      const totalNrPages = itemData.book.getNrPages();
      let routeToPageNr = deletePageNr;

      if (deletePageNr && deletePageNr === totalNrPages - 1) { routeToPageNr = deletePageNr - 1; }

      await deleteLocalAndDBPage(itemData.pageData, itemData.toc);

      if (routeToPageNr) {
         const routeToPageRecordID = itemData.book.getTableOfContents()?.getIDOfPageNr(routeToPageNr);
         if (routeToPageRecordID) {
            return reloadPage(routeToPageRecordID);
         }
      }

      return null;
   };

   const changeItemNameAndUpdateTOC = async (itemData: NameChangeEventType) => {
      if (!itemData.pageData || !itemData.pageIndex) return;

      const pageDataObj = itemData.pageData.data();
      pageDataObj.name = itemData.name;

      itemData.pageIndex.name = itemData.name;

      const content = pageDataObj.page_activeContent.content;
      pageDataObj.page_activeContent.content = TypeGuard.isString(content) ?
         content :
         JSON.stringify(content);

      if (itemData.pageData && itemData.toc) {
         await updateLocalAndDBPage(itemData.pageData, itemData.toc, true);
      }

      return;
   }


   const updateTOC = async (itemData: NameChangeEventType) => {
      if (!itemData.book) return;
      const textID = itemData.book?.getID();
      const localBook = await lsc.get(`Book-${textID}`);

      if (itemData.toc) {
         await itemData.toc.updateInDB();

         if (localBook) {
            localBook.tableOfContents = itemData.toc.getRecord();
            await lsc.set(`Book-${textID}`, localBook);
         }
         console.log({ textID, itemData })

      }
      return;
   }

   const addItemAndUpdateTOC = async (itemData: ItemCreationEventType) => {
      let type: TextType;
      const { book, toc } = { ...itemData };
      if (!book || !toc) return;

      const textID = book.getID();
      if (!textID) return;

      let componentContent: PageComponent[] = [];
      switch (book.getBookRecord()?.data().fieldType) {
         case "QUESTION_BANK":
            type = TextType.QUESTION;
            componentContent = [getEmptyQuestion()];
            break;
         case "ASSIGNBOOK":
            type = TextType.ASSIGNMENT;
            break;
         case "LABROOM":
            type = TextType.EXPERIMENT;
            break;
         case "BOOK":
            type = TextType.PAGE;
            componentContent = [getEmptyMarkdown()];
            break;
         default:
            type = TextType.PAGE;
            break;
      }

      const tmpQuestionPageContent = {
         components: componentContent
      };

      const emptyPageIndex = CreateEmptyPageIndex();
      toc.getRootIndex()?.children.push(emptyPageIndex);
      await toc.updateInDB();

      const tempPage = new PageRecord()
         .createNewFromTOCentry(type, emptyPageIndex, textID);

      tempPage.data().page_activeContent.content = JSON.stringify(tmpQuestionPageContent);

      book.getPageList().push(tempPage);

      await insertLocalAndDBPage(tempPage, toc)
      console.log("reload")
      reloadPage(tempPage.data().id);
   }
   return {
      updateTOC,
      updateLocalAndDBPage,
      deletePageAndUpdateTOC,
      deleteLocalAndDBPage,
      changeItemNameAndUpdateTOC,
      addItemAndUpdateTOC,
      pageHasComponent,
      getComponentList,
      getIconForComponentType,
      getEmptyComponent
   }

}