import { TextType } from "@/API";
import { BookRecord } from "@/store/database/Textbook/record-book";
import { PageRecord } from "@/store/database/Textbook/record-page";
import { PageIndex } from "@/store/database/Textbook/table-textbook.interface";
import { TypeGuard } from "./mathjs/Type-guards";
import { TableOfContents } from "./tableOfContents";

export class TextbookObject {
   private bookRecord: BookRecord | undefined = undefined;
   private tableOfContents: TableOfContents | undefined = undefined;
   private pages: PageRecord[] = [];

   constructor() {
      return;
   }

   async initializeInDB(name: string, type: TextType) {
      this.bookRecord = new BookRecord();

      let pageType: TextType;
      switch (type) {
         case TextType.BOOK:
            pageType = TextType.PAGE;
            break;
         case TextType.ASSIGNBOOK:
            pageType = TextType.ASSIGNMENT;
            break;
         case TextType.QUESTION_BANK:
            pageType = TextType.QUESTION;
            break;
         case TextType.LOGBOOK:
            pageType = TextType.LOGPAGE;
            break;
         default:
            throw new Error("Unable to find type:" + type);
      }

      // textbook record
      await this.bookRecord.createNewInDB({
         id: "",
         textID: "",
         name: name,
         description: "",
         fieldType: type,
      }, { type });

      const textID = this.bookRecord.data().id;
      console.debug("Textbook ID:", textID);

      // create the table of contents
      this.tableOfContents = new TableOfContents();
      await this.tableOfContents.createNewInDB({
         id: "",
         version: "",
         textID,
      }, {
         textID
      });
      console.debug("Root Index:", this.tableOfContents.getRootIndex());

      // create the root page
      const newPage = new PageRecord();
      const rootIndex = this.tableOfContents.getRootIndex();
      if (!rootIndex) {
         console.debug("Unable to find rootIndex.");
         return;
      }

      await newPage
         .createNewFromTOCentry(pageType, rootIndex, textID)
         .saveToDB();

      this.pages = [];
      this.pages.push(newPage);


      console.debug("Page Index:", this.pages[0].data().id);
   }

   async storeInDB() {
      await this.bookRecord?.saveToDB();
      await this.tableOfContents?.saveToDB();

      for (const page of this.pages) {
         await page.saveToDB();
      }
   }

   static loadFromJSON(book: Record<string, unknown>,
      tocRecord: Record<string, unknown>,
      pages: Record<string, unknown>[]) {
      const newTextbook = new TextbookObject();
      try {
         newTextbook.bookRecord = BookRecord.loadFromJSON(book);
         newTextbook.tableOfContents = TableOfContents.loadFromJSON(tocRecord);
         newTextbook.pages = PageRecord.loadFromJSON(pages);
      } catch (err) {
         throw new Error(err as string);
      }

      return newTextbook;
   }

   hasData() {
      return this.bookRecord && this.tableOfContents;
   }

   getID() {
      return this.bookRecord?.data().id;
   }

   getBookRecord() {
      return this.bookRecord;
   }

   public getPageByID(id: string | undefined): PageRecord | undefined {
      if (id === undefined) return;

      const index = this.pages.findIndex((v) => { return v.data().id === id; });
      if (index === -1) return undefined;
      return this.pages[index];
   }

   getPageByNr(pageNr: number) {
      return this.getPageByID(this.tableOfContents?.getIDOfPageNr(pageNr));
   }

   public findPageNrOf(pageID: string) {
      return this.tableOfContents?.findPageNrOf(pageID);
   }

   public getNrPages() {
      if (this.tableOfContents) {
         return this.tableOfContents.getNrPages();
      } else {
         return 0;
      }
   }

   public getChidrenOfID(id: string): PageIndex[] | undefined {
      return this.tableOfContents?.findPageIndexOf(id)?.children;
   }

   async updateTOC() {
      await this.tableOfContents?.updateInDB();
   }

   async addNewPage(page: PageRecord) {
      this.pages.push(page);
      page.saveToDB();
      await this.updateTOC();
   }

   public getPageList() {
      return this.pages;
   }

   public getRootPageID(): string | undefined {
      return this.tableOfContents?.getRootIndex()?.pageID;
   }

   public getFirstPage(): string | undefined {
      const rootIndex = this.tableOfContents?.getRootIndex();
      if (rootIndex) {
         if (rootIndex?.children.length > 0) {
            return rootIndex.children[0].pageID;
         } else {
            return rootIndex.pageID;
         }
      }
      return undefined;
   }

   public getFirstLevelPages(): PageRecord[] {
      const pageIndexChildren = this.tableOfContents?.getRootIndex()?.children;
      const arrayOfPages: PageRecord[] = [];
      pageIndexChildren?.forEach((v) => {
         const page = this.getPageByID(v.pageID);
         if (!TypeGuard.isNullOrUndefined(page)) {
            arrayOfPages.push(page);
         }
      });
      return arrayOfPages;
   }

   public getTableOfContents() {
      return this.tableOfContents;
   }

}