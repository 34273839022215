
  import { defineComponent } from "vue";
  import { bSortDirections } from "./b-table.interface";
  import BTable from "./b-table.vue";

  export default defineComponent({
    components: {
      BTable,
    },
    setup() {
      const fields = [
        {
          key: "name",
          freezeColumn: true,
          label: "First Name",
          sortable: true,
          class: "",
        },
        {
          key: "age",
          sortable: false,
          sortDirection: bSortDirections.ASCENDING,
          formatter: (value: unknown) => {
            return (value as number) * 2;
          },
        },
        { key: "hasCat", sortable: false },
        {
          key: "date",
          sortable: true,

          formatter: (value: string) => {
            return new Date(value).toDateString();
          },
        },
      ];

      const data = [
        { name: "t", age: 6, hasCat: true, date: new Date().toISOString() },
        { name: "s2", age: 5, hasCat: false, date: new Date().toISOString() },
        { name: "t3", age: 4, hasCat: true, date: new Date().toISOString() },
        { name: "t4", age: 3, hasCat: false, date: new Date().toISOString() },
        { name: "w5", age: 2, hasCat: false, date: new Date().toISOString() },
        { name: "t6", age: 1, hasCat: false, date: new Date().toISOString() },
        { name: "t", age: 3, hasCat: true, date: new Date().toISOString() },
        { name: "t2", age: 5, hasCat: false, date: new Date().toISOString() },
        { name: "t3", age: 4, hasCat: true, date: new Date().toISOString() },
        { name: "t4", age: 7, hasCat: false, date: new Date().toISOString() },
        { name: "t5", age: 2, hasCat: false, date: new Date().toISOString() },
        { name: "m", age: 6, hasCat: true, date: new Date().toISOString() },
        { name: "t2", age: 5, hasCat: false, date: new Date().toISOString() },
        { name: "t3", age: 9, hasCat: true, date: new Date().toISOString() },
        { name: "t4", age: 3, hasCat: false, date: new Date().toISOString() },
        { name: "t5", age: 2, hasCat: false, date: new Date().toISOString() },
        { name: "t", age: 6, hasCat: true, date: new Date().toISOString() },
        { name: "a2", age: 5, hasCat: false, date: new Date().toISOString() },
        { name: "w3", age: 10, hasCat: true, date: new Date().toISOString() },
        { name: "n4", age: 3, hasCat: false, date: new Date().toISOString() },
        { name: "s5", age: 2, hasCat: false, date: new Date().toISOString() },
      ];

      return {
        fields,
        data,
      };
    },
  });
