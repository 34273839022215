<template>
  <div v-katex:auto>
    <div v-if="submission && submissionRecords"
         class="container-sm p-2 p-md-4">
      <div class="row  limit-growth">
        <div class="col">
          <BMarkdown :content="problemStatement"
                     class="problemStatement" />
        </div>
        <div v-if="includeFigure"
             class="col">
          <BImg v-if="imgSrc"
                :src="imgSrc"
                :style="{}"
                class="card-img-top" />
        </div>
      </div>
      <div class="row">
        <div class="col limit-growth">
          <UserResponseList :requested-user-responses="responseRequests"
                            :question-model="questionModel"
                            :submission-records="submissionRecords"
                            :allow-input="allowInput"
                            @submit="handleSubmit" />
        </div>
      </div>
      <div v-if="showSolution"
           class=" row border-top mt-2">
        <div class="col limit-growth">
          <h4>Solution</h4>
          <BMarkdown :content="solutionStatement" />
        </div>
      </div>
    </div>
    <div v-else>
      <b-loading label="Loading Question ... " />
    </div>
  </div>
</template>

<script lang="ts">
  import BMarkdown from "@/components/bootstrap/b-markdown.vue";
  import { BaseEventI } from "@/components/global/event.interface";
  import { SubmissionItem, SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent, onMounted, PropType, Ref, ref } from "vue";
  import { AnswerState } from "./components/user-response.interface";
  import UserResponseList from "./components/UserResponseList.vue";
  import {
    SubmissionInfoRequest,
    useSubmissions,
  } from "./components/useSubmissions";
  import {
    useMockSubmissions,
  } from "./components/useMockSubmissions";

  import { ModelHealthReport } from "./model/model-health-report";
  import { QuestionDataInterface } from "./QuestionViewer.interface";
  import { getRequestedResponseFromQuestionComp, readQuestionComponent } from "./questionComponentReader";
  import { TypeGuard } from "../mathjs/Type-guards";
  import BImg from "@/components/bootstrap/b-img.vue";
  import useS3 from "@/components/use/useS3";
  import usePageComponents from "@/components/use/usePageComponents";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import { QRandom } from "./model/qRandom";
  import BLoading from "@/components/bootstrap/b-loading.vue";


  export default defineComponent({
    components: { BMarkdown, UserResponseList, BImg, BLoading },
    props: {
      questionData: {
        type: Object as PropType<PageFromTextbookTable>,
        required: true,
      },
      componentId: {
        type: String,
        required: true,
      },
      courseId: {
        type: String,
        required: true,
      },
      questionBankId: {
        type: String,
        required: true
      },
      allowInput: {
        type: Boolean,
        default: true
      },
      showSolution: {
        type: Boolean,
        default: false
      },
      mockSubmissions: {
        type: Boolean,
        default: false
      },
      injectedUserId: {
        type: String,
        default: ""
      }
    },
    setup(props) {
      console.debug("Start Question Viewer");

      const { getActiveComponentsFromPageData } = usePageComponents();
      const component = getActiveComponentsFromPageData(props.questionData).components[0];

      const modelHealthReport = new ModelHealthReport();

      const { userName } = useUserStore();
      console.debug("User Name is", userName);

      const responseRequests = getRequestedResponseFromQuestionComp(component as unknown as QuestionDataInterface);

      const {
        pullQuestionSubmission,
        deleteSubmission,
        updateFinalSubmission,
        registerSubmissionAttempt,
      } = props.mockSubmissions ?
          useMockSubmissions(responseRequests) :
          useSubmissions();


      const idInfo: SubmissionInfoRequest = {
        courseID: props.courseId,
        componentID: props.componentId,
        questionID: props.questionData.id,
        questionVersion: props.questionData.version ? props.questionData.version : "1",
        userEmail: userName as string,
        userId: props.injectedUserId
      };

      const submission: Ref<SubmissionItem | undefined> = ref();
      const submissionRecords: Ref<SumissionQuestionPart[]> = ref(
        []
      );

      const problemStatement = ref("");
      const solutionStatement = ref("");
      const figureURL = ref("");
      const includeFigure = ref(true);
      const questionModel = ref({});
      const imgSrc = ref("");

      onMounted(async () => {
        submission.value = await pullQuestionSubmission({
          ...idInfo,
          nrProblemParts: responseRequests.length,
        }) as SubmissionItem;

        submissionRecords.value = submission.value.data;

        console.debug("Question Viewer is Mounted")
        console.debug({ submissionRecords, submission, questionData: props.questionData });
        console.debug("Seed ", submission.value.seed.toString());
        QRandom.setSeed(submission.value.seed.toString());

        const result = readQuestionComponent(component as unknown as QuestionDataInterface, modelHealthReport);
        problemStatement.value = result.problemStatement;
        solutionStatement.value = result.solutionStatement;
        includeFigure.value = result.includeFigure;
        questionModel.value = result.questionModel;
        figureURL.value = result.figureURL;

        // const nrQPartsStoredInSubmission = submissionRecords.value.length;
        // const nrQPartsInComponent = result.responseRequests.length;
        // if (nrQPartsStoredInSubmission !== nrQPartsInComponent) {
        //   deleteSubmission()
        //   throw new Error("Problem Solution has changed.")
        // }

        console.debug("Resolve S3")
        const { resolveURL } = useS3();
        imgSrc.value = includeFigure.value ? resolveURL(props.questionBankId, figureURL.value) : "";

        console.debug("End Question Viewer")
      })


      const submitAttemptUpdate = async (qPartIndex: number, entry: string) => {

        await registerSubmissionAttempt({
          ...idInfo,
          questionPart: qPartIndex,
          entry: [entry],
        });

        submissionRecords.value[qPartIndex].nrAttempts++;
      };

      const handleSubmit = async (
        event: BaseEventI<
          "submit",
          { index: number; entry: string; answerState: AnswerState }
        >
      ) => {
        console.log("Handle Submit");
        if (TypeGuard.isNullOrUndefined(event.payload?.entry) || event.payload?.entry.length === 0 || event.payload?.entry === "\"\"") {
          return;
        }

        const payload = event.payload;
        if (!payload) return;

        const index = payload.index;
        console.log("Payload", payload);
        if (payload.answerState !== AnswerState.CORRECT) {
          submitAttemptUpdate(index, payload.entry);
          return;
        }

        console.debug("Computing Score")
        // compute the score for the question part
        const nrAttempts = submissionRecords.value[index].nrAttempts + 1;
        submissionRecords.value[index].nrAttempts = nrAttempts;

        const lossPerGuess = responseRequests[index].partialPMper;
        const qPartScorePercent = 100 - (nrAttempts - 1) * lossPerGuess;

        const nrQuestionParts = responseRequests.length;
        const percentOfQuestion = TypeGuard.safePullProp(responseRequests[index],
          "percentOfQuestion", 100 / nrQuestionParts);

        submissionRecords.value[index].scoreInPercent = qPartScorePercent;
        submissionRecords.value[index].percentOfQuestion = percentOfQuestion;

        console.log("Correct Ans Submitted to Problem Part:", index);
        console.log("nrAttempts", nrAttempts);
        console.log("score", qPartScorePercent);
        console.log("percentOfQuestion", percentOfQuestion);
        console.log("lossPerGuess", lossPerGuess);

        // need assignment to compute this. Max points will be in the assignment.
        // console.log("qScorePoints:", );

        // submit changes to database
        const newObj = await updateFinalSubmission({
          ...idInfo,
          questionPart: index,
          entry: [payload.entry],
          scoreInPercent: submissionRecords.value[index].scoreInPercent,
          percentOfQuestion,
        });
        console.log(newObj);
      };



      return {
        submission,
        imgSrc,
        submissionRecords,
        handleSubmit,
        questionModel,
        problemStatement,
        solutionStatement,
        includeFigure,
        responseRequests,
      };
    },
  });
</script>

<style scoped>
  .limit-growth {
    max-width: 900px;
    margin: auto;
  }
  ::v-deep(.problemStatement) {
    font-size: 1.2em;
  }
</style>