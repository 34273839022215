import { _u } from '../values/ScalarValue';

export enum PipeNominal {
    NOM_1D8 = "1/8",
    NOM_1D4 = "1/4",
    NOM_3D8 = "3/8",
    NOM_1D2 = "1/2",
    NOM_3D4 = "3/4",
    NOM_1 = "1",
    NOM_1_1D2 = "1 1/2",
    NOM_2 = "2",
    NOM_3 = "3",
    NOM_4 = "4"
}

export enum PipeSchedule {
    Sch_10 = "10",
    Sch_40 = "40",
    Sch_80 = "80",
    Sch_160 = "160"
}

export interface StandardPipe {
    nominalSize: PipeNominal;
    outerDiameter: number;
    schedule: PipeSchedule;
    wallThickness: number;
    innerDiameter: number;
    material: PipeMaterial;
    surfaceRoughness: number;
}


export enum PipeMaterial {
    CONCRETE_COARSE,
    CONCRETE_SMOOTH,
    COPPER,
    PVC,
    FIBERGLASS,
    DRAWN_TUBING,
    GLASS,
    IRON_CAST,
    IRON_GALVONIZED,
    STEEL_RUSTED,
    STEEL_STRUCTURAL,
    WOOD
}



export class PipeScheduleFactory {
    // sizes are in inches
    // [ O.D., 10, 40, 80, 160]
    private static lookupTable = [
        [0.405, 0.049, 0.068, 0.095, 0],  // 1/8
        [0.540, 0.065, 0.088, 0.119, 0],  // 1/4
        [0.675, 0.065, 0.091, 0.126, 0],  // 3/8
        [0.840, 0.083, 0.109, 0.147, 0.187],  // 1/2
        [1.050, 0.083, 0.113, 0.154, 0.218], // 3/4
        [1.315, 0.109, 0.133, 0.179, 0.250],  // 1
        [1.900, 0.109, 0.145, 0.200, 0.343],  // 1 1/2
        [2.375, 0.109, 0.154, 0.218, 0.437],  // 2
        [3.500, 0.120, 0.216, 0.300, 0],  // 3
        [4.500, 0, 0, 0.337, 0.531],      // 4
    ];

    // units of mm
    private static surfaceRounghness: Map<PipeMaterial, number> = new Map([
        [PipeMaterial.GLASS, 0.0025],
        [PipeMaterial.WOOD, 0.3],

        [PipeMaterial.STEEL_RUSTED, 0.5],
        [PipeMaterial.STEEL_STRUCTURAL, 0.025],
        [PipeMaterial.IRON_CAST, 0.26],
        [PipeMaterial.IRON_GALVONIZED, 0.15],

        [PipeMaterial.CONCRETE_COARSE, 0.25],
        [PipeMaterial.CONCRETE_SMOOTH, 0.025],

        [PipeMaterial.COPPER, 0.0015],
        [PipeMaterial.DRAWN_TUBING, 0.0015],

        [PipeMaterial.PVC, 0.0015],
        [PipeMaterial.FIBERGLASS, 0.005]
    ]);


    private static getRow(nominal: PipeNominal) {
        switch (nominal) {
            case PipeNominal.NOM_1D8:
                return 0;
            case PipeNominal.NOM_1D4:
                return 1;
            case PipeNominal.NOM_3D8:
                return 2;
            case PipeNominal.NOM_1D2:
                return 3;
            case PipeNominal.NOM_3D4:
                return 4;
            case PipeNominal.NOM_1:
                return 5;
            case PipeNominal.NOM_1_1D2:
                return 6;
            case PipeNominal.NOM_2:
                return 7;
            case PipeNominal.NOM_3:
                return 8;
            case PipeNominal.NOM_4:
                return 9;
        }
    }

    private static getColumn(schedule: PipeSchedule) {
        switch (schedule) {
            case PipeSchedule.Sch_10:
                return 1;
            case PipeSchedule.Sch_40:
                return 2;
            case PipeSchedule.Sch_80:
                return 3;
            case PipeSchedule.Sch_160:
                return 4;
        }
    }

    static getPipeSize(nominal: PipeNominal, schedule: PipeSchedule, unit: string, material: PipeMaterial): StandardPipe {
        const row = PipeScheduleFactory.getRow(nominal);
        const column = PipeScheduleFactory.getColumn(schedule);

        const outer = _u(PipeScheduleFactory.lookupTable[row][0], 'in');
        const wallThickness = _u(PipeScheduleFactory.lookupTable[row][column], 'in');
        const inner = _u(PipeScheduleFactory.lookupTable[row][0] - PipeScheduleFactory.lookupTable[row][column], 'in');
        const surfRough = _u(PipeScheduleFactory.surfaceRounghness.get(material), 'mm');
        return {
            nominalSize: nominal,
            schedule: schedule,
            material: material,
            outerDiameter: outer.in(unit),
            wallThickness: wallThickness.in(unit),
            innerDiameter: inner.in(unit),
            surfaceRoughness: surfRough.in(unit)
        }
    }
}