export const enum DebugLevel {
    DEBUG,
    INFO,
    WARN,
    ERROR,
    NONE
}

export const setConsoleLevel = (level: DebugLevel) => {
    try {
        console.log('Setting Log Level: ', level);
        switch (level) {
            case DebugLevel.NONE:
                window.console.error = function () { return; };
                window.console.warn = function () { return; };
                window.console.dir = function () { return; };
                window.console.log = function () { return; };
                window.console.info = function () { return; };
                window.console.debug = function () { return; };
                break;
            case DebugLevel.ERROR:
                window.console.warn = function () { return; };
                window.console.dir = function () { return; };
                window.console.log = function () { return; };
                window.console.info = function () { return; };
                window.console.debug = function () { return; };
                break;
            case DebugLevel.WARN:
                window.console.dir = function () { return; };
                window.console.log = function () { return; };
                window.console.info = function () { return; };
                window.console.debug = function () { return; };
                break;
            case DebugLevel.INFO:
                window.console.debug = function () { return; };
                break;
            case DebugLevel.DEBUG:
                // print everything
                break;
        }
    } catch (err) {
        throw new Error("Unable to redefine console log");
    }
}