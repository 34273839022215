import * as BABYLON from 'babylonjs';
import { OBJFileLoader } from 'babylonjs-loaders';

import { BaseRenderedObject, RenderedObjectTypes, AssetTaskTiedToScene } from "../BaseRenderedObject";
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { SceneMetaData } from "../../engine/SceneMetaData";
import { ConnectableObjectInterface, PortInternals } from "../../instruments/Electronic/CordConnector";
import { ControlResponseRequested } from "../../controls/Control_Base";
import { Nullable } from 'babylonjs/types';

interface PressureConnectorOptions {
    location: BABYLON.Vector3,
    pipeDiameter?: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number
}

export class Pressure_Connect extends BaseRenderedObject implements ConnectableObjectInterface {
    public portMap: Map<string, PortInternals> = new Map();
    public static connectorTemplate: Nullable<BABYLON.Mesh> = null;
    public static loadAssetTask: Nullable<AssetTaskTiedToScene> = null;

    private options: PressureConnectorOptions = {
        pipeDiameter: 0.0254,
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.PIPE_PRESSURE_CONNECTOR;
        this.portMap.set("0", { name: "0", value: null, connection: { isConnected: false, connectedPort: "", connectedObject: null } });
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {

        this.preloadAssetWorker(
            assetManager,
            Pressure_Connect.loadAssetTask,
            "Load Pressure Connector",
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/pipes/",
            "Pressure_Connect.obj",
            function (task) {
                Pressure_Connect.connectorTemplate = (task.loadedMeshes[0] as BABYLON.Mesh);
                Pressure_Connect.connectorTemplate.scaling = BABYLON.Vector3.One().scale(0.001);
                Pressure_Connect.connectorTemplate.setEnabled(false);
            }
        );

        super.addPreloadAssets(assetManager);
    }

    public setOptions(options: PressureConnectorOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public getBezierPoints(port: string) {
        const local_pos = new BABYLON.Vector3(0, 0, 0);

        if (this.myContainerNode === null) { return [local_pos, local_pos]; }

        const pos = [];
        const matrix = this.myContainerNode.computeWorldMatrix(true);

        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        local_pos.z -= 400;
        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        return pos;
    }

    public registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface) {

        const tmpPort = this.portMap.get(myPort);
        if (tmpPort)
            tmpPort.connection = { isConnected: true, connectedPort: connectPort, connectedObject: connectToObj };
    }

    public removeConnection(myPort: string) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.connectedObject?.removeConnection(tmpPort?.connection?.connectedPort);
            tmpPort.connection.isConnected = false;
        }
    }

    public render() {

        if (Pressure_Connect.connectorTemplate === null)
            return this;

        const newBase = Pressure_Connect.connectorTemplate
            .clone();
        newBase.name = this.name;
        //newBase.makeGeometryUnique();
        newBase.addRotation(this.options.rotateX, this.options.rotateY, this.options.rotateZ);
        newBase.setEnabled(true);
        newBase.material = MaterialRegistry.getSilver(this.scene);

        // position and orient the valve
        newBase.position = this.options.location;
        newBase.setParent(this.parent?.getContainerMesh() ?? null);
        this.myContainerNode = newBase;

        // register hit response
        newBase.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getSilver(this.scene);
                }));

        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getHighlight(this.scene);
                }));

        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    let command: Nullable<ControlResponseRequested> = null;
                    if (this.portMap.get("0")?.connection?.isConnected) {
                        command = ControlResponseRequested.REMOVE_CONNECTION;
                    } else {
                        command = ControlResponseRequested.SECONDARY_PRESSURE_CONNECTION;
                    }
                    (this.scene.metadata as SceneMetaData).controlPanel
                        .processHit(command,
                            {
                                controlParams: {}
                            },
                            this,
                            { port: this.portMap.get("0") });
                }));

        super.render();

        return this;
    }
}