import { ScalarValue, _u } from '../values/ScalarValue';
import { interpolateObjectTable } from './interpolateObjectTable';

export enum Fluids {
    AIR,
    WATER,
    SAE10W40,
    SAE10W60
}

export interface FluidProperties {
    temp: number; // C
    dens: number; // kg/m^3
    specW: number; // N/m3
    visc: number; // N*s/m^2 // Pa * s
    kinVisc: number; // m^2/s
    surfTen: number; // N/m
    vapP: number;    // kPa
    blkM: number;    // GPa
    cp: number // kJ/(kg K) isochoric specific  heat
}

export class Fluid {
    public static getListOfFluids() {
        // needs to be in the same order as the Fluids Enum
        return ["Air", "Water", "SAE10W40", "SAE10W60"];
    }

    static getEntry(tempInC: number, denInkgPM3: number, viscosity: number, surfTen: number, vapPressure: number, blkM: number, cp: number): FluidProperties {
        return {
            temp: tempInC,
            dens: denInkgPM3,
            specW: denInkgPM3 * 9.81,
            visc: viscosity,
            kinVisc: viscosity / denInkgPM3,
            surfTen: surfTen,
            vapP: vapPressure,
            blkM: blkM,
            cp: cp
        };
    }

    static waterTable: FluidProperties[] = [
        Fluid.getEntry(0, 999.9, 1.792E-3, 0.0762, 0.610, 2.04, 4.2199),
        Fluid.getEntry(5, 1000.0, 1.519E-3, 0.0754, 0.872, 2.06, 4.20),
        Fluid.getEntry(10, 999.7, 1.308E-3, 0.0748, 1.13, 2.11, 4.1955),
        Fluid.getEntry(15, 999.1, 1.140E-3, 0.0741, 1.60, 2.14, 4.18995),
        Fluid.getEntry(20, 998.2, 1.005E-3, 0.0736, 2.34, 2.20, 4.1844),
        Fluid.getEntry(30, 995.7, 0.801E-3, 0.0718, 4.24, 2.23, 4.1801),
        Fluid.getEntry(50, 988.1, 0.549E-3, 0.0682, 12.3, 2.30, 4.1851),
        Fluid.getEntry(70, 977.8, 0.406E-3, 0.0650, 31.2, 2.25, 4.1902),
        Fluid.getEntry(100, 958.4, 0.284E-3, 0.0594, 101.3, 2.07, 4.2157)
    ];

    static airTable: FluidProperties[] = [
        Fluid.getEntry(-40, 1.52, 1.51E-5, 0, 0, 0, 1.006),
        Fluid.getEntry(0, 1.29, 1.71E-5, 0, 0, 0, 1.006),
        Fluid.getEntry(20, 1.20, 1.80E-5, 0, 0, 0, 1.006),
        Fluid.getEntry(50, 1.09, 1.95E-5, 0, 0, 0, 1.007),
        Fluid.getEntry(100, 0.946, 2.17e-5, 0, 0, 0, 1.012),
        Fluid.getEntry(200, 0.746, 2.57E-5, 0, 0, 0, 1.030),
        Fluid.getEntry(300, 0.616, 2.93E-5, 0, 0, 0, 1.051),
        Fluid.getEntry(400, 0.525, 6.20E-5, 0, 0, 0, 1.075)
    ];

    //https://wiki.anton-paar.com/en/engine-oil/
    static SAE10W40: FluidProperties[] = [
        Fluid.getEntry(0, 876, 0.735, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(20, 863, 0.208, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(40, 850, 0.07933, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(60, 838, 0.03715, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(90, 819, 0.01509, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(100, 813, 0.011877, 3.6E-2, 0, 1.31E9, 2.3)
    ];

    //https://wiki.anton-paar.com/en/engine-oil/
    static SAE10W60: FluidProperties[] = [
        Fluid.getEntry(0, 863, 1.454, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(20, 850, 0.3810, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(40, 838, 0.1355, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(60, 826, 0.0606, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(90, 807, 0.03929, 3.6E-2, 0, 1.31E9, 2.3),
        Fluid.getEntry(100, 800, 0.01799, 3.6E-2, 0, 1.31E9, 2.3)
    ];

    fluidType: Fluids;
    temperature: ScalarValue = _u(20, 'C');

    constructor(type: Fluids, temperature: ScalarValue = _u(20, 'C')) {
        this.setTemp(temperature);
        this.fluidType = type;
    }

    setType(type: Fluids) {
        this.fluidType = type;
    }

    setTemp(temp: ScalarValue) {
        this.temperature = temp.clone();
    }

    private getTable() {
        switch (this.fluidType) {
            case Fluids.WATER:
                return Fluid.waterTable;
            case Fluids.AIR:
                return Fluid.airTable;
            case Fluids.SAE10W40:
                return Fluid.SAE10W40;
            case Fluids.SAE10W60:
                return Fluid.SAE10W60;
            default:
                throw Error("Could not find fluid type:" + this.fluidType);
        }
    }

    getDensity(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'dens'), 'kg/m3');
    }

    getViscosity(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'visc'), 'Pa*s');
    }

    getKinematicViscosity(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'kinVisc'), 'm^2/s');
    }

    getSpecificHeat(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'cp'), 'kJ/kg/K');
    }

    getSurfaceTension(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'surfTen'), 'N/m');
    }

    getSpecificWeight(): ScalarValue {
        return _u(Fluid.interpolate(this.getTable(), this.temperature, 'specW'), 'N/m3');
    }

    static interpolate(table: FluidProperties[], temp: ScalarValue, prop: string) {
        const tempInC = temp.in('C');
        return interpolateObjectTable(table, 'temp', tempInC, prop);
    }
}

