export interface ChildBearing {
   name: string,
   id: string,
   prefix?: string,
   children: ChildBearing[]
}

export const flattenChildList = (list: ChildBearing[] | ChildBearing): ChildBearing[] => {
   const flatList = flat(Array.isArray(list) ? list : [list]);
   return flatList;

   function flat(array: ChildBearing[]) {
      let result: ChildBearing[] = [];
      array.forEach(function (a) {
         result.push(a);
         if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children));
         }
      });
      return result;
   }
}