/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelTextbookPrimaryCompositeKeyConditionInput = {
  eq?: ModelTextbookPrimaryCompositeKeyInput | null,
  le?: ModelTextbookPrimaryCompositeKeyInput | null,
  lt?: ModelTextbookPrimaryCompositeKeyInput | null,
  ge?: ModelTextbookPrimaryCompositeKeyInput | null,
  gt?: ModelTextbookPrimaryCompositeKeyInput | null,
  between?: Array< ModelTextbookPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelTextbookPrimaryCompositeKeyInput | null,
};

export type ModelTextbookPrimaryCompositeKeyInput = {
  fieldType?: TextType | null,
  id?: string | null,
};

export enum TextType {
  BOOK = "BOOK",
  TOC = "TOC",
  PAGE = "PAGE",
  LOGBOOK = "LOGBOOK",
  LOGPAGE = "LOGPAGE",
  ASSIGNBOOK = "ASSIGNBOOK",
  ASSIGNMENT = "ASSIGNMENT",
  LABROOM = "LABROOM",
  EXPERIMENT = "EXPERIMENT",
  QUESTION_BANK = "QUESTION_BANK",
  QUESTION = "QUESTION",
}


export type ModelTextbookFilterInput = {
  id?: ModelIDInput | null,
  textID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  long_description?: ModelStringInput | null,
  fieldType?: ModelTextTypeInput | null,
  txt_pictureURL?: ModelStringInput | null,
  lab_pictureURL?: ModelStringInput | null,
  toc_pageIndex?: ModelStringInput | null,
  page_isActive?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  ttlExpireTime?: ModelIntInput | null,
  version?: ModelStringInput | null,
  and?: Array< ModelTextbookFilterInput | null > | null,
  or?: Array< ModelTextbookFilterInput | null > | null,
  not?: ModelTextbookFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelTextTypeInput = {
  eq?: TextType | null,
  ne?: TextType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTextbookConnection = {
  __typename: "ModelTextbookConnection",
  items?:  Array<Textbook | null > | null,
  nextToken?: string | null,
};

export type Textbook = {
  __typename: "Textbook",
  id?: string,
  textID?: string,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  fieldType?: TextType,
  txt_pictureURL?: string | null,
  lab_pictureURL?: string | null,
  toc_pageIndex?: string | null,
  page_isActive?: boolean | null,
  page_activeContent?: PageContent,
  page_draftContent?: PageContent,
  page_history?:  Array<PageContent | null > | null,
  asn_options?: AssignmentOptions,
  owner?: string | null,
  ttlExpireTime?: number | null,
  version?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type PageContent = {
  __typename: "PageContent",
  createdOn?: string | null,
  updatedOn?: string | null,
  creator?: string | null,
  content?: string | null,
};

export type AssignmentOptions = {
  __typename: "AssignmentOptions",
  isGroup?: boolean | null,
  formOwnGroups?: boolean | null,
  maxGroupMembers?: number | null,
  requiresGroupNotebook?: boolean | null,
  openOn?: string | null,
  dueOn?: string | null,
  isTimed?: boolean | null,
  timeLimit?: string | null,
  maxPoints?: number | null,
  maxAttempts?: number | null,
  catagory?: string | null,
  showSolutions?: boolean | null,
  showScoreBreakdown?: boolean | null,
  useHandwritingEntry?: boolean | null,
  randomizeQuestions?: boolean | null,
  randomizeAnswers?: boolean | null,
};

export type Submissions = {
  __typename: "Submissions",
  searchID?: string,
  courseID?: string,
  userEmail?: string,
  fieldType?: SubmissionType | null,
  data?: string | null,
  seed?: number | null,
  gradeOverride?: number | null,
  earnedPercent?: number | null,
  owner?: string | null,
  ttlExpireTime?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export enum SubmissionType {
  ASSIGNMENT = "ASSIGNMENT",
  QUESTION = "QUESTION",
}


export type CreateCodeLogTableInput = {
  id?: string | null,
  fieldType: string,
  createdOn?: string | null,
  issue?: string | null,
  resolution?: string | null,
  status?: string | null,
  createdBy?: string | null,
};

export type ModelCodeLogTableConditionInput = {
  fieldType?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  issue?: ModelStringInput | null,
  resolution?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelCodeLogTableConditionInput | null > | null,
  or?: Array< ModelCodeLogTableConditionInput | null > | null,
  not?: ModelCodeLogTableConditionInput | null,
};

export type CodeLogTable = {
  __typename: "CodeLogTable",
  id?: string,
  fieldType?: string,
  createdOn?: string | null,
  issue?: string | null,
  resolution?: string | null,
  status?: string | null,
  createdBy?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateCodeLogTableInput = {
  id: string,
  fieldType?: string | null,
  createdOn?: string | null,
  issue?: string | null,
  resolution?: string | null,
  status?: string | null,
  createdBy?: string | null,
};

export type DeleteCodeLogTableInput = {
  id: string,
};

export type CreateUserPermissionsInput = {
  id?: string | null,
  fieldType: string,
  userEmail: string,
  item_type?: string | null,
  editable_item?: string | null,
  permission?: string | null,
  createdBy?: string | null,
  courseID?: string | null,
  role?: string | null,
  profile?: string | null,
  lastLogin?: string | null,
  nrVisits?: number | null,
};

export type ModelUserPermissionsConditionInput = {
  item_type?: ModelStringInput | null,
  editable_item?: ModelIDInput | null,
  permission?: ModelStringInput | null,
  courseID?: ModelIDInput | null,
  role?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  nrVisits?: ModelIntInput | null,
  and?: Array< ModelUserPermissionsConditionInput | null > | null,
  or?: Array< ModelUserPermissionsConditionInput | null > | null,
  not?: ModelUserPermissionsConditionInput | null,
};

export type UserPermissions = {
  __typename: "UserPermissions",
  id?: string,
  fieldType?: string,
  userEmail?: string,
  item_type?: string | null,
  editable_item?: string | null,
  permission?: string | null,
  createdBy?: string | null,
  courseID?: string | null,
  role?: string | null,
  profile?: string | null,
  lastLogin?: string | null,
  nrVisits?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateUserPermissionsInput = {
  id: string,
  fieldType: string,
  userEmail: string,
  item_type?: string | null,
  editable_item?: string | null,
  permission?: string | null,
  createdBy?: string | null,
  courseID?: string | null,
  role?: string | null,
  profile?: string | null,
  lastLogin?: string | null,
  nrVisits?: number | null,
};

export type DeleteUserPermissionsInput = {
  userEmail: string,
  fieldType: string,
  id: string,
};

export type CreateGroupFormationInput = {
  id?: string | null,
  fieldType: string,
  userEmail: string,
  courseID?: string | null,
  groupID?: string | null,
  role?: string | null,
  assignmentID?: string | null,
};

export type ModelGroupFormationConditionInput = {
  courseID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  role?: ModelStringInput | null,
  assignmentID?: ModelStringInput | null,
  and?: Array< ModelGroupFormationConditionInput | null > | null,
  or?: Array< ModelGroupFormationConditionInput | null > | null,
  not?: ModelGroupFormationConditionInput | null,
};

export type GroupFormation = {
  __typename: "GroupFormation",
  id?: string,
  fieldType?: string,
  userEmail?: string,
  courseID?: string | null,
  groupID?: string | null,
  role?: string | null,
  assignmentID?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateGroupFormationInput = {
  id: string,
  fieldType: string,
  userEmail: string,
  courseID?: string | null,
  groupID?: string | null,
  role?: string | null,
  assignmentID?: string | null,
};

export type DeleteGroupFormationInput = {
  userEmail: string,
  fieldType: string,
  id: string,
};

export type CreateCourseInput = {
  id?: string | null,
  courseID: string,
  fieldType: FieldType,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  availibility?: AvailibilityInput | null,
  isActive?: boolean | null,
  userEmail: string,
  co_editors?: Array< string | null > | null,
  co_pictureURL?: string | null,
  co_includeText?: boolean | null,
  co_textbookID?: string | null,
  co_includeAssignments?: boolean | null,
  co_assignmentBook?: string | null,
  co_includeLab?: boolean | null,
  co_laboratoryID?: string | null,
  co_questionBankID?: string | null,
  co_includeNotebook?: boolean | null,
  co_topics?: Array< TopicsInput | null > | null,
  assign_data?: string | null,
  reg_initDate?: string | null,
  reg_expiration?: string | null,
  reg_receipt?: ReceiptInput | null,
  reg_role?: string | null,
  note_bookID?: string | null,
  ttlExpireTime?: number | null,
};

export enum FieldType {
  COURSE = "COURSE",
  ASSIGNMENT = "ASSIGNMENT",
  REGISTRATION = "REGISTRATION",
  NOTEBOOK = "NOTEBOOK",
}


export type AvailibilityInput = {
  restrictionType?: AvailibilityRestrictionType | null,
  inputCode?: string | null,
  userEmailMatch?: string | null,
  userEmailList?: Array< string | null > | null,
  cost?: number | null,
  stripeProductID?: string | null,
  stripePriceID?: string | null,
  restrictToDates?: boolean | null,
  showOn?: string | null,
  openOn?: string | null,
  closeOn?: string | null,
  hideOn?: string | null,
};

export enum AvailibilityRestrictionType {
  ACCESS_CODE = "ACCESS_CODE",
  EMAIL_MATCH = "EMAIL_MATCH",
  EMAIL_LIST = "EMAIL_LIST",
  PAID = "PAID",
  FREE = "FREE",
  NONE = "NONE",
}


export type TopicsInput = {
  name?: string | null,
  id?: string | null,
  subTopics?: Array< SubTopicsInput | null > | null,
};

export type SubTopicsInput = {
  name?: string | null,
  id?: string | null,
};

export type ReceiptInput = {
  registeredOn: string,
  wasFree?: boolean | null,
  wasCharged?: boolean | null,
  hasPaid?: boolean | null,
  cost?: number | null,
  discountApplied?: number | null,
  amountPaid?: number | null,
  transactionID?: string | null,
};

export type ModelCourseConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  long_description?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  co_pictureURL?: ModelStringInput | null,
  co_includeText?: ModelBooleanInput | null,
  co_textbookID?: ModelIDInput | null,
  co_includeAssignments?: ModelBooleanInput | null,
  co_assignmentBook?: ModelIDInput | null,
  co_includeLab?: ModelBooleanInput | null,
  co_laboratoryID?: ModelIDInput | null,
  co_questionBankID?: ModelIDInput | null,
  co_includeNotebook?: ModelBooleanInput | null,
  assign_data?: ModelStringInput | null,
  reg_initDate?: ModelStringInput | null,
  reg_expiration?: ModelStringInput | null,
  reg_role?: ModelStringInput | null,
  note_bookID?: ModelStringInput | null,
  ttlExpireTime?: ModelIntInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type Course = {
  __typename: "Course",
  id?: string,
  courseID?: string,
  fieldType?: FieldType,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  availibility?: Availibility,
  isActive?: boolean | null,
  userEmail?: string,
  co_editors?: Array< string | null > | null,
  co_pictureURL?: string | null,
  co_includeText?: boolean | null,
  co_textbookID?: string | null,
  co_includeAssignments?: boolean | null,
  co_assignmentBook?: string | null,
  co_includeLab?: boolean | null,
  co_laboratoryID?: string | null,
  co_questionBankID?: string | null,
  co_includeNotebook?: boolean | null,
  co_topics?:  Array<Topics | null > | null,
  assign_data?: string | null,
  reg_initDate?: string | null,
  reg_expiration?: string | null,
  reg_receipt?: Receipt,
  reg_role?: string | null,
  note_bookID?: string | null,
  ttlExpireTime?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export type Availibility = {
  __typename: "Availibility",
  restrictionType?: AvailibilityRestrictionType | null,
  inputCode?: string | null,
  userEmailMatch?: string | null,
  userEmailList?: Array< string | null > | null,
  cost?: number | null,
  stripeProductID?: string | null,
  stripePriceID?: string | null,
  restrictToDates?: boolean | null,
  showOn?: string | null,
  openOn?: string | null,
  closeOn?: string | null,
  hideOn?: string | null,
};

export type Topics = {
  __typename: "Topics",
  name?: string | null,
  id?: string | null,
  subTopics?:  Array<SubTopics | null > | null,
};

export type SubTopics = {
  __typename: "SubTopics",
  name?: string | null,
  id?: string | null,
};

export type Receipt = {
  __typename: "Receipt",
  registeredOn?: string,
  wasFree?: boolean | null,
  wasCharged?: boolean | null,
  hasPaid?: boolean | null,
  cost?: number | null,
  discountApplied?: number | null,
  amountPaid?: number | null,
  transactionID?: string | null,
};

export type UpdateCourseInput = {
  id: string,
  courseID: string,
  fieldType: FieldType,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  availibility?: AvailibilityInput | null,
  isActive?: boolean | null,
  userEmail: string,
  co_editors?: Array< string | null > | null,
  co_pictureURL?: string | null,
  co_includeText?: boolean | null,
  co_textbookID?: string | null,
  co_includeAssignments?: boolean | null,
  co_assignmentBook?: string | null,
  co_includeLab?: boolean | null,
  co_laboratoryID?: string | null,
  co_questionBankID?: string | null,
  co_includeNotebook?: boolean | null,
  co_topics?: Array< TopicsInput | null > | null,
  assign_data?: string | null,
  reg_initDate?: string | null,
  reg_expiration?: string | null,
  reg_receipt?: ReceiptInput | null,
  reg_role?: string | null,
  note_bookID?: string | null,
  ttlExpireTime?: number | null,
};

export type DeleteCourseInput = {
  courseID: string,
  fieldType: FieldType,
  userEmail: string,
  id: string,
};

export type CreateTextbookInput = {
  id?: string | null,
  textID: string,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  fieldType: TextType,
  txt_pictureURL?: string | null,
  lab_pictureURL?: string | null,
  toc_pageIndex?: string | null,
  page_isActive?: boolean | null,
  page_activeContent?: PageContentInput | null,
  page_draftContent?: PageContentInput | null,
  page_history?: Array< PageContentInput | null > | null,
  asn_options?: AssignmentOptionsInput | null,
  owner?: string | null,
  ttlExpireTime?: number | null,
  version?: string | null,
};

export type PageContentInput = {
  createdOn?: string | null,
  updatedOn?: string | null,
  creator?: string | null,
  content?: string | null,
};

export type AssignmentOptionsInput = {
  isGroup?: boolean | null,
  formOwnGroups?: boolean | null,
  maxGroupMembers?: number | null,
  requiresGroupNotebook?: boolean | null,
  openOn?: string | null,
  dueOn?: string | null,
  isTimed?: boolean | null,
  timeLimit?: string | null,
  maxPoints?: number | null,
  maxAttempts?: number | null,
  catagory?: string | null,
  showSolutions?: boolean | null,
  showScoreBreakdown?: boolean | null,
  useHandwritingEntry?: boolean | null,
  randomizeQuestions?: boolean | null,
  randomizeAnswers?: boolean | null,
};

export type ModelTextbookConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  long_description?: ModelStringInput | null,
  txt_pictureURL?: ModelStringInput | null,
  lab_pictureURL?: ModelStringInput | null,
  toc_pageIndex?: ModelStringInput | null,
  page_isActive?: ModelBooleanInput | null,
  ttlExpireTime?: ModelIntInput | null,
  version?: ModelStringInput | null,
  and?: Array< ModelTextbookConditionInput | null > | null,
  or?: Array< ModelTextbookConditionInput | null > | null,
  not?: ModelTextbookConditionInput | null,
};

export type UpdateTextbookInput = {
  id: string,
  textID: string,
  name?: string | null,
  description?: string | null,
  long_description?: string | null,
  fieldType: TextType,
  txt_pictureURL?: string | null,
  lab_pictureURL?: string | null,
  toc_pageIndex?: string | null,
  page_isActive?: boolean | null,
  page_activeContent?: PageContentInput | null,
  page_draftContent?: PageContentInput | null,
  page_history?: Array< PageContentInput | null > | null,
  asn_options?: AssignmentOptionsInput | null,
  owner?: string | null,
  ttlExpireTime?: number | null,
  version?: string | null,
};

export type DeleteTextbookInput = {
  textID: string,
  fieldType: TextType,
  id: string,
};

export type CreateSubmissionsInput = {
  searchID: string,
  courseID: string,
  userEmail: string,
  fieldType?: SubmissionType | null,
  data?: string | null,
  seed?: number | null,
  gradeOverride?: number | null,
  earnedPercent?: number | null,
  owner?: string | null,
  ttlExpireTime?: number | null,
};

export type ModelSubmissionsConditionInput = {
  fieldType?: ModelSubmissionTypeInput | null,
  data?: ModelStringInput | null,
  seed?: ModelFloatInput | null,
  gradeOverride?: ModelFloatInput | null,
  earnedPercent?: ModelFloatInput | null,
  ttlExpireTime?: ModelIntInput | null,
  and?: Array< ModelSubmissionsConditionInput | null > | null,
  or?: Array< ModelSubmissionsConditionInput | null > | null,
  not?: ModelSubmissionsConditionInput | null,
};

export type ModelSubmissionTypeInput = {
  eq?: SubmissionType | null,
  ne?: SubmissionType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateSubmissionsInput = {
  searchID: string,
  courseID: string,
  userEmail: string,
  fieldType?: SubmissionType | null,
  data?: string | null,
  seed?: number | null,
  gradeOverride?: number | null,
  earnedPercent?: number | null,
  owner?: string | null,
  ttlExpireTime?: number | null,
};

export type DeleteSubmissionsInput = {
  courseID: string,
  searchID: string,
  userEmail: string,
};

export type CreateMarketingEmailListsInput = {
  id?: string | null,
  fieldType: string,
  userEmail: string,
  contactDates?: Array< string | null > | null,
  optedOut?: boolean | null,
  optedOutDate?: string | null,
  optedOutIPaddress?: string | null,
  meta?: string | null,
};

export type ModelMarketingEmailListsConditionInput = {
  contactDates?: ModelStringInput | null,
  optedOut?: ModelBooleanInput | null,
  optedOutDate?: ModelStringInput | null,
  optedOutIPaddress?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelMarketingEmailListsConditionInput | null > | null,
  or?: Array< ModelMarketingEmailListsConditionInput | null > | null,
  not?: ModelMarketingEmailListsConditionInput | null,
};

export type MarketingEmailLists = {
  __typename: "MarketingEmailLists",
  id?: string,
  fieldType?: string,
  userEmail?: string,
  contactDates?: Array< string | null > | null,
  optedOut?: boolean | null,
  optedOutDate?: string | null,
  optedOutIPaddress?: string | null,
  meta?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateMarketingEmailListsInput = {
  id: string,
  fieldType: string,
  userEmail: string,
  contactDates?: Array< string | null > | null,
  optedOut?: boolean | null,
  optedOutDate?: string | null,
  optedOutIPaddress?: string | null,
  meta?: string | null,
};

export type DeleteMarketingEmailListsInput = {
  userEmail: string,
  fieldType: string,
  id: string,
};

export type CreateSharePermissionsInput = {
  id?: string | null,
  fieldType: string,
  userEmail?: string | null,
  courseID?: string | null,
  textID?: string | null,
  pageID?: string | null,
  componentID?: string | null,
  accessCode?: string | null,
  startAccess?: string | null,
  endAccess?: string | null,
  conditionalAccessType?: string | null,
  conditionalAccessData?: string | null,
  isActive?: boolean | null,
  meta?: string | null,
};

export type ModelSharePermissionsConditionInput = {
  userEmail?: ModelStringInput | null,
  courseID?: ModelStringInput | null,
  textID?: ModelStringInput | null,
  pageID?: ModelStringInput | null,
  componentID?: ModelStringInput | null,
  accessCode?: ModelStringInput | null,
  startAccess?: ModelStringInput | null,
  endAccess?: ModelStringInput | null,
  conditionalAccessType?: ModelStringInput | null,
  conditionalAccessData?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelSharePermissionsConditionInput | null > | null,
  or?: Array< ModelSharePermissionsConditionInput | null > | null,
  not?: ModelSharePermissionsConditionInput | null,
};

export type SharePermissions = {
  __typename: "SharePermissions",
  id?: string,
  fieldType?: string,
  userEmail?: string | null,
  courseID?: string | null,
  textID?: string | null,
  pageID?: string | null,
  componentID?: string | null,
  accessCode?: string | null,
  startAccess?: string | null,
  endAccess?: string | null,
  conditionalAccessType?: string | null,
  conditionalAccessData?: string | null,
  isActive?: boolean | null,
  meta?: string | null,
  createdAt?: string,
  updatedAt?: string,
  owner?: string | null,
};

export type UpdateSharePermissionsInput = {
  id: string,
  fieldType: string,
  userEmail?: string | null,
  courseID?: string | null,
  textID?: string | null,
  pageID?: string | null,
  componentID?: string | null,
  accessCode?: string | null,
  startAccess?: string | null,
  endAccess?: string | null,
  conditionalAccessType?: string | null,
  conditionalAccessData?: string | null,
  isActive?: boolean | null,
  meta?: string | null,
};

export type DeleteSharePermissionsInput = {
  id: string,
  fieldType: string,
};

export type CreateTextRecognitionInput = {
  id?: string | null,
  fieldType: string,
  character: string,
  userEmail: string,
  included?: boolean | null,
  orignalPaths?: Array< string | null > | null,
  dateIndex?: string | null,
  meta?: string | null,
  owner?: string | null,
};

export type ModelTextRecognitionConditionInput = {
  userEmail?: ModelStringInput | null,
  included?: ModelBooleanInput | null,
  orignalPaths?: ModelStringInput | null,
  dateIndex?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelTextRecognitionConditionInput | null > | null,
  or?: Array< ModelTextRecognitionConditionInput | null > | null,
  not?: ModelTextRecognitionConditionInput | null,
};

export type TextRecognition = {
  __typename: "TextRecognition",
  id?: string,
  fieldType?: string,
  character?: string,
  userEmail?: string,
  included?: boolean | null,
  orignalPaths?: Array< string | null > | null,
  dateIndex?: string | null,
  meta?: string | null,
  owner?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTextRecognitionInput = {
  id: string,
  fieldType: string,
  character: string,
  userEmail?: string | null,
  included?: boolean | null,
  orignalPaths?: Array< string | null > | null,
  dateIndex?: string | null,
  meta?: string | null,
  owner?: string | null,
};

export type DeleteTextRecognitionInput = {
  fieldType: string,
  character: string,
  id: string,
};

export type ModelCodeLogTableFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  issue?: ModelStringInput | null,
  resolution?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelCodeLogTableFilterInput | null > | null,
  or?: Array< ModelCodeLogTableFilterInput | null > | null,
  not?: ModelCodeLogTableFilterInput | null,
};

export type ModelCodeLogTableConnection = {
  __typename: "ModelCodeLogTableConnection",
  items?:  Array<CodeLogTable | null > | null,
  nextToken?: string | null,
};

export type ModelUserPermissionsPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
  le?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
  lt?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
  ge?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
  gt?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
  between?: Array< ModelUserPermissionsPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUserPermissionsPrimaryCompositeKeyInput | null,
};

export type ModelUserPermissionsPrimaryCompositeKeyInput = {
  fieldType?: string | null,
  id?: string | null,
};

export type ModelUserPermissionsFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  userEmail?: ModelIDInput | null,
  item_type?: ModelStringInput | null,
  editable_item?: ModelIDInput | null,
  permission?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  courseID?: ModelIDInput | null,
  role?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  nrVisits?: ModelIntInput | null,
  and?: Array< ModelUserPermissionsFilterInput | null > | null,
  or?: Array< ModelUserPermissionsFilterInput | null > | null,
  not?: ModelUserPermissionsFilterInput | null,
};

export type ModelUserPermissionsConnection = {
  __typename: "ModelUserPermissionsConnection",
  items?:  Array<UserPermissions | null > | null,
  nextToken?: string | null,
};

export type ModelGroupFormationPrimaryCompositeKeyConditionInput = {
  eq?: ModelGroupFormationPrimaryCompositeKeyInput | null,
  le?: ModelGroupFormationPrimaryCompositeKeyInput | null,
  lt?: ModelGroupFormationPrimaryCompositeKeyInput | null,
  ge?: ModelGroupFormationPrimaryCompositeKeyInput | null,
  gt?: ModelGroupFormationPrimaryCompositeKeyInput | null,
  between?: Array< ModelGroupFormationPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelGroupFormationPrimaryCompositeKeyInput | null,
};

export type ModelGroupFormationPrimaryCompositeKeyInput = {
  fieldType?: string | null,
  id?: string | null,
};

export type ModelGroupFormationFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  userEmail?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  groupID?: ModelIDInput | null,
  role?: ModelStringInput | null,
  assignmentID?: ModelStringInput | null,
  and?: Array< ModelGroupFormationFilterInput | null > | null,
  or?: Array< ModelGroupFormationFilterInput | null > | null,
  not?: ModelGroupFormationFilterInput | null,
};

export type ModelGroupFormationConnection = {
  __typename: "ModelGroupFormationConnection",
  items?:  Array<GroupFormation | null > | null,
  nextToken?: string | null,
};

export type ModelCoursePrimaryCompositeKeyConditionInput = {
  eq?: ModelCoursePrimaryCompositeKeyInput | null,
  le?: ModelCoursePrimaryCompositeKeyInput | null,
  lt?: ModelCoursePrimaryCompositeKeyInput | null,
  ge?: ModelCoursePrimaryCompositeKeyInput | null,
  gt?: ModelCoursePrimaryCompositeKeyInput | null,
  between?: Array< ModelCoursePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelCoursePrimaryCompositeKeyInput | null,
};

export type ModelCoursePrimaryCompositeKeyInput = {
  fieldType?: FieldType | null,
  userEmail?: string | null,
  id?: string | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  fieldType?: ModelFieldTypeInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  long_description?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  userEmail?: ModelStringInput | null,
  co_editors?: ModelStringInput | null,
  co_pictureURL?: ModelStringInput | null,
  co_includeText?: ModelBooleanInput | null,
  co_textbookID?: ModelIDInput | null,
  co_includeAssignments?: ModelBooleanInput | null,
  co_assignmentBook?: ModelIDInput | null,
  co_includeLab?: ModelBooleanInput | null,
  co_laboratoryID?: ModelIDInput | null,
  co_questionBankID?: ModelIDInput | null,
  co_includeNotebook?: ModelBooleanInput | null,
  assign_data?: ModelStringInput | null,
  reg_initDate?: ModelStringInput | null,
  reg_expiration?: ModelStringInput | null,
  reg_role?: ModelStringInput | null,
  note_bookID?: ModelStringInput | null,
  ttlExpireTime?: ModelIntInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelFieldTypeInput = {
  eq?: FieldType | null,
  ne?: FieldType | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items?:  Array<Course | null > | null,
  nextToken?: string | null,
};

export type ModelSubmissionsPrimaryCompositeKeyConditionInput = {
  eq?: ModelSubmissionsPrimaryCompositeKeyInput | null,
  le?: ModelSubmissionsPrimaryCompositeKeyInput | null,
  lt?: ModelSubmissionsPrimaryCompositeKeyInput | null,
  ge?: ModelSubmissionsPrimaryCompositeKeyInput | null,
  gt?: ModelSubmissionsPrimaryCompositeKeyInput | null,
  between?: Array< ModelSubmissionsPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelSubmissionsPrimaryCompositeKeyInput | null,
};

export type ModelSubmissionsPrimaryCompositeKeyInput = {
  searchID?: string | null,
  userEmail?: string | null,
};

export type ModelSubmissionsFilterInput = {
  searchID?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  fieldType?: ModelSubmissionTypeInput | null,
  data?: ModelStringInput | null,
  seed?: ModelFloatInput | null,
  gradeOverride?: ModelFloatInput | null,
  earnedPercent?: ModelFloatInput | null,
  owner?: ModelStringInput | null,
  ttlExpireTime?: ModelIntInput | null,
  and?: Array< ModelSubmissionsFilterInput | null > | null,
  or?: Array< ModelSubmissionsFilterInput | null > | null,
  not?: ModelSubmissionsFilterInput | null,
};

export type ModelSubmissionsConnection = {
  __typename: "ModelSubmissionsConnection",
  items?:  Array<Submissions | null > | null,
  nextToken?: string | null,
};

export type ModelMarketingEmailListsPrimaryCompositeKeyConditionInput = {
  eq?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
  le?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
  lt?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
  ge?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
  gt?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
  between?: Array< ModelMarketingEmailListsPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelMarketingEmailListsPrimaryCompositeKeyInput | null,
};

export type ModelMarketingEmailListsPrimaryCompositeKeyInput = {
  fieldType?: string | null,
  id?: string | null,
};

export type ModelMarketingEmailListsFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  userEmail?: ModelIDInput | null,
  contactDates?: ModelStringInput | null,
  optedOut?: ModelBooleanInput | null,
  optedOutDate?: ModelStringInput | null,
  optedOutIPaddress?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelMarketingEmailListsFilterInput | null > | null,
  or?: Array< ModelMarketingEmailListsFilterInput | null > | null,
  not?: ModelMarketingEmailListsFilterInput | null,
};

export type ModelMarketingEmailListsConnection = {
  __typename: "ModelMarketingEmailListsConnection",
  items?:  Array<MarketingEmailLists | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSharePermissionsFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  courseID?: ModelStringInput | null,
  textID?: ModelStringInput | null,
  pageID?: ModelStringInput | null,
  componentID?: ModelStringInput | null,
  accessCode?: ModelStringInput | null,
  startAccess?: ModelStringInput | null,
  endAccess?: ModelStringInput | null,
  conditionalAccessType?: ModelStringInput | null,
  conditionalAccessData?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelSharePermissionsFilterInput | null > | null,
  or?: Array< ModelSharePermissionsFilterInput | null > | null,
  not?: ModelSharePermissionsFilterInput | null,
};

export type ModelSharePermissionsConnection = {
  __typename: "ModelSharePermissionsConnection",
  items?:  Array<SharePermissions | null > | null,
  nextToken?: string | null,
};

export type ModelTextRecognitionPrimaryCompositeKeyConditionInput = {
  eq?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
  le?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
  lt?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
  ge?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
  gt?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
  between?: Array< ModelTextRecognitionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelTextRecognitionPrimaryCompositeKeyInput | null,
};

export type ModelTextRecognitionPrimaryCompositeKeyInput = {
  character?: string | null,
  id?: string | null,
};

export type ModelTextRecognitionFilterInput = {
  id?: ModelIDInput | null,
  fieldType?: ModelStringInput | null,
  character?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  included?: ModelBooleanInput | null,
  orignalPaths?: ModelStringInput | null,
  dateIndex?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTextRecognitionFilterInput | null > | null,
  or?: Array< ModelTextRecognitionFilterInput | null > | null,
  not?: ModelTextRecognitionFilterInput | null,
};

export type ModelTextRecognitionConnection = {
  __typename: "ModelTextRecognitionConnection",
  items?:  Array<TextRecognition | null > | null,
  nextToken?: string | null,
};

export type ListTextbooks2QueryVariables = {
  textID?: string | null,
  fieldTypeId?: ModelTextbookPrimaryCompositeKeyConditionInput | null,
  filter?: ModelTextbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTextbooks2Query = {
  listTextbooks?:  {
    __typename: "ModelTextbookConnection",
    items?:  Array< {
      __typename: "Textbook",
      id: string,
      textID: string,
      name?: string | null,
      description?: string | null,
      long_description?: string | null,
      fieldType: TextType,
      txt_pictureURL?: string | null,
      lab_pictureURL?: string | null,
      toc_pageIndex?: string | null,
      page_isActive?: boolean | null,
      page_activeContent?:  {
        __typename: "PageContent",
        createdOn?: string | null,
        updatedOn?: string | null,
        creator?: string | null,
        content?: string | null,
      } | null,
      page_draftContent?:  {
        __typename: "PageContent",
        createdOn?: string | null,
        updatedOn?: string | null,
        creator?: string | null,
        content?: string | null,
      } | null,
      page_history?:  Array< {
        __typename: "PageContent",
        createdOn?: string | null,
        updatedOn?: string | null,
        creator?: string | null,
        content?: string | null,
      } | null > | null,
      asn_options?:  {
        __typename: "AssignmentOptions",
        isGroup?: boolean | null,
        formOwnGroups?: boolean | null,
        maxGroupMembers?: number | null,
        requiresGroupNotebook?: boolean | null,
        openOn?: string | null,
        dueOn?: string | null,
        isTimed?: boolean | null,
        timeLimit?: string | null,
        maxPoints?: number | null,
        maxAttempts?: number | null,
        catagory?: string | null,
        showSolutions?: boolean | null,
        showScoreBreakdown?: boolean | null,
        useHandwritingEntry?: boolean | null,
        randomizeQuestions?: boolean | null,
        randomizeAnswers?: boolean | null,
      } | null,
      owner?: string | null,
      ttlExpireTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateQuestionSubmissionMutationVariables = {
  courseID?: string,
  componentID?: string,
  questionID?: string,
  questionVersion?: string,
  questionPart?: number | null,
  entry?: string | null,
  scoreInPercent?: number | null,
  percentOfQuestion?: number | null,
};

export type UpdateQuestionSubmissionMutation = {
  updateQuestionSubmission?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubmissionAttemptMutationVariables = {
  courseID?: string,
  componentID?: string,
  questionID?: string,
  questionVersion?: string,
  questionPart?: number,
  entry?: string,
};

export type UpdateSubmissionAttemptMutation = {
  updateSubmissionAttempt?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCodeLogTableMutationVariables = {
  input?: CreateCodeLogTableInput,
  condition?: ModelCodeLogTableConditionInput | null,
};

export type CreateCodeLogTableMutation = {
  createCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCodeLogTableMutationVariables = {
  input?: UpdateCodeLogTableInput,
  condition?: ModelCodeLogTableConditionInput | null,
};

export type UpdateCodeLogTableMutation = {
  updateCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCodeLogTableMutationVariables = {
  input?: DeleteCodeLogTableInput,
  condition?: ModelCodeLogTableConditionInput | null,
};

export type DeleteCodeLogTableMutation = {
  deleteCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserPermissionsMutationVariables = {
  input?: CreateUserPermissionsInput,
  condition?: ModelUserPermissionsConditionInput | null,
};

export type CreateUserPermissionsMutation = {
  createUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPermissionsMutationVariables = {
  input?: UpdateUserPermissionsInput,
  condition?: ModelUserPermissionsConditionInput | null,
};

export type UpdateUserPermissionsMutation = {
  updateUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPermissionsMutationVariables = {
  input?: DeleteUserPermissionsInput,
  condition?: ModelUserPermissionsConditionInput | null,
};

export type DeleteUserPermissionsMutation = {
  deleteUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroupFormationMutationVariables = {
  input?: CreateGroupFormationInput,
  condition?: ModelGroupFormationConditionInput | null,
};

export type CreateGroupFormationMutation = {
  createGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGroupFormationMutationVariables = {
  input?: UpdateGroupFormationInput,
  condition?: ModelGroupFormationConditionInput | null,
};

export type UpdateGroupFormationMutation = {
  updateGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGroupFormationMutationVariables = {
  input?: DeleteGroupFormationInput,
  condition?: ModelGroupFormationConditionInput | null,
};

export type DeleteGroupFormationMutation = {
  deleteGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input?: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input?: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input?: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTextbookMutationVariables = {
  input?: CreateTextbookInput,
  condition?: ModelTextbookConditionInput | null,
};

export type CreateTextbookMutation = {
  createTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTextbookMutationVariables = {
  input?: UpdateTextbookInput,
  condition?: ModelTextbookConditionInput | null,
};

export type UpdateTextbookMutation = {
  updateTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTextbookMutationVariables = {
  input?: DeleteTextbookInput,
  condition?: ModelTextbookConditionInput | null,
};

export type DeleteTextbookMutation = {
  deleteTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubmissionsMutationVariables = {
  input?: CreateSubmissionsInput,
  condition?: ModelSubmissionsConditionInput | null,
};

export type CreateSubmissionsMutation = {
  createSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubmissionsMutationVariables = {
  input?: UpdateSubmissionsInput,
  condition?: ModelSubmissionsConditionInput | null,
};

export type UpdateSubmissionsMutation = {
  updateSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubmissionsMutationVariables = {
  input?: DeleteSubmissionsInput,
  condition?: ModelSubmissionsConditionInput | null,
};

export type DeleteSubmissionsMutation = {
  deleteSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMarketingEmailListsMutationVariables = {
  input?: CreateMarketingEmailListsInput,
  condition?: ModelMarketingEmailListsConditionInput | null,
};

export type CreateMarketingEmailListsMutation = {
  createMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMarketingEmailListsMutationVariables = {
  input?: UpdateMarketingEmailListsInput,
  condition?: ModelMarketingEmailListsConditionInput | null,
};

export type UpdateMarketingEmailListsMutation = {
  updateMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMarketingEmailListsMutationVariables = {
  input?: DeleteMarketingEmailListsInput,
  condition?: ModelMarketingEmailListsConditionInput | null,
};

export type DeleteMarketingEmailListsMutation = {
  deleteMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSharePermissionsMutationVariables = {
  input?: CreateSharePermissionsInput,
  condition?: ModelSharePermissionsConditionInput | null,
};

export type CreateSharePermissionsMutation = {
  createSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSharePermissionsMutationVariables = {
  input?: UpdateSharePermissionsInput,
  condition?: ModelSharePermissionsConditionInput | null,
};

export type UpdateSharePermissionsMutation = {
  updateSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSharePermissionsMutationVariables = {
  input?: DeleteSharePermissionsInput,
  condition?: ModelSharePermissionsConditionInput | null,
};

export type DeleteSharePermissionsMutation = {
  deleteSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTextRecognitionMutationVariables = {
  input?: CreateTextRecognitionInput,
  condition?: ModelTextRecognitionConditionInput | null,
};

export type CreateTextRecognitionMutation = {
  createTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTextRecognitionMutationVariables = {
  input?: UpdateTextRecognitionInput,
  condition?: ModelTextRecognitionConditionInput | null,
};

export type UpdateTextRecognitionMutation = {
  updateTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTextRecognitionMutationVariables = {
  input?: DeleteTextRecognitionInput,
  condition?: ModelTextRecognitionConditionInput | null,
};

export type DeleteTextRecognitionMutation = {
  deleteTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAssignmentSubmissionQueryVariables = {
  type?: string,
  courseID?: string,
  componentID?: string | null,
  questionID?: string | null,
  questionVersion?: string | null,
  nrProblemParts?: number | null,
};

export type GetAssignmentSubmissionQuery = {
  getAssignmentSubmission?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetBatchOfAssignmentSubmissionsQueryVariables = {
  courseID?: string,
  assignments?: Array< string | null > | null,
};

export type GetBatchOfAssignmentSubmissionsQuery = {
  getBatchOfAssignmentSubmissions?:  Array< {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetAllCourseAssignmentSubmissionsQueryVariables = {
  courseID?: string,
};

export type GetAllCourseAssignmentSubmissionsQuery = {
  getAllCourseAssignmentSubmissions?:  Array< {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetQuestionQueryVariables = {
  questionBankID?: string | null,
  questionID?: string | null,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetBatchOfQuestionQueryVariables = {
  questions?: Array< string | null > | null,
};

export type GetBatchOfQuestionQuery = {
  getBatchOfQuestion?:  Array< {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetCodeLogTableQueryVariables = {
  id?: string,
};

export type GetCodeLogTableQuery = {
  getCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCodeLogTablesQueryVariables = {
  id?: string | null,
  filter?: ModelCodeLogTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCodeLogTablesQuery = {
  listCodeLogTables?:  {
    __typename: "ModelCodeLogTableConnection",
    items?:  Array< {
      __typename: "CodeLogTable",
      id: string,
      fieldType: string,
      createdOn?: string | null,
      issue?: string | null,
      resolution?: string | null,
      status?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserPermissionsQueryVariables = {
  userEmail?: string,
  fieldType?: string,
  id?: string,
};

export type GetUserPermissionsQuery = {
  getUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPermissionssQueryVariables = {
  userEmail?: string | null,
  fieldTypeId?: ModelUserPermissionsPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPermissionssQuery = {
  listUserPermissionss?:  {
    __typename: "ModelUserPermissionsConnection",
    items?:  Array< {
      __typename: "UserPermissions",
      id: string,
      fieldType: string,
      userEmail: string,
      item_type?: string | null,
      editable_item?: string | null,
      permission?: string | null,
      createdBy?: string | null,
      courseID?: string | null,
      role?: string | null,
      profile?: string | null,
      lastLogin?: string | null,
      nrVisits?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetGroupFormationQueryVariables = {
  userEmail?: string,
  fieldType?: string,
  id?: string,
};

export type GetGroupFormationQuery = {
  getGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroupFormationsQueryVariables = {
  userEmail?: string | null,
  fieldTypeId?: ModelGroupFormationPrimaryCompositeKeyConditionInput | null,
  filter?: ModelGroupFormationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGroupFormationsQuery = {
  listGroupFormations?:  {
    __typename: "ModelGroupFormationConnection",
    items?:  Array< {
      __typename: "GroupFormation",
      id: string,
      fieldType: string,
      userEmail: string,
      courseID?: string | null,
      groupID?: string | null,
      role?: string | null,
      assignmentID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  courseID?: string,
  fieldType?: FieldType,
  userEmail?: string,
  id?: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  courseID?: string | null,
  fieldTypeUserEmailId?: ModelCoursePrimaryCompositeKeyConditionInput | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      courseID: string,
      fieldType: FieldType,
      name?: string | null,
      description?: string | null,
      long_description?: string | null,
      isActive?: boolean | null,
      userEmail: string,
      co_editors?: Array< string | null > | null,
      co_pictureURL?: string | null,
      co_includeText?: boolean | null,
      co_textbookID?: string | null,
      co_includeAssignments?: boolean | null,
      co_assignmentBook?: string | null,
      co_includeLab?: boolean | null,
      co_laboratoryID?: string | null,
      co_questionBankID?: string | null,
      co_includeNotebook?: boolean | null,
      assign_data?: string | null,
      reg_initDate?: string | null,
      reg_expiration?: string | null,
      reg_role?: string | null,
      note_bookID?: string | null,
      ttlExpireTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTextbookQueryVariables = {
  textID?: string,
  fieldType?: TextType,
  id?: string,
};

export type GetTextbookQuery = {
  getTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTextbooksQueryVariables = {
  textID?: string | null,
  fieldTypeId?: ModelTextbookPrimaryCompositeKeyConditionInput | null,
  filter?: ModelTextbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTextbooksQuery = {
  listTextbooks?:  {
    __typename: "ModelTextbookConnection",
    items?:  Array< {
      __typename: "Textbook",
      id: string,
      textID: string,
      name?: string | null,
      description?: string | null,
      long_description?: string | null,
      fieldType: TextType,
      txt_pictureURL?: string | null,
      lab_pictureURL?: string | null,
      toc_pageIndex?: string | null,
      page_isActive?: boolean | null,
      owner?: string | null,
      ttlExpireTime?: number | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSubmissionsQueryVariables = {
  courseID?: string,
  searchID?: string,
  userEmail?: string,
};

export type GetSubmissionsQuery = {
  getSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubmissionssQueryVariables = {
  courseID?: string | null,
  searchIDUserEmail?: ModelSubmissionsPrimaryCompositeKeyConditionInput | null,
  filter?: ModelSubmissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSubmissionssQuery = {
  listSubmissionss?:  {
    __typename: "ModelSubmissionsConnection",
    items?:  Array< {
      __typename: "Submissions",
      searchID: string,
      courseID: string,
      userEmail: string,
      fieldType?: SubmissionType | null,
      data?: string | null,
      seed?: number | null,
      gradeOverride?: number | null,
      earnedPercent?: number | null,
      owner?: string | null,
      ttlExpireTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMarketingEmailListsQueryVariables = {
  userEmail?: string,
  fieldType?: string,
  id?: string,
};

export type GetMarketingEmailListsQuery = {
  getMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMarketingEmailListssQueryVariables = {
  userEmail?: string | null,
  fieldTypeId?: ModelMarketingEmailListsPrimaryCompositeKeyConditionInput | null,
  filter?: ModelMarketingEmailListsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMarketingEmailListssQuery = {
  listMarketingEmailListss?:  {
    __typename: "ModelMarketingEmailListsConnection",
    items?:  Array< {
      __typename: "MarketingEmailLists",
      id: string,
      fieldType: string,
      userEmail: string,
      contactDates?: Array< string | null > | null,
      optedOut?: boolean | null,
      optedOutDate?: string | null,
      optedOutIPaddress?: string | null,
      meta?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSharePermissionsQueryVariables = {
  id?: string,
  fieldType?: string,
};

export type GetSharePermissionsQuery = {
  getSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSharePermissionssQueryVariables = {
  id?: string | null,
  fieldType?: ModelStringKeyConditionInput | null,
  filter?: ModelSharePermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSharePermissionssQuery = {
  listSharePermissionss?:  {
    __typename: "ModelSharePermissionsConnection",
    items?:  Array< {
      __typename: "SharePermissions",
      id: string,
      fieldType: string,
      userEmail?: string | null,
      courseID?: string | null,
      textID?: string | null,
      pageID?: string | null,
      componentID?: string | null,
      accessCode?: string | null,
      startAccess?: string | null,
      endAccess?: string | null,
      conditionalAccessType?: string | null,
      conditionalAccessData?: string | null,
      isActive?: boolean | null,
      meta?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTextRecognitionQueryVariables = {
  fieldType?: string,
  character?: string,
  id?: string,
};

export type GetTextRecognitionQuery = {
  getTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTextRecognitionsQueryVariables = {
  fieldType?: string | null,
  characterId?: ModelTextRecognitionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelTextRecognitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTextRecognitionsQuery = {
  listTextRecognitions?:  {
    __typename: "ModelTextRecognitionConnection",
    items?:  Array< {
      __typename: "TextRecognition",
      id: string,
      fieldType: string,
      character: string,
      userEmail: string,
      included?: boolean | null,
      orignalPaths?: Array< string | null > | null,
      dateIndex?: string | null,
      meta?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByFieldTypeAndDateQueryVariables = {
  fieldType?: string | null,
  createdOn?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCodeLogTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByFieldTypeAndDateQuery = {
  byFieldTypeAndDate?:  {
    __typename: "ModelCodeLogTableConnection",
    items?:  Array< {
      __typename: "CodeLogTable",
      id: string,
      fieldType: string,
      createdOn?: string | null,
      issue?: string | null,
      resolution?: string | null,
      status?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByStatusAndDateQueryVariables = {
  status?: string | null,
  createdOn?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCodeLogTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByStatusAndDateQuery = {
  byStatusAndDate?:  {
    __typename: "ModelCodeLogTableConnection",
    items?:  Array< {
      __typename: "CodeLogTable",
      id: string,
      fieldType: string,
      createdOn?: string | null,
      issue?: string | null,
      resolution?: string | null,
      status?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByEditableItemQueryVariables = {
  editable_item?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPermissionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByEditableItemQuery = {
  byEditableItem?:  {
    __typename: "ModelUserPermissionsConnection",
    items?:  Array< {
      __typename: "UserPermissions",
      id: string,
      fieldType: string,
      userEmail: string,
      item_type?: string | null,
      editable_item?: string | null,
      permission?: string | null,
      createdBy?: string | null,
      courseID?: string | null,
      role?: string | null,
      profile?: string | null,
      lastLogin?: string | null,
      nrVisits?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByGroupIdQueryVariables = {
  groupID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFormationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByGroupIdQuery = {
  byGroupID?:  {
    __typename: "ModelGroupFormationConnection",
    items?:  Array< {
      __typename: "GroupFormation",
      id: string,
      fieldType: string,
      userEmail: string,
      courseID?: string | null,
      groupID?: string | null,
      role?: string | null,
      assignmentID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByAssignmentIdQueryVariables = {
  courseID?: string | null,
  assignmentID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFormationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByAssignmentIdQuery = {
  byAssignmentID?:  {
    __typename: "ModelGroupFormationConnection",
    items?:  Array< {
      __typename: "GroupFormation",
      id: string,
      fieldType: string,
      userEmail: string,
      courseID?: string | null,
      groupID?: string | null,
      role?: string | null,
      assignmentID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByCourseFieldTypeQueryVariables = {
  fieldType?: FieldType | null,
  userEmail?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCourseFieldTypeQuery = {
  byCourseFieldType?:  {
    __typename: "ModelCourseConnection",
    items?:  Array< {
      __typename: "Course",
      id: string,
      courseID: string,
      fieldType: FieldType,
      name?: string | null,
      description?: string | null,
      long_description?: string | null,
      isActive?: boolean | null,
      userEmail: string,
      co_editors?: Array< string | null > | null,
      co_pictureURL?: string | null,
      co_includeText?: boolean | null,
      co_textbookID?: string | null,
      co_includeAssignments?: boolean | null,
      co_assignmentBook?: string | null,
      co_includeLab?: boolean | null,
      co_laboratoryID?: string | null,
      co_questionBankID?: string | null,
      co_includeNotebook?: boolean | null,
      assign_data?: string | null,
      reg_initDate?: string | null,
      reg_expiration?: string | null,
      reg_role?: string | null,
      note_bookID?: string | null,
      ttlExpireTime?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ByTextbookFieldTypeQueryVariables = {
  fieldType?: TextType | null,
  txt_pictureURL?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTextbookFieldTypeQuery = {
  byTextbookFieldType?:  {
    __typename: "ModelTextbookConnection",
    items?:  Array< {
      __typename: "Textbook",
      id: string,
      textID: string,
      name?: string | null,
      description?: string | null,
      long_description?: string | null,
      fieldType: TextType,
      txt_pictureURL?: string | null,
      lab_pictureURL?: string | null,
      toc_pageIndex?: string | null,
      page_isActive?: boolean | null,
      owner?: string | null,
      ttlExpireTime?: number | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TextRecognitionByUserQueryVariables = {
  userEmail?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextRecognitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TextRecognitionByUserQuery = {
  textRecognitionByUser?:  {
    __typename: "ModelTextRecognitionConnection",
    items?:  Array< {
      __typename: "TextRecognition",
      id: string,
      fieldType: string,
      character: string,
      userEmail: string,
      included?: boolean | null,
      orignalPaths?: Array< string | null > | null,
      dateIndex?: string | null,
      meta?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TextRecognitionByDateQueryVariables = {
  fieldType?: string | null,
  dateIndex?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextRecognitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TextRecognitionByDateQuery = {
  textRecognitionByDate?:  {
    __typename: "ModelTextRecognitionConnection",
    items?:  Array< {
      __typename: "TextRecognition",
      id: string,
      fieldType: string,
      character: string,
      userEmail: string,
      included?: boolean | null,
      orignalPaths?: Array< string | null > | null,
      dateIndex?: string | null,
      meta?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SubscribeSubmissionSubscriptionVariables = {
  courseID?: string,
};

export type SubscribeSubmissionSubscription = {
  subscribeSubmission?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SubscribeSubmissionAttemptSubscriptionVariables = {
  courseID?: string,
};

export type SubscribeSubmissionAttemptSubscription = {
  subscribeSubmissionAttempt?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCodeLogTableSubscription = {
  onCreateCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCodeLogTableSubscription = {
  onUpdateCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCodeLogTableSubscription = {
  onDeleteCodeLogTable?:  {
    __typename: "CodeLogTable",
    id: string,
    fieldType: string,
    createdOn?: string | null,
    issue?: string | null,
    resolution?: string | null,
    status?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserPermissionsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateUserPermissionsSubscription = {
  onCreateUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserPermissionsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateUserPermissionsSubscription = {
  onUpdateUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserPermissionsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteUserPermissionsSubscription = {
  onDeleteUserPermissions?:  {
    __typename: "UserPermissions",
    id: string,
    fieldType: string,
    userEmail: string,
    item_type?: string | null,
    editable_item?: string | null,
    permission?: string | null,
    createdBy?: string | null,
    courseID?: string | null,
    role?: string | null,
    profile?: string | null,
    lastLogin?: string | null,
    nrVisits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGroupFormationSubscriptionVariables = {
  userEmail?: string | null,
};

export type OnCreateGroupFormationSubscription = {
  onCreateGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGroupFormationSubscriptionVariables = {
  userEmail?: string | null,
};

export type OnUpdateGroupFormationSubscription = {
  onUpdateGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGroupFormationSubscriptionVariables = {
  userEmail?: string | null,
};

export type OnDeleteGroupFormationSubscription = {
  onDeleteGroupFormation?:  {
    __typename: "GroupFormation",
    id: string,
    fieldType: string,
    userEmail: string,
    courseID?: string | null,
    groupID?: string | null,
    role?: string | null,
    assignmentID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseSubscriptionVariables = {
  owner?: string | null,
  co_editors?: string | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseSubscriptionVariables = {
  owner?: string | null,
  co_editors?: string | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseSubscriptionVariables = {
  owner?: string | null,
  co_editors?: string | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    courseID: string,
    fieldType: FieldType,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    availibility?:  {
      __typename: "Availibility",
      restrictionType?: AvailibilityRestrictionType | null,
      inputCode?: string | null,
      userEmailMatch?: string | null,
      userEmailList?: Array< string | null > | null,
      cost?: number | null,
      stripeProductID?: string | null,
      stripePriceID?: string | null,
      restrictToDates?: boolean | null,
      showOn?: string | null,
      openOn?: string | null,
      closeOn?: string | null,
      hideOn?: string | null,
    } | null,
    isActive?: boolean | null,
    userEmail: string,
    co_editors?: Array< string | null > | null,
    co_pictureURL?: string | null,
    co_includeText?: boolean | null,
    co_textbookID?: string | null,
    co_includeAssignments?: boolean | null,
    co_assignmentBook?: string | null,
    co_includeLab?: boolean | null,
    co_laboratoryID?: string | null,
    co_questionBankID?: string | null,
    co_includeNotebook?: boolean | null,
    co_topics?:  Array< {
      __typename: "Topics",
      name?: string | null,
      id?: string | null,
    } | null > | null,
    assign_data?: string | null,
    reg_initDate?: string | null,
    reg_expiration?: string | null,
    reg_receipt?:  {
      __typename: "Receipt",
      registeredOn: string,
      wasFree?: boolean | null,
      wasCharged?: boolean | null,
      hasPaid?: boolean | null,
      cost?: number | null,
      discountApplied?: number | null,
      amountPaid?: number | null,
      transactionID?: string | null,
    } | null,
    reg_role?: string | null,
    note_bookID?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTextbookSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTextbookSubscription = {
  onCreateTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTextbookSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTextbookSubscription = {
  onUpdateTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTextbookSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTextbookSubscription = {
  onDeleteTextbook?:  {
    __typename: "Textbook",
    id: string,
    textID: string,
    name?: string | null,
    description?: string | null,
    long_description?: string | null,
    fieldType: TextType,
    txt_pictureURL?: string | null,
    lab_pictureURL?: string | null,
    toc_pageIndex?: string | null,
    page_isActive?: boolean | null,
    page_activeContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_draftContent?:  {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null,
    page_history?:  Array< {
      __typename: "PageContent",
      createdOn?: string | null,
      updatedOn?: string | null,
      creator?: string | null,
      content?: string | null,
    } | null > | null,
    asn_options?:  {
      __typename: "AssignmentOptions",
      isGroup?: boolean | null,
      formOwnGroups?: boolean | null,
      maxGroupMembers?: number | null,
      requiresGroupNotebook?: boolean | null,
      openOn?: string | null,
      dueOn?: string | null,
      isTimed?: boolean | null,
      timeLimit?: string | null,
      maxPoints?: number | null,
      maxAttempts?: number | null,
      catagory?: string | null,
      showSolutions?: boolean | null,
      showScoreBreakdown?: boolean | null,
      useHandwritingEntry?: boolean | null,
      randomizeQuestions?: boolean | null,
      randomizeAnswers?: boolean | null,
    } | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSubmissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateSubmissionsSubscription = {
  onCreateSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubmissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateSubmissionsSubscription = {
  onUpdateSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubmissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteSubmissionsSubscription = {
  onDeleteSubmissions?:  {
    __typename: "Submissions",
    searchID: string,
    courseID: string,
    userEmail: string,
    fieldType?: SubmissionType | null,
    data?: string | null,
    seed?: number | null,
    gradeOverride?: number | null,
    earnedPercent?: number | null,
    owner?: string | null,
    ttlExpireTime?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMarketingEmailListsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnCreateMarketingEmailListsSubscription = {
  onCreateMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMarketingEmailListsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnUpdateMarketingEmailListsSubscription = {
  onUpdateMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMarketingEmailListsSubscriptionVariables = {
  createdBy?: string | null,
};

export type OnDeleteMarketingEmailListsSubscription = {
  onDeleteMarketingEmailLists?:  {
    __typename: "MarketingEmailLists",
    id: string,
    fieldType: string,
    userEmail: string,
    contactDates?: Array< string | null > | null,
    optedOut?: boolean | null,
    optedOutDate?: string | null,
    optedOutIPaddress?: string | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSharePermissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateSharePermissionsSubscription = {
  onCreateSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSharePermissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateSharePermissionsSubscription = {
  onUpdateSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSharePermissionsSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteSharePermissionsSubscription = {
  onDeleteSharePermissions?:  {
    __typename: "SharePermissions",
    id: string,
    fieldType: string,
    userEmail?: string | null,
    courseID?: string | null,
    textID?: string | null,
    pageID?: string | null,
    componentID?: string | null,
    accessCode?: string | null,
    startAccess?: string | null,
    endAccess?: string | null,
    conditionalAccessType?: string | null,
    conditionalAccessData?: string | null,
    isActive?: boolean | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTextRecognitionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTextRecognitionSubscription = {
  onCreateTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTextRecognitionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTextRecognitionSubscription = {
  onUpdateTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTextRecognitionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTextRecognitionSubscription = {
  onDeleteTextRecognition?:  {
    __typename: "TextRecognition",
    id: string,
    fieldType: string,
    character: string,
    userEmail: string,
    included?: boolean | null,
    orignalPaths?: Array< string | null > | null,
    dateIndex?: string | null,
    meta?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
