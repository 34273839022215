<template>
  <Suspense>
    <template #default>
      <component :is="findComponentType(component.type)"
                 :content="component" />
    </template>
    <template #fallback>
      <b-loading label="Loading Page Component... " />
    </template>
  </Suspense>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import useComponents from "../../use/usePageComponents";
  import MarkdownComponent from "../pageComponents/MarkdownComponent.vue";
  import BabylonComponent from "../pageComponents/BabylonComponent.vue";
  import VideoComponent from "../pageComponents/VideoComponent.vue";
  import ImageComponent from "../pageComponents/ImageComponent.vue";
  import QuizComponent from "../pageComponents/QuizComponent.vue";
  import { PageComponent } from "./pages.interface";
  import BLoading from "@/components/bootstrap/b-loading.vue";

  export default defineComponent({
    components: {
      BabylonComponent,
      MarkdownComponent,
      VideoComponent,
      ImageComponent,
      QuizComponent,
      BLoading,
    },
    props: {
      component: {
        type: Object as PropType<PageComponent>,
        required: true,
      },
    },
    setup() {
      const {
        findComponentType,
      } = useComponents();

      return {
        findComponentType,
      };
    },
  });
</script>

<style scoped>
</style>