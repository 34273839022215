<template>
  <div class="d-flex flexCanvasContainer">
    <div class="canvasContainer flex-grow-1">
      <div class="flex-shrink-0 w-100">
        <NotebookToolbar v-if="scope"
                         :allow-discard="allowDiscard"
                         :allow-commit="allowCommit"
                         @activeToolNr="activateTool"
                         @brushColor="setBrushColor"
                         @brushWidth="setBrushSize"
                         @discard="discardChanges"
                         @commit="commitChanges" />
      </div>
      <canvas id="myCanvas"
              ref="canvasRef"
              class="canvas-style m-0 p-0"
              resize="true"
              @mousedown="captureOnlyPencil" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, Ref, ref, watch } from 'vue'
  import * as paper from 'paper';
  import usePaperJS from '../usePaperJS';
  import NotebookToolbar from './NotebookToolbar.vue';

  export default defineComponent({
    components: { NotebookToolbar },
    props: {
      allowCommit: {
        type: Boolean,
        default: true
      },
      allowDiscard: {
        type: Boolean,
        default: true
      }
    },
    emits: ["update:uncommittedLayer"],
    setup(props, { emit }) {
      console.log("Setup Notebook");

      const canvasRef: Ref<HTMLCanvasElement | null> = ref(null);
      const scope: Ref<paper.PaperScope | null> = ref(null);

      const {
        createTools,
        captureOnlyPencil,
        activateTool,
        setBrushColor,
        setBrushSize,
        setScope,
        discardChanges,
        commitChanges,
        uncommittedLayer,
        mostRecentStroke
      } = usePaperJS(new paper.Size({ width: 1000, height: 800 }));

      watch(mostRecentStroke, () => {
        emit("update:uncommittedLayer", uncommittedLayer);
      });

      onMounted(() => {
        console.log("Mount Notebook.")
        scope.value = new paper.PaperScope();
        scope.value?.setup("myCanvas");
        setScope(scope.value);
        createTools();
        activateTool(0);
      });

      return {
        scope,
        discardChanges,
        commitChanges,
        setBrushColor,
        setBrushSize,
        activateTool,
        canvasRef,
        captureOnlyPencil
      }
    }
  })
</script>

<style scoped>
  .flexCanvasContainer {
    position: relative;
    max-width: 100%;
    width: 100%;
  }

  .canvasContainer {
    box-shadow: 0 10px 8px -8px black;
    overflow: hidden;
    display: block;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-image: radial-gradient(#2e2f30 0px, #fff 1px);
    background-position: 0 0;
    background-size: 25px 25px;
  }

  .canvas-style {
    cursor: crosshair;
    display: block;
    aspect-ratio: 1;
  }

  canvas[resize] {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>