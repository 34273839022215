import { FluidDyanmicsEquations } from "@/babylon/properties/FrictionFactorEquations";
import { CylinderDrag } from "@/babylon/properties/Geometries_Drag";
import math, { AccessorNodeDependencies, MathNode, Unit } from "mathjs"
import { MjsMath } from "../math-core";
import { mjsUnknown } from "../math-types";
import { VariableTable } from "../mathjs-variable-table";
import { TypeGuard } from "../Type-guards";

function constant(a: number | Unit) {
   const mjs = MjsMath.getMathJS();
   return new mjs.ConstantNode(a);
}

function add(a: MathNode, b: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.OperatorNode("+", "add", [a, b]);
}

function subtract(a: MathNode, b: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.OperatorNode("-", "subtract", [a, b]);
}

function multiply(a: MathNode, b: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.OperatorNode("*", "multiply", [a, b]);
}

function divide(a: MathNode, b: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.OperatorNode("/", "divide", [a, b]);
}

function sqrt(a: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.FunctionNode(new mjs.SymbolNode("sqrt"), [a]);
}

function unary(fncName: string, a: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.FunctionNode(new mjs.SymbolNode(fncName), [a]);
}

function namedFunction(fncName: string, a: MathNode[]) {
   const mjs = MjsMath.getMathJS();
   return new mjs.FunctionNode(new mjs.SymbolNode(fncName), a);
}

function access(a: MathNode, index: number) {
   const mjs = MjsMath.getMathJS();
   const constIndex = new mjs.ConstantNode(index)
   const ind = new mjs.IndexNode([constIndex])
   return new mjs.AccessorNode(a, ind);
}

function vector(x: MathNode, y: MathNode, z: MathNode) {
   const mjs = MjsMath.getMathJS();
   return new mjs.ArrayNode([x, y, z]);
}

function _dot(args: MathNode[]) {
   return add(add(multiply(access(args[0], 1), access(args[1], 1)),
      multiply(access(args[0], 2), access(args[1], 2))),
      multiply(access(args[0], 3), access(args[1], 3)));
}

function _cross(args: MathNode[]) {
   const x1 = access(args[0], 1);
   const y1 = access(args[0], 2);
   const z1 = access(args[0], 3);
   const x2 = access(args[1], 1);
   const y2 = access(args[1], 2);
   const z2 = access(args[1], 3);
   const t1 = subtract(multiply(y1, z2), multiply(z1, y2));
   const t2 = subtract(multiply(z1, x2), multiply(x1, z2));
   const t3 = subtract(multiply(x1, y2), multiply(y1, x2));
   return vector(t1, t2, t3);
}

function _magnitude(node: MathNode) {
   const magSqr: MathNode = _dot([node, node]);
   return sqrt(magSqr);
}

export const extendedMathJSFunctions: Record<string, (args: MathNode[], scope: Record<string, mjsUnknown>) => MathNode> = {
   // Compute the Reynolds number given:
   // rho - density            kg/m^3
   // V - velocity             m/s
   // D - Diameter             m
   // mu - dynamic viscosity   N s/m^2
   ReynoldsNr: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return divide(multiply(multiply(args[0], args[1]), args[2]), args[3]);
   },

   // Compute the magnitude of a vector
   // mag - magnitude         Any
   mag: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return _magnitude(args[0]);
   },

   fromRadToDeg: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      console.log({ args })
      const theta = args[0];
      const radian = MjsMath.createUnit(360 / (2 * Math.PI), "degree");
      const rConst = constant(radian);
      return multiply(rConst, theta);
   },


   inDeg: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const theta = args[0];
      const radian = MjsMath.createUnit(1, "degree");
      const rConst = constant(radian);
      return multiply(theta, rConst);
   },

   // Compute a unit vector from a given vector
   // vector - vector         No Units allowed
   unitv: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const magnitude: MathNode = _magnitude(args[0]);
      return divide(args[0], magnitude);
   },

   // Create  a vector
   // Magnitude -            Any
   // Theta  -               Radians or Degrees
   vecFromMagTheta: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const mag = args[0];
      const theta = args[1];
      const xComp = unary("cos", theta);
      const yComp = unary("sin", theta);
      const zComp = constant(0);
      return multiply(mag, vector(xComp, yComp, zComp));
   },

   // Create  a vector
   // Magnitude -            Any
   // Azimuth Angle  -               Radians or Degrees
   // Elevation Angle  -               Radians or Degrees
   vecFromAzElevation: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const mag = args[0];
      const azimuth = args[1];
      const elevation = args[2];
      const xComp = multiply(unary("cos", azimuth), unary("cos", elevation));
      const yComp = multiply(unary("sin", azimuth), unary("cos", elevation));
      const zComp = unary("sin", elevation);
      return multiply(mag, vector(xComp, yComp, zComp));
   },


   // Create a vector
   // Magnitude
   // thetaX - angle made with x axis
   // thetaY - angle made with y axis
   // thetaZ - angle made with z axis
   vecFromMagCos: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const mag = args[0];
      const thetaX = args[1];
      const thetaY = args[2];
      const thetaZ = args[3];
      const xComp = multiply(mag, unary("cos", thetaX));
      const yComp = multiply(mag, unary("cos", thetaY));
      const zComp = multiply(mag, unary("cos", thetaZ));
      return vector(xComp, yComp, zComp);
   },

   // magnitude - Magnitude of the vector
   // vector - gives the direction (does not have to be a unit vector)
   vecFromMagAndDirVec: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const oldMagnitude: MathNode = _magnitude(args[1]);
      const dirVec = divide(args[1], oldMagnitude);

      const newMagnitude = args[0];
      return multiply(newMagnitude, dirVec); // extendedMathJSFunctions.unitv([dirVec]));
   },


   getX: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return access(args[0], 1);
   },

   getY: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return access(args[0], 2);
   },

   getZ: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return access(args[0], 3);
   },

   dotProd: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return _dot(args);
   },

   crossProd: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return _cross(args);
   },

   angleBetweenVectors: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const mag1 = _magnitude(args[0]);
      const mag2 = _magnitude(args[1]);
      const dot = _dot(args);
      return unary("acos", divide(dot, multiply(mag1, mag2)));
   },

   BranchLT: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      const actualValue = args[0];
      const cutoffValue = args[1];
      console.log(actualValue);
      console.log(cutoffValue);
      if (actualValue < cutoffValue) {
         return args[2];
      } else {
         return args[3];
      }
   },

   cylinderDragCoef: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return constant(CylinderDrag.getDrag(args[0] as any));
   },

   pumpCalculation: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return namedFunction("pumpCalculation", args);
   },

   frictionFactor: (args: MathNode[], scope: Record<string, mjsUnknown>): MathNode => {
      return namedFunction("frictionFactor", args);
   }

   // cUnit: (args: MathNode[]): MathNode => {
   //    const mjs = MjsMath.getMathJS();
   //    return new mjs.ConstantNode(1);
   // }
};

export const extendedNumericMathJSFunctions = {
   frictionFactor: (Re: number, relativeRoughness: number): number => {
      return FluidDyanmicsEquations.frictionFactor(Re, relativeRoughness);
   },

   pumpCalculation: (guessV: number, pumpA: number, pumpB: number, mu: any, rho: any, D: any, L: any, Ktotal: number, relativeRough: number): number => {
      mu = mu.value;
      rho = rho.value;
      D = D.value;
      L = L.value;

      let V = guessV;
      let fOld = 1000, diff, diffF;
      do {
         const Re = rho * guessV / mu;
         const A = 3.1415 * D * D / 4;
         let f;
         if (Re < 3000) {
            f = 64 / Re;
         } else {
            f = FluidDyanmicsEquations.frictionFactor(Re, relativeRough);
         }

         const Q = V * A;
         const Vsolve = Math.sqrt((2 * 9.81) * (-pumpA * Q + pumpB) / (f * L / D + Ktotal));
         diff = Math.abs((Vsolve - V) / Vsolve);
         diffF = Math.abs((fOld - f) / fOld);
         fOld = f;
         V = Vsolve;
      } while (diff > 0.0001 || diffF > 0.0001);

      return V;
   }
};

export const registeredFunctionNames = Object.keys(extendedMathJSFunctions);
