<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3>
          {{ error }}
        </h3>
      </div>
    </div>
    <div class="row g-0">
      <div class="col-12 col-md-4">
        <h2>
          {{ course.data().name }}
        </h2>
        <hr class="d-md-none">
      </div>
      <div class="col-12 col-md-8">
        <div class="container-fluid">
          <div class="row g-1">
            <PCardButtonBar :buttons="buttons"
                            bar-class="justify-content-end"
                            col-class="col-4 col-lg-3 col-md-4 col-sm-3 p-1"
                            @click="eventRouter($event, routes)" />
            <hr class="d-md-none">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="error === undefined"
       class=" container mt-3">
    <div class="row">
      <div>
        <BTable :data="assignments"
                :fields="assignFields"
                :is-scrollable="false"
                :updateInterval="20"
                class="p-1"
                @click="onAssignmentClick" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { bTableEvent } from "@/components/bootstrap/table/b-table.interface";
  import BTable from "@/components/bootstrap/table/b-table.vue";
  import { BaseEventI, BaseEventRoute } from "@/components/global/event.interface";
  import useEvents from "@/components/use/useEvents";
  import useTime from "@/components/use/useTime";
  import router from "@/router";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { CourseRecord } from "@/store/database/Course/record-course";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent, EmitsOptions, SetupContext } from "vue";
  import { Router } from "vue-router";
  import PCardButtonBar from "../components/p-cardButtonBar.vue";
  import { useDueDates } from "@/views/student/useDueDates";
  import { useGradedAssignments } from "./useGradedAssignments";
  import { SubmissionItem } from "@/store/database/Submission/submitted-item.interface";

  interface SetupReturnType {
    error: unknown;
    course: CourseRecord;
    registration: unknown;
    assignments: unknown;
    assignFields: unknown;
    userName: unknown;
    buttons: unknown;
    routes: BaseEventRoute[];
    eventRouter: (event: BaseEventI<string, Record<string, unknown>>, routes: BaseEventRoute[], router: Router) => void;
    onAssignmentClick: unknown;
  }

  export default defineComponent({
    components: { BTable, PCardButtonBar },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props, context: SetupContext<EmitsOptions>): Promise<SetupReturnType | null> {
      const { userName, isAdmin } = useUserStore();

      if (!userName) return null;

      const {
        error,
        course,
        registration,
        assignmentBook,
        textbook,
        laboratory,
      } = await useDatabaseMappings().getCourseMaterials(userName, props.courseId);
      console.debug("Course - Pulled Course Materials", course);


      const { computeRelativeDateString } = useTime();

      const assignFields = [
        {
          key: "name",
          label: "Assignment",
          class: "",
          includeInFilter: true,
          freezeColumn: true
        },
        {
          key: "openOn",
          label: "Opens On",
          class: "",
          formatter: (value: unknown) => {
            return computeRelativeDateString(value as string);
          },
          includeInFilter: true
        },
        {
          key: "dueOn",
          label: "Due On",
          class: "",
          formatter: (value: unknown) => {
            return computeRelativeDateString(value as string);
          },
          includeInFilter: true
        },
      ];

      const courseData = course?.data();
      if (!courseData) return null;

      const buttons = [
        {
          label: "Edit Course",
          icon: "bi-pencil",
          emitStr: "EditMenu",
          show: isAdmin
        },
        {
          label: "Textbook",
          icon: "bi-book",
          emitStr: "StudentTextbook",
          show: (Object.prototype.hasOwnProperty.call(courseData, "co_includeText") && courseData.co_includeText === true)

        },
        {
          label: "Laboratory",
          icon: "bi-tools",
          emitStr: "StudentLaboratory",
          show: (Object.prototype.hasOwnProperty.call(courseData, "co_includeLab") && courseData.co_includeLab === true)
        },
        {
          label: "Notebook",
          icon: "bi-journal-text",
          emitStr: "StudentNotebook",
          show: (Object.prototype.hasOwnProperty.call(courseData, "co_includeNotebook") && courseData.co_includeNotebook === true)
        },
        {
          label: "Progress",
          icon: "bi-table",
          emitStr: "StudentProgress",
          show: (Object.prototype.hasOwnProperty.call(courseData, "co_includeAssignments") && courseData.co_includeAssignments === true)
        },
      ];

      const { eventRouter } = useEvents(context);
      const routes = [
        {
          eventName: "StudentTextbook",
          routeName: "TextbookViewer",
          params: {
            courseId: props.courseId,
            textId: textbook?.getID(),
            rootPageId: textbook?.getRootPageID(),
            pageId: textbook?.getRootPageID(),
          },
        },
        {
          eventName: "StudentLaboratory",
          routeName: "LaboratoryList",
          params: {
            courseId: props.courseId,
            textId: laboratory?.getID(),
            rootPageId: laboratory?.getRootPageID(),
            pageId: laboratory?.getRootPageID(),
          },
        },
        {
          eventName: "StudentProgress",
          routeName: "StudentProgress",
          params: {
            courseId: props.courseId,
          },
        },
        {
          eventName: "EditMenu",
          routeName: "CourseEditMenu",
          params: {
            courseId: props.courseId,
          },
        },
      ] as BaseEventRoute[];

      const onAssignmentClick = (event: bTableEvent): void => {
        if (!assignmentBook) return;

        const rowData = (event.payload.rowData as { pageID: string, status: string });
        const status = (rowData.status as any)();

        if (status === "not_open" && !isAdmin)
          return;

        if (status !== "open" && !isAdmin) {
          // condition for opening

        }

        const textID = assignmentBook.getID();
        if (!textID) return;

        router.push({
          name: "AssignmentViewer",
          params: {
            courseId: props.courseId,
            textId: textID,
            rootPageId: rowData.pageID,
            pageId: rowData.pageID,
          },
        });
      };


      const { getStatus, getDueDates } = useDueDates();


      function getFormat(status: string) {
        switch (status) {
          case 'not_open':
            return 'future-assignment-row';
          case 'closed':
            return 'closed-assignment-row';
          case 'open':
            return 'open-assignment-row';
        }
      }

      console.log("Course - Pulling Submissions");
      const { getAssignmentToComponentMap,
        getStudentAssignments,
        createListOfComponents } = await useGradedAssignments(props.courseId, userName)
      createListOfComponents();

      const submissions = await getStudentAssignments();
      console.log({ submissions })

      console.log("Course - Pulling Assignments");

      let assignments: unknown[] = [];

      const listOfAssignments = assignmentBook?.getFirstLevelPages();
      listOfAssignments?.forEach((v) => {
        if (v.data().asn_options?.catagory === "Link") {
          return;
        }

        const compID = getAssignmentToComponentMap()[v.id()];
        const assignmentSub = submissions
          .find((s) => { return s.searchID === compID }) as unknown as SubmissionItem;
        const { openOn, dueOn } = getDueDates(v.data(), assignmentSub);

        const status = getStatus(v, assignmentSub)
        assignments.push({
          name: v.data().name,
          dueOn,
          openOn,
          pageID: v.id(),
          status: () => {
            return getStatus(v, assignmentSub)
          },
          _rowVariant: () => {
            const status2 = getStatus(v, assignmentSub)
            return getFormat(status2)
          }
        })
      });



      return {
        error,
        course: course as CourseRecord,
        registration,
        assignments,
        assignFields,
        userName,
        buttons,
        routes,
        eventRouter,
        onAssignmentClick,
      };
    },
  });
</script>

<style>
  .closed-assignment-row {
    background: rgb(180, 206, 255);
  }
  .open-assignment-row {
    background: rgb(192, 212, 156);
  }
  .future-assignment-row {
    background: rgb(236, 232, 194);
  }
</style>