import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';

export interface DynamoSearchKernal<T> {
    extraQueryData: any;
    distributeDBItems: (fetchedData: any, type: T, obj: DynamoSearchResults<T>) => void;
    getQueryData: (type: T, extraQueryData: any) => { query: any, queryData: Record<string, unknown> }
}

export abstract class DynamoSearchResults<T>{
    public isCompleted = false;
    public searchType: T | undefined;
    public searchKernal: DynamoSearchKernal<T>;

    constructor(searchKernal: DynamoSearchKernal<T>) {
        this.searchKernal = searchKernal;
    }

    abstract clear(): boolean;

    public runSearch(type: T) {
        this.searchType = type;

        this.clear();

        return new Promise<DynamoSearchResults<T>>((resolve) => {
            this.fetchContent(this.searchKernal, type)
                .then((fetchedData: any) => {
                    if (fetchedData) {
                        this.searchKernal.distributeDBItems(fetchedData, type, this);
                    }
                    resolve(this);
                });
        });
    }


    private async fetchContent(searchKernal: DynamoSearchKernal<T>, type: T) {
        const queryData: any = searchKernal.getQueryData(type, searchKernal.extraQueryData);
        queryData.queryData.nextToken = null;

        // dynamo has a limit on data per search call, therefore 
        // this function will make  multiple trips to the 
        // database to fetch until all of the data has been returned.
        try {
            let returnedData = null;
            do {
                const tmpData: any = await GraphQLAPI.graphql(graphqlOperation(queryData.query, queryData.queryData));
                const obj = tmpData.data;
                const key = Object.keys(obj)[0];
                queryData.queryData.nextToken = obj[key].nextToken;

                if (returnedData === null) { returnedData = tmpData; }
                else {
                    // join results 
                    const arr1 = returnedData.data[key].items;
                    const arr2 = obj[key].items;
                    returnedData.data[key].items.push.apply(arr1, arr2);
                }
            } while (queryData.queryData.nextToken !== null);

            console.debug("Fetch content success for type:", type);
            console.debug("Fetch search kernal:", searchKernal);
            console.debug("Fetch content data:", returnedData);

            return returnedData;
        } catch (error) {
            console.error("Fetch Content for type: ", type);
            console.error("Fetchsearch kernal: ", searchKernal);
            console.error("Fetch internal message", error);
            return null;
        }
    }
}