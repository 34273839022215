<template>
  <video controls>
    <source :src="src"
            type="video/mp4">
  </video>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from "vue";
  import { Page_VideoComponent } from "../pages/pages.interface";

  export default defineComponent({
    props: {
      content: {
        type: Object as PropType<Page_VideoComponent>,
        required: true,
      },
    },
    setup(props) {
      const src = ref(props.content.data.src);
      return {
        src,
      };
    },
  });
</script>

<style scoped>
</style>