import { MathNode } from "mathjs";
import { MjsMath } from "../math-core";
import { MathJSTree } from "../math-node-tree";
import { LookupTableType, MjsNode, mjsUnknown } from "../math-types";
import { VariableTable } from "../mathjs-variable-table";
import { TypeGuard } from "../Type-guards";
import { mjsParser } from "./parse";

interface FlatFraction { num: MjsNode[], den: MjsNode[] }


export class mjsSimplify {
   static simplify(expression: string, variableTable: Record<string, unknown>) {
      const parsed = mjsParser.parseEnforceBracketedUnits(expression, variableTable as Record<string, mjsUnknown>);
      const mjs = MjsMath.getMathJS();
      // const rules = [
      //    ...(mjs.simplify as unknown as { rules: { l: string, r: string }[] }).rules,
      //    ...[
      //       { l: "n / n", r: "1" },
      //       { l: "n / (n)", r: "1" },
      //       { l: "n^n1 / n^n2", r: "n^(n1 - n2)" }
      //    ]
      // ];
      // console.log(rules)
      //const simplified = mjs.simplify(parsed, rules);
      const simplified = mjs.simplify(parsed);
      const terms = this.simplifyTerms(simplified as MjsNode, variableTable, true);
      return terms;
   }

   static simplifyTerms(exp: MjsNode, variableTable: Record<string, unknown> | undefined = undefined, evalFunctions = false): MjsNode {
      const mjs = MjsMath.getMathJS();

      if (!TypeGuard.isNullOrUndefined(variableTable)) {
         exp = MathJSTree.replaceWithLookupInfo(exp, variableTable as LookupTableType);
      }

      if (evalFunctions) {
         exp = this.evaluateFunctions(exp);
      }

      if (exp.isOperatorNode) {
         if (exp.op === "+" || (exp.op === "-" && exp.args?.length === 2)) {
            // split into terms
            if (exp.args) {
               exp.args[0] = this.simplifyTerms(exp.args[0]);
               exp.args[1] = this.simplifyTerms(exp.args[1]);
            }

            const simplify1 = mjs.simplify(exp as MathNode) as MjsNode;
            const rationalize = mjs.rationalize(simplify1);
            return mjs.simplify(rationalize as unknown as MathNode) as MjsNode;

            //return mjs.simplify(exp as MathNode) as MjsNode;
         } else {
            const split = this.splitFraction(exp);
            const reduceSplit = this.reduceSplit(split);
            const rules = [
               //     ...(mjs.simplify as unknown as { rules: { l: string, r: string }[] }).rules,
               ...[
                  { l: "n / n", r: "1" },
                  { l: "n / (n)", r: "1" },
                  { l: "n^n1 / n^n2", r: "n^(n1 - n2)" },
                  { l: "n^n1 * n^n2", r: "n^(n1 + n2)" },
                  { l: "n^(n1) * n^(n2)", r: "n^(n1 + n2)" },
                  { l: "n^n1 / n", r: "n^(n1 - 1)" },
                  { l: "c*n^n1 / n", r: "c* n^(n1 - 1)" },
                  { l: "n1*(n2 + n3)", r: "n1 * n2 + n3 * n1" },
                  { l: "n1*(n2 - n3)", r: "n1 * n2 - n3 * n1" },
                  { l: "(n1 + n2)*n3", r: "n3 * n1 + n2 * n3" },
                  { l: "(n1 - n2)*n3", r: "n3 * n1 - n2 * n3" },
               ]
            ];
            // const simplified = mjs.simplify(reduceSplit as MathNode, rules) as MjsNode;
            // console.log("Simplify:", simplified.toString());
            // const rationalize = mjs.rationalize(simplified);
            // console.log("Rationalize:", rationalize.toString());
            // return rationalize as unknown as MjsNode;

            const simplified = mjs.simplify(reduceSplit as MathNode, rules) as MjsNode;
            const secondSimplify = mjs.simplify(simplified as unknown as MathNode) as MjsNode;
            console.debug("secondSimplify:", secondSimplify.toString());
            return secondSimplify;
         }
      }

      console.debug("Return Simplified Terms:", exp.toString())
      return exp;
   }

   public static ensureUnitHasNumericalValue(exp: MjsNode): MjsNode {
      const mjs = MjsMath.getMathJS();

      return exp.transform((node) => {
         if (node.isConstantNode) {
            if (node.value && TypeGuard.isUnit(node.value)) {
               if (node.value.value === null) {
                  node.value.value = 1;
               }
            }
         }

         return node;
      });
   }

   private static evaluateFunctions(exp: MjsNode): MjsNode {
      const mjs = MjsMath.getMathJS();

      return exp.transform((node) => {
         if (node.isFunctionNode) {
            const testEval = node.evaluate();
            if (TypeGuard.isUnit(testEval) || TypeGuard.isNumber(testEval)) {
               return new mjs.ConstantNode(testEval) as MjsNode;
            }
         }

         return node;
      });

   }

   private static splitFraction(exp: MjsNode): FlatFraction {
      // flatten multiplication
      let numeratorTerms: MjsNode[] = [];
      let denomenatorTerms: MjsNode[] = [];

      if (exp.op === "*" && exp.args) {
         const result1 = this.splitFraction(exp.args[0]);
         const result2 = this.splitFraction(exp.args[1]);
         numeratorTerms = [...numeratorTerms, ...result1.num, ...result2.num];
         denomenatorTerms = [...denomenatorTerms, ...result1.den, ...result2.den];
      } else if (exp.op === "/" && exp.args) {
         const result1 = this.splitFraction(exp.args[0]);
         const result2 = this.splitFraction(exp.args[1]);
         numeratorTerms = [...numeratorTerms, ...result1.num, ...result2.den];
         denomenatorTerms = [...denomenatorTerms, ...result1.den, ...result2.num];
      } else {
         numeratorTerms.push(exp);
      }

      return { num: numeratorTerms, den: denomenatorTerms };
   }


   private static reduceSplit(flatFraction: FlatFraction): MjsNode {
      const mjs = MjsMath.getMathJS();

      const remainder: FlatFraction = { num: [], den: [] };
      const constTerm = new mjs.ConstantNode(1);

      //remainder.num.push(constTerm);

      flatFraction.num.forEach((item) => {
         if (!this.hasSymbol(item)) {
            const val = item.evaluate() as unknown as number;
            constTerm.value = mjs.multiply(constTerm.value, val);
         } else {
            remainder.num.push(item);
         }
      });

      flatFraction.den.forEach((item) => {
         if (!this.hasSymbol(item)) {
            const val = item.evaluate() as unknown as number;
            constTerm.value = mjs.divide(constTerm.value, val);
         } else {
            remainder.den.push(item);
         }
      });

      //this.cancelTerms(remainder);
      let numTerm;
      let denTerm = null;

      if (remainder.den.length > 0) {
         denTerm = this.convertArrayToMultiplciation(remainder.den);
      }

      if (constTerm.value !== 1 || remainder.num.length === 0)
         remainder.num.unshift(constTerm);

      if (denTerm === null) {

         numTerm = this.convertArrayToMultiplciation(remainder.num) as MjsNode;
         return numTerm;
      } else {
         numTerm = this.convertArrayToMultiplciation(remainder.num) as MjsNode;

         return new mjs.OperatorNode('/', 'divide', [numTerm, denTerm]);
         //return new mjs.OperatorNode('*', 'multiply', [constTerm, fraction]);
      }
   }

   private static hasSymbol(exp: MathNode) {
      return exp.filter((node) => { return node.isSymbolNode }).length > 0;
   }

   private static cancelTerms(remainder: FlatFraction) {
      const num = remainder.num;
      const den = remainder.den;
      console.log("Cancel Terms")
      for (let i = 0; i < num.length; i++) {
         const term = num[i];
         if (term.isSymbolNode) {
            const varName = term.name;
            for (let j = 0; j < den.length; j++) {
               const secondTerm = den[j];
               if (secondTerm.isSymbolNode && secondTerm.name === varName) {
                  num.splice(i, 1);
                  den.splice(j, 1);
                  break;
               }
               // if (secondTerm.isOperatorNode && secondTerm.op === "^"){
               //    const powerBase = secondTerm.args[0];
               //    if (powerBase.isSymbolNode && powerBase.name === varName){

               //    }
               // }
            }
         }
      }

      return num;
   }

   private static convertArrayToMultiplciation(list: MjsNode[]): MjsNode | null {
      const mjs = MjsMath.getMathJS();

      if (list.length === 1) return list[0];

      let multNode: MjsNode | null = null;
      list.forEach((item, index, array) => {
         if (index > 0) {
            if (multNode === null) {
               multNode = new mjs.OperatorNode('*', 'multiply', [array[index - 1], array[index]]);
            } else {
               multNode = new mjs.OperatorNode('*', 'multiply', [multNode, array[index]]);
            }
         }
      });

      return multNode;
   }
}