import { MjsMath } from "./math-core";
import { MjsNode } from "./math-types";
import { TypeGuard } from "./Type-guards";


const functionalInverses: Record<string, string> = {
   "cos": "acos",
   "sin": "asin",
   "tan": "atan",
   "acos": "cos",
   "asin": "sin",
   "atan": "tan",
}

export function invertMJSNodes(node: MjsNode, otherSide: MjsNode): MjsNode | undefined {
   const mjs = MjsMath.getMathJS();

   if (node.parent === null) { return otherSide; }

   if (TypeGuard.isString(node.parent?.fn)) {
      switch (node.parent?.fn) {
         case "add": {
            const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
            return new mjs.OperatorNode('-', 'subtract', [invertMJSNodes(node.parent, otherSide), other])
         }
         case "subtract": {
            // check which arguement c - y or y - c
            if (node.indexInParentArg === 0) {
               const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
               return new mjs.OperatorNode('+', 'add', [invertMJSNodes(node.parent, otherSide), other])
            } else {
               const negConst = new mjs.ConstantNode(-1);
               const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
               return new mjs.OperatorNode('+', 'add', [new mjs.OperatorNode('*', 'multiply', [negConst, invertMJSNodes(node.parent, otherSide)]), other])
            }
         }
         case "multiply": {
            const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
            return new mjs.OperatorNode('/', 'divide', [invertMJSNodes(node.parent, otherSide), other])
         }
         case "divide": {
            // TODO check which arg. is it x/1 or 1/x
            const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
            return new mjs.OperatorNode('*', 'multiply', [invertMJSNodes(node.parent, otherSide), other])
         }
         case "unaryMinus": {
            const other = new mjs.ConstantNode(-1);
            return new mjs.OperatorNode('*', 'multiply', [invertMJSNodes(node.parent, otherSide), other])
         }
         case "unaryPlus": {
            return invertMJSNodes(node.parent, otherSide);
         }
         case "pow": {
            const other = getOtherChildFromParent(node.indexInParentArg, node.parent);
            const constant = new mjs.ConstantNode(1);
            const invPow = new mjs.OperatorNode('/', 'divide', [constant, other])
            return new mjs.FunctionNode('pow', [invertMJSNodes(node.parent, otherSide), invPow]);
         }
         default:
            console.log("Could not find string:", node.parent);
      }
   } else {
      const fnName = node.parent?.fn?.name;
      if (fnName && TypeGuard.hasProp(functionalInverses, fnName)) {
         return new mjs.FunctionNode(functionalInverses[fnName], [invertMJSNodes(node.parent, otherSide)]);
      } else {
         // parenthesis node
         if (TypeGuard.hasProp(node.parent, "content")) {
            return invertMJSNodes(node.parent, otherSide);
         }

      }


   }

   function getOtherChildFromParent(myIndex: number, parent: MjsNode): MjsNode | null {
      if (!parent.args) return null;
      return parent.args[(myIndex === 0) ? 1 : 0];
   }
}
