
  import { defineComponent, ref } from "vue";
  import draggable from "vuedraggable";

  export interface BDragListInterface {
    selectItem(index: number): void;
  }

  interface moveAction {
    moved: { newIndex: number; oldIndex: number; element: unknown };
  }
  export default defineComponent({
    name: "BDragList",
    components: {
      draggable,
    },
    props: {
      initialSelection: { type: Number, default: 0 },
      modelValue: { type: Array, required: true },
    },
    emits: ["itemSelected", "update:modelValue"],
    setup(props) {
      const activeIndex = ref(props.initialSelection);
      const reactiveArray = ref(props.modelValue);
      return { reactiveArray, activeIndex };
    },
    methods: {
      selectItem(index: number): void {
        this.activeIndex = index;
        this.$emit("itemSelected", index);
      },
      onChange(action: moveAction): void {
        if (Object.hasOwnProperty.call(action, "moved")) {
          let newIndex = (action as moveAction).moved.newIndex;
          this.selectItem(newIndex);
          this.$emit("update:modelValue", this.reactiveArray);
        }
      },
    },
  });
