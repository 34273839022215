
  import { computed, defineComponent, EmitsOptions, PropType, ref, Ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import { NumericValidityCheck, useNumericResponses } from "./useNumericResponses";
  import SubmissionButtonGroup from "./SubmissionButtonGroup.vue";
  import useEvents from "@/components/use/useEvents";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import { AnswerState, UserResponseSetupData } from "./user-response.interface";
  import { mjsVectorIJK } from "../../mathjs/math-types";

  export default defineComponent({
    components: { SubmissionButtonGroup },
    props: {
      partNr: {
        type: Number,
        required: true,
      },
      questionPartData: {
        type: Object as PropType<UserResponseSetupData>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecord: {
        type: Object as PropType<SumissionQuestionPart>,
        required: true,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const showPreviousAnswers = ref(false);

      console.debug("Setup User Numeric Input.")
      const evaluateStatement = (statement: string) => {
        return props.questionModel.evaluateStatement(statement).getData();
      };

      const partLabel = (index: number) => {
        return index !== -1 ? "Part " + (index + 1) + ". " : "";
      };

      const {
        isScalar,
        choice,
        evalulateCurrentState,
        ansState,
        getCorrectAnswer,
        getCorrectUnit,
        isValidNumericInput,
        registerEntryAsSubmitted,
        formatPreviousEntry
      } = useNumericResponses(props.questionModel, props.questionPartData, props.submissionRecord);

      const answerUnit = getCorrectUnit(); //ref(props.questionPartData.correctUnit);

      if (props.isFinished) {
        choice.value = getCorrectAnswer();
      }

      const isCorrect = computed(() => {
        return ansState.value === AnswerState.CORRECT;
      });

      const validationClass = computed(() => {
        switch (ansState.value) {
          case AnswerState.CORRECT:
            return "correct";
          case AnswerState.WRONG:
            return "wrong";
        }

        return "";
      });

      const { emitEvent } = useEvents(context);
      const validityCheck: Ref<NumericValidityCheck> = ref({ hasError: false, error: "", component: 0 });

      const onSubmit = (): void => {
        showPreviousAnswers.value = false;
        validityCheck.value = isValidNumericInput();
        if (!validityCheck.value.hasError) {
          evalulateCurrentState();

          emitEvent("submit", {
            type: "submit",
            payload: {
              entry: JSON.stringify(choice.value),
              answerState: ansState.value,
            },
          });

          registerEntryAsSubmitted();
        }
      };

      return {
        validationClass,
        isCorrect,
        onSubmit,
        evaluateStatement,
        choice,
        vChoice: choice as Ref<mjsVectorIJK>,
        answerUnit,
        isScalar,
        partLabel,
        validityCheck,
        showPreviousAnswers,
        formatPreviousEntry
      };
    },
  });
