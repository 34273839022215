export interface SumissionQuestionPart {
   nrAttempts: number;
   entries: string[];
   scoreInPercent: number;
   percentOfQuestion: number;
}

export interface SubmissionItem {
   earnedPercent: number,
   searchID: string,
   seed: number,
   userEmail: string
   data: SumissionQuestionPart[]
}

export function MockSubmissionItem(options: Partial<SubmissionItem> = {}): SubmissionItem {
   const defaultVals: SubmissionItem = {
      earnedPercent: 0,
      searchID: "",
      seed: Math.random(),
      userEmail: "mock@mock.edu",
      data: []
   };

   return {
      ...defaultVals, ...options
   };
}

export function MockSubmissionQuestionPart(options: Partial<SumissionQuestionPart> = {}): SumissionQuestionPart {
   const defaultVals: SumissionQuestionPart = {
      nrAttempts: 0,
      entries: [],
      scoreInPercent: 0,
      percentOfQuestion: 10,
   };

   return {
      ...defaultVals, ...options
   };
}