<template>
  <div id="textbook-offcanvas"
       :class="isLargeScreen?'':'offcanvas offcanvas-start'"
       tabindex="-1">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">
        {{ title }}
      </h5>
      <button v-if="!isLargeScreen"
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close" />
    </div>
    <div v-if="editable"
         class="offcanvas-header">
      <b-button class="btn btn-primary"
                icon="bi-plus"
                @click="onAddItem(activeId)" />
      <b-button class="btn btn-danger"
                icon="bi-trash"
                @click="onDeleteItem(activeId)" />
    </div>
    <div v-else
         class="offcanvas-body list-group list-group-flush">
      <div v-for="item in arrayData"
           :key="item.prefix"
           class="list-group-item"
           :class="{ active: activeId === item.id }"
           data-bs-dismiss="offcanvas"
           @click="onClick(item.id)">
        <span class="prefix">{{ item.prefix + " " }}</span>
        <span class="title">{{ item.name }}</span>
      </div>
    </div>
    <BSortableList v-if="editable"
                   class="offcanvas-body"
                   :active-id="activeId"
                   :model-value="arrayData"
                   :nestable="nestable"
                   data-bs-dismiss="offcanvas"
                   @update:modelValue="updateModelValue"
                   @change="onReorderItems"
                   @click="onClick" />
  </div>
</template>

<script lang="ts">
  import useEvents from "@/components/use/useEvents";
  import { ChildBearing } from "@/utils/arrays";
  import { defineComponent, EmitsOptions, PropType, Ref, ref, SetupContext } from "vue";
  import BButton from "./b-button.vue";
  import BSortableList from "./b-sortable-list.vue";
  import { useMediaQuery } from "@vueuse/core";

  export default defineComponent({
    name: "BOffCanvasList",
    components: { BSortableList, BButton },
    props: {
      modelValue: {
        type: Array as PropType<ChildBearing[]>,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      activeId: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        required: true
      },
      nestable: {
        type: Boolean,
        required: true
      }
    },
    emits: ["click", "addItem", "deleteItem", "reorderItems", "update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const { emitTyped } = useEvents(context);
      let arrayData: Ref<ChildBearing[]>;

      if (props.editable)
        arrayData = ref(props.modelValue[0].children);
      else
        arrayData = ref(props.modelValue);

      console.log("OffCanvas active:", props.activeId, props.modelValue)

      const onClick = (itemID: string) => {
        emitTyped('click', itemID);
      }

      function getTypedEvent(eventString: string) {
        return (activeItemID: string) => {
          context.emit('update:modelValue', arrayData.value);
          emitTyped(eventString, activeItemID);
        }
      }

      const onReorderItems = getTypedEvent('reorderItems');
      const onAddItem = getTypedEvent('addItem');
      const onDeleteItem = getTypedEvent('deleteItem');

      const updateModelValue = (newArray: ChildBearing[]) => {
        console.log("Update Model Value in OffCanvas")
        arrayData.value = newArray;
      }

      const isLargeScreen = useMediaQuery('(min-width: 1024px)')

      return {
        isLargeScreen,
        onAddItem,
        onDeleteItem,
        onReorderItems,
        onClick,
        arrayData,
        updateModelValue
      };
    },
  });
</script>

<style scoped>
  .prefix {
    color: gray;
    margin-right: 0.5rem;
  }

  .active > .prefix {
    color: white;
  }
</style>