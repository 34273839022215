import * as BABYLON from 'babylonjs';
import { ConnectableObjectInterface, PortInternals } from "../../instruments/Electronic/CordConnector";
import { BaseRenderedObject, RenderedObjectTypes, SnapBehaviorType, AssetTaskTiedToScene } from "../BaseRenderedObject";
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { ControlResponseRequested } from '../../controls/Control_Base';
import { Fluids, Fluid } from '../../properties/Standard_Fluids';
import { _u, ScalarValue } from '../../values/ScalarValue';
import { Nullable } from 'babylonjs/types';


enum BeakerSize {
    ML1000,
    ML500,
    ML200,
    ML100,
}


interface BeakerOptions {
    location: BABYLON.Vector3,
    pipeDiameter?: number,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    fluid: Fluids,
    size: BeakerSize,
    temperature: ScalarValue
}

export class Beaker extends BaseRenderedObject {
    public portMap: Map<string, PortInternals> = new Map();
    public static meshTemplate: Nullable<BABYLON.Mesh> = null;
    public static loadAssetTask: Nullable<AssetTaskTiedToScene> = null;
    public fluidMesh: Nullable<BABYLON.Mesh> = null;
    public fluid: Nullable<Fluid> = null;

    private options: BeakerOptions = {
        pipeDiameter: 0.0254,
        location: new BABYLON.Vector3(0, 0, 0),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        fluid: Fluids.AIR,
        size: BeakerSize.ML1000,
        temperature: _u(20, 'C')
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.BEAKER;
        this.portMap.set("0", { name: "0", value: undefined, connection: { isConnected: false, connectedPort: "", connectedObject: null } });
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.preloadAssetWorker(assetManager,
            Beaker.loadAssetTask,
            "Load Beaker Mesh",
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/glassware/",
            "beaker.stl",
            function (task) {
                Beaker.meshTemplate = (task.loadedMeshes[0] as BABYLON.Mesh);
                Beaker.meshTemplate.scaling = BABYLON.Vector3.One().scale(0.001);
                Beaker.meshTemplate.setEnabled(false);
            });

        super.addPreloadAssets(assetManager);
    }

    public setOptions(options: BeakerOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort)
            tmpPort.connection = { isConnected: true, connectedPort: connectPort, connectedObject: connectToObj };
    }

    public removeConnection(myPort: string) {
        const tmpPort = this.portMap.get(myPort);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.connectedObject?.removeConnection(tmpPort.connection.connectedPort);
            tmpPort.connection.isConnected = false;
        }
    }

    private getVolume() {
        switch (this.options.size) {
            case BeakerSize.ML100:
                return _u(100, 'ml');
            case BeakerSize.ML200:
                return _u(200, 'ml');
            case BeakerSize.ML500:
                return _u(500, 'ml');
            case BeakerSize.ML1000:
                return _u(1000, 'ml');
        }
    }


    private getScale() {
        switch (this.options.size) {
            case BeakerSize.ML100:
                return 0.001;
            case BeakerSize.ML200:
                return 0.0012;
            case BeakerSize.ML500:
                return 0.0014;
            case BeakerSize.ML1000:
                return 0.0015;
        }
    }

    private setFluidColor() {
        if (this.fluidMesh)
            this.fluidMesh.material = MaterialRegistry.getFluid(this.scene, this.options.fluid);
    }

    public render() {

        if (Beaker.meshTemplate === null) return this;

        const newBase = Beaker.meshTemplate.clone();

        newBase.name = this.name;
        newBase.addRotation(this.options.rotateX, this.options.rotateY, this.options.rotateZ);
        newBase.setEnabled(true);
        newBase.material = MaterialRegistry.getGlass(this.scene);
        const scale = this.getScale();

        const fluidHeight = 50 * scale;
        this.fluidMesh = BABYLON.CylinderBuilder.CreateCylinder(self.name + 'fluid', {
            diameter: 58 * scale,
            height: fluidHeight
        }, this.scene);
        this.fluidMesh.setParent(newBase);
        this.fluidMesh.locallyTranslate(new BABYLON.Vector3(0, fluidHeight / 2.0, 0));
        this.setFluidColor();

        // position and orient the valve
        newBase.position = this.options.location;
        newBase.scaling = new BABYLON.Vector3(scale, scale, scale);
        newBase.setParent(this.parent?.getContainerMesh() ?? null);

        this.myContainerNode = newBase;

        // register hit response
        newBase.actionManager = new BABYLON.ActionManager(this.scene);

        // cannot set material which has only a getter
        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
                (_evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getGlass(this.scene);
                }));

        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger,
                (_evt: BABYLON.ActionEvent) => {
                    newBase.material = MaterialRegistry.getHighlight(this.scene);
                }));

        newBase.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (_evt: BABYLON.ActionEvent) => {
                    // select fluid
                    // select amount?
                    this.getControlPanel()
                        .processHit(ControlResponseRequested.SELECT_FLUID,
                            {
                                controlParams: {}
                            },
                            this,
                            { properties: ["FLUID"] });
                }));

        this.addSnapBehaviorToMesh(newBase, SnapBehaviorType.AXIS, new BABYLON.Vector3(1, 0, 0));

        super.render();

        return this;
    }

    public getDragPoint(): BABYLON.Vector3 {
        return this.myContainerNode?.getAbsolutePosition().clone() ?? BABYLON.Vector3.Zero();
    }

    public dragTo(absPosition: BABYLON.Vector3, orientation: Nullable<BABYLON.Vector3>, alignment: any) {
        this.myContainerNode
            ?.setAbsolutePosition(absPosition);
    }

    public setTemperature(temp: ScalarValue) {
        this.options.temperature = temp;
    }

    public simulateHeating(surfTemp: number, dTinSeconds: number) {
        // Q = m c T
        // dTemp = dQ / m c
        const curTemp = this.options.temperature.in('C');
        const props = this.getFluidProps();
        const cp = props.getSpecificHeat().in('kJ/kg/K');
        const den = props.getDensity().in('kg/m3');
        const volume = this.getVolume().in('m3');
        const A = Math.pow(volume, 2 / 3);
        const k = 1; // kJ/m K
        const t = 0.002; // m

        // dQ/Dt= - k A / d * (curTemp - surfTemp)
        // dTemp = dQ/Dt * dT / (m c)
        const dQDt = -k * A / t * (curTemp - surfTemp);
        let dTemp = dQDt * dTinSeconds / (cp * volume * den);
        if (Math.abs(dTemp) > 100) { dTemp = surfTemp - curTemp; }
        this.options
            .temperature
            .setValue(curTemp + dTemp);
    }

    public setFluid(val: Fluids) {
        console.log("Setting Fluid");

        this.options.fluid = val;
        this.options.temperature = _u(20, 'C');
        this.setFluidColor();
    }

    public getFluidType() { return this.options.fluid; }
    public getFluidProps() {
        if (this.fluid === null) {
            this.fluid = new Fluid(this.getFluidType(), this.options.temperature);
        }

        this.fluid.setType(this.getFluidType());
        this.fluid.setTemp(this.options.temperature);
        return this.fluid;
    }

    public setProperty(name: string, value: any) {
        switch (name) {
            case "FLUID":
                this.setFluid(value);
                break;
            case "TEMPERATURE":
                this.setTemperature(value);
                break;
        }
    }

    public getProperty(name: string): any {
        switch (name) {
            case "FLUID":
                return this.getFluidType();
            case "TEMPERATURE":
                return this.options.temperature;
        }

        super.getProperty(name);
    }

}

