import * as BABYLON from 'babylonjs';
import { VisualFlowMeterModel } from "../instruments/Analog/VisualFlowMeter";
import { PressureMeterElectronic } from "../instruments/Electronic/PressureMeterElectronic";
import { BaseRenderedObject } from "../primatives/BaseRenderedObject";
import { PipeGenerator, PipeGeneratorType } from "../primatives/Factories/PipeGenerator";
import { MaterialRegistry } from "../primatives/Materials/MaterialRegistry";
import { BallValve } from "../primatives/Pipe/Pipe_C_BallValve";
import { Pressure_Connect } from "../primatives/Pipe/Pipe_C_Pressure_Connector";
import { PointListGenerator } from "../primatives/PointListGenerator";
import { FluidDyanmicsEquations } from '../properties/FrictionFactorEquations';
import { Fluid, Fluids } from '../properties/Standard_Fluids';
import { PipeMaterial, PipeNominal, PipeSchedule, PipeScheduleFactory, StandardPipe } from '../properties/Standard_Pipes';
import { PipeComponent, PipeComponentFactory, PipeComponentType } from '../properties/Standard_Pipe_Components';
import { ScalarValue, _u } from '../values/ScalarValue';
import { VariableTable } from "../values/VariableTable";
import { ExperimentInterface } from "./ExperimentInterface";
import { FlowMeterElectronic } from '../instruments/Electronic/FlowMeter';
import { Nullable } from 'babylonjs/types';
import { GridSearch } from '@/math/solver/oneDNewton';

interface PipeLostStationOptions {
    location: BABYLON.Vector3,
    rotationY: number,
    fluid: Fluid,
    initialPressure: ScalarValue,
    pipe1: StandardPipe,
    pipe2: StandardPipe,
    rackWidth: number,
    tee: PipeComponent,
    elbow: PipeComponent,
    valve_in: PipeComponent,
    valve_p1: PipeComponent,
    valve_p2: PipeComponent
}

interface PipeLossResults {
    Q: number,
    V1: number,
    V2: number,
    perValveOpen1: number,
    perValveOpen2: number,
    L1?: number,
    L2?: number,
    D1?: number,
    D2?: number,
    K1?: number,
    K2?: number,
    P1: number,
    P2: number,
    P3: number,
    P4: number,
    f1?: number,
    f2?: number
}

export class PipeLossStation extends BaseRenderedObject implements ExperimentInterface {
    private pipeGenerator: PipeGenerator;
    private variableTable: VariableTable;

    private options: PipeLostStationOptions;
    private currentSolution: Nullable<PipeLossResults> = null;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        const fluid = new Fluid(Fluids.WATER);

        const pipe1 = PipeScheduleFactory.getPipeSize(PipeNominal.NOM_1, PipeSchedule.Sch_10, 'm', PipeMaterial.COPPER);
        const pipe2 = PipeScheduleFactory.getPipeSize(PipeNominal.NOM_1D2, PipeSchedule.Sch_10, 'm', PipeMaterial.COPPER);
        const valve_in = PipeComponentFactory.getComponent(PipeComponentType.SWING_CHECK_VALVE, pipe1.innerDiameter);
        const valve_p1 = PipeComponentFactory.getComponent(PipeComponentType.SWING_CHECK_VALVE, pipe1.innerDiameter);
        const valve_p2 = PipeComponentFactory.getComponent(PipeComponentType.SWING_CHECK_VALVE, pipe2.innerDiameter);
        const tee = PipeComponentFactory.getComponent(PipeComponentType.TEE, pipe2.innerDiameter);
        const elbow = PipeComponentFactory.getComponent(PipeComponentType.ELBOW, pipe2.innerDiameter);

        this.options = {
            fluid: fluid,
            initialPressure: _u(275.79, 'kPa'),
            location: new BABYLON.Vector3(0, 0, 0),
            rotationY: 0,
            rackWidth: 4,
            pipe1: pipe1,
            pipe2: pipe2,
            valve_in: valve_in,
            valve_p1: valve_p1,
            valve_p2: valve_p2,
            tee: tee,
            elbow: elbow
        }

        this.variableTable = new VariableTable();

        this.pipeGenerator = new PipeGenerator(scene);

        this.addChild(new FlowMeterElectronic('FLOW1', scene, this));

        this.addChild(new BallValve("V1", this.scene, this));
        this.addChild(new BallValve("V2", this.scene, this));
        this.addChild(new BallValve("VMAIN", this.scene, this));

        this.addChild(new PressureMeterElectronic("pressureMeter", this.scene, this));

        this.addChild(new Pressure_Connect("PC1", this.scene, this));
        this.addChild(new Pressure_Connect("PC2", this.scene, this));
        this.addChild(new Pressure_Connect("PC3", this.scene, this));
        this.addChild(new Pressure_Connect("PC4", this.scene, this));

        //scene.debugLayer.show();
    }


    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.pipeGenerator.addPreloadAsset(this.scene, assetManager);
        super.addPreloadAssets(assetManager);
    }

    public setOptions(options: PipeLostStationOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public render() {
        const rackLen = this.options.rackWidth ?? 0;

        this.myContainerNode = this.pipeGenerator.create(PipeGeneratorType.Board, null, {
            color: BABYLON.Color3.White(),
            width: rackLen + 1,
            height: 1.25
        });

        const texture = new BABYLON.Texture(
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/signs/PipeRack1Bck.jpg",
            this.scene);
        if (this.myContainerNode) {
            this.myContainerNode.material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Blue());
            (this.myContainerNode.material as BABYLON.StandardMaterial).emissiveTexture = texture;
        }

        const pointList = PointListGenerator.fromJSON({
            W1: [-0.5 * rackLen, -0.5, 0],
            A: [-0.5 * rackLen, -0.5, -0.1],
            B: [-0.5 * rackLen, -0.30, -0.1],
            BB: [-0.5 * rackLen, -0.25, -0.1],
            BC: [-0.5 * rackLen, -0.15, -0.1],
            CC: [-0.5 * rackLen, -0.05, -0.1],
            C: [-0.5 * rackLen, 0.0, -0.1],
            D: [-0.5 * rackLen, 0.5, -0.1],
            E: [0.5 * rackLen, 0.5, -0.1],
            F: [0.5 * rackLen, 0.0, -0.1],
            G: [0.5 * rackLen, -0.5, -0.1],
            H: [-0.45 * rackLen, 0.5, -0.1],   // valve v2 TOP VALVE
            I: [-0.45 * rackLen, 0, -0.1],     // valve V3 BOTTOM VALVE
            W2: [0.5 * rackLen, -0.5, 0.0],
            P1: [-0.4 * rackLen, 0.0, -0.1],   // pressure connector bottom left
            P2: [0.45 * rackLen, 0.0, -0.1],   // pressure connector bottom right
            P3: [-0.4 * rackLen, 0.5, -0.1],   // pressure connector top left
            P4: [0.45 * rackLen, 0.5, -0.1],   // pressure connector top right

            METER: [0 * rackLen, -0.35, -0.2]
        });

        const pairTable =
            [
                ["W1", "A", this.options.pipe1.outerDiameter],
                ["A", "BB", this.options.pipe1.outerDiameter],
                ["CC", "C", this.options.pipe1.outerDiameter],
                ["C", "D", this.options.pipe1.outerDiameter],
                ["D", "E", this.options.pipe2.outerDiameter],
                ["E", "F", this.options.pipe1.outerDiameter],
                ["F", "G", this.options.pipe1.outerDiameter],
                ["C", "F", this.options.pipe1.outerDiameter],
                ["G", "W2", this.options.pipe1.outerDiameter]
            ];

        for (let i = 0; i < pairTable.length; i++) {
            this.pipeGenerator.create(PipeGeneratorType.Pipe,
                this.myContainerNode,
                {
                    startPoint: pointList[pairTable[i][0]],
                    endPoint: pointList[pairTable[i][1]],
                    diameter: pairTable[i][2]
                });
        }

        this.getChildByName("VMAIN")
            ?.setOptions({
                location: pointList["B"],
                rotateZ: -Math.PI / 2.0
            })
            .render();

        this.getChildByName("V1")
            ?.setOptions({
                location: pointList["I"],
                rotateZ: -Math.PI
            })
            .render();
        this.getChildByName("V2")
            ?.setOptions({
                location: pointList["H"],
                rotateZ: -Math.PI
            })
            .render();

        this.pipeGenerator.create(PipeGeneratorType.T_Joint, this.myContainerNode, { location: pointList["C"], rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.T_Joint, this.myContainerNode, { location: pointList["F"], rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["D"], rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["E"], rotateX: 0, rotateY: 0, rotateZ: -Math.PI });

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["A"], rotateX: Math.PI / 2.0, rotateY: 0, rotateZ: 0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["G"], rotateX: Math.PI / 2.0, rotateY: 0, rotateZ: 0 });

        this.getChildByName("PC1")
            ?.setOptions({ location: pointList["P1"] })
            .render();

        this.getChildByName("PC2")
            ?.setOptions({ location: pointList["P2"] })
            .render();

        this.getChildByName("PC3")
            ?.setOptions({ location: pointList["P3"] })
            .render();

        this.getChildByName("PC4")
            ?.setOptions({ location: pointList["P4"] })
            .render();

        // create instruments and meters
        this.getChildByName("FLOW1")
            ?.setOptions({
                location: pointList["BC"],
                model: VisualFlowMeterModel.FourToTwentyEightGPM
            })
            .render();

        this.getChildByName("pressureMeter")
            ?.setOptions({
                location: pointList["METER"]
            })
            .render();

        // move container only after all children are added
        if (this.myContainerNode) {
            this.myContainerNode?.addRotation(0, this.options.rotationY, 0);
            this.myContainerNode.position = this.options.location;
        }
        return super.render();
    }

    public updateTime(time: number, deltaT: number) {
        this.variableTable.set("TIME", time, "ms");
        this.variableTable.set("DELTA_T", deltaT, "ms");
        this.onChange();
    }

    public onChange() {
        this.setTableFromObjectState(this.variableTable);
        this.reComputeSystemState(this.variableTable);
        this.setObjectStateFromTable(this.variableTable);
    }

    private computeFluidValues(perMainValveOpen: number, perValveOpen1: number, perValveOpen2: number) {
        // nothing has changed same as earlier solution
        const Q = 0.005 * perMainValveOpen * Math.max(perValveOpen1, perValveOpen2 / 2.0) / (100 * 100);


        if (this.currentSolution !== null &&
            Math.abs(this.currentSolution.perValveOpen1 - perValveOpen1) < 0.5 &&
            Math.abs(this.currentSolution.perValveOpen2 - perValveOpen2) < 0.5 &&
            Math.abs(this.currentSolution.Q - Q) < 1E-9) {
            return this.currentSolution;
        }

        // No flow
        if (Math.abs(Q) < 1E-12) {
            this.currentSolution = { Q, V1: 0, V2: 0, P1: 0, P2: 0, P3: 0, P4: 0, perValveOpen1, perValveOpen2 }
            return this.currentSolution;
        }

        const D1 = this.options.pipe1.innerDiameter;
        const D2 = this.options.pipe2.innerDiameter;
        const e1 = this.options.pipe1.surfaceRoughness;
        const e2 = this.options.pipe2.surfaceRoughness;
        const A1 = Math.PI * D1 * D1 / 4.0;
        const A2 = Math.PI * D2 * D2 / 4.0;

        const vwat = this.options.fluid.getViscosity().in('Pa*s') / this.options.fluid.getDensity().in('kg/m3');
        const specificWeight = this.options.fluid.getSpecificWeight().in('N/m3')

        const L1 = 0.85 * this.options.rackWidth;
        const L2 = 0.85 * this.options.rackWidth;

        let f1 = 1 / (-0.86 * Math.log(e1 / (3.7 * D1))) ^ 2;
        let f2 = 1 / (-0.86 * Math.log(e2 / (3.7 * D2))) ^ 2;

        const K1 = this.options.valve_p1.setPercentOpen(perValveOpen1).getK();
        const K2 = this.options.valve_p2.setPercentOpen(perValveOpen2).getK();

        // valve 1 is off
        let V1, V2;
        if (perValveOpen1 === 0) {
            V1 = 0;
            f1 = 0;
            V2 = Q / A2;
            f2 = FluidDyanmicsEquations.findFrictionCoef(V2, D2, e2, vwat);
        } else if (perValveOpen2 === 0) {
            V1 = Q / A1;
            f1 = FluidDyanmicsEquations.findFrictionCoef(V1, D1, e1, vwat);
            V2 = 0;
            f2 = 0;
        } else {
            const results = fnc(GridSearch(fnc as any, 0.01, 15)[0], true) as PipeLossResults;
            V1 = results.V1;
            V2 = results.V2;
            f1 = results.f1!;
            f2 = results.f2!;
        }

        const p0 = this.options.initialPressure.in('Pa');
        const dP1 = specificWeight * FluidDyanmicsEquations.pipeLoss(f1, D1, 1, V1);

        this.currentSolution = {
            V1, V2, f1, f2, Q, perValveOpen1, perValveOpen2,
            P1: V1 == 0 ? 0 : p0,
            P2: V1 == 0 ? 0 : p0 - specificWeight * FluidDyanmicsEquations.pipeLoss(f1, D1, L1, V1),
            P3: V2 == 0 ? 0 : p0 - dP1,
            P4: V2 == 0 ? 0 : p0 - dP1 - specificWeight * FluidDyanmicsEquations.pipeLoss(f2, D2, L2, V2)
        };
        //console.log('D1', D1, 'A1', A1, 'v1', V1, 'f1', f1, 'Q', Q, 'P1', this.currentSolution.P1, 'P2', this.currentSolution.P2)
        //console.log('D2', D2, 'A2', A2, 'v2', V2, 'f2', f2, 'Q', Q, 'P3', this.currentSolution.P3, 'P4', this.currentSolution.P4 )
        return this.currentSolution;

        function fnc(V1: number, exportAllData = false): number | PipeLossResults | null {
            const V2 = (Q - V1 * A1) / A2; // cons of mass
            if (V2 < 0 || V1 < 0) { return null; }

            f1 = FluidDyanmicsEquations.findFrictionCoef(V1, D1, e1, vwat);
            f2 = FluidDyanmicsEquations.findFrictionCoef(V2, D2, e2, vwat);

            if (exportAllData) {
                return {
                    f1,
                    f2,
                    V1,
                    V2,
                    L1,
                    L2,
                    D1,
                    D2,
                    Q,
                    K1,
                    K2,
                    P1: 0,
                    P2: 0,
                    P3: 0,
                    P4: 0,
                    perValveOpen1,
                    perValveOpen2
                }
            } else {
                //return (f2 * L2 / D2 + K2) * V2 ^ 2 / (2 * g) - (f1 * L1 / D1 + K1) * V1 ^ 2 / (2 * g);
                return (f2 * L2 / D2 + K2) * V2 * V2 - (f1 * L1 / D1 + K1) * V1 * V1;
            }
        }
        //}
    }

    private reComputeSystemState(variableTable: VariableTable) {
        // the flow rate 
        const mainValve = variableTable.get("VMAIN")?.value ?? 0;
        const branch1Valve = variableTable.get("V1")?.value ?? 0;
        const branch2Valve = variableTable.get("V2")?.value ?? 0;

        const values = this.computeFluidValues(mainValve * 100, branch1Valve * 100, branch2Valve * 100);

        variableTable.set("FLOW1", values.Q, "m3/s");
        variableTable.set("PC10", values.P1, "Pa");
        variableTable.set("PC20", values.P2, "Pa");
        variableTable.set("PC30", values.P3, "Pa");
        variableTable.set("PC40", values.P4, "Pa");
    }
}