<template>
  <section class="pt-12 pt-md-14 pb-12 pb-md-15 bg-gray-900">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-7 text-center">
          <h1 class="display-2 font-weight-bold">
            Our Laboratories
          </h1>
          <p class="lead mb-4">
            We are creating new laboratories right now. If you have particular needs, contact us and we would be happy to create labs that fit your requirements.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="py-8 py-md-11 mt-n10 mt-md-n14">
    <div class="container">
      <div class="row">
        <div v-for="(experiment, index) in listOfExperiments"
             :key="index"
             class="col-12 col-md-4 mb-4">
          <div class="card lift lift-lg shadow-light-lg mb-2 h-100">
            <div class="card-body">
              <h5 class="text-uppercase card-title">
                {{ experiment.title }}
              </h5>
              <p class="card-text">
                {{ experiment.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    setup() {
      const listOfExperiments = [
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity of various fluids.",
        },
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity as a function of temperature for a fluid.",
        },
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity as a function of shear rate for a fluid.",
        },
        {
          title: "Losses in Pipes",
          img: "",
          text: "Determine the major losses in a 1 inch and 1/2 inch pipe.",
        },
        {
          title: "Losses in Pipes",
          img: "",
          text:
            "Determine the minor losses in a 45 degree elbow, and a 90 degree elbow.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the drag coefficient of a sphere as a function of reynolds number.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the drag coefficient of a golf ball as a function of reynolds number and compare to the smooth sphere results.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the lift and drag coefficients for an airfoil as a function of angle of attack and the Reynolds number.",
        },
        {
          title: "Linear Momentum",
          img: "",
          text:
            "Determine the relationship between force and velocity in a water jet.",
        },
        {
          title: "Linear Momentum",
          img: "",
          text:
            "Determine the linear momentum in a water jet that impacts a hemispherical deflector vs a flat wall.",
        },
      ];
      return {
        listOfExperiments,
      };
    },
  });
</script>

<style scoped>
</style>