<template>
  <div :class="'tab-pane ' + (isActive?' show active':'')"
       role="tabpanel">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, onBeforeMount, ref, watch } from "vue";

  export default defineComponent({
    name: "BTab",
    props: {
      title: {
        type: String,
        default: "",
      },
    },

    setup() {
      const index = ref(0);
      const isActive = ref(false);

      const tabs = inject("TabsProvider") as {
        selectedIndex: number;
        count: number;
      };

      watch(
        () => tabs.selectedIndex,
        () => {
          isActive.value = index.value === tabs.selectedIndex;
        }
      );

      onBeforeMount(() => {
        index.value = tabs.count;
        tabs.count++;
        isActive.value = index.value === tabs.selectedIndex;
      });
      return { index, isActive };
    },
  });
</script>

<style scoped>
</style>