import { PageRecord } from "@/store/database/Textbook/record-page";
import { TOCCreationOptioins, TOC_Record } from "@/store/database/Textbook/record-tableOfContents";
import { PageIndex, TOCFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
import { flattenChildList } from "@/utils/arrays";
import { TypeGuard } from "./mathjs/Type-guards";
import { TextbookObject } from "./textbook-object";
import { v4 as uuid4 } from "uuid";

export class TableOfContents {
   private tocRecord: TOC_Record | undefined;

   private isPageMapDirty = true;
   private pageNrMap: string[] = [];
   private rootPageID: string | null = null;
   private rootPageIndex: PageIndex | null = null;
   private startPrefixAtRoot = true;

   constructor() {
      this.tocRecord = new TOC_Record();
      return;
   }

   private searchFor(curIndex: PageIndex, condition: (pI: PageIndex) => boolean): PageIndex | null {
      if (condition(curIndex)) { return curIndex; }

      let result: PageIndex | null = null;
      for (let index = 0; result !== null && index < curIndex.children.length; index++) {
         result = this.searchFor(curIndex.children[index], condition);
      }
      return result;
   }

   public deleteEntryFor(pageID: string) {
      const rootNode = this.getRootIndex();
      if (!rootNode) return;
      const parent = this.searchFor(rootNode, (item) => (item.children.findIndex(child => child.pageID === pageID) !== -1));
      const pI_index = parent?.children.findIndex(child => child.pageID === pageID);

      if (TypeGuard.isNullOrUndefined(pI_index)) return;
      if (pI_index === -1 || parent === null) return;

      const pageIndex = parent.children[pI_index];
      parent.children.splice(pI_index, 1);
      parent.children = parent.children.concat(pageIndex.children);

      this.isPageMapDirty = true;
   }

   public getRecord() { return this.tocRecord; }

   // set pageID = null to set the root to the 
   // true root page.
   public setRoot(pageID: string, startPrefixAtRoot = true) {
      this.isPageMapDirty = true;
      this.rootPageID = pageID;
      this.startPrefixAtRoot = startPrefixAtRoot;
      this.rootPageIndex = pageID === null ? null : this.findPageIndexOf(pageID);
      return this;
   }


   public getRootIndex(): PageIndex | undefined {
      if (this.rootPageIndex === null) {
         return this.tocRecord?.pageIndex[0];
      } else {
         return this.rootPageIndex;
      }
   }

   public findPageIndexOf(pageID: string): PageIndex | null {
      let resultPageIndex: PageIndex | null = null;

      const findPI = (el: PageIndex | undefined) => {
         if (el === undefined) return;

         if (el.pageID === pageID) { resultPageIndex = el; }
         else {
            el.children.forEach((v) => {
               findPI(v);
            });
         }
      }

      findPI(this.tocRecord?.pageIndex[0]);

      return resultPageIndex;
   }

   public getFlatPageIndexArray(rootPageID?: string | undefined) {
      let nestedList;
      if (rootPageID) {
         nestedList = this.findPageIndexOf(rootPageID);
      } else {
         nestedList = this.getPageIndex();
      }

      nestedList = this.getPageIndex();

      let rootPageIndex;
      if (nestedList && nestedList !== null) {
         rootPageIndex = flattenChildList(nestedList).slice(1);
      }

      return rootPageIndex;
   }


   public append(pIndex: PageIndex) {
      this.tocRecord?.pageIndex[0].children.push(pIndex);
      this.isPageMapDirty = true;
      return this;
   }

   public findPageIndexFromIndexID(pageIndexID: string): PageIndex | undefined {
      const rootPageIndex = this.getRootIndex();
      const foundPageIndex = findPageIndexFrom(rootPageIndex);
      console.log("FindPage", pageIndexID, foundPageIndex, rootPageIndex);

      return foundPageIndex;
      function findPageIndexFrom(pageIndex: PageIndex | undefined): PageIndex | undefined {
         if (pageIndex === undefined) return undefined;

         if (pageIndex.id === pageIndexID)
            return pageIndex;

         let result = undefined;
         for (let i = 0; result === undefined && i < pageIndex.children.length; i++) {
            result = findPageIndexFrom(pageIndex.children[i]);
         }

         return result ? result : undefined;
      }
   }

   public findPageNrOf(pageID: string) {
      return this.getPageNrMap().findIndex((v) => { return v === pageID; });
   }

   public getPageIndex() {
      if (this.isPageMapDirty) {
         this.recreatePageNrMap();
      }

      return this.tocRecord?.pageIndex;
   }

   private getPageNrMap() {
      if (this.isPageMapDirty) {
         this.recreatePageNrMap();
      }

      return this.pageNrMap;
   }

   public async rebuildFromBookPages(book: TextbookObject) {
      const pageList = book.getPageList();
      const rootPage = pageList.find((v) => v.data().name === "ROOT");

      if (TypeGuard.isNullOrUndefined(rootPage)) {
         throw Error("Cannot find root page in the book.")
      }

      if (TypeGuard.isNullOrUndefined(this.tocRecord)) {
         throw Error("Could not find TOC Record.")
      }

      const rootIndex = getIndexFromPage(rootPage);
      this.rootPageIndex = rootIndex;
      this.rootPageID = rootIndex.pageID;
      rootIndex.children = pageList.filter(v => v.data().name !== "ROOT").map(v => getIndexFromPage(v))

      this.tocRecord.pageIndex = [rootIndex];

      await this.updateInDB();

      function getIndexFromPage(page: PageRecord): PageIndex {
         return {
            name: page.data().name as string,
            pageID: page.id(),
            children: [],
            isActive: true,
            prefix: "",
            id: uuid4()
         }
      }

   }

   // creates array of page IDs in sequential pageNr order
   private recreatePageNrMap() {
      let initialPrefix = "";
      if (!this.startPrefixAtRoot) {
         const dynamicRootID = this.getRootIndex()?.pageID;

         const iteratePrefix = (pI: PageIndex | undefined, curPrefix: string) => {
            if (pI === undefined) return;

            if (dynamicRootID === pI.pageID) { initialPrefix = curPrefix; }
            pI.children.forEach((v, i) => {
               iteratePrefix(v, curPrefix + ((curPrefix.length === 0) ? "" : ".") + (i + 1).toString());
            });
         }

         iteratePrefix(this.tocRecord?.pageIndex[0], initialPrefix);
      }

      const iteratePages = (pI: PageIndex | undefined, curPrefix: string) => {
         if (pI === undefined) return;

         pI.prefix = curPrefix;

         this.pageNrMap.push(pI.pageID);
         pI.children.forEach((v, i) => {
            iteratePages(v, curPrefix + ((curPrefix.length === 0) ? "" : ".") + (i + 1).toString());
         });
      }

      this.pageNrMap = [];
      iteratePages(this.getRootIndex(), initialPrefix);

      this.isPageMapDirty = false;

      return this;
   }

   public getNrPages() {
      return this.getPageNrMap().length;
   }

   // return the pageID of the page on pageNr
   public getIDOfPageNr(pageNr: number) {
      if (this.isPageMapDirty) {
         this.recreatePageNrMap();
      }

      const totalNrPages = this.getNrPages();
      if (pageNr < 0) return this.pageNrMap[0];
      if (pageNr > totalNrPages) return this.pageNrMap[totalNrPages - 1];

      return this.pageNrMap[pageNr];
   }


   // update the name on the page object with PageID 
   // must search the heirarchy to find the pageID
   updateReferenceToPage(pageID: string, newName: string) {
      const updateRef = (el: PageIndex | undefined) => {
         if (el === undefined) return;

         if (el.pageID === pageID) { el.name = newName; }
         else {
            el.children.forEach((v) => {
               updateRef(v);
            });
         }
      }

      updateRef(this.tocRecord?.pageIndex[0]);
      return this;
   }


   async createNewInDB(options: TOCFromTextbookTable, createOptions: TOCCreationOptioins) {
      await this.tocRecord?.createNewInDB(options, createOptions);
   }

   async updateInDB() {
      await this.tocRecord?.updateInDB();
   }

   async saveToDB() {
      await this.tocRecord?.saveToDB();
   }

   static loadFromJSON(data: Record<string, unknown>) {
      if (!data) return;
      const newTableOfContents = new TableOfContents();
      newTableOfContents.tocRecord = TOC_Record.loadFromJSON(data);
      return newTableOfContents;
   }
}