import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';

import { Control_Base } from "./Control_Base";

export interface ControlSelectOptions {
    onChange?: (value: number) => void,
    value?: {
        initialValue?: string,
        list?: string[],
    },
    selectOptions?: {
        title?: string
    }
}

export class Control_Select extends Control_Base {

    public options: ControlSelectOptions = {
        value: { list: ["opt1", "opt2"], initialValue: 'opt1' },
        selectOptions: {
            title: "group"
        },
        onChange: (_value: number) => { return }
    };

    constructor(name: string, scene: BABYLON.Scene, options: ControlSelectOptions) {
        super(name, scene);
        this.options = { ...this.options, ...options };
        return this;
    }

    public renderGUI() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const setProperty = (selectedOption: unknown) => {
            console.log("Render Gui:", { selectedOption, property: self.property });
            self.instrument?.setProperty(self.property, selectedOption);
        }

        this.gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI_SELECT");

        const rect1 = new GUI.Rectangle();
        rect1.widthInPixels = 200;
        rect1.heightInPixels = 50 + 50 * (this.options?.value?.list?.length ?? 1);
        rect1.color = "gray";
        rect1.thickness = 1;
        rect1.cornerRadius = 4;
        rect1.background = "white";
        rect1.fontSizeInPixels = 25;
        this.gui.addControl(rect1);

        const stack = new GUI.StackPanel();
        rect1.addControl(stack);

        const rg = new GUI.RadioGroup(this.options.selectOptions?.title ?? "");
        this.options.value?.list?.forEach((v, index) => {
            rg.addRadio(v, setProperty, index === this.instrument?.getProperty(this.property));
        });

        const selectBox = new GUI.SelectionPanel("sp", [rg]);
        selectBox.heightInPixels = 40 * (this.options.value?.list?.length ?? 1);
        stack.addControl(selectBox);

        stack.addControl(this.createDoneButton());

        if (this.instrument) {
            rect1.linkWithMesh(this.instrument.getContainerMesh());
            rect1.linkOffsetYInPixels = 50;
            rect1.linkOffsetXInPixels = 110;
        }
    }

}