import { TextRecognitionBaseTableType } from "./table-textrecognition.interface";

export function getDefaultBaseTextRecognition(): TextRecognitionBaseTableType {
   return {
      id: "",
      fieldType: "CHAR",
      character: "",
      userEmail: "",
      included: true,
      orignalPaths: [""],
      dateIndex: "",
      meta: {}
   };
}
