
  import { defineComponent, inject, PropType, ref } from "vue";
  import { PageComponent } from "../pages/pages.interface";
  import BMarkdown from "@/components/bootstrap/b-markdown.vue";

  export default defineComponent({
    name: "MarkdownComponent",
    components: {
      BMarkdown,
    },
    props: {
      content: {
        type: Object as PropType<PageComponent>,
        required: true,
      },
    },
    setup(props) {
      const sourceContent = ref(props.content.data.content);
      const textId = inject("textId", "empty") as string;

      return {
        textId,
        sourceContent,
      };
    },
  });
