import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import { FormCatalogInterface } from "@/components/formGenerator/form-schema.interface";

export function getQuizComponentSchema(catalogOptions: FormCatalogInterface) {
   return FormSchemaMaker.Group({
      label: "Quiz Component",
      field: "data",
      properties: {
         classes: "m-1 p-1 border formColorGroup",
         customButtons: [
            { title: 'Thumbnail', emit: 'view-preview' },
            { title: 'Sample', emit: 'view-sample' }
         ]
      }
   },
      [
         FormSchemaMaker.Select({
            label: "Display Type",
            field: "displayType",
            choices: ["QUIZ", "WORKSHEET"],
            properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
            hint: "Quiz displays one question at a time, worksheet displays a page of questions"
         }),
         FormSchemaMaker.CheckBox({
            label: "Same for all Students",
            field: "setSeed",
            properties: { labelCols: 8, componentCols: 3, componentOffset: 0 },
            hint: "If selected, the question will be the same for all students."
         }),
         FormSchemaMaker.CheckBox({
            label: "Randomize Question Order",
            field: "randomizeOrder",
            properties: { labelCols: 8, componentCols: 3, componentOffset: 0 },
            hint: "If selected, the questions will be presented to the student in a randomized order"
         }),
         FormSchemaMaker.Text({
            label: "Seed Value",
            field: "seedValue",
            properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
            hint: "Value of the seed used if it is the same for all students. "
         }),

         FormSchemaMaker.CheckBox({
            label: "Show Score Breakdown",
            field: "showScoreBreakdown",
            rules: {},
            properties: { labelCols: 8, componentCols: 3, componentOffset: 0 },
            hint: "Show the student how they scored on each part of each question immediately after completing the assignment. Otherwise, they need to wait until the assignment closes.",
            value: false
         }),
         FormSchemaMaker.CheckBox({
            label: "Show Solutions",
            field: "showSolutions",
            rules: {},
            properties: { labelCols: 8, componentCols: 3, componentOffset: 0 },
            hint: "Show the solutions to the student immediately after submitting. Otherwise they need to wait until the assignment closes.",
            value: false
         }),
         FormSchemaMaker.SelectFromCatalog({
            label: "Question List",
            field: "array",
            properties: {
               labelAlign: "top",
            },
            hint: "List of questions included in the quiz.",
            value: [],
            catalog: catalogOptions
         },
            [
               FormSchemaMaker.ReadOnly({ label: "Name", field: "name", hint: "", rules: { variable_name: true } }),
               //FormSchemaMaker.ReadOnly({ label: "Question ID", field: "id", hint: "", rules: { variable_name: true } }),
               //FormSchemaMaker.ReadOnly({ label: "Question Bank", field: "questionBankID", hint: "", rules: { variable_name: true } }),
               FormSchemaMaker.Text({ label: "Points", field: "points", hint: "Enter the percentage of total assigned to this question. Enter -1 to automatically distribute.", value: -1 }),
               FormSchemaMaker.Text({ label: "Max Attempts", field: "maxAttempts", hint: "Enter the maximum number of times a student may attempt the problem. Enter -1 to inherit from the assignment.", value: -1 }),
            ]
         )

      ]);
}