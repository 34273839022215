import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useLogin from "@/components/use/useLogin";
import useSchema from "@/components/use/useSchema";
import { LoginActionTypes, LoginPageParameterType } from "./login.interface";

export const signUpButton = {
    class: "btn-success m-2 save float-end",
    label: "Sign Up",
    triggerEvent: {
        originatingAction: "Sign Up Button",
        type: LoginActionTypes.SIGN_UP_ACTION,
        payload: null
    },
    isEnabled: true,
    enabledWhenFormIsValid: true,
}

export const signUpSchema = FormSchemaMaker.Group(
    {
        label: "Create a new account",
        field: "data",
        properties: {
            classes: "m-1",
            hideLabel: false
        },
    },
    [
        FormSchemaMaker.Email({
            name: "username",
            label: "Email Address",
            field: "emailValue",
            properties: { labelAlign: "top" },
            rules: { required: true, email: true },
            value: "",
            placeHolder: "Enter your email address",
            autocomplete: "username"
        }),

        FormSchemaMaker.Password({
            name: "password",
            label: "Password",
            field: "passwordValue",
            properties: { labelAlign: "top" },
            rules: { required: true, min: 8, max: 32, has_lowercase: true, has_uppercase: true, has_number: true },
            value: "",
            placeHolder: "Enter your password",
            hint: "",
            autocomplete: "new-password"
        }),

        FormSchemaMaker.Divider({
            label: "Divider",
            field: "none",
            properties: { labelAlign: "top", hideLabel: true },
            value: "",
        }),

        FormSchemaMaker.Text({
            label: "First Name",
            field: "first",
            properties: { labelAlign: "top" },
            rules: { required: true },
            placeHolder: "Enter your first name",
            value: "",
            autocomplete: "given-name"
        }),
        FormSchemaMaker.Text({
            label: "Last Name",
            field: "last",
            rules: { required: true },
            properties: { labelAlign: "top" },
            placeHolder: "Enter your last name",
            value: "",
            autocomplete: "family-name"
        }),

        FormSchemaMaker.Text({
            label: "School",
            field: "school",
            rules: { required: true },
            properties: { labelAlign: "top" },
            placeHolder: "Enter the name of your school",
            value: "",
            autocomplete: "organization"
        }),
        FormSchemaMaker.CheckBox({
            label: "By clicking on this box, you agree to user terms and conditions as specified on the terms page: www.vengla.com/Terms",
            field: "agreeToTerms",
            rules: { check_true: true },
            properties: {
                classes: "checkboxInput",
                labelAlign: "right",
                labelCols: 10
            },
            value: false,
        }),
    ]
);

export const signUpUserPage = (options: LoginPageParameterType): void => {
    options.currentSchema.value = signUpSchema;
    options.buttonFooter.value = {
        infoText: "Have an account?",
        linkText: "Sign in",
        eventType: {
            originatingAction: "Sign Up Link",
            type: LoginActionTypes.GOTO_SIGN_IN_PAGE
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
            originatingAction: "Sign up user page",
            type: LoginActionTypes.EMPTY
        },
    };
    options.buttonFooter2.value = {
        infoText: "Confirm an existing account?",
        linkText: "Enter Code",
        eventType: {
            originatingAction: "Sign up user page",
            type: LoginActionTypes.GOTO_USER_CONFIRM_PAGE
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
            originatingAction: "Sign up user page",
            type: LoginActionTypes.EMPTY
        },
    };
    options.buttonList[0] = signUpButton;
    options.enterKeyAction.value.triggerEvent.type = LoginActionTypes.SIGN_UP_ACTION;
}

export const signUpAction = async (options: LoginPageParameterType): Promise<void> => {
    console.log("Sign up new User");
    const { signUp } = useLogin();
    const { schema_getJSON } = useSchema();

    if (options.originationEvent.payload?.schemaItem) {
        const data = schema_getJSON(options.originationEvent.payload.schemaItem).data;
        const result = await signUp({
            agree: data.agreeToTerms as string,
            confirmation: false,
            firstName: data.first as string,
            lastName: data.last as string,
            password: data.passwordValue as string,
            school: data.school as string,
            username: data.emailValue as string,
        });

        if (result.hasError()) {
            options.errorMessage.value = result.getFlatErrorMessage();
        } else {
            options.onPress({
                originatingAction: "Sign Up On Success",
                type: LoginActionTypes.GOTO_USER_CONFIRM_PAGE,
                payload: data.emailValue,
            });
        }

    }
}

