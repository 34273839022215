<template>
  <div v-katex:auto
       class="container-fluid">
    <div v-if="content.components.length === 1">
      <ComponentViewer :component="content.components[0]" />
    </div>
    <div v-else
         class="row">
      <div class="col-2">
        <ul class="list-group list-group-flush item-container m-0 p-0">
          <li v-for="(component, index) in content.components"
              :key="component.id"
              :class="{ active: activeComponent === index }"
              class="list-group-item item-group m-0 p-1"
              @click="activeComponent = index">
            <table class="table m-0 p-0">
              <tr>
                <td class="thinColumn">
                  <i class="bi"
                     :class="getIconForComponentType(component.type)" />
                </td>
                <td class="itemName">
                  {{ component.name }}
                </td>
              </tr>
            </table>
          </li>
        </ul>
      </div>
      <div class="col">
        <ComponentViewer :key="activeComponent"
                         :component="content.components[activeComponent]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, provide, ref } from "vue";
  import useComponents from "../../use/usePageComponents";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import ComponentViewer from "./ComponentViewer.vue";
  import { usePageEditorFunctions } from "@/views/instructor/usePageEditorFunctions";

  export default defineComponent({
    components: {
      ComponentViewer
    },
    props: {
      pageData: {
        type: Object as PropType<PageFromTextbookTable>,
        required: true,
      },
    },
    setup(props) {
      provide("pageData", props.pageData);

      const {
        getActiveComponentsFromPageData,
      } = useComponents();

      const {
        getIconForComponentType,
      } = usePageEditorFunctions(() => { return; });


      let activeComponent = ref(0);
      let content = getActiveComponentsFromPageData(props.pageData);

      return {
        content,
        activeComponent,
        getIconForComponentType
      };
    },
  });
</script>

<style scoped>
  .thinColumn {
    width: 20px;
  }
  .active {
    background-color: #ddd;
  }
</style>