import * as BABYLON from 'babylonjs';
import { Fluids } from '../../properties/Standard_Fluids';
import { SceneMetaData } from '../../engine/SceneMetaData';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
import { Nullable } from 'babylonjs/types';

interface MaterialTextureOptions {
    clone: boolean,
    rAngle: number,
    uScale: number,
    vScale: number,
    rCenter: number
}

export class MaterialRegistry {
    static validForSceneID = "";
    static myAssetManager: Nullable<BABYLON.AssetsManager> = null;
    static materialLibrary: Map<string, BABYLON.StandardMaterial> = new Map();

    static addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        MaterialRegistry.myAssetManager = assetManager;
        assetManager.addImageTask("rotBricks", "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Red_Brick.jpg");
    }

    static getColor(scene: BABYLON.Scene, c: BABYLON.Color3) {
        return this.materialFinder("Color" + c.toString(), scene, (scene: BABYLON.Scene) => {
            const newCol = new BABYLON.StandardMaterial("Color" + c.toString(), scene);
            newCol.diffuseColor = c;
            return newCol;
        });
    }

    static getGlass(scene: BABYLON.Scene) {
        return this.materialFinder('glass', scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("glassMaterial", scene);
            tmpMaterial.alpha = 0.5;
            return tmpMaterial;
        });
    }

    private static clearRegistry() {
        this.materialLibrary.clear();
    }

    private static materialFinder(name: string, scene: BABYLON.Scene, createFunc: (scene: BABYLON.Scene) => BABYLON.StandardMaterial) {
        const sceneID = (scene.metadata as SceneMetaData).id;
        if (sceneID !== MaterialRegistry.validForSceneID) {
            this.clearRegistry();
            MaterialRegistry.validForSceneID = sceneID;
        }

        if (!this.materialLibrary.has(name)) {
            this.materialLibrary.set(name, createFunc(scene));
        }

        const foundMaterial = this.materialLibrary.get(name);
        if (TypeGuard.isNullOrUndefined(foundMaterial)) {
            throw Error("Unable to find material: " + name);
        }
        return foundMaterial;
    }

    static getSilver(scene: BABYLON.Scene) {
        return this.materialFinder('silver', scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("Silver", scene);
            tmpMaterial.diffuseColor = new BABYLON.Color3(0.827, 0.831, 0.835);
            tmpMaterial.specularColor = new BABYLON.Color3(0.2, 0.2, 0.2);
            return tmpMaterial;
        });
    }

    static getCopper(scene: BABYLON.Scene) {
        return this.materialFinder('copper', scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("Copper", scene);
            tmpMaterial.diffuseColor = new BABYLON.Color3(0.72, 0.45, 0.20);
            return tmpMaterial;
        });
    }

    static getHighlight(scene: BABYLON.Scene) {
        return this.materialFinder('highlight', scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("Highlight", scene);
            tmpMaterial.diffuseColor = BABYLON.Color3.Yellow();
            tmpMaterial.emissiveColor = BABYLON.Color3.Yellow();
            return tmpMaterial;
        });
    }


    static getWhiteWireFrame(scene: BABYLON.Scene) {
        return this.materialFinder('TickMarks', scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("tickMarks", scene);
            tmpMaterial.alpha = 1.0;
            tmpMaterial.diffuseColor = new BABYLON.Color3(0.0, 0.0, 0.0);
            tmpMaterial.wireframe = true;
            return tmpMaterial;
        });
    }

    static getBrickTexture(scene: BABYLON.Scene, options: MaterialTextureOptions) {
        return this.getBasicTexture(scene, {
            name: 'BrickTexture',
            url: "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Red_Brick.jpg"
        }, options);
    }

    static getBasicTexture(scene: BABYLON.Scene, input: { name: string, url: string }, options: Partial<MaterialTextureOptions>) {
        return this.materialFinder(input.name, scene, (scene: BABYLON.Scene) => {
            const tmpMaterial = new BABYLON.StandardMaterial("Metal", scene);
            let texture;
            if (options === null) {
                texture = new BABYLON.Texture(input.url, scene);
            } else {
                texture = MaterialRegistry.rotateTexture(new BABYLON.Texture(input.url, scene), options);
            }
            tmpMaterial.diffuseTexture = texture;
            return tmpMaterial;
        });
    }

    static getMetalTexture(scene: BABYLON.Scene, options: Partial<MaterialTextureOptions>) {
        return this.getBasicTexture(scene, {
            name: 'MetalTexture',
            url: "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Metal1.jpg"
        }, options);
    }

    static getCabinetTexture(scene: BABYLON.Scene, options: Partial<MaterialTextureOptions>) {
        return this.getBasicTexture(scene, {
            name: 'CabinetTexture',
            url: "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Cabinets.png"
        }, options);
    }

    static rotateTexture(texture: BABYLON.Texture, options: Partial<MaterialTextureOptions>) {
        const mergedOptions: MaterialTextureOptions = { ...{ clone: false, rAngle: 0, uScale: 1, vScale: 1, rCenter: 0 }, ...options };

        if (mergedOptions.clone) {
            texture = texture.clone();
        }

        if (mergedOptions.rAngle != 0) {
            texture.wRotationCenter = mergedOptions.rCenter;
            texture.wAng = mergedOptions.rAngle;
        }

        texture.uScale = mergedOptions.uScale;
        texture.vScale = mergedOptions.vScale;

        return texture;
    }

    static getBall(scene: BABYLON.Scene, ballType: BallType, options: Partial<MaterialTextureOptions>) {
        let name = "";
        let url = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/";
        switch (ballType) {
            case BallType.GOLF:
                name = "GolfTexture";
                url = url + "surfaces/Metal1.jpg";
                break;
            case BallType.BASEBALL:
                name = "BaseBallTexture";
                url = url + "baseBall.jpg";
                break;
        }

        return this.getBasicTexture(scene, {
            name: name,
            url: url
        }, options);
    }


    // fluids
    static getFluid(scene: BABYLON.Scene, fluidType: Fluids) {
        switch (fluidType) {
            case Fluids.WATER:
                return this.materialFinder('water', scene, (scene: BABYLON.Scene) => {
                    const tmpMaterial = new BABYLON.StandardMaterial("Highlight", scene);
                    tmpMaterial.diffuseColor = new BABYLON.Color3(0.25, 0.64, 0.87);
                    tmpMaterial.emissiveColor = new BABYLON.Color3(0.25, 0.64, 0.87);
                    tmpMaterial.alpha = 0.5;
                    return tmpMaterial;
                });
            case Fluids.AIR:
                return this.materialFinder('air', scene, (scene: BABYLON.Scene) => {
                    const tmpMaterial = new BABYLON.StandardMaterial("Highlight", scene);
                    tmpMaterial.alpha = 0.0;
                    return tmpMaterial;
                });
            case Fluids.SAE10W40:
            case Fluids.SAE10W60:
                return this.materialFinder('SAE10W', scene, (scene: BABYLON.Scene) => {
                    const tmpMaterial = new BABYLON.StandardMaterial("Highlight", scene);
                    tmpMaterial.diffuseColor = new BABYLON.Color3(0.855, 0.808, 0.359);
                    tmpMaterial.emissiveColor = new BABYLON.Color3(0.855, 0.808, 0.359);
                    tmpMaterial.alpha = 0.9;
                    return tmpMaterial;
                });
        }
    }

    static generateDiffTexture(scene: BABYLON.Scene, name: string, url: string, nU: number, nV: number) {
        const backgroundMaterial = new BABYLON.StandardMaterial(name, scene);
        backgroundMaterial.diffuseTexture = new BABYLON.Texture(url, scene);
        (backgroundMaterial.diffuseTexture as any).uScale = nU;
        (backgroundMaterial.diffuseTexture as any).vScale = nV;
        return backgroundMaterial;
    }
}

export enum BallType {
    GOLF,
    BASEBALL
}