<template>
  <QuestionViewer :question-data="questionData"
                  course-id="abc"
                  component-id="abc" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import QuestionViewer from './QuestionViewer.vue'
  import questionData from "./mock/question-component-1.json"

  export default defineComponent({
    components: { QuestionViewer },
    setup() {
      return {
        questionData
      }
    }
  })
</script>

<style scoped>
</style>