import { reactive, ref } from "@vue/reactivity";
import { ConfirmDialogOptions } from "../bootstrap/b-confirm-dialog.interface";
import { FormSchemaItem } from "../formGenerator/form-schema.interface";
import useSchema from "./useSchema";

export default function useReactiveForm(inputSchema: FormSchemaItem, initialData: Record<string, unknown>) {
   const { schema_mergeWithData } = useSchema();

   const isFormValid = ref(true);
   const isLoading = ref(false);
   const formKey = ref(1);

   const confirmDialog = ref<ConfirmDialogOptions | null>(null);

   const rawSchema = reactive(inputSchema);
   const schema = ref(schema_mergeWithData(rawSchema, initialData));

   const displaybehavior = reactive({
      displayHints: false,
      hasChanges: false,
      showJSONButton: true,
      buttonAlign: "top",
   });

   const triggers = reactive({
      validate: false,
   });

   return {
      displaybehavior,
      triggers,
      isLoading,
      rawSchema,
      schema,
      isFormValid,
      formKey,
      confirmDialog
   }
}