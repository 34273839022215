<template>
  <canvas ref="babylonElement"
          class="w-100 h-100" />
</template>

<script lang="ts">
  import { GameEngine } from "@/babylon/engine/GameEngine";
  import { LaboratoryNameMap, LaboratoryTypes } from "@/babylon/laboratories/LaboratoryTypes";
  import { Nullable } from "babylonjs/types";
  import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from "vue";
  import { PageComponent } from "../pages/pages.interface";

  export default defineComponent({
    name: "BabylonComponent",
    props: {
      content: {
        type: Object as PropType<PageComponent>,
        required: true,
      },
    },
    setup(props) {
      const babylonElement = ref(null);
      console.log("Babylon Component Loaded")
      const labTypeName: string = props.content.data.displayType as string;
      let gameEngine: Nullable<GameEngine> = null;

      onMounted(() => {
        console.log('game engine mounted.')
        if (babylonElement.value !== null) {
          gameEngine = new GameEngine(babylonElement.value!,
            LaboratoryNameMap.get(labTypeName) ?? LaboratoryTypes.VISCOMETER)
          gameEngine.run();
        }
      });

      onBeforeUnmount(() => {
        console.log('game engine destoyed.')
        gameEngine?.destroy();
        gameEngine = null;
      })

      return {
        babylonElement
      };
    },
  });
</script>

<style scoped>
</style>