
  import { defineComponent, onErrorCaptured, Ref, ref } from "vue";
  import PNavbar from "./views/components/p-navbar.vue";
  import { signInExistingUser } from "./views/components/login/login-sign-in";
  import BLoading from "./components/bootstrap/b-loading.vue";

  export default defineComponent({
    components: {
      PNavbar,
      BLoading
    },
    setup() {
      const error: Ref<null | unknown> = ref(null);
      signInExistingUser();
      onErrorCaptured((e) => {
        error.value = e;
        return true;
      });

      return {
        error,
      };
    },
  });
