import { UserResponseSetupData } from "./components/user-response.interface"
import { EquationStack } from "./equations/equation-template-helpers"
import { ModelVariableOptions } from "./model/model-variable"
import { ModelVectorVariableOptions } from "./model/model-vector"

export enum QuestionFigureType {
  IMAGE
}

export interface QuestionFigureData {
  meta: {
    caption: string,
    figureObjects: [],
    include: boolean,
    type: QuestionFigureType,
    url: string
  },
  tabTitle: string;
}

export interface QuestionModelData {
  meta: {
    formulas: EquationStack,
    parameters: ModelVariableOptions[],
    vector_parameters: ModelVectorVariableOptions[]
  },
  tabTitle: string;
}

export interface QuestionSolutionData {
  meta: {
    solutionStatement: string
  },
  tabTitle: string
}

export interface QuestionStatementData {
  meta: {
    problemStatement: string,
    requestedResponses: UserResponseSetupData[]
  },
  tabTitle: string
}

export interface QuestionDataInterface {
  id: string;
  isActive: boolean;
  isInitialized: boolean;
  name: string;
  seed: string;
  showSolution: boolean;
  //type: ??
  data: {
    figure: QuestionFigureData;
    model: QuestionModelData;
    solution: QuestionSolutionData;
    view: QuestionStatementData;
  }
}
