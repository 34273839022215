import * as BABYLON from 'babylonjs';
import { Nullable } from 'babylonjs/types';
import { BaseRenderedObject, RenderedObjectTypes } from "../../primatives/BaseRenderedObject";
import { MaterialRegistry } from "../../primatives/Materials/MaterialRegistry";
import { ScalarValue, _u } from "../../values/ScalarValue";
import { VariableTable } from "../../values/VariableTable";
import { InstrumentDisplayValue, NoiseCorrelation, NoiseType, RangeOverFlowOptions } from "../InstrumentDisplayValue";



export enum VisualFlowMeterModel {
    OneToTenGPM,
    FourToTwentyEightGPM
}

export interface flowMeterGeometricData {
    model: VisualFlowMeterModel,
    clearGlassHeight: number,
    clearCylinderDiameter: number,
    bobHeight: number,
    nrTickMarks: number,
    baseCylinderHeight: number,
    location: BABYLON.Vector3,
    minValueQ: ScalarValue,
    maxValueQ: ScalarValue
}


/**
 * Visual Flow Meter
 * An instrument that can be used to find the 
 * volumetric flow rate of a liquid. A bob in the 
 * center of a clear cylinder rises against gravity
 * as the liquid flows past the bob
 * */
export class VisualFlowMeter extends BaseRenderedObject {
    private flowDisplay: Nullable<InstrumentDisplayValue> = null;
    private flowBob: Nullable<BABYLON.Mesh> = null;
    private flowBobYPositionLimits: {
        minTick: number,
        maxTick: number,
        minPhysical: number,
        maxPhysical: number
    } = {
            minTick: 0,
            maxTick: 0,
            minPhysical: 0,
            maxPhysical: 0
        };

    private options: flowMeterGeometricData;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.VISUAL_FLOW_METER
        this.options = { model: VisualFlowMeterModel.OneToTenGPM, ...this.defaultOptions(VisualFlowMeterModel.OneToTenGPM) };
    }

    public setOptions(options: flowMeterGeometricData) {
        this.options = { ...this.defaultOptions(options.model), ...options };
        return this;
    }

    public defaultOptions(model: VisualFlowMeterModel) {
        switch (model) {
            case VisualFlowMeterModel.OneToTenGPM:
                return {
                    location: BABYLON.Vector3.Zero(),
                    clearGlassHeight: 0.16,
                    clearCylinderDiameter: 0.0254,
                    bobHeight: 0.02,
                    baseCylinderHeight: 0.02,
                    nrTickMarks: 20,
                    maxValueQ: _u(10, "gpm"),
                    minValueQ: _u(1, "gpm")
                };
            case VisualFlowMeterModel.FourToTwentyEightGPM:
                return {
                    location: BABYLON.Vector3.Zero(),
                    clearGlassHeight: 0.16,
                    clearCylinderDiameter: 0.0254,
                    bobHeight: 0.02,
                    baseCylinderHeight: 0.02,
                    nrTickMarks: 20,
                    maxValueQ: _u(28, "gpm"),
                    minValueQ: _u(4, "gpm")
                };

        }
    }


    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        super.addPreloadAssets(assetManager);
    }

    render() {
        this.flowDisplay = new InstrumentDisplayValue(0, "gpm",
            {
                minValue: this.options.minValueQ,
                maxValue: this.options.maxValueQ,
                overflow: RangeOverFlowOptions.NONE
            },
            {
                addNoise: true,
                type: NoiseType.PerOfRange,
                correlation: NoiseCorrelation.SinVariationWithTime,
                percent: 0.5,
                period: 0.001
            }
        );

        this.myContainerNode = new BABYLON.Mesh("valveContainer", this.scene);
        if (this.parent !== null) {
            this.myContainerNode.parent = this.parent.getContainerMesh();
        }

        const clearCylinder = BABYLON.CylinderBuilder.CreateCylinder("Glass",
            {
                height: this.options.clearGlassHeight,
                diameter: 2 * this.options.clearCylinderDiameter
            }, this.scene);
        clearCylinder.parent = this.myContainerNode;

        this.flowBob = BABYLON.CylinderBuilder.CreateCylinder("Bob",
            {
                height: this.options.bobHeight,
                diameter: 1.0 * this.options.clearCylinderDiameter
            }, this.scene);
        this.flowBob.parent = this.myContainerNode;
        this.flowBob.material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Yellow());

        const baseCylinder = BABYLON.CylinderBuilder.CreateCylinder("Base Metal Ring",
            {
                height: this.options.baseCylinderHeight,
                diameter: 2.5 * this.options.baseCylinderHeight
            }, this.scene);
        baseCylinder.parent = this.myContainerNode;
        baseCylinder.material = MaterialRegistry.getSilver(this.scene);

        // materials
        clearCylinder.material = MaterialRegistry.getGlass(this.scene);

        // position parts
        const placementPos = this.options.location.clone();
        this.flowBobYPositionLimits.minPhysical = placementPos.y - (this.options.clearGlassHeight) / 2.0 + this.options.bobHeight / 2.0;
        this.flowBobYPositionLimits.minTick = placementPos.y - (this.options.clearGlassHeight) / 2.0 + this.options.bobHeight;
        this.flowBobYPositionLimits.maxTick = placementPos.y + (this.options.clearGlassHeight) / 2.0 - 3 * this.options.bobHeight / 2.0;
        this.flowBobYPositionLimits.maxPhysical = placementPos.y + (this.options.clearGlassHeight) / 2.0 - this.options.bobHeight / 2.0;


        clearCylinder.position = placementPos.clone();

        baseCylinder.position = placementPos.clone();
        baseCylinder.position.y = this.options.location.y - this.options.clearGlassHeight / 2.0 - this.options.baseCylinderHeight / 2.0;

        const topCylinder = baseCylinder.clone();
        topCylinder.name = "Top metal Ring"
        topCylinder.position.y = this.options.location.y + this.options.clearGlassHeight / 2.0 + this.options.baseCylinderHeight / 2.0;

        this.flowBob.position = placementPos.clone();
        this.flowBob.position.y = this.flowBobYPositionLimits.minTick;

        const nrTickMarks = this.options.nrTickMarks;

        const offset_x = this.options.location.x;
        const offset_z = this.options.location.z;
        const offset_y = this.flowBobYPositionLimits.minTick + this.options.bobHeight / 2.0;

        const deltaY = (this.flowBobYPositionLimits.maxTick - this.flowBobYPositionLimits.minTick) / nrTickMarks;
        const radius = 2.0 * this.options.clearCylinderDiameter / 2.0;

        const pathHelix = [];
        const colors = [];

        for (let j = 0; j <= nrTickMarks; j++) {


            let i = 0;
            let v = 0;
            for (i = 15; i <= 20; i++) {
                const minorTick = j % 5; // every fifth tick mark is wider

                v = 2.0 * Math.PI * i / (20 * (minorTick ? 1 : 0.5));

                if (i === 15) {
                    pathHelix.push(getCoor(v, j));
                    colors.push(new BABYLON.Color4(1, 1, 1, 0));
                }

                pathHelix.push(getCoor(v, j));
                colors.push(new BABYLON.Color4(1, 1, 1, 1));
            }

            pathHelix.push(getCoor(v, j));
            colors.push(new BABYLON.Color4(1, 1, 1, 0));
        }

        const lines = BABYLON.MeshBuilder.CreateLines("helixLines", { points: pathHelix, colors: colors }, this.scene);
        lines.parent = this.myContainerNode;

        function getCoor(v: number, j: number) {
            const x = radius * Math.cos(v) + offset_x;
            const y = j * deltaY + offset_y;
            const z = radius * Math.sin(v) + offset_z;

            return new BABYLON.Vector3(x, y, z);
        }

        return super.render();
    }

    setObjectStateFromTable(variableTable: VariableTable) {
        if (!this.flowBob)
            return;

        const time = variableTable.get("TIME");
        this.flowBob.position.y
            = this.flowDisplay
                ?.copy(variableTable.get(this.name))
                .interpolateToNewRange(this.flowBobYPositionLimits.minTick, this.flowBobYPositionLimits.maxTick, time?.in("s"))
            ?? 0;

        // clip at physical limits
        if (this.flowBob.position.y < this.flowBobYPositionLimits.minTick) {
            this.flowBob.position.y = this.flowBobYPositionLimits.minPhysical;
        }

        if (this.flowBob.position.y > this.flowBobYPositionLimits.maxPhysical) {
            this.flowBob.position.y = this.flowBobYPositionLimits.maxPhysical;
        }

        super.setObjectStateFromTable(variableTable);
    }

    setTableFromObjectState(variableTable: VariableTable) {
        super.setTableFromObjectState(variableTable);
    }
}

