<template>
  <teleport v-if="dialog"
            to="body">
    <div class="b-overlay">
      <div class="modal-open ">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title">
                <h4> {{ title }} </h4>
              </div>
            </div>
            <div class="modal-body">
              <p> {{ message }} </p>
              <input v-if="requestValue"
                     v-model="inputValue"
                     type="text"
                     class="form-control">
            </div>
            <div class="modal-footer">
              <button :class="options.cancel_class"
                      type="button"
                      @click="cancel">
                {{ options.cancel_text }}
              </button>
              <button :class="options.ok_class"
                      type="button"
                      @click="agree">
                {{ options.ok_text }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs } from "vue";
  import { ConfirmDialogButtonOptions } from "./b-confirm-dialog.interface";

  export default defineComponent({
    props: {
      requestValue: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const state = reactive({
        title: "",
        message: "",
        inputValue: "",
        dialog: false,
        resolve: null as null | ((v: unknown) => void),
        reject: null as null | ((v: unknown) => void),
        options: {
          cancel_text: "Cancel",
          cancel_class: "btn btn-danger",
          ok_text: "Ok",
          ok_class: "btn btn-success",
        },
      });

      const open = (
        title: string,
        message: string,
        options: ConfirmDialogButtonOptions
      ) => {
        state.dialog = true;
        state.title = title;
        (state.message = message),
          (state.options = Object.assign(state.options, options));
        return new Promise((resolve, reject) => {
          state.resolve = resolve;
          state.reject = reject;
        });
      };

      const agree = () => {
        if (props.requestValue) {
          state.resolve?.({ isConfirmed: true, value: state.inputValue });
        } else {
          state.resolve?.(true);
        }
        state.dialog = false;
      };

      const cancel = () => {
        state.resolve?.(false);
        state.dialog = false;
      };

      return {
        ...toRefs(state),
        open,
        agree,
        cancel,
      };
    },
  });
</script>

<style scoped>
  .b-overlay {
    background-color: #0000004a;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in;
    left: 0;
    top: 0;
    z-index: 999999999999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: baseline;
  }
</style>