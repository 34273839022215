import { RequestResponse } from "@/components/global/response.interface";
import { QuestionModel } from "../model/model-question";
import { QRandom } from "../model/qRandom";
import { UserResponseSetupData } from "./user-response.interface";

export function generateFixedMultipleChoice(rrd: UserResponseSetupData, model: QuestionModel): { index: number, array: string[] } {
   const answers: string[] = [];

   const statement = model.evaluateStatement(rrd.correctAnswer);
   if (!statement.hasError()) {
      answers.push(statement.getData());
   }

   rrd.incorrectAnswers.forEach((v) => {
      const tmpAns: RequestResponse<string> = model.evaluateStatement(v);
      if (!tmpAns.hasError()) {
         const data = tmpAns.getData();

         if (answers.findIndex((answer) => { return answer == data; }) === -1) {
            answers.push(data);
         }
      }
   });

   const shuffle = (array: string[], index: number) => {
      for (let i = array.length - 1; i > 0; i--) {
         const j = Math.floor(QRandom.getRnd() * i);

         if (index === i) {
            index = j;
         }
         if (index === j) {
            index = i;
         }

         const temp = array[i];
         array[i] = array[j];
         array[j] = temp;
      }
      return { index, array };
   };

   return shuffle(answers, 0);
}
