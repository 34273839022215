
  import { defineComponent, PropType, Ref, ref } from 'vue'
  import BImg from './b-img.vue';
  import { BCatalogEntry } from './b-catalog.interface';

  export default defineComponent({
    components: { BImg },
    props: {
      modelValue: {
        type: Object as PropType<{ id: string }[]>,
        required: true
      },
      itemList: {
        type: Array as PropType<BCatalogEntry[]>,
        required: true
      },
      itemEntryMaker: {
        type: Function as PropType<(item: BCatalogEntry) => { id: string }>,
        required: true
      }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const selectedList: Ref<{ id: string }[]> = ref(props.modelValue);

      const onClick = (item: BCatalogEntry): void => {

        const index = selectedList.value.findIndex(entry => entry.id === item.id);
        if (index !== -1) {
          selectedList.value.splice(index, 1);
        } else {
          selectedList.value.push(props.itemEntryMaker(item));
        }

        context.emit("update:modelValue", selectedList.value);
      }

      const isSelected = (id: string) => {
        return selectedList.value.findIndex(item => item.id === id) !== -1;
      }


      return {
        isSelected,
        selectedList,
        onClick
      }
    }
  })
