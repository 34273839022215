<template>
  <suspense>
    <template #default>
      <div class="container-fluid">
        <accomodation-selector :key="showAccomodation"
                               v-model:show="showAccomodation"
                               :schema="modalSchema"
                               :onOK="onAcceptAccomodation" />
        <div class="row">
          <div class="col">
            <h2 v-if="detailView">
              <b-button label="Back to Grade Book"
                        class="btn btn-primary"
                        @click="detailView = false" />
              Detail View
            </h2>
            <h2 v-else>
              Grade Book
            </h2>
          </div>
          <div v-if="isAdmin && !detailView"
               class="col ">
            <BButton class="btn btn-primary float-end"
                     icon="bi-download"
                     title="Download"
                     @click="downloadExcel" />
            <div class="float-end pe-4">
              <b-radio-button-group v-model:value="clickMode"
                                    button-class="btn-primary me-1"
                                    :buttons="adminRadioButtons" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <template v-if="!detailView">
              <BTable :fields="assignFields"
                      class="m-0 p-0"
                      style="max-width:2000px"
                      :data="assignmentScores"
                      table-classes="table-bordered table-striped table-hover"
                      @click="handleClick" />
            </template>
            <template v-else>
              <progress-detail :user-name="userName"
                               :assignments="assignments"
                               :assignment-id="detailAssignmentId"
                               :course-id="courseId"
                               :graded-assignments="gradedAssignments"
                               :registered-users="userProfiles" />
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      <b-loading label="Loading Quiz ... " />
    </template>
  </suspense>
</template>

<script lang="ts">
  import { Submissions } from "@/API";
  import BButton from "@/components/bootstrap/b-button.vue";
  import BTable from "@/components/bootstrap/table/b-table.vue";
  import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
  import { BaseEventI } from "@/components/global/event.interface";
  import router from "@/router";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent, onUnmounted, reactive, Ref, ref } from "vue";
  import ProgressDetail from "./ProgressDetail.vue";
  import { GradedAssignement, ReturnAssignmentData, useGradedAssignments } from "./useGradedAssignments";
  import { sort } from 'fast-sort';
  import BLoading from "@/components/bootstrap/b-loading.vue";
  import BRadioButtonGroup from "@/components/bootstrap/b-radio-button-group.vue";
  import AccomodationSelector from "../../components/formGenerator/form-modal-viewer.vue";
  import { getAccomodationSchema } from './Accomodation.schema'
  import { UpdateAccomodationRequest, useSubmissions } from "@/components/contentGenerator/questions/components/useSubmissions";
  import { bTableEvent } from "@/components/bootstrap/table/b-table.interface";


  export default defineComponent({
    components: { BTable, BButton, ProgressDetail, BLoading, BRadioButtonGroup, AccomodationSelector },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      const adminRadioButtons = [
        { label: "View", value: "view" },
        { label: "Override Grade", value: "override" },
        { label: "Accomodation", value: "accomodation" },
      ]
      const clickMode = ref("view");
      const showAccomodation = ref(false);
      const modalSchema = ref({});
      const accomodationMetaData: Ref<Record<string, unknown>> = ref({});

      const detailView = ref(false);
      const detailAssignmentId = ref("");

      const { userName, isAdmin } = useUserStore();
      if (!userName) return null;

      let unsubscribe = () => { return; };

      onUnmounted(() => {
        unsubscribe();
      })

      const {
        assignFields,
        addAssignmentToTable,
        pageComponents,
        lookupComponentData,
        assignments,
        getStudentAssignments,
        getAllStudentAssignments,
        findMostRecentGradedAssignments,
        computeGradesForEachAssignment,
        subscribeToSubmissions,
        reComputeSubmissionGrade,
        getAssignmentToComponentMap
      } = await useGradedAssignments(props.courseId, userName);

      assignments?.forEach((assignment): void => {
        addAssignmentToTable(assignment);
      });

      console.log("Assignment Fields", assignFields);
      console.log("page components", pageComponents);
      console.log("lookupComponentData", lookupComponentData);

      let unorganizedAssignmentSubmissions = isAdmin ?
        await getAllStudentAssignments() :
        await getStudentAssignments();

      //console.log({ unorganizedAssignmentSubmissions });

      // pick out the latest question version for each assignment
      const gradedAssignments: Record<string, GradedAssignement[]> =
        findMostRecentGradedAssignments(unorganizedAssignmentSubmissions);

      // const { getRegisteredUsers } = useCourseMaterials();
      // const registeredUserData = await getRegisteredUsers(props.courseId);

      const { getUserProfilesForCourse } = useDatabaseMappings();
      const userProfiles = await getUserProfilesForCourse(props.courseId);
      console.log({ userProfiles })

      let assignmentScores = reactive(computeGradesForEachAssignment(userProfiles,
        gradedAssignments));

      console.log({ assignmentScores });

      unsubscribe = await subscribeToSubmissions(props.courseId, (item: Submissions) => {
        const new_userID = item.userEmail;
        const assignmentID = item.searchID?.split('#')[0];

        if (TypeGuard.isNullOrUndefined(assignmentID)) {
          return;
        }

        const toChange = assignmentScores.filter((v) => { return v.userID === new_userID })[0];
        toChange[assignmentID] = reComputeSubmissionGrade(item as unknown as ReturnAssignmentData);

        return;
      });


      assignmentScores = sort(assignmentScores).asc((u: any) => u.lastName.toLowerCase());

      const downloadExcel = () => {
        const mimeType = 'data:application/vnd.ms-excel';
        //const html = this.$refs.gradeTable.$el.innerHTML;
        // assemble
        let titles: string[] = [];
        let keys: string[] = [];
        let arrData: unknown[] = [];

        assignFields.forEach((f) => {
          titles.push(f.label ?? "");
          keys.push(f.key ?? "");
        });

        assignmentScores.forEach((i) => {
          let iData: unknown[] = [];
          keys.forEach((k) => {
            if (TypeGuard.hasProp(i, k)) {
              iData.push(TypeGuard.hasProp(i[k], 'grade') ? (i[k] as any).grade : i[k]);
            } else {
              iData.push('');
            }
          });
          arrData.push(iData.join(','));
        });

        const html = titles.join(',') + '\n' + arrData.join('\n');

        const documentPrefix = 'Gradebook';
        const d = new Date();
        const dummy = document.createElement('a');
        dummy.href = mimeType + ', ' + html;
        dummy.download = documentPrefix
          + '-' + d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
          + '-' + d.getHours() + '-' + d.getMinutes() + '-' + d.getSeconds()
          + '.csv';
        document.body.appendChild(dummy);
        dummy.click();
      }

      const handleClick = (event: bTableEvent) => {
        if (clickMode.value === "accomodation") {
          showAccomodation.value = true;
          const lastName = pullEventRowData(event, "lastName");
          const firstName = pullEventRowData(event, "firstName");
          const email = pullEventRowData(event, "userEmail");
          const userID = pullEventRowData(event, "userID");

          modalSchema.value = getAccomodationSchema(firstName, lastName, email,
            (event.payload as any).field.label);

          const assignmentID = assignFields[event.payload?.colIndex || 0].meta?.assignmentID as string;
          accomodationMetaData.value = {
            email,
            componentID: getAssignmentToComponentMap()[assignmentID],
            userID
          };

          return;
        }

        if (!event.payload || !isAdmin) return;

        if (event.type === "clickRow") {
          const assignmentField = assignFields[event.payload?.colIndex]
          router.push({
            name: "AssignmentViewer",
            params: {
              courseId: props.courseId,
              textId: assignmentField.meta?.textID as string,
              rootPageId: assignmentField.meta?.assignmentID as string,
              pageId: assignmentField.meta?.assignmentID as string,
            },
          });
        } else {
          console.log("Header");
          detailView.value = true;
          detailAssignmentId.value = (event.payload.field as any).meta.assignmentID;
        }
      }

      const { updateAccomodations } = useSubmissions();
      const onAcceptAccomodation = (jsonData: any) => {
        const accomodationData: UpdateAccomodationRequest = {
          courseID: props.courseId,
          componentID: accomodationMetaData.value.componentID as string,
          userId: accomodationMetaData.value.userID as string,
          accomodationType: jsonData.data._groupSelectedType,
          accomodationValue: JSON.stringify({
            extraTime: jsonData.data.extraTime,
            dueOn: jsonData.data.dueOn,
            openOn: jsonData.data.openOn,
          })
        };
        console.log(accomodationData);

        console.log(updateAccomodations(accomodationData));
        return;
      }

      function pullEventRowData(event: BaseEventI<unknown, Record<string, unknown>>, propName: string) {
        return (event.payload as any).rowData[propName] as string;
      }

      return {
        accomodationMetaData,
        onAcceptAccomodation,
        modalSchema,
        showAccomodation,
        clickMode,
        detailView,
        isAdmin,
        downloadExcel,
        assignFields,
        assignmentScores,
        handleClick,
        userName,
        assignments,
        detailAssignmentId,
        gradedAssignments,
        userProfiles,
        adminRadioButtons
      };
    },
  });
</script>

<style scoped>
</style>