<template>
  <draggable :key="activeIndex"
             v-model="reactiveArray"
             tag="ul"
             handle=".handle"
             item-key="id"
             @change="onChange">
    <template #item="{element, index}">
      <li :class="(activeIndex=== index)?'selected drag-item':'drag-item'">
        <div class="container w-100 p-1"
             style="min-height:20px"
             @click="selectItem(index)">
          <div class="row">
            <div class="col-1">
              <i v-if="activeIndex === index"
                 class="bi bi-arrows-move handle" />
            </div>
            <div class="col">
              {{ (index + 1) + ' ' + element.name }}
            </div>
          </div>
        </div>
      </li>
    </template>
  </draggable>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import draggable from "vuedraggable";

  export interface BDragListInterface {
    selectItem(index: number): void;
  }

  interface moveAction {
    moved: { newIndex: number; oldIndex: number; element: unknown };
  }
  export default defineComponent({
    name: "BDragList",
    components: {
      draggable,
    },
    props: {
      initialSelection: { type: Number, default: 0 },
      modelValue: { type: Array, required: true },
    },
    emits: ["itemSelected", "update:modelValue"],
    setup(props) {
      const activeIndex = ref(props.initialSelection);
      const reactiveArray = ref(props.modelValue);
      return { reactiveArray, activeIndex };
    },
    methods: {
      selectItem(index: number): void {
        this.activeIndex = index;
        this.$emit("itemSelected", index);
      },
      onChange(action: moveAction): void {
        if (Object.hasOwnProperty.call(action, "moved")) {
          let newIndex = (action as moveAction).moved.newIndex;
          this.selectItem(newIndex);
          this.$emit("update:modelValue", this.reactiveArray);
        }
      },
    },
  });
</script>

<style scoped>
  .selected {
    background-color: rgb(69, 96, 117);
    color: white;
  }

  .drag-item {
    list-style: none;
  }
</style>