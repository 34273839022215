import * as BABYLON from 'babylonjs';

import { BasePrimativeObjectGenerator } from "../Factories/PrimativeFactoryInterface";

export class Pipe_Elbow_45 extends BasePrimativeObjectGenerator {
    constructor(scene: BABYLON.Scene) {
        super(scene);
        this.name = "Elbow 45";
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        super.addPreloadAssetURL(assetManager,
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/pipes/",
            "Elbow_45.obj");
    }
}