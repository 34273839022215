<template>
  <b-sortable-list :model-value="list"
                   :nestable="true"
                   :prefix="'1.'"
                   active-id="C1"
                   @update:modelValue="list=$event"
                   @change="log"
                   @click="log" />
  <button type="button"
          @click="deleteElement"
          v-text="'Delete'" />
  <div>
    {{ list }}
  </div>
  <div v-for="ev in eventList"
       :key="ev">
    {{ ev }}
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref } from 'vue'
  import bSortableList from './b-sortable-list.vue'

  export default defineComponent({
    name: "BSortableListTest",
    components: { bSortableList },
    setup() {
      const list = ref([{
        name: "P1",
        id: "P1",
        children: [
          {
            name: "C1",
            id: "C1",
            children: []
          },
          {
            name: "C2",
            id: "C2",
            children: []
          }
        ]
      },
      {
        name: "P2",
        id: "P2",
        children: []
      }])

      const eventList: unknown[] = reactive([]);

      const log = (event: unknown) => {
        console.log(event);
      }

      const deleteElement = () => {
        list.value.splice(0, 1)
      }

      return {
        log,
        eventList,
        list,
        deleteElement
      }
    }
  })
</script>

<style scoped>
</style>