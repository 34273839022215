import { FormSchemaItem, FormTypes, deepCombineSchemaSingleItem, FormTypesMap, FormProperties } from './form-schema.interface';
import { v4 as uuid4 } from "uuid";
/**
 * Factory class used to simplify the creation of a schema. 
 * Schema items are set to default values initially and can be 
 * overrriden through options parameter. 
 * Schema can be nested by using the GROUP maker.
 * */
export class FormSchemaMaker {
    private static Base(type: FormTypes, options: FormSchemaItem, children: FormSchemaItem[], defaultValue: string | unknown = "") {
        const defaultValues: FormSchemaItem = {
            name: "",
            fieldType: FormTypes.NONE,
            label: "",
            field: "",
            placeHolder: null,
            autocomplete: "",
            choices: [],
            hint: "",
            value: defaultValue,
            properties: {
                disabled: false,
                classes: "",
                hidden: false,
                labelCols: 2,
                labelAlign: "left",
                labelSize: "default",
                showJSON: false,
                customButtons: [],
                componentCols: 12,
                componentOffset: 0,
                hideLabel: false,
            },
            rules: {},
            children: [],
        };

        const result = deepCombineSchemaSingleItem(defaultValues, options, children);
        result.fieldType = type;
        result.formGen = FormTypesMap.get(result.fieldType);
        result._internalID = uuid4();
        return result;
    }

    static Tab(options: FormSchemaItem, children: FormSchemaItem[]): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.TAB, options, children);
    }

    static Group(options: FormSchemaItem, children: FormSchemaItem[]): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.GROUP, options, children);
    }

    static Row(options: FormSchemaItem, children: FormSchemaItem[]): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.ROW, options, children);
    }

    static ReadOnly(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.READONLY, options, []);
        (result.properties as FormProperties).disabled = true;
        return result;
    }

    static Hidden(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.READONLY, options, []);
        (result.properties as FormProperties).disabled = true;
        (result.properties as FormProperties).hidden = true;
        return result;
    }

    static ItemSubItem(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.SELECT_ITEM_SUBITEM, options, []);
    }

    static Color(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.COLOR, options, []);
    }

    static Text(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.TEXT, options, []);
    }

    static Email(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.EMAIL, options, []);
    }

    static Password(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.PASSWORD, options, []);
    }

    static TipTap(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.TIP_TAP, options, []);
    }

    static MarkdownIt(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.MARKDOWN_IT, options, []);
    }

    static Integer(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.INTEGER, options, [], 0);
    }

    static Number(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.NUMBER, options, [], 0);
    }

    static TextBox(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.TEXT_BOX, options, []);
    }

    static Divider(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.DIVIDER, options, []);
    }

    static CheckBox(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.CHECK_BOX, options, []);
    }

    static Date(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.DATE, options, []);
    }

    static Time(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.TIME, options, []);
    }

    static DateTime(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.DATE_TIME, options, []);
    }

    static FileLocation(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.FILE_LOCATION, options, []);
    }


    /**
     * Create an array of objects. 
     * The arrayTemplate includes schema that define the fields 
     * of the contained items.
     * @param options
     * @param arrayTemplate
     */
    static ArrayOfObject(options: FormSchemaItem, arrayTemplate: FormSchemaItem[]): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OBJECTS, options, arrayTemplate);
        // default value
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }


    static SelectFromCatalog(options: FormSchemaItem, arrayTemplate: FormSchemaItem[]): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.SELECT_CATALOG, options, arrayTemplate);
        // default value
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static ArrayOfObjectWithEditor(options: FormSchemaItem, arrayTemplate: FormSchemaItem[]): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OBJECTS_EDITOR, options, arrayTemplate);
        // default value
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static ArrayOfCourseTopics(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_COURSETOPICS, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static ArrayOfQuestions(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_QUESTIONS, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }


    static ArrayOfText(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_TEXT, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static ArrayOfEmail(options: FormSchemaItem): FormSchemaItem {
        options.rules = { ...options.rules, ...{ email: true } };
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_EMAIL, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }


    static ArrayOfFloats(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_FLOAT, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static ArrayOfInts(options: FormSchemaItem): FormSchemaItem {
        const result = FormSchemaMaker.Base(FormTypes.ARRAY_OF_INT, options, []);
        if (!('value' in options)) {
            result.value = [];
        }
        return result;
    }

    static Select(options: FormSchemaItem): FormSchemaItem {
        return FormSchemaMaker.Base(FormTypes.SELECT, options, []);
    }
}