
  import { defineComponent } from "vue";

  export default defineComponent({
    metaInfo() {
      return {
        title: "Vengla - Virtual Engineering Laboratories, Video Demonstration",
        meta: [
          {
            name: "description",
            content:
              "A video showing the graphics and basic usage of the Vengla virtual engineering system on a web browser.",
          },
        ],
      };
    },
    setup() {
      return {};
    },
  });
