
export enum bNavbarDropdownType {
    LINK,
    DIVIDER,
    TEXT,
    ACTION
}

export interface bNavbarDropdownPropTypes {
    type: bNavbarDropdownType;
    label?: string;
    to?: string | { name: string };
    iconName?: string;
    class?: string;
    restrictToAdmin?: boolean;
    action?: () => void;
}
