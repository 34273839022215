import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";

export type Maybe<T> = T | undefined | null;


export function Either<T, S>(value: Maybe<T>,
   Left: (x: T) => S,
   Right: (x: undefined | null) => S) {

   if (TypeGuard.isNullOrUndefined(value)) {
      return Right(value);
   } else {
      return Left(value);
   }
}