import * as BABYLON from 'babylonjs';

import { BasePrimativeObjectGenerator, PrimativeOptions } from "../Factories/PrimativeFactoryInterface";
import { MaterialRegistry } from "../Materials/MaterialRegistry";



export interface PipeBoardOptions extends PrimativeOptions {
    name?: string,
    height: number,
    width: number,
    color: BABYLON.Color3
}

export class Pipe_MountBoard extends BasePrimativeObjectGenerator {

    public defaultPrimOptions: PipeBoardOptions = {
        name: "",
        height: 1,
        width: 1,
        color: BABYLON.Color3.Blue(),
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0
    }

    constructor(scene: BABYLON.Scene) {
        super(scene);
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        return;
    }

    public create(parent: BABYLON.Mesh, options: PipeBoardOptions) {
        options = { ...this.defaultPrimOptions, ...options };
        const board = BABYLON.MeshBuilder.CreateBox("Pipe Board", {
            height: options.height,
            width: options.width,
            depth: 0.02
        }, this.scene);
        board.material = MaterialRegistry.getColor(this.scene, options.color);

        return this.adjustMesh(board, options, parent);
    }
}