import * as BABYLON from 'babylonjs';
import { BaseLaboratory } from './BaseLaboratory';
import { RenderedObject } from '../primatives/BaseRenderedObject';
import { PipeLossStation_Minor } from '../experiments/PipeLoss_Minor';
import { Nullable } from 'babylonjs/types';

export class MinorLossesLaboratory extends BaseLaboratory {
	constructor(name: string, scene: BABYLON.Scene, parent: Nullable<RenderedObject>) {
		super(name, scene, parent);

		// create experiments
		this.experiments.push(new PipeLossStation_Minor("Pipe Loss Experiment", this.scene, this));

		this.experiments.forEach((v) => {
			this.addChild(v);
		});
	}

	public render() {
		const floorWidth = this.options.floorWidth ?? 0;
		this.setCamera(0, floorWidth / 2.0 - 3, 0, 1.3, floorWidth / 2.0 - 0.2);

		// set options on components and experiments here
		// set options on components and experiments here
		this.experiments[0]
			.setOptions({
				location: new BABYLON.Vector3(0, 1.5, floorWidth / 2.0),
				rotationY: 0 // Math.PI / 2.0
			});

		return super.render();
	}
}

