<template>
  <select :id="id"
          v-model="currentValue"
          :readonly="isDisabled">
    <option disabled
            value="">
      Select
    </option>
    <option v-for="(option) in options"
            :key="option"
            :value="option">
      {{ option }}
    </option>
  </select>
</template>
 
<script lang="ts">
  import { defineComponent, ref, watch } from "vue";

  export default defineComponent({
    props: {
      id: {
        type: String,
        default: "sel1"
      },
      modelValue: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        default: () => {
          return [];
        },
      },
      isDisabled: { type: Boolean, default: false },
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const currentValue = ref(props.modelValue);

      watch(currentValue, (newValue: unknown) => {
        context.emit("update:modelValue", newValue);
      });

      return {
        currentValue,
      };
    },
  });
</script>

<style scoped>
</style>