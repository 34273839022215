
  import BButton from '@/components/bootstrap/b-button.vue'
  import BModalView from '@/components/bootstrap/b-modal-view.vue'
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import { FormSchemaItem } from '@/components/formGenerator/form-schema.interface'
  import useSchema from '@/components/use/useSchema'
  import { useVModel } from '@vueuse/core'
  import { defineComponent, PropType } from 'vue'

  export default defineComponent({
    components: { FormRoot, BModalView, BButton },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      schema: {
        type: Object as PropType<FormSchemaItem>,
        required: true
      },
      onOK: {
        type: Function,
        default: () => { return; }
      },
      title: {
        type: String,
        default: ""
      },
    },
    setup(props, { emit }) {
      const { schema_getJSON } = useSchema();
      const showModal = useVModel(props, 'show', emit);
      const onChange = () => { return; };

      const onCancel = () => {
        showModal.value = false;
      }

      const handleOK = () => {
        const data = schema_getJSON(props.schema);
        console.log(data)
        props.onOK(data);
      }

      return {
        handleOK,
        showModal,
        onChange,
        onCancel
      };
    }
  })
