<template>
  <suspense>
    <template #default>
      <div v-if="(isOpen || isAdmin) && ((!isExam && !isLink) || allowInput)"
           class="p-0 p-md-2">
        <div v-if="countDown"
             class="row text-end">
          <h4> Remaining Time: {{ countDown }} </h4>
        </div>
        <div class="container-fluid shadow p-0">
          <div class="row g-0 border-bottom p-2 rounded-top quiz-header">
            <div class="col-3">
              <BButton class="btn btn-primary"
                       icon="bi-chevron-left"
                       @click="setPageNr(activePageNr - 1)" />
            </div>
            <div class="col text-center">
              <BButton v-for="(question, index) in questions"
                       :key="index"
                       class="btn ps-1 ps-sm-2 pe-1 pe-sm-2"
                       :class="{ active: index == activePageNr}"
                       icon="bi-square"
                       @click="setPageNr(index)" />
            </div>
            <div class="col-3 text-end">
              <BButton class="btn btn-primary"
                       icon="bi-chevron-right"
                       @click="setPageNr(activePageNr + 1)" />
            </div>
          </div>
          <div class="row g-0">
            <div class="col">
              <QuestionViewer :key="activePageNr"
                              :course-id="courseID"
                              :question-data="currentQuestionData"
                              :question-bank-id="currentQuestionBankID"
                              :component-id="content.id"
                              :allow-input="allowInput"
                              :show-solution="showSolution || (isAdmin && isLink)" />
            </div>
          </div>
          <div class="row g-0 rounded-bottom p-2 quiz-header" />
        </div>
      </div>
      <div v-else
           class="text-center m-4 p-4">
        <div v-if="!isOpen">
          <h2> {{ pageName }}</h2>
          <h4> Assignment Opens at : {{ readableOpenOn }} </h4>
        </div>
      </div>
    </template>
    <template #fallback>
      <b-loading label="Loading Quiz ... " />
    </template>
  </suspense>
</template>

<script lang="ts">
  import BButton from "@/components/bootstrap/b-button.vue";
  import { defineComponent, inject, PropType, ref } from "vue";
  import { Page_QuizComponent } from "../pages/pages.interface";
  import usePagination from "@/components/use/usePagination";
  import QuestionViewer from "../questions/QuestionViewer.vue";
  import { useSubmissions } from "../questions/components/useSubmissions";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import useTime from "@/components/use/useTime";
  import useQuestionsFromQuizComponent from "@/components/use/useQuestionsFromQuizComponent";
  import BLoading from "@/components/bootstrap/b-loading.vue";
  import { useIntervalFn } from "@vueuse/core";
  import { useDueDates } from "@/views/student/useDueDates";


  export default defineComponent({
    components: { BButton, QuestionViewer, BLoading },
    props: {
      content: {
        type: Object as PropType<Page_QuizComponent>,
        required: true,
      },
    },
    async setup(props) {
      let countDown = null;
      const isExam = ref(false);
      const isLink = ref(false);
      const isOpen = ref(true);
      const courseID = inject("courseId", "empty") as string;
      const pageData = inject("pageData", {}) as PageFromTextbookTable;
      const pageName = pageData.name;
      const showSolution = ref(false);
      const allowInput = ref(true);

      const { datePlusOffsetHasPast, countDownTo } = useTime();

      console.log("Quiz:", props.content.id);
      console.log("Course:", courseID);
      console.log({ pageData });


      const {
        getLocalTimeFromTimeStamp,
        computeRelativeDateString
      } = useTime();

      const {
        questionIDs,
        questionBankInfo,
        pullQuestions
      } = useQuestionsFromQuizComponent(props.content);

      const questions = await pullQuestions();

      const currentQuestionData = ref(questions[0]);
      const currentQuestionBankID = ref(questionBankInfo.value[0].questionBankID)

      const { activePageNr, setPageNr, getMaxNrPages } = usePagination(
        0,
        ref(() => questions.length),
        () => {
          currentQuestionData.value = questions[activePageNr.value];
          currentQuestionBankID.value = questionBankInfo.value[activePageNr.value].questionBankID
          //context.emit("change", activePageNr.value);
        }
      );

      const { pullAssignmentSubmission } = useSubmissions();
      const { userName, isAdmin } = useUserStore();

      const submission = await pullAssignmentSubmission({
        courseID,
        componentID: props.content.id,
        userEmail: userName as string,
        questionIDs,
      });

      const { getDueDates } = useDueDates();
      const { dueOn, openOn } = getDueDates(pageData, submission);
      const readableOpenOn = computeRelativeDateString(new Date(openOn).toISOString());

      if (pageData.fieldType === "ASSIGNMENT") {
        if (datePlusOffsetHasPast(dueOn, 1)) {
          showSolution.value = true;
        }
      }

      if (pageData.fieldType === "ASSIGNMENT" || pageData.asn_options?.catagory === "Exam" || pageData.asn_options?.catagory === "Link") {

        isOpen.value = openOn < new Date().getTime();
        isLink.value = pageData.asn_options?.catagory === "Link";
        isExam.value = pageData.asn_options?.catagory === "Exam";

        if (datePlusOffsetHasPast(dueOn, 0)) {
          allowInput.value = false;
        } else {
          countDown = countDownTo(dueOn);
        }

        useIntervalFn(() => {
          isOpen.value = openOn < new Date().getTime();
          allowInput.value = dueOn > new Date().getTime();
        }, 2000);
      }




      return {
        pageName,
        countDown,
        readableOpenOn,
        isExam,
        isLink,
        isOpen,
        isAdmin,
        allowInput,
        courseID,
        activePageNr,
        setPageNr,
        getMaxNrPages,
        questions,
        currentQuestionData,
        currentQuestionBankID,
        showSolution,
        getLocalTimeFromTimeStamp
      };
    },
  });
</script>

<style scoped>
  .quiz-header {
    background-color: lightgray;
  }

  .active {
    color: yellow;
  }
</style>