
  import { sort } from 'fast-sort';
  import bTable from '@/components/bootstrap/table/b-table.vue';
  import { defineComponent } from 'vue'
  import { listUserPermissionss } from '@/graphql/queries';
  import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
  import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
  import useTime from '@/components/use/useTime';

  export default defineComponent({
    components: { bTable },
    async setup() {
      const { computeRelativeDateString } = useTime();

      const regFields = [
        {
          key: 'createdAt', label: 'Date', sortable: true,
          formatter: (value: any) => {
            console.log(value)
            return new Date(value).toLocaleString("en-GB")
          },
        },
        {
          key: 'lastLogin', label: 'Last Login', sortable: true,
          formatter: (value: any) => {
            return computeRelativeDateString(value);
          },
        },
        { key: 'nrVisits', label: 'Visits', sortable: true },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'userEmail', label: 'Email', sortable: true, includeInFilter: true },
        { key: 'profile.last_name', label: 'Last', sortable: true, includeInFilter: true },
        { key: 'profile.first_name', label: 'First', sortable: true, includeInFilter: true },
        { key: 'profile.school', label: 'School', sortable: true, includeInFilter: true },
        //   { key: 'profile.agree', label: 'Terms', sortable: true },
      ];

      const queryData = await GraphQLAPI.graphql(graphqlOperation(listUserPermissionss, { input: {} }));

      const items = (queryData as any).data.listUserPermissionss.items;
      console.log(items)
      const userData = sort(items).desc((u: any) => u.lastLogin);

      const { computeNrDaysPast } = useTime();

      userData.forEach((v: any) => {
        if (TypeGuard.isNullOrUndefined(v.profile) || v.profile === "")
          v.profile = { last_name: "", first_name: "", school: "" }
        else
          v.profile = JSON.parse(v.profile);

        const daysPast = computeNrDaysPast(v.lastLogin);
        v._rowVariant = daysPast > 30 ? "text-danger" : (daysPast > 7 ? "text-primary" : "text-success");
      });

      console.log(userData);

      return {
        userData,
        regFields
      }
    }
  })
