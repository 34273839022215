import { MjsMath } from "./math-core";
import { mjsUnknown } from "./math-types";
import { TypeGuard } from "./Type-guards";

export class VariableTable {
   private _values: Record<string, mjsUnknown> = {};
   get values(): Record<string, mjsUnknown> { return this._values; }

   listOfVariableNames() {
      return Object.keys(this._values);
   }

   clear(): void {
      this._values = {};
   }

   setValue(key: string, value: mjsUnknown): void {
      if (TypeGuard.isString(value)) {
         this._values[key] = MjsMath.evaluate(value);
      } else {
         this._values[key] = value;
      }
   }

   getNumericValue(key: string, inUnit?: string) {
      if (!this.hasValueKey(key)) {
         return `Unable to find variable: ${key}`;
      }

      const storedVal = this._values[key];
      return _convertToNumeric(storedVal);

      function _convertToNumeric(val: mjsUnknown) {
         if (TypeGuard.isNumber(val)) {
            return val;
         }

         if (TypeGuard.isUnit(val)) {
            if (inUnit) {
               return val.toNumeric(inUnit);
            }
            const unitStr = val.formatUnits();
            return val.toNumber(unitStr);
         }

         if (TypeGuard.isMatrix(val)) {
            const vecNumericVal: number[] = [];
            val.forEach((item) => {
               console.log(item)
               const numericVal = _convertToNumeric(item);
               if (TypeGuard.isNumber(numericVal)) {
                  vecNumericVal.push(numericVal);
               }
            });
            return vecNumericVal;
         }
      }
   }

   setUnitValue(key: string, value: number, unit: string) {
      if (unit === "") {
         this._values[key] = value;
      } else
         this._values[key] = MjsMath.createUnit(value, unit);
   }

   hasValueKey(key: string): boolean {
      return Object.prototype.hasOwnProperty.call(this._values, key);
   }

}
