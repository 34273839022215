import * as BABYLON from 'babylonjs';
import { ImageButtonMap } from './ImageButtonMap';
import { BaseRenderedObject } from '../../primatives/BaseRenderedObject';
import { MaterialRegistry } from '../../primatives/Materials/MaterialRegistry';
import { PortInternals, ConnectableObjectInterface, CordConnector } from './CordConnector';
import { VariableTable } from '../../values/VariableTable';
import { ScalarValue } from '../../values/ScalarValue';
import { CurvedRectangularMesh } from '../../primatives/CurvedRectangularMesh';
import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';

interface StateMachineInterface {
    states: { [key: string]: any; },
    computed: { [key: string]: (esm: ElectronicStateMachine) => any },
    actions: { [key: string]: (esm: ElectronicStateMachine) => void }
}

export class ElectronicStateMachine {
    private state: Map<string, any> = new Map();
    private actions: Map<string, (stateMachine: ElectronicStateMachine) => void> = new Map();
    private computed: Map<string, (stateMachine: ElectronicStateMachine) => any> = new Map();

    constructor(inputObject: StateMachineInterface) {
        const obj = inputObject.states;
        Object.keys(obj).forEach((key) => { this.set(key, obj[key]); });

        const actions = inputObject.actions;
        Object.keys(actions).forEach((key) => { this.addAction(key, actions[key]); });

        const computed = inputObject.computed;
        Object.keys(computed).forEach((key) => { this.addComputed(key, computed[key]); });

        this.set('isOn', false);
    }

    public addAction(name: string, action: (stateMachine: ElectronicStateMachine) => void) {
        this.actions.set(name, action);
        return this;
    }

    public addComputed(name: string, getter: (stateMachine: ElectronicStateMachine) => void) {
        this.computed.set(name, getter);
        return this;
    }

    public set(stateName: string, value: any) {
        this.state.set(stateName, value);
        return this;
    }

    public get(stateName: string) {
        if (!this.state.has(stateName)) { throw Error('State is not contained in Electronic State Machine') }
        return this.state.get(stateName);
    }

    public limitValue(stateName: string, minValue: number, maxValue: number) {
        const val = this.state.get(stateName);
        if (val < minValue) this.state.set(stateName, minValue);
        else if (val > maxValue) this.state.set(stateName, maxValue);
        return this;
    }

    public trigger(action: string) {
        if (!this.actions.has(action)) { throw Error('Action not contained in Electronic State Machine'); }
        this.actions.get(action)!(this);
        return this;
    }

    public getComputed(getter: string) {
        if (!this.computed.has(getter)) { throw Error('Getter not contained in Electronic State Machine'); }
        return this.computed.get(getter)!(this);
    }

    // compulsory states
    public hasPower() { return this.get('isOn'); }
    public togglePower() { this.toggle('isOn'); }

    // computation functions 

    public toggle(stateName: string) {
        this.set(stateName, !this.get(stateName));
        return this;
    }

    public incrementListSelection(indexName: string, listName: string, forward = true) {
        let curIndex = this.get(indexName);
        curIndex += forward ? 1 : -1;

        const listLen = this.get(listName).length;
        if (curIndex >= listLen)
            curIndex = 0;

        if (curIndex < 0)
            curIndex = listLen - 1;



        this.set(indexName, curIndex);
        return this;
    }

    public addToState(stateName: string, value: number) {
        this.set(stateName, this.get(stateName) + value);
        return this;
    }
}

export class ElectronicDisplay {
    private meterTexture: BABYLON.DynamicTexture;
    private resolution: number;
    private invertY = true;
    //private clearColor: string = "#70FFF5"; // for creating new displays
    private clearColor = "transparent";
    private textColor = '#70FFF5';
    private fontFamily = "Roboto Mono";

    constructor(scene: BABYLON.Scene,
        parent: BABYLON.Mesh,
        resolution: number,
        width: number,
        height: number,
        location: BABYLON.Vector3,
        rotationX: number) {
        this.resolution = resolution;

        const displaySurf = BABYLON.PlaneBuilder.CreatePlane("Instrument", { width: width, height: height });
        displaySurf.position.copyFrom(location);
        displaySurf.setParent(parent);
        displaySurf.addRotation(rotationX, 0, 0);

        this.meterTexture = new BABYLON.DynamicTexture("Electronic Disp Texture", resolution, scene, true);
        this.meterTexture.level = 2;

        const mat = new BABYLON.StandardMaterial("Electronic Display Mat", scene);
        mat.diffuseTexture = this.meterTexture;
        mat.diffuseTexture.hasAlpha = true;
        displaySurf.material = mat;
    }

    public clear() {
        this.meterTexture.getContext().clearRect(0, 0, this.resolution, this.resolution);
    }

    public conditionalDraw(condition: boolean, fallbackText: string, value: number, addTagAtEnd: string, nrDigits: number, negXPos: number, x: number, y: number, fontSize: string) {
        if (condition) {
            return this.drawLeadingNegValue(value, addTagAtEnd, nrDigits, negXPos, x, y, fontSize);
        }
        else { return this.drawText(fallbackText, x, y, fontSize); }
    }

    public drawText(text: string, x: number, y: number, fontSize: string) {
        this.meterTexture
            .drawText(text, x, y, fontSize + " " + this.fontFamily, this.textColor, this.clearColor, this.invertY);
        return this;
    }

    public drawValue(value: number, addTagAtEnd: string, nrDigits: number, x: number, y: number, fontSize: string) {
        let str = "";

        if (value === 0) { str = "0." + Array(nrDigits - 1).join('0'); }
        else {
            const nrDigBeforeDecimal = Math.floor(Math.log10(Math.abs(value)));
            if (nrDigBeforeDecimal === nrDigits)
                str = value.toPrecision(nrDigits);
            else {
                if (nrDigBeforeDecimal < 0) {
                    str = value.toFixed(nrDigits - 1);
                } else {
                    const nrDigAfterDecimal = nrDigits - 1 - nrDigBeforeDecimal;
                    if (nrDigAfterDecimal >= 0)
                        str = value.toFixed(nrDigAfterDecimal);
                }
            }
        }

        this.meterTexture
            .drawText(str + addTagAtEnd, x, y, fontSize + " " + this.fontFamily, this.textColor, this.clearColor, this.invertY);
        return this;
    }

    public drawLeadingNegValue(value: number, addTagAtEnd: string, nrDigits: number, xNegSign: number, xValue: number, y: number, fontSize: string) {
        if (value < 0) {
            value *= -1;
            this.drawText("-", xNegSign, y, fontSize);
        }
        this.drawValue(value, addTagAtEnd, nrDigits, xValue, y, fontSize);
    }
}

interface SamplingSettings {
    speed: ScalarValue,
    lastTakenAt: number
}


interface ElectronicDeviceOptions {
    width: number,
    height: number,
    depth: number,
    edgeRadius: number,
    location: BABYLON.Vector3,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
    displayResolution: number,
    fontFamily: string,
    fontSize: string,
    displayWidth: number,
    displayHeight: number,
    displayLocation: BABYLON.Vector3,
    portrudeDistance: number,
    portrudeHeight: number
}

export class ElectronicDevice extends BaseRenderedObject implements ConnectableObjectInterface {
    public portMap: Map<string, PortInternals> = new Map();

    protected stateMachine: ElectronicStateMachine | undefined;
    protected display: ElectronicDisplay | undefined;
    protected onTimeIncrement: ((vt: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) => void) = () => { return; };
    protected onDisplayUpdate: ((vt: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) => void) = () => { return; };
    protected onParentCreated: (() => void) = () => { return; };

    private buttonMap: ImageButtonMap = new ImageButtonMap();
    private samplingSettings: SamplingSettings = {
        lastTakenAt: 0,
        speed: new ScalarValue(0.5, "s")
    };

    public digitFont = "3em";
    public infoFont = "2em";
    public infoFont2 = "1.5em";


    protected options: ElectronicDeviceOptions = {
        width: 1,
        height: 1,
        depth: 1,
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        edgeRadius: 0.01,
        displayResolution: 128,
        fontFamily: "Share Tech Mono",
        fontSize: "40px",
        displayWidth: 1,
        displayHeight: 1,
        displayLocation: BABYLON.Vector3.Zero(),
        portrudeDistance: 0,
        portrudeHeight: 0
    };

    public faceTextureURL = "";

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
    }

    public addButtonMapItem(name: string, coordinates: number[], action: string) {
        if (coordinates.length !== 4) { throw Error('Four coordinates are needed to create button map item.') }
        this.buttonMap.register(name,
            coordinates[0], coordinates[1], coordinates[2], coordinates[3],
            () => { this.stateMachine?.trigger(action); });
    }


    public setOptions(options: Partial<ElectronicDeviceOptions>) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public render() {
        let angle = 0;

        // create uv indexes that allow texture 
        // to be painted onto the rectangle
        let shiftZ = 0;
        if (this.options.edgeRadius === 0) {
            console.log("Edge Radius Calculation")
            if (this.options.portrudeDistance === 0) {
                // eslint-disable-next-line prefer-spread
                const faceUV = Array.apply(null, Array(6)).map(x => new BABYLON.Vector4(0, 0.99, 1, 1));
                faceUV[1] = new BABYLON.Vector4(0, 0, 1, 1);

                // create instrument 3d box
                this.myContainerNode = BABYLON.BoxBuilder.CreateBox("Electronic " + this.name, {
                    width: this.options.width,
                    height: this.options.height,
                    depth: this.options.depth,
                    faceUV: faceUV,
                    wrap: true
                }, this.scene);
            } else {
                const w = this.options.width ?? 0 / 2.0;
                const h = this.options.height ?? 0 / 2.0;
                const l = this.options.depth ?? 0 / 2.0;
                const p = this.options.portrudeDistance ?? 0;
                const ph = this.options.portrudeHeight ?? 0;

                //angle = Math.atan2(-(2*h - ph), -p) +  Math.PI;
                angle = Math.atan2(-p, -(2 * h - ph)) + Math.PI;

                const faceUVs = [];
                faceUVs.push(new BABYLON.Vector4(1, 0, 0, 1));
                for (let i = 0; i < 8; i++) {
                    faceUVs.push(new BABYLON.Vector4(0.99, 0, 1, 1));
                }

                const instrumentPrism = {
                    "name": "Instrument Prism",
                    "category": ["Prism"],
                    "vertex": [
                        [-w, -h, -l], // 0
                        [w, -h, -l],  // 1
                        [w, h, -l],  // 2
                        [-w, h, -l], // 3
                        [-w, -h, l], // 4
                        [w, -h, l],  // 5
                        [w, h, l],  // 6
                        [-w, h, l], // 7
                        [-w, -h, -(p + l)], // 8
                        [w, -h, -(p + l)],  // 9
                        [w, ph - h, -(p + l)],  // 10
                        [-w, ph - h, -(p + l)], // 11

                    ],
                    "face": [
                        [3, 2, 10, 11], // front panel
                        [8, 11, 10, 9],
                        [9, 10, 2, 6, 5, 1], //side
                        [11, 8, 0, 4, 7, 3], // side
                        [9, 1, 5, 4, 0, 8], // bottom
                        [3, 2, 1, 0],
                        [7, 6, 2, 3],
                        [5, 6, 7, 4]
                    ]
                };

                this.myContainerNode = BABYLON.MeshBuilder.CreatePolyhedron("instrument", { custom: instrumentPrism, faceUV: faceUVs }, this.scene);

            }
        } else {
            this.myContainerNode = CurvedRectangularMesh.Create("Electronic " + this.name,
                this.scene,
                this.options.width,
                this.options.height,
                this.options.depth,
                this.options.edgeRadius);
            shiftZ = this.options.depth / 2.0;

        }


        this.myContainerNode
            .material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.White()).clone("ed");

        (this.myContainerNode.material as any)
            .ambientTexture = new BABYLON.Texture(this.faceTextureURL, this.scene);
        (this.myContainerNode.material as BABYLON.StandardMaterial).specularPower = 100;


        this.display = new ElectronicDisplay(this.scene,
            this.myContainerNode,
            this.options.displayResolution,
            this.options.displayWidth,
            this.options.displayHeight,
            this.options.displayLocation,
            angle
        );

        this.myContainerNode
            .addRotation(this.options.rotateX, this.options.rotateY, this.options.rotateZ);


        this.onParentCreated();

        this.myContainerNode.position.copyFrom(this.options.location);
        this.myContainerNode.position.z -= shiftZ;
        if (this.parent !== null) {
            this.myContainerNode.setParent(this.parent.getContainerMesh());
        }

        // setup hit test on the control surface
        this.myContainerNode.actionManager = new BABYLON.ActionManager(this.scene);
        this.myContainerNode.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                (evt: BABYLON.ActionEvent) => {
                    const pick = this.scene.pick(evt.pointerX, evt.pointerY);
                    const txtCoordinates = pick?.getTextureCoordinates();
                    this.buttonMap.processHit(txtCoordinates?.x ?? 0, txtCoordinates?.y ?? 0);
                }));

        super.render();
        return this;
    }

    public setObjectStateFromTable(variableTable: VariableTable) {
        const time = variableTable.get("TIME")?.in("s") ?? 0;
        const sSpeed = this.samplingSettings.speed.in("s");

        if (time - this.samplingSettings.lastTakenAt > sSpeed) {
            this.samplingSettings.lastTakenAt = time;
            this.display?.clear();

            if (this.onTimeIncrement !== null && this.display) {
                this.onTimeIncrement(variableTable, this, this.display);
            }

            if (this.stateMachine?.hasPower()) {
                if (this.onDisplayUpdate !== null && this.display) {
                    this.onDisplayUpdate(variableTable, this, this.display);
                }
            } else {
                this.display?.drawText("", 0, 0, this.options.fontSize);
            }
        }

        super.setObjectStateFromTable(variableTable);
    }


    // ------------------ port functions --------------------------
    // used when a cord is being attached to this object
    public getBezierPoints(portName: string) {
        const x = (portName === "LEFT") ? -this.options.width / 3.0 : this.options.width / 3.0;
        const pos: BABYLON.Vector3[] = [];
        if (TypeGuard.isNullOrUndefined(this.myContainerNode))
            return pos;

        const matrix = this.myContainerNode.computeWorldMatrix(true);
        const local_pos = new BABYLON.Vector3(x, -this.options.depth / 2.0, this.options.height / 2.0);
        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        local_pos.z += 0.6;
        pos.push(BABYLON.Vector3.TransformCoordinates(local_pos, matrix));
        return pos;
    }

    public removeConnection(portName: string) {
        const tmpPort = this.portMap.get(portName);
        if (tmpPort?.connection?.isConnected) {
            tmpPort.connection.cord?.dispose();
            tmpPort.connection.isConnected = false;
        }
    }

    public registerConnection(portName: string, connectPort: string, connectToObj: ConnectableObjectInterface) {
        const cord = new CordConnector("C1", this.scene, this);

        const myPort = this.portMap.get(portName);
        if (myPort) {
            myPort.connection = {
                isConnected: true,
                connectedPort: connectPort,
                connectedObject: connectToObj,
                cord: cord
            };
        }

        cord.buildFromTwoObjects(
            portName, this as unknown as ConnectableObjectInterface,
            connectPort, connectToObj as unknown as ConnectableObjectInterface)
            .render();
    }

}