import { TextType } from "@/API";
import { createTextbook, deleteTextbook, updateTextbook } from "@/graphql/mutations";
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { getDefaultBaseTextbookDBRecord } from "./table-textbook-utils";
import { TextbookFromTextbookTable } from "./table-textbook.interface";

export interface BookCreationOptions {
   type: TextType
}

const bookRecordCreator: RecordCreatorParameters<TextbookFromTextbookTable, BookCreationOptions> = {
   defaultValues: {
      ...getDefaultBaseTextbookDBRecord(),
      txt_pictureURL: "none"
   },
   objectType: RecordTypes.BOOK,
   createQuery: createTextbook,
   updateQuery: updateTextbook,
   deleteQuery: deleteTextbook,

   getDeleteItemParameters: function (data: TextbookFromTextbookTable) {
      return {
         id: data.id,
         fieldType: data.fieldType,
         textID: data.textID
      };

   },

   onAfterCreatedModifyData: function (data: TextbookFromTextbookTable,
      createOptions: BookCreationOptions,
      myBook: BookRecord) {
      myBook.data().fieldType = createOptions.type;
      myBook.data().textID = data.id;
   },

   onBeforeSaveToDB: function (data: TextbookFromTextbookTable, myBook: BookRecord) {
      return data;
   },

   onAfterRecordWrapFromDB(data: TextbookFromTextbookTable, myBook: BookRecord) {
      return;
   }
}

setRecordCreator(RecordTypes.BOOK, bookRecordCreator);


/**
 * Class used to hold, update, and create database 
 * Textbook items. 
 * */
export class BookRecord extends WrappedRecord<TextbookFromTextbookTable, BookCreationOptions> {
   constructor() {
      super(RecordTypes.BOOK)
   }

   static loadFromJSON(data: Record<string, unknown>): BookRecord | undefined {
      if (!data) return;
      const newBook = new BookRecord();
      Object.assign(newBook, data);
      return newBook;
   }
}