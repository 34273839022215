
  import { EquationStack } from '@/components/contentGenerator/questions/equations/equation-template-helpers';
  import { ModelHealthReport } from '@/components/contentGenerator/questions/model/model-health-report';
  import { QuestionModel } from '@/components/contentGenerator/questions/model/model-question';
  import { defineComponent } from 'vue'

  export default defineComponent({
    setup() {
      const eqnStack = [
        "a = 1.23",
        "a_u = 2.34 [m/s]",
        "V = [1, 2, 3]",
        "V_u = [1, 2, 3] [m/s]"] as unknown as EquationStack;

      const mHealth = new ModelHealthReport();
      const qModel = new QuestionModel(eqnStack, [], [], mHealth);

      const evalEqn = (eqn: string, wrap: boolean) => {
        const tmpEqn = wrap ? `$ {{ ${eqn} }} $` : eqn;
        return qModel.evaluateStatement(tmpEqn).getData();
      }

      const operations = [
        {
          title: "Units",
          wrap: true,
          statements: [
            ["Create", "1 [m/s]"],
            ["Create", "1 m/s"],
            ["Multiply", "1 [m/s] * 2 [cm]"],
            ["Multiply", "1 [N/s] * 2 [1/m]"],
            ["Multiply", "1 [kN] * 2 [1/s]"],
            ["Power", "(2 [m/s])^3"],
            ["Power", "(2 [m])^3.2"],
            ["Power", "(2 [m])^(1/3)"],
          ]
        },
        {
          title: "Vector",
          wrap: true,
          statements: [
            ["Create", " [1, 2, 3] [m/s]"],
            ["Create", " [1, 2, 3] m/s"],
            ["Magnitude", " norm([1, 2, 3])"],
            ["Magnitude", " norm(V_u)"],
            ["dot", " dot([1, 2, 3], [1, 2, 3])"],
            ["dot", " dot(V_u, [1, 2, 3])"],
          ]
        },
        {
          title: "Output",
          wrap: false,
          statements: [
            ["Present number", "${a}"],
            ["Present value with unit", "${a_u}"],
            ["Present vector", "${V}"],
            ["Present vector with value", "${V_u}"],
            ["Compute numerical", "{{a}}"],
            ["Compute with units unformated", "{{a_u}}"],
            ["Compute formated", "${{a_u}}$"],
            ["Compute with vectors", "${{V}}$"],
            ["Compute with vectors and units", "${{V_u}}$"],
          ]
        }
      ];

      return {
        evalEqn,
        operations
      }
    }
  })
