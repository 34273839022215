import { TypeGuard } from "../../mathjs/Type-guards";
import { PointMathJSCalculations, PointStatistics } from "./ConvexHulls";
import { HandwritingSimplficationMode } from "./useHandwritingAnalysis";
import * as paper from 'paper';
import { usePossibleStrokes } from "./storkeLabels";
import { ParallelismPerKPU } from "aws-sdk/clients/kinesisanalyticsv2";
import { AnswerState } from "../../questions/components/user-response.interface";


export enum EqnCharacterType {
   equation,
   character,
   superscript,
   subscript,
   sqrt,
   fraction,
   group
}
export interface EqnCharacter {
   id: string,
   type: EqnCharacterType,
   highlight?: boolean,
   kcc: string, // character code
   dc: string, // display character
   children: EqnCharacter[],
   meta?: paper.Layer
}

export interface StrokeAnalysisType extends PointStatistics {
   label: string,
   nrIntersections: number,
   strokeLabel: string,
   nrStrokes: number,
   strokeIndexInLayer: number,
   stroke?: paper.Path
}

export interface CharacterAnalysisType {
   label: string,
   nrStrokes: number,
   strokeIntersections: number,
   strokes: StrokeAnalysisType[]
}


function simplifyStroke(stroke: paper.Path, displayMode: HandwritingSimplficationMode) {
   //if (displayMode != HandwritingSimplficationMode.Original) {
   //stroke.simplify(3);
   // if (displayMode === HandwritingSimplficationMode.Flattened) {
   stroke.flatten(3);
   // }
   //}
   return stroke;
}


const getSingleStrokeStats = (stroke: paper.Path): StrokeAnalysisType => {
   const arrayOfPoints = stroke.segments.map((v) => {
      return v.point;
   });

   const stats: StrokeAnalysisType = PointMathJSCalculations.getStatistics(arrayOfPoints) as StrokeAnalysisType;
   stats.strokeLabel = stroke.data.strokeLabel;
   stats.nrIntersections = stroke.data.nrIntersections;
   return stats;
}


function seperateEqnIntoCharacters(eqn: EqnCharacter) {

   const characterOrganizedLayers: Record<string, paper.Layer[]> = {};
   splitTerm(eqn);
   return characterOrganizedLayers;

   function splitTerm(eqn: EqnCharacter) {
      switch (eqn.type) {
         case EqnCharacterType.character: {
            const charLabel = eqn.dc;
            if (!TypeGuard.hasProp(characterOrganizedLayers, charLabel)) {
               characterOrganizedLayers[charLabel] = [];
            }
            characterOrganizedLayers[charLabel].push(eqn.meta!);
            break;
         }
         default:
            eqn.children.forEach((v) => {
               splitTerm(v)
            })
            break;
      }
   }
}

function convertCharRecordToPaperLayer(paths: any): paper.Layer {
   const tmpLayer: paper.Layer = new paper.Layer();
   tmpLayer.importJSON(paths.path);
   (tmpLayer as any).fullySelected = false;
   return tmpLayer;
}

const { possibleStrokes } = usePossibleStrokes();
function labelStroke(charCode: string, nrStrokes: number, stroke: paper.Item, anaStroke: StrokeAnalysisType) {
   anaStroke.label = charCode;

   if (TypeGuard.hasProp(possibleStrokes, charCode) &&
      nrStrokes === 1
   ) {
      anaStroke.strokeLabel = possibleStrokes[charCode].strokes[0];
      anaStroke.nrStrokes = 1;
      anaStroke.strokeIndexInLayer = 1;
   }

}

function labelDifficultStrokes(strokes: paper.Item[], strokeAnalyses: StrokeAnalysisType[]) {
   const nrStrokes = strokeAnalyses.length;
   strokeAnalyses.forEach((s, ind) => {
      s.nrStrokes = nrStrokes;
      s.strokeIndexInLayer = ind;
      if (TypeGuard.isNullOrUndefined(s.strokeLabel) || s.strokeLabel === "") {
         if (TypeGuard.hasProp(possibleStrokes, s.label)) {
            s.strokeLabel = possibleStrokes[s.label].identifyFunc(ind, strokes, strokeAnalyses);
         }
      }
   })


   strokeAnalyses.forEach((s, ind) => {
      if (TypeGuard.isNullOrUndefined(s.strokeLabel) || s.strokeLabel === "") {
         if (TypeGuard.hasProp(possibleStrokes, s.label)) {
            console.log("Label needed for:", s.label);
         }
      }
   });
   return;
}


export function analyzeEqnCharacters(eqn: EqnCharacter) {
   const characterAnalysis: Record<string, CharacterAnalysisType[]> = {};


   const orgChars = seperateEqnIntoCharacters(eqn);
   Object.keys(orgChars).forEach((key) => {
      if (!TypeGuard.hasProp(characterAnalysis, key)) {
         characterAnalysis[key] = [];
      }

      orgChars[key].forEach((layer: any) => {
         const strokeAnalysis: StrokeAnalysisType[] = []
         const convLayer = convertCharRecordToPaperLayer(layer);
         const strokes = convLayer.children;

         strokes.forEach((stroke) => {
            const simplifiedStroke = simplifyStroke(stroke as paper.Path, HandwritingSimplficationMode.Original);
            const analyzedStroke = getSingleStrokeStats(simplifiedStroke);

            labelStroke(key, strokes.length, stroke, analyzedStroke);

            strokeAnalysis.push({
               ...analyzedStroke,
               stroke: stroke as paper.Path
            });
         })

         labelDifficultStrokes(strokes, strokeAnalysis);

         characterAnalysis[key].push({
            label: key,
            strokeIntersections: 0,
            nrStrokes: strokeAnalysis.length,
            strokes: strokeAnalysis
         });
      });


   })

   return characterAnalysis;
}

export function extractCharacterAnalysisFromEquations(eqn: EqnCharacter[]) {
   const eqnAnalysis: Record<string, CharacterAnalysisType[]>[] = [];

   eqn.forEach((e) => {
      eqnAnalysis.push(analyzeEqnCharacters(e));
   })

   const charAnalaysis: Record<string, CharacterAnalysisType[]> = {};
   eqnAnalysis.forEach((e) => {
      Object.keys(e).forEach((key) => {
         if (!TypeGuard.hasProp(charAnalaysis, key)) {
            charAnalaysis[key] = [];
         }
         e[key].forEach((value) => {
            charAnalaysis[key].push(value);
         })
      })
   })

   return charAnalaysis;
}

export function extractEquationAnalysisFromEquations(eqn: EqnCharacter[]) {
   const eqnAnalysis: Record<number, StrokeAnalysisType[]> = [];

   eqn.forEach((e, index) => {
      const aec = analyzeEqnCharacters(e);
      const arrOfStrokes: StrokeAnalysisType[] = [];
      Object.keys(aec).forEach(v => {
         const charA = aec[v];
         charA.forEach((strokeAnalysis => {
            const strokeAnalyses = strokeAnalysis.strokes;
            arrOfStrokes.push(...strokeAnalyses)
         }))
      })
      eqnAnalysis[index] = arrOfStrokes;
   })


   return eqnAnalysis;
}



