import * as BABYLON from 'babylonjs';
import { VisualFlowMeterModel } from "../instruments/Analog/VisualFlowMeter";
import { FlowMeterElectronic } from '../instruments/Electronic/FlowMeter';
import { PressureMeterElectronic } from "../instruments/Electronic/PressureMeterElectronic";
import { BaseRenderedObject } from "../primatives/BaseRenderedObject";
import { PipeGenerator, PipeGeneratorType } from "../primatives/Factories/PipeGenerator";
import { MaterialRegistry } from "../primatives/Materials/MaterialRegistry";
import { BallValve } from "../primatives/Pipe/Pipe_C_BallValve";
import { Pressure_Connect } from "../primatives/Pipe/Pipe_C_Pressure_Connector";
import { PointListGenerator } from "../primatives/PointListGenerator";
import { FluidDyanmicsEquations } from '../properties/FrictionFactorEquations';
import { Fluid, Fluids } from '../properties/Standard_Fluids';
import { PipeMaterial, PipeNominal, PipeSchedule, PipeScheduleFactory, StandardPipe } from '../properties/Standard_Pipes';
import { PipeComponent, PipeComponentFactory, PipeComponentType } from '../properties/Standard_Pipe_Components';
import { ScalarValue, _u } from '../values/ScalarValue';
import { VariableTable } from "../values/VariableTable";
import { ExperimentInterface } from "./ExperimentInterface";

interface PipeLostStationOptions {
    location: BABYLON.Vector3,
    rotationY: number,
    fluid: Fluid,
    initialPressure: ScalarValue,
    pipe1: StandardPipe,
    rackWidth: number,
    tee: PipeComponent,
    elbow90: PipeComponent,
    elbow45: PipeComponent,
    valve_in: PipeComponent
}

interface PipeLossResults {
    Q: number,
    V1: number,
    V2: number,
    perValveOpen1: number,
    perValveOpen2: number,
    L1?: number,
    L2?: number,
    D1?: number,
    D2?: number,
    K1?: number,
    K2?: number,
    P1: number,
    P2: number,
    P3: number,
    P4: number,
    f1?: number,
    f2?: number
}

export class PipeLossStation_Minor extends BaseRenderedObject implements ExperimentInterface {
    private pipeGenerator: PipeGenerator;
    private variableTable: VariableTable;

    private options: PipeLostStationOptions;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        const fluid = new Fluid(Fluids.WATER);

        const pipe1 = PipeScheduleFactory.getPipeSize(PipeNominal.NOM_1, PipeSchedule.Sch_10, 'm', PipeMaterial.COPPER);

        const valve_in = PipeComponentFactory.getComponent(PipeComponentType.SWING_CHECK_VALVE, pipe1.innerDiameter);

        const tee = PipeComponentFactory.getComponent(PipeComponentType.TEE, pipe1.innerDiameter);
        const elbow90 = PipeComponentFactory.getComponent(PipeComponentType.ELBOW, pipe1.innerDiameter);
        const elbow45 = PipeComponentFactory.getComponent(PipeComponentType.ELBOW_45, pipe1.innerDiameter);

        this.options = {
            fluid: fluid,
            initialPressure: _u(275.79, 'kPa'),
            location: new BABYLON.Vector3(0, 0, 0),
            rotationY: 0,
            rackWidth: 2,
            pipe1: pipe1,
            valve_in: valve_in,
            tee: tee,
            elbow90: elbow90,
            elbow45: elbow45
        }

        this.variableTable = new VariableTable();

        this.pipeGenerator = new PipeGenerator(scene);

        this.addChild(new FlowMeterElectronic('FLOW1', scene, this));
        this.addChild(new BallValve("V1", this.scene, this));

        this.addChild(new PressureMeterElectronic("pressureMeter", this.scene, this));

        this.addChild(new Pressure_Connect("PC1", this.scene, this));
        this.addChild(new Pressure_Connect("PC2", this.scene, this));
        this.addChild(new Pressure_Connect("PC3", this.scene, this));

        //scene.debugLayer.show();
    }


    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        this.pipeGenerator.addPreloadAsset(this.scene, assetManager);
        super.addPreloadAssets(assetManager);
    }

    public setOptions(options: PipeLostStationOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public render() {
        const rackLen = this.options.rackWidth ?? 0;

        this.myContainerNode = this.pipeGenerator.create(PipeGeneratorType.Board, null, {
            color: BABYLON.Color3.White(),
            width: rackLen + 1,
            height: 1.25
        });

        const texture = new BABYLON.Texture(
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/signs/PipeRack2Bck.png",
            this.scene);

        if (this.myContainerNode) {
            this.myContainerNode.material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Blue());
            (this.myContainerNode.material as BABYLON.StandardMaterial).emissiveTexture = texture;
        }

        const y1 = -0.5, y2 = -0.20, y3 = 0.0, y4 = 0.2, y5 = 0.4;
        const x1 = 0, x2 = -0.2, x3 = -0.4, x4 = -0.6;

        const x7 = 0.8, x8 = 0.2;

        const x9 = 0.4, x10 = 0.6, x11 = 0.7;

        const pointList = PointListGenerator.fromJSON({
            W1: [x1, y1, 0],
            A: [x1, y1, -0.1],
            B: [x1, y3, -0.1],
            C: [x2, y3, -0.1],
            D: [x2, y2, -0.1],
            E: [x3, y2, -0.1],
            F: [x3, y3, -0.1],
            G: [x4, y3, -0.1],
            H: [x4, y4, -0.1],
            I: [x3, y4, -0.1],
            J: [x3, y5, -0.1],
            K: [x2, y5, -0.1],
            L: [x2, y4, -0.1],
            LA: [x1, y4, -0.1],
            LPA: [x1 + 0.015, y4, -0.1],
            LPB: [x8 - 0.015, y5, -0.1],
            LB: [x8, y5, -0.1],
            LC: [x9, y5, -0.1],
            LPC: [x9 + 0.015, y5, -0.1],
            LPD: [x10 - 0.015, y4, -0.1],

            LD: [x10, y4, -0.1],

            M: [x7, y4, -0.1],
            N: [x7, y3, -0.1],
            O: [x8, y3, -0.1],
            P: [x8, y1, -0.1],

            P1: [(x1 + x2) / 2.0, y3, -0.1],
            P2: [(x1 + x2) / 2.0, y4, -0.1],
            P3: [x11, y4, -0.1],

            VALVE: [x1, y2, -0.1],
            FLOW_METER: [x8, y2, -0.1],
            MANOMETER: [0.3 * rackLen, y1, -0.1]
        });

        const pairTable =
            [
                ["W1", "A", this.options.pipe1.outerDiameter],
                ["A", "B", this.options.pipe1.outerDiameter],
                ["B", "C", this.options.pipe1.outerDiameter],
                ["C", "D", this.options.pipe1.outerDiameter],
                ["D", "E", this.options.pipe1.outerDiameter],
                ["E", "F", this.options.pipe1.outerDiameter],
                ["F", "G", this.options.pipe1.outerDiameter],
                ["G", "H", this.options.pipe1.outerDiameter],
                ["H", "I", this.options.pipe1.outerDiameter],
                ["I", "J", this.options.pipe1.outerDiameter],
                ["J", "K", this.options.pipe1.outerDiameter],
                ["K", "L", this.options.pipe1.outerDiameter],
                ["L", "LA", this.options.pipe1.outerDiameter],
                ["LPA", "LPB", this.options.pipe1.outerDiameter],
                ["LB", "LC", this.options.pipe1.outerDiameter],
                ["LPC", "LPD", this.options.pipe1.outerDiameter],
                ["LD", "M", this.options.pipe1.outerDiameter],
                ["M", "N", this.options.pipe1.outerDiameter],
                ["N", "O", this.options.pipe1.outerDiameter],
                ["O", "P", this.options.pipe1.outerDiameter]
            ];

        for (let i = 0; i < pairTable.length; i++) {
            this.pipeGenerator.create(PipeGeneratorType.Pipe,
                this.myContainerNode,
                {
                    startPoint: pointList[pairTable[i][0]],
                    endPoint: pointList[pairTable[i][1]],
                    diameter: pairTable[i][2]
                });
        }

        this.getChildByName("V1")
            ?.setOptions({
                location: pointList["VALVE"],
                rotateZ: -Math.PI / 2.0
            })
            .render();

        const scale = 1.5;

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["B"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["C"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["D"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["E"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: 0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["F"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["G"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: 0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["H"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["M"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["N"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["O"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["A"], scale: scale, rotateX: Math.PI / 2.0, rotateY: 0, rotateZ: 0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["P"], scale: scale, rotateX: Math.PI / 2.0, rotateY: 0, rotateZ: 0 });

        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["I"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["J"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["K"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_90, this.myContainerNode, { location: pointList["L"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: 0 });


        this.pipeGenerator.create(PipeGeneratorType.Elbow_45, this.myContainerNode, { location: pointList["LA"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_45, this.myContainerNode, { location: pointList["LB"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -Math.PI / 2.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_45, this.myContainerNode, { location: pointList["LC"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: -3 * Math.PI / 4.0 });
        this.pipeGenerator.create(PipeGeneratorType.Elbow_45, this.myContainerNode, { location: pointList["LD"], scale: scale, rotateX: 0, rotateY: 0, rotateZ: Math.PI / 4.0 });

        this.getChildByName("PC1")
            ?.setOptions({ location: pointList["P1"] })
            .render();

        this.getChildByName("PC2")
            ?.setOptions({ location: pointList["P2"] })
            .render();

        this.getChildByName("PC3")
            ?.setOptions({ location: pointList["P3"] })
            .render();


        // create instruments and meters
        this.getChildByName("FLOW1")
            ?.setOptions({
                location: pointList["FLOW_METER"],
                model: VisualFlowMeterModel.FourToTwentyEightGPM
            })
            .render();

        this.getChildByName("pressureMeter")
            ?.setOptions({
                location: pointList["MANOMETER"]
            })
            .render();

        // move container only after all children are added
        if (this.myContainerNode) {
            this.myContainerNode.addRotation(0, this.options.rotationY, 0);
            this.myContainerNode.position = this.options.location;
        }

        return super.render();
    }

    public updateTime(time: number, deltaT: number) {
        this.variableTable.set("TIME", time, "ms");
        this.variableTable.set("DELTA_T", deltaT, "ms");
        this.onChange();
    }

    public onChange() {
        this.setTableFromObjectState(this.variableTable);
        this.reComputeSystemState(this.variableTable);
        this.setObjectStateFromTable(this.variableTable);
    }


    private reComputeSystemState(variableTable: VariableTable) {
        // the flow rate 
        const mainValve = variableTable.get("V1")?.value ?? 0;

        const Q = 0.001 * mainValve;
        const D1 = this.options.pipe1?.innerDiameter ?? 0;
        const A1 = Math.PI * D1 * D1 / 4.0;
        const V1 = Q / A1;
        const vwat = this.options.fluid.getViscosity().in('Pa*s') / this.options.fluid.getDensity().in('kg/m3');
        const specificWeight = this.options.fluid.getSpecificWeight().in('N/m3')
        const L1 = 9 * 0.2; // m
        const L2 = 0.28 * 2 + 0.2;
        const K90 = this.options.elbow90.getK();
        const K45 = this.options.elbow45.getK();
        const e1 = this.options.pipe1?.surfaceRoughness ?? 0;
        const f1 = FluidDyanmicsEquations.findFrictionCoef(V1, D1, e1, vwat);
        const h1 = FluidDyanmicsEquations.pipeLossPlusMinor(f1, D1, L1, 10 * K90, V1);
        const h2 = FluidDyanmicsEquations.pipeLossPlusMinor(f1, D1, L2, 4 * K45, V1);

        //console.log(h2, FluidDyanmicsEquations.pipeLoss(f1, D1, L2, V1))

        const dP1 = specificWeight * h1;
        const dP2 = specificWeight * h2;
        const p0 = this.options.initialPressure.in('Pa');
        //console.log('f1', f1, 'D1', D1, 'L2', L2, 10 * K90, 'V1', V1, p0 - dP1 - dP2)

        variableTable.set("FLOW1", Q, "m3/s");
        variableTable.set("PC10", p0, "Pa");
        variableTable.set("PC20", p0 - dP1, "Pa");
        variableTable.set("PC30", p0 - dP1 - dP2, "Pa");
    }
}