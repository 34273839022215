import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
import * as BABYLON from 'babylonjs';
import { Nullable } from 'babylonjs/types';
import { BaseRenderedObject, OBSERVABLETYPE, RenderedObjectTypes } from '../../primatives/BaseRenderedObject';
import { Beaker } from '../../primatives/LabEquipment/Beakers';
import { MaterialRegistry } from '../../primatives/Materials/MaterialRegistry';
import { convert_util } from '../../values/Conversion';
import { _u } from '../../values/ScalarValue';
import { VariableTable } from "../../values/VariableTable";
import { InstrumentDisplayValue, NoiseCorrelation, NoiseType, RangeOverFlowOptions } from '../InstrumentDisplayValue';
import { ElectronicDevice, ElectronicDisplay, ElectronicStateMachine } from './BaseElectronicDevice';

interface SpindleSettings {
    name: string,
    radialScale: number,
    verticalScale: number,
    height: number,
    torqueConst: number
}

export class ViscometerController extends ElectronicDevice {
    private viscometer: Nullable<Viscometer> = null;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.VISCOMETER_CONTROLLER;

        // textures
        this.faceTextureURL = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/Viscometer.png";

        // setup state 
        // setup state machine
        this.stateMachine = new ElectronicStateMachine({
            states: {
                selectedSpindle: 0,
                spindleList: ['SP01', 'SP02', 'SP03', 'SP04', 'SP05', 'SP06', 'SP07'],
                spindleShearRate: [],
                selectedSpeed: 0,
                speedList: [1, 10, 20, 50, 100, 150, 500, 1000],
                isRunning: false
            },
            computed: {
                getSpindle: (sm) => { return sm.get('spindleList')[sm.get('selectedSpindle')]; },
                getSpeed: (sm) => { return sm.get('speedList')[sm.get('selectedSpeed')]; }
            },
            actions: {
                hitPower: (sm) => {
                    sm.togglePower();
                },
                hitRun: (sm) => {
                    sm.set('isRunning', true);
                },
                hitStop: (sm) => {
                    sm.set('isRunning', false);
                },
                hitSpeedUp: (sm) => {
                    sm.incrementListSelection('selectedSpeed', 'speedList');
                },
                hitSpeedDown: (sm) => {
                    sm.incrementListSelection('selectedSpeed', 'speedList', false);
                },
                hitSpindleUp: (sm) => {
                    sm.incrementListSelection('selectedSpindle', 'spindleList', false);
                },
                hitSpindleDown: (sm) => {
                    sm.incrementListSelection('selectedSpindle', 'spindleList');
                },
            }
        });

        // button map
        this.addButtonMapItem('SPEED_UP', [0.03, 0.23, 0.23, 0.35], 'hitSpeedUp');
        this.addButtonMapItem('SPEED_DOWN', [0.03, 0.03, 0.23, 0.15], 'hitSpeedDown');

        this.addButtonMapItem('POWER', [0.27, 0.23, 0.48, 0.35], 'hitPower');
        this.addButtonMapItem("RUN", [0.27, 0.03, 0.48, 0.15], 'hitRun');

        this.addButtonMapItem("STOP", [0.50, 0.03, 0.71, 0.15], 'hitStop');

        this.addButtonMapItem('SPINDLE_UP', [0.73, 0.23, 0.93, 0.35], 'hitSpindleUp');
        this.addButtonMapItem('SPINDLE_DOWN', [0.73, 0.03, 0.93, 0.15], 'hitSpindleDown');

        // setup ports
        this.portMap.set('Viscosity',
            {
                name: '0',
                value: new InstrumentDisplayValue(0, 'cP',
                    {
                        minValue: _u(0, "cP"),
                        maxValue: _u(1000, "cP"),
                        overflow: RangeOverFlowOptions.CLIP
                    },
                    {
                        addNoise: true,
                        type: NoiseType.PerOfRange,
                        percent: 0.0001,
                        correlation: NoiseCorrelation.SinVariationWithTime,
                        period: 1
                    }),
                connection: null
            });


        // display function
        this.onDisplayUpdate = this.displayCallback;
    }


    public setViscometer(viscometer: Viscometer) {
        this.viscometer = viscometer;
    }

    public getStateMachine() {
        return this.stateMachine;
    }


    private displayCallback(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {
        const time = variableTable.get("TIME")?.in("s") ?? 0;

        const rpm = this.stateMachine?.getComputed('getSpeed');
        const spindle = this.stateMachine?.getComputed('getSpindle');
        const isRunning = this.stateMachine?.get('isRunning');

        if (this.viscometer?.spindleSize !== spindle) {
            this.viscometer?.selectSpindle(spindle);
        }
        let viscosity = null;
        let range = null;

        let spindleSpeed = 0;

        if (isRunning) {
            const settings = Viscometer.spindleTable.find((v) => { return v.name === spindle });
            spindleSpeed = 0.001 * convert_util(rpm, 'rpm', 'rad/s'); // in rad / ms

            // pull viscosity
            if (this.viscometer?.connectedBeaker !== null) {
                const fluidProps = this.viscometer?.connectedBeaker.getFluidProps()
                if (fluidProps !== null) {
                    const tmpVisc = Math.round(1000 * (fluidProps?.getViscosity()?.in('cP') ?? 0)) / 1000;

                    const dispVisc = (this.portMap.get('Viscosity')?.value?.copy(_u(tmpVisc, 'cP')) as InstrumentDisplayValue)
                        .getDisplayValueIn('cP', time);

                    viscosity = dispVisc;
                    const tmpRange = (Math.round(100 * dispVisc / ((settings?.torqueConst ?? 1) * 10000 / rpm)));

                    // overflow
                    if (tmpRange > 100) {
                        viscosity = null;
                        range = '100';
                    } else {
                        range = tmpRange.toString();
                    }
                }
            }
        }

        this.viscometer?.setSpindleSpeed(spindleSpeed);

        // display 
        if (isRunning) {
            const rangeVal = parseFloat(range ?? '0');
            if (viscosity === null || rangeVal < 10 || rangeVal > 90) {
                display.drawText("--", 5, 15, this.infoFont);
            } else {
                display.drawValue(viscosity, "", 5, 5, 15, this.infoFont);
            }

            display.drawText("cP", 90, 15, this.infoFont);
            if (range === null) {
                display.drawText("--" + "% Range", 5, 50, this.infoFont);
            } else {
                display.drawText(range + "% Range", 5, 50, this.infoFont);
            }

        } else {
            display.drawText("Press Run", 5, 15, this.infoFont);
        }


        display.drawText(rpm.toString(), 5, 80, this.infoFont);
        display.drawText("rpm", 90, 80, this.infoFont);

        display.drawText(spindle, 5, 120, this.infoFont);

    }
}


export interface ViscometerOptions {
    location: BABYLON.Vector3,
    rotateX: number,
    rotateY: number,
    rotateZ: number,
}

export class Viscometer extends BaseRenderedObject {
    static spindleTable: SpindleSettings[] = [
        { name: "SP01", verticalScale: 10, radialScale: 2.2, height: 1, torqueConst: 1 },
        { name: "SP02", verticalScale: 1, radialScale: 2.2, height: 1, torqueConst: 4 },
        { name: "SP03", verticalScale: 1, radialScale: 1.9, height: 1, torqueConst: 10 },
        { name: "SP04", verticalScale: 1, radialScale: 1.5, height: 1, torqueConst: 20 },
        { name: "SP05", verticalScale: 1, radialScale: 1, height: 1, torqueConst: 40 },
        { name: "SP06", verticalScale: 1, radialScale: 0.5, height: 1, torqueConst: 100 },
        { name: "SP07", verticalScale: 1, radialScale: 0.1, height: 1, torqueConst: 400 },
    ];

    private spindleLastUpdate = 0;
    private spindleSpeed = 0;
    private spindleMesh: Nullable<BABYLON.Mesh> = null;
    private spindleBuldge: Nullable<BABYLON.Mesh> = null;
    private spindleBuldgeHeight = 0.005;
    public spindleSize = "";
    private snapBeakerLocation: BABYLON.Vector3 = BABYLON.Vector3.Zero();
    public connectedBeaker: Nullable<Beaker> = null;
    private controller: Nullable<ViscometerController> = null;

    private options: ViscometerOptions = {
        location: BABYLON.Vector3.Zero(),
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.VISCOMETER;

        this.addChild(new ViscometerController("vs_controller", this.scene, this));
    }

    public setOptions(options: ViscometerOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public setSpindleSpeed(speed: number) {
        this.spindleSpeed = speed;
    }

    public registerAbsoluteSnapPoints() {
        let isUp = false;
        this.registerSnapPoint(
            this.snapBeakerLocation,
            [RenderedObjectTypes.BEAKER, RenderedObjectTypes.HOT_PLATE],
            (newBeaker) => {
                this.connectedBeaker = newBeaker as Beaker;
                if (newBeaker.type === RenderedObjectTypes.HOT_PLATE) {
                    if (!isUp)
                        this.controller?.getContainerMesh()?.locallyTranslate(new BABYLON.Vector3(0, 0, 0.07));
                    isUp = true;
                } else {
                    if (isUp) {
                        this.controller?.getContainerMesh()?.locallyTranslate(new BABYLON.Vector3(0, 0, -0.07));
                        isUp = false;
                    }
                }
            },
            (oldBeaker) => {
                this.connectedBeaker = null;
            }
        );
        super.registerAbsoluteSnapPoints();
    }

    public render() {
        const internalOptions = {
            width: 0.2,
            height: 0.30,
            depth: 0.20,
            location: new BABYLON.Vector3(0, 0, 0.2 / 2.0),
            edgeRadius: 0.02,
            displayHeight: 0.12,
            displayWidth: 0.15,
            displayLocation: new BABYLON.Vector3(0, 0.06, -0.001)
        };
        const distanceToTheTable = 0.4;
        const poleHeight = 0.6;

        this.controller = this.getChildByName('vs_controller') as ViscometerController;
        this.controller.setViscometer(this);

        const baseDepth = 2 * internalOptions.depth;// / Math.sin(Math.PI / 4.0);
        const base = BABYLON.MeshBuilder.CreateBox(this.name + "Base",
            {
                width: 0.04,
                height: 0.02,
                depth: baseDepth
            }, this.scene);

        base.material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Black());
        base.setPivotPoint(new BABYLON.Vector3(0, 0, baseDepth / 4.0));
        base.addRotation(0, Math.PI / 4.0, 0);
        base.position.x -= (internalOptions.width / 2.0 - 0.04 / 2.0);
        base.position.y -= (distanceToTheTable);
        base.position.z += (0.04);
        this.myContainerNode = base;

        const base2 = base.clone();
        base2.addRotation(0, -Math.PI / 2.0, 0);
        base2.setParent(this.getContainerMesh());

        const pole = BABYLON.MeshBuilder.CreateCylinder(this.name + "Pole",
            {
                diameter: 0.03,
                height: poleHeight
            },
            this.scene);

        pole.material = MaterialRegistry.getSilver(this.scene);

        pole.setParent(this.getContainerMesh());
        pole.locallyTranslate(new BABYLON.Vector3(0, -(distanceToTheTable - poleHeight / 2.0), internalOptions.depth));


        // now create electronic display
        this.controller
            .setOptions(internalOptions)
            .render();


        const knob = BABYLON.MeshBuilder.CreateCylinder(this.name + "Knob",
            {
                diameter: 0.09,
                height: 0.03
            }, this.scene);

        knob.material = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Black());
        knob.position.z += internalOptions.depth / 2.0;
        knob.position.x += internalOptions.width / 2.0;
        knob.addRotation(0, 0, Math.PI / 2.0);
        knob.setParent(this.controller.getContainerMesh());

        const spindle = BABYLON.MeshBuilder.CreateCylinder(this.name + "Spindle",
            {
                diameter: 0.005,
                height: 0.2
            },
            this.scene);

        this.spindleBuldge = BABYLON.MeshBuilder.CreateCylinder(this.name + "SpindleBulge",
            {
                diameter: 0.05,
                height: this.spindleBuldgeHeight
            },
            this.scene);

        this.spindleBuldge.setParent(spindle);
        this.spindleBuldge.locallyTranslate(new BABYLON.Vector3(0, -0.05, 0));

        spindle.position.z += 0.05;
        spindle.position.y -= (internalOptions.height / 2.0 + 0.2 / 2.0);

        const marker = BABYLON.MeshBuilder.CreateSphere(this.name, {
            diameter: 0.001
        }, this.scene);

        marker.setParent(this.getContainerMesh());
        marker.locallyTranslate(new BABYLON.Vector3(0, -distanceToTheTable, 0.05));
        this.snapBeakerLocation = marker.getAbsolutePosition();

        this.spindleBuldge.material = MaterialRegistry.getMetalTexture(this.scene, {});
        spindle.material = MaterialRegistry.getMetalTexture(this.scene, {});

        spindle.setParent(this.controller.getContainerMesh());
        this.spindleMesh = spindle;

        // register observable
        this.addObservable('rotateSpindle', OBSERVABLETYPE.onBeforeRenderObservable,
            () => {
                const now = Date.now(); // in milliseconds
                const dt = now - this.spindleLastUpdate;
                this.spindleLastUpdate = now;
                this.spindleMesh?.addRotation(0, dt * this.spindleSpeed, 0);
            });

        base.position = this.options.location;
        base.setParent(this.parent?.getContainerMesh() ?? null);

        super.render();
        return this;
    }


    public selectSpindle(spindle: string) {
        const settings = Viscometer.spindleTable.find((v) => { return v.name === spindle });
        if (TypeGuard.isNullOrUndefined(settings)) {
            return;
        }

        if (this.spindleBuldge) {
            this.spindleBuldge.scaling = new BABYLON.Vector3(1, 1, 1);
            this.spindleBuldge.setPositionWithLocalVector(new BABYLON.Vector3(0, -0.07 - settings.verticalScale * this.spindleBuldgeHeight / 2.0, 0));
            this.spindleBuldge.scaling = new BABYLON.Vector3(settings.radialScale, settings.verticalScale, settings.radialScale);
        }
    }

}