import { CharacterAnalysisType, EqnCharacter, EqnCharacterType, StrokeAnalysisType } from "./useEqnCharacterAnalysis";


export function convertEqnCharToKatex(eqnCharacter: EqnCharacter): string {
   switch (eqnCharacter.type) {
      case EqnCharacterType.character:
         return eqnCharacter.highlight ? `\\textcolor{RED}{${eqnCharacter.dc}}` : `${eqnCharacter.dc} `;
      default:
      case EqnCharacterType.group:
      case EqnCharacterType.equation:
         return `${getChildKatex()}`;
      case EqnCharacterType.superscript:
         return `^{${getChildKatex()}}`;
      case EqnCharacterType.subscript:
         return `_{${getChildKatex()}}`;
      case EqnCharacterType.fraction: {
         const num = convertEqnCharToKatex(eqnCharacter.children[0]);
         const den = convertEqnCharToKatex(eqnCharacter.children[1]);
         return `\\frac{${num}}{${den}}`;
      }
   }

   function getChildKatex(): string {
      return eqnCharacter.children.map((v) => convertEqnCharToKatex(v)).join('');
   }
}

export function flattenEqnCharTree(eqnCharacter: EqnCharacter): EqnCharacter[] {
   let eqnCharFlatArray: EqnCharacter[] = [];

   if (eqnCharacter.type === EqnCharacterType.fraction) {
      eqnCharFlatArray = eqnCharFlatArray.concat(flattenEqnCharTree(eqnCharacter.children[0]));
      eqnCharFlatArray.push(eqnCharacter);
      eqnCharFlatArray = eqnCharFlatArray.concat(flattenEqnCharTree(eqnCharacter.children[1]));
      return eqnCharFlatArray;
   }

   if (eqnCharacter.children.length > 0) {
      eqnCharacter.children.forEach((v) => {
         eqnCharFlatArray = eqnCharFlatArray.concat(flattenEqnCharTree(v));
      })
   } else {
      eqnCharFlatArray.push(eqnCharacter);
   }

   return eqnCharFlatArray;
}


export function flattenStrokes(storedCharacterAnalysis: Record<string, CharacterAnalysisType[]>) {
   const flatArray: StrokeAnalysisType[] = [];

   Object.keys(storedCharacterAnalysis).forEach((key: string) => {
      const charAnalysis = storedCharacterAnalysis[key];
      charAnalysis.forEach((analysis) => {
         analysis.strokes.forEach((stroke) => {
            flatArray.push(stroke);
         })
      })
   });
   return flatArray;
}