<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <input :id="fid"
           :name="schemaitem.name"
           :class="'form-control ' + validationState"
           :type=" schemaitem.formGen.inputType"
           :value="currentValue"
           :aria-describedby="fid"
           :placeholder="schemaitem.placeHolder"
           :readonly="schemaitem.properties.disabled"
           required
           :autocomplete="schemaitem.autocomplete"
           @input="currentValue=$event.target.value">
  </form-input-group>
</template>

<script>
  import formInputGroup from "./form-input-layout.vue";
  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormInputText",
    components: { formInputGroup },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context) {
      return {
        ...useValidation(props, context),
      };
    },
  });
</script>
<style lang="">
</style>