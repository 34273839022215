
export function OneDNewton(fnc: (x: number) => number, xo: number, stepSize: number): number {
    let fo = fnc(xo);
    const maxIterations = 20;
    let iters = 0;
    let x_new = xo + stepSize;
    let f_new = fnc(x_new);
    let dfdx;

    while (Math.abs(f_new) > 1E-12 && iters < maxIterations) {
        dfdx = (f_new - fo) / (x_new - xo);

        iters++;
        xo = x_new;
        fo = f_new;

        x_new = xo - fo / dfdx;
        if (x_new <= 0) { x_new = 1E-12; }
        f_new = fnc(x_new);
    }

    return xo;
}

export function GridSearch(fnc: (x: number) => number, x_min: number, x_max: number): number[] {
    const xValues = [];
    const fValues = [];

    const nrGridPoints = 50;
    const dX = (x_max - x_min) / (nrGridPoints - 1);
    for (let i = 0; i < nrGridPoints; i++) {
        xValues.push(x_min + dX * i);
        fValues.push(fnc(xValues[i]));
    }

    const roots = [];
    for (let i = 1; i < nrGridPoints; i++) {
        if (fValues[i - 1] !== null &&
            fValues[i] !== null &&
            fValues[i - 1] * fValues[i] < 0) {
            roots.push(Bisect(fnc, xValues[i - 1], xValues[i]));
        }
    }

    return roots;
}

export function Bisect(fnc: (x: number) => number, x_left: number, x_right: number): number {
    let f_left = fnc(x_left);
    let f_right = fnc(x_right);

    const maxIterations = 10;
    let iters = 0;

    while (Math.abs(f_left) > 1E-10 && iters < maxIterations) {
        iters++;

        const x_new = 0.5 * (x_left + x_right);
        const f_new = fnc(x_new);

        if (f_left * f_new < 0) {
            f_right = f_new;
            x_right = x_new;
        } else {
            f_left = f_new;
            x_left = x_new;
        }
    }
    return x_left;
}

