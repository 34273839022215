
import { QRandom } from './qRandom';
import { BaseVariable } from './base-variable';
import { LookupTableType } from '../../mathjs/math-types';
import { TypeGuard } from '../../mathjs/Type-guards';

export enum ModelVariableType {
   INTEGER = "INTEGER",
   FLOAT = "FLOAT",
   BOOLEAN = "BOOLEAN"
}

export interface ModelVariableOptions {
   name: string;
   type: ModelVariableType;
   min: string;
   max: string;
   increment: number | string;
   nrSigFigs: number | string;
   unit: string;
}

export class ModelVariable extends BaseVariable {

   public options: ModelVariableOptions = {
      name: "",
      type: ModelVariableType.INTEGER,
      min: '0',
      max: '10',
      increment: 2,
      nrSigFigs: 3,
      unit: ""
   }

   constructor(name: string, options: Partial<ModelVariableOptions>) {
      super(name);
      this.options = { ...this.options, ...options };
   }

   getTestValue(lookupTable: LookupTableType, selectMin = true): number | string {
      let tmpVal;
      const nrSigFigs = BaseVariable.reduceVariable(this.options.nrSigFigs, lookupTable);

      if (selectMin) {
         tmpVal = BaseVariable.reduceVariable(this.options.min, lookupTable);
      } else {
         tmpVal = BaseVariable.reduceVariable(this.options.max, lookupTable);
      }

      if (!TypeGuard.isNumber(nrSigFigs)) { return "Unable to reduce NrSigFigs to a number." }
      if (!TypeGuard.isNumber(tmpVal)) { return "Unable to reduce MIN to a number." }

      return ModelVariable.precise(tmpVal, nrSigFigs);
   }

   getValue(lookupTable: LookupTableType): number | string {
      let tmpVal;
      const max = BaseVariable.reduceVariable(this.options.max, lookupTable);
      const min = BaseVariable.reduceVariable(this.options.min, lookupTable);
      const incr = BaseVariable.reduceVariable(this.options.increment, lookupTable);
      const nrSigFigs = BaseVariable.reduceVariable(this.options.nrSigFigs, lookupTable);

      if (!TypeGuard.isNumber(max)) { return "Unable to reduce MAX to a number." }
      if (!TypeGuard.isNumber(min)) { return "Unable to reduce MIN to a number." }
      if (!TypeGuard.isNumber(incr)) { return "Unable to reduce INCR to a number." }
      if (!TypeGuard.isNumber(nrSigFigs)) { return "Unable to reduce NrSigFigs to a number." }
      if (max < min) { return "MAX is larger than MIN." }

      const dVal = max - min;

      if (incr !== null && incr !== 0) {
         const maxNrIncrements = dVal / incr;
         tmpVal = Math.floor(maxNrIncrements * QRandom.getRnd()) * incr;
      } else {
         tmpVal = QRandom.getRnd() * dVal;
      }

      tmpVal += min

      if (this.options.type === ModelVariableType.INTEGER)
         return Math.floor(ModelVariable.precise(tmpVal, nrSigFigs));

      return ModelVariable.precise(tmpVal, nrSigFigs);
   }
}
