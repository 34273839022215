import { AmplifyUser, AmplifyUserCredentials } from '@/views/components/login/login.interface';
import { defineStore } from 'pinia'

type UserState = {
    user: AmplifyUser | null,
    credentials: AmplifyUserCredentials | null,
}

export const useUserStore = defineStore({
    id: "UserStore",
    state: (): UserState => ({
        user: null,
        credentials: null
    }),
    actions: {
        setUser(newUser: AmplifyUser) {
            this.$state.user = newUser;
        },
        setCredentials(newCredentials: AmplifyUserCredentials) {
            this.$state.credentials = newCredentials;
        },
        logout() {
            this.$state.user = null;
            this.$state.credentials = null;
        }
    },
    getters: {
        isSignedIn: (state: UserState) => state.user !== null,
        userName: (state: UserState) => state.user?.attributes.email,
        lastName: (state: UserState) => state.user?.attributes.family_name,
        firstName: (state: UserState) => state.user?.attributes.name,
        fullName: (state: UserState) => (state.user?.attributes.name + ' ' + state.user?.attributes.family_name),
        groups: (state: UserState) => state.user?.signInUserSession.accessToken.payload["cognito:groups"],
        isAdmin: (state: UserState) => state.user?.signInUserSession.accessToken.payload["cognito:groups"].includes('Admin'),
        isTA: (state: UserState) => state.user?.signInUserSession.accessToken.payload["cognito:groups"].includes('TA'),
        isStudent: (state: UserState) => state.user?.signInUserSession.accessToken.payload["cognito:groups"].includes('Student'),
        identityID: (state: UserState) => state.credentials?.identityId,
    }
});