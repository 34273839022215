<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <BOffCanvasList :key="isLargeScreen"
                        title="Table of Contents"
                        :model-value="rootPageIndex"
                        :active-id="pageIndex.id"
                        :editable="editable"
                        :nestable="nestable"
                        @update:modelValue="updateItems"
                        @addItem="onAddItem"
                        @deleteItem="onDeleteItem"
                        @reorderItems="onReorderItems"
                        @click="clickOnPageIndex" />
      </div>
      <div class="col-12 col-lg-9">
        <div class="container-fluid">
          <div class="row">
            <div class="col text-start">
              <h3 :key="currentPageNr">
                <BButton v-if="!isLargeScreen"
                         class="btn btn-outline-primary "
                         icon="bi-list"
                         data-bs-toggle="offcanvas"
                         data-bs-target="#textbook-offcanvas"
                         aria-controls="offcanvasExample" />
                <span class="prefix"> {{ pageIndex.prefix + " " }}</span>
                <input v-if="editable"
                       placeholder="Enter Name Here..."
                       class="titleInput "
                       :value="pageIndex.name"
                       @input="onChangeItemName($event.target.value)">
                <span v-else
                      class="title">
                  {{ pageIndex.name }}
                </span>
              </h3>
            </div>
            <div class="col text-end">
              <BPaginator v-if="getMaxNrPages() > 1"
                          v-model:pageNr="currentPageNr"
                          :max-page-nrs="getMaxNrPages"
                          class="float-end"
                          @change="handlePageChange" />
            </div>
          </div>
          <slot name="display"
                :data="pageData" />
        </div>
      </div>
    </div>
  </div>
  <BConfirmDialog ref="confirmDialog" />
</template>

<script lang="ts">
  import { defineComponent, EmitsOptions, provide, Ref, ref, SetupContext } from "vue";
  import useCourseMaterials from "@/store/connectors/useCourseMaterials";
  import BOffCanvasList from "../../components/bootstrap/b-offcanvas-list.vue";
  import { PageIndex } from "@/store/database/Textbook/table-textbook.interface";
  import router from "@/router";
  import { TypedEvent } from "@/components/use/useEvents";
  import { PageRecord } from "@/store/database/Textbook/record-page";
  import BPaginator from "@/components/bootstrap/b-pagination.vue";
  import BButton from "@/components/bootstrap/b-button.vue";
  import { ItemCreationEventType, NameChangeEventType } from './p-page-iterator.types';
  import BConfirmDialog from "@/components/bootstrap/b-confirm-dialog.vue";
  import useConfirmationDialog from "@/components/use/useConfirmationDialog";
  import { ChildBearing } from "@/utils/arrays";
  import { useMediaQuery } from "@vueuse/core";
  import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
  import { TextbookObject } from "@/components/contentGenerator/textbook-object";

  export default defineComponent({
    name: "PageIterator",
    components: { BOffCanvasList, BPaginator, BButton, BConfirmDialog },
    async beforeRouteUpdate(to, from, next) {
      await (this as { fetchData: (pageID: string) => void }).fetchData(
        to.params.pageID as string
      );
      next();
    },
    props: {
      courseId: {
        type: String,
        required: true,
      },
      textId: {
        type: String,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      rootPageId: {
        type: String,
        required: true,
      },
      routeToDestination: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        default: true
      },
      nestable: {
        type: Boolean,
        default: true
      }
    },
    emits: ["deleteItem", "addItem", "changeItemName", "onReorderItems"],
    async setup(props, context: SetupContext<EmitsOptions>) {
      provide("courseId", props.courseId);
      provide("textId", props.textId);

      console.debug("Opening Page Iterator");

      const { findPage, findBook } = useCourseMaterials();
      const book = await findBook(props.courseId, props.textId);
      const toc = book?.getTableOfContents();
      if (!toc) return;

      const routeTo = (inputPageId: string) => {
        router.push({
          name: props.routeToDestination,
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
            rootPageId: props.rootPageId,
          },
        });
      };

      const curPageId = ref(props.pageId);
      if (curPageId.value === props.rootPageId) {
        curPageId.value = toc.getIDOfPageNr(1);
      }

      if (toc.getPageIndex()?.length === 0) {
        await toc.rebuildFromBookPages(book as TextbookObject);
      }

      const rootPageIndex = props.editable ? ref(toc.getPageIndex()) : ref(toc.getFlatPageIndexArray(props.rootPageId));
      console.debug("RootPageIndex", rootPageIndex.value)

      let pageData: Ref<undefined | PageRecord> = ref(undefined);
      let pageIndex: Ref<undefined | PageIndex> = ref(undefined);




      const currentPageNr = ref(toc.findPageNrOf(curPageId.value));

      const tmpPageIndex = toc.findPageIndexOf(curPageId.value);
      pageIndex.value = tmpPageIndex === null ? undefined : tmpPageIndex;

      console.log("Found page index", pageIndex)

      const getMaxNrPages = () => {
        return toc.getNrPages();
      };

      const fetchData = async (id: string) => {
        pageData.value = await findPage(props.courseId, props.textId, id);
        const tmpPI = toc.findPageIndexOf(id);
        pageIndex.value = tmpPI === null ? undefined : tmpPI;
      };

      await fetchData(curPageId.value);


      const clickOnPageIndex = async (clickEvent: TypedEvent) => {
        pageIndex.value = toc.findPageIndexFromIndexID(clickEvent.type);

        // const pageIndex = rootPageIndex?.find(
        //   (v) => clickEvent.type === (v as PageIndex).id
        // ) as PageIndex;

        if (pageIndex.value) {
          currentPageNr.value = toc?.findPageNrOf(pageIndex.value.pageID);
          await fetchData(pageIndex.value.pageID);
          routeTo(pageIndex.value.pageID);
        }
      };

      const handlePageChange = async () => {
        const pageId = toc?.getIDOfPageNr(currentPageNr.value);

        if (pageId) {
          await fetchData(pageId);
          routeTo(pageId);
        }
      };

      function getCurrentItemData() {
        return {
          pageData: pageData.value,
          pageIndex: pageIndex.value,
          toc,
          book,
        } as ItemCreationEventType;
      }

      const onAddItem = (): void => {
        context.emit("addItem", getCurrentItemData());
      }

      const { confirmDialog, onDeleteFunctionWithConfirmation } = useConfirmationDialog();
      const onDeleteItem = onDeleteFunctionWithConfirmation((): void => {
        context.emit("deleteItem", getCurrentItemData());
      });

      const onChangeItemName = (name: string): void => {
        context.emit("changeItemName", {
          name,
          ...getCurrentItemData()
        } as NameChangeEventType);
      }

      const onReorderItems = (): void => {
        console.log("Reorder Items event recieve", { rootPageIndex })
        context.emit("onReorderItems", {
          ...getCurrentItemData()
        });
      }

      const updateItems = (newItems: ChildBearing[]) => {
        if (!props.editable) return;

        rootPageIndex.value = newItems;
        const tocRecord = toc.getRecord();
        if (TypeGuard.isNullOrUndefined(tocRecord))
          return;


        if (tocRecord.pageIndex[0].name !== "ROOT") {
          throw Error("Expected Root page index to be the first page.")
        }

        tocRecord.pageIndex[0].children = rootPageIndex.value as PageIndex[];
        console.log("Update after rorder", rootPageIndex.value)
        // set either child of root or entire page index to newItems
        // send up emitl onReoder (onUpdateTOC) 
        //context.emit("update:modelValue", newItems);
        // remove on reorder items..
        console.log("Root Page Index New Value", { newItems })
      }

      const isLargeScreen = useMediaQuery('(min-width: 1024px)')

      return {
        isLargeScreen,
        onAddItem,
        onDeleteItem,
        onChangeItemName,
        onReorderItems,
        toc,
        fetchData,
        pageData,
        pageIndex,
        currentPageNr,
        getMaxNrPages,
        rootPageIndex,
        clickOnPageIndex,
        handlePageChange,
        confirmDialog,
        updateItems
      };
    },
  });
</script>

<style scoped>
  ::v-deep(.prefix) {
    color: gray;
    margin-left: 0.4rem;
  }

  ::v-deep(.itemName) {
    color: black;
  }

  .titleInput {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
</style>