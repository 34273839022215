import { computed } from "@vue/runtime-dom";
import { useTimestamp } from "@vueuse/core";

export default function useTime() {

    const weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const datePlusOffsetHasPast = (dateTime: number, nrDaysOffset = 0) => {
        const curDate = new Date();
        const targetDate = new Date(dateTime + nrDaysOffset * 24 * 60 * 60 * 1000);

        if (curDate > targetDate) return true;
    }


    const countDownTo = (expireDate: number, intervalSeconds = 1000) => {
        //const expireDate = new Date(dateISO);
        const nrMilliSecondsInDay = 24 * 60 * 60 * 1000;

        const timeStamp = useTimestamp({ interval: intervalSeconds });
        const timeLeft = computed(() => {
            const diff = expireDate - timeStamp.value;
            let div = diff / nrMilliSecondsInDay;
            const nrDays = Math.floor(div);
            div -= nrDays;
            div *= 24.0;
            const nrHours = Math.floor(div);
            div -= nrHours;
            div *= 60.0;
            const nrMinutes = Math.floor(div);
            div -= nrMinutes;
            div *= 60.0;
            const nrSeconds = Math.floor(div);

            let str = "";


            if (nrDays > 0) {
                str += `${nrDays} day`;
                if (nrDays > 1)
                    str += 's';

                str += ' ';
            }

            if (nrHours > 0 && nrDays < 2) {
                str += `${nrHours} hour`;
                if (nrHours > 1)
                    str += 's';
            }

            if (nrMinutes > 0 && nrDays === 0 && nrHours < 3) {
                if (str.length > 0) {
                    str += ' and ';
                }

                str += `${nrMinutes} minute`;
                if (nrMinutes > 1)
                    str += 's';
            }

            if (str.length === 0) {
                str += ` ${nrSeconds} seconds`;
            }

            return str;
        });

        return timeLeft;
    }

    const getDateInFuture = (nrDays: number): Date => {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + nrDays);
        return targetDate;
    }


    const getCurrentDateToMinISO = (): string => {
        const newDate = new Date();
        newDate.setMinutes(0, 0, 0);
        return newDate.toISOString();
    }

    const getCurrentDateOnlyToMinISO = (): string => {
        const newDate = new Date();
        newDate.setMinutes(0, 0, 0);
        //return newDate.toISOString().split('T')[0];
        return newDate.toISOString()
    }


    const getCurrentDateTimeISO = (): string => {
        const newDate = new Date();
        newDate.setMinutes(0, 0, 0);
        return newDate.toISOString();
    }

    const getCurrentTimeISO = (): string => {
        const newDate = new Date();
        newDate.setMinutes(0, 0, 0);
        return newDate.toTimeString().split(' ')[0];
    }

    const getLocalTimeFrom = (dateTime: string): string => {
        const newDate = (dateTime.length === 0) ? new Date() : new Date(dateTime);
        return newDate.toTimeString().split(' ')[0];
    }

    const getLocalTimeFromTimeStamp = (timeStamp: number): string => {
        console.log(timeStamp);
        const newDate = new Date(timeStamp);
        return newDate.toTimeString().split(' ')[0];
    }

    const getLocalDateFrom = (dateTime: string): string => {
        console.log(dateTime)

        const newDate = (dateTime.length === 0) ? new Date() : new Date(dateTime);
        return newDate.toLocaleDateString('en-SE');
    }

    const getDayOfWeekLocal = (inputDate: Date) => {
        return weekDayNames[inputDate.getDay()];
    }

    // time - 02:32 or 02:32:03
    // date - 2021-07-27 
    const composeDateTime = (timeLocal: string, dateLocal: string): string => {
        const timeString = timeLocal + (timeLocal.length === 5 ? ":00.000" : ".000");
        const newDate = new Date(dateLocal + 'T' + timeString);
        return newDate.toISOString();
    }

    const computeNrDaysPast = (date: string) => {
        const currentDate = new Date();
        const storedDate = new Date(date);
        const diff = currentDate.getTime() - storedDate.getTime();
        const nrMilliSecondsInDay = 24 * 60 * 60 * 1000;
        return diff / nrMilliSecondsInDay;
    }

    const computeRelativeDateString = (date: string) => {
        let dateStr = "";
        let timeStr = "";
        const currentDate = new Date();
        const storedDate = new Date(date);
        const diff = currentDate.getTime() - storedDate.getTime();
        const nrMilliSecondsInDay = 24 * 60 * 60 * 1000;
        if (Math.abs(diff) <= nrMilliSecondsInDay) {
            if (currentDate.getDay() === storedDate.getDay())
                dateStr = "Today";
            else if (diff > 0)
                dateStr = "Yesterday";
            else if (diff < 0)
                dateStr = "Tomorrow";
        } else if (Math.abs(diff) < 5 * nrMilliSecondsInDay && diff < 0) {
            dateStr = getDayOfWeekLocal(storedDate);
        } else {
            dateStr = new Date(date).toLocaleDateString('default', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' });
            return dateStr as string;
        }

        timeStr = new Date(date).toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' });
        return dateStr + ' at ' + timeStr as string;
    }

    return {
        countDownTo,
        getLocalTimeFromTimeStamp,
        getCurrentDateToMinISO,
        getCurrentDateOnlyToMinISO,
        getCurrentDateTimeISO,
        getCurrentTimeISO,
        getLocalTimeFrom,
        getLocalDateFrom,
        composeDateTime,
        computeRelativeDateString,
        computeNrDaysPast,
        getDateInFuture,
        getDayOfWeekLocal,
        weekDayNames,
        datePlusOffsetHasPast
    }
}