import router from "@/router";
import { EmitsOptions, SetupContext } from "@vue/runtime-core";
import { RouteLocationRaw, Router } from "vue-router";
import { BaseErrorI } from "../global/error.interface";
import { BaseEventI, BaseEventRoute, BaseFormEventI } from "../global/event.interface";

export interface TypedEvent {
    type: string
}

export interface UseEventsReturnItems {
    emitWithAddToPayload: (name: string, event: BaseEventI<unknown, Record<string, unknown>>, obj: Record<string, unknown>) => void;
    emitTyped: (name: string, type: string) => void;
    emitError: (name: string, error: BaseErrorI<unknown, Record<string, unknown>>) => void;
    emitEvent: (name: string, event: BaseEventI<unknown, Record<string, unknown>>) => void;
    emitFormEvent: (name: string, event: BaseFormEventI<unknown, unknown>) => void;
    eventRouter: (event: BaseEventI<unknown, Record<string, unknown>>, routes: BaseEventRoute[], routerVar: Router) => void;
}

export default function useEvents(context: SetupContext<EmitsOptions>): UseEventsReturnItems {

    const emitWithAddToPayload = (name: string, event: BaseEventI<unknown, Record<string, unknown>>, obj: Record<string, unknown>) => {
        event.payload = event.payload ? { ...event.payload, ...obj } : obj;
        context.emit(name, event);
    }

    const emitEvent = (name: string, event: BaseEventI<unknown, Record<string, unknown>>): void => {
        context.emit(name, event);
    }


    const emitTyped = (name: string, type: string) => {
        context.emit(name, { type });
    }

    const emitFormEvent = (name: string, event: BaseFormEventI<unknown, unknown>): void => {
        context.emit(name, event);
    }

    const emitError = (name: string, error: BaseErrorI<unknown, Record<string, unknown>>): void => {
        context.emit(name, error);
    }

    const eventRouter = (event: BaseEventI<unknown, Record<string, unknown>>, routes: BaseEventRoute[], routerVar: Router = router) => {
        const route = routes.find((v) => v.eventName === event.type);
        if (route) {
            console.log("Event Router - routing to:", event.type);
            const newRoute: RouteLocationRaw = {
                name: route.routeName,
                params: route.params
            };
            routerVar.push(newRoute);
        } else {
            throw new Error("Unable to find route.");
        }
    }

    return {
        emitWithAddToPayload,
        emitTyped,
        emitError,
        emitEvent,
        emitFormEvent,
        eventRouter
    };
}