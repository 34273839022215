import { TypeGuard } from "../../mathjs/Type-guards";
import { PointStatistics } from "./ConvexHulls";
import { StrokeAnalysisType } from "./useEqnCharacterAnalysis";

export function usePossibleStrokes() {
   const possibleStrokes: Record<string, {
      strokes: string[],
      minNrStrokes: number,
      maxNrStrokes: number,
      identifyFunc: (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => string
   }> = {};

   function genStr(minNrStrokes: number, maxNrStrokes: number, char: string, strokes: string[], identifyFunc: (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => string = () => { return ""; }) {
      possibleStrokes[char] = {
         strokes,
         identifyFunc,
         minNrStrokes,
         maxNrStrokes
      };
   }

   function hasLeftDownTilt(strokeAnalysis: PointStatistics) {
      return strokeAnalysis.endToEndX < 0 && strokeAnalysis.endToEndY > 0;
   }

   function hasRightDownTilt(strokeAnalysis: PointStatistics) {
      return strokeAnalysis.endToEndX > 0 && strokeAnalysis.endToEndY > 0;
   }



   function getMaxHeightAssignFunc(onMax: string, onMin: string) {
      return (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
         if (findIndexOfMax(strokeAnalysis, "height") === index) {
            return onMax;
         } else {
            return onMin;
         }
      }
   }

   function assignToLeftDown(leftDown: string, other: string) {
      return (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
         if (hasLeftDownTilt(strokeAnalysis[index]))
            return leftDown;
         else
            return other;
      }
   }

   function assignToFunc(func: (index: number, x: PointStatistics[]) => boolean, leftDown: string, other: string) {
      return (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
         if (func(index, strokeAnalysis))
            return leftDown;
         else
            return other;
      }
   }

   function findIndexOfMax(arr: any[], key: string) {
      let indexOfMax = 0;
      let highestVal = arr[0][key];

      arr.forEach((v, ind) => {
         if (v[key] > highestVal) {
            highestVal = v[key];
            indexOfMax = ind;
         }
      })

      return indexOfMax;
   }


   function findIndexOfMin(arr: any[], key: string) {
      let indexOfMin = 0;
      let lowestVal = arr[0][key];

      arr.forEach((v, ind) => {
         if (v[key] < lowestVal) {
            lowestVal = v[key];
            indexOfMin = ind;
         }
      })

      return indexOfMin;
   }


   function hasMaxX(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMax(strokeAnalysis, "xMax") === index;
   }

   function hasMinAvgX(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMin(strokeAnalysis, "xAvg") === index;
   }


   function hasMinX(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMin(strokeAnalysis, "xMin") === index;
   }


   function hasMinY(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMin(strokeAnalysis, "yMin") === index;
   }

   function hasMaxY(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMax(strokeAnalysis, "yMax") === index;
   }


   function hasMaxHeight(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMax(strokeAnalysis, "height") === index;
   }


   function hasMaxWidth(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMax(strokeAnalysis, "width") === index;
   }
   function hasMinHeight(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMin(strokeAnalysis, "height") === index;
   }

   function hasMinWidth(index: number, strokeAnalysis: PointStatistics[]) {
      return findIndexOfMin(strokeAnalysis, "width") === index;
   }

   function isNotYetLabeled(index: number, strokeAnalysis: StrokeAnalysisType[]) {
      return TypeGuard.isNullOrUndefined(strokeAnalysis[index].strokeLabel) || strokeAnalysis[index].strokeLabel === "";
   }


   genStr(1, 2, "1", ["|", "|", "-", "/"], getMaxHeightAssignFunc("|", "-"));

   genStr(1, 1, "2", ["2"]);
   genStr(1, 1, "3", ["3"]);
   genStr(2, 2, "4", ["4t", "|"], getMaxHeightAssignFunc("|", "4t"));

   genStr(1, 2, "5", ["5", "-"], getMaxHeightAssignFunc("5", "-"));
   genStr(1, 1, "6", ["6"]);
   genStr(1, 2, "7", ["7", "-"], getMaxHeightAssignFunc("7", "-"));
   genStr(1, 1, "8", ["8"]);
   genStr(1, 1, "9", ["9"]);
   genStr(1, 1, "0", ["0"]);
   genStr(1, 1, "\\cdot", ["."]);
   genStr(3, 3, "\\div", ["\\div"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMaxWidth(index, strokeAnalysis))
         return "-";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return ".";

      return "";
   });
   genStr(3, 3, "\\ne", ["\\ne"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMaxHeight(index, strokeAnalysis))
         return "/";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "-";

      return "";
   });
   genStr(2, 2, "\\leq", ["\\leq"], assignToFunc(hasMaxY, "-", "<"));
   genStr(2, 2, "\\geq", ["\\geq"], assignToFunc(hasMaxY, "-", ">"));
   genStr(1, 1, ">", [">"]);
   genStr(1, 1, "<", ["<"]);
   genStr(1, 1, "\\sim", ["~"]);
   genStr(2, 2, "\\simeq", ["\\simeq"], assignToFunc(hasMaxY, "-", "~"));
   genStr(2, 2, "\\approx", ["\\approx"], assignToFunc(hasMaxY, "~", "~"));
   genStr(1, 1, "\\propto", ["\\propto"]);
   genStr(1, 2, "\\vec{}", ["\\vec{}", "head", ">", "-", "/", "\\"]);
   genStr(1, 1, "\\hat{}", ["\\hat"]);
   genStr(2, 2, "\\times", ["/", "\\"], assignToLeftDown("/", "\\"));
   genStr(1, 1, "/", ["/"]);
   genStr(2, 2, "+", ["-", "|"], getMaxHeightAssignFunc("|", "-"));
   genStr(1, 1, "-", ["-"]);
   genStr(1, 1, "---", ["---"]);
   genStr(1, 1, ".", ["."]);
   genStr(4, 4, "*", ["-", "|", "/", "\\"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      const curStroke = strokeAnalysis[index];
      if (hasMinHeight(index, strokeAnalysis))
         return "-";
      if (hasMinWidth(index, strokeAnalysis))
         return "|";
      if (isNotYetLabeled(index, strokeAnalysis as any) && hasLeftDownTilt(curStroke))
         return "/";
      if (isNotYetLabeled(index, strokeAnalysis as any) && hasRightDownTilt(curStroke))
         return "\\";

      return "";
   });
   genStr(1, 1, "^", ["^"]);
   genStr(1, 2, "\\sqrt{}", ["\\sqrt{}"]);
   genStr(1, 1, "\\partial{}", ["\\partial{}"]);
   genStr(1, 1, "a", ["a"]);
   genStr(1, 1, "b", ["b"]);
   genStr(1, 1, "c", ["c"]);
   genStr(1, 1, "d", ["d"]);
   genStr(1, 1, "e", ["e"]);
   genStr(2, 2, "f", ["f", "-"], getMaxHeightAssignFunc("f", "-"));
   genStr(1, 1, "g", ["g"]);
   genStr(1, 1, "h", ["h"]);
   genStr(2, 2, "i", ["i", "|", "."]);
   genStr(2, 2, "j", ["j", "."]);
   genStr(2, 2, "k", ["k", "|", "<"], assignToFunc(hasMaxX, "<", "|"));
   genStr(1, 1, "l", ["l"]);
   genStr(1, 1, "m", ["m"]);
   genStr(1, 1, "n", ["n"]);
   genStr(1, 1, "o", ["0"]);
   genStr(1, 2, "p", ["p"], getMaxHeightAssignFunc("|", ")"));
   genStr(1, 2, "q", ["q"], assignToFunc(hasMinY, "q", "-"));
   genStr(1, 1, "r", ["r"]);
   genStr(1, 1, "s", ["s"]);
   genStr(2, 2, "t", ["t", "-"], getMaxHeightAssignFunc("t", "-"));
   genStr(1, 1, "u", ["u"]);
   genStr(1, 2, "v", ["v", "\\", "/"], assignToLeftDown("/", "\\"));
   genStr(1, 1, "w", ["w"]);
   genStr(2, 2, "x", ["x", "\\", "/"], assignToLeftDown("/", "\\"));
   genStr(1, 2, "y", ["y", "\\"], assignToLeftDown("/", "\\"));
   genStr(1, 2, "z", ["z", "-"], getMaxHeightAssignFunc("z", "-"));
   genStr(1, 1, "[", ["["]);
   genStr(1, 1, "]", ["]"]);
   genStr(1, 1, "(", ["("]);
   genStr(1, 1, ")", [")"]);
   genStr(2, 2, "=", ["-", "-"], getMaxHeightAssignFunc("-", "-"));
   genStr(1, 1, "|", [""]);

   genStr(2, 3, "A", ["/", "\\", "-"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      const nrStrokes = strokes.length;

      if (nrStrokes === 2) {
         if (hasMaxWidth(index, strokeAnalysis))
            return "Vinv";
         else
            return "-";
      }

      if (nrStrokes === 3) {
         if (hasMinHeight(index, strokeAnalysis)) {
            return "-";
         }

         if (isNotYetLabeled(index, strokeAnalysis as any) && hasRightDownTilt(strokeAnalysis[index])) {
            return "\\";
         }
         if (isNotYetLabeled(index, strokeAnalysis as any) && hasLeftDownTilt(strokeAnalysis[index])) {
            return "/";
         }
      }


      return "";
   });
   genStr(1, 2, "B", ["|", "B"], assignToFunc(hasMaxWidth, "3", "|"));
   genStr(1, 1, "C", ["c"]);
   genStr(2, 2, "D", ["|", ")"], assignToFunc(hasMinAvgX, "|", ")"));
   genStr(2, 4, "E", ["[", "L", "|", "-"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMinAvgX(index, strokeAnalysis)) {
         if (strokes.length === 3) return "L";
         if (strokes.length === 4) return "|";
         if (strokes.length === 2) return "[";
      }

      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "-";

      return "";
   });
   genStr(3, 3, "F", ["|", "-"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMinWidth(index, strokeAnalysis))
         return "|";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "-";

      return "";
   });
   genStr(1, 2, "G", ["G", "GC", "G-"], assignToFunc(hasMinX, "GC", "G-"));
   genStr(3, 3, "H", ["|", "-"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMaxWidth(index, strokeAnalysis))
         return "-";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "|";

      return "";
   });
   genStr(3, 3, "I", ["|", "-"]);
   genStr(2, 2, "J", ["J", "-"]);
   genStr(2, 2, "K", ["|", "<"], assignToFunc(hasMaxWidth, "<", "|"));
   genStr(1, 1, "L", ["L"]);
   genStr(1, 2, "M", ["M"], assignToFunc(hasMinWidth, "|", "Mremain"));
   genStr(1, 2, "N", ["N"], assignToFunc(hasMinWidth, "|", "Nremain"));
   genStr(1, 1, "O", ["0"]);
   genStr(1, 2, "P", ["P"], assignToFunc(hasMinAvgX, "|", "Cinv"));
   genStr(2, 2, "Q", ["Q"], assignToFunc(hasMinY, "0", "\\"));
   genStr(1, 2, "R", ["R", "|", "RR"], assignToFunc(hasMaxWidth, "RR", "|"));
   genStr(1, 1, "S", ["s"]);
   genStr(2, 2, "T", ["T"], getMaxHeightAssignFunc("|", "-"));
   genStr(1, 1, "U", ["U"]);
   genStr(1, 2, "V", ["v"]);
   genStr(1, 1, "W", ["w"]);
   genStr(2, 2, "X", ["X"], assignToLeftDown("/", "\\"));
   genStr(1, 3, "Y", ["Y", "v", "|", "\\", "/"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      const nrStrokes = strokes.length;

      if (nrStrokes === 2) {
         if (hasMaxWidth(index, strokeAnalysis))
            return "V";
         else
            return "|";
      }

      if (nrStrokes === 3) {
         if (hasMinWidth(index, strokeAnalysis)) {
            return "|";
         }

         if (isNotYetLabeled(index, strokeAnalysis as any) && hasRightDownTilt(strokeAnalysis[index])) {
            return "\\";
         }
         if (isNotYetLabeled(index, strokeAnalysis as any) && hasLeftDownTilt(strokeAnalysis[index])) {
            return "/";
         }
      }


      return "";
   });
   genStr(1, 2, "Z", ["z", "-"], getMaxHeightAssignFunc("z", "-"));

   genStr(1, 1, "\\alpha", ["\\alpha"]);
   genStr(1, 2, "\\beta", ["\\beta"], assignToFunc(hasMaxHeight, "\\beta", "Cinv"));
   genStr(1, 1, "\\gamma", ["\\gamma"]);
   genStr(1, 1, "\\delta", ["\\delta"]);
   genStr(1, 2, "\\epsilon", ["\\epsilon"], assignToFunc(hasMaxHeight, "c", "-"));
   genStr(2, 2, "\\theta", ["\\theta"], assignToFunc(hasMaxHeight, "0", "-"));
   genStr(2, 2, "\\lambda", ["\\lambda"], assignToLeftDown("/", "\\lambda"));
   genStr(1, 1, "\\mu", ["\\mu"]);
   genStr(1, 1, "\\nu", ["\\nu"]);
   //genStr(2, 2, "\\xi", ["\\xi"]);
   genStr(3, 3, "\\pi", ["\\pi"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMaxWidth(index, strokeAnalysis))
         return "-";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "|";

      return "";
   });
   genStr(1, 1, "\\sigma", ["\\sigma"]);
   genStr(2, 2, "\\tau", ["\\tau"], getMaxHeightAssignFunc("t", "~"));
   genStr(2, 2, "\\phi", ["\\phi"], getMaxHeightAssignFunc("|", "0"));
   genStr(2, 2, "\\psi", ["\\psi"], getMaxHeightAssignFunc("|", "psi"));
   genStr(1, 1, "\\omega", ["\\omega"]);
   genStr(1, 1, "\\Delta", ["\\Delta"]);
   //genStr(1, 1, "\\Theta", ["\\Theta"]);
   //genStr(1, 1, "\\Lambda", ["\\Lambda"]);
   genStr(1, 3, "\\Sigma", ["\\Sigma"], (index: number, strokes: paper.Item[], strokeAnalysis: PointStatistics[]) => {
      if (hasMaxWidth(index, strokeAnalysis))
         return "\\Sigma";
      if (isNotYetLabeled(index, strokeAnalysis as any))
         return "|";

      return "";
   });
   //genStr(1, 1, "\\Phi", ["\\Phi"]);
   //genStr(1, 1, "\\Psi", ["\\Psi"]);
   //genStr(1, 1, "\\Omega", ["\\Omega"]);

   return {
      possibleStrokes
   }
}