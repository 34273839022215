
  import BCard from "@/components/bootstrap/b-card.vue";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";
  import { CourseRecord } from '@/store/database/Course/record-course';
  import useConfirmationDialog from '@/components/use/useConfirmationDialog';
  import BConfirmDialog from '@/components/bootstrap/b-confirm-dialog.vue';


  export default {
    name: "AvailableCourseList",
    components: { BCard, BConfirmDialog },
    async setup() {
      const { userName, isAdmin } = useUserStore();
      if (!userName) return;

      const availableCourses =
        await useDatabaseMappings().getAvailableCourseList();

      console.log({ availableCourses })
      const onClick = (courseId: string) => {
        router.push({ name: "AvailableCourseDetail", params: { courseId } });
      };


      const { confirmDialog } = useConfirmationDialog();

      const gotoCreate = () => {
        confirmDialog.value
          ?.open(
            "Create A New Course",
            "Are you sure you would like to add a new course?",
            {}
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed)
              router.push({ name: "CreateCourse" });
          });
      }

      const { resolveURL } = useS3();


      const getFooter = (course: CourseRecord) => {
        if (course.data().availibility) {
          switch (course.data().availibility?.restrictionType) {
            case "EMAIL_MATCH":
              return "Restricted";
            case "FREE":
              return "Free";
            case "ACCESS_CODE":
              return "Code Required";
            case "PAID":
              return "Cost: $" + course.data().availibility?.cost;
          }
        }

        return "";
      }

      return {
        availableCourses,
        onClick,
        resolveURL,
        gotoCreate,
        getFooter,
        isAdmin,
        confirmDialog
      };
    },
  };
