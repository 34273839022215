import { FieldType, AvailibilityInput, ReceiptInput } from '@/API';

export interface CourseTableBaseType {
    id: string;
    courseID?: string;

    name?: string;
    description?: string;
    long_description?: string;

    fieldType?: FieldType;

    availibility?: AvailibilityInput;
    isActive?: boolean;

    userEmail?: string;

    co_editors?: [string];

    createdAt?: string;
    updatedAt?: string;

    ttlExpireTime?: number;
}

export interface SubTopics {
    name: string;
    id: string;
}

export interface Topics {
    name: string;
    id: string;
    subTopics: SubTopics[];
}

export interface CourseFromCourseTable extends CourseTableBaseType {
    co_textbookID?: string;
    co_assignmentBook?: string;
    co_laboratoryID?: string;
    co_questionBankID?: string;
    co_pictureURL?: string;
    co_editors?: [string];
    co_includeText?: boolean;
    co_includeAssignments?: boolean;
    co_includeLab?: boolean;
    co_includeNotebook?: boolean;
    co_topics?: Topics[];
}

export interface AssignmentFromCourseTable extends CourseFromCourseTable {
    assign_data?: any;
}

export interface RegistrationFromCourseTable extends CourseFromCourseTable {
    reg_initDate?: string;
    reg_expiration?: string;
    reg_receipt?: ReceiptInput;
    reg_role?: string;
    reg_userID?: string;
}

export interface NotebookFromCourseTable extends CourseFromCourseTable {
    note_bookID?: string;
}

export enum CourseTableSearchTypes {
    COURSE_BY_ID,
    COURSE_LIST,
    REGISTRATIONS_BY_COURSE,
    REGISTRATIONS_BY_USER,
    NOTEBOOKS_BY_COURSE,
    NOTEBOOKS_BY_USER
}
