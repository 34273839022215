<template>
  <li class="nav-item dropdown">
    <a id="navbarDropdown"
       class="nav-link dropdown-toggle"
       href="#"
       role="button"
       data-bs-toggle="dropdown"
       aria-expanded="false">
      <i v-if="dropButtonStyle.iconName"
         :class="'bi ' + dropButtonStyle.iconName" />
      {{ dropButtonStyle.label }}
    </a>
    <ul :class="'dropdown-menu ' + (dropButtonStyle.rightSideDrop?'dropdown-menu-end':'')"
        aria-labelledby="navbarDropdown">
      <li v-for="(item, index) in items"
          :key="index">
        <router-link v-if="item.type== bNavbarDropdownType.LINK && (!item.restrictToAdmin || isAdmin)"
                     :to="item.to"
                     class="nav-link">
          <i v-if="item.type== bNavbarDropdownType.LINK && item.iconName"
             class="p-2"
             :class="'bi ' + item.iconName" />
          <span>
            {{ item.label }}
          </span>
        </router-link>
        <hr v-if="item.type== bNavbarDropdownType.DIVIDER"
            class="dropdown-divider">
        <span v-if="item.type == bNavbarDropdownType.TEXT"
              class="nav-link">
          {{ item.label }}
        </span>
        <div v-if="item.type == bNavbarDropdownType.ACTION"
             @click="() => {item.action()}"
             class="nav-link">
          <i v-if="item.iconName"
             class="p-2"
             :class="'bi ' + item.iconName" />
          <span>
            {{ item.label }}
          </span>
        </div>
      </li>
    </ul>
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import {
    bNavbarDropdownPropTypes,
    bNavbarDropdownType,
  } from "./b-navbar.interface";

  export default defineComponent({
    props: {
      isAdmin: {
        type: Boolean,
        default: false
      },
      dropButtonStyle: {
        type: Object as PropType<{
          label: string;
          iconName: string;
          rightSideDrop: boolean;
        }>,
        default: () => {
          return { label: "Dropdown", iconName: "bi-person-fill" };
        },
      },
      items: {
        type: Array as PropType<Array<bNavbarDropdownPropTypes>>,
        required: true,
      },
    },
    setup() {
      return {
        bNavbarDropdownType,
      };
    },
  });
</script>

<style scoped>
</style>