import nerdamer from 'nerdamer/nerdamer.core.js'
import 'nerdamer/Algebra.js'
import 'nerdamer/Calculus.js'
import 'nerdamer/Solve.js'

import { BaseVariable } from './base-variable';
import { ModelVariableType } from './model-variable';
import { NerdExpression } from '../../nerdamer/nerdamer.interface';
import { LookupTableType } from '../../mathjs/math-types';

export enum ModelVectorVariableType {
   XYZ = "XYZ",
   POLAR_XY = "Polar XY",
   AZI_ELEV = "Azi-Elev",
   MAG_DIR = "Magnitude + Direction"
}

export interface ModelVectorVariableOptions {
   name: string;
   vectorType: ModelVectorVariableType;
   type: ModelVariableType;
   magnitude: string;
   theta: string;
   azimuth: string;
   elevation: string;
   direction: string;
   x: string;
   y: string;
   z: string;
   unit: string;
   nrSigFigs: number;
}


export interface ModelVectorVariableTable {
   [key: string]: ModelVectorVariable;
}

export class ModelVectorVariable extends BaseVariable {

   public options: ModelVectorVariableOptions = {
      name: "",
      vectorType: ModelVectorVariableType.XYZ,
      type: ModelVariableType.FLOAT,
      magnitude: '0',
      theta: '0',
      azimuth: '0',
      elevation: '0',
      direction: '',
      x: '0',
      y: '0',
      z: '0',
      unit: '',
      nrSigFigs: 3
   }

   constructor(name: string, options: Partial<ModelVectorVariableOptions>) {
      super(name);
      this.options = { ...this.options, ...options };
   }

   getValue(lookupTable: LookupTableType): NerdExpression {
      let substitutionValues = {};
      let vstr = [];

      switch (this.options.vectorType) {
         case ModelVectorVariableType.XYZ:
            substitutionValues = {
               xCompnwoieur: BaseVariable.reduceVariable(this.options.x, lookupTable),
               yCompnwoieur: BaseVariable.reduceVariable(this.options.y, lookupTable),
               zCompnwoieur: BaseVariable.reduceVariable(this.options.z, lookupTable)
            };

            vstr = ["xCompnwoieur", "yCompnwoieur", "zCompnwoieur"];
            break;
         case ModelVectorVariableType.POLAR_XY:
            substitutionValues = {
               magnwoieur: BaseVariable.reduceVariable(this.options.magnitude, lookupTable),
               thetanwoieur: BaseVariable.reduceVariable(this.options.theta, lookupTable)
            };

            vstr = ["magnwoieur * cosd(thetanwoieur)",
               "magnwoieur * sind(thetanwoieur)",
               "0"];
            break;
         case ModelVectorVariableType.AZI_ELEV:
            substitutionValues = {
               mag2nwoieur: BaseVariable.reduceVariable(this.options.magnitude, lookupTable),
               azinwoieur: BaseVariable.reduceVariable(this.options.azimuth, lookupTable),
               elevnwoieur: BaseVariable.reduceVariable(this.options.elevation, lookupTable)
            };

            vstr = ['mag2nwoieur * cosd(elevnwoieur) * cosd(azinwoieur)',
               'mag2nwoieur * cosd(elevnwoieur) * sind(azinwoieur)',
               'mag2nwoieur * sind(elevnwoieur)'];
            break;
         case ModelVectorVariableType.MAG_DIR:
            console.log('mag + dir');
            substitutionValues = {
               mag2nwoieur: BaseVariable.reduceVariable(this.options.magnitude, lookupTable),
               unitvwoieur: BaseVariable.reduceVariable('unitv(' + this.options.direction + ')', lookupTable)
            };

            return nerdamer('mag2nwoieur * unitvwoieur')
               .evaluate(substitutionValues);
            break;
      }


      const adjForType = (str: string) => {
         switch (this.options.type) {
            case ModelVariableType.FLOAT:
               return str;
            case ModelVariableType.INTEGER:
               return 'round(' + str + ')';
         }
      }

      vstr = vstr.map((v) => { return adjForType(v); })
      return nerdamer('vector(' + vstr[0] + ',' + vstr[1] + ',' + vstr[2] + ')')
         .evaluate(substitutionValues);

   }



}
