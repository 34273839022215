import * as BABYLON from 'babylonjs';

import { BasePrimativeObjectGenerator } from "../Factories/PrimativeFactoryInterface";
import { CylinderGenerator } from "../CylinderGenerator";
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { Nullable } from 'babylonjs/types';



export interface PipeTubeOptions {
    name?: string,
    startPoint: BABYLON.Vector3,
    endPoint: BABYLON.Vector3,
    diameter: number
}

export class Pipe_Tube extends BasePrimativeObjectGenerator {
    public originalMesh: Nullable<BABYLON.Mesh> = null;
    public defaultPrimOptions: PipeTubeOptions = {
        name: "",
        startPoint: BABYLON.Vector3.Zero(),
        endPoint: BABYLON.Vector3.Zero(),
        diameter: 0.00254
    }

    constructor(scene: BABYLON.Scene) {
        super(scene);
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        return;
    }

    public create(parent: BABYLON.Mesh, options: PipeTubeOptions) {
        options = { ...this.defaultPrimOptions, ...options };
        const pipe = CylinderGenerator.create(this.scene,
            options.startPoint, options.endPoint, options.diameter, 10);

        pipe.parent = parent;
        pipe.material = MaterialRegistry.getCopper(this.scene);
        return pipe;
    }
}