import { ref } from "@vue/reactivity";
import { ConfirmDialogOptions } from "../bootstrap/b-confirm-dialog.interface";
//
// includ the following in your template
//       <BConfirmDialog ref="confirmDialog" />
//
export default function useConfirmationDialog() {
   const confirmDialog = ref<ConfirmDialogOptions | null>(null);

   const onDeleteFunctionWithConfirmation = (onDelete: () => void) => {
      return () => {
         confirmDialog?.value
            ?.open(
               "Delete Item",
               "Clicking Ok will permentaly delete this item! Are you sure?",
               {}
            )
            .then((isConfirmed: boolean) => {
               if (isConfirmed)
                  onDelete();
            });
      }
   }

   const deleteConfirmationModal = (onDelete: () => void) => {
      confirmDialog?.value
         ?.open(
            "Delete Item",
            "Clicking Ok will permentaly delete this item! Are you sure?",
            {}
         )
         .then((isConfirmed: boolean) => {
            if (isConfirmed)
               onDelete();
         });
   }


   return {
      onDeleteFunctionWithConfirmation,
      confirmDialog,
      deleteConfirmationModal
   }
}