
  import {
    computed,
    defineComponent,
    EmitsOptions,
    onMounted,
    PropType,
    reactive,
    ref,
    SetupContext,
    watch,
  } from "vue";
  import BPagination from "./b-table-pagination.vue";
  import {
    bSortDirections,
    bTableEvent,
    bTableFields,
  } from "./b-table.interface";
  import {
    ISortByAscSorter,
    ISortByDescSorter,
    ISortByObjectSorter,
    sort,
  } from "fast-sort";
  import useEvents from "@/components/use/useEvents";
  import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
  import { useIntervalFn } from "@vueuse/core";



  export default defineComponent({
    components: { BPagination },
    props: {
      fields: {
        type: [Object] as PropType<bTableFields[]>,
        required: true,
      },
      data: {
        type: [Object] as PropType<Record<string, unknown>[]>,
        required: true,
      },
      showSearch: {
        type: Boolean,
        default: true,
      },
      showPagination: {
        type: Boolean,
        default: true,
      },
      isScrollable: {
        type: Boolean,
        default: true
      },
      tableClasses: {
        type: String,
        default: "table-bordered table-striped table-hover",
      },
      theadClasses: {
        type: String,
        default: "",
      },
      nrRecordsPerPage: {
        type: Number,
        default: 100,
      },
      updateInterval: {
        type: Number,
        default: null
      }
    },
    emits: ["click"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.log(props.data);


      // -------------------- Initialize --------------------
      const resolve = (obj: Record<string, unknown>, inputKey: string) => {
        // resolve the object key   obj.field1.field2
        // by digging deeper into the object and returing the very
        // last field whcih should be a string
        let path: string[] = inputKey.split(".");
        let current: Record<string, unknown> | string = obj;
        while (path.length) {
          if (typeof current !== "object") return undefined;
          let shifted = path.shift();
          if (!shifted) return undefined;
          current = current[shifted] as Record<string, unknown>;
        }
        return (current as unknown) as string;
      };

      props.fields.forEach((field) => {
        if (!Object.prototype.hasOwnProperty.call(field, "key")) {
          console.debug("Table field is missing a key: ", field);
        }

        if (!Object.prototype.hasOwnProperty.call(field, "label")) {
          field.label = field.key;
        }

        if (!Object.prototype.hasOwnProperty.call(field, "formatter")) {
          field.formatter = (item) => {
            return item as string;
          };
        }

        if (!Object.prototype.hasOwnProperty.call(field, "dynamicItemClass")) {
          field.dynamicItemClass = (item, key, field) => {
            return "";
          };
        }
      });

      const { emitEvent } = useEvents(context);

      const onClick = (
        rowIndex: number,
        colIndex: number,
        rowData: Record<string, unknown>
      ) => {
        emitEvent("click", {
          type: "clickRow",
          payload: {
            rowIndex,
            colIndex,
            rowData,
            field: props.fields[colIndex],
          },
        } as bTableEvent);

        console.debug("BTable - Clicked on:", rowIndex, colIndex, rowData);
        console.debug(" - field:", props.fields[colIndex]);
        console.debug(" - data:", rowData);
      };


      const onHeaderClick = (fieldIndex: number, fieldKey: string, field: bTableFields) => {
        emitEvent("click", {
          type: "clickHeader",
          payload: {
            rowIndex: null,
            colIndex: fieldIndex,
            rowData: null,
            field: field
          }
        })
      }

      const getHTMLContent = (
        row: Record<string, unknown>,
        key: string,
        field: bTableFields
      ): string => {
        return field.dynamicHTML(resolve(row, key) as string, key, row);
      };

      const getDynamicClass = (
        row: Record<string, unknown>,
        key: string,
        field: bTableFields
      ): string => {
        return field.dynamicItemClass(resolve(row, key) as string, key, row);
      };

      const getFormattedData = (
        row: Record<string, unknown>,
        key: string,
        field: bTableFields
      ): string => {
        return field.formatter(resolve(row, key) as string, key, row);
      };

      // --------------- filtering -------------------------
      const searchText = ref("");
      let lowerCaseSearchText = "";

      const filteredRecords = ref(props.data);

      const filterFields = props.fields.filter((field) => {
        return field.includeInFilter;
      });

      const rowFilterData = props.data.map((v) => {
        return JSON.stringify(filterFields.map((field) => { return getFormattedData(v, field.key, field) })).toLowerCase();
      })

      function contains(el: Record<string, unknown>, index: number) {
        return (rowFilterData[index].indexOf(lowerCaseSearchText) !== -1);
      }

      watch(searchText, () => {
        lowerCaseSearchText = searchText.value.toLowerCase();
        filteredRecords.value = props.data.filter(contains);
      });

      // -------------------- Sorting --------------------------
      const getSortIcon = (field: bTableFields) => {
        return field._currentSortDirection === bSortDirections.ASCENDING
          ? "bi-caret-up-fill"
          : "bi-caret-down-fill";
      };

      const sortField = ref(props.fields[0]);
      const sortedData = ref(filteredRecords);

      const sortDataByColumn = (field: bTableFields) => {
        if (field._currentSortDirection === bSortDirections.ASCENDING) {
          return sort(filteredRecords.value).asc((u) => resolve(u, field.key));
        } else {
          return sort(filteredRecords.value).desc((u) => resolve(u, field.key));
        }
      };

      const handleClickSort = (field: bTableFields) => {
        sortField.value = field;
        field._currentSortDirection =
          field._currentSortDirection === bSortDirections.ASCENDING
            ? bSortDirections.DESCENDING
            : bSortDirections.ASCENDING;

        sortedData.value = sortDataByColumn(field);
      };

      // --------------- pagination ----------------------------
      const firstRecordNr = ref(0);
      const lastRecordNr = ref(props.data.length - 1);

      const handlePageRangeChange = (newRange: [number, number]) => {
        firstRecordNr.value = newRange[0];
        lastRecordNr.value = newRange[1];
      };

      const displayedRecords = computed(() => {
        return sortedData.value.slice(firstRecordNr.value, lastRecordNr.value);
      });

      const timeStamp = ref(0);

      if (!TypeGuard.isNullOrUndefined(props.updateInterval)) {
        useIntervalFn(() => {
          timeStamp.value = new Date().getTime();
        }, props.updateInterval * 1000);
      }

      onMounted(() => {
        const initialSort: ISortByObjectSorter<unknown>[] = [];
        props.fields.forEach((field) => {
          if (Object.prototype.hasOwnProperty.call(field, "sortDirection")) {
            let newSortItem =
              field.sortDirection === bSortDirections.ASCENDING
                ? ({
                  asc: (u: Record<string, unknown>): unknown => {
                    return resolve(u, field.key);
                  },
                } as ISortByAscSorter<unknown>)
                : ({
                  desc: (u: Record<string, unknown>): unknown =>
                    resolve(u, field.key),
                } as ISortByDescSorter<unknown>);

            initialSort.push(newSortItem);
          }
        });

        sortedData.value = sort(filteredRecords.value).by(initialSort);
      });

      const processRowVariant = (row: Record<string, unknown>) => {
        const rowVariant = row._rowVariant;

        if (TypeGuard.isNullOrUndefined(rowVariant)) {
          return "";
        }

        if (TypeGuard.isFunction(rowVariant)) {
          return (rowVariant as any)(row);
        }

        return rowVariant;
      }

      return {
        timeStamp,
        processRowVariant,
        getHTMLContent,
        getDynamicClass,
        sortDataByColumn,
        getSortIcon,
        searchText,
        firstRecordNr,
        handlePageRangeChange,
        filteredRecords,
        displayedRecords,
        getFormattedData,
        onClick,
        onHeaderClick,
        handleClickSort,
      };
    },
  });
