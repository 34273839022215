import * as BABYLON from 'babylonjs';

import { ScalarValue } from "../../values/ScalarValue";
import { BaseRenderedObject, RenderedObjectTypes } from '../../primatives/BaseRenderedObject';

export interface ConnectionInterface {
    isConnected: boolean,
    connectedPort: string,
    connectedObject: ConnectableObjectInterface | null,
    cord?: CordConnector
}

export interface PortInternals {
    name?: string,
    value?: ScalarValue | null,
    //displayValue?: InstrumentDisplayValue,
    connection?: ConnectionInterface | null
}

//  Cordable Object should return two points
// [0] - endpoint
// [1] - control point
export interface ConnectableObjectInterface {
    portMap: Map<string, PortInternals>,
    getBezierPoints(port: string): BABYLON.Vector3[],
    registerConnection(myPort: string, connectPort: string, connectToObj: ConnectableObjectInterface): void,
    removeConnection(myPort: string | undefined): void
}

export interface CordOptions {
    p1: BABYLON.Vector3,
    p2: BABYLON.Vector3,
    p3: BABYLON.Vector3,
    p4: BABYLON.Vector3,
    nrPoints: number,
    color?: BABYLON.Color3
}

export class CordConnector extends BaseRenderedObject {
    private options: CordOptions = {
        p1: BABYLON.Vector3.Zero(),
        p2: BABYLON.Vector3.Zero(),
        p3: BABYLON.Vector3.Zero(),
        p4: BABYLON.Vector3.Zero(),
        nrPoints: 15,
        color: BABYLON.Color3.White()
    };

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.CORD_CONNECTOR;
    }

    render() {
        const cubicBezierVectors = BABYLON.Curve3.CreateCubicBezier(
            this.options.p1,
            this.options.p2,
            this.options.p3,
            this.options.p4,
            this.options.nrPoints);

        const cubicBezierCurve = BABYLON.MeshBuilder.CreateTube("cbezier", {
            radius: 0.003,
            path: cubicBezierVectors.getPoints()
        }, this.scene);

        cubicBezierCurve.setParent(this.parent?.getContainerMesh() ?? null);
        this.myContainerNode = cubicBezierCurve;
        return this;
    }

    buildFromTwoObjects(port1: string, obj1: ConnectableObjectInterface, port2: string, obj2: ConnectableObjectInterface) {
        const pnts1 = obj1.getBezierPoints(port1);
        this.options.p1 = pnts1[0];
        this.options.p2 = pnts1[1];

        const pnts2 = obj2.getBezierPoints(port2);
        this.options.p4 = pnts2[0];
        this.options.p3 = pnts2[1];
        return this;
    }
}