import { Maybe } from "@/utils/maybe";
import { ScalarValue } from "./ScalarValue";


export class VariableTable {
    private variableTable: Map<string, ScalarValue> = new Map();

    constructor() {
        return;
    }

    public setVariable(name: string, value: ScalarValue) {
        this.variableTable.set(name, value);
        return this;
    }

    public set(name: string, value: number, unit: string) {
        this.variableTable.set(name, new ScalarValue(value, unit));
        return this;
    }

    public get(name: string): Maybe<ScalarValue> {
        return this.variableTable.get(name);
    }

}