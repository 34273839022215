import { AvailibilityRestrictionType } from '../../../API';
import { DatabaseUtility } from '../databaseUtility';
import { CourseFromCourseTable } from './table-course.interface';

export function getDefaultBaseCourseValues(): CourseFromCourseTable {
    return {
        id: "",
        courseID: "",
        name: "",
        description: "",
        long_description: "",
        availibility: {
            cost: 0,
            restrictionType: AvailibilityRestrictionType.FREE,
            inputCode: "",
            showOn: DatabaseUtility.getCurrentDate(),
            openOn: DatabaseUtility.getCurrentDate(),
            closeOn: DatabaseUtility.getCurrentDate(),
            hideOn: DatabaseUtility.getCurrentDate(),
            restrictToDates: false,
            userEmailList: [],
            userEmailMatch: "",
            stripePriceID: "",
            stripeProductID: ""
        },
        isActive: true,
        userEmail: "",
        co_editors: [""]
    };
}
