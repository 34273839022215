enum UnitType {
    NONE,
    LENGTH,
    PRESSURE,
    ANGLE,
    TIME,
    FLOWRATE,
    VISCOSITY,
    DENSITY,
    TEMPERATURE,
    SPECIFIC_WEIGHT,
    ROTATION,
    AREA,
    VOLUME,
    ENERGY,
    SPECIFIC_HEAT,
    FORCE,
    KINEMATIC_VISCOSITY,
    VELOCITY
}

interface ConversionTerm {
    factor: number,
    type: UnitType
}

export function convert_util(value: number, from: string, to: string) {
    const fromConv = conversionTable[from];
    const toConv = conversionTable[to];
    return value * fromConv.factor / toConv.factor;
}

export function convert_to_base_unit(value: number, from: string) {
    const fromConv = conversionTable[from];
    return value * fromConv.factor;
}

export function convert_from_base_value_to(baseValue: number, toUnit: string) {
    const toConv = conversionTable[toUnit];
    return baseValue / toConv.factor;
}

const conversionTable: { [key: string]: ConversionTerm } = {
    NONE: { factor: 1, type: UnitType.NONE },
    PERCENT: { factor: 0.01, type: UnitType.NONE },

    // Length
    m: { factor: 1, type: UnitType.LENGTH },
    ft: { factor: 0.3048, type: UnitType.LENGTH },
    yard: { factor: 0.9144, type: UnitType.LENGTH },
    yrd: { factor: 0.9144, type: UnitType.LENGTH },
    in: { factor: 0.0254, type: UnitType.LENGTH },
    mile: { factor: 1609.34, type: UnitType.LENGTH },
    mm: { factor: 0.001, type: UnitType.LENGTH },
    cm: { factor: 0.01, type: UnitType.LENGTH },
    km: { factor: 1000, type: UnitType.LENGTH },

    // Angles
    RADIANS: { factor: 1, type: UnitType.ANGLE },
    Radians: { factor: 1, type: UnitType.ANGLE },
    DEG: { factor: 0.0174533, type: UnitType.ANGLE },
    DEGREES: { factor: 0.0174533, type: UnitType.ANGLE },
    DEGREE: { factor: 0.0174533, type: UnitType.ANGLE },

    // Pressure Terms
    Pa: { factor: 1, type: UnitType.PRESSURE },
    kPa: { factor: 1000, type: UnitType.PRESSURE },
    MPa: { factor: 1000000, type: UnitType.PRESSURE },
    bar: { factor: 100000, type: UnitType.PRESSURE },
    mbar: { factor: 100, type: UnitType.PRESSURE },
    inHg: { factor: 3386.39, type: UnitType.PRESSURE },
    mmHg: { factor: 133.322, type: UnitType.PRESSURE },
    psi: { factor: 6894.76, type: UnitType.PRESSURE },
    inH2O: { factor: 248.84, type: UnitType.PRESSURE },
    Torr: { factor: 133.322, type: UnitType.PRESSURE },
    torr: { factor: 133.322, type: UnitType.PRESSURE },
    atm: { factor: 101325, type: UnitType.PRESSURE },

    // time
    s: { factor: 1, type: UnitType.TIME },
    ms: { factor: 0.001, type: UnitType.TIME },
    us: { factor: 0.000001, type: UnitType.TIME },
    min: { factor: 60, type: UnitType.TIME },
    hr: { factor: 3600, type: UnitType.TIME },
    day: { factor: 86400, type: UnitType.TIME },
    week: { factor: 604800, type: UnitType.TIME },

    // flow rates
    "m3/s": { factor: 1, type: UnitType.FLOWRATE },
    "m3/min": { factor: 1 / 60, type: UnitType.FLOWRATE },
    "m3/hr": { factor: 1 / 3600, type: UnitType.FLOWRATE },
    "ft3/s": { factor: 0.0283168, type: UnitType.FLOWRATE },
    "l/s": { factor: 0.001, type: UnitType.FLOWRATE },
    "l/min": { factor: 1.666666E-5, type: UnitType.FLOWRATE },
    "gpm": { factor: 6.309E-5, type: UnitType.FLOWRATE },
    "gpd": { factor: 1, type: UnitType.FLOWRATE },

    // viscosity
    'Pa*s': { factor: 1, type: UnitType.VISCOSITY },
    'N*s/m2': { factor: 1, type: UnitType.VISCOSITY },
    'P': { factor: 0.1, type: UnitType.VISCOSITY },
    'cP': { factor: 0.001, type: UnitType.VISCOSITY },

    // density
    'kg/m3': { factor: 1, type: UnitType.DENSITY },
    'kg/m^3': { factor: 1, type: UnitType.DENSITY },
    'g/cm3': { factor: 0.001, type: UnitType.DENSITY },
    'oz/in3': { factor: 0.00057804, type: UnitType.DENSITY },
    'lb/ft3': { factor: 0.06242, type: UnitType.DENSITY },


    // temperature
    'C': { factor: 1, type: UnitType.TEMPERATURE },

    // sepcific weight
    'N/m3': { factor: 1, type: UnitType.SPECIFIC_WEIGHT },
    'kN/m3': { factor: 0.001, type: UnitType.SPECIFIC_WEIGHT },

    'rad/s': { factor: 1, type: UnitType.ROTATION },
    'rpm': { factor: 0.104719755, type: UnitType.ROTATION },
    'deg/s': { factor: 0.0174533, type: UnitType.ROTATION },

    // area
    'm^2': { factor: 1, type: UnitType.AREA },
    'cm^2': { factor: 1E-4, type: UnitType.AREA },

    // volume
    m3: { factor: 1, type: UnitType.VOLUME },
    ml: { factor: 1E-6, type: UnitType.VOLUME },
    L: { factor: 1E-3, type: UnitType.VOLUME },


    // energy
    J: { factor: 1, type: UnitType.ENERGY },
    kJ: { factor: 1000, type: UnitType.ENERGY },

    // specific heat
    'kJ/kg/K': { factor: 1, type: UnitType.SPECIFIC_HEAT },

    // force
    'N': { factor: 1, type: UnitType.FORCE },
    'kN': { factor: 1000, type: UnitType.FORCE },
    'lbf': { factor: 4.4482216, type: UnitType.FORCE },
    'dyne': { factor: 1E-5, type: UnitType.FORCE },

    // KINEMATIC_VISCOSITY
    'm^2/s': { factor: 1, type: UnitType.KINEMATIC_VISCOSITY },
    'cm^2/s': { factor: 1E-4, type: UnitType.KINEMATIC_VISCOSITY },

    // VELOCITY
    'm/s': { factor: 1, type: UnitType.VELOCITY },
    'km/s': { factor: 1000, type: UnitType.VELOCITY },
    'cm/s': { factor: 0.01, type: UnitType.VELOCITY },
    'km/hr': { factor: 0.27778, type: UnitType.VELOCITY },
    'mph': { factor: 0.44704, type: UnitType.VELOCITY }
};
