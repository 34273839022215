import { Maybe } from "@/utils/maybe";
import { TypeGuard } from "../../mathjs/Type-guards";

interface IndexedErrorType {
   name: string,
   index: number,
   error: string
}

export enum ModelHealthErrorTypes {
   GENERAL,
   EQUATIONS,
   VARIABLES
}

export class ModelHealthReport {
   private timeToCreate = 0;
   private variableCreationErrors: IndexedErrorType[] = [];
   private equationParseErrors: IndexedErrorType[] = [];
   private generalErrors: IndexedErrorType[] = [];

   clear() {
      this.variableCreationErrors = [];
      this.equationParseErrors = [];
      this.generalErrors = [];
   }

   recordModelCreationTime(startTime: number) {
      const endTime = Date.now();
      this.timeToCreate = endTime - startTime;
   }

   getTimeToCreate() { return this.timeToCreate; }

   addVariableError(name: string, index: number, error: string) {
      this.variableCreationErrors.push({ name, index, error });
   }

   addEquationError(name: string, index: number, error: string) {
      this.equationParseErrors.push({ name, index, error });
   }

   addGeneralError(error: string) {
      this.generalErrors.push({ name: "", index: 0, error });
   }

   hasAnyErrors() {
      return this.variableCreationErrors.length > 0 ||
         this.equationParseErrors.length > 0 ||
         this.generalErrors.length > 0
   }

   private getErrorArray(ofType: ModelHealthErrorTypes) {
      switch (ofType) {
         case ModelHealthErrorTypes.EQUATIONS:
            return this.equationParseErrors
         case ModelHealthErrorTypes.GENERAL:
            return this.generalErrors;
         case ModelHealthErrorTypes.VARIABLES:
            return this.variableCreationErrors;
      }
   }

   getErrors(ofType: ModelHealthErrorTypes, index = 0) {
      return this.getErrorArray(ofType)
         .filter((item) => { return item.index === index })
         .map((item) => item.error);
   }

   hasErrors(ofType: ModelHealthErrorTypes, index = 0) {
      return this.getErrorArray(ofType)
         .findIndex((item) => { return item.index === index }) !== -1;
   }
}
