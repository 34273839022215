import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { LocalStorageClass } from "../local/localStorage";

export interface DynamicPullInjection<T> {
    storage: LocalStorageClass,
    pullFromDB: () => unknown,
    reconstructObjects: (data: Record<string, unknown>) => T,
    expirationDate: number,
    creationDate?: number,
    forcePullFromDB?: boolean
}

export async function dynamicPullData<T>(namespace: string,
    localKey: string,
    options: DynamicPullInjection<T>) {
    const { storage,
        pullFromDB,
        expirationDate,
        forcePullFromDB,
        reconstructObjects } = options;

    const creationDate = await storage.getCreationDateFor(localKey);
    const isNullOrExpired = TypeGuard.isNullOrUndefined(creationDate) || new Date().getTime() - creationDate > 60 * 1000 * 60 * 24 * 4;

    if (forcePullFromDB || isNullOrExpired) {
        console.log("Data is being pulled from Database.")
        const data = await pullFromDB();
        await storage.set(localKey, data, expirationDate);
    }

    const pulledData = await storage.get(localKey);
    if (Array.isArray(pulledData)) {
        return pulledData.map((v) => reconstructObjects(v));
    } else {
        return reconstructObjects(pulledData);
    }
}



