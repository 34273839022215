import { MjsMath } from "@/components/contentGenerator/mathjs/math-core";
import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { Ref, ref, SetupContext, EmitsOptions, watch, computed, ComputedRef } from "vue";
import { FormCustomValidationRule, FormSchemaItem, FormTypes, FormValidateRules, FormValidationStates, ValidatedState, ValidationHooks, ValidationMetaData } from "../form-schema.interface";
import { formItemMixinInterface } from "../mixins/form.mixins";

export interface useValidationReturnType {
    currentValue: Ref<unknown>,
    validationState: ComputedRef<"not-tested" | "is-valid" | "is-invalid">,
    getValidationClass: (state: FormValidationStates | undefined) => string;
    isSchemaValid: (schemaRoot: FormSchemaItem) => boolean;
    getArrayValidationForRow: (schemaItem: FormSchemaItem, index: number) => ValidatedState;
    getArrayValidationForItem: (schemaItem: FormSchemaItem, rowIndex: number, fieldName: string) => ValidatedState;
}


export const getValidationClass = (state: FormValidationStates | undefined) => {
    switch (state) {
        case 0:
            return "not-tested";
        case 1:
            return "is-valid";
        case 2:
            return "is-invalid";
        default:
            return "not-tested";
    }
}

function canValidateRuleOnSelf(schemaItem: FormSchemaItem) {
    switch (schemaItem.fieldType) {
        case FormTypes.ARRAY_OBJECTS:
        case FormTypes.ARRAY_OBJECTS_EDITOR:
        case FormTypes.ARRAY_OF_COURSETOPICS:
        case FormTypes.ARRAY_OF_EMAIL:
        case FormTypes.ARRAY_OF_FLOAT:
        case FormTypes.ARRAY_OF_INT:
        case FormTypes.ARRAY_OF_QUESTIONS:
        case FormTypes.ARRAY_OF_TEXT:
            return false;
        default:
            return true;
    }
}

export const validateValue = (val: string,
    schemaItem: FormSchemaItem,
    meta: ValidationMetaData | undefined = undefined): ValidatedState => {

    const rules = schemaItem.rules;
    let isValid = true;
    schemaItem._validationHints = [];

    if (rules && canValidateRuleOnSelf(schemaItem)) {
        Object.keys(rules).forEach((item) => {
            if (!ruleIsValid(item, rules[item as keyof FormValidateRules] as number | string | FormCustomValidationRule)) {
                isValid = false;
            }
        });
    }

    schemaItem._isValid = isValid ? FormValidationStates.valid : FormValidationStates.invalid;
    return { state: schemaItem._isValid, class: getValidationClass(schemaItem._isValid), hints: schemaItem._validationHints };

    function ruleIsValid(ruleType: string, ruleValue: number | string | FormCustomValidationRule) {
        switch (ruleType) {
            case "min":
                if (val.length > 0 && val.length < ruleValue) {
                    addHint("Minimum of " + ruleValue + " characters required.");
                    return false;
                }
                break;
            case "max":
                if (val.length > 0 && val.length > ruleValue) {
                    addHint("Maximum of " + ruleValue + " characters allowed.");
                    return false;
                }
                break;
            case "max_value":
                if (val > ruleValue) {
                    addHint("Value must be less than " + ruleValue + ".");
                    return false;
                }
                break;
            case "min_value":
                if (val < ruleValue) {
                    addHint("Value must be greater than " + ruleValue + ".");
                    return false;
                }
                break;
            case "integer":
                if (parseFloat(val) != Math.floor(parseFloat(val))) {
                    addHint("Value must be an integer.");
                    return false;
                }
                break;
            case "email":
                if (TypeGuard.isNullOrUndefined(val) || (val.length > 0 && ! /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.toLowerCase()))) {
                    addHint("Invalid email address.");
                    return false;
                }
                break;
            case "required":
                if (!TypeGuard.isNullOrUndefined(val) && (!TypeGuard.isString(val) || val.length === 0)) {
                    addHint("This field is required.");
                    return false;
                }
                break;
            case "check_true":
                if (val as unknown != true) {
                    addHint("You must check this box.");
                    return false;
                }
                break;
            case "variable_name":
                if (val.length > 0 && ! /[a-zA-Z]+([a-zA-Z0-9]*)/.test(val)) {
                    addHint("Variable name must begin with a letter and contain only letters and numbers.")
                    return false;
                }
                break;
            case "physical_unit":
                if (val.length > 0 && !MjsMath.isParseableUnit(val)) {
                    addHint(`Your unit [${val}] is invalid.`);
                    return false;
                }
                break;
            case "has_lowercase":
                if (val == val.toUpperCase() && val.length > 0) {
                    addHint("Must contain at least one lower case letter.")
                    return false;
                }
                break;

            case "has_uppercase":
                if (val == val.toLowerCase() && val.length > 0) {
                    addHint("Must contain at least one upper case letter.")
                    return false;
                }
                break;
            case "has_symbol":
                if (/^[A-Za-z0-9 ]+$/.test(val) && val.length > 0) {
                    addHint("Must contain at least one symbol.")
                    return false;
                }
                break;
            case "has_number":
                if (!/\d/.test(val) && val.length > 0) {
                    addHint("Must contain at least one number.")
                    return false;
                }
                break;
            case "custom": {
                const result = (ruleValue as FormCustomValidationRule)(val, meta);
                if (!result.isValid) {
                    if (TypeGuard.isString(result.errorMessage)) {
                        addHint(result.errorMessage);
                    } else {
                        result.errorMessage.forEach((str) => {
                            addHint(str);
                        });
                    }

                    return false;
                }
                break;
            }
            default:
                break;
        }

        return true;

        function addHint(hint: string) {
            schemaItem._validationHints?.push(hint);
        }
    }
}


export const testSchemaValidity = (schemaRoot: FormSchemaItem) => {
    console.debug("Checking Child Schema Validity")
    return checkChildren(schemaRoot);

    function checkChildren(schemaItem: FormSchemaItem,
        overrideValueData: number | string | undefined = undefined,
        meta: ValidationMetaData | undefined = undefined) {
        const testData = overrideValueData ? overrideValueData : schemaItem.value;


        switch (schemaItem.fieldType) {
            case FormTypes.ARRAY_OBJECTS:
            case FormTypes.ARRAY_OBJECTS_EDITOR:
            case FormTypes.ARRAY_OF_EMAIL:
            case FormTypes.ARRAY_OF_TEXT:
                schemaItem._isValid = FormValidationStates.valid;
                break;
            default:
                validateValue(testData as string, schemaItem, meta);
        }

        let isValid = true && schemaItem._isValid == FormValidationStates.valid;

        switch (schemaItem.fieldType) {
            case FormTypes.ARRAY_OF_EMAIL:
            case FormTypes.ARRAY_OF_TEXT: {
                schemaItem._rowValidation = [];

                const dataArray = schemaItem.value as string[];
                dataArray.forEach((dataRow, rowIndex) => {
                    const isRowValid = validateTheRow(dataRow, schemaItem, rowIndex);
                    isValid = (isValid && isRowValid);
                });

                break;
            }
            case FormTypes.ARRAY_OBJECTS:
            case FormTypes.ARRAY_OBJECTS_EDITOR: {
                schemaItem._rowValidation = [];
                schemaItem._itemValidation = [];

                const data = schemaItem.value as Record<string, unknown>[];
                data.forEach((dataItem, rowIndex) => {
                    isValid = validateTheRow("", schemaItem, rowIndex);
                    const arr = (schemaItem._itemValidation as Record<string, ValidatedState>[]);
                    arr.push({});

                    schemaItem.children?.forEach((item, fieldIndex) => {
                        const fieldData = dataItem[item.field] as string | number;
                        const childIsValid = checkChildren(item, fieldData, { fieldIndex, rowIndex });
                        arr[rowIndex][item.field] = {
                            state: item._isValid || FormValidationStates.dirty,
                            hints: item._validationHints || [],
                            class: getValidationClass(item._isValid)
                        };

                        isValid = (isValid && childIsValid);
                    });
                });

                break;
            }
            default:
                schemaItem.children?.forEach((item) => {
                    const childIsValid = checkChildren(item);
                    isValid = (isValid && childIsValid);
                });
        }


        if (!isValid) {
            console.debug("Form is Invalid", schemaItem)
            console.debug("Form data was: ", schemaItem.value)
        }

        return isValid;

        function validateTheRow(dataItem: string, schItem: FormSchemaItem, rowIndex: number) {
            schItem._rowValidation?.push(validateValue(dataItem, schItem, { fieldIndex: 0, rowIndex }));
            const isRowValid = true && schItem._isValid == FormValidationStates.valid;
            return isRowValid;
        }
    }
}

export const setSchemaToDirty = (schemaRoot: FormSchemaItem) => {
    setChildren(schemaRoot);

    function setChildren(schemaItem: FormSchemaItem) {
        schemaRoot._isValid = FormValidationStates.dirty;

        schemaItem.children?.forEach((item) => {
            item._isValid = FormValidationStates.dirty;
        });
    }
}



export const useValidation = (props: formItemMixinInterface,
    context: SetupContext<EmitsOptions>,
    hooks: ValidationHooks = {
        onAfterValidate: () => { return; },
        onBeforeValidate: () => { return; }
    }): useValidationReturnType => {

    const currentValue = ref(props.modelValue);

    const validationState = computed(() => {
        return getValidationClass(props.schemaitem._isValid);
    });

    watch(() => props.triggers.validate, () => {
        console.debug('Validation has been triggered.')
        validateValue(props.schemaitem.value as string, props.schemaitem).state;
    });

    watch(currentValue, (newValue: string | unknown) => {
        context.emit('update:modelValue', newValue);
        validateValue(newValue as string, props.schemaitem);
    });

    const isSchemaValid = (schemaRoot: FormSchemaItem) => {
        hooks.onBeforeValidate();
        const result = testSchemaValidity(schemaRoot);
        hooks.onAfterValidate();
        return result;
    }

    const getArrayValidationForRow = (schemaItem: FormSchemaItem, index: number): ValidatedState => {
        const result = schemaItem?._rowValidation?.[index];
        return (result) ? result : { class: "not-tested", hints: [], state: 0 }
    }

    const getArrayValidationForItem = (schemaItem: FormSchemaItem, rowIndex: number, fieldName: string): ValidatedState => {
        const row = schemaItem?._itemValidation?.[rowIndex];
        if (TypeGuard.isNullOrUndefined(row)) {
            return { class: "not-tested", hints: [], state: 0 };
        }
        const result = row[fieldName];
        return (result) ? result : { class: "not-tested", hints: [], state: 0 }
    }


    return {
        currentValue,
        validationState,
        getValidationClass,
        getArrayValidationForRow,
        getArrayValidationForItem,
        isSchemaValid
    }
}
