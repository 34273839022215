import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useLogin from "@/components/use/useLogin";
import useSchema from "@/components/use/useSchema";
import { LoginActionTypes, LoginPageParameterType } from "./login.interface";

export const requestPasswordResetButton = {
    class: "btn-success m-2 save float-end",
    label: "Reset",
    triggerEvent: {
        originatingAction: "Request Pass Reset Button",
        type: LoginActionTypes.REQUEST_RESET_PASSWORD_ACTION,
        payload: null
    },
    isEnabled: true,
    enabledWhenFormIsValid: true,
};

export const requestPasswordResetSchema =
    FormSchemaMaker.Group(
        {
            label: "Reset your password",
            field: "data",
            properties: {
                classes: "m-1",
                hideLabel: false
            },
        },
        [
            FormSchemaMaker.Email({
                label: "Email Address",
                field: "emailValue",
                properties: { labelAlign: "top" },
                rules: { required: true, email: true },
                value: "",
                placeHolder: "Enter your email address",
                autocomplete: "username"
            }),
        ]
    );

export const requestPasswordResetPage = (options: LoginPageParameterType): void => {

    options.currentSchema.value = requestPasswordResetSchema;
    options.buttonFooter.value = {
        infoText: "Back to ",
        linkText: "Sign In",
        eventType: {
            originatingAction: "Request Pass Reset Link",
            type: LoginActionTypes.GOTO_SIGN_IN_PAGE
        },
        footerInfoText: "",
        footerLinkText: "",
        footerEventType: {
            originatingAction: "Request Pass Reset Footer",
            type: LoginActionTypes.EMPTY
        },
    };
    options.buttonFooter2.value = null;
    options.buttonList[0] = requestPasswordResetButton;
    options.enterKeyAction.value.triggerEvent.type = LoginActionTypes.REQUEST_RESET_PASSWORD_ACTION;
}

export const requestPasswordResetAction = async (options: LoginPageParameterType): Promise<void> => {
    const { requestReset } = useLogin();
    const { schema_getJSON } = useSchema();

    let data: Record<string, unknown>;
    if (options.originationEvent?.payload?.schemaItem) {
        data = schema_getJSON(options.originationEvent.payload.schemaItem).data;
        const result = await requestReset(
            data.emailValue as string
        );

        if (result.hasError()) {
            options.errorMessage.value = result.getFlatErrorMessage();
        } else {
            options.onPress({
                originatingAction: "Requeset Password Reset Action",
                type: LoginActionTypes.GOTO_RESET_PASSWORD_CONFIRMATION_PAGE,
                payload: {
                    username: data.emailValue as string
                }
            });
        }
    }
}