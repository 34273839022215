
  import { defineComponent } from "vue";

  export default defineComponent({
    setup() {
      const listOfExperiments = [
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity of various fluids.",
        },
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity as a function of temperature for a fluid.",
        },
        {
          title: "Viscosity",
          img: "",
          text: "Measure the viscosity as a function of shear rate for a fluid.",
        },
        {
          title: "Losses in Pipes",
          img: "",
          text: "Determine the major losses in a 1 inch and 1/2 inch pipe.",
        },
        {
          title: "Losses in Pipes",
          img: "",
          text:
            "Determine the minor losses in a 45 degree elbow, and a 90 degree elbow.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the drag coefficient of a sphere as a function of reynolds number.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the drag coefficient of a golf ball as a function of reynolds number and compare to the smooth sphere results.",
        },
        {
          title: "Drag Coefficients",
          img: "",
          text:
            "Determine the lift and drag coefficients for an airfoil as a function of angle of attack and the Reynolds number.",
        },
        {
          title: "Linear Momentum",
          img: "",
          text:
            "Determine the relationship between force and velocity in a water jet.",
        },
        {
          title: "Linear Momentum",
          img: "",
          text:
            "Determine the linear momentum in a water jet that impacts a hemispherical deflector vs a flat wall.",
        },
      ];
      return {
        listOfExperiments,
      };
    },
  });
