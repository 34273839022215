import { sort } from 'fast-sort';
import { CourseTableBaseType, CourseTableSearchTypes } from './table-course.interface';
import { DynamoSearchKernal, DynamoSearchResults } from '../dynamoSearchResult';
import { CourseRecord } from './record-course';
import { byCourseFieldType, listCourses } from '@/graphql/queries';
import { NotebookRecord } from './record-notebook';
import { RegistrationRecord } from './record-registration';
import { AssignmentRecord } from './record-assignment';
import { byCourseFieldType2 } from '@/graphql/custom-queries';

const courseSearchKernal = (courseID: string, userEmail: string) => {
    return {
        distributeDBItems: function (fetchedData: any, type: CourseTableSearchTypes, obj: CourseTableSearchResult) {
            let items: CourseTableBaseType[];

            switch (type) {
                case CourseTableSearchTypes.NOTEBOOKS_BY_COURSE:
                case CourseTableSearchTypes.REGISTRATIONS_BY_COURSE:
                case CourseTableSearchTypes.COURSE_BY_ID:
                    items = fetchedData.data.listCourses.items;
                    break;
                case CourseTableSearchTypes.COURSE_LIST:
                case CourseTableSearchTypes.NOTEBOOKS_BY_USER:
                case CourseTableSearchTypes.REGISTRATIONS_BY_USER:
                    items = fetchedData.data.byCourseFieldType.items;
                    break;
            }

            items.forEach((v) => {
                switch (v.fieldType) {
                    case "NOTEBOOK":
                        obj.notebooks.push(new NotebookRecord().wrapDBRecord(v));
                        break;
                    case "ASSIGNMENT":
                        obj.assignments.push(new AssignmentRecord().wrapDBRecord(v));
                        break;
                    case "COURSE":
                        obj.courses.push(new CourseRecord().wrapDBRecord(v));
                        break;
                    case "REGISTRATION":
                        obj.registrations.push(new RegistrationRecord().wrapDBRecord(v));
                        break;
                }
            });

            // sort
            obj.courses = sort(obj.courses).desc(u => u.data().createdAt);
        },

        extraQueryData: {
            courseID: courseID,
            userEmail: userEmail
        },

        getQueryData: function (type: CourseTableSearchTypes, extraQueryData: any) {
            switch (type) {
                case CourseTableSearchTypes.COURSE_BY_ID:
                    return {
                        query: listCourses,
                        queryData: { courseID: extraQueryData.courseID }
                    };
                case CourseTableSearchTypes.NOTEBOOKS_BY_COURSE:
                    return {
                        query: listCourses,
                        queryData: {
                            courseID: extraQueryData.courseID,
                            fieldTypeUserEmailId: {
                                beginsWith: {
                                    fieldType: "NOTEBOOK",
                                    userEmail: extraQueryData.userEmail
                                }
                            }
                        }
                    };
                case CourseTableSearchTypes.NOTEBOOKS_BY_USER:
                    return {
                        query: byCourseFieldType,
                        queryData: {
                            fieldType: "NOTEBOOK",
                            userEmail: {
                                eq: extraQueryData.userEmail
                            }
                        }
                    };
                case CourseTableSearchTypes.REGISTRATIONS_BY_COURSE:
                    return {
                        query: listCourses,
                        queryData: {
                            courseID: extraQueryData.courseID,
                            fieldTypeUserEmailId: {
                                beginsWith: {
                                    fieldType: "REGISTRATION"
                                }
                            }
                        }
                    };
                case CourseTableSearchTypes.REGISTRATIONS_BY_USER:
                    return {
                        query: byCourseFieldType,
                        queryData: {
                            fieldType: "REGISTRATION",
                            userEmail: {
                                eq: extraQueryData.userEmail
                            }
                        }
                    };
                case CourseTableSearchTypes.COURSE_LIST:
                    return {
                        query: byCourseFieldType2,
                        queryData: {
                            fieldType: "COURSE"
                        }
                    };
            }
        }
    }
}

export class CourseTableSearchResult extends DynamoSearchResults<CourseTableSearchTypes> {
    public courses: CourseRecord[] = [];
    public assignments: AssignmentRecord[] = [];
    public registrations: RegistrationRecord[] = [];
    public notebooks: NotebookRecord[] = [];

    public clear() {
        this.courses = [];
        this.assignments = [];
        this.registrations = [];
        this.notebooks = [];
        return true;
    }

    public hasCourse(id: string) { return this.courses.some(course => course.id() === id); }

    public getCourseByID(id: string) {
        const index = this.courses.findIndex(course => course.id() === id);
        return this.courses[index];
    }

    public getAllCourses() { return this.courses; }
    public getAllRegistrations() { return this.registrations; }
    public getAllAssignments() { return this.assignments; }
    public getAllNotebooks() { return this.notebooks; }

    constructor(courseID: string, userEmail: string) {
        super(courseSearchKernal(courseID, userEmail) as unknown as DynamoSearchKernal<CourseTableSearchTypes>);
        return this;
    }
}