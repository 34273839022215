import { MockQueryItems } from "@/store/database/dynamo/query.interface";
import { MockSubmissionItem, MockSubmissionQuestionPart, SubmissionItem } from "@/store/database/Submission/submitted-item.interface";
import { UserResponseSetupData } from "./user-response.interface";
import { DeleteSubmissionParams, PullAssignmentSubmissionRequest, PullQuestionSubmissionRequest, UpdateSubmissionAttemptData, UpdateSubmissionRequest } from "./useSubmissions";

function getMockResponseData(responseRequests: UserResponseSetupData[]) {
   const newArray = new Array(responseRequests.length).fill(undefined);
   const mockData = newArray.map(() => {
      return MockSubmissionQuestionPart()
   })
   console.log(mockData);
   return mockData;
}


export function useMockSubmissions(responseRequests: UserResponseSetupData[]) {
   const pullAssignmentSubmission =
      async (params: PullAssignmentSubmissionRequest) => {
         return MockQueryItems<SubmissionItem>([MockSubmissionItem({
            data: getMockResponseData(responseRequests)
         })]);
      };

   const pullQuestionSubmission =
      async (params: PullQuestionSubmissionRequest) => {
         return MockSubmissionItem({
            data: getMockResponseData(responseRequests)
         });
      };

   const registerSubmissionAttempt =
      async (params: UpdateSubmissionAttemptData) => { return { Attributes: MockSubmissionItem() } };

   const updateFinalSubmission =
      async (params: UpdateSubmissionRequest) => { return { Attributes: MockSubmissionItem() } };

   const deleteSubmission = async (params: DeleteSubmissionParams) => {
      return { Attributes: [] }
   }



   return {
      deleteSubmission,
      pullQuestionSubmission,
      pullAssignmentSubmission,
      updateFinalSubmission,
      registerSubmissionAttempt
   }
}