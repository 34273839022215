import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useTime from "@/components/use/useTime";

export function getAssignmentSchema(courseId: string, textId: string) {
   const { getDateInFuture } = useTime();

   return FormSchemaMaker.Group({
      label: "Assignment Page Item",
      field: "data",
      properties: {
         hideLabel: true,
         classes: "m-1 p-1 border formColorGroup"
      }
   },
      [
         FormSchemaMaker.Text({ label: "Assignment Name", field: "name", rules: { min: 5, max: 50 }, hint: "Assign a name to your assignment. This name will appear in a table on the student's homepage." }),
         FormSchemaMaker.TextBox({ label: "Short Description", field: "description", hint: "Add a description to characterize this assignment." }),
         FormSchemaMaker.FileLocation({ label: "Thumbnail", field: "lab_pictureURL", hint: "Add the URL for a thumbnail photo." }),
         FormSchemaMaker.Group({ label: "Grading", field: "asn_options", properties: { classes: "m-0 border", hideLabel: true } },
            [
               FormSchemaMaker.Select({
                  label: "Catagory", field: "catagory",
                  choices: ["Homework", "Quiz", "Test", "Exam", "Lab", "Presentation", "Report", "Link"],
                  properties: { labelCols: 8, componentCols: 6, componentOffset: 0 },
                  hint: "Select the type of assignment. Homework can be evaluated problem by problem whereas an Exam, Test, and Quiz must be submitted as a whole before being graded.",
                  value: "Homework"
               }),
               FormSchemaMaker.Number({
                  label: "Points",
                  field: "maxPoints",
                  rules: { min_value: 0 },
                  properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
                  hint: "Enter maximum achievable grade for this assignment",
                  value: 100
               }),
               FormSchemaMaker.Integer({
                  label: "Max Attempts",
                  field: "maxAttempts",
                  rules: { min_value: 1 },
                  properties: {
                     labelCols: 4,
                     componentCols: 6,
                     componentOffset: 0
                  },
                  hint: "Maximum number of times the student may attempt each question (for homework only).",
                  value: 1
               }),

               FormSchemaMaker.CheckBox({
                  label: "Timed Assignment",
                  field: "isTimed",
                  rules: {},
                  properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
                  hint: "Click here to set a time limit on this assignment. The clock begins when the student first clicks on the assignment.",
                  value: false
               }),

               FormSchemaMaker.DateTime({
                  label: "Open On", field: "openOn",
                  hint: "The student may begin the assignment on this date.",
                  value: new Date().toISOString(),
                  properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
               }),
               // FormSchemaMaker.Integer({
               //    label: "Time Limit (in min)",
               //    field: "timeLimit",
               //    properties: { labelCols: 6, componentCols: 6, componentOffset: 0 },
               //    rules: { min_value: 1 },
               //    hint: "Amount of time in minutes given to the student to complete the assignment. ",
               //    value: 60
               // }),

               FormSchemaMaker.DateTime({
                  label: "Due On", field: "dueOn",
                  hint: "The assignment will be automatically collected and shut down on this date.",
                  value: getDateInFuture(7).toISOString(),
                  properties: { labelCols: 4, componentCols: 6, componentOffset: 0 },
               }),
            ])
      ]);
}