/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAssignmentSubmission = /* GraphQL */ `
  query GetAssignmentSubmission(
    $type: String!
    $courseID: String!
    $componentID: String
    $questionID: String
    $questionVersion: String
    $nrProblemParts: Int
    $userId: String
  ) {
    getAssignmentSubmission(
      type: $type
      courseID: $courseID
      componentID: $componentID
      questionID: $questionID
      questionVersion: $questionVersion
      nrProblemParts: $nrProblemParts
      userId: $userId
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const getOtherPersonsAssignmentSubmission = /* GraphQL */ `
  query GetOtherPersonsAssignmentSubmission(
    $type: String!
    $courseID: String!
    $componentID: String
    $questionID: String
    $questionVersion: String
    $nrProblemParts: Int
    $userId: String
  ) {
    getOtherPersonsAssignmentSubmission(
      type: $type
      courseID: $courseID
      componentID: $componentID
      questionID: $questionID
      questionVersion: $questionVersion
      nrProblemParts: $nrProblemParts
      userId: $userId
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const getBatchOfAssignmentSubmissions = /* GraphQL */ `
  query GetBatchOfAssignmentSubmissions(
    $courseID: String!
    $assignments: [String]
  ) {
    getBatchOfAssignmentSubmissions(
      courseID: $courseID
      assignments: $assignments
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const getAllCourseAssignmentSubmissions = /* GraphQL */ `
  query GetAllCourseAssignmentSubmissions($courseID: String!) {
    getAllCourseAssignmentSubmissions(courseID: $courseID) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($questionBankID: String, $questionID: String) {
    getQuestion(questionBankID: $questionBankID, questionID: $questionID) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const getBatchOfQuestions = /* GraphQL */ `
  query GetBatchOfQuestions(
    $questionBankIDs: [String]
    $questionIDs: [String]
  ) {
    getBatchOfQuestions(
      questionBankIDs: $questionBankIDs
      questionIDs: $questionIDs
    ) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const getBatchOfUserProfiles = /* GraphQL */ `
  query GetBatchOfUserProfiles($userEmails: [String], $userIDs: [String]) {
    getBatchOfUserProfiles(userEmails: $userEmails, userIDs: $userIDs) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const getCodeLogTable = /* GraphQL */ `
  query GetCodeLogTable($id: ID!) {
    getCodeLogTable(id: $id) {
      id
      fieldType
      createdOn
      issue
      resolution
      status
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listCodeLogTables = /* GraphQL */ `
  query ListCodeLogTables(
    $id: ID
    $filter: ModelCodeLogTableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodeLogTables(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        createdOn
        issue
        resolution
        status
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPermissions = /* GraphQL */ `
  query GetUserPermissions($userEmail: ID!, $fieldType: String!, $id: ID!) {
    getUserPermissions(userEmail: $userEmail, fieldType: $fieldType, id: $id) {
      id
      fieldType
      userEmail
      item_type
      editable_item
      permission
      createdBy
      courseID
      role
      profile
      lastLogin
      nrVisits
      createdAt
      updatedAt
    }
  }
`;
export const listUserPermissionss = /* GraphQL */ `
  query ListUserPermissionss(
    $userEmail: ID
    $fieldTypeId: ModelUserPermissionsPrimaryCompositeKeyConditionInput
    $filter: ModelUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserPermissionss(
      userEmail: $userEmail
      fieldTypeId: $fieldTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        userEmail
        item_type
        editable_item
        permission
        createdBy
        courseID
        role
        profile
        lastLogin
        nrVisits
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupFormation = /* GraphQL */ `
  query GetGroupFormation($userEmail: ID!, $fieldType: String!, $id: ID!) {
    getGroupFormation(userEmail: $userEmail, fieldType: $fieldType, id: $id) {
      id
      fieldType
      userEmail
      courseID
      groupID
      role
      assignmentID
      createdAt
      updatedAt
    }
  }
`;
export const listGroupFormations = /* GraphQL */ `
  query ListGroupFormations(
    $userEmail: ID
    $fieldTypeId: ModelGroupFormationPrimaryCompositeKeyConditionInput
    $filter: ModelGroupFormationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroupFormations(
      userEmail: $userEmail
      fieldTypeId: $fieldTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        userEmail
        courseID
        groupID
        role
        assignmentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse(
    $courseID: ID!
    $fieldType: FieldType!
    $userEmail: String!
    $id: ID!
  ) {
    getCourse(
      courseID: $courseID
      fieldType: $fieldType
      userEmail: $userEmail
      id: $id
    ) {
      id
      courseID
      fieldType
      name
      description
      long_description
      availibility {
        restrictionType
        inputCode
        userEmailMatch
        userEmailList
        cost
        stripeProductID
        stripePriceID
        restrictToDates
        showOn
        openOn
        closeOn
        hideOn
      }
      isActive
      userEmail
      co_editors
      co_pictureURL
      co_includeText
      co_textbookID
      co_includeAssignments
      co_assignmentBook
      co_includeLab
      co_laboratoryID
      co_questionBankID
      co_includeNotebook
      co_topics {
        name
        id
      }
      assign_data
      reg_initDate
      reg_expiration
      reg_receipt {
        registeredOn
        wasFree
        wasCharged
        hasPaid
        cost
        discountApplied
        amountPaid
        transactionID
      }
      reg_role
      reg_userID
      note_bookID
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $courseID: ID
    $fieldTypeUserEmailId: ModelCoursePrimaryCompositeKeyConditionInput
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      courseID: $courseID
      fieldTypeUserEmailId: $fieldTypeUserEmailId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        courseID
        fieldType
        name
        description
        long_description
        isActive
        userEmail
        co_editors
        co_pictureURL
        co_includeText
        co_textbookID
        co_includeAssignments
        co_assignmentBook
        co_includeLab
        co_laboratoryID
        co_questionBankID
        co_includeNotebook
        assign_data
        reg_initDate
        reg_expiration
        reg_role
        reg_userID
        note_bookID
        ttlExpireTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTextbook = /* GraphQL */ `
  query GetTextbook($textID: ID!, $fieldType: TextType!, $id: ID!) {
    getTextbook(textID: $textID, fieldType: $fieldType, id: $id) {
      id
      textID
      name
      description
      long_description
      fieldType
      txt_pictureURL
      lab_pictureURL
      toc_pageIndex
      page_isActive
      page_activeContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_draftContent {
        createdOn
        updatedOn
        creator
        content
      }
      page_history {
        createdOn
        updatedOn
        creator
        content
      }
      asn_options {
        isGroup
        formOwnGroups
        maxGroupMembers
        requiresGroupNotebook
        openOn
        dueOn
        isTimed
        timeLimit
        maxPoints
        maxAttempts
        catagory
        showSolutions
        showScoreBreakdown
        useHandwritingEntry
        randomizeQuestions
        randomizeAnswers
      }
      owner
      ttlExpireTime
      version
      createdAt
      updatedAt
    }
  }
`;
export const listTextbooks = /* GraphQL */ `
  query ListTextbooks(
    $textID: ID
    $fieldTypeId: ModelTextbookPrimaryCompositeKeyConditionInput
    $filter: ModelTextbookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTextbooks(
      textID: $textID
      fieldTypeId: $fieldTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        textID
        name
        description
        long_description
        fieldType
        txt_pictureURL
        lab_pictureURL
        toc_pageIndex
        page_isActive
        owner
        ttlExpireTime
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubmissions = /* GraphQL */ `
  query GetSubmissions($courseID: ID!, $searchID: ID!, $userEmail: String!) {
    getSubmissions(
      courseID: $courseID
      searchID: $searchID
      userEmail: $userEmail
    ) {
      searchID
      courseID
      userEmail
      fieldType
      data
      seed
      gradeOverride
      earnedPercent
      accomodationType
      accomodationValue
      owner
      ttlExpireTime
      createdAt
      updatedAt
    }
  }
`;
export const listSubmissionss = /* GraphQL */ `
  query ListSubmissionss(
    $courseID: ID
    $searchIDUserEmail: ModelSubmissionsPrimaryCompositeKeyConditionInput
    $filter: ModelSubmissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubmissionss(
      courseID: $courseID
      searchIDUserEmail: $searchIDUserEmail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        searchID
        courseID
        userEmail
        fieldType
        data
        seed
        gradeOverride
        earnedPercent
        accomodationType
        accomodationValue
        owner
        ttlExpireTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarketingEmailLists = /* GraphQL */ `
  query GetMarketingEmailLists($userEmail: ID!, $fieldType: String!, $id: ID!) {
    getMarketingEmailLists(
      userEmail: $userEmail
      fieldType: $fieldType
      id: $id
    ) {
      id
      fieldType
      userEmail
      contactDates
      optedOut
      optedOutDate
      optedOutIPaddress
      meta
      createdAt
      updatedAt
    }
  }
`;
export const listMarketingEmailListss = /* GraphQL */ `
  query ListMarketingEmailListss(
    $userEmail: ID
    $fieldTypeId: ModelMarketingEmailListsPrimaryCompositeKeyConditionInput
    $filter: ModelMarketingEmailListsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMarketingEmailListss(
      userEmail: $userEmail
      fieldTypeId: $fieldTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        userEmail
        contactDates
        optedOut
        optedOutDate
        optedOutIPaddress
        meta
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSharePermissions = /* GraphQL */ `
  query GetSharePermissions($id: ID!, $fieldType: String!) {
    getSharePermissions(id: $id, fieldType: $fieldType) {
      id
      fieldType
      userEmail
      courseID
      textID
      pageID
      componentID
      accessCode
      startAccess
      endAccess
      conditionalAccessType
      conditionalAccessData
      isActive
      meta
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSharePermissionss = /* GraphQL */ `
  query ListSharePermissionss(
    $id: ID
    $fieldType: ModelStringKeyConditionInput
    $filter: ModelSharePermissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSharePermissionss(
      id: $id
      fieldType: $fieldType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        userEmail
        courseID
        textID
        pageID
        componentID
        accessCode
        startAccess
        endAccess
        conditionalAccessType
        conditionalAccessData
        isActive
        meta
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTextRecognition = /* GraphQL */ `
  query GetTextRecognition($fieldType: String!, $character: String!, $id: ID!) {
    getTextRecognition(fieldType: $fieldType, character: $character, id: $id) {
      id
      fieldType
      character
      userEmail
      included
      orignalPaths
      dateIndex
      meta
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listTextRecognitions = /* GraphQL */ `
  query ListTextRecognitions(
    $fieldType: String
    $characterId: ModelTextRecognitionPrimaryCompositeKeyConditionInput
    $filter: ModelTextRecognitionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTextRecognitions(
      fieldType: $fieldType
      characterId: $characterId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fieldType
        character
        userEmail
        included
        orignalPaths
        dateIndex
        meta
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byFieldTypeAndDate = /* GraphQL */ `
  query ByFieldTypeAndDate(
    $fieldType: String
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCodeLogTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byFieldTypeAndDate(
      fieldType: $fieldType
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        createdOn
        issue
        resolution
        status
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusAndDate = /* GraphQL */ `
  query ByStatusAndDate(
    $status: String
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCodeLogTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusAndDate(
      status: $status
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        createdOn
        issue
        resolution
        status
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byEditableItem = /* GraphQL */ `
  query ByEditableItem(
    $editable_item: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEditableItem(
      editable_item: $editable_item
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        userEmail
        item_type
        editable_item
        permission
        createdBy
        courseID
        role
        profile
        lastLogin
        nrVisits
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byGroupId = /* GraphQL */ `
  query ByGroupId(
    $groupID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFormationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGroupID(
      groupID: $groupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        userEmail
        courseID
        groupID
        role
        assignmentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAssignmentId = /* GraphQL */ `
  query ByAssignmentId(
    $courseID: ID
    $assignmentID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFormationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAssignmentID(
      courseID: $courseID
      assignmentID: $assignmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        userEmail
        courseID
        groupID
        role
        assignmentID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCourseFieldType = /* GraphQL */ `
  query ByCourseFieldType(
    $fieldType: FieldType
    $userEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCourseFieldType(
      fieldType: $fieldType
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseID
        fieldType
        name
        description
        long_description
        isActive
        userEmail
        co_editors
        co_pictureURL
        co_includeText
        co_textbookID
        co_includeAssignments
        co_assignmentBook
        co_includeLab
        co_laboratoryID
        co_questionBankID
        co_includeNotebook
        assign_data
        reg_initDate
        reg_expiration
        reg_role
        reg_userID
        note_bookID
        ttlExpireTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTextbookFieldType = /* GraphQL */ `
  query ByTextbookFieldType(
    $fieldType: TextType
    $txt_pictureURL: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextbookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTextbookFieldType(
      fieldType: $fieldType
      txt_pictureURL: $txt_pictureURL
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        textID
        name
        description
        long_description
        fieldType
        txt_pictureURL
        lab_pictureURL
        toc_pageIndex
        page_isActive
        owner
        ttlExpireTime
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const textRecognitionByUser = /* GraphQL */ `
  query TextRecognitionByUser(
    $userEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelTextRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textRecognitionByUser(
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        character
        userEmail
        included
        orignalPaths
        dateIndex
        meta
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const textRecognitionByDate = /* GraphQL */ `
  query TextRecognitionByDate(
    $fieldType: String
    $dateIndex: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textRecognitionByDate(
      fieldType: $fieldType
      dateIndex: $dateIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fieldType
        character
        userEmail
        included
        orignalPaths
        dateIndex
        meta
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
