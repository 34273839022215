<template>
  <div :class="'container-fluid form-group-header ' + schemaitem.properties.classes">
    <label v-if="!schemaitem.properties.hideLabel">{{ schemaitem.label }}</label>
    <div v-if="(hasSelectableTypeInfo())"
         class="row g-0">
      <div class="col">
        <label> {{ getSelectedTypeLabel() }} </label>
      </div>
      <div class="col">
        <BSelect :key="selectedType"
                 v-model:modelValue="selectedType"
                 :options="getSelectableObjectTypes()"
                 @update:modelValue="changeTypeSelector($event)" />
      </div>
    </div>
    <div class="row g-0">
      <template v-for="(field, index) in visibleItems"
                :key="field.label + index"
                :class="getComponentColumnClass(field)">
        <component :is="field.formGen.componentName"
                   v-model:modelValue="visibleItems[index].value"
                   :fid="myID + '_' + field.label + '_' + index"
                   :schemaitem="visibleItems[index]"
                   :displaybehavior="displaybehavior"
                   :triggers="triggers"
                   @update:modelValue="onChange($event)" />
      </template>
    </div>
  </div>
</template> 
<script lang='ts'>
  import TextInput from "./form-input-text.vue";
  import TextBox from "./form-input-textbox.vue";
  import SelectList from "./form-input-selectlist.vue";
  import CheckBox from "./form-input-checkbox.vue";
  import DateTime from "./form-input-datetime.vue";
  import MarkdownIt from "./form-input-markdown.vue";
  import ArrayInput from "./form-input-array.vue";
  import ObjectEditorInput from "./form-input-objecteditor.vue";
  import SelectFromCatalog from "./form-select-catalog.vue";
  import FormInputFile from "./form-input-file.vue";
  import DividerInput from "./form-input-divider.vue";
  import ArrayOfObjects from "./form-input-arrayOfObjects.vue";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { v4 as uuid4 } from "uuid";
  import { defineComponent, EmitsOptions, Ref, ref, SetupContext, watch } from "vue";
  import useFormTypeSelector from "../use/useFormTypeSelector";
  import BSelect from "@/components/bootstrap/b-select.vue";
  import { FormSchemaItem } from "../form-schema.interface";

  export default defineComponent({
    name: "FormInputGroup",
    components: {
      TextInput,
      TextBox,
      SelectList,
      CheckBox,
      DateTime,
      MarkdownIt,
      ArrayInput,
      ObjectEditorInput,
      SelectFromCatalog,
      FormInputFile,
      DividerInput,
      ArrayOfObjects,
      BSelect,
    },
    props: { ...formItemMixinProps },
    emits: ["change", "update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {

      console.log("model", props.modelValue);
      const {
        selectedType,
        setSelectedTypeIndicator,
        hasSelectableTypeInfo,
        getVisibleSchemaItems,
        getSelectableObjectTypes,
        getSelectedTypeLabel,
      } = useFormTypeSelector(props.modelValue, props.schemaitem);

      const visibleItems: Ref<FormSchemaItem[]> = ref(getVisibleSchemaItems());

      watch(selectedType, () => {
        visibleItems.value = getVisibleSchemaItems();
        console.log({ selectedType })
      });

      const onChange = (data: unknown) => {
        context.emit("change");
      }

      const changeTypeSelector = (data: unknown) => {
        setSelectedTypeIndicator(data);
        context.emit("update:modelValue", data);
      }


      return {
        changeTypeSelector,
        selectedType,
        setSelectedTypeIndicator,
        onChange,
        hasSelectableTypeInfo,
        getVisibleSchemaItems,
        getSelectableObjectTypes,
        getSelectedTypeLabel,
        visibleItems
      };
    },
    data() {
      const myID = (this as any).getUniqueID();

      return {
        myID,
      };
    },
    methods: {
      getComponentColumnClass(field: any) {
        return "col-" + field.properties.componentCols;
      },
      getUniqueID() {
        return uuid4();
      },
    },
  });
</script>
<style lang="">
</style>