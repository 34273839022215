
  import PageModule from "@/components/contentGenerator/pages/PageModule.vue";
  import useCourseMaterials from "@/store/connectors/useCourseMaterials";
  import { PageRecord } from "@/store/database/Textbook/record-page";
  import { defineComponent, provide, ref, Ref } from "vue";

  export default defineComponent({
    name: "AssignmentViewer",
    components: { PageModule },
    props: {
      courseId: {
        type: String,
        required: true,
      },
      textId: {
        type: String,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      rootPageId: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      provide("courseId", props.courseId);
      provide("textId", props.textId);


      const { getPageFromDB } = useCourseMaterials();


      let pageData: Ref<undefined | PageRecord> = ref(undefined);


      const pageFromTextbookTable = await getPageFromDB(props.textId, "ASSIGNMENT", props.pageId) as unknown as Record<string, unknown>;
      console.log({ pageFromTextbookTable })
      pageData.value = new PageRecord().wrapDBRecord(pageFromTextbookTable);


      //pageData.value = await findPage(props.courseId, props.textId, props.pageId);

      return {
        pageData
      };
    },
  });
