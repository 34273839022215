
  import draggable from "vuedraggable";
  import { defineComponent, PropType, ref } from "vue";
  import { ChildBearing } from "@/utils/arrays";

  export default defineComponent({
    name: "BSortableList",
    components: {
      draggable,
    },
    props: {
      activeId: {
        type: String,
        required: true
      },
      modelValue: {
        type: Array as PropType<ChildBearing[]>,
        required: true,
      },
      prefix: {
        required: false,
        type: String,
        default: "",
      },
      nestable: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ["click", "change", "update:modelValue"],
    setup(props, context) {
      const reactiveList = ref(props.modelValue)

      const dragging = ref(false);
      const dragOptions = ref({
        animation: 0,
        group: "description",
        disabled: false,
      });

      const onClick = (id: string): void => {
        context.emit("click", id);
      }

      const onChange = (data: unknown): void => {
        context.emit("update:modelValue", reactiveList.value)
        context.emit("change", data);
      }

      return {
        onClick,
        onChange,
        reactiveList,
        dragging,
        dragOptions,
      };
    },
  });
