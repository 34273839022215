import * as BABYLON from 'babylonjs';
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { BaseRenderedObject, RenderedObject, RenderedObjectTypes } from '../BaseRenderedObject';

interface LabBenchOptions {
    bottomCenterLocation?: BABYLON.Vector3,
    width?: number;
    depth?: number;
    height?: number;
    rotationY?: number;
    tableThickness?: number;
}

export class LabBench extends BaseRenderedObject {
    private options: LabBenchOptions = {
        bottomCenterLocation: BABYLON.Vector3.Zero(),
        width: 2,
        depth: 0.5,
        height: 0.7,
        rotationY: 0,
        tableThickness: 0.05
    };

    constructor(name: string, scene: BABYLON.Scene, parent: RenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.LAB_BENCH;
    }

    public setOptions(options: LabBenchOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }


    public render() {
        const top = BABYLON.BoxBuilder.CreateBox("TableTop", {
            width: this.options.width,
            height: this.options.tableThickness,
            depth: this.options.depth
        }, this.scene);

        top.material = MaterialRegistry.generateDiffTexture(this.scene,
            "BenchMaterial",
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/surfaces/Wood.jpg",
            5,
            5);

        const width = this.options.width ?? 0;
        const height = this.options.height ?? 0;
        const depth = this.options.depth ?? 0;
        const rotationY = this.options.rotationY ?? 0;
        const tableThickness = this.options.tableThickness ?? 0;
        const bottomCenterLocation = this.options.bottomCenterLocation ?? BABYLON.Vector3.Zero();

        const base = BABYLON.BoxBuilder.CreateBox("TableTop", {
            width: width - tableThickness,
            height: height - tableThickness,
            depth: depth - tableThickness
        }, this.scene);

        base.material = MaterialRegistry.getCabinetTexture(this.scene, { uScale: 5 });
        base.checkCollisions = true;

        this.myContainerNode = top;

        top.addChild(base)
        top.position = bottomCenterLocation.clone();
        top.position.y = height - tableThickness / 2;

        base.position.y = -(height - tableThickness) / 2.0

        top.rotate(BABYLON.Axis.Y, rotationY);
        return super.render();
    }
}