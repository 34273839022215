<template>
  <div>
    <div class="container mb-5"
         style="max-width:1000px">
      <div class="row align-items-center">
        <div class="col-12 col-md-5 col-lg-6 order-md-2">
          <img src="https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/Images/ViscometerTemp.JPG" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate" alt="..." data-aos="fade-up" data-aos-delay="100">
        </div>
        <div class="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate">
          <h1 class="display-3 text-center text-md-left">
            Virtual Engineering Laboratories
          </h1>
          <div>
            <p class="lead text-center text-md-left text-muted mb-6 mb-lg-8">
              Simulated laboratories that provide students the opportunity to analyze
              complex physical systems in a virtual environment.
            </p>
            <br>
            <hr>
            <router-link :to="{ path:'Login' }">
              Sign up
            </router-link> for a free account and try it out, or request a
            <router-link :to="{ path:'Contact' }">
              guided demo
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <h4 class="card-title">
              Browser Based
            </h4>
            <img src="https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/icons/BeakerIcon.jpg"
                 style="max-width:50px">
            <p class="card-text text-muted">
              There is no software to install. Labs are delivered via the browser and work on any device. <router-link :to="{ path:'Demo' }">
                Video Demos
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <h4>
              Expanding Catalog
            </h4>
            <img src="https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/icons/WindIcon.jpg"
                 style="max-width:50px">
            <p class="card-text text-muted">
              Contact us and we will build the labs you need for any undergraduate engineering course! <router-link :to="{ path:'Catalog' }">
                Catalog
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card h-100 text-center">
          <div class="card-body">
            <h4>
              University Level
            </h4>
            <img src="https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/icons/ValveIcon.jpg"
                 style="max-width:50px">
            <p class="card-text text-muted">
              Labs are designed for an undergraduate level Introduction to Fluid Mechanics course.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    metaInfo() {
      return {
        title:
          "Vengla - Virtual Engineering Laboratories, Online Fluid Mechanics Laboratory, Remote Laboratories",
        meta: [
          {
            name: "description",
            content:
              "Virtual Engineering Labs are designed to engage university students in data collection, facilitate student interaction with engineering instrumentation, and " +
              " create explorable virtual environments.Professors can tailor laboratory materials for use in their course.",
          },
        ],
      };
    },
    components: {},
    setup(): unknown {
      return {};
    },
  };
</script>
