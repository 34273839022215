import { HitData, ControlResponseRequested } from "./Control_Base";

export class HitSequence {
    hasHit = false;
    sequence: HitData[] = [];

    constructor() {
        return;
    }

    add(hitData: HitData) {
        this.hasHit = true;
        this.sequence.push(hitData);
        return this;
    }

    clear() {
        this.hasHit = false;
        this.sequence = [];
        return this;
    }

    has(nrConnections: number) {
        return this.sequence.length === nrConnections;
    }

    connectionIsType(connectionNr: number, type: ControlResponseRequested) {
        if (connectionNr >= this.sequence.length) return false;
        return this.sequence[connectionNr].type === type;
    }

    getObjectFrom(connectionNr: number) {
        if (connectionNr >= this.sequence.length) return null;
        return this.sequence[connectionNr].primaryRenderedObject;
    }

    getPortFrom(connectionNr: number) {
        if (connectionNr >= this.sequence.length) return null;
        return this.sequence[connectionNr].port;
    }
}