

import { TextbookObject } from "@/components/contentGenerator/textbook-object";
import { useUserStore } from "../vueStore/useUserStore";
import { useDatabaseMappings } from "./databaseMapping";



export default function useCourseMaterials() {
   const { userName } = useUserStore();


   async function getPageFromDB(textID: string, fieldType: string, pageID: string) {
      const { getSinglePage } = useDatabaseMappings();
      return await getSinglePage(textID, fieldType, pageID);
   }

   async function getCourseMaterials(courseID: string, isAdmin = false) {
      if (!userName) return undefined;
      const { getCourseMaterials } = useDatabaseMappings();
      return await getCourseMaterials(userName, courseID, isAdmin);
   }

   function checkBook(textID: string, book: TextbookObject | undefined) {
      if (book && book.getID() === textID) {
         return book;
      }
      return undefined;
   }

   const getCourseRecord = async (courseID: string, isAdmin = false) => {
      const courseMaterials = await getCourseMaterials(courseID, isAdmin);
      return courseMaterials?.course;
   }

   const getQuestionBank = async (courseID: string) => {
      const courseMaterials = await getCourseMaterials(courseID);
      return courseMaterials?.questionBank;
   }

   const findPage = async (courseID: string, textID: string, pageID: string) => {

      const courseMaterials = await getCourseMaterials(courseID);

      let tmpResult = undefined;

      tmpResult = checkBook(textID, courseMaterials?.assignmentBook);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.textbook);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.laboratory);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.questionBank);

      return tmpResult?.getPageByID(pageID);
   }

   const findBook = async (courseID: string, textID: string) => {
      const courseMaterials = await getCourseMaterials(courseID);

      let tmpResult = undefined;

      tmpResult = checkBook(textID, courseMaterials?.assignmentBook);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.textbook);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.laboratory);
      if (!tmpResult) tmpResult = checkBook(textID, courseMaterials?.questionBank);

      return tmpResult;
   }

   const getRegisteredUsers = async (courseID: string) => {
      return await useDatabaseMappings().getRegistrationForCourse(courseID);
   }

   return {
      getPageFromDB,
      findPage,
      findBook,
      getCourseRecord,
      getQuestionBank,
      getRegisteredUsers
   }
}