import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import useTime from "@/components/use/useTime";

export enum AccomodationType {
   EXTRA_TIME = "Extra Time",
   DIFFERENT_DATE = "Different Date"
}

export function getAccomodationSchema(firstName: string, lastName: string, email: string, assignmentName: string) {
   const { getDateInFuture } = useTime();

   return FormSchemaMaker.Group({
      label: "Accomodations",
      field: "data",
      properties: {
         classes: "m-1 p-1 border formColorGroup",
         customButtons: [
            { title: 'Thumbnail', emit: 'view-preview' },
            { title: 'Sample', emit: 'view-sample' }
         ]
      },
      typeSelectorInfo: {
         label: "Accomodation Type",
         default: AccomodationType.EXTRA_TIME,
         choices: [
            {
               typeName: AccomodationType.EXTRA_TIME,
               visibleFields: ['lastname', 'firstname', 'email', 'assignment', 'extraTime']
            },
            {
               typeName: AccomodationType.DIFFERENT_DATE,
               visibleFields: ['lastname', 'firstname', 'email', 'assignment', 'openOn', 'dueOn']
            }
         ]
      },
      value: AccomodationType.EXTRA_TIME
   },
      [
         FormSchemaMaker.ReadOnly({ label: "Last Name", field: "lastname", value: lastName, properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
         FormSchemaMaker.ReadOnly({ label: "First Name", field: "firstname", value: firstName, properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
         FormSchemaMaker.ReadOnly({ label: "Email", field: "email", value: email, properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
         FormSchemaMaker.ReadOnly({ label: "Assignment", field: "assignment", value: assignmentName, properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this assignment." }),
         FormSchemaMaker.Number({
            label: "Extra Time (hours)",
            field: "extraTime",
            properties: { labelCols: 4, componentCols: 12, componentOffset: 0 },
            rules: { min_value: 0 },
            hint: "User will be given this many extra hours to complete the assignment. "
         }),
         FormSchemaMaker.DateTime({
            label: "Open On",
            field: "openOn",
            hint: "The student may begin the assignment on this date.",
            value: new Date().toISOString(),
            properties: { labelCols: 4, componentCols: 8, componentOffset: 0 },
         }),
         FormSchemaMaker.DateTime({
            label: "Due On",
            field: "dueOn",
            hint: "The assignment will be automatically collected and shut down on this date.",
            value: getDateInFuture(7).toISOString(),
            properties: { labelCols: 4, componentCols: 12, componentOffset: 0 },
         }),
      ]);
}