import { convert_util, convert_to_base_unit, convert_from_base_value_to } from "../values/Conversion";
import { ScalarValue, _u } from "../values/ScalarValue";

export enum NoiseType {
    PerOfRange,
    FixedAmplitude
}

export enum NoiseCorrelation {
    Uncorrelated,
    SinVariationWithTime,
    DecayingSinusoidal
}

export interface NoiseOptionsInterface {
    addNoise: boolean;
    type: NoiseType;
    correlation: NoiseCorrelation;
    amplitude: ScalarValue;
    period: number;
    percent: number;
    decayConst: number;
    decayInitialTime: number;
}

export enum RangeOverFlowOptions {
    NONE,
    CLIP,
    ERROR
}

export interface DisplayRangeOptions {
    minValue: ScalarValue,
    maxValue: ScalarValue,
    overflow?: RangeOverFlowOptions
}

/**
 * Instrument Display Value
 * 
 * This class is inteneded to simulate the 
 * noise and uncertainty in instrument readings.
 * The instrumentNumericalValue holds the 
 * true value, and this class will add a certain
 * amount and type of noise to the actual value.
 * 
 * You can select to clip overflow.
 * 
 * */
export class InstrumentDisplayValue extends ScalarValue {
    private seed: number;
    private displayRangeInBaseUnits: number;
    public maxValueInBaseUnit: number;
    public minValueInBaseUnit: number;

    private displayRangeOptions: DisplayRangeOptions = {
        minValue: _u(0, "NONE"), // same unit as original value
        maxValue: _u(0, "NONE"), // same unit as original value
        overflow: RangeOverFlowOptions.CLIP
    };

    private noiseOptions: NoiseOptionsInterface = {
        addNoise: false,
        type: NoiseType.PerOfRange,
        correlation: NoiseCorrelation.Uncorrelated,
        amplitude: new ScalarValue(1, ""),
        period: 0.01, // units of 1/s
        percent: 0.02,
        decayConst: 1,
        decayInitialTime: 0
    };

    constructor(value: number, unit: string, displayRangeOptions: DisplayRangeOptions, noiseOptions: Partial<NoiseOptionsInterface>) {
        super(value, unit);
        this.seed = Math.random();
        this.noiseOptions = { ...this.noiseOptions, ...noiseOptions };
        this.displayRangeOptions = { ...this.displayRangeOptions, ...displayRangeOptions };

        this.maxValueInBaseUnit = this.displayRangeOptions.maxValue.getValueInBaseUnit();
        this.minValueInBaseUnit = this.displayRangeOptions.minValue.getValueInBaseUnit();

        this.displayRangeInBaseUnits = (this.maxValueInBaseUnit - this.minValueInBaseUnit);

    }

    public getDisplayScalar(time = -1) {
        return _u(this.getDisplayValue(time), this.unit);
    }


    public getDisplayValueIn(outUnit: string, time = -1) {
        return convert_util(this.getDisplayValue(time), this.unit, outUnit);
    }


    public getDisplayValue(time = -1) {
        const trueValue = this.value;

        if (!this.noiseOptions.addNoise) {
            return this.clip(trueValue);
        }

        let amplitudeInSameUnits;
        switch (this.noiseOptions.type) {
            case NoiseType.PerOfRange:
                amplitudeInSameUnits = convert_from_base_value_to(this.displayRangeInBaseUnits, this.unit) * this.noiseOptions.percent;
                break;
            case NoiseType.FixedAmplitude:
                amplitudeInSameUnits = this.noiseOptions.amplitude.inSameAs(this);
                break;
        }

        switch (this.noiseOptions.correlation) {
            case NoiseCorrelation.Uncorrelated:
                return this.addUncorrelatedNoise(trueValue, amplitudeInSameUnits);
            case NoiseCorrelation.SinVariationWithTime:
                return this.addSinVariationWithTime(trueValue, time, amplitudeInSameUnits);
            case NoiseCorrelation.DecayingSinusoidal:
                return this.addDecayingSinusoidal(trueValue, time, amplitudeInSameUnits);
        }
    }

    public setDecayInitialTime(timeInS: number) {
        this.noiseOptions.decayInitialTime = timeInS;
    }


    private addDecayingSinusoidal(value: number, time: number, amplitude: number) {
        if (time == -1) { return this.clip(value); }
        const adjTime = time + 4 * this.seed / this.noiseOptions.period;
        return this.clip(value + 0.25 * (Math.exp(-this.noiseOptions.decayConst * (time - this.noiseOptions.decayInitialTime)) + 1)
            * amplitude * (
                Math.sin(this.noiseOptions.period * adjTime) +
                Math.sin(0.4 * this.noiseOptions.period * adjTime) +
                Math.sin(2 * this.noiseOptions.period * adjTime) +
                Math.sin(4 * this.noiseOptions.period * adjTime)
            ));
    }

    private addSinVariationWithTime(value: number, time: number, amplitude: number) {
        if (time == -1) { return this.clip(value); }
        const adjTime = time + 4 * this.seed / this.noiseOptions.period;
        return this.clip(value + 0.25 * amplitude * (
            Math.sin(this.noiseOptions.period * adjTime) +
            Math.sin(0.4 * this.noiseOptions.period * adjTime) +
            Math.sin(2 * this.noiseOptions.period * adjTime) +
            Math.sin(4 * this.noiseOptions.period * adjTime)
        ));
    }

    private addUncorrelatedNoise(value: number, amplitude: number) {
        return this.clip(value + (Math.random()) * amplitude);
    }

    private clip(value: number) {
        if (RangeOverFlowOptions.NONE) return value;

        if (value < convert_from_base_value_to(this.minValueInBaseUnit, this.unit)) {
            switch (this.displayRangeOptions.overflow) {
                case RangeOverFlowOptions.CLIP:
                    value = convert_from_base_value_to(this.minValueInBaseUnit, this.unit);
                    break;
                case RangeOverFlowOptions.ERROR:
                    console.error("Display Value UnderFlow");
                    break;
            }
        }

        if (value > convert_from_base_value_to(this.maxValueInBaseUnit, this.unit)) {
            switch (this.displayRangeOptions.overflow) {
                case RangeOverFlowOptions.CLIP:
                    value = convert_from_base_value_to(this.maxValueInBaseUnit, this.unit);
                    break;
                case RangeOverFlowOptions.ERROR:
                    console.error("Display Value OverFlow");
                    break;
            }
        }

        return value;
    }

    public getRatioOfRange(time = -1) {
        const range = convert_from_base_value_to(this.displayRangeInBaseUnits, this.unit);
        return (this.getDisplayValue(time) - convert_from_base_value_to(this.minValueInBaseUnit, this.unit)) / (range);
    }

    public interpolateToNewRange(newRangeMin: number, newRangeMax: number, time = -1) {
        return this.getRatioOfRange(time) * (newRangeMax - newRangeMin) + newRangeMin;
    }
}