export enum TextRecognitionFieldType {
   CHAR = "CHAR",
   EQN = "EQN",
   EQN_2 = "EQN_2"
}

export interface TextRecognitionBaseTableType {
   id: string;
   character: string;
   fieldType: string;
   userEmail: string;
   included: boolean;
   orignalPaths: string[];
   dateIndex: string;
   meta?: any;


   createdAt?: string;
   updatedAt?: string;
}

export enum TextRecognitionSearchTypes {
   BY_CHAR,
   BY_EQN,
   BY_OWNER,
   BY_DATE,
   BY_VERSION
}


