
import { ref } from 'vue'
import { FormSchemaItem } from '../form-schema.interface';

export default function useFormTypeSelector(modelValue: any, schemaItem: FormSchemaItem) {
   const schemaValue = ref(modelValue);

   const hasSelectableTypeInfo = () => {
      return Object.prototype.hasOwnProperty.call(
         schemaItem,
         "typeSelectorInfo"
      );
   };

   const setSelectedTypeIndicator = (value: any) => {
      modelValue = value;
   };

   const getSelectableObjectTypes = () => {
      if (hasSelectableTypeInfo()) {
         return schemaItem.typeSelectorInfo?.choices.map(
            (x: { typeName: string }) => {
               return x.typeName;
            }
         );
      } else {
         return [];
      }
   };

   let defaultSchemaType = "";
   if (hasSelectableTypeInfo()) {
      console.log("Modal")
      defaultSchemaType = schemaItem.typeSelectorInfo?.default || "";
   }

   const selectedType = ref(modelValue || defaultSchemaType);


   const typeSelectorIncludes = (typeSelectorName: string, field: string) => {
      const typeSel = schemaItem.typeSelectorInfo?.choices.find(
         (x: { typeName: string }) => {
            return x.typeName === typeSelectorName;
         }
      );
      if (typeSel) {
         return typeSel.visibleFields.includes(field);
      } else {
         return true;
      }
   };

   const getVisibleSchemaItems = (): FormSchemaItem[] => {
      if (hasSelectableTypeInfo() === null) {
         return schemaItem?.children ? schemaItem?.children : [];
      } else {
         const returnChildren: FormSchemaItem[] = [];
         schemaItem.children?.forEach((v: FormSchemaItem) => {
            if (typeSelectorIncludes(selectedType.value, v.field)) {
               returnChildren.push(v);
            }
         });
         return returnChildren;
      }
   };

   const getSelectedTypeLabel = () => {
      return schemaItem.typeSelectorInfo?.label;
   }


   return {
      schemaValue,
      selectedType,
      hasSelectableTypeInfo,
      getSelectableObjectTypes,
      typeSelectorIncludes,
      getVisibleSchemaItems,
      setSelectedTypeIndicator,
      getSelectedTypeLabel
   }
}