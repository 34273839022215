
  import { reactive } from "vue";
  import FormRoot from "./components/form-root.vue";
  import { FormSchemaMaker } from "./form-schema-maker";
  import { v4 as uuid4 } from "uuid";
  import BConfirmDialog from "../bootstrap/b-confirm-dialog.vue";

  import useSchema from "../use/useSchema";

  import useTime from "../use/useTime";
  import { FormSchemaItem } from "./form-schema.interface";
  import useReactiveForm from '../use/useReactiveForm';
  import { AccomodationType } from '@/views/student/Accomodation.schema';

  export default {
    name: "FormSamplepage",
    components: {
      FormRoot,
      BConfirmDialog,
    },
    setup(): unknown {
      const {
        getCurrentDateOnlyToMinISO,
        getCurrentTimeISO,
        getCurrentDateTimeISO,
      } = useTime();

      const inputSchema = (
        FormSchemaMaker.Group(
          {
            label: "Course Database Item",
            field: "data",
            properties: { classes: "m-1" },
          },
          [
            FormSchemaMaker.FileLocation({
              label: "File Location",
              field: "fileValue",
              properties: { classes: "fileInput" },
              rules: { min: 5, max: 32, required: true },
              value: "old file",
              hint: "Upload a file.",
            }),
            FormSchemaMaker.ReadOnly({
              label: "Read Only",
              properties: {
                classes: "readOnly",
                labelCols: 2,
                componentCols: 12,
              },
              field: "readValue",
              value: "read text",
            }),
            FormSchemaMaker.Text({
              label: "Text Test",
              field: "textValue",
              properties: { classes: "textInput" },
              rules: { min: 5, max: 32, required: true },
              value: "old text",
              hint:
                "Assign a name to your course. Use a descriptive title that will help users find your course.",
            }),
            FormSchemaMaker.Email({
              label: "Email Test",
              field: "emailValue",
              properties: { classes: "emailInput" },
              rules: { required: true, email: true },
              value: "oldEmail@j.com",
              hint:
                "Assign a name to your course. Use a descriptive title that will help users find your course.",
            }),
            FormSchemaMaker.Integer({
              label: "Integer Test",
              field: "integerValue",
              properties: { classes: "integerInput" },
              rules: {
                min_value: 0,
                max_value: 100,
                required: true,
                integer: true,
              },
              hint: "Cost in dollars students will be charged.",
              value: "5",
            }),
            FormSchemaMaker.TextBox({
              label: "Text Box Test",
              field: "textBoxValue",
              properties: { classes: "textBox" },
              hint: "Add a description to help users learn about your content.",
              value: "This is text",
            }),
            FormSchemaMaker.Select({
              label: "Select Test",
              field: "selectValue",
              properties: { classes: "selectInput" },
              choices: ["BUG", "FEATURE", "TODO"],
              value: "TODO",
              hint: "",
            }),
            FormSchemaMaker.CheckBox({
              label: "Check Test",
              field: "checkboxValue",
              properties: { classes: "checkboxInput" },
              value: true,
            }),
            FormSchemaMaker.Date({
              label: "Date Test",
              field: "dateValue",
              properties: { classes: "dateInput" },
              value: getCurrentDateOnlyToMinISO(),
              hint: "Access to this item begins on the set date. ",
            }),
            FormSchemaMaker.Time({
              label: "Time Test",
              field: "timeValue",
              value: getCurrentTimeISO(),
              properties: { classes: "timeInput" },
              hint: "Access to this item begins on the set date. ",
            }),
            FormSchemaMaker.DateTime({
              label: "Date Time Test",
              field: "dateTimeValue",
              properties: { classes: "dateTimeInput" },
              value: getCurrentDateTimeISO(),
              hint: "Access to this item begins on the set date. ",
            }),
            FormSchemaMaker.MarkdownIt({
              label: "Markdown Test",
              field: "markdownValue",
              properties: { classes: "markdownInput" },
              rules: { min: 5, max: 32, required: true },
              value: "old markdown",
              hint:
                "Assign a name to your course. Use a descriptive title that will help users find your course.",
            }),
            FormSchemaMaker.ArrayOfInts({
              label: "Array Int Test",
              field: "arrayIntValue",
              properties: { classes: "arrayIntInput" },
              value: [1, 2],
              hint: "enter array items",
            }),
            FormSchemaMaker.ArrayOfObject({
              label: "Question Topics",
              field: "topics",
              hint: "Select topics addressed by this question.",
              properties: {
                classes: "m-1",
                labelAlign: "top",
                labelCols: 0,
                componentCols: 6
              },
              value: [],
            },
              [
                FormSchemaMaker.Select({
                  label: "Difficulty",
                  field: "difficulty",
                  properties: { classes: "selectInput" },
                  choices: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                  value: "5",
                  hint: "",
                }),
                FormSchemaMaker.ItemSubItem({
                  label: "Items",
                  field: "topic",
                  hint: "Enter the allowable variable increment.",
                  value: { item: "Topic 1", subItem: "Sub Topic 2" },
                  meta: {
                    topics: [{
                      name: "Topic 1",
                      id: "abc",
                      subItems: [
                        {
                          name: "Sub Topic 1",
                          id: "cde"
                        },
                        {
                          name: "Sub Topic 2",
                          id: "cde"
                        }
                      ]
                    }]
                  }
                }),
              ]
            ),
            FormSchemaMaker.ArrayOfObjectWithEditor(
              {
                label: "Course Topics",
                field: "objectArrayValue",
                properties: { classes: "arrayOfObjectsInput" },
                rules: {},
                hint: "",
                typeSelectorInfo: {
                  label: "Question Type",
                  default: "Multiple Choice",
                  choices: [
                    {
                      typeName: "Multiple Choice",
                      visibleFields: ["name", "description", "items"],
                    },
                    {
                      typeName: "Single Choice",
                      visibleFields: ["name"],
                    },
                  ],
                },
                value: [
                  { name: "test 1", description: "abc", items: "item 1" },
                  { name: "test 2", description: "def", items: "item 2" },
                ],
              },
              [
                FormSchemaMaker.Text({
                  label: "Name",
                  field: "name",
                  properties: { classes: "objectNameEntry" },
                  hint: "",
                  value: "New Item",
                }),
                FormSchemaMaker.ReadOnly({
                  label: "Description",
                  field: "description",
                  value: () => {
                    return uuid4();
                  },
                }),
                FormSchemaMaker.Text({
                  label: "items",
                  field: "items",
                  hint: "",
                  value: "Something",
                }),
              ]
            ),
            FormSchemaMaker.Group({
              label: "Accomodations",
              field: "data",
              properties: {
                classes: "m-1 p-1 border formColorGroup",
                customButtons: [
                  { title: 'Thumbnail', emit: 'view-preview' },
                  { title: 'Sample', emit: 'view-sample' }
                ]
              },
              typeSelectorInfo: {
                label: "Accomodation Type",
                default: AccomodationType.EXTRA_TIME,
                choices: [
                  {
                    typeName: AccomodationType.EXTRA_TIME,
                    visibleFields: ['lastname', 'firstname', 'email', 'assignment', 'extraTime']
                  },
                  {
                    typeName: AccomodationType.DIFFERENT_DATE,
                    visibleFields: ['lastname', 'firstname', 'email', 'assignment', 'openOn', 'dueOn']
                  }
                ]
              },
              value: AccomodationType.EXTRA_TIME
            },
              [
                FormSchemaMaker.ReadOnly({ label: "Last Name", field: "lastname", value: "lastName", properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
                FormSchemaMaker.ReadOnly({ label: "First Name", field: "firstname", value: "firstName", properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
                FormSchemaMaker.ReadOnly({ label: "Email", field: "email", value: "email", properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this user." }),
                FormSchemaMaker.ReadOnly({ label: "Assignment", field: "assignment", value: "assignmentName", properties: { labelCols: 4, componentCols: 12 }, hint: "Accomodation will be applied to this assignment." }),
                FormSchemaMaker.Number({
                  label: "Extra Time (hours)",
                  field: "extraTime",
                  properties: { labelCols: 4, componentCols: 12, componentOffset: 0 },
                  rules: { min_value: 0 },
                  hint: "User will be given this many extra hours to complete the assignment. "
                }),
                FormSchemaMaker.DateTime({
                  label: "Open On",
                  field: "openOn",
                  hint: "The student may begin the assignment on this date.",
                  value: new Date().toISOString(),
                  properties: { labelCols: 4, componentCols: 8, componentOffset: 0 },
                }),
                FormSchemaMaker.DateTime({
                  label: "Due On",
                  field: "dueOn",
                  hint: "The assignment will be automatically collected and shut down on this date.",
                  value: new Date().toISOString(),
                  properties: { labelCols: 4, componentCols: 12, componentOffset: 0 },
                }),
              ])
          ]
        )
      );


      const buttonList = reactive([
        {
          class: "btn-success ",
          label: "Save",
          emit: "save",
          isEnabled: true,
        },
        {
          class: "btn-danger m-2",
          label: "Reset",
          emit: "reset",
          isEnabled: true,
        },
        {
          class: "btn-primary",
          label: "Hints",
          emit: "hints",
          isEnabled: true,
        },
        {
          class: "btn-primary",
          label: "Dialog",
          emit: "dialog",
          isEnabled: true,
        },
      ]);


      const { schema_mergeWithData } = useSchema();

      const {
        displaybehavior,
        triggers,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog
      } = useReactiveForm(inputSchema, {});

      const onPress = (v: { type: string; schemaItem: FormSchemaItem }): void => {
        switch (v.type) {
          case "save":
            buttonList[0].isEnabled = false;
            triggers.validate = !triggers.validate;
            break;
          case "reset":
            schema.value = schema_mergeWithData(rawSchema, {});
            formKey.value++;
            break;
          case "hints":
            displaybehavior.displayHints = !displaybehavior.displayHints;
            break;
          case "dialog":
            confirmDialog?.value
              ?.open("test", "test", {})
              .then((isConfirmed: boolean) => {
                console.log(isConfirmed);
              });
            break;
        }
      };

      const onChange = () => {
        buttonList[0].isEnabled = true;
      };

      return {
        isFormValid,
        schema,
        formKey,
        onPress,
        onChange,
        buttonList,
        displaybehavior,
        triggers,
        confirmDialog,
      };
    },
  };
