
  import { defineComponent, PropType, provide, ref } from "vue";
  import useComponents from "../../use/usePageComponents";
  import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
  import ComponentViewer from "./ComponentViewer.vue";
  import { usePageEditorFunctions } from "@/views/instructor/usePageEditorFunctions";

  export default defineComponent({
    components: {
      ComponentViewer
    },
    props: {
      pageData: {
        type: Object as PropType<PageFromTextbookTable>,
        required: true,
      },
    },
    setup(props) {
      provide("pageData", props.pageData);

      const {
        getActiveComponentsFromPageData,
      } = useComponents();

      const {
        getIconForComponentType,
      } = usePageEditorFunctions(() => { return; });


      let activeComponent = ref(0);
      let content = getActiveComponentsFromPageData(props.pageData);

      return {
        content,
        activeComponent,
        getIconForComponentType
      };
    },
  });
