import { OneDNewton } from "@/math/solver/oneDNewton";

export class FluidDyanmicsEquations {
    static findFrictionCoef(velocity: number, diameter: number, surfRough: number, dynamicViscosity: number): number {
        const Re = velocity * diameter / dynamicViscosity;
        const relativeRoughness = surfRough / diameter;

        return FluidDyanmicsEquations.frictionFactor(Re, relativeRoughness);
    }

    static frictionFactor(Re: number, relativeRoughness: number) {
        console.log("Hellow")
        console.debug({ Re, relativeRoughness });
        // laminar flow
        if (Re < 2000) { return 64 / Re; }

        // turbulent
        return OneDNewton(minColebrook, 0.01, 0.001);

        function minColebrook(x: number): number {
            const fric = x;
            if (fric < 0) return -1 * fric * 100000;
            return (1 / Math.sqrt(fric)) + (0.86 * Math.log(relativeRoughness / 3.7 + 2.51 / (Re * Math.sqrt(fric))));
        }
    }

    static pipeLoss(frictionFactor: number, diameter: number, length: number, velocity: number) {
        const g = 9.81;
        return frictionFactor * length / diameter * velocity * velocity / (2 * g);
    }

    static pipeLossPlusMinor(frictionFactor: number, diameter: number, length: number, Ktotal: number, velocity: number) {
        const g = 9.81;
        return (frictionFactor * length / diameter + Ktotal) * velocity * velocity / (2 * g);
    }

}
