import { TextType } from "@/API";
import { createTextbook, deleteTextbook, updateTextbook } from "@/graphql/mutations";
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { getDefaultBaseTextbookDBRecord } from "./table-textbook-utils";
import { PageFromTextbookTable, PageIndex } from "./table-textbook.interface";
import useTime from "@/components/use/useTime";

const { getCurrentDateOnlyToMinISO, getCurrentDateTimeISO } = useTime();

export interface PageCreateOptions {
   textID: string;
   pageType: TextType;
}

const pageRecordCreator: RecordCreatorParameters<PageFromTextbookTable, PageCreateOptions> = {
   defaultValues: {
      ...getDefaultBaseTextbookDBRecord(),
      page_isActive: true,
      page_activeContent: {
         content: JSON.stringify({ components: [] }),
         createdOn: getCurrentDateTimeISO(),
         creator: "",
         updatedOn: getCurrentDateTimeISO()
      },
      page_draftContent: {
         //content: new PageContent().toJSONString(),
         createdOn: getCurrentDateTimeISO(),
         creator: "",
         updatedOn: getCurrentDateTimeISO()
      },
      page_history: [],
      asn_options: {
         dueOn: getCurrentDateOnlyToMinISO(),
         formOwnGroups: false,
         isGroup: false,
         isTimed: false,
         maxAttempts: 1,
         maxGroupMembers: 1,
         maxPoints: 100,

         catagory: "Homework",

         showSolutions: false,
         showScoreBreakdown: true,
         useHandwritingEntry: false,

         randomizeQuestions: false,
         randomizeAnswers: true,

         openOn: getCurrentDateOnlyToMinISO(),
         requiresGroupNotebook: false,
         timeLimit: null
      },
      lab_pictureURL: ""
   },
   objectType: RecordTypes.PAGE,
   createQuery: createTextbook,
   updateQuery: updateTextbook,
   deleteQuery: deleteTextbook,

   getDeleteItemParameters: function (data: PageFromTextbookTable) {
      return {
         id: data.id,
         textID: data.textID,
         fieldType: data.fieldType
      }
   },

   onAfterCreatedModifyData: function (data: PageFromTextbookTable,
      createOptions: PageCreateOptions,
      curObj: WrappedRecord<PageFromTextbookTable, PageCreateOptions>) {
      curObj.data().fieldType = createOptions.pageType;
      curObj.data().textID = createOptions.textID;
   },

   onBeforeSaveToDB: function (data: PageFromTextbookTable,
      curObj: WrappedRecord<PageFromTextbookTable, PageCreateOptions>) {
      return data;
   },

   onAfterRecordWrapFromDB(data: PageFromTextbookTable,
      curObj: WrappedRecord<PageFromTextbookTable, PageCreateOptions>) {
      return;
   }
}


setRecordCreator(RecordTypes.PAGE, pageRecordCreator);

/**
 * Class used to hold, update, and create database 
 * Table of Contents items. 
 * */
export class PageRecord extends WrappedRecord<PageFromTextbookTable, PageCreateOptions> {
   constructor() {
      super(RecordTypes.PAGE)
   }

   // initialize a new page record when a page is added to the table of contents
   // list. The page needs to be saved to the database seperately.
   createNewFromTOCentry(pageType: TextType, entry: PageIndex, textID: string) {
      // add data to this object
      this.setData({ ...this.data(), ...this.myCreator().defaultValues });
      this.data().fieldType = pageType;

      this.data().name = entry.name;
      this.data().id = entry.pageID;
      this.data().textID = textID;

      return this;
   }

   static loadFromJSON(data: Record<string, unknown>[]) {
      if (!data) return [];

      const newArrayOfPages: PageRecord[] = [];
      data.forEach((v) => {
         const newPage = new PageRecord();
         Object.assign(newPage, v);
         newArrayOfPages.push(newPage);
      })

      return newArrayOfPages;
   }
}