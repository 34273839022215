<template>
  <div>
    <BOverlay :show="showOverlay" />
    <div class="container border shadow mt-3 p-4">
      <h2> Contact Us</h2>
      <h3 v-show="sent"
          class="text-success">
        Your message has been received. Thank you!
      </h3>
      <p>
        Request a demo or send along comments, ideas for new labs, and questions.
        We are dedicated to improving our virtual laboratory and would be happy
        to work with you to create valuable student experiences.
      </p>
      <div v-if="!isAuthenticated"
           class="mb-3">
        <label for="emailInput"
               class="form-label">
          Your email address
        </label>
        <input id="emailInput"
               v-model="form.email"
               type="email"
               class="form-control"
               placeholder="name@example.com">
      </div>
      <div class="mb-3">
        <label for="textInput"
               class="form-label">
          Enter your message
        </label>
        <textarea id="textInput"
                  v-model="form.message"
                  class="form-control"
                  rows="10" />
      </div>

      <button class="btn btn-success"
              type="button"
              @click="onSubmit">
        Submit
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import BOverlay from "@/components/bootstrap/b-overlay.vue";
  import { defineComponent } from "vue";
  import useAxios, { LambdaFunctions } from "@/components/use/useAxios";
  import { useUserStore } from "@/store/vueStore/useUserStore";

  export default defineComponent({
    components: { BOverlay },
    metaInfo() {
      return {
        title: "Vengla Contact Us Page",
        meta: [
          {
            name: "description",
            content: `
                                      Users may directly contact Vengla using this email form. 
                                      Enter your email and a message on this site and we will
                                       get back to you as quickly as possible.`,
          },
        ],
      };
    },
    data() {
      return {
        form: {
          email: "",
          message: "",
        },
        showOverlay: false,
        show: true,
        sent: false,
        isAuthenticated: false,
      };
    },
    methods: {
      async onSubmit() {
        this.showOverlay = true;
        console.debug("Contact - Sending Email", this.form);

        const { postAxios } = useAxios();
        const { isSignedIn, userName } = useUserStore();

        let email = isSignedIn ? userName! : this.form.email;

        const result = await postAxios({
          lambdaFunction: LambdaFunctions.CONTACT_US,
          params: {
            email,
            message: this.form.message,
          },
        });

        console.debug("Contact - Axios Response:", result);
        this.showOverlay = false;
        this.onReset();

        if (!result.hasError()) {
          this.sent = true;
        }
      },
      onReset() {
        this.form.email = "";
        this.form.message = "";
      },
    },
  });
</script>

<style scoped>
  @media only screen and (max-width: 500px) {
    ::v-deep(.form-control) {
      font-size: 16px;
    }
  }
</style>