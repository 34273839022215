
  import BOverlay from "@/components/bootstrap/b-overlay.vue";
  import { defineComponent } from "vue";
  import useAxios, { LambdaFunctions } from "@/components/use/useAxios";
  import { useUserStore } from "@/store/vueStore/useUserStore";

  export default defineComponent({
    components: { BOverlay },
    metaInfo() {
      return {
        title: "Vengla Contact Us Page",
        meta: [
          {
            name: "description",
            content: `
                                      Users may directly contact Vengla using this email form. 
                                      Enter your email and a message on this site and we will
                                       get back to you as quickly as possible.`,
          },
        ],
      };
    },
    data() {
      return {
        form: {
          email: "",
          message: "",
        },
        showOverlay: false,
        show: true,
        sent: false,
        isAuthenticated: false,
      };
    },
    methods: {
      async onSubmit() {
        this.showOverlay = true;
        console.debug("Contact - Sending Email", this.form);

        const { postAxios } = useAxios();
        const { isSignedIn, userName } = useUserStore();

        let email = isSignedIn ? userName! : this.form.email;

        const result = await postAxios({
          lambdaFunction: LambdaFunctions.CONTACT_US,
          params: {
            email,
            message: this.form.message,
          },
        });

        console.debug("Contact - Axios Response:", result);
        this.showOverlay = false;
        this.onReset();

        if (!result.hasError()) {
          this.sent = true;
        }
      },
      onReset() {
        this.form.email = "";
        this.form.message = "";
      },
    },
  });
