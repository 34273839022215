<template>
  <div class="container-fluid p-0 m-0 condensedForm">
    <div class="row g-0">
      <div class="col">
        <b-button label="Save"
                  class="btn btn-success float-end"
                  :disabled="!hasChanges"
                  @click="onClickSave" />
      </div>
    </div>
    <div class="row g-0">
      <div class="col">
        <b-markdown-editor v-model:content="content" />
      </div>
    </div>
  </div>
</template>


<script lang="ts">
  import BButton from '@/components/bootstrap/b-button.vue';
  import bMarkdownEditor from '@/components/bootstrap/b-markdown-editor.vue';
  import usePageComponents from '@/components/use/usePageComponents';
  import { PageRecord } from '@/store/database/Textbook/record-page'
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext, watch } from 'vue'

  export default defineComponent({
    components: { bMarkdownEditor, BButton },
    props: {
      dataPage: {
        type: Object as PropType<PageRecord>,
        required: true
      }
    },
    emits: ["saveQuestion"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.debug("Enter Textbook Page Creator");
      const hasChanges = ref(false);
      const pageData = props.dataPage.data();
      const { getActiveComponentsFromPageData } = usePageComponents();
      const componentObj = getActiveComponentsFromPageData(pageData).components[0];
      pageData.page_activeContent.content = { components: [componentObj] };

      const content = ref(componentObj.data.content);


      const onClickSave = () => {
        componentObj.data.content = content.value;
        pageData.page_activeContent.content = JSON.stringify({ components: [componentObj] });
        context.emit("saveQuestion", props.dataPage);
        hasChanges.value = false;
      }

      watch(content, () => { hasChanges.value = true; })

      return {
        onClickSave,
        content,
        hasChanges
      }
    }
  })
</script>

<style scoped>
</style>
