<template>
  <nav v-if="!isVertical">
    <div class="nav nav-tabs" role="tablist">
      <button v-for="(tab, index) in tabs"
              :key="'nav-key-' + index.toString()"
              :class="{ 'nav-link': true, 'active': selectedIndex === index }"
              @click="selectTab(index)">
        {{ tab.props.title }}
      </button>
    </div>
  </nav>
  <div v-if="!isVertical"
       class="tab-content">
    <slot />
  </div>
  <div v-if="isVertical"
       class="d-flex align-items-start">
    <div class="nav flex-column nav-pills me-3">
      <button v-for="(tab, index) in tabs"
              :key="'nav-key-' + index.toString()"
              :class="{ 'nav-link': true, 'active': selectedIndex === index }"
              :style="{ 'width': menuWidth }"
              @click="selectTab(index)">
        {{ tab.props.title }}
      </button>
    </div>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  import { toRefs } from "@vueuse/core";
  import {
    defineComponent,
    onBeforeMount,
    onMounted,
    provide,
    reactive,
    VNode,
  } from "vue";

  interface TabProps {
    title: string;
  }

  export default defineComponent({
    name: "BTabs",
    props: {
      isVertical: {
        type: Boolean,
        default: false,
      },
      menuWidth: {
        type: String,
        default: "100px",
      },
    },
    setup(_, context) {
      const state = reactive({
        selectedIndex: 0,
        tabs: [] as VNode<TabProps>[],
        count: 0,
      });

      provide("TabsProvider", state);

      const selectTab = (i: number) => {
        state.selectedIndex = i;
      };

      onBeforeMount(() => {
        if (context.slots.default) {
          state.tabs = context.slots
            .default()
            .filter(
              (child) => (child.type as { name: string }).name === "BTab"
            ) as VNode<TabProps>[];
        }
      });

      onMounted(() => {
        selectTab(0);
      });

      return { ...toRefs(state), selectTab };
    },
  });
</script>

<style scoped>
</style>