<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-1" />
      <div class="col mt-5"
           style="max-width:720px">
        <div class="ratio ratio-16x9">
          <iframe src="https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/web/movies/VenglaIntro.mp4" title="Demonstration" allowfullscreen />
        </div>
      </div>
      <div class="col-1" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    metaInfo() {
      return {
        title: "Vengla - Virtual Engineering Laboratories, Video Demonstration",
        meta: [
          {
            name: "description",
            content:
              "A video showing the graphics and basic usage of the Vengla virtual engineering system on a web browser.",
          },
        ],
      };
    },
    setup() {
      return {};
    },
  });
</script>

<style scoped>
</style>