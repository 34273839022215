<template>
  <suspense>
    <template #default>
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="text-center">
              Available Courses
            </h1>
          </div>
        </div>
        <div class="row">
          <div v-for="course in availableCourses"
               :key="course.data().name"
               class="col align-items-stretch d-flex justify-content-center m-2">
            <BCard :title="course.data().name"
                   :description="course.data().description"
                   :img-src="resolveURL(course.data().id, course.data().co_pictureURL)"
                   class="h-100"
                   :footer="getFooter(course)"
                   @click="onClick(course.data().id)" />
          </div>
          <div v-if="isAdmin"
               class="col align-items-stretch d-flex justify-content-center m-2">
            <BCard title="Create"
                   description="Create a new course."
                   :img-src="resolveURL('e67a4452-9218-4fa9-b1e0-1d4203b34a70', 'photos/Slide15.JPG')"
                   class="h-100"
                   @click="gotoCreate()" />
          </div>
        </div>
        <BConfirmDialog ref="confirmDialog" />
      </div>
    </template>
    <template #fallback>
      <div>Loading Course List...</div>
    </template>
  </suspense>
</template>

<script lang="ts">
  import BCard from "@/components/bootstrap/b-card.vue";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";
  import { CourseRecord } from '@/store/database/Course/record-course';
  import useConfirmationDialog from '@/components/use/useConfirmationDialog';
  import BConfirmDialog from '@/components/bootstrap/b-confirm-dialog.vue';


  export default {
    name: "AvailableCourseList",
    components: { BCard, BConfirmDialog },
    async setup() {
      const { userName, isAdmin } = useUserStore();
      if (!userName) return;

      const availableCourses =
        await useDatabaseMappings().getAvailableCourseList();

      console.log({ availableCourses })
      const onClick = (courseId: string) => {
        router.push({ name: "AvailableCourseDetail", params: { courseId } });
      };


      const { confirmDialog } = useConfirmationDialog();

      const gotoCreate = () => {
        confirmDialog.value
          ?.open(
            "Create A New Course",
            "Are you sure you would like to add a new course?",
            {}
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed)
              router.push({ name: "CreateCourse" });
          });
      }

      const { resolveURL } = useS3();


      const getFooter = (course: CourseRecord) => {
        if (course.data().availibility) {
          switch (course.data().availibility?.restrictionType) {
            case "EMAIL_MATCH":
              return "Restricted";
            case "FREE":
              return "Free";
            case "ACCESS_CODE":
              return "Code Required";
            case "PAID":
              return "Cost: $" + course.data().availibility?.cost;
          }
        }

        return "";
      }

      return {
        availableCourses,
        onClick,
        resolveURL,
        gotoCreate,
        getFooter,
        isAdmin,
        confirmDialog
      };
    },
  };
</script>

<style scoped>
</style>