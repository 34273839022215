import { FieldType } from "../../../API";
import { createCourse, deleteCourse, updateCourse } from '../../../graphql/mutations';
import { BaseRecord, RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { AssignmentFromCourseTable } from "./table-course.interface";
import { CourseCreateOptions } from "./record-course";
import { getDefaultBaseCourseValues } from "./table-course-utils";

export interface CourseAssignmentCreateOptions {
    empty?: string;
}

const assignmentRecordCreator: RecordCreatorParameters<AssignmentFromCourseTable, CourseAssignmentCreateOptions> = {
    defaultValues: {
        ...getDefaultBaseCourseValues(),
        assign_data: {}
    },
    objectType: RecordTypes.ASSIGNMENT,
    createQuery: createCourse,
    updateQuery: updateCourse,
    deleteQuery: deleteCourse,

    getDeleteItemParameters: function (data: AssignmentFromCourseTable) {
        return {
            id: data.id,
            courseID: data.courseID,
            userEmail: data.userEmail,
            fieldType: data.fieldType
        }
    },

    onAfterCreatedModifyData: function (data: AssignmentFromCourseTable,
        createOptions: CourseAssignmentCreateOptions,
        self: AssignmentRecord) {
        self.data().fieldType = FieldType.ASSIGNMENT;
    },

    onBeforeSaveToDB: function (data: AssignmentFromCourseTable, self: AssignmentRecord) {
        return {
            ...data,
            assign_data: JSON.stringify(self.data().assign_data)
        };
    },

    onAfterRecordWrapFromDB: function (data: AssignmentFromCourseTable, self: AssignmentRecord) {
        return;
    }
};

setRecordCreator(RecordTypes.ASSIGNMENT, assignmentRecordCreator);

/**
 * Class used to hold, update, and create database
 * Assignment items.
 * */
export class AssignmentRecord extends WrappedRecord<AssignmentFromCourseTable, CourseAssignmentCreateOptions>{
    constructor() {
        super(RecordTypes.ASSIGNMENT)
    }

    static loadFromJSON(data: Record<string, unknown>) {
        const newBook = new AssignmentRecord();
        Object.assign(newBook, data);
        return newBook;
    }
}
