<template>
  <div v-if="isFinished"
       class="pagination float-end">
    <span class="border page-item page-link">
      {{ (score) + '%' }}
    </span>
    <i class="bi bi-check" />
    <span v-tooltip.tooltip="'View Previous Answers.'"
          class="border page-item page-link"
          @click="$emit('update:showPreviousAnswers', !showPreviousAnswers)">
      <i class="bi bi-caret-down"
         :class="showPreviousAnswers?'bi-caret-up':'bi-caret-down'" />
    </span>
  </div>
  <div v-else
       class="pagination float-end">
    <span v-tooltip.tooltip="'Maximum Number of Attempts Left'"
          class="border page-item page-link">
      {{ maxNrAttempts - nrAttemptsMade }}
    </span>
    <span v-tooltip.tooltip="'Percent Lost Per Wrong Answer'"
          class="border page-item page-link">
      {{ lossPerGuess + '%' }}
    </span>
    <span v-tooltip.tooltip="'Maximum achievable score.'"
          class="border page-item page-link"
          title="Maximum achievable score.">
      {{ (maxPossibleScore - nrAttemptsMade*lossPerGuess) + '%' }}
    </span>
    <span v-tooltip.tooltip="'View Previous Answers.'"
          class="border page-item page-link"
          @click="$emit('update:showPreviousAnswers', !showPreviousAnswers)">
      <i class="bi bi-caret-down"
         :class="showPreviousAnswers?'bi-caret-up':'bi-caret-down'" />
    </span>
    <BButton class="btn btn-success"
             label="Submit"
             @click="$emit('click')" />
  </div>
</template>

<script lang="ts">
  import BButton from "@/components/bootstrap/b-button.vue";
  import { defineComponent } from "vue";

  export default defineComponent({
    components: { BButton },
    props: {
      lossPerGuess: {
        type: Number,
        required: true,
      },
      maxPossibleScore: {
        type: Number,
        required: true,
      },
      nrAttemptsMade: {
        type: Number,
        required: true,
      },
      maxNrAttempts: {
        type: Number,
        required: true,
      },
      score: {
        type: Number,
        default: undefined,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
      showPreviousAnswers: {
        type: Boolean,
        required: true
      }
    },
    emits: ["click", "update:showPreviousAnswers"],
    setup() {
      return {};
    },
  });
</script>
<style lang="scss" scoped>
  @import "../../../bootstrap/tooltip/b-tooltip.scss";
</style>