import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import { HitSequence } from "./HitSequence";
import { PortInternals } from "../instruments/Electronic/CordConnector";
import { BaseRenderedObject } from '../primatives/BaseRenderedObject';
import { Nullable } from 'babylonjs/types';

export enum ControlResponseRequested {
    CANCEL,
    OPEN_VALVE_SLIDER,
    PRIMARY_PRESSURE_CONNECTION,
    SECONDARY_PRESSURE_CONNECTION,
    REMOVE_CONNECTION,
    SELECT_FLUID,
    FORCE_CONNNECTION,
    SECONDARY_FORCE_CONNECTION,
    SELECT_JET_DEFLECTOR_GEOM
}

export interface HitData {
    type?: ControlResponseRequested,
    port?: string,
    primaryRenderedObject?: BaseRenderedObject
}


export interface DynamicGui {
    manager: Nullable<GUI.AdvancedDynamicTexture>,
    title: Nullable<GUI.TextBlock>
}

export enum ControlTypes {
    VALVE_SLIDER,
    SELECT_FLUID,
    SELECT_JET_DEFLECTOR_GEOM
}

export interface ControlPayloadData {
    properties: string[],
    port: PortInternals
}

export enum ControlPanelModes {
    WAITING,
    DISPLAY_OBJECT_CONTROL,
    PRIMARY_CONNECTION_SELECTED,
    SECONDARY_CONNECTION_SELECTED,
    DESELECT_SECONDARY_CONNECTION
}

export class Control_Base {
    public name: string;
    public scene: BABYLON.Scene;
    public gui: Nullable<GUI.AdvancedDynamicTexture> = null;

    public instrument: Nullable<BaseRenderedObject> = null;
    public baseOptions: any;
    public property = "";

    constructor(name: string, scene: BABYLON.Scene) {
        this.name = name;
        this.scene = scene;
    }


    public attachInstrument(instrument: BaseRenderedObject, property: string) {
        console.debug("Attach Instrument");
        this.instrument = instrument;
        this.property = property;
    }

    public respond(hitSequence: HitSequence,
        gui: DynamicGui,
        instrument: BaseRenderedObject,
        options: any,
        data: ControlPayloadData) {

        // create the context menu
        this.baseOptions = options;
        this.attachInstrument(instrument, data.properties[0]);
        //gui.title.text = "Slide to Open and Close the Valve";
        this.renderGUI();
    }

    public renderGUI() {
        return;
    }

    public closeGUI() {
        if (this.gui != null) {
            this.gui.dispose();
            this.gui = null;
        }
    }

    public createDoneButton() {
        const button = GUI.Button.CreateSimpleButton("button.done", "Done");
        button.widthInPixels = 80;
        button.heightInPixels = 40;
        button.cornerRadius = 4;

        button.color = "white";
        button.background = "blue";
        button.onPointerClickObservable.add(() => {
            this.closeGUI();
        });
        return button;
    }
}