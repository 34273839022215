
import { FormSchemaParser } from "@/components/formGenerator/form-schema-parser";
import { FormSchemaItem } from "@/components/formGenerator/form-schema.interface";
import { failable } from "@/components/global/response.interface";
import { GraphQLAPI, GraphQLResult, graphqlOperation } from '@aws-amplify/api-graphql';
import { BaseRecord } from "./database.interface";

export class DatabaseUtility {
    static createUpdated<T extends BaseRecord>(existingRecord: T, usingSchema: FormSchemaItem) {
        const jsonData: Record<string, unknown> = new FormSchemaParser(usingSchema).toJSON() as Record<string, unknown>;
        const newObject = { ...existingRecord, ...jsonData };
        return newObject;
    }

    static async executeOnDB<T extends BaseRecord>(command: string, inputRecord: T, errorText: string) {
        const result = await failable(() => {
            return GraphQLAPI.graphql(graphqlOperation(command, { input: inputRecord })) as Promise<GraphQLResult<Record<string, unknown>>>
        });

        if (result.hasError()) {
            console.error("Database Execute - Error on command:", command);
            console.error(" - Internal Error:", result.getErrorMessage());
            console.error(" - Error Text:", errorText);
            console.error(" - Input Record:", inputRecord);
        } else {
            console.debug("Execute on DB Successful: ", result.getData());
        }

        return result;
    }

    static async updateInDB<T>(command: string, inputRecord: T, errorText: string) {
        const objClone = { ...inputRecord };

        // strip createdAt and updatedAt from the clone before sending to the server
        delete (objClone as any).createdAt;
        delete (objClone as any).updatedAt;

        const result = await failable(() => {
            return GraphQLAPI.graphql(graphqlOperation(command, { input: objClone })) as Promise<GraphQLResult<Record<string, unknown>>>
        });

        if (result.hasError()) {
            console.error("Database Update - Error on command:", command);
            console.error(" - Internal Error:", result.getErrorMessage());
            console.error(" - Error Text:", errorText);
            console.error(" - Input Record:", inputRecord);
        }

        return result;
    }

    static getCurrentDate() {
        const newDate = new Date();
        newDate.setMinutes(0, 0, 0);
        return newDate.toISOString();
    }
}