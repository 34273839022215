import { DirectiveBinding } from 'vue';

function init(el: HTMLElement, binding: DirectiveBinding) {
   const position = binding.arg || "top";
   const tooltipText = binding.value || "test";
   el.setAttribute("position", position);
   el.setAttribute("tooltip", tooltipText);
}

export const tooltipDirective = {
   name: "tooltip",
   directive: {
      mounted(el: HTMLElement, binding: DirectiveBinding): void {
         init(el, binding);
      },
      updated(el: HTMLElement, binding: DirectiveBinding): void {
         init(el, binding);
      }
   }
}