
  import { defineComponent, PropType } from "vue";

  export interface DropOption {
    label: string,
    value: number,
    icon: string
  }

  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: undefined,
      },
      label: {
        type: String,
        default: undefined,
      },
      show: {
        type: Boolean,
        default: true,
      },
      options: {
        type: Array as PropType<DropOption[]>,
        default: () => []
      },
      onClickCallback: {
        type: Function,
        requited: true,
        default: () => {
          return;
        },
      },
    },
    emits: ["click"],
    setup(props, context) {
      const onClick = (index: number) => {
        context.emit("click", index);
      }

      return { onClick };
    },
  });
