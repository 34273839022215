
  import BButton from '@/components/bootstrap/b-button.vue'
  import { bTableEvent, bTableFields } from '@/components/bootstrap/table/b-table.interface';
  import BTable from '@/components/bootstrap/table/b-table.vue';

  import { useDatabaseMappings } from '@/store/connectors/databaseMapping';
  import { defineComponent, onMounted, Ref, ref } from 'vue'
  import * as paper from 'paper';
  import usePaperJS from '../usePaperJS';
  import { TextRecognitionSearchTypes } from '@/store/database/TextRecognition/table-textrecognition.interface';
  import { extractCharacterAnalysisFromEquations, extractEquationAnalysisFromEquations, StrokeAnalysisType } from './useEqnCharacterAnalysis';
  import { flattenStrokes } from './textUtilities';
  import { downloadArrayOfObjectsData, downloadData, downloadJSON } from '@/utils/downloadData';
  import { TypeGuard } from '../../mathjs/Type-guards';

  export default defineComponent({
    components: { BButton, BTable },
    async setup() {
      const scope: Ref<paper.PaperScope | null> = ref(null);

      const { setScope } = usePaperJS(new paper.Size(800, 800));
      const { getAllHandwrittenChars } = useDatabaseMappings();
      const strokeAnalysis: Ref<StrokeAnalysisType[]> = ref([]);
      const equationAnalysis: Ref<{ eqnString: string, analysis: StrokeAnalysisType[] }[]> = ref([]);

      onMounted(async () => {
        console.log("Mount Notebook.")
        scope.value = new paper.PaperScope();
        scope.value?.setup("textCanvas");
        setScope(scope.value);

        const dbEquations = (await getAllHandwrittenChars(TextRecognitionSearchTypes.BY_EQN));

        // character stroke data
        const equations = dbEquations.map((e) => JSON.parse(e.data().meta));
        const characterAnalysis = extractCharacterAnalysisFromEquations(equations);
        strokeAnalysis.value = flattenStrokes(characterAnalysis);

        // equation data
        const equationRecord = extractEquationAnalysisFromEquations(equations);

        dbEquations.forEach((e, eindex) => {
          equationAnalysis.value.push({ eqnString: e.data().character, analysis: equationRecord[eindex] });
        })

        console.log({ ea: equationAnalysis.value });
        clearViewport();
      });



      const downloadOriginal = () => {
        downloadJSON(strokeAnalysis.value as unknown as Record<string, unknown>[],
          "test.txt", "text/plain");

        downloadJSON(equationAnalysis.value as unknown as Record<string, unknown>[],
          "equations.txt", "text/plain");

        return;
      }


      const fields: Partial<bTableFields>[] = [
        {
          key: "label",
          freezeColumn: true,
          label: "Char",
          sortable: true,
          includeInFilter: true,
          class: "",
        },
        {
          key: "strokeLabel",
          freezeColumn: true,
          label: "Stroke",
          sortable: true,
          includeInFilter: true,
          class: "",
        },
        { key: "nrStrokes", label: "Nr Strokes" },
        { key: "height", label: "height" },
        { key: "width", label: "width" },

        { key: "strokeIndexInLayer", label: "Strokes Index" },
        { key: "cumulativeNegCurvature", label: "Pos Curvature" },
        { key: "cumulativePosCurvature", label: "Neg Curvature" },
        { key: "endToEndX", label: "E2EX" },
        { key: "endToEndY", label: "E2EY" },
        { key: "nrCurvatureChanges", label: "Nr Curv Chng" },
        { key: "nrSelfIntersections", label: "Nr Self Inter" },
        { key: "nrXDirSwitch", label: "NrXSwitch" },
        { key: "nrYDirSwitch", label: "NrYSwitch" },
        { key: "rEEToL", label: "rEEToL" },
        { key: "rEigenvalues", label: "rEigenvalues" },
        { key: "xCentroidLocRelToBox", label: "xC rel Box" },
        { key: "yCentroidLocRelToBox", label: "yC rel Box" },
      ];

      const clickOnStroke = (event: bTableEvent): void => {
        (event.payload.rowData.stroke as paper.Path).visible = true;
      }

      const clearViewport = () => {
        strokeAnalysis.value.forEach((s) => {
          if (!TypeGuard.isNullOrUndefined(s.stroke)) {
            s.stroke.visible = false;
          }
        });
      }

      return {
        clearViewport,
        clickOnStroke,
        fields,
        downloadOriginal,
        strokeAnalysis
      }
    }
  })
