<template>
  <PageIterator :key="pageId"
                :course-id="courseId"
                :text-id="textId"
                :page-id="pageId"
                :root-page-id="rootPageId"
                :editable="true"
                :nestable="false"
                route-to-destination="QuestionBankEditor"
                @addItem="addItemAndUpdateTOC"
                @deleteItem="deletePageAndUpdateTOC"
                @changeItemName="changeItemNameAndUpdateTOC"
                @onReorderItems="updateTOC">
    <template #display="{ data }">
      <QuestionCreatorForm v-if="pageHasComponent(data)"
                           :key="data?.data().id"
                           :data-page="data"
                           @saveQuestion="updateLocalAndDBPage" />
    </template>
  </PageIterator>
</template>

<script lang="ts">
  import { bookEditorProps, pageEditorProps, usePageEditorFunctions } from "./usePageEditorFunctions";
  import { defineComponent } from "vue";
  import PageIterator from "../components/p-page-iterator.vue";
  import QuestionCreatorForm from "./QuestionCreatorForm.vue";
  import router from "@/router";


  export default defineComponent({
    name: "QuetionBankEditor",
    components: { PageIterator, QuestionCreatorForm },
    props: {
      ...bookEditorProps,
      ...pageEditorProps
    },
    async setup(props) {
      function routeTo(inputPageId: string) {
        router.push({
          name: "QuestionBankEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
            rootPageId: props.rootPageId,
          },
        });
      }

      return {
        ...usePageEditorFunctions(routeTo)
      }
    },
  });
</script>

<style scoped>
</style>