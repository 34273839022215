
  import BImg from "@/components/bootstrap/b-img.vue";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { CourseRecord } from "@/store/database/Course/record-course";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import { defineComponent } from "vue";

  export default defineComponent({
    components: { BImg },
    props: {
      courseId: {
        type: String,
        required: true,
      },
    },
    async setup(props) {
      const { userName } = useUserStore();

      if (!userName) return null;

      const {
        error,
        course,
        laboratory,
      } = await useDatabaseMappings().getCourseMaterials(userName, props.courseId);
      console.debug("Course - Pulled Course Materials", course);

      const courseData = course?.data();
      if (!courseData) return null;

      const onLaboratoryClick = (clickLab: { _data: { textID: string, id: string } }): void => {
        console.log(clickLab._data)
        router.push({
          name: "AssignmentViewer",
          params: {
            courseId: props.courseId,
            textId: clickLab._data.textID,
            rootPageId: laboratory?.getRootPageID() ?? "",
            pageId: clickLab._data.id,
          },
        });
      };

      const { resolveURL } = useS3();


      const listOfLaboratories = laboratory?.getFirstLevelPages() ?? [];

      return {
        error,
        resolveURL,
        course: course as CourseRecord,
        listOfLaboratories,
        userName,
        onLaboratoryClick
      };
    },
  });
