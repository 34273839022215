export enum bSortDirections {
   ASCENDING,
   DESCENDING
}

export interface bTableEvent {
   type: string,
   payload: {
      rowIndex: number,
      colIndex: number,
      rowData: Record<string, unknown>,
      field: bTableFields,
   }
}
export interface bTableFields {
   key: string,
   label: string,
   sortable: boolean,
   sortDirection: bSortDirections,
   _currentSortDirection: bSortDirections,
   class: string,
   includeInFilter: boolean,
   freezeColumn: boolean,
   formatter: (
      value: unknown,
      key: string,
      item: Record<string, unknown>
   ) => string,
   dynamicItemClass: (
      value: unknown,
      key: string,
      item: Record<string, unknown>
   ) => string,
   dynamicHTML: (
      value: unknown,
      key: string,
      item: Record<string, unknown>
   ) => string,
   meta: Record<string, unknown>
}