
  import { defineComponent } from 'vue'
  import NotebookPage from '../Notebook/NotebookPage.vue'

  export default defineComponent({
    components: { NotebookPage },
    setup() {
      return {}
    }
  })
