<template>
  <section>
    <nav>
      <ul class="pagination">
        <li class="page-item d-none d-lg-inline"
            @click="setPageNr(1)">
          <i class="page-link bi bi-chevron-double-left" />
        </li>
        <li class="page-item"
            @click="setPageNr(pageNr-1)">
          <i class="page-link bi bi-chevron-left" />
        </li>
        <li class="page-item">
          <div class="page-link">
            <span class="d-none d-lg-inline">Page </span>
            <span>{{ pageNr }} </span>
            <span class="d-none d-md-inline"> of {{ getMaxNrPages() - (excludeFirstPage ? 1: 0) }} </span>
          </div>
        </li>
        <li class="page-item"
            @click="setPageNr(pageNr+1)">
          <i class="page-link bi bi-chevron-right" />
        </li>
        <li class="page-item d-none d-lg-inline"
            @click="setPageNr(100000)">
          <i class="page-link bi bi-chevron-double-right" />
        </li>
      </ul>
    </nav>
  </section>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from "vue";
  import usePagination from "../use/usePagination";

  export default defineComponent({
    props: {
      pageNr: {
        type: Number,
        default: 0,
      },
      maxPageNrs: {
        type: Function as PropType<() => number>,
        required: true,
      },
      excludeFirstPage: {
        type: Boolean,
        default: true
      }
    },
    emits: ["update:pageNr", "change"],
    setup(props, context) {
      const { activePageNr, setPageNr, getMaxNrPages } = usePagination(
        1,
        ref(props.maxPageNrs),
        () => {
          context.emit("update:pageNr", activePageNr.value);
          context.emit("change", activePageNr.value);
        }
      );

      return {
        activePageNr,
        setPageNr,
        getMaxNrPages,
      };
    },
  });
</script>

<style scoped>
</style>