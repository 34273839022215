
  import { defineComponent } from "vue";
  import Page from "../../components/contentGenerator/pages/Page.vue";
  import PageIterator from "../components/p-page-iterator.vue";

  export default defineComponent({
    name: "TextbookViewer",
    components: { Page, PageIterator },
    props: {
      courseId: {
        type: String,
        required: true,
      },
      textId: {
        type: String,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      rootPageId: {
        type: String,
        required: true,
      },
    },
    async setup() {
      return {};
    },
  });
