<template>
  <div class="container">
    <div class="row g-0 align-items-stretch"
         :class="barClass">
      <div v-for="item in buttons"
           v-show="item.show"
           :key="item.label"
           :class="colClass">
        <BCard :footer="item.label"
               width=""
               class="text-center button h-100"
               :class="cardClass"
               @click="emitTyped('click', item.emitStr ? item.emitStr: '')">
          <template #body>
            <i class="bi fs-2"
               :class="item.icon"
               scale="2" />
          </template>
        </BCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import BCard from "@/components/bootstrap/b-card.vue";
  import useEvents from "@/components/use/useEvents";
  import { defineComponent, EmitsOptions, PropType, SetupContext } from "vue";

  export interface buttonBarOptions {
    label: string;
    icon: string;
    show: boolean;
    emitStr: string;
  }

  export default defineComponent({
    components: { BCard },
    props: {
      buttons: {
        type: Object as PropType<Partial<buttonBarOptions>[]>,
        required: true,
      },
      cardClass: {
        type: String,
        default: ""
      },
      colClass: {
        type: String,
        default: "col-lg-4 col-md-6 col-sm-12 p-1"
      },
      barClass: {
        type: String,
        default: ""
      }
    },
    emits: ["click"],
    setup(prop, context: SetupContext<EmitsOptions>) {
      const { emitTyped } = useEvents(context);
      return {
        emitTyped,
      };
    },
  });
</script>

<style scoped>
</style>