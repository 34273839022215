
  import { defineComponent, EmitsOptions, PropType, ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import { generateFixedMultipleChoice } from "./user-response-fixed-selection";
  import { generateNumericalMultipleChoice } from "./user-response-multiple-choice";
  import SubmissionButtonGroup from "./SubmissionButtonGroup.vue";
  import {
    UserResponseSetupData,
    MultipleChoiceType,
    AnswerState,
  } from "./user-response.interface";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import useEvents from "@/components/use/useEvents";

  export default defineComponent({
    components: { SubmissionButtonGroup },
    props: {
      partNr: {
        type: Number,
        required: true,
      },
      questionPartData: {
        type: Object as PropType<UserResponseSetupData>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecord: {
        type: Object as PropType<SumissionQuestionPart>,
        required: true,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const mct = ref(props.questionPartData.multipleChoiceType);
      const choice = ref(-1);
      const ansState = ref(AnswerState.NOT_CHECKED);

      const evaluateStatement = (statement: string) => {
        return props.questionModel.evaluateStatement(statement).getData();
      };

      const partLabel = (index: number) => {
        return index !== -1 ? "Part " + (index + 1) + ". " : "";
      };

      const { array, index } =
        mct.value === MultipleChoiceType.SPECIFY_ANSWERS
          ? generateFixedMultipleChoice(
            props.questionPartData,
            props.questionModel
          )
          : generateNumericalMultipleChoice(
            props.questionPartData,
            props.questionModel
          );

      const correctIndex = ref(index);
      const options = ref(array);

      const { emitEvent } = useEvents(context);

      const reRender = ref(false);
      const selectedColor = ref("bg-primary");

      const select = (index: number) => {
        selectedColor.value = "bg-primary";
        choice.value = index;
      };

      const evalulateCurrentState = () => {
        reRender.value = !reRender.value;
        const isCorrect = choice.value === correctIndex.value;
        if (isCorrect) {
          selectedColor.value = "bg-success";
          ansState.value = AnswerState.CORRECT;
        } else {
          selectedColor.value = "bg-danger";
          ansState.value = AnswerState.WRONG;
        }
      };

      const onSubmit = (): void => {
        evalulateCurrentState();
        emitEvent("submit", {
          type: "submit",
          payload: {
            entry: JSON.stringify(choice.value),
            answerState: ansState.value,
          },
        });
      };

      return {
        reRender,
        onSubmit,
        partLabel,
        evaluateStatement,
        correctIndex,
        options,
        choice,
        select,
        selectedColor
      };
    },
  });
