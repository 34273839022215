
  import { defineComponent, PropType } from "vue";
  import { FormSchemaItem } from "../form-schema.interface";
  import { FormDisplayBehavior } from "../form.interface";

  export default defineComponent({
    name: "FormInputLayout",
    props: {
      fid: {
        type: String,
        required: true,
      },
      schemaitem: {
        type: Object as PropType<FormSchemaItem>,
        default: null,
      },
      displaybehavior: {
        type: Object as PropType<FormDisplayBehavior>,
        default: () => {
          return {
            displayHints: false,
            hasChanges: false,
            showJSONButton: false,
            rootTitle: "",
            buttonAlign: "top",
          };
        },
      },
      feedback: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const getInputColumnClasses = (): string => {
        switch (props.schemaitem?.properties?.labelAlign) {
          case "right":
          case "left":
            if (props.schemaitem?.properties?.labelCols) {
              return "col-" + (12 - props.schemaitem.properties.labelCols);
            }
            break;
          case "top":
          case "bottom":
            return "col-12";
        }
        return "col-6";
      };

      return {
        getInputColumnClasses,
      };
    },
  });
