import math from 'mathjs';
import { MjsMath } from './math-core';
import { mjsDenseMatrix, mjsUnit } from './math-types';


export enum MATHJS_TYPE {
   NUMBER,
   UNIT,
   MATRIX,
   NOT_DEFINED,
   STRING
}

export class TypeGuard {
   static hasProp(obj: unknown, name: string) {
      return Object.prototype.hasOwnProperty.call(obj, name);
   }

   static safePullProp(obj: unknown, field: string, defaultVal: unknown) {
      if (!this.hasProp(obj, field))
         return defaultVal;
      else {
         return (obj as any)[field]
      }
   }

   static isMathJSNode(x: unknown): x is math.MathNode {
      return MjsMath.getMathJS().isNode(x);
   }

   static isMatrix(x: unknown): x is mjsDenseMatrix {
      return MjsMath.getMathJS().typeOf(x) === "Matrix";
   }

   static isUnit(x: unknown): x is mjsUnit {
      return MjsMath.getMathJS().typeOf(x) === "Unit";
   }

   static isNullOrUndefined(x: unknown): x is null | undefined {
      return x === null || x === undefined || typeof x === undefined;
   }

   static isArray(x: unknown): x is any[] {
      return Array.isArray(x);
   }

   static isNumber(x: unknown): x is number {
      return typeof x === "number";
   }

   static isString(x: unknown): x is string {
      return typeof x === "string";
   }

   static isPopulatedString(x: unknown): x is string {
      return !this.isNullOrUndefined(x) && this.isString(x) && x.length > 0;
   }

   static isFunction(obj: any): obj is (() => unknown) {
      return !!(obj && obj.constructor && obj.call && obj.apply);
   }

   static getMathType(x: unknown) {
      if (this.isNumber(x)) return MATHJS_TYPE.NUMBER;
      if (this.isUnit(x)) return MATHJS_TYPE.UNIT;
      if (this.isString(x)) return MATHJS_TYPE.STRING;
      if (this.isMatrix(x)) return MATHJS_TYPE.MATRIX;

      return MATHJS_TYPE.NOT_DEFINED;
   }
}