
  import {
    defineComponent,
    EmitsOptions,
    PropType,
    Ref,
    ref,
    SetupContext,
  } from "@vue/runtime-core";
  import FormInputGroup from "./form-input-group.vue";
  import FormTab from "./form-tab.vue";
  import FormRootButtonRow from "./form-root-buttonRow.vue";

  import { formItemMixinProps } from "../mixins/form.mixins";
  import { FormButtonAction, FormErrorEvent } from "../form.interface";

  import { onKeyStroke } from "@vueuse/core";
  import { FormErrorTypes, ValidationHooks } from "../form-schema.interface";
  import useEvents from "../../use/useEvents";
  import { useValidation } from "../use/useValidation";

  export default defineComponent({
    components: {
      FormInputGroup,
      FormRootButtonRow,
      FormTab
    },

    props: {
      ...formItemMixinProps,
      isLoading: {
        type: Boolean,
        default: false,
      },
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        default: () => {
          return [];
        },
      },
      enterKeyAction: {
        type: Object as PropType<FormButtonAction>,
        default: null,
      },
      validationHooks: {
        type: Object as PropType<ValidationHooks>,
        default: () => {
          return {
            onBeforeValidate: () => { return; },
            onAfterValidate: () => { return; }
          }
        }
      }
    },
    emits: ["btnPress", "change", "error"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.debug("Load Form Root")
      const jsonKey: Ref<number> = ref(0);

      const changeTypeSelector = (newData: any) => {
        console.log({ newData });
        // eslint-disable-next-line vue/no-mutating-props
        props.schemaitem.value = newData;
        console.log(props.modelValue)
      }

      return {
        changeTypeSelector,
        jsonKey,
        ...useValidation(props as any, context, props.validationHooks),
        ...useEvents(context),
      };
    },
    mounted() {
      onKeyStroke("Enter", (e) => {
        if (this.enterKeyAction) {
          this.onBtnPress(this.enterKeyAction);
        }
      });
    },
    methods: {
      onChange() {
        this.$emit("change");
        this.jsonKey++;
      },
      onBtnPress(btnAction: FormButtonAction) {
        if (!btnAction.enabledWhenFormIsValid || this.isSchemaValid(this.schemaitem)) {
          this.emitEvent("btnPress", {
            type: btnAction.triggerEvent.type,
            payload: {
              schemaItem: this.schemaitem,
            },
          });
        } else {
          this.emitError("error", {
            type: FormErrorTypes.INVALID,
            message: "Unable to process button because form is not validated. ",
          } as FormErrorEvent);
          console.log("Form Error Hints:", this.schemaitem._validationHints);
        }
      },
    },
  });
