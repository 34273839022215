<template>
  <div class="m-2 text">
    <BTabs :is-vertical="true"
           menu-width="150px">
      <BTab title="Terms and Conditions">
        <terms-and-conditions class="text"
                              title="Terms" />
      </BTab>
      <BTab title="Privacy Policy">
        <privacy-statement class="text"
                           title="Privacy" />
      </BTab>
    </BTabs>
  </div>
</template>

<script lang="ts">
  import BTab from "@/components/bootstrap/b-tab.vue";
  import BTabs from "@/components/bootstrap/b-tabs.vue";
  import { defineComponent } from "vue";
  import PrivacyStatement from "./PrivacyStatement.vue";
  import TermsAndConditions from "./TermsAndConditions.vue";

  export default defineComponent({
    components: { PrivacyStatement, TermsAndConditions, BTabs, BTab },
    setup() {
      console.debug("tabs created");
      return {};
    },
  });
  PrivacyStatement;
</script>

<style scoped>
  @media only screen and (max-width: 500px) {
    .text {
      font-size: 10px;
    }
  }
</style>