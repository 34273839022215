<template>
  <div id="course-tabContent"
       class="tab-content">
    <div id="pills-availability"
         class="tab-pane fade show active"
         role="tabpanel"
         aria-labelledby="pills-home-tab">
      <FormRoot :key="formKey"
                :is-loading="isLoading"
                :schemaitem="schema"
                :button-list="buttonList"
                :triggers="triggers"
                :displaybehavior="displaybehavior"
                @change="onChange"
                @btnPress="onPress" />
    </div>
    <div id="pills-details"
         class="tab-pane fade"
         role="tabpanel"
         aria-labelledby="pills-profile-tab">
      Details
    </div>
    <div id="pills-topics"
         class="tab-pane fade"
         role="tabpanel"
         aria-labelledby="pills-contact-tab" />
  </div>
</template>

<script lang="ts">
  import { FormButtonAction } from '@/components/formGenerator/form.interface';
  import useReactiveForm from '@/components/use/useReactiveForm';
  import useSchema from '@/components/use/useSchema';
  import useCourseMaterials from '@/store/connectors/useCourseMaterials';
  import { defineComponent } from 'vue'
  import { getCourseAvailabilitySchema } from '@/store/database/Course/record-course-schema';
  import { FormSchemaItem } from '@/components/formGenerator/form-schema.interface';
  import FormRoot from '@/components/formGenerator/components/form-root.vue';
  import { useCourseEditorFunctions } from '@/views/instructor/userCourseEditorFunctions';

  export default defineComponent({
    components: { FormRoot },
    props: {
      courseId: {
        type: String,
        required: true,
        default: ""
      }
    },
    async setup(props) {
      const { schema_getJSON } = useSchema();
      const buttonList: FormButtonAction[] = [
        {
          class: 'btn-primary m-1 save',
          label: 'Hints',
          triggerEvent: { type: 'hints', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: false
        },
        {
          class: 'btn-success',
          label: 'Save',
          triggerEvent: { type: 'save', payload: null },
          isEnabled: true,
          enabledWhenFormIsValid: true
        }
      ];


      const onChange = () => {
        buttonList[0].isEnabled = true;
        console.log("Course Record changed.")
      };


      const { getCourseRecord } = useCourseMaterials();
      const courseRecord = await getCourseRecord(props.courseId, true);
      const { updateLocalAndDBCourse } = useCourseEditorFunctions();

      const updateCourseFromFormData = async (formSchema: FormSchemaItem) => {
        if (courseRecord) {
          console.log("Save");
          courseRecord?.updateFromSchema(formSchema);
          await updateLocalAndDBCourse(courseRecord);
        }
      };

      const onPress = (v: { type: string; schemaItem: FormSchemaItem }): void => {
        console.log("press", v.type)

        switch (v.type) {
          case "save":
            console.log("Save pressed")
            buttonList[0].isEnabled = false;
            triggers.validate = !triggers.validate;
            updateCourseFromFormData(schema.value);
            //context.emit("saveQuestion", props.dataPage);
            break;
          case "hints":
            displaybehavior.displayHints = !displaybehavior.displayHints;
            break;
          case "dialog":
            confirmDialog?.value
              ?.open("test", "test", {})
              .then((isConfirmed: boolean) => {
                console.log(isConfirmed);
              });
            break;
        }
      };

      const {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
      } = useReactiveForm(getCourseAvailabilitySchema(props.courseId),
        courseRecord?.data() as unknown as Record<string, unknown>);
      displaybehavior.showJSONButton = true;

      return {
        displaybehavior,
        triggers,
        isLoading,
        rawSchema,
        schema,
        isFormValid,
        formKey,
        confirmDialog,
        buttonList,
        onChange,
        onPress
      }
    }
  })
</script>

<style scoped>
</style>