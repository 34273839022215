<template>
  <suspense>
    <template #default>
      <div class="container-md">
        <div class="row">
          <div class="col">
            <h1 class="text-center">
              My Courses
            </h1>
            <hr class="d-md-none">
          </div>
        </div>
        <div class="row g-0">
          <div v-for="regInfo in registeredCourses"
               :key="regInfo.course.data().name"
               class="col align-items-stretch d-flex justify-content-center m-1 m-sm-2">
            <BCard :title="regInfo.course.data().name"
                   :description="regInfo.course.data().description"
                   :img-src="resolveURL(regInfo.course.data().id, regInfo.course.data().co_pictureURL)"
                   class="h-100"
                   @click="onClick(regInfo.course.data().id)" />
          </div>
          <div class="col align-items-stretch d-flex justify-content-center m-2">
            <BCard title="Register"
                   description="Register for a new course."
                   :img-src="resolveURL('e67a4452-9218-4fa9-b1e0-1d4203b34a70', 'photos/Slide15.JPG')"
                   class="h-100"
                   @click="gotoRegistration()" />
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      <div>Loading Course List...</div>
    </template>
  </suspense>
</template>

<script lang="ts">
  import BCard from "@/components/bootstrap/b-card.vue";
  import { useDatabaseMappings } from "@/store/connectors/databaseMapping";
  import { useUserStore } from "@/store/vueStore/useUserStore";
  import useS3 from "@/components/use/useS3";
  import router from "@/router";



  export default {
    name: "StudentHome",
    components: { BCard },
    async setup() {
      const { userName } = useUserStore();
      if (!userName) return;

      const registeredCourses =
        await useDatabaseMappings().getRegisteredCoursesForUser(userName);

      const onClick = (courseId: string) => {
        router.push({ name: "StudentCourse", params: { courseId } });
      };

      const gotoRegistration = () => {
        router.push({ name: "AvailableCourseList" });
      }

      const { resolveURL } = useS3();

      return {
        registeredCourses,
        onClick,
        resolveURL,
        gotoRegistration
      };
    },
  };
</script>
