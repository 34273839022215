<template>
  <BModalView v-if="showModal"
              v-model:show="showModal"
              :title="title">
    <template #body>
      <FormRoot :schemaitem="schema"
                @change="onChange" />
    </template>
    <template #footer>
      <b-button class="btn btn-primary"
                label="Cancel"
                @click="onCancel" />
      <b-button class="btn btn-success"
                label="OK"
                @click="handleOK" />
    </template>
  </BModalView>
</template>

<script lang="ts">
  import BButton from '@/components/bootstrap/b-button.vue'
  import BModalView from '@/components/bootstrap/b-modal-view.vue'
  import FormRoot from '@/components/formGenerator/components/form-root.vue'
  import { FormSchemaItem } from '@/components/formGenerator/form-schema.interface'
  import useSchema from '@/components/use/useSchema'
  import { useVModel } from '@vueuse/core'
  import { defineComponent, PropType } from 'vue'

  export default defineComponent({
    components: { FormRoot, BModalView, BButton },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      schema: {
        type: Object as PropType<FormSchemaItem>,
        required: true
      },
      onOK: {
        type: Function,
        default: () => { return; }
      },
      title: {
        type: String,
        default: ""
      },
    },
    setup(props, { emit }) {
      const { schema_getJSON } = useSchema();
      const showModal = useVModel(props, 'show', emit);
      const onChange = () => { return; };

      const onCancel = () => {
        showModal.value = false;
      }

      const handleOK = () => {
        const data = schema_getJSON(props.schema);
        console.log(data)
        props.onOK(data);
      }

      return {
        handleOK,
        showModal,
        onChange,
        onCancel
      };
    }
  })
</script>

<style scoped>
</style>