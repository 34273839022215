
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "BOverlay",
    props: {
      show: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {};
    },
  });
