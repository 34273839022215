<template>
  <section>
    <nav>
      <ul class="pagination">
        <li class="page-item"
            @click="activePageNr = Math.max(activePageNr-1, 1)">
          <i class="page-link bi bi-chevron-double-left" />
        </li>
        <li v-for="index in nrPages"
            :key="index"
            class="page-item"
            :class="(index == activePageNr)?'active':''">
          <a class="page-link"
             @click="activePageNr = index">
            {{ index }}
          </a>
        </li>
        <li class="page-item"
            @click="activePageNr = Math.min(activePageNr+1, nrPages)">
          <i class="page-link bi bi-chevron-double-right" />
        </li>
        <li class=" d-none d-lg-block">
          <select v-model="activeNrRecordsPerPage"
                  class="form-select">
            <option v-for="item in perPageOptions"
                    :key="item"
                    :value="item">
              {{ item }}
            </option>
          </select>
          <!-- <span> &nbsp; per page </span> -->
        </li>
      </ul>
    </nav>
  </section>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    EmitsOptions,
    onMounted,
    Ref,
    ref,
    SetupContext,
    watch,
  } from "vue";

  export default defineComponent({
    props: {
      nrRecords: {
        type: Number,
        required: true,
      },
      nrRecordsPerPage: {
        type: Number,
        default: 5,
      },
    },
    emits: ["pageRangeChange"],
    setup(props, context: SetupContext<EmitsOptions>) {
      let activePageNr: Ref<number> = ref(1);
      const activeNrRecordsPerPage = ref(props.nrRecordsPerPage);

      const nrPages = computed(() => {
        return Math.ceil(props.nrRecords / activeNrRecordsPerPage.value);
      });

      const perPageOptions = [5, 10, 20, 50, 100];

      const emitRange = (): void => {
        context.emit("pageRangeChange", [
          (activePageNr.value - 1) * activeNrRecordsPerPage.value,
          activePageNr.value * activeNrRecordsPerPage.value,
        ]);
      };

      watch(
        () => props.nrRecords,
        () => {
          activePageNr.value = 1;
        }
      );

      watch(activeNrRecordsPerPage, () => {
        emitRange();
      });

      watch(activePageNr, () => {
        emitRange();
      });

      onMounted(() => {
        activePageNr.value = 1;
        emitRange();
      });

      return {
        activePageNr,
        nrPages,
        perPageOptions,
        activeNrRecordsPerPage,
      };
    },
  });
</script>

<style scoped>
</style>