<template>
  <button v-if="show"
          type="button"
          :disabled="disabled || isLoading"
          @click="$emit('click')">
    <span v-if="isLoading"
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true" />
    <span>{{ label }}</span>
    <i v-if="icon"
       class=" bi"
       :class="icon" />
  </button>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: undefined,
      },
      label: {
        type: String,
        default: undefined,
      },
      show: {
        type: Boolean,
        default: true,
      },
      onClickCallback: {
        type: Function,
        requited: true,
        default: () => {
          return;
        },
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ["click"],
    setup() {
      return {};
    },
  });
</script>

<style scoped>
</style>