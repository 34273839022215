<template>
  <div class="h2 overlay d-flex justify-content-center">
    <div class="p-3 m-3">
      {{ label }}
      <div class="ms-2 spinner-border text-success"
           role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      label: {
        type: String,
        default: "Loading ..."
      }
    },
    setup() {

      return {}
    }
  })
</script>

<style scoped>
</style>