import { EquationTemplateDefinition, SubstitutionType } from "./equation-template-helpers";

const equationTemplates: EquationTemplateDefinition[] = [{
   id: "c1",
   name: "d",
   description: "",
   katex: "x = h \\cos(\\theta)",
   help: "# Introduction",
   variables: { "x": "m", "h": "m", "theta": "radians" },
   equation: (sub: SubstitutionType) => `${sub["x"]} = ${sub["h"]} * cos(${sub["theta"]})`
},
{
   id: "c2",
   name: "d",
   description: "",
   katex: "\\theta = acos{ \frac{x}{h} }",
   help: "# Introduction",
   variables: { "x": "m", "h": "m", "theta": "radians" },
   equation: (sub: SubstitutionType) => `${sub["theta"]} = acos(${sub["x"]}/${sub["h"]})`
},
{
   id: "a1",
   name: "Area of a cirle as a function of radius",
   katex: "A = \\pi R^2",
   description: "The area of a circle computed from the radius.",
   help: "# Introduction",
   variables: { "A": "cm^2", "R": "cm" },
   equation: (sub: SubstitutionType) => `${sub["A"]} = pi * ${sub["R"]}^2`
},
{
   id: "a2",
   name: "Area of a triangle as a function of base and height",
   katex: "A = 1/2 * b * h",
   description: "The area of a triangle is one half its base times the height.",
   help: "# Introduction",
   variables: { "A": "m^2", "b": "m", "h": "m" },
   equation: (sub: SubstitutionType) => `${sub["A"]} = 1/2 * ${sub["b"]} * ${sub["h"]}`
},
{
   id: "f1",
   name: "Sum of force along the x axis is zero.",
   description: "",
   katex: "\\sum{F_x} = 0 N",
   help: "# Newton's Laws",
   variables: { "F": "N" },
   equation: (sub: SubstitutionType) => {
      if (Array.isArray(sub["F"])) {
         return (sub["F"] as string[]).join(" + ") + " = 0 N ";
      } else {
         return `${sub["F"]} = 0 N`;
      }
   }
},
{
   id: "v1",
   name: "Create a vector from components.",
   description: "",
   katex: "\\vec{V} = V_x \\hat{i} + V_y \\hat{j} + V_z \\hat{k}",
   help: "# Newton's Laws",
   variables: { "V": "*u1", "Vx": "*u1", "Vy": "*u1", "Vz": "*u1" },
   equation: (sub: SubstitutionType) => {
      return `${sub["V"]} = [${sub["Vx"]}, ${sub["Vy"]}, ${sub["Vz"]}]`
   }
},
{
   id: "custom1",
   name: "Create a custom function.",
   description: "",
   katex: "",
   help: "",
   variables: { "F": "" },
   equation: (sub: SubstitutionType) => {
      return `${sub["F"]}`
   }
},

];

export const registeredTemplates: { [id: string]: EquationTemplateDefinition } = {};

equationTemplates.forEach((template) => {
   registeredTemplates[template.id] = template;
});



