<template>
  <b-img :src="url" />
  <div> {{ caption }} </div>
</template>

<script lang="ts">
  import BImg from "@/components/bootstrap/b-img.vue";
  import { defineComponent, PropType, ref } from "vue";
  import { Page_ImageComponent } from "../pages/pages.interface";

  export default defineComponent({
    components: { BImg },
    props: {
      content: {
        type: Object as PropType<Page_ImageComponent>,
        required: true,
      },
    },
    setup(props) {
      const url = ref(props.content.data.src);
      const caption = ref(props.content.data.caption);

      return {
        url,
        caption,
      };
    },
  });
</script>

<style scoped>
</style>