<template>
  <div>
    <template v-for="(button, index) in buttons"
              :key="button.label">
      <input :id="'option' + index"
             type="radio"
             class="btn-check"
             name="options"
             autocomplete="off"
             :checked="button.value === value"
             @click="btnValue = button.value">
      <label class="btn"
             :class="buttonClass"
             :for="'option' + index">
        {{ button.label }}
      </label>
    </template>
  </div>
</template>

<script lang="ts">
  import { useVModel } from '@vueuse/core'
  import { defineComponent, PropType } from 'vue'

  export interface ButtonGroupOptions {
    label: string,
    value: string
  }

  export default defineComponent({
    props: {
      buttons: {
        type: Array as PropType<ButtonGroupOptions[]>,
        required: true
      },
      buttonClass: {
        type: String,
        default: "btn-primary"
      },
      value: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const btnValue = useVModel(props, 'value', emit);

      return {
        btnValue
      }
    }
  })
</script>

<style scoped>
</style>