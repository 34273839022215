import { FormSchemaMaker } from "@/components/formGenerator/form-schema-maker";
import { FormCustomValidationRule } from "@/components/formGenerator/form-schema.interface";
import { ModelVariableType } from "../model/model-variable";
import { getProblemPartsSchema } from "./ProblemPart.schema";

export function questionCreatorSchema(modelEvaluation: FormCustomValidationRule, variableEvaluation: FormCustomValidationRule, validateVariables: FormCustomValidationRule) {
   return FormSchemaMaker.Group({
      label: "Question",
      field: "data",
      properties: {
         classes: "m-0 p-1",
         hideLabel: true,
         showJSON: true,
         customButtons: [
            { title: 'Thumbnail', emit: 'view-preview' },
            { title: 'Sample', emit: 'view-sample' }
         ]
      }
   },
      [
         FormSchemaMaker.Group({
            label: "1. Create a Mathematical Model",
            field: "model",
            properties: {
               hideLabel: false,
               classes: "m-1 p-1 border formColorGroup"
            }
         },
            [
               FormSchemaMaker.ArrayOfObject({
                  label: "Random Variables",
                  field: "parameters",
                  properties: {
                     labelAlign: "top",
                  },
                  rules: {
                     custom: validateVariables
                  },
                  hint: "List any known variables"
               },
                  [
                     FormSchemaMaker.Text({ label: "Name", field: "name", hint: "Enter the variable name.", rules: { variable_name: true } }),
                     FormSchemaMaker.Select({ label: "Type", field: "type", choices: [ModelVariableType.INTEGER, ModelVariableType.FLOAT], value: ModelVariableType.FLOAT, hint: "Enter the type of variable used." }),
                     FormSchemaMaker.Text({ label: "Min Value", field: "min", hint: "Enter the minimum variable value", value: 1 }),
                     FormSchemaMaker.Text({ label: "Max Value", field: "max", hint: "Enter the maximum variable value", value: 10 }),
                     FormSchemaMaker.Number({ label: "Increment", field: "increment", hint: "Enter the allowable variable increment.", value: 0 }),
                     FormSchemaMaker.Text({ label: "Unit", field: "unit", hint: "Enter the variable unit", rules: { physical_unit: true } }),
                     FormSchemaMaker.Number({ label: "NrSigFigs", field: "nrSigFigs", rules: { min_value: 2, max_value: 20 }, value: 3, hint: "Enter the number of significant figures to use." }),
                  ]
               ),
               FormSchemaMaker.ArrayOfText({
                  label: "Equations",
                  field: "formulas",
                  hint: "Enter the formulas governing the solution to the problem.",
                  properties: {
                     labelAlign: "top",
                  },
                  rules: {
                     custom: variableEvaluation
                  }
               })
            ]),
         FormSchemaMaker.Group({
            label: "2. Pose the problem",
            field: "prob_data",
            properties: {
               hideLabel: false,
               classes: "m-1 p-1 border formColorGroup"
            }
         },
            [
               FormSchemaMaker.TextBox({
                  label: "Problem Statement",
                  field: "problemStatement",
                  hint: "Enter the problem statement. You can use a variable in double brackets {{ x }} to display the numerical value of x. You can include equations using LaTeX syntax in between dollar signs. Single dollar sign gives an inline equation $\\frac{x}$ or double dollar signs for newline equation $$ \\hat{x} $$ ",
                  properties: {
                     labelAlign: "top",
                     labelCols: 0,
                     classes: "m-0",
                     componentCols: 6
                  },
                  rules: {
                     custom: modelEvaluation
                  }
               }),
               FormSchemaMaker.FileLocation({
                  label: "Figure (JPG / PNG)",
                  field: "url",
                  properties: {
                     labelAlign: "top",
                     labelCols: 0,
                     classes: "m-1",
                     componentCols: 6
                  },
                  hint: "Enter url for an image or use the drag / drop tool by clicking on the icon. "
               }),
               getProblemPartsSchema()
            ]),
         FormSchemaMaker.Group({
            label: "3. Create a solution",
            field: "soln_data",
            properties: {
               hideLabel: false,
               classes: "m-1 p-1 border formColorGroup"
            }
         },
            [
               FormSchemaMaker.TextBox({
                  label: "Solution",
                  field: "solutionStatement",
                  hint: "Enter the problem statement. You can use a variable in double brackets {{ x }} to display the numerical value of x. You can include equations using LaTeX syntax in between dollar signs. Single dollar sign gives an inline equation $\\frac{x}$ or double dollar signs for newline equation $$ \\hat{x} $$ ",
                  properties: {

                  },
               })
            ]),
         FormSchemaMaker.Group({
            label: "4. Catagorize Question",
            field: "data",
            properties: {
               hideLabel: false,
               classes: "m-1 p-1 border formColorGroup"
            }
         },
            [
               FormSchemaMaker.ArrayOfObject({
                  label: "Question Topics",
                  field: "topics",
                  hint: "Select topics addressed by this question.",
                  properties: {
                     classes: "m-0",
                     labelAlign: "top",
                     labelCols: 0,
                  },
                  value: []
               },
                  [
                     FormSchemaMaker.ItemSubItem({
                        label: "Items",
                        field: "topics",
                        hint: "Enter the allowable variable increment.",
                        value: { item: "Topic 1", subItem: "Sub Topic 1" },
                        meta: {
                           topics: [{
                              name: "Topic 1",
                              id: "abc",
                              subItems: [
                                 { name: "Sub Topic 1", id: "cde" },
                                 { name: "Sub Topic 2", id: "cde2" }
                              ]
                           },
                           {
                              name: "Topic 2",
                              id: "abc",
                              subItems: [
                                 { name: "Sub Topic 3", id: "cde3" },
                                 { name: "Sub Topic 4", id: "cde4" }
                              ]
                           }]
                        }
                     }),
                     FormSchemaMaker.Select({
                        label: "Difficulty",
                        field: "difficulty",
                        properties: { classes: "selectInput" },
                        choices: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                        value: "5",
                        hint: "",
                     }),
                  ]
               ),
            ]),
      ]);
}