import * as BABYLON from 'babylonjs';

export class PointListGenerator {
    /**
     *  Generate a dictionary of BABYLON.Vector3 from a json Object
     *   example usage: 
     *          pointListFromJSON({a: [0, 1, 2], b: [2, 3, 4], c: [1, 1, 1]});
     *          
     * @param json - list in the form = { key: point } 
     * @return a dictionary of BABYLON.Vector3
     */
    public static fromJSON(json: Record<string, number[]>): Record<string, BABYLON.Vector3> {
        const points: Record<string, BABYLON.Vector3> = {};
        for (const key in json) {
            points[key] = new BABYLON.Vector3(json[key][0], json[key][1], json[key][2]);
        }
        return points;
    }
}