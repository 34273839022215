<template>
  <div>
    <div class="col">
      <BSelect id="topic"
               v-model="selectedTopic"
               :options="topicList"
               class="form-control" />
    </div>
    <div v-if="selectedTopic !== ''"
         class="col">
      <BSelect id="subtopic"
               v-model="selectedSubTopic"
               :options="subTopicList"
               class="form-control" />
    </div>
  </div>
</template>

<script lang="ts">
  import BSelect from '@/components/bootstrap/b-select.vue'
  import { defineComponent, PropType, Ref, ref, watch } from 'vue'

  interface ItemSubItemResult {
    item: string,
    subItem: string
  }

  interface SubItemChoiceType {
    name: string,
    id: string
  }

  interface ItemSubItemChoiceType {
    name: string,
    id: string,
    subItems: SubItemChoiceType[]
  }

  export default defineComponent({
    name: "BItemSubItem",
    components: { BSelect },
    props: {
      modelValue: {
        type: Object as PropType<ItemSubItemResult>,
        required: true,
      },
      class: {
        type: String,
        default: ""
      },
      choices: {
        type: Object as PropType<ItemSubItemChoiceType[]>,
        required: true
      }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const topicList = props.choices.map((v) => {
        return v.name;
      });
      const subTopicList: Ref<string[]> = ref([]);

      const selectedTopic = ref(props.modelValue.item);
      createSubTopicList(selectedTopic.value);

      const selectedSubTopic = ref(props.modelValue.subItem);

      function createSubTopicList(topicName: string) {
        const curTopicItem = props.choices
          .find((v) => {
            return v.name === topicName;
          });

        if (curTopicItem) {
          subTopicList.value = curTopicItem
            .subItems.map((v) => v.name) as string[];
        } else {
          subTopicList.value = [];
        }
      }

      watch(selectedTopic, (newValue: string) => {
        console.debug("Item Selected:", selectedTopic.value);
        createSubTopicList(newValue);
        selectedSubTopic.value = subTopicList.value[0];
        context.emit("update:modelValue", { item: newValue, subItem: "" });
      });


      watch(selectedSubTopic, (newValue: unknown) => {
        context.emit("update:modelValue", { item: selectedTopic.value, subItem: newValue });
      });


      return {
        topicList,
        subTopicList,
        selectedTopic,
        selectedSubTopic
      }
    }
  })
</script>

<style scoped>
</style>