
import { listTextbooksAllContent } from '@/graphql/custom-queries';
import { byTextbookFieldType } from '@/graphql/queries';
import { sort } from 'fast-sort';
import { DynamoSearchKernal, DynamoSearchResults } from '../dynamoSearchResult';
import { BookRecord } from './record-book';
import { PageRecord as PageRecord } from './record-page';
import { TOC_Record } from './record-tableOfContents';
import { TextbookTableBaseType, TextbookTableSearchTypes } from './table-textbook.interface';

const textbookSearchKernal = (textbookID: string, userEmail: string) => {
   return {
      distributeDBItems: function (fetchedData: any, type: TextbookTableSearchTypes, obj: TextbookSearchResults) {
         let items: TextbookTableBaseType[];
         switch (type) {
            case TextbookTableSearchTypes.NOTEBOOK_BY_ID:
            case TextbookTableSearchTypes.ASSIGNMENTBOOK_BY_ID:
            case TextbookTableSearchTypes.QUESTION_BANK_BY_ID:
            case TextbookTableSearchTypes.LABORATORY_BY_ID:
            case TextbookTableSearchTypes.TEXTBOOK_BY_ID:
               items = fetchedData.data.listTextbooks.items;
               break;
            case TextbookTableSearchTypes.TEXTBOOK_LIST:
               items = fetchedData.data.byTextbookFieldType.items;
               break;
         }

         items.forEach((v) => {
            switch (v.fieldType) {
               case "LOGBOOK":
               case "ASSIGNBOOK":
               case "BOOK":
               case "LABROOM":
               case "QUESTION_BANK":
                  obj.textbooks.push(new BookRecord().wrapDBRecord(v));
                  break;
               case "TOC":
                  obj.toc.push(new TOC_Record().wrapDBRecord(v));
                  break;
               case "LOGPAGE":
               case "QUESTION":
               case "ASSIGNMENT":
               case "EXPERIMENT":
               case "PAGE":
                  console.debug(v)
                  obj.pages.push(new PageRecord().wrapDBRecord(v));
                  break;

            }
         });

         // sort
         obj.textbooks = sort(obj.textbooks).desc(u => u.data().createdAt);

      },
      extraQueryData: {
         textbookID: textbookID,
         userEmail: userEmail
      },
      getQueryData: function (type: TextbookTableSearchTypes, extraQueryData: any) {
         switch (type) {
            case TextbookTableSearchTypes.LABORATORY_BY_ID:
            case TextbookTableSearchTypes.NOTEBOOK_BY_ID:
            case TextbookTableSearchTypes.ASSIGNMENTBOOK_BY_ID:
            case TextbookTableSearchTypes.QUESTION_BANK_BY_ID:
            case TextbookTableSearchTypes.TEXTBOOK_BY_ID:
               return {
                  query: listTextbooksAllContent,
                  queryData: { textID: textbookID }
               };
            case TextbookTableSearchTypes.TEXTBOOK_LIST:
               return {
                  queryData: { fieldType: "BOOK" },
                  query: byTextbookFieldType
               };
         }
      }
   }
}


export class TextbookSearchResults extends DynamoSearchResults<TextbookTableSearchTypes>{
   public queryType: TextbookTableSearchTypes = TextbookTableSearchTypes.TEXTBOOK_BY_ID;
   public textbooks: BookRecord[] = [];
   public toc: TOC_Record[] = [];
   public pages: PageRecord[] = [];

   public clear() {
      this.textbooks = [];
      this.toc = [];
      this.pages = [];
      return true;
   }

   constructor(textbookID: string, userEmail: string) {
      super(textbookSearchKernal(textbookID, userEmail) as unknown as DynamoSearchKernal<TextbookTableSearchTypes>);
   }

   getTextWithID(id: string) {
      return this.textbooks.find((v) => v.data().id === id)
   }

   getPageWithID(id: string) {
      return this.pages.find((v) => v.data().id === id)
   }

   getTOCWithID(id: string) {
      return this.toc.find((v) => v.data().id === id)
   }


}

