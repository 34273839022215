import { MathNode } from "mathjs";
import { extendedMathJSFunctions, registeredFunctionNames } from "./extension/math-util-functions";
import { MjsMath } from "./math-core";
import { LookupTableType, MjsNode, mjsUnknown } from "./math-types";
import { VariableTable } from "./mathjs-variable-table";
import { TypeGuard } from "./Type-guards";


export class MathJSTree {
   static addParentInfoToNodeTree(mathjsNode: MjsNode, indexInParentArg: number, parent: MjsNode | null) {
      mathjsNode.parent = parent;
      mathjsNode.indexInParentArg = indexInParentArg;

      populateParentInfo(mathjsNode.args);
      populateParentInfo(mathjsNode.items);

      if (TypeGuard.hasProp(mathjsNode, "content")) {
         if (mathjsNode.content?.args)
            populateParentInfo(mathjsNode.content.args);
      }

      function populateParentInfo(arr: MjsNode[] | undefined) {
         if (TypeGuard.isNullOrUndefined(arr)) return;

         arr.forEach((item, index) => {
            MathJSTree.addParentInfoToNodeTree(item, index, mathjsNode);
         })
      }
   }

   static processBracUnitAndExtendedFunctions(eqnNode: MjsNode, scope: Record<string, mjsUnknown>) {
      const mjs = MjsMath.getMathJS() as any;

      return eqnNode.transform(function (node) {
         if (node.isFunctionNode) {
            if (!TypeGuard.isString(node.fn)) {
               const fnName = node.fn.name as string;
               if (fnName === "cUnit") {
                  const unitStr = node.args?.[0].items?.[0].toString();
                  console.log("Process US: ", unitStr, node.args?.[0].items?.[0]);
                  //const unit = mjs.unit(1, unitStr);//"1 " + unitStr);
                  const unit = mjs.unit(unitStr);
                  return new mjs.ConstantNode(unit);
               }

               if (registeredFunctionNames.indexOf(fnName) !== -1) {
                  const extFunc = extendedMathJSFunctions[fnName];
                  return extFunc(node.args as MathNode[], scope);
               }
            }
         }

         return node;
      });
   }

   static substituteNode(eqnNode: MjsNode, subNode: MjsNode, forVariableName: string) {
      return eqnNode.transform(function (node) {
         if (node.isSymbolNode && node.name === forVariableName) {
            return subNode;
         }

         return node;
      });
   }

   static replaceWithLookupInfo(inputNode: MjsNode, variableTable: LookupTableType) {
      const mjs = MjsMath.getMathJS() as any;

      return inputNode.transform(function (node) {
         if (node.isSymbolNode && node.name) {
            if (TypeGuard.hasProp(variableTable, node.name)) {
               const substitution = variableTable[node.name];
               switch (mjs.typeOf(substitution)) {
                  case "number":
                  case "Matrix":
                  case "Unit":
                     return new mjs.ConstantNode(substitution);
                  case "string":
                     return mjs.parse(substitution);
                  case "OperatorNode":
                  case "FunctionNode":
                  case "SymbolNode":

                     return substitution;
                  default:
                     console.debug("Replace Function could not find type: ", mjs.typeOf(substitution));
               }
            }
         }

         return node;

      });
   }

   static findVariable(node: MjsNode, variableName: string): MjsNode[] {
      if (node.isSymbolNode && node.name === variableName) {
         return [node];
      }

      let results: MjsNode[] = [];
      if (node.args || node.content) {
         let list;
         if (node.args) list = node.args;
         else list = node.content?.args;
         if (list)
            list.forEach((n) => {
               const found = MathJSTree.findVariable(n, variableName);
               if (found.length > 0) {
                  results = results.concat(found);
               }
            });
      }
      return results;
   }

}