import * as BABYLON from 'babylonjs';
import { HeatPlate } from '../instruments/Electronic/HeatPlate';
import { Viscometer } from '../instruments/Electronic/Viscometer';
import { BaseRenderedObject } from "../primatives/BaseRenderedObject";
import { Beaker } from '../primatives/LabEquipment/Beakers';
import { Fluid, Fluids } from '../properties/Standard_Fluids';
import { VariableTable } from "../values/VariableTable";
import { ExperimentInterface } from "./ExperimentInterface";

interface ViscometryStationOptions {
    location?: BABYLON.Vector3,
    rotationY?: number,
    fluid: Fluid
}

interface ViscometryResults {
    rpm: number,
    torque: number,
    viscosity: number,
    temperature: number
}

export class ViscometryStation extends BaseRenderedObject implements ExperimentInterface {
    private variableTable: VariableTable;

    private options: ViscometryStationOptions;


    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        const fluid = new Fluid(Fluids.WATER);

        this.options = {
            fluid: fluid,
            location: new BABYLON.Vector3(0, 0, 0),
            rotationY: 0
        }

        this.variableTable = new VariableTable();

        this.addChild(new Viscometer('VISCO', scene, this));
        this.addChild(new Beaker('BEAKER', scene, this));
        this.addChild(new HeatPlate('HOTPLATE', scene, this));
    }

    public setOptions(options: ViscometryStationOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        super.addPreloadAssets(assetManager);
    }


    public render() {

        this.myContainerNode = BABYLON.MeshBuilder.CreateSphere('Viscometery Origin', {
            diameter: 0.001
        }, this.scene);


        this.getChildByName("VISCO")
            ?.setOptions({
                location: new BABYLON.Vector3(0, 0.0, -0.1)
            })
            .render();

        this.getChildByName("BEAKER")
            ?.setOptions({
                location: new BABYLON.Vector3(0.5, 0, -0.1)
            })
            .render();

        this.getChildByName("HOTPLATE")
            ?.setOptions({
                location: new BABYLON.Vector3(-0.5, 0.05, -0.10)
            })
            .render();

        // move container only after all children are added
        if (this.myContainerNode) {
            this.myContainerNode.addRotation(0, this.options.rotationY ?? 0, 0);
            this.myContainerNode.position = this.options.location ?? BABYLON.Vector3.Zero();
        }

        return super.render();
    }

    public updateTime(time: number, deltaT: number) {
        this.variableTable.set("TIME", time, "ms");
        this.variableTable.set("DELTA_T", deltaT, "ms");
        this.onChange();
    }

    public onChange() {
        this.setTableFromObjectState(this.variableTable);
        this.reComputeSystemState(this.variableTable);
        this.setObjectStateFromTable(this.variableTable);
    }


    private reComputeSystemState(variableTable: VariableTable) {

        //variableTable.set("PC40", values.P4, "Pa");
    }
}