import { AssignmentOptionsInput, PageContentInput, TextType } from '@/API';
import { v4 as uuidv4 } from 'uuid';

export interface TextbookTableBaseType {
   id: string;
   textID?: string;

   name?: string;
   description?: string;
   long_description?: string;

   fieldType?: TextType;

   createdAt?: string;
   updatedAt?: string;

   version?: string;

   ttlExpireTime?: number;
}

export interface TextbookFromTextbookTable extends TextbookTableBaseType {
   txt_pictureURL?: string;
}

export interface PageFromTextbookTable extends TextbookFromTextbookTable {
   page_isActive: boolean;
   page_activeContent: PageContentInput | { content: { components: unknown[] } };
   page_draftContent: PageContentInput | { content: { components: unknown[] } };
   page_history: PageContentInput[];

   // assignment characteristics
   asn_options?: AssignmentOptionsInput;

   // thumbnails used by labs
   lab_pictureURL?: string;
}

export interface PageIndex {
   id: string,
   name: string,
   pageID: string,
   isActive: boolean,
   prefix: string,
   children: PageIndex[]
}

export interface TOCFromTextbookTable extends TextbookFromTextbookTable {
   toc_pageIndex?: string;
}

export enum TextbookTableSearchTypes {
   TEXTBOOK_BY_ID,
   TEXTBOOK_LIST,
   LABORATORY_BY_ID,
   ASSIGNMENTBOOK_BY_ID,
   NOTEBOOK_BY_ID,
   QUESTION_BANK_BY_ID
}


export function CreateEmptyPageIndex(): PageIndex {
   return {
      id: uuidv4(),
      name: "Untitled",
      pageID: uuidv4(),
      isActive: true,
      prefix: "",
      children: []
   }
}