import * as BABYLON from 'babylonjs';

import { BasePrimativeObjectGenerator } from "../Factories/PrimativeFactoryInterface";

export class Pipe_T_Joint extends BasePrimativeObjectGenerator {
    
    constructor(scene: BABYLON.Scene) {
        super(scene);
        this.name = "Tee"
    }

    public addPreloadAssets(assetManager: BABYLON.AssetsManager) {
        super.addPreloadAssetURL(assetManager,
            "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/pipes/",
            "Joint_T.obj");
    }
}