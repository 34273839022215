<template>
  <FormInputGroup v-show="!schemaitem.properties?.hidden"
                  :fid="fid"
                  :schemaitem="schemaitem"
                  :displaybehavior="displaybehavior"
                  :feedback="schemaitem._validationHints">
    <table class=" table table-striped table-bordered"
           style="text-align:center">
      <thead class="thead-dark">
        <tr>
          <th> # </th>
          <th />
          <th>{{ schemaitem.label }}</th>
        </tr>
      </thead>
      <draggable v-model="arrayValue"
                 item-key="id"
                 tag="tbody"
                 class=""
                 handle=".handle">
        <template #item="{index}">
          <tr :key="'arr_' + index + triggers.validate">
            <td class="handle">
              {{ index + 1 }}
            </td>
            <td>
              <i class="bi bi-trash deleteButton"
                 @click="deleteRow(index)" />
            </td>
            <td class="p-1">
              <input :class="'form-control ' + getArrayValidationForRow(schemaitem, index).class"
                     :readonly="schemaitem.properties?.disabled"
                     required
                     :type=" schemaitem.formGen?.inputType"
                     :value="arrayValue[index]"
                     @input="arrayValue[index]=$event.target.value">
              <div v-if="getArrayValidationForRow(schemaitem, index).hints.length > 0">
                {{ getArrayValidationForRow(schemaitem, index).hints }}
              </div>
            </td>
          </tr>
        </template>
      </draggable>
      <tfoot>
        <tr>
          <td :colspan="3"
              class="p-1"
              style="text-align:right">
            <button type="button"
                    class="btn btn-primary"
                    @click="addArrayRow()">
              Add Row
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  </FormInputGroup>
  <BConfirmDialog ref="confirmDialog"
                  class="inputArrayDeleteModal" />
</template>

<script lang="ts">
  import FormInputGroup from "./form-input-layout.vue";
  import BConfirmDialog from "../../bootstrap/b-confirm-dialog.vue";

  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent, EmitsOptions, ref, SetupContext, watch } from "vue";
  import { FormSchemaItem, FormTypes } from "../form-schema.interface";
  import { ConfirmDialogOptions } from "@/components/bootstrap/b-confirm-dialog.interface";
  import draggable from "vuedraggable";

  export default defineComponent({
    name: "FormInputArray",
    components: {
      FormInputGroup,
      BConfirmDialog,
      draggable
    },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const confirmDialog = ref<ConfirmDialogOptions | null>(null);
      const arrayValue = (ref(props.modelValue as unknown as unknown[]));

      const deleteRow = (index: number) => {
        confirmDialog.value
          ?.open(
            "Confirm Delete",
            "Area you sure you want to delete this item?",
            {
              cancel_class: "btn btn-secondary",
              ok_class: "btn btn-danger deleteArrayRowButton",
              ok_text: "Delete",
            }
          )
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              arrayValue.value.splice(index, 1);
            }
          });
      };

      const { isSchemaValid } = useValidation(props, context);

      const addArrayRow = () => {
        let newVal: string | number = "";
        switch ((props.schemaitem as FormSchemaItem).fieldType) {
          case FormTypes.EMAIL:
          case FormTypes.TEXT:
            newVal = "";
            break;
          case FormTypes.NUMBER:
          case FormTypes.INTEGER:
            newVal = 0;
            break;
        }

        arrayValue.value.push(newVal);
        isSchemaValid(props.schemaitem);
      }

      watch(arrayValue, () => {
        console.log("Change array", arrayValue)
        context.emit("update:modelValue", arrayValue);
      });

      return {
        deleteRow,
        addArrayRow,
        arrayValue,
        confirmDialog,
        ...useValidation(props, context)
      };
    },
  });
</script>

<style scoped>
</style>