import { ModelHealthReport } from "./model/model-health-report";
import { QuestionModel } from "./model/model-question";
import { QuestionDataInterface } from "./QuestionViewer.interface";


export function getRequestedResponseFromQuestionComp(component: QuestionDataInterface) {
   return component.data.view.meta.requestedResponses;
}

export function readQuestionComponent(component: QuestionDataInterface, modelHealthReport: ModelHealthReport) {
   let questionModel;
   let problemStatement;
   let solutionStatement;

   const model = component.data.model.meta;

   try {
      questionModel = new QuestionModel(
         model.formulas,
         model.parameters,
         model.vector_parameters,
         modelHealthReport
      );
   } catch (err) {
      return returnError("Error in question model. ", err as string)
   }

   try {
      problemStatement = questionModel.evaluateStatement(
         component.data.view.meta.problemStatement
      ).getData();
   } catch (err) {
      return returnError("An error was encountered while parsing the solution statement.", err as string);
   }

   try {
      solutionStatement = questionModel.evaluateStatement(
         component.data.solution.meta.solutionStatement
      ).getData();

      const includeFigure = component.data.figure.meta.include;

      const responseRequests = component.data.view.meta.requestedResponses;

      const figureURL = component.data.figure.meta.url;

      return {
         problemStatement,
         solutionStatement,
         includeFigure,
         responseRequests,
         questionModel,
         figureURL
      }
   } catch (err) {
      return returnError("An error was encountered while parsing the solution statement.", err as string);
   }

   function returnError(msg: string, err: string) {
      modelHealthReport.addGeneralError(msg + err)
      return {
         problemStatement: "",
         solutionStatement: "",
         includeFigure: false,
         responseRequests: [],
         questionModel: new QuestionModel([], [], [], new ModelHealthReport()),
         figureURL: ""
      }
   }
}

