import katex from 'katex';
import renderMathInElement from 'katex/dist/contrib/auto-render';
import { DirectiveBinding } from 'vue';
import { katexGlobalOptions } from './katex-options';

export const katexDirective = {
   name: "katex",
   directive: {
      mounted(el: HTMLElement, binding: DirectiveBinding): void {
         renderMathInElement(el, katexGlobalOptions);
      },
      updated(el: HTMLElement, binding: DirectiveBinding): void {
         renderMathInElement(el, katexGlobalOptions);
      }
   }
}