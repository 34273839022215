
  import { defineComponent, PropType, ref } from "vue";
  import usePagination from "../use/usePagination";

  export default defineComponent({
    props: {
      pageNr: {
        type: Number,
        default: 0,
      },
      maxPageNrs: {
        type: Function as PropType<() => number>,
        required: true,
      },
      excludeFirstPage: {
        type: Boolean,
        default: true
      }
    },
    emits: ["update:pageNr", "change"],
    setup(props, context) {
      const { activePageNr, setPageNr, getMaxNrPages } = usePagination(
        1,
        ref(props.maxPageNrs),
        () => {
          context.emit("update:pageNr", activePageNr.value);
          context.emit("change", activePageNr.value);
        }
      );

      return {
        activePageNr,
        setPageNr,
        getMaxNrPages,
      };
    },
  });
