import * as BABYLON from 'babylonjs';
import { BaseRenderedObject, RenderedObjectTypes } from '../../primatives/BaseRenderedObject';
import { VariableTable } from "../../values/VariableTable";
import { ElectronicDevice, ElectronicDisplay, ElectronicStateMachine } from './BaseElectronicDevice';
import { InstrumentDisplayValue, RangeOverFlowOptions, NoiseType, NoiseCorrelation } from '../InstrumentDisplayValue';
import { _u } from '../../values/ScalarValue';


export class FlowMeterElectronic extends ElectronicDevice {

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.FLOW_METER_ELECTRONIC;

        // textures
        this.faceTextureURL = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/FlowMeter.png";

        // setup state 
        // setup state machine
        this.stateMachine = new ElectronicStateMachine({
            states: {
                curUnit: 0,
                units: ["gpm", "gallons"],
                mode: 0,
                modeList: ['Flow Rate', 'Total'],
                totalFlow: 0
            },
            computed: {
                getCurMode: (sm) => { return sm.get('modeList')[sm.get('mode')]; }
            },
            actions: {
                hitPower: (sm) => {
                    sm.set('isOn', true);
                    sm.set('totalFlow', 0);
                    //sm.togglePower();
                },
                hitMode: (sm) => {
                    sm.set('isOn', true);
                    sm.incrementListSelection('mode', 'modeList');
                },
            }
        });

        // default options
        this.setOptions({
            width: 0.15 * 1.5,
            height: 0.15 * 1.5,
            depth: 0.10,
            edgeRadius: 0.02,
            //edgeRadius: 0.0,
            fontSize: this.digitFont,
            displayHeight: 0.1,
            displayWidth: 0.15,
            displayLocation: new BABYLON.Vector3(0, 0.03, -0.001)
        });

        // button map
        this.addButtonMapItem('POWER', [0.15, 0.12, 0.48, 0.32], 'hitPower');
        this.addButtonMapItem("MODE", [0.53, 0.12, 0.85, 0.32], 'hitMode');

        // setup ports
        this.portMap.set('FLOW_RATE',
            {
                name: '0',
                value: new InstrumentDisplayValue(0, 'gpm',
                    {
                        minValue: _u(0, "gpm"),
                        maxValue: _u(100, "gpm"),
                        overflow: RangeOverFlowOptions.CLIP
                    },
                    {
                        addNoise: true,
                        type: NoiseType.PerOfRange,
                        percent: 0.001,
                        correlation: NoiseCorrelation.SinVariationWithTime,
                        period: 1
                    }),
                connection: null
            });


        // display function
        this.onDisplayUpdate = this.displayCallback;
    }



    private displayCallback(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {
        const time = variableTable.get("TIME")?.in("s");
        const dtInMin = variableTable.get("DELTA_T")?.in('min') ?? 0;

        const flowRate = variableTable.get(this.name);
        const pmv = this.portMap.get('FLOW_RATE');
        pmv?.value?.copy(flowRate);
        const displayFlowRate = (pmv?.value as InstrumentDisplayValue).getDisplayValueIn('gpm', time);


        const mode = this.stateMachine?.get('mode');
        if (mode === 0) {
            display.drawText("Rate (GPM)", 5, 40, this.infoFont);
            display.drawValue(displayFlowRate, "", 5, 20, 100, this.digitFont);
        } else {
            display.drawText("Total (Gal)", 5, 40, this.infoFont);
            this.stateMachine?.addToState('totalFlow', (flowRate?.in('gpm') ?? 0) * dtInMin);
            display.drawValue(this.stateMachine?.get('totalFlow'), "", 5, 20, 100, this.digitFont);
        }
    }

}