
import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { SubmissionItem } from "@/store/database/Submission/submitted-item.interface";
import { PageRecord } from "@/store/database/Textbook/record-page";
import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
import { AccomodationType } from "./Accomodation.schema";

export function useDueDates() {

   const getStatus = (assignment: PageRecord, submission: SubmissionItem | null = null) => {
      const { dueOn, openOn } = getDueDates(assignment.data(), submission);
      return _status(dueOn, openOn);
   }

   function _status(dueOn: number, openOn: number) {
      const curDate = Date.now();
      if (curDate < openOn) return 'not_open';
      if (dueOn < curDate) return 'closed';
      return 'open';
   }

   const getDueDates = (assignment: PageFromTextbookTable, submission: SubmissionItem | null = null) => {
      const openOn = Date.parse(assignment.asn_options?.openOn as string);
      const dueOn = Date.parse(assignment.asn_options?.dueOn as string);

      if (!TypeGuard.isNullOrUndefined(submission)) {
         if ((submission as any).accomodationType === AccomodationType.EXTRA_TIME) {
            const json = JSON.parse((submission as any).accomodationValue);
            const extraHours = parseFloat(json.extraTime);
            return { openOn, dueOn: dueOn + extraHours * 60 * 60 * 1000 };
         }

         if ((submission as any).accomodationType === AccomodationType.DIFFERENT_DATE) {
            const json = JSON.parse((submission as any).accomodationValue);
            const dueOn = Date.parse(json.dueOn);
            const openOn = Date.parse(json.openOn);
            return { openOn, dueOn };
         }
      }

      return { openOn, dueOn };
   }

   return {
      getDueDates,
      getStatus
   }
}