
  import { defineComponent, PropType } from "vue";
  import {
    bNavbarDropdownPropTypes,
    bNavbarDropdownType,
  } from "./b-navbar.interface";

  export default defineComponent({
    props: {
      items: {
        type: Array as PropType<Array<bNavbarDropdownPropTypes>>,
        required: true,
      },
    },
    setup() {
      return {
        bNavbarDropdownType,
      };
    },
  });
