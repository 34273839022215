<template>
  <form-input-group v-show="!schemaitem.properties.hidden"
                    :fid="fid"
                    :schemaitem="schemaitem"
                    :displaybehavior="displaybehavior"
                    :feedback="schemaitem._validationHints">
    <div class="container">
      <div class="row">
        <div class="col">
          <textarea :id="fid"
                    v-model="currentValue"
                    :class="'form-control ' + validationState"
                    :type="schemaitem.formGen.inputType"
                    rows="5"
                    :aria-describedby="fid"
                    :readonly="schemaitem.properties.disabled"
                    required />
        </div>
        <div class="col">
          <div ref="mathElement">
            test
          </div>
        </div>
      </div>
    </div>
  </form-input-group>
</template>

<script>
  import formInputGroup from "./form-input-layout.vue";
  import { useValidation } from "../use/useValidation";
  import { formItemMixinProps } from "../mixins/form.mixins";
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FormInputMarkdown",
    components: { formInputGroup },
    props: { ...formItemMixinProps },
    emits: ["update:modelValue"],
    setup(props, context) {
      return {
        ...useValidation(props, context),
      };
    },
  });
</script>
<style lang="">
</style>