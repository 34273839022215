<template>
  <div v-if="isLoading"
       class="modal-backdrop fade show ">
    <div class="container h-100">
      <div class="row justify-content-center align-items-center h-100">
        <div class="spinner-border " />
      </div>
    </div>
  </div>
  <div class="container-fluid form-root">
    <div class="row g-0 align-items-center">
      <div class="col from-header">
        <slot name="header" />
        <h1>{{ displaybehavior.rootTitle }}</h1>
      </div>
      <div v-if="displaybehavior.buttonAlign=='top'"
           class="col form-button-row text-end ">
        <FormRootButtonRow :key="jsonKey"
                           :form-global-options="formGlobalOptions"
                           :displaybehavior="displaybehavior"
                           :schemaitem="schemaitem"
                           :button-list="buttonList"
                           @btnPress="onBtnPress" />
      </div>
    </div>
    <div v-if="schemaitem"
         :key="schemaitem._internalID"
         class="row g-0 form-body">
      <div class="col">
        <form v-if="schemaitem && schemaitem!== null"
              class="row g-0 needs-validation"
              novalidate>
          <FormInputGroup v-if="schemaitem.formGen.componentName === 'FormInputGroup'"
                          :form-global-options="formGlobalOptions"
                          :displaybehavior="displaybehavior"
                          :schemaitem="schemaitem"
                          :model-value="schemaitem.value"
                          :triggers="triggers"
                          @update:modelValue="changeTypeSelector($event)"
                          @change="onChange" />
          <FormTab v-if="schemaitem.formGen.componentName === 'FormTab'"
                   :form-global-options="formGlobalOptions"
                   :displaybehavior="displaybehavior"
                   :schemaitem="schemaitem"
                   :triggers="triggers"
                   @change="onChange" />
        </form>
      </div>
    </div>
    <div class="row g-0">
      <div class="col form-footer">
        <slot name="footer" />
      </div>
    </div>
    <div class="row form-button-row">
      <div class="col form-button-footer">
        <slot name="buttonFooter" />
        <br>
        <slot name="buttonFooter2" />
      </div>
      <div class="col">
        <div v-if="displaybehavior.buttonAlign=='bottom'"
             class="col">
          <FormRootButtonRow :key="jsonKey"
                             :form-global-options="formGlobalOptions"
                             :displaybehavior="displaybehavior"
                             :schemaitem="schemaitem"
                             :button-list="buttonList"
                             :triggers="triggers"
                             @btnPress="onBtnPress" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import {
    defineComponent,
    EmitsOptions,
    PropType,
    Ref,
    ref,
    SetupContext,
  } from "@vue/runtime-core";
  import FormInputGroup from "./form-input-group.vue";
  import FormTab from "./form-tab.vue";
  import FormRootButtonRow from "./form-root-buttonRow.vue";

  import { formItemMixinProps } from "../mixins/form.mixins";
  import { FormButtonAction, FormErrorEvent } from "../form.interface";

  import { onKeyStroke } from "@vueuse/core";
  import { FormErrorTypes, ValidationHooks } from "../form-schema.interface";
  import useEvents from "../../use/useEvents";
  import { useValidation } from "../use/useValidation";

  export default defineComponent({
    components: {
      FormInputGroup,
      FormRootButtonRow,
      FormTab
    },

    props: {
      ...formItemMixinProps,
      isLoading: {
        type: Boolean,
        default: false,
      },
      buttonList: {
        type: Array as PropType<Array<FormButtonAction>>,
        default: () => {
          return [];
        },
      },
      enterKeyAction: {
        type: Object as PropType<FormButtonAction>,
        default: null,
      },
      validationHooks: {
        type: Object as PropType<ValidationHooks>,
        default: () => {
          return {
            onBeforeValidate: () => { return; },
            onAfterValidate: () => { return; }
          }
        }
      }
    },
    emits: ["btnPress", "change", "error"],
    setup(props, context: SetupContext<EmitsOptions>) {
      console.debug("Load Form Root")
      const jsonKey: Ref<number> = ref(0);

      const changeTypeSelector = (newData: any) => {
        console.log({ newData });
        // eslint-disable-next-line vue/no-mutating-props
        props.schemaitem.value = newData;
        console.log(props.modelValue)
      }

      return {
        changeTypeSelector,
        jsonKey,
        ...useValidation(props as any, context, props.validationHooks),
        ...useEvents(context),
      };
    },
    mounted() {
      onKeyStroke("Enter", (e) => {
        if (this.enterKeyAction) {
          this.onBtnPress(this.enterKeyAction);
        }
      });
    },
    methods: {
      onChange() {
        this.$emit("change");
        this.jsonKey++;
      },
      onBtnPress(btnAction: FormButtonAction) {
        if (!btnAction.enabledWhenFormIsValid || this.isSchemaValid(this.schemaitem)) {
          this.emitEvent("btnPress", {
            type: btnAction.triggerEvent.type,
            payload: {
              schemaItem: this.schemaitem,
            },
          });
        } else {
          this.emitError("error", {
            type: FormErrorTypes.INVALID,
            message: "Unable to process button because form is not validated. ",
          } as FormErrorEvent);
          console.log("Form Error Hints:", this.schemaitem._validationHints);
        }
      },
    },
  });
</script>
<style lang="">
</style>