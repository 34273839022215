<template>
  <div class="row align-items-stretch border-top pt-2">
    <div class="col">
      {{ partLabel(partNr) + evaluateStatement(questionPartData.description) }}
    </div>
    <div v-if="!isCorrect"
         class="col text-end">
      <SubmissionButtonGroup v-model:show-previous-answers="showPreviousAnswers"
                             :loss-per-guess="questionPartData.partialPMper"
                             :is-finished="isFinished"
                             :score="submissionRecord.scoreInPercent"
                             :max-possible-score="100"
                             :max-nr-attempts="Math.floor(100.0/questionPartData.partialPMper)"
                             :nr-attempts-made="submissionRecord.nrAttempts"
                             @click="onSubmit" />
    </div>
    <i v-else
       class="bi bi-check" />
  </div>
  <div v-if="showPreviousAnswers"
       class="row">
    <div class="col">
      <ul v-katex>
        <li v-for="(item, index) in submissionRecord.entries"
            :key="item"
            class="text-danger">
          {{ formatPreviousEntry(index) }}
        </li>
      </ul>
    </div>
  </div>
  <div v-if="validityCheck.hasError"
       class="row">
    <div class="col">
      <span class="text-danger"> {{ validityCheck.error }} </span>
    </div>
  </div>
  <div class="row mt-4 input-group"
       :class="validationClass">
    <div v-if="isScalar"
         class="col-8 input-group-text">
      <input v-model="choice"
             class="form-control"
             :class="{ 'border-danger': validityCheck.component === 0 && validityCheck.hasError}"
             :disabled="isCorrect || isFinished">
    </div>
    <div v-else
         class="col-8">
      <div class="input-group">
        <input v-model="vChoice.i"
               class="form-control"
               :class="{ 'border-danger': validityCheck.component === 0 && validityCheck.hasError}"
               :disabled="isCorrect || isFinished">
        <span class="input-group-text">$ \hat{i}$ </span>
        <span class="m-2">$+$</span>
        <input v-model="vChoice.j"
               class="form-control"
               :class="{ 'border-danger': validityCheck.component === 1 && validityCheck.hasError}"
               :disabled="isCorrect || isFinished">
        <span class="input-group-text">$ \hat{j} $ </span>
        <span class="m-2">$+$</span>
        <input v-model="vChoice.k"
               class="form-control"
               :class="{ 'border-danger': validityCheck.component === 2 && validityCheck.hasError }"
               :disabled="isCorrect || isFinished">
        <span class="input-group-text">$ \hat{k} $</span>
      </div>
    </div>
    <div class="col-4 input-group-text text-center">
      <span>{{ '$ ['+ answerUnit + '] $' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, EmitsOptions, PropType, ref, Ref, SetupContext } from "vue";
  import { QuestionModel } from "../model/model-question";
  import { NumericValidityCheck, useNumericResponses } from "./useNumericResponses";
  import SubmissionButtonGroup from "./SubmissionButtonGroup.vue";
  import useEvents from "@/components/use/useEvents";
  import { SumissionQuestionPart } from "@/store/database/Submission/submitted-item.interface";
  import { AnswerState, UserResponseSetupData } from "./user-response.interface";
  import { mjsVectorIJK } from "../../mathjs/math-types";

  export default defineComponent({
    components: { SubmissionButtonGroup },
    props: {
      partNr: {
        type: Number,
        required: true,
      },
      questionPartData: {
        type: Object as PropType<UserResponseSetupData>,
        required: true,
      },
      questionModel: {
        type: QuestionModel,
        required: true,
      },
      submissionRecord: {
        type: Object as PropType<SumissionQuestionPart>,
        required: true,
      },
      isFinished: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["submit"],
    setup(props, context: SetupContext<EmitsOptions>) {
      const showPreviousAnswers = ref(false);

      console.debug("Setup User Numeric Input.")
      const evaluateStatement = (statement: string) => {
        return props.questionModel.evaluateStatement(statement).getData();
      };

      const partLabel = (index: number) => {
        return index !== -1 ? "Part " + (index + 1) + ". " : "";
      };

      const {
        isScalar,
        choice,
        evalulateCurrentState,
        ansState,
        getCorrectAnswer,
        getCorrectUnit,
        isValidNumericInput,
        registerEntryAsSubmitted,
        formatPreviousEntry
      } = useNumericResponses(props.questionModel, props.questionPartData, props.submissionRecord);

      const answerUnit = getCorrectUnit(); //ref(props.questionPartData.correctUnit);

      if (props.isFinished) {
        choice.value = getCorrectAnswer();
      }

      const isCorrect = computed(() => {
        return ansState.value === AnswerState.CORRECT;
      });

      const validationClass = computed(() => {
        switch (ansState.value) {
          case AnswerState.CORRECT:
            return "correct";
          case AnswerState.WRONG:
            return "wrong";
        }

        return "";
      });

      const { emitEvent } = useEvents(context);
      const validityCheck: Ref<NumericValidityCheck> = ref({ hasError: false, error: "", component: 0 });

      const onSubmit = (): void => {
        showPreviousAnswers.value = false;
        validityCheck.value = isValidNumericInput();
        if (!validityCheck.value.hasError) {
          evalulateCurrentState();

          emitEvent("submit", {
            type: "submit",
            payload: {
              entry: JSON.stringify(choice.value),
              answerState: ansState.value,
            },
          });

          registerEntryAsSubmitted();
        }
      };

      return {
        validationClass,
        isCorrect,
        onSubmit,
        evaluateStatement,
        choice,
        vChoice: choice as Ref<mjsVectorIJK>,
        answerUnit,
        isScalar,
        partLabel,
        validityCheck,
        showPreviousAnswers,
        formatPreviousEntry
      };
    },
  });
</script>

<style scoped>
  .correct input {
    background-color: greenyellow;
  }
  .wrong input {
    background-color: lightcoral;
  }

  @media only screen and (max-width: 500px) {
    ::v-deep(.form-control) {
      font-size: 16px;
    }
  }
</style>