
import { deleteSubmissionFromTable, updateAccomodation, updateQuestionSubmission, updateSubmissionAttempt } from "@/graphql/mutations";
import { getAssignmentSubmission } from "@/graphql/queries";
import { SubmissionItem } from "@/store/database/Submission/submitted-item.interface";
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';

export interface BaseSubmissionInfoRequest {
   [key: string]: unknown;
   courseID: string,
   componentID: string,
   userEmail: string;
   type?: string;
}

export interface DeleteSubmissionParams {
   courseID: string,
   componentID: string,
   questionID: string,
   questionVersion: string,
}


export interface PullAssignmentSubmissionRequest extends BaseSubmissionInfoRequest {
   questionIDs: string[]
}

export interface SubmissionInfoRequest extends BaseSubmissionInfoRequest {
   questionID: string,
   questionVersion: string,
}
export interface PullQuestionSubmissionRequest extends SubmissionInfoRequest {
   nrProblemParts: number,
}

export interface UpdateSubmissionAttemptData extends SubmissionInfoRequest {
   questionPart: number,
   entry: string[];
}

export interface UpdateSubmissionRequest extends UpdateSubmissionAttemptData {
   scoreInPercent: number;
   percentOfQuestion: number;
}

export interface UpdateAccomodationRequest {
   courseID: string,
   componentID: string,
   userId: string,
   accomodationType: string,
   accomodationValue: string
}

export function useSubmissions() {
   const pullAssignmentSubmission = async (params: PullAssignmentSubmissionRequest) => {
      params.type = "ASSIGNMENT";
      const result = await GraphQLAPI.graphql(graphqlOperation(getAssignmentSubmission, params)) as unknown as { data: { getAssignmentSubmission: SubmissionItem } };
      return result.data.getAssignmentSubmission;
   }

   const pullQuestionSubmission = async (params: PullQuestionSubmissionRequest) => {
      params.type = "QUESTION";
      const result = await GraphQLAPI.graphql(graphqlOperation(getAssignmentSubmission, params)) as unknown as { data: { getAssignmentSubmission: SubmissionItem } };
      result.data.getAssignmentSubmission.data = JSON.parse(result.data.getAssignmentSubmission.data as unknown as string);
      return result.data.getAssignmentSubmission;
   }

   const registerSubmissionAttempt = async (params: UpdateSubmissionAttemptData) => {
      const result = await GraphQLAPI.graphql(graphqlOperation(updateSubmissionAttempt, params)) as { data: { updateSubmissionAttempt: SubmissionItem } };
      result.data.updateSubmissionAttempt.data = JSON.parse(result.data.updateSubmissionAttempt.data as unknown as string);
      return result.data.updateSubmissionAttempt;
   }

   const updateFinalSubmission = async (params: UpdateSubmissionRequest) => {
      const result = await GraphQLAPI.graphql(graphqlOperation(updateQuestionSubmission, params)) as { data: { updateSubmissions: SubmissionItem } };
      return result.data.updateSubmissions;
   }

   const deleteSubmission = async (params: DeleteSubmissionParams) => {
      const result = await GraphQLAPI.graphql(graphqlOperation(deleteSubmissionFromTable, params));
      return result;
   }

   const updateAccomodations = async (params: UpdateAccomodationRequest) => {
      const result = await GraphQLAPI.graphql(graphqlOperation(updateAccomodation, params)) as { data: { updateSubmissions: SubmissionItem } };
      console.log(result.data);
      return result.data.updateSubmissions;
   }

   return {
      updateAccomodations,
      pullQuestionSubmission,
      pullAssignmentSubmission,
      updateFinalSubmission,
      deleteSubmission,
      registerSubmissionAttempt
   }
}