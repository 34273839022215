
export enum LaboratoryTypes {
   EVERYTHING,
   VISCOMETER,
   PIPE_RACK_MAJOR,
   PIPE_RACK_MINOR,
   WINDTUNNEL_1,
   MOMENTUM
}

export const LaboratoryNameMap: Map<string, LaboratoryTypes> = new Map([
   ['All Labs In Same Room', LaboratoryTypes.EVERYTHING],
   ['Viscometer', LaboratoryTypes.VISCOMETER],
   ['Pipe Rack Major', LaboratoryTypes.PIPE_RACK_MAJOR],
   ['Pipe Rack Minor', LaboratoryTypes.PIPE_RACK_MINOR],
   ['Windtunnel', LaboratoryTypes.WINDTUNNEL_1],
   ['Linear Momentum', LaboratoryTypes.MOMENTUM]
]);
