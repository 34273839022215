<template>
  <teleport to="body">
    <div class="b-overlay modalView">
      <div :class="showModal? 'modal-open':'modal' ">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {{ title }}
              </h5>
              <button type="button"
                      class="btn-close"
                      aria-label="Close"
                      @click="cancel" />
              <div>
                <i class="bi bi-text" />
              </div>
            </div>
            <div class="modal-body">
              <slot name="body" />
            </div>
            <div class="modal-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    props: {
      show: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ""
      }
    },
    emits: ["update:show"],
    setup(props, context) {
      const showModal = ref(props.show);

      const cancel = () => {
        showModal.value = false;
        context.emit("update:show", false);
      };



      return {
        showModal,
        cancel,
      };
    },
  });
</script>

<style scoped>
  .modal-dialog {
    max-width: 1000px;
    max-height: 600px;
  }

  .b-overlay {
    background-color: #0000004a;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in;
    left: 0;
    top: 0;
    z-index: 999999999999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: baseline;
  }
</style>