<template>
  <li v-for="(item, index) in items"
      :key="index"
      :class="item.class">
    <router-link v-if="item.type== bNavbarDropdownType.LINK"
                 :to="item.to"
                 class="nav-link d-flex align-items-center">
      <i v-if="item.iconName"
         :class="'bi ' + item.iconName" />
      <span v-if="item.label">
        {{ item.label }}
      </span>
    </router-link>
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import {
    bNavbarDropdownPropTypes,
    bNavbarDropdownType,
  } from "./b-navbar.interface";

  export default defineComponent({
    props: {
      items: {
        type: Array as PropType<Array<bNavbarDropdownPropTypes>>,
        required: true,
      },
    },
    setup() {
      return {
        bNavbarDropdownType,
      };
    },
  });
</script>

<style scoped>
</style>