import * as BABYLON from 'babylonjs';
import { MaterialRegistry } from "../Materials/MaterialRegistry";
import { BaseRenderedObject, RenderedObject, RenderedObjectTypes } from '../BaseRenderedObject';
import { BUtil } from '../../utils/BabylonUtils';

interface UFrameOptions {
    location?: BABYLON.Vector3,
    width?: number;
    innerWidth?: number;
    depth?: number;
    height?: number;
    rotationY?: number;
    barThickness?: number;
    supportThickness?: number;
}

export class UBearingFrame extends BaseRenderedObject {
    private options: UFrameOptions = {
        location: BABYLON.Vector3.Zero(),
        width: 0.2,
        innerWidth: 0.1,
        depth: 0.1,
        height: 0.1,
        barThickness: 0.05,
        supportThickness: 0.02,
        rotationY: 0,
    };

    constructor(name: string, scene: BABYLON.Scene, parent: RenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.LAB_BENCH;
    }

    public setOptions(options: UFrameOptions) {
        this.options = { ...this.options, ...options };
        return this;
    }


    public render() {
        const innerWidth = this.options.innerWidth ?? 0;
        const supportThickness = this.options.supportThickness ?? 0;
        const width = this.options.width ?? 0;
        const height = this.options.height ?? 0;
        const depth = this.options.depth ?? 0;
        const barThickness = this.options.barThickness ?? 0;
        const rotationY = this.options.rotationY ?? 0;
        const location = this.options.location ?? BABYLON.Vector3.Zero();


        const top = BUtil.Box("UF_TOP", innerWidth, barThickness, depth, this.scene);
        const left = BUtil.Box("UF_LEFT", supportThickness, height, depth, this.scene);
        const right = left.clone("UF_RIGHT");

        const lScrewPlate = BUtil.Box("UF_LEFT_SCREWPLATE", width - innerWidth, supportThickness, depth, this.scene);
        const rScrewPlate = lScrewPlate.clone();

        const vertDisp = (-height + barThickness) / 2.0;
        const horzDisp = (-innerWidth + supportThickness) / 2;
        BUtil.setParentAndTranslate(left, top, horzDisp, vertDisp, 0);
        BUtil.setParentAndTranslate(right, top, -horzDisp, vertDisp, 0);

        const delta = width - innerWidth - supportThickness;
        const screwVertDist = (-height + supportThickness);
        BUtil.setParentAndTranslate(lScrewPlate, top, horzDisp - delta / 2.0, screwVertDist, 0);
        BUtil.setParentAndTranslate(rScrewPlate, top, -horzDisp + delta / 2.0, screwVertDist, 0);

        top.position = location.clone();
        top.rotate(BABYLON.Axis.Y, rotationY);

        this.myContainerNode = top;

        top.setParent(this.parent?.getContainerMesh() ?? null);

        const colorMat = MaterialRegistry.getColor(this.scene, BABYLON.Color3.Red());
        top.material = colorMat;
        left.material = colorMat;
        right.material = colorMat;
        lScrewPlate.material = colorMat;
        rScrewPlate.material = colorMat;
        return super.render();
    }
}