import { createRouter, createWebHistory, RouteComponent, RouteLocationNormalized, RouteMeta, RouteRecordRaw } from 'vue-router'
import Home from '../views/guest/Home.vue'
import Login from '../views/guest/Login.vue'
import About from '../views/guest/About.vue'
import Terms from '../views/guest/Terms.vue'
import Contact from '../views/guest/Contact.vue'
import Catalog from '../views/guest/Catalog.vue'
import Demo from '../views/guest/Demo.vue'

import Profile from '../views/shared/Profile.vue'

import StudentHome from '../views/student/Home.vue'
import StudentCourse from '../views/student/Course.vue'
import StudentProgress from '../views/student/Progress.vue'

import TextbookViewer from '../views/shared/TextbookViewer.vue'
import AssignmentViewer from '../views/shared/AssignmentViewer.vue'
import AvailableCourseList from '../views/student/AvailableCourseList.vue'
import AvailableCourseDetail from '../views/student/AvailableCourseDetail.vue'
import LaboratoryList from '../views/student/LaboratoryList.vue'
import AdminUserData from '../views/admin/AdminUserData.vue'

import CourseEditMenu from '../views/instructor/CourseEditMenu.vue'
import CourseEditor from '../views/instructor/CourseEditor.vue'
import QuestionBankEditor from '../views/instructor/QuestionBankEditor.vue'
import QuestionCreatorHelp from '../views/instructor/QuestionCreatorHelp.vue'
import AssignmentEditor from '../views/instructor/AssignmentEditor.vue'
import AssignmentPageEditor from '../views/instructor/AssignmentPageEditor.vue'
import TextbookEditor from '../views/instructor/TextbookEditor.vue'

import useLogin from '../components/use/useLogin';


// remove later
import SampleTable from '@/components/bootstrap/table/table.test.vue'
import SamplePage from '@/components/contentGenerator/pages/page-sample.test.vue'
import SampleNotebookPage from '@/components/contentGenerator/handwriting/Notebook/SampleNotebookPage.vue'
import FormSamplePage from '@/components/formGenerator/form-samplepage.vue'
import QuestionViewerTest from '@/components/contentGenerator/questions/QuestionViewer.test.vue'
import bSortableListTest from '@/components/bootstrap/b-sortable-list.test.vue';
import TextAnalysis from '@/components/contentGenerator/handwriting/TextRecognition/TextAnalysis2.vue'
import HandwritingTraining from '@/components/contentGenerator/handwriting/TextRecognition/HandwritingTraining.vue'


enum RouterAccessType {
  GUEST,
  USER,
  STUDENT,
  INSTRUCTOR,
  ADMIN
}

export interface ExtendedMeta extends RouteMeta {
  breadCrumbs: {
    includeCourse: boolean,
    icon: string
  }
}

const rMeta = (accessType: RouterAccessType, includeCourse = false, icon = ""): ExtendedMeta => {
  return {
    requiresAuth: accessType !== RouterAccessType.GUEST,
    accessType,
    breadCrumbs: {
      includeCourse,
      icon
    }
  }
}

function requiresAuth(to: RouteLocationNormalized) {
  return (to.meta as RouteMeta).requiresAuth;
}

function createPath(name: string, component: RouteComponent, accessType: RouterAccessType, includeCourse: boolean, path: string): RouteRecordRaw {
  return {
    path,
    name,
    component,
    meta: rMeta(accessType, includeCourse)
  }
}

function createPathProps(name: string, component: RouteComponent, accessType: RouterAccessType, includeCourse: boolean, icon: string, path: string): RouteRecordRaw {
  return {
    path,
    name,
    component,
    props: true,
    meta: rMeta(accessType, includeCourse, icon)
  }
}

const routes: Array<RouteRecordRaw> = [
  createPath('Home', Home, RouterAccessType.GUEST, false, '/'),
  createPath('Login', Login, RouterAccessType.GUEST, false, '/login'),
  createPath('Terms', Terms, RouterAccessType.GUEST, false, '/terms'),
  createPath('Contact', Contact, RouterAccessType.GUEST, false, '/contact'),

  createPath('Demo', Demo, RouterAccessType.GUEST, false, '/demo'),
  createPath('Catalog', Catalog, RouterAccessType.GUEST, false, '/catalog'),
  createPath('About', About, RouterAccessType.GUEST, false, '/about'),

  createPath('Profile', Profile, RouterAccessType.USER, false, '/profile'),
  createPath('StudentHome', StudentHome, RouterAccessType.STUDENT, false, '/home'),
  createPath('AvailableCourseList', AvailableCourseList, RouterAccessType.STUDENT, false, '/availableCourseList'),
  createPathProps('AvailableCourseDetail', AvailableCourseDetail, RouterAccessType.STUDENT, false, '', '/availableCourseDetail/:courseId'),
  createPathProps('LaboratoryList', LaboratoryList, RouterAccessType.STUDENT, false, '', '/laboratoryList/:courseId'),


  createPathProps('StudentCourse', StudentCourse, RouterAccessType.STUDENT, true, '', '/course/:courseId'),
  createPathProps('TextbookViewer', TextbookViewer, RouterAccessType.STUDENT, true, 'bi-book', '/textbook/:courseId/:textId/:rootPageId/:pageId'),
  createPathProps('AssignmentViewer', AssignmentViewer, RouterAccessType.STUDENT, true, 'bi-book', '/assignment/:courseId/:textId/:rootPageId/:pageId'),
  createPathProps('StudentProgress', StudentProgress, RouterAccessType.STUDENT, true, 'bi-table', '/progress/:courseId'),

  // admin pages
  createPath('AdminUserData', AdminUserData, RouterAccessType.ADMIN, false, '/AdminUserData'),

  // instructor pages
  createPathProps('CourseEditMenu', CourseEditMenu, RouterAccessType.ADMIN, true, 'bi-pencil-fill', '/courseeditmenu/:courseId'),
  createPathProps('QuestionBankEditor', QuestionBankEditor, RouterAccessType.ADMIN, true, 'bi-question-diamond-fill', '/questionbankeditor/:courseId/:textId/:rootPageId/:pageId'),
  createPathProps('QuestionCreatorHelp', QuestionCreatorHelp, RouterAccessType.ADMIN, true, 'bi-question-diamond-fill', '/questioncreatorhelp'),
  createPathProps('AssignmentEditor', AssignmentEditor, RouterAccessType.ADMIN, true, 'bi-journal-text', '/assignmenteditor/:courseId/:textId'),
  createPathProps('CourseEditor', CourseEditor, RouterAccessType.ADMIN, true, 'bi-journal-text', '/courseeditor/:courseId'),
  createPathProps('AssignmentPageEditor', AssignmentPageEditor, RouterAccessType.ADMIN, true, 'bi-journal-text', '/assignmentpageeditor/:courseId/:textId/:pageId'),
  createPathProps('TextbookEditor', TextbookEditor, RouterAccessType.ADMIN, true, 'bi-book', '/textbookeditor/:courseId/:textId/:rootPageId/:pageId'),


  // temporary pages
  createPath('SampleTable', SampleTable, RouterAccessType.GUEST, false, '/sampletable'),
  createPath('SamplePage', SamplePage, RouterAccessType.GUEST, false, '/samplepage'),
  createPath('SampleNotebookPage', SampleNotebookPage, RouterAccessType.GUEST, false, '/samplenotebookpage'),
  createPath('TextAnalysis', TextAnalysis, RouterAccessType.ADMIN, false, '/textanalysis'),
  createPath('HandwritingTraining', HandwritingTraining, RouterAccessType.ADMIN, false, '/handwritingtraining'),
  createPath('FormSamplePage', FormSamplePage, RouterAccessType.GUEST, false, '/formsamplepage'),
  createPath('QuestionViewerTest', QuestionViewerTest, RouterAccessType.GUEST, false, '/questionviewertest'),
  createPath('BSortableListTest', bSortableListTest, RouterAccessType.GUEST, false, '/bsortablelisttest'),


  // custom routes

  {
    path: '/logout', name: 'LogOut',
    redirect: () => {
      const { signOut } = useLogin();
      signOut();
      return { path: '/' }
    },
    meta: rMeta(RouterAccessType.GUEST)
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const { getCurrentCredentials, hasAuthenticatedUser } = useLogin();

router.beforeEach(async (to, from, next) => {
  if (requiresAuth(to)) {
    const hasUser = await hasAuthenticatedUser();
    //const user = await getCurrentCredentials();
    if (hasUser.hasData() && hasUser.getData() === true) {
      const user = await getCurrentCredentials();
      console.debug("Router - User Found", user.getData());
    } else {
      next({ name: "Home" });
      return;

    }

    next();
  } else {
    console.debug("Router - No Authentication Required.");
    next();
  }
})

export default router
