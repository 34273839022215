import * as BABYLON from 'babylonjs';
import { Nullable } from 'babylonjs/types';
import { BaseRenderedObject, RenderedObjectTypes, SnapBehaviorType } from '../../primatives/BaseRenderedObject';
import { Beaker } from '../../primatives/LabEquipment/Beakers';
import { MaterialRegistry } from '../../primatives/Materials/MaterialRegistry';
import { _u, ScalarValue } from '../../values/ScalarValue';
import { VariableTable } from "../../values/VariableTable";
import { InstrumentDisplayValue, NoiseCorrelation, NoiseType, RangeOverFlowOptions } from '../InstrumentDisplayValue';
import { ElectronicDevice, ElectronicDisplay, ElectronicStateMachine } from './BaseElectronicDevice';


export class HeatPlate extends ElectronicDevice {
    public wattage: ScalarValue = _u(0.5, 'kJ/s'); // 500 Watts
    private snapBeakerLocation: BABYLON.Vector3 = BABYLON.Vector3.Zero();
    private connectedBeaker: Nullable<Beaker> = null;

    constructor(name: string, scene: BABYLON.Scene, parent: BaseRenderedObject) {
        super(name, scene, parent);
        this.type = RenderedObjectTypes.HOT_PLATE;

        // textures
        this.faceTextureURL = "https://content-2963cdfd-0edd-493c-bc78-d0c9602417d4.s3.amazonaws.com/assets/textures/meters/HotPlate.png";

        this.stateMachine = new ElectronicStateMachine({
            states: {
                temp: 0,
                setTemp: 0,
                maxTemp: 200
            },
            computed: {
            },
            actions: {
                hitPower: (sm) => {
                    sm.togglePower();
                    sm.set('temp', 20);

                },
                hitHeatUp: (sm) => {
                    sm.addToState('temp', 5).limitValue('temp', 20, 150);
                },
                hitHeatDown: (sm) => {
                    sm.addToState('temp', -5).limitValue('temp', 20, 150);
                }
            }
        });

        // default options
        const ph = 0.015;
        const pd = 0.08;
        this.setOptions({
            width: 0.10,
            height: 0.04,
            depth: 0.10,
            edgeRadius: 0.0,
            fontSize: this.digitFont,
            displayHeight: 0.07,
            displayWidth: 0.09,
            displayLocation: new BABYLON.Vector3(0, ph / 2.0, ((-0.2 - pd) / 2.0 - 0.001)),
            portrudeHeight: ph,
            portrudeDistance: pd
        });

        // button map

        this.addButtonMapItem('POWER', [0.03, -0.35, 0.19, -0.07], 'hitPower');

        this.addButtonMapItem('HEAT_UP', [0.77, -0.35, 0.96, -0.07], 'hitHeatUp');
        this.addButtonMapItem('HEAT_DOWN', [0.77, -0.95, 0.94, -0.60], 'hitHeatDown');

        // setup ports
        this.portMap.set('Temperature',
            {
                name: '0',
                value: new InstrumentDisplayValue(0, 'cP',
                    {
                        minValue: _u(-100, "C"),
                        maxValue: _u(400, "C"),
                        overflow: RangeOverFlowOptions.CLIP
                    },
                    {
                        addNoise: true,
                        type: NoiseType.PerOfRange,
                        percent: 0.0001,
                        correlation: NoiseCorrelation.SinVariationWithTime,
                        period: 1,
                        decayConst: 1
                    }),
                connection: null
            });


        // display function
        this.onParentCreated = this.renderAdditionalParts;
        this.onDisplayUpdate = this.displayCallback;
        this.onTimeIncrement = this.tickTimeIncrement;
    }

    public registerAbsoluteSnapPoints() {
        this.registerSnapPoint(
            this.snapBeakerLocation,
            [RenderedObjectTypes.BEAKER],
            (newBeaker) => { this.connectedBeaker = newBeaker as Beaker; },
            (oldBeaker) => { this.connectedBeaker = null; }
        );
        super.registerAbsoluteSnapPoints();
    }

    private renderAdditionalParts() {
        console.debug("Render Additional Hot Plate Parts")
        this.snapBeakerLocation = BABYLON.Vector3.Zero();

        const topPlate = BABYLON.MeshBuilder.CreateCylinder(this.name + "topPlate",
            {
                diameter: this.options.width * 2,
                height: 0.01
            }, this.scene);

        topPlate.material = MaterialRegistry.getSilver(this.scene);
        topPlate.position.y += this.options.height;
        topPlate.setParent(this.getContainerMesh());

        const marker = BABYLON.MeshBuilder.CreateSphere(this.name, {
            diameter: 0.001
        }, this.scene);

        marker.setParent(this.getContainerMesh());
        marker.locallyTranslate(new BABYLON.Vector3(0, this.options.height, 0));
        this.snapBeakerLocation = marker.getAbsolutePosition();

        this.addSnapBehaviorToMesh(this.myContainerNode, SnapBehaviorType.AXIS, new BABYLON.Vector3(1, 0, 0));
    }

    public getDragPoint(): BABYLON.Vector3 {
        return this.myContainerNode?.getAbsolutePosition()
            .clone()
            .addInPlace(new BABYLON.Vector3(0, -this.options.height / 2.0, 0)) ?? BABYLON.Vector3.Zero();
    }

    public dragTo(absPosition: BABYLON.Vector3, orientation: BABYLON.Vector3, alignment: any) {
        this.myContainerNode
            ?.setAbsolutePosition(absPosition.clone()
                .addInPlace(new BABYLON.Vector3(0, this.options.height, 0)));

        // drag connected object
        if (this.connectedBeaker !== null) {
            this.connectedBeaker.dragTo(this.snapBeakerLocation, null, null);
        }
    }

    public getFluidProps() {
        if (this.connectedBeaker === null) return null;
        return this.connectedBeaker.getFluidProps();
    }

    private tickTimeIncrement(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {
        const dT = variableTable.get("DELTA_T")?.in("s") ?? 0;

        let temperature = 20;
        const isOn = this.stateMachine?.get('isOn');
        if (isOn) {
            temperature = this.stateMachine?.get('temp');
        }

        if (this.connectedBeaker !== null) {
            this.connectedBeaker.simulateHeating(temperature, dT);
        }
    }

    private displayCallback(variableTable: VariableTable, ed: ElectronicDevice, display: ElectronicDisplay) {
        const time = variableTable.get("TIME")?.in("s");
        const dT = variableTable.get("DELTA_T")?.in("s");

        const isOn = this.stateMachine?.get('isOn');
        if (isOn) {
            display.drawText("Set Temp", 0, 20, this.infoFont);
            display.drawText(this.stateMachine?.get('temp').toString() + " C", 5, 50, this.digitFont);
        }

        if (this.connectedBeaker !== null) {
            const fluidTemp = this.connectedBeaker.getProperty('TEMPERATURE');
            const actualTemp = (this.portMap?.get('Temperature')?.value?.copy(fluidTemp) as InstrumentDisplayValue)
                .getDisplayScalar(time);

            if (isOn) {
                display.drawText("Actual Temp", 0, 80, this.infoFont);
                display.drawValue(actualTemp.in('C'), " C", 3, 5, 110, this.digitFont);
            }
        }

    }
}