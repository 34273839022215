import { TypeGuard } from "@/components/contentGenerator/mathjs/Type-guards";
import { Maybe } from "@/utils/maybe";

export enum PipeComponentType {
    GLOBE_VALVE,
    ANGLE_VALVE,
    SWING_CHECK_VALVE,
    GATE_VALVE,
    ELBOW,
    ELBOW_45,
    SUDDEN_CONTRACTION,
    SUDDEN_ENLARGEMENT,
    TEE
}

export class PipeComponent {
    componentType: PipeComponentType;
    inletDiameter: number;  // in meters
    outletDiameter: Maybe<number>; // in meters
    percentOpen: number;    // in percent 0 to 100

    constructor(componentType: PipeComponentType, inletDiameter: number, outletDiameter: Maybe<number> = null, percentOpen = 100) {
        this.componentType = componentType;
        this.inletDiameter = inletDiameter;
        this.outletDiameter = outletDiameter;
        this.percentOpen = 0;
        this.setPercentOpen(percentOpen);
    }

    setPercentOpen(percentOpen: number) {
        this.percentOpen = percentOpen;
        return this;
    }

    getK() {
        const f = PipeComponentFactory.lookupTable.get(this.componentType);
        if (TypeGuard.isNullOrUndefined(f))
            throw Error("Unable to determine K for this component.");

        return f(this.inletDiameter, this.outletDiameter as number, this.percentOpen);
    }
}

export class PipeComponentFactory {
    static lookupTable: Map<PipeComponentType, (inD: number, outD: number, perO: number) => number> = new Map([
        [PipeComponentType.ANGLE_VALVE, (inD, outD, perO) => { const mx = 4.7 * (0.025 / inD); return 100 * mx / perO; }],
        [PipeComponentType.GATE_VALVE, (inD, outD, perO) => { const mx = 0.25 * (0.025 / inD); return 100 * mx / perO; }],
        [PipeComponentType.GLOBE_VALVE, (inD, outD, perO) => { const mx = 8.2 * (0.025 / inD); return 100 * mx / perO; }],
        [PipeComponentType.SWING_CHECK_VALVE, (inD, outD, perO) => { const mx = 2.9 * (0.025 / inD); return 100 * mx / perO; }],
        [PipeComponentType.ELBOW, (inD, outD, perO) => { return 1.5; }],
        [PipeComponentType.ELBOW_45, (inD, outD, perO) => { return 0.32; }],
        [PipeComponentType.TEE, (inD, outD, perO) => { return 1.8; }],
        [PipeComponentType.SUDDEN_CONTRACTION, (inD, outD, perO) => { return 0.42 * (1 - (outD * outD) / (inD * inD)); }],
        [PipeComponentType.SUDDEN_ENLARGEMENT, (inD, outD, perO) => { return (1 - (inD * inD) / (outD * outD)); }]
    ]);

    static getComponent(type: PipeComponentType, inletDiameter: number, outletDiameter: Maybe<number> = null, percentOpen = 100) {
        return new PipeComponent(type, inletDiameter, outletDiameter, percentOpen);
    }
}