import { TextbookTableBaseType } from "./table-textbook.interface";

export function getDefaultBaseTextbookDBRecord(): TextbookTableBaseType {
   return {
      id: "",
      textID: "",
      version: "",
      name: "",
      description: "",
      long_description: ""
   }
}
