<template>
  <teleport v-if="show" to="body">
    <div v-if="show"
         class="overlay d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "BOverlay",
    props: {
      show: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {};
    },
  });
</script>

<style scoped>
  .overlay {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .overlay:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    background: -webkit-radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
</style>