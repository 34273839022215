
  import { mjsFormatting } from '@/components/contentGenerator/mathjs/extension/formatting';
  import { mjsUnknown } from '@/components/contentGenerator/mathjs/math-types';
  import { TypeGuard } from '@/components/contentGenerator/mathjs/Type-guards';
  import { ModelHealthReport } from '@/components/contentGenerator/questions/model/model-health-report';
  import { readQuestionComponent } from '@/components/contentGenerator/questions/questionComponentReader';
  import { QuestionDataInterface } from '@/components/contentGenerator/questions/QuestionViewer.interface';
  import usePageComponents from '@/components/use/usePageComponents';
  import { PageFromTextbookTable } from '@/store/database/Textbook/table-textbook.interface'
  import { defineComponent, PropType, Ref, ref, toRefs, watch } from 'vue'

  interface variableListItem {
    name: string,
    value: unknown
  }

  export default defineComponent({
    props: {
      questionData: {
        type: Object as PropType<PageFromTextbookTable>,
        required: true,
      },
    },
    setup(props) {
      const { questionData } = toRefs(props);
      const { getActiveComponentsFromPageData } = usePageComponents();
      const component = ref({});
      const modelHealthReport = ref(new ModelHealthReport());
      const listOfRandomVariables: Ref<variableListItem[]> = ref([]);
      const listOfComputedVariables: Ref<variableListItem[]> = ref([]);
      const listOfRequestedValues: Ref<variableListItem[]> = ref([]);
      const minMax: Ref<Record<string, { min: mjsUnknown, max: mjsUnknown }>> = ref({});

      const recomputeSystem = () => {
        component.value = getActiveComponentsFromPageData(props.questionData).components[0];

        const {
          problemStatement,
          solutionStatement,
          includeFigure,
          responseRequests,
          questionModel
        } = readQuestionComponent(component.value as unknown as QuestionDataInterface,
          modelHealthReport.value as ModelHealthReport);

        const tmpListOfRndVar = questionModel.getListOfRandomVariables();
        listOfRandomVariables.value = tmpListOfRndVar
          .map((name: string) => {
            return {
              name,
              value: questionModel.getVariableValue(name, false)
            }
          });

        listOfComputedVariables.value = questionModel.getListOfAllComputedQuantities()
          .filter((name: string) => tmpListOfRndVar.indexOf(name) === -1)
          .map((name: string) => {
            return {
              name,
              value: questionModel.getVariableValue(name, true)
            }
          });

        listOfRequestedValues.value = responseRequests.map((v) => {
          try {
            const value = questionModel.evalToText(questionModel.evalToNumeric(v.correctAnswer), 3);
            return {
              name: v.correctAnswer,
              value
            }
          } catch (err) {
            console.log(err);
            return {
              name: v.correctAnswer,
              value: 0
            }
          }
        });

        console.log("List of Rand Var", listOfRandomVariables.value);
        console.log("List of Rand Var", listOfComputedVariables.value);

        minMax.value = questionModel.solveForMinMax();
      };

      recomputeSystem();

      watch(questionData, () => {
        recomputeSystem();
      });

      const getMinMax = (name: string) => {
        if (TypeGuard.hasProp(minMax.value, name)) {
          const simplify = listOfRandomVariables.value.findIndex((i) => i.name === name) === -1;
          return {
            min: mjsFormatting.toText(minMax.value[name].min, 3, false, simplify, true),
            max: mjsFormatting.toText(minMax.value[name].max, 3, false, simplify, true),
          };
        }
        return { min: 0, max: 0 }
      }

      return {
        listOfRandomVariables,
        listOfComputedVariables,
        modelHealthReport,
        listOfRequestedValues,
        getMinMax
      }
    }
  })
