<template>
  <div class="container">
    <div class="row">
      <div v-for="item in itemList"
           :key="item"
           :style="{width: '20rem'}"
           class="card"
           :class="{ active: isSelected(item.id) }"
           @click="onClick(item)">
        <div class="card-body">
          <h5 class="card-title">
            {{ item.name }}
          </h5>
          <BImg :src="item.url"
                :style="{ objectFit:'cover', maxHeight: '150px' }"
                class="card-img-bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, Ref, ref } from 'vue'
  import BImg from './b-img.vue';
  import { BCatalogEntry } from './b-catalog.interface';

  export default defineComponent({
    components: { BImg },
    props: {
      modelValue: {
        type: Object as PropType<{ id: string }[]>,
        required: true
      },
      itemList: {
        type: Array as PropType<BCatalogEntry[]>,
        required: true
      },
      itemEntryMaker: {
        type: Function as PropType<(item: BCatalogEntry) => { id: string }>,
        required: true
      }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const selectedList: Ref<{ id: string }[]> = ref(props.modelValue);

      const onClick = (item: BCatalogEntry): void => {

        const index = selectedList.value.findIndex(entry => entry.id === item.id);
        if (index !== -1) {
          selectedList.value.splice(index, 1);
        } else {
          selectedList.value.push(props.itemEntryMaker(item));
        }

        context.emit("update:modelValue", selectedList.value);
      }

      const isSelected = (id: string) => {
        return selectedList.value.findIndex(item => item.id === id) !== -1;
      }


      return {
        isSelected,
        selectedList,
        onClick
      }
    }
  })
</script>

<style scoped>
  .active {
    border: 3px solid blue;
  }
</style>