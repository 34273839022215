import { PageRecord } from "@/store/database/Textbook/record-page";
import { PageFromTextbookTable } from "@/store/database/Textbook/table-textbook.interface";
import { TypeGuard } from "../contentGenerator/mathjs/Type-guards";
import { PageComponent, PageComponentTypes } from "../contentGenerator/pages/pages.interface";


interface ComponentReturnType {
   components: PageComponent[]
}

interface UseComponetsReturnItems {
   findComponentType: (type: number | PageComponentTypes | undefined) => string;
   getActiveComponentsFromPageData: (pageData: PageFromTextbookTable) => ComponentReturnType;
   getFirstComponentOfType: (type: PageComponentTypes, pageRecord: PageRecord) => PageComponent | null
}

export default function usePageComponents(): UseComponetsReturnItems {
   const getFirstComponentOfType = (type: PageComponentTypes, pageRecord: PageRecord) => {
      const processedPageData = getActiveComponentsFromPageData(pageRecord.data());
      const foundComponents = processedPageData.components.filter((v) => v.type === type);
      return foundComponents.length > 0 ? foundComponents[0] : null;
   }

   const findComponentType = (type: number | PageComponentTypes | undefined) => {
      switch (type) {
         case PageComponentTypes.MARKDOWN:
            return "MarkdownComponent";

         case PageComponentTypes.VIDEO:
            return "VideoComponent";

         case PageComponentTypes.IMG:
            return "ImageComponent";

         case PageComponentTypes.QUESTION_BLOCK:
            return "QuizComponent";

         case PageComponentTypes.BABYLON:
            return "BabylonComponent";
      }

      return "undefined";
   };

   const getActiveComponentsFromPageData = (pageData: PageFromTextbookTable): ComponentReturnType => {
      const rawContent = pageData.page_activeContent.content;

      if (typeof rawContent === "string") {
         return JSON.parse(rawContent);
      }

      if (TypeGuard.isNullOrUndefined(rawContent)) {
         return { components: [] };
      }

      if (rawContent.components && Array.isArray(rawContent.components)) {
         return { components: rawContent.components };
      }

      return { components: [] };
   }


   return {
      getActiveComponentsFromPageData,
      getFirstComponentOfType,
      findComponentType
   }
}