import { FormSchemaItem } from "../formGenerator/form-schema.interface";
import { FormSchemaParser } from "../formGenerator/form-schema-parser";

export default function useSchema() {
    const schema_mergeWithData = (inputSchema: FormSchemaItem, initialValues: Record<string, unknown>): FormSchemaItem => {
        try {
            const schema = new FormSchemaParser(inputSchema, initialValues).toSchema();
            return schema;
        } catch (error) {
            console.log('error trying to parse schema', inputSchema);
            throw Error("Unable to get schema form parser")
        }
    }

    const schema_getJSON = (inputSchema: FormSchemaItem) => {
        try {
            const jsonData = new FormSchemaParser(inputSchema, {}).toJSON();
            return jsonData as { data: Record<string, unknown> };
        } catch (error) {
            console.log('error trying to parse schema', inputSchema);
            throw Error("Unable to get JSON from schema")
        }
    }

    return {
        schema_mergeWithData,
        schema_getJSON
    };
}
