import { failable } from "@/components/global/response.interface";

let mockStorage: Record<string, string> = {};
const mockStorageInterface: Storage = {
    clear: () => { mockStorage = {}; },
    getItem: (key: string) => { return mockStorage[key]; },
    setItem: (key: string, value: string) => { mockStorage[key] = value; },
    removeItem: (key: string) => { delete mockStorage[key]; }
} as Storage;

export class LocalStorageClass {
    private storage: Storage | undefined;

    private namespace = "loc_";

    constructor(storage?: Storage) {
        if (storage) {
            this.storage = storage;
        } else {
            try {
                if (window) {
                    this.storage = window.localStorage;
                } else {
                    this.storage = new Storage();
                }
            } catch (err) {
                console.log(err);
                this.storage = mockStorageInterface;
            }
        }
    }

    config(namespace: string): LocalStorageClass {
        this.namespace = namespace;
        return this;
    }

    async clearStorage() {
        return await failable(() => {
            const removedKeys: string[] = [];
            const regexp = new RegExp(`^${this.namespace}.+`, "i");

            Object.keys(this.storage!).forEach((key) => {
                if (regexp.test(key)) {
                    removedKeys.push(key);
                }
            });

            removedKeys.forEach((key) => {
                this.storage?.removeItem(key);
            });
        })
    }

    remove(key: string): boolean {
        if (!this.hasKey(key)) return false;
        this.storage?.removeItem(this._constructItemKey(key));
        return true;
    }

    hasKey(key: string): boolean {
        const lookupKey = this._constructItemKey(key);
        const dataString = this.storage?.getItem(lookupKey);
        if (dataString === null || dataString === undefined) return false;

        const obj = JSON.parse(dataString!) as StorageObject;

        if (Object.prototype.hasOwnProperty.call(obj, 'expirationDate') &&
            !this._isExpired(obj.expirationDate)) {
            return true;
        }

        return false;
    }

    private _constructItemKey(key: string) {
        return `${this.namespace}.${key}`
    }

    private _isExpired(expirationDate: number) {
        if (expirationDate < 0) return false;

        if (expirationDate < new Date().getTime()) {
            return true;
        }

        return false;
    }

    async getCreationDateFor(key: string) {
        const lookupKey = this._constructItemKey(key);
        const dataString = this.storage?.getItem(lookupKey);
        if (dataString === null || dataString === undefined) return null;

        const obj = JSON.parse(dataString) as StorageObject;

        if (Object.prototype.hasOwnProperty.call(obj, 'creationDate')) {
            return obj.creationDate;
        }

        return null;
    }

    async get(key: string) {
        const result = await failable(() => {
            const lookupKey = this._constructItemKey(key);
            const dataString = this.storage?.getItem(lookupKey);
            if (dataString === null) {
                throw Error(`Local Storage - Key: ${key} - does not exist`);
            }

            const storageObject = JSON.parse(dataString!) as StorageObject;

            if (storageObject.expirationDate < 0 ||
                !this._isExpired(storageObject.expirationDate)) {
                const item = JSON.parse(storageObject.dataString);
                return item;
            } else {
                throw Error(`Local Storage - ${key} item was found in storage, but has expired`)
            }
        });

        if (result.hasData()) return result.getData();
        return null;
    }

    async set(key: string, data: unknown, expireTime = -1) {
        return await failable(() => {
            const dataString = JSON.stringify(data);
            const expirationDate = expireTime;
            const creationDate = new Date().getTime();
            const storageObject: StorageObject = {
                dataString,
                expirationDate,
                creationDate
            }
            const storageString = JSON.stringify(storageObject);
            const lookupKey = this._constructItemKey(key);
            this.storage?.setItem(lookupKey, storageString);
        });
    }
}