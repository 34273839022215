
  import { bookEditorProps, pageEditorProps, usePageEditorFunctions } from "./usePageEditorFunctions";
  import { defineComponent } from "vue";
  import PageIterator from "../components/p-page-iterator.vue";
  import QuestionCreatorForm from "./QuestionCreatorForm.vue";
  import router from "@/router";


  export default defineComponent({
    name: "QuetionBankEditor",
    components: { PageIterator, QuestionCreatorForm },
    props: {
      ...bookEditorProps,
      ...pageEditorProps
    },
    async setup(props) {
      function routeTo(inputPageId: string) {
        router.push({
          name: "QuestionBankEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
            rootPageId: props.rootPageId,
          },
        });
      }

      return {
        ...usePageEditorFunctions(routeTo)
      }
    },
  });
