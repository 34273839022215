<template>
  <PageIterator :key="pageId"
                :course-id="courseId"
                :text-id="textId"
                :page-id="pageId"
                :root-page-id="rootPageId"
                :editable="true"
                :nestable="true"
                route-to-destination="TextbookEditor"
                @addItem="addItemAndUpdateTOC"
                @deleteItem="deletePageAndUpdateTOC"
                @onReorderItems="updateTOC"
                @changeItemName="changeItemNameAndUpdateTOC">
    <template #display="{ data }">
      <TextbookPageEditor v-if="pageHasComponent(data)"
                          :key="data?.data().id"
                          :data-page="data"
                          @saveQuestion="updateLocalAndDBPage" />
    </template>
  </PageIterator>
</template>

<script lang="ts">
  import router from "@/router";
  import { defineComponent } from "vue";
  import PageIterator from "../components/p-page-iterator.vue";
  import TextbookPageEditor from "./TextbookPageCreator.vue";
  import { bookEditorProps, pageEditorProps, usePageEditorFunctions } from "./usePageEditorFunctions";

  export default defineComponent({
    name: "TextbookEditor",
    components: { PageIterator, TextbookPageEditor },
    props: {
      ...bookEditorProps,
      ...pageEditorProps
    },
    async setup(props) {
      function routeTo(inputPageId: string) {
        router.push({
          name: "TextbookEditor",
          params: {
            courseId: props.courseId,
            textId: props.textId,
            pageId: inputPageId,
            rootPageId: props.rootPageId,
          },
        });
      }

      return {
        ...usePageEditorFunctions(routeTo)
      }
    },
  });
</script>

<style scoped>
</style>