import * as BABYLON from 'babylonjs';

export interface ImageButtonMapItem {
    isActive: boolean,
    lowerLeft_uX: number,
    lowerLeft_uY: number,
    upperRight_uX: number,
    upperRight_uY: number,
    execute: () => void
}

export class ImageButtonMap {
    private buttonMap: Map<string, ImageButtonMapItem> = new Map();

    register(name: string, lowerLeft_uX: number, lowerLeft_uY: number,
        upperRight_uX: number, upperRight_uY: number,
        execute: () => void, isActive = true) {
        this.buttonMap.set(name, {
            isActive: isActive,
            lowerLeft_uX: lowerLeft_uX,
            lowerLeft_uY: lowerLeft_uY,
            upperRight_uX: upperRight_uX,
            upperRight_uY: upperRight_uY,
            execute: execute
        });
    }

    processHit(uX: number, uY: number) {
        // for creating buttonmaps
        console.log(uX, uY);
        this.buttonMap.forEach((v, i, a) => {
            if (v.isActive) {
                if (uX < v.upperRight_uX && uX > v.lowerLeft_uX) {
                    if (uY < v.upperRight_uY && uY > v.lowerLeft_uY) {
                        v.execute();
                        console.log(v.execute);
                    }
                }
            }
        });
    }

    setActive(name: string, active: boolean) {
        const tmpButton = this.buttonMap.get(name);
        if (tmpButton)
            tmpButton.isActive = active;
    }
}