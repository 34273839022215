import { createTextRecognition, deleteTextRecognition, updateTextRecognition } from "@/graphql/mutations";
import { RecordCreatorParameters, RecordTypes, setRecordCreator } from "../database.interface";
import { WrappedRecord } from "../wrappedRecord";
import { getDefaultBaseTextRecognition } from "./table-textrecognition-utils";
import { TextRecognitionBaseTableType, TextRecognitionFieldType } from "./table-textrecognition.interface";

export interface TextRecognitionCreationOptions {
   type: TextRecognitionFieldType
}

const charRecordCreator: RecordCreatorParameters<TextRecognitionBaseTableType, TextRecognitionCreationOptions> = {
   defaultValues: {
      ...getDefaultBaseTextRecognition(),
   },
   objectType: RecordTypes.BOOK,
   createQuery: createTextRecognition,
   updateQuery: updateTextRecognition,
   deleteQuery: deleteTextRecognition,

   getDeleteItemParameters: function (data: TextRecognitionBaseTableType) {
      return {
         id: data.id,
         fieldType: data.fieldType,
      };

   },

   onAfterCreatedModifyData: function (data: TextRecognitionBaseTableType,
      createOptions: TextRecognitionCreationOptions,
      myChar: CharRecord) {
      myChar.data().fieldType = createOptions.type;
   },

   onBeforeSaveToDB: function (data: TextRecognitionBaseTableType, myChar: CharRecord) {
      return data;
   },

   onAfterRecordWrapFromDB(data: TextRecognitionBaseTableType, myChar: CharRecord) {
      return;
   }
}

setRecordCreator(RecordTypes.TEXT_RECOGNITION, charRecordCreator);


/**
 * Class used to hold, update, and create database 
 * Textbook items. 
 * */
export class CharRecord extends WrappedRecord<TextRecognitionBaseTableType, TextRecognitionCreationOptions> {
   constructor() {
      super(RecordTypes.TEXT_RECOGNITION)
   }

   static loadFromJSON(data: Record<string, unknown>): CharRecord {
      if (!data) return new CharRecord();
      const newBook = new CharRecord();
      Object.assign(newBook, data);
      return newBook;
   }
}